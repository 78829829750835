import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../_metronic/layout";

import {
    makeStyles,
    MenuItem,
    TextField,
    Link
} from '@material-ui/core';

import {
    Button,
    Accordion,
    Card,
    Tabs,
    Tab,
    Spinner
} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));

export function NewExtract() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar um Extrato");

    const [isSubmit, setIsSubmit] = useState(false);


    return (

        <div className="row card card-body pt-4">

            <form
                className={'makeStyles-container-12'}
                noValidate
                autoComplete="off">

                <Tabs defaultActiveKey="detalhes-extrato" id="newproduct-form-tabs">
                    <Tab eventKey="detalhes-extrato" title="Detalhes do Extrato">

                        <div className="row">

                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Tipo de Lançamento"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    name="typeLaunch"
                                >
                                    <MenuItem key="0" value="receita">
                                        Receita
                                    </MenuItem>

                                    <MenuItem key="1" value="despesa">
                                        Despesa
                                    </MenuItem>

                                    <MenuItem key="2" value="transferencia">
                                        Transferência
                                    </MenuItem>

                                </TextField>
                            </div>


                            <div className="col-lg-4">
                                <TextField
                                    label="Nome da Receita ou Despesa"
                                    margin="normal"
                                    variant="outlined"
                                    name="nameRecipeOrExpense"
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Conta Bancária"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    name="bankAccount"
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    label="Categoria"
                                    margin="normal"
                                    variant="outlined"
                                    name="category"
                                />
                            </div>

                        </div>

                        <div className="row ">

                            <div className="col-lg-6 d-flex align-items-center">
                                <TextField
                                    label="Cliente ou Fornecedor"
                                    margin="normal"
                                    variant="outlined"
                                    name="clientOrProvider"
                                />

                                <Button
                                    style={{ marginLeft: 8 }}
                                    size="lg"
                                    variant="primary"
                                >
                                    Procurar
                                </Button>
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    type="date"
                                    label="Data de Emissão"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="emissionDate"
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    type="date"
                                    label="Vencimento"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="dueDate"
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    label="Valor"
                                    margin="normal"
                                    variant="outlined"
                                    name="value"
                                />
                            </div>

                        </div>

                    </Tab>

                    <Tab eventKey='detalhes-conta' title='Detalhes da conta'>

                        <div className="row">
                            <div className="col-lg-2">
                                <TextField
                                    label="Nº Documento"
                                    margin="normal"
                                    variant="outlined"
                                    name="numberDocument"
                                />
                            </div>

                            <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Forma de Pagamento"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    name="wayOfPayment"
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-6">
                                <TextField
                                    label="Centro de custos"
                                    margin="normal"
                                    variant="outlined"
                                    name="centerCost"
                                />
                            </div>

                            <div className="col-lg-12">
                                <TextField
                                    label="Observações"
                                    multiline
                                    rows="10"
                                    margin="normal"
                                    variant="outlined"
                                    name="note"
                                />
                            </div>
                        </div>

                    </Tab>

                </Tabs>


                <div className='col-lg-6 mt-15 d-flex flex-row'>

                    <Button
                        type='submit'
                        className='mr-3'
                        variant="primary"
                        disabled={isSubmit}
                    >

                        {isSubmit ? <>

                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>

                        </> : <>

                            <span>
                                Salvar
                            </span>

                        </>}


                    </Button>

                    <Link href='/extrato' className='btn btn-secondary'>

                        Cancelar

                    </Link>

                </div>

            </form>
        </div>
    );
}