import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditNfce } from '../pages/Nfce/Edit-Nfce';
import {ListNfce} from '../pages/Nfce/List-Nfce';
import {NewNfce} from '../pages/Nfce/New-Nfce';

export function Nfce() {
    
    return (
        <Switch>
            <Route path="/notas-fiscais-consumidor/adicionar" component={NewNfce}/>
            <Route path="/notas-fiscais-consumidor/duplicar/:idToClone" component={NewNfce}/>
            <Route path="/notas-fiscais-consumidor/:id" component={EditNfce}/>
            <Route path="/notas-fiscais-consumidor" component={ListNfce}/>
        </Switch>
    );
}