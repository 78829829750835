import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { formatCurrency, formatIntegerToMoney } from '../../../utils/formatCurrency';
import api from "../../../services/Api";
import { ListProducts } from './List-Products'

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import { PdvSale } from '../../../types/PdvSale';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useBackendLoad from '../../../hooks/backendReload';
import CustomerService from '../../../services/CustomerService';
import { Customer } from '../../../types/Customer';
import ApiResourceSelect from '../../../components/ApiResourceSelect';

type ReportPdvResponse = {
    rows: PdvSale[];
    count: number;
}

type Filters = {
    typePayment: string;
    customer: number;
    dateEmissionInitial: string;
    dateEmissionEnd: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "clientName", value: "Cliente" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "paidValue", value: "Total Pago" },
    { reference: "createdDate", value: "Data de Cadastro" }
];

const productHeadData: HeadDataBaseProps[] = [
    { reference: "code", value: "Cod" },
    { reference: "quantity", value: "Quanti." },
    { reference: "name", value: "Nome" },
    { reference: "unitValue", value: "Valor Unitário" },
    { reference: "totalValue", value: "Valor Total" },
];

export function ListReportPDV() {
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [listPdvSales, setListPdvSales] = useState<PdvSale[]>([]);
    const [countPdvSalesPaginated, setCountPdvSalesPaginated] = useState(0);
    const [countPdvSales, setCountPdvSales] = useState(0);
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [customer, setCustomer] = useState(0)
    const [dateEmissionInitial, setDateEmissionInitial] = useState('')
    const [dateEmissionEnd, setDateEmissionEnd] = useState('')
    const [typePayment, setTypePayment] = useState('')
    const [showProducts, setShowProducts] = useState(false)
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData= useCallback(async (
        sortDirection,
        sortReference
    ) => {
        const { data } = await api.get<ReportPdvResponse>("/report/pdv", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            let exportProductData: ReportDataRow[] = [];
            const data = mountBodyDataRow(reportRow);
            const products = reportRow.items;

            data.push(
                {
                    for: "products/services",
                    value: '',
                    secondaryRow: true,
                    jsx: <ListProducts products={products}/>
                },
            );

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportProductData = products ? products.map((product: any) => {
                return {
                    cells: [
                        {for: 'code', content: product.product.code ?? null},
                        {for: 'quantity', content: product.quantity ?? '0'},
                        {for: 'name', content: product.product.name ?? null},
                        {for: 'unitValue', content: product.unitValue ?? '0'},
                        {for: 'totalValue', content: product.totalValue ?? '0'},
                    ],
                };
            }) : [];

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productHeadData,
                    bodyData: exportProductData,
                }
            });

            setExportTotals([
                { name: 'Total de PDVs', value: count },
            ])
        });

        setExportBodyData(exportDataList);
        setCountPdvSales(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportPdvResponse>("/report/pdv", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setListPdvSales(rows);
        setCountPdvSalesPaginated(count);
        setLastSortDirection(sortDirection);
        setLastSortReference(sortReference);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = listPdvSales;

        aux.forEach((pdvSale) => {
            const data = mountBodyDataRow(pdvSale);
            const products = pdvSale.items

            if (showProducts == true) {
                data.push(
                    {
                        for: "products/services",
                        value: '',
                        secondaryRow: true,
                        jsx: <ListProducts items={products}/>
                    },
                );
            }

            list.push(data);
        });

        setBodyData(list);
    }, [listPdvSales]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: PdvSale) {
        const date = new Date(reportRow.createdDate);

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "clientName", value: CustomerService.getCustomerName(reportRow.customerEntity) || 'N/A' },
            { for: "totalValue", value: formatIntegerToMoney(reportRow.totalValue, true) },
            { for: "paidValue", value: formatIntegerToMoney(reportRow.totalValue, true) },
            { for: "createdDate", value: String(("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()) },
        ];

        return data;
    }

    const clearSearch = () => {
        setCustomer(0);
        setDateEmissionInitial('');
        setDateEmissionEnd('');
        setTypePayment('');
        setShowProducts(false);
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            typePayment,
            customer,
            dateEmissionInitial,
            dateEmissionEnd
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        typePayment,
        customer,
        dateEmissionInitial,
        dateEmissionEnd
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'PDVs', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4 w-100">
            <div className="row d-flex align-items-center w-100">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(option?.id || 0)}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão Inicial"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={dateEmissionInitial}
                        onChange={(e) => setDateEmissionInitial(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de Emissão Final"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={dateEmissionEnd}
                        onChange={(e) => setDateEmissionEnd(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        label="Forma de Pagamento"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        value={typePayment}
                        onChange={(e) => setTypePayment(e.target.value)}
                    >
                        <MenuItem key="0" value="">Todos</MenuItem>
                        <MenuItem key="1" value="money">Dinheiro</MenuItem>
                        <MenuItem key="2" value="credit_card">Cartão de Crédito</MenuItem>
                        <MenuItem key="3" value="debit_card">Cartão de Débito</MenuItem>
                        <MenuItem key="4" value="pix">PIX</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProducts}
                                onChange={(_, checked) => setShowProducts(checked)}
                            />
                        }
                        label="Exibir os produtos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de PDV"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'PDV', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                   headData={headData}
                   bodyData={bodyData}
                   loadData={loadData}
                   sortable={true}
                   totalCount={countPdvSalesPaginated}
                   triggerLoad={triggerLoad}
                   setTriggerLoad={setTriggerLoad}
                   lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de PDVs</TableCell>
                                    <TableCell colSpan={1}>{countPdvSales}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}