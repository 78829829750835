import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Link
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal
} from "react-bootstrap";

import '../../style.css';

import api from '../../services/Api';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';
import ModalError from '../../components/ModalError';
import LogService from '../../services/LogService';
import { SubCategory } from '../../types/Dre';
import SellerService from '../../services/SellerService';
import { frequency as frequencyEnum, RecurringExpenses } from '../../types/RecurringExpenses';
import { frequencyToMonths } from '../../utils/frequencyToMonths';
import { extractDateStringFromTimestamp, getLastDayOfMonth } from '../../utils/dateTimeHelper';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import CustomerService from '../../services/CustomerService';
import BankAccountService from '../../services/BankAccountService';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import useAddAccountBank from '../../hooks/addAccountBank';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';



type BankAccount = {
    id: number;
    name: string;
    nameBank: string;
};

type Beneficiary = {
    id: number;
    name: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        },
    },
}));


export function NewRecurringExpenses() {
    const [activeTab, setActiveTab] = useState("detalhes-despesa");
    const [frequency, setFrequency] = useState("");
    const [name, setName] = useState("");
    const [payment, setPayment] = useState("");
    const [nextPaymentDate, setNextPaymentDate] = useState(new Date().toDateString());
    const [docNumber, setDocNumber] = useState("");
    const [comments, setComments] = useState("");
    const [amount, setAmount] = useState("0");
    const [isPaid, setIsPaid] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [occurrence] = useState("unica");
    const [expireDay, setExpireDay] = useState("");
    const [daysBeforeExpire, setDaysBeforeExpire] = useState("");
    const [accountBank, setAccountBank] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [beneficiaryType, setBeneficiaryType] = useState("supplier");
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)
    const [categoryName, setCategoryName] = useState("")

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    // Inputs Verify
    const [expensesError, setExpensesError] = useState(false);
    const [accountBankError, setAccountBankError] = useState(false);
    const [payMethodsError, setPayMethodsError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [accountPlanError, setAccountPlanError] = useState(false);
    const [expireDayIsInvalid, setExpireDayIsInvalid] = useState(false);

    const { idToClone } = useParams<{idToClone: string}>();
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, watch, handleSubmit } = useForm();

    subHeader.setTitle("Adicionar Despesa Recorrente");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        setAccountBank(String(accountBank.id));
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setBeneficiaryId(String(createdCustomer.id));
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    useEffect(() => {
        async function getDefaultAccountBank() {
            const { standardExpenseAccount } = await BankAccountService.getDefaultBankAccounts();
            setAccountBank(String(standardExpenseAccount?.id ?? ''));
        }

        getDefaultAccountBank();
    }, []);

    useEffect(() => {
        if(!idToClone) return;

        async function getExpenseToCloneData() {
            const { data: expenseToClone } = await api.get<RecurringExpenses>(`recurringExpenses/${idToClone}`);

            if(!expenseToClone) return;

            setName(expenseToClone.name);
            setAmount(formatCurrency(expenseToClone.amount));
            setPayment(expenseToClone.payment);
            setExpireDay(expenseToClone.expireDay);
            setNextPaymentDate(expenseToClone.nextPaymentDate ? extractDateStringFromTimestamp(expenseToClone.nextPaymentDate) : '');
            setComments(expenseToClone.comments);
            setBeneficiaryType(expenseToClone.beneficiaryType);
            setBeneficiaryId(
                expenseToClone.beneficiaryType === 'supplier'
                    ? String(expenseToClone.supplier ?? '')
                    : String(expenseToClone.seller ?? '')
            );
            setDocNumber(expenseToClone.docNumber);
            setCenterCost(expenseToClone.centerCost ? String(expenseToClone.centerCost) : '');
            setAccountBank(expenseToClone.bankAccount);
            setCategoryName(expenseToClone.categoryName);
            setDreSubCategoryId(expenseToClone.dreSubCategoryId);
            setDaysBeforeExpire(expenseToClone.daysBeforeExpire);
            setFrequency(expenseToClone.frequency);
        }

        getExpenseToCloneData();
    }, [idToClone]);

    const onSubmit = async (dataForm: any) => {
        try {
            setIsSubmit(true);

            if (!inputsVerify(dataForm)) return;

            const bank = await api.get(`/accountBank/${parseInt(accountBank)}`);
            const today = new Date().toISOString().split('T')[0];

            const generateDateObj = new Date(`${extractDateStringFromTimestamp(nextPaymentDate)} 00:00:00`);
            generateDateObj.setDate(generateDateObj.getDate() - Number(daysBeforeExpire));
            const nextDate = extractDateStringFromTimestamp(generateDateObj.toISOString());

            const data = {
                name: name,
                amount: formatToFloat(amount),
                remaining: formatToFloat(amount),
                status: isPaid ? "paid" : "pending",
                bankAccount: String(accountBank).length > 0 ? parseInt(accountBank) : null,
                nameBank: String(accountBank).length > 0 ? bank.data.nameBank : null,
                centerCost: String(centerCost).length > 0 ? parseInt(centerCost) : null,
                docNumber: String(docNumber).length > 0 ? docNumber : null,
                comments: String(comments).length > 0 ? comments : null,
                nextPaymentDate: nextPaymentDate ? new Date(nextPaymentDate) : null,
                nextGenerateDate: nextDate,
                beneficiaryType,
                payment: String(payment).length > 0 ? payment : null,
                supplier: beneficiaryType === 'supplier' ? (beneficiaryId || null) : null,
                seller: beneficiaryType === 'seller' ? (beneficiaryId || null) : null,
                occurrence: "unica",
                frequency: frequency || frequencyEnum.MONTHLY,
                daysBeforeExpire: daysBeforeExpire || 5,
                expireDay: expireDay,
                categoryName: categoryName,
                dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
                payedDate: isPaid ? today : null,
                totalPaid: isPaid ? formatToFloat(amount) : null,
            }

            const unicResult = await api.post("recurringExpenses", data);

            LogService.logRegister({
                itemId: unicResult.data.id,
                module: 'Despesas Recorrentes',
                itemName: unicResult.data.name
            });


            setIsSubmit(false);

            history.push("/despesas-recorrentes");

        } catch (error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    function inputsVerify(dataForm: any) {
        
        if (!name) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Nome da despesa é obrigatório!");
            setShowModalError(true);
            setExpensesError(true);
            return false;
        }

        if (!accountBank) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Selecione uma conta bancária!");
            setShowModalError(true);
            return false;
        }

        // if(!dataForm.categoryName) {
        //     setIsSubmit(false);
        //     setMsgError("Campo Plano de Contas é obrigatório!");
        //     setShowModalError(true);
        //     return false;
        // }

        if (!payment) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            setPayMethodsError(true);
            return false;
        }

        if (!dreSubCategoryId) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas é obrigatório!");
            setShowModalError(true);
            setAccountPlanError(true);
            return false;
        }

        if (!amount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }
        if (Number(amount) <= 0) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }

        if (!expireDay) {
            setActiveTab("recorrencia");
            setIsSubmit(false);
            setMsgError('Informe o dia do vencimento!');
            setShowModalError(true);
            setExpireDayIsInvalid(true);
            return false;
        }

        return true;
    }

    function validateNextPaymentDate(expire: string, paymentDate: string) {
        if (!paymentDate || !expire) return;

        const paymentDateObj = new Date(`${paymentDate} 00:00:00`);
        if (!(paymentDateObj instanceof Date)) {
            setNextPaymentDate('');
            return;
        }

        const expireDayNumber = Number(expire);
        const monthLastDay = getLastDayOfMonth(paymentDateObj.getFullYear(), paymentDateObj.getMonth() + 1);
        if (monthLastDay <= expireDayNumber) {
            paymentDateObj.setDate(monthLastDay);
        } else {
            paymentDateObj.setDate(expireDayNumber);
        }

        setNextPaymentDate(extractDateStringFromTimestamp(paymentDateObj.toISOString()));
    }

    function handleChangeNextPaymentDate(event: React.ChangeEvent<HTMLInputElement>) {
        setNextPaymentDate(event.target.value);

        validateNextPaymentDate(expireDay, event.target.value);
    }

    function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>) {
        setFrequency(event.target.value);
    }

    function handleChangeExpireDay(event: React.ChangeEvent<HTMLInputElement>) {
        const expireDayNumber = Number(event.target.value);
        let expireDayValue = event.target.value;

        if (expireDayNumber <= 0) {
            expireDayValue = '';
        } else if (expireDayNumber > 31) {
            expireDayValue = '31';
        }

        setExpireDay(expireDayValue);

        validateNextPaymentDate(expireDayValue, nextPaymentDate);
    }


    function convertToFloat(value: any) {
        var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
        if (valueConverted.includes(".") && valueConverted.includes(",")) {
            valueConverted = valueConverted.replace(".", "").replace(",", ".");
        } else {
            valueConverted = valueConverted.replace(",", ".");
        }

        return parseFloat(valueConverted);
    }


    function handleChangeBeneficiaryType(value: string) {
        setBeneficiaryType(value);
        setBeneficiaryId('');
    }

    return (

        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <form
                className={'makeStyles-container-12'}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs">
                    <Tab eventKey="detalhes-despesa" title="Detalhes da Despesa">

                        <div className="row">

                            <div className="col-lg-3">
                                <TextField
                                    label="Nome da despesa*"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    error={expensesError}
                                    className={classes.error}
                                    value={name}
                                    onChange={(evt) => {
                                        setName(evt.target.value)
                                        setExpensesError(evt.target.value ? false : true)
                                    }}
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Conta bancária *"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={accountBank}
                                    hasError={accountBankError}
                                    onSelect={(option) => setAccountBank(String(option?.id ?? ''))}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!accountBank) return null;
                                        return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                                    }}
                                    onChangeTextField={(e) => setAccountBankError(e.target.value ? false : true)}
                                    renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Forma de Pagamento*"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu && classes.error
                                        },
                                    }}
                                    error={payMethodsError}
                                    margin="normal"
                                    size="small"
                                    variant="outlined"
                                    value={payment}
                                    onChange={(evt) => {
                                        setPayment(evt.target.value)
                                        setPayMethodsError(evt.target.value ? false : true)
                                    }}
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>

                                    {
                                        paymentOptions.map((payment, index) => (
                                            <MenuItem key={index + 1} value={payment.value}>
                                                {payment.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas *"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryId}
                                    hasError={accountPlanError}
                                    onSelect={(option) => {
                                        setDreSubCategoryId(option?.id ?? 0)
                                        setCategoryName(option?.name ?? '')
                                    }}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                    onChangeTextField={(e) => setAccountPlanError(e.target.value ? false : true)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2">
                                <TextField
                                    value={beneficiaryType}
                                    select
                                    label="Tipo Beneficiário"
                                    size="small"
                                    className="ml-0"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                                >
                                    <MenuItem key="0" value="supplier">
                                        Fornecedor
                                    </MenuItem>

                                    <MenuItem key="1" value="seller">
                                        Vendedor
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-4 d-flex align-items-center">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Beneficiário"
                                    getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                                    value={beneficiaryId}
                                    onSelect={(option) => setBeneficiaryId(option ? String(option.id) : '')}
                                    apiSearchHandler={beneficiaryType === 'supplier'
                                        ? ((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true }))
                                        : ((typedText) => SellerService.getSellersFiltered({ name: typedText }))}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!beneficiaryId) return null;

                                        const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(beneficiaryId));
                                        if(loadedBeneficiary) return loadedBeneficiary;

                                        return beneficiaryType === 'supplier' ? CustomerService.getCustomerById(beneficiaryId) : SellerService.getSellerById(beneficiaryId);
                                    }}
                                    renderAddButton={(
                                        beneficiaryType === 'supplier'
                                            ? (typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText)} />
                                            : undefined
                                    )}
                                />
                            </div>

                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor"
                                    startAdornment="R$"
                                    error={valueError}
                                    className={`${classes.error}`}
                                    value={amount}
                                    onChange={(evt) => {
                                        setAmount(evt.target.value)
                                        setValueError(evt.target.value ? false : true)
                                    }}
                                />
                            </div>
                        </div>

                    </Tab>

                    <Tab eventKey="detalhes-conta" title="Detalhes da Conta">

                        <div className="row">
                            <div className="col-lg-6">
                                <TextField
                                    label="Nº do documento"
                                    size="small"
                                    className="ml-0"
                                    margin="normal"
                                    variant="outlined"
                                    value={docNumber}
                                    onChange={(evt) => setDocNumber(evt.target.value)}
                                />
                            </div>

                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    label="Centro de Custos"
                                    getOptionLabel={(option: CenterCost) => option.name}
                                    value={centerCost}
                                    onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!centerCost) return null;
                                        return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-5">
                                <label htmlFor="formFile" className="mt-3">Anexo</label>
                                <input className="form-control" type="file" id="formFile" />
                                <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                            </div>
                        </div>

                        <TextField
                            label="Observações"
                            multiline
                            rows="2"
                            size="small"
                            className="ml-0"
                            margin="normal"
                            variant="outlined"
                            value={comments}
                            onChange={(evt) => setComments(evt.target.value)}
                        />

                    </Tab>
                    <Tab eventKey="recorrencia" title="Recorrência">
                        <div className="row">
                            <div className="col-lg-3">
                                <NumericFormat
                                    label="Dia de Vencimento*"
                                    className={`mx-2 ${classes.error}`}
                                    thousandSeparator={''}
                                    decimalScale={0}
                                    value={expireDay}
                                    onChange={handleChangeExpireDay}
                                    error={expireDayIsInvalid}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat
                                    label="Dias para gerar Fatura"
                                    className={` mx-2 ${''}`}
                                    thousandSeparator={''}
                                    decimalScale={0}
                                    value={daysBeforeExpire}
                                    onChange={(e) => setDaysBeforeExpire(e.target.value)}
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    value={frequency}
                                    onChange={handleChangeFrequency}
                                    size="small"
                                    select
                                    label="Periodicidade"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    defaultValue={frequencyEnum.MONTHLY}
                                >
                                    <MenuItem value={frequencyEnum.MONTHLY}>
                                        Mensal
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.BIMONTHLY}>
                                        Bimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.TRIMONTHLY}>
                                        Trimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.QUARTERLY}>
                                        Quadrimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.SEMIANNUAL}>
                                        Semestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.ANNUAL}>
                                        Anual
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-3">
                            <TextField
                                size="small"
                                type='date'
                                margin="normal"
                                label="Próximo Pagamento"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                className={` ${classes.textField} ${classes.error} `}
                                value={nextPaymentDate}
                                onChange={handleChangeNextPaymentDate}
                            />
                            </div>
                        </div>
                    </Tab>

                </Tabs>

                <div className='col-lg-6 mt-10 d-flex flex-row'>

                    <Button
                        type='submit'
                        className='mr-3'
                        variant="primary"
                        disabled={isSubmit}
                    >

                        {isSubmit ? <>

                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>

                        </> : <>

                            <span>
                                Salvar
                            </span>

                        </>}


                    </Button>

                    <Link href='/despesas-recorrentes' className='btn btn-secondary'>

                        Cancelar

                    </Link>

                </div>

            </form>
        </div>
    );
}
