import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
    Line
} from "@react-pdf/renderer";
import { usePdfStyles } from "../../hooks/pdfStyles";
import Table from "../../components/Pdf/Table";
import TableRow from "../../components/Pdf/TableRow";
import TableCell from "../../components/Pdf/TableCell";
import { formatCurrency } from "../../utils/formatCurrency";
import { BillsCashFlow } from "../../types/BillsCashFlow";

type PdfCashFlowProps = {
    company: any;
    bills: BillsCashFlow[];
    totalReceive: number;
    totalPay: number;
    accomplished: boolean;
    foreseen: boolean;
    totalBillsAccomplished: number;
    totalBillsForeseen: number;
}

export function PdfCashFlow({
    company,
    bills,
    totalReceive,
    totalPay,
    accomplished,
    foreseen,
    totalBillsAccomplished,
    totalBillsForeseen,
}: PdfCashFlowProps) {
    const styles = usePdfStyles();

    const today = new Date().toLocaleDateString();
    
    const rowStyle = styles.flexRow;
    const cellStyle = styles.flexCell;

    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4">
                <View style={styles.header}>
                    <View style={styles.photo}>
                        <Image source={{ uri: `${process.env.REACT_APP_API_URL}/${company?.logo ?? ''}`, method: "GET", headers: {}, body: "" }} style={styles.customerPhoto} />
                    </View>
                    <View style={styles.infoHeader}>
                        <View style={styles.titleHeader}>
                            <Text>Fluxo de Caixa</Text>
                        </View>
                        <Text style={styles.titleInfo}>{today}</Text>
                    </View>
                </View>

                <>
                    <Table>
                        <TableRow style={rowStyle}>
                            <TableCell key="date" style={cellStyle}>
                                DATA
                            </TableCell>
                            <TableCell key="name" style={cellStyle}>
                                LANÇAMENTO
                            </TableCell>
                            <TableCell key="bankAccountNameBank" style={cellStyle}>
                                BANCO
                            </TableCell>
                            <TableCell key="dreSubCategoryName" style={cellStyle}>
                                PLANO DE CONTAS
                            </TableCell>
                            <TableCell key="credit" style={cellStyle}>
                                CRÉDITO (R$)
                            </TableCell>
                            <TableCell key="debit" style={cellStyle}>
                                DÉBITO (R$)
                            </TableCell>
                            <TableCell key="balance" style={cellStyle}>
                                SALDO
                            </TableCell>
                            <TableCell key="status" style={cellStyle}>
                                SITUAÇÃO
                            </TableCell>
                        </TableRow>
                    </Table>

                    <Table style={{ paddingTop: 0, }}>
                        {
                            bills.map((bill, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        <View style={rowStyle}>
                                            <Text style={cellStyle}>
                                                {bill.date.split("-").reverse().join("/")}
                                            </Text>
                                            <Text style={cellStyle}>
                                                {bill.name}
                                            </Text>
                                            <Text style={cellStyle}>
                                                {bill.bankAccountNameBank ?? ''}
                                            </Text>
                                            <Text style={cellStyle}>
                                                {bill.dreSubCategoryName ?? ''}
                                            </Text>
                                            <Text style={{...cellStyle, color: 'green'}}>
                                                {bill.typeBills == "receive" ? new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                                    bill.status == "paid"
                                                        ? (bill.totalPaid) ? bill.totalPaid : bill.amount
                                                        : bill.amount
                                                ) : ""}
                                            </Text>
                                            <Text style={{...cellStyle, color: 'red'}}>
                                                {bill.typeBills == "pay" ? "-" + new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(
                                                    bill.status == "paid"
                                                        ? (bill.totalPaid) ? bill.totalPaid : bill.amount
                                                        : bill.amount
                                                ) : ""}
                                            </Text>
                                            <Text style={{...cellStyle, color: bill.balance > 0 ? 'green' : 'red'}}>
                                                {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bill.balance)}
                                            </Text>
                                            <Text style={cellStyle}>
                                                {index > 0 ? (
                                                    <>{bill.status == "paid" ? 'Realizado' : 'Previsto'}</>
                                                ) : <></>}
                                            </Text>
                                        </View>
                                    </React.Fragment>
                                )
                            })
                        }
                        <React.Fragment>
                            <View style={rowStyle}>
                                <Text style={cellStyle}>
                                    TOTAL
                                </Text>

                                <Text style={cellStyle}></Text>
                                <Text style={cellStyle}></Text>
                                <Text style={cellStyle}></Text>

                                <Text style={{...cellStyle, color: 'green'}}>
                                    {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalReceive)}
                                </Text>
                                <Text style={{...cellStyle, color: 'red'}}>
                                    {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalPay)}
                                </Text>
                                <Text style={{...cellStyle, color: bills[bills.length - 1]?.balance > 0 ? 'green' : 'red'}}>
                                    {new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bills[bills.length - 1]?.balance)}
                                </Text>

                                <Text style={cellStyle}></Text>
                            </View>
                        </React.Fragment>
                    </Table>
                </>

                <View style={styles.section}>
                    <Text style={styles.titleSection}></Text>

                    <View style={styles.rowPayment}>
                        <View style={styles.left}>

                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Quantidade Total de Lançamentos</Text>
                                </View>
                                <View style={styles.rightPayment}>
                                    <Text style={styles.titleInfo}>{bills.length - 1}</Text>
                                </View>
                            </View>
                            
                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Total de Receitas</Text>
                                </View>
                                <View style={{...styles.rightPayment, color: 'green'}}>
                                    <Text style={styles.titleInfo}>{formatCurrency(totalReceive)}</Text>
                                </View>
                            </View>

                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Total de Despesas</Text>
                                </View>
                                <View style={{...styles.rightPayment, color: 'red'}}>
                                    <Text style={styles.titleInfo}>{formatCurrency(totalPay)}</Text>
                                </View>
                            </View>

                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Saldo Lançamentos Realizados</Text>
                                </View>
                                <View style={{...styles.rightPayment, color: totalBillsAccomplished > 0 ? 'green' : 'red'}}>
                                    <Text style={styles.titleInfo}>{formatCurrency(totalBillsAccomplished)}</Text>
                                </View>
                            </View>

                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Saldo Lançamentos Previstos</Text>
                                </View>
                                <View style={{...styles.rightPayment, color: totalBillsForeseen > 0 ? 'green' : 'red'}}>
                                    <Text style={styles.titleInfo}>{formatCurrency(totalBillsForeseen)}</Text>
                                </View>
                            </View>

                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>Saldo Total</Text>
                                </View>
                                <View style={{...styles.rightPayment, color: bills[bills.length - 1]?.balance > 0 ? 'green' : 'red'}}>
                                    <Text style={styles.titleInfo}>{formatCurrency(bills[bills.length - 1]?.balance)}</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}