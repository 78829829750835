import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid,
    Checkbox,
    MenuItem,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab
} from "react-bootstrap";

import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import { Menu } from '../../types/Menu';
import api from '../../services/Api';
import { api as ApiUrl } from '../../services/ApiURL';
import { useHistory } from 'react-router';
import { Profile } from '../../types/Profile';
import { Autocomplete } from '@material-ui/lab';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { PartnersObj } from '../../types/Partners';
import PermissionMenuSelector from '../../components/PermissionMenuSelector';
import { SubscriptionPlan } from '../../types/SubscriptionPlan';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { sanitizeInput, SanitizeInputType } from '../../utils/sanitizeInput';
import { yesOrNo } from '../../types/yesOrNo';
import useCompany from '../../hooks/company';
import { useSelector } from 'react-redux';
import ModalLimit from '../../components/ModalLimit';
import logService from '../../services/LogService';
import CompanyService from '../../services/CompanyService';
import { formatCpfCnpjDynamic } from '../../utils/formatCpfCnpj';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { useCustomerPermissionHandler } from '../../hooks/customPermissionHandler';
import UserService from '../../services/UserService';
import { status, typeIndication, whoPay } from '../../types/Indications';
import { CompanyAccountant } from '../../types/CompanyAccountant';
import { CompanyFormAccountant } from '../../components/CompanyForm/CompanyFormAccountant';
import useAllUf from '../../hooks/uf';
import { addressSearch } from '../../utils/addressSearch';
import { cnpjSearch } from '../../utils/cnpjSearch';
import { PatternFormat } from '../../components/PatternFormat';
import useQueryParams from '../../hooks/queryParams';
import { PermissionMenu } from '../../types/PermissionMenu';
import PermissionMenuService from '../../services/PermissionMenuService';
import ModalSuccess from '../../components/ModalSuccess';
import { useStyles } from '../../hooks/styles';

type submenu = {
    id: number,
    name: string,
    menuId: number,
    checked: boolean,
    subMenu: submenu[]
}

type menuSystem = {
    id: number,
    menuId: number,
    name: string,
    checked: boolean,
    subMenu: submenu[]
}

type Branch = {
    checked: boolean;
    data: any;
    id: number;
}

export function NewUser() {
    const history = useHistory();

    const classes = useStyles();
    const { getQueryParam } = useQueryParams();
    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Usuário");

    const [activeTab, setActiveTab] = useState("details");
    const [canCreateUser, setCanCreateUser] = useState(true);
    const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
    const [showModalLimit, setShowModalLimit] = useState(false);

    const { user } = useSelector((state: any) => state.auth);
    const { companies } = useCompany({only: user.companyId, appendSubscriptionPlan: true});
    const [ company ] = companies;
    const { selectedCompany } = useCompanyBranch();

    /* Photo */
    const imgFileInput = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState("");

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [isAccountant, setIsAccountant] = useState(false);

    const [accountantCreatedFromIndication, setAccountantCreatedFromIndication] = useState(false);

    /* Permissoes */
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [profileId, setProfileId] = useState<number>();
    const { menus } = useMenu();
    const {
        permissionMenus,
        setPermissionMenus,
        handleChangePermission,
        menuSystem,
        setMenuSystem,
        handleChangePermissionFastAccess,
    } = useCustomerPermissionHandler();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const [canAccessMainCompany, setCanAccessMainCompany] = useState<yesOrNo>(yesOrNo.YES);
    const [userBranches, setUserBranches] = useState<Branch[]>([]);
    const [userCompany, setUserCompany] = useState<any>();

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [firstnameInvalid, setFirstnameInvalid] = useState(false);
    const [cellInvalid, setCellInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [loginInvalid, setLoginInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);

    //Dados adicionais de contador
    const [accountantCpf, setAccountantCpf] = useState("");
    const [accountantCnpj, setAccountantCnpj] = useState("");
    const [accountantCrc, setAccountantCrc] = useState("");
    const [accountantPhone, setAccountantPhone] = useState("");
    const [accountantZipCode, setAccountantZipCode] = useState("");
    const [accountantAddress, setAccountantAddress] = useState("");
    const [accountantNumber, setAccountantNumber] = useState("");
    const [accountantDistrict, setAccountantDistrict] = useState("");
    const [accountantComplement, setAccountantComplement] = useState("");
    const [accountantCity, setAccountantCity] = useState("");
    const [accountantState, setAccountantState] = useState("");
    const [accountantNameHasError, setAccountantNameHasError] = useState(false);
    const [accountantEmailHasError, setAccountantEmailHasError] = useState(false);
    const [accountantCellHasError, setAccountantCellHasError] = useState(false);
    const [accountantCpfHasError, setAccountantCpfHasError] = useState(false);
    const [accountantCnpjHasError, setAccountantCnpjHasError] = useState(false);
    const { allStates } = useAllUf();

    useEffect(() => {
        async function checkLimits() {
            const response = await api.get('companySubscriptionPlans/limits/byField?field=users');
            const limits = response.data;
            if(limits.max !== -1 && limits.current >= limits.max) {
                setCanCreateUser(false);
                setShowModalLimit(true);
                setModalLimitMsg(['Seu plano atual não permite o cadastro de novos usuários']);
            }
        }

        async function getMenusSystem() {
            const menus  = await api.get('/menus');

            var header = menus.data.filter((menuHeader: any) => menuHeader.menuId == null).sort((data1: any, data2: any) => {
                return data1.order - data2.order;
            });

            var menusArr: any = [];
            header.map(async(menuHeader: any, indexHeader: number) => {
                var moduleArr = {
                    id: menuHeader.id,
                    menuId: menuHeader.menuId,
                    name: menuHeader.name,
                    checked: false,
                    subMenu: []
                }

                var submenu1Data = menus.data.filter((submenu1: any) => submenu1.menuId == menuHeader.id).sort((data1: any, data2: any) => {
                    return data1.order - data2.order;
                });   
                
                var submenuArr: any = [];
                submenu1Data.map(async(submenu1Data: any, indexSubmenu1: number) => {
                    var submenu1Arr = {
                        id: submenu1Data.id,
                        menuId: submenu1Data.menuId,
                        name: submenu1Data.name,
                        checked: false,
                        subMenu: []
                    }

                    var submenu2Arr: any = [];
                    menus.data.filter((submenu2Data: any) => submenu2Data.menuId == submenu1Data.id).map(async(submenu2Data: any, indexSubmenu2: number) => {
                        submenu2Arr.push({
                            id: submenu2Data.id,
                            menuId: submenu2Data.menuId,
                            name: submenu2Data.name,
                            checked: false
                        })
                    });

                    submenu1Arr = {
                        ...submenu1Arr,
                        subMenu: submenu2Arr
                    }

                    submenuArr.push(submenu1Arr);
                });


                moduleArr = {
                    ...moduleArr,
                    subMenu: submenuArr
                }
                menusArr.push(moduleArr);
            });

            setMenuSystem(menusArr);
        }

        checkLimits();
        getMenusSystem();
    }, []);

    useEffect(() => {
        async function loadAccountantData() {
            const accountantId = Number(getQueryParam('accountantId') ?? '0');

            if (!accountantId) {
                return;
            }

            const accountant = await UserService.getUserById(accountantId);

            if (!accountant) {
                return;
            }

            setAccountantCreatedFromIndication(true);

            setFirstname(accountant.firstname ?? '');
            setLastname(accountant.lastname ?? '');
            setEmail(accountant.email ?? '');
            setCell(accountant.cell ?? '');
            setIsAccountant(accountant.isAccountant == "y" ? true : false);
            setProfileId(accountant.userProfileId ?? undefined);
            setLogin(accountant.login ?? '');

            if(accountant.photo) {
                setPhoto(`${api}/${accountant.photo}`);
            }

            setCanAccessMainCompany(accountant.canAccessMainCompany);

            setUserCompany(await CompanyService.getCompanyById(accountant.companyId));
        }

        loadAccountantData();
    }, [getQueryParam]);

    useEffect(() => {
        async function getProfiles() {
            const {data} = await api.get<Profile[]>('userProfiles');
            let administrator: Profile[] = [{
                id: 0,
                name: 'Administrador',
                permissions: company ? company.permissions : '[]',
                extraMenuPermissions: JSON.stringify(PermissionMenuService.getMenuExtraPermissionsFromMenuPermissions(company ? company.permissions : '[]')),
                visible: yesOrNo.YES,
                createdDate: new Date(),
                updatedDate: new Date(),
            }];

            let accountant: Profile[] = [{
                id: 0.1,
                name: 'Contador',
                permissions: company ? company.permissions : '[]',
                extraMenuPermissions: '[]',
                visible: yesOrNo.YES,
                createdDate: new Date(),
                updatedDate: new Date(),
            }];

            var allProfiles = [...administrator, ...accountant, ...data];
            setProfiles(allProfiles);
        }

        async function getBranches() {
            const allBranches = await CompanyService.getCompanyBranches(user.companyId);

            setUserBranches(
                allBranches.map((branch: any) => {
                    return {
                        id: branch.id,
                        checked: false,
                        data: branch,
                    }
                })
            );
        }

        getBranches();
        getProfiles();
    }, [company]);

    useEffect(() => {
        if(!menus.length) return;
        if(!user) return;
        if(!company) return;

        const companyPermissions: Array<number> = JSON.parse(company.permissions);
        const filtered = PermissionMenuService.filterCompanyPermissionMenu(menus as PermissionMenu[], companyPermissions);
        setPermissionMenus(filtered);
    }, [menus, user, company]);
  
    const handleCloseModalLimit = useCallback(() => {
        if(!canCreateUser) {
            history.push("/usuarios");
        }
    }, [canCreateUser]);

    async function inputsVerify(existAccountant: any): Promise<boolean> {
        setFirstnameInvalid(false);
        setCellInvalid(false);
        setEmailInvalid(false);
        setLoginInvalid(false);
        setPasswordInvalid(false);

        if (!firstname) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setFirstnameInvalid(true);
            return false
        }
        if (!cell) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o celular corretamente")
            setShowModalError(true);
            setCellInvalid(true);
            return false
        }

        // const cellExists = !existAccountant ? await UserService.verifyCellExists(cell) : false;
        // if(cellExists) {
        //     setActiveTab("details");
        //     setIsSubmit(false)
        //     setMsgError("Celular já existe");
        //     setShowModalError(true);
        //     setCellInvalid(true);
        //     return false;
        // }

        if (!email) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o e-mail corretamente")
            setShowModalError(true);
            setEmailInvalid(true);
            return false
        }

        const emailExists = !existAccountant ? await UserService.verifyEmailExists(email) : false;
        if(emailExists) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("E-mail já existe");
            setShowModalError(true);
            setEmailInvalid(true);
            return false;
        }

        // if(!isAccountant && !login) {
        //     setActiveTab("details");
        //     setIsSubmit(false)
        //     setMsgError("Preencha o Login corretamente")
        //     setShowModalError(true);
        //     setLoginInvalid(true);
        //     return false;
        // }


        // const loginExists = await UserService.verifyLoginExists(isAccountant ? email : login, existAccountant?.id);
        // if(loginExists) {
        //     setActiveTab("details");
        //     setIsSubmit(false)
        //     setMsgError("O login digitado não pode ser usado, informe um login diferente");
        //     setShowModalError(true);
        //     setLoginInvalid(true);
        //     return false;
        // }
        if(!isAccountant && !password) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha a Senha corretamente")
            setShowModalError(true);
            setPasswordInvalid(true);
            return false;
        }

        return true;
    }

    function verifyAccountantAdditionalInfo() {
        if (accountantCpf && sanitizeInput(SanitizeInputType.NUMERIC, accountantCpf).length !== 11) {
            setAccountantCpfHasError(true);
            setActiveTab("details");
            setMsgError("CPF do Contador é inválido");
            return false;
        }

        if (accountantCnpj && sanitizeInput(SanitizeInputType.NUMERIC, accountantCnpj).length !== 14) {
            setAccountantCnpjHasError(true);
            setActiveTab("details");
            setMsgError("CNPJ do Contador é inválido");
            return false;
        }

        return true;
    }

    function handleUploadClick() {
        if(imgFileInput && imgFileInput.current) {
            imgFileInput.current.click();
        }
    }

    const handleChangeProfile = useCallback((event: React.ChangeEvent<{}>, profile: Profile | null) => {
        setProfileId(profile?.id ?? undefined);
        setIsAccountant(profile && profile?.name.toLowerCase() == "contador" ? true : false)

        if(profile) {
            const aux = permissionMenus.map(menu => ({ ...menu }));
            let permissions = PermissionMenuService.mapPermissionsChecked(JSON.parse(profile.permissions), aux);
            permissions = PermissionMenuService.mapMenuExtraPermissionsChecked(JSON.parse(profile.extraMenuPermissions ?? '[]'), permissions);
            setPermissionMenus([...permissions]);
        }
    }, [permissionMenus]);

    function handleChangeImgFile(event: any) {
        const input: HTMLInputElement = event.target;
        if(!input.files || input.files.length === 0) return;
        const fileUrl = window.URL.createObjectURL(input.files[0]);
        setPhoto(fileUrl);
    }

    function mapMenusSystem(menus: any): Array<number> {
        let mapped = menus.filter((menuChecked: any) => menuChecked.checked).map((menu: any) => menu.id);

        for(let i = 0; i < menus.length; i++) {
            if(menus[i].subMenu?.length) {
                mapped = mapped.concat(mapMenusSystem(menus[i].subMenu as submenu[]));
            }
        }

        return mapped;
    }

    function handleCheckMainCompany(checked: boolean) {
        setCanAccessMainCompany(checked ? yesOrNo.YES : yesOrNo.NO);
    }

    function handleCheckBranch(checked: boolean, branchId:  number) {
        setUserBranches((prevState) => {
            return prevState.map((branch) => {
                if(branch.id !== branchId) return branch;

                return {
                    ...branch,
                    checked,
                }
            })
        });
    }

    async function completeAccountant(emailUser: string) {
        // const existUserEmail = await api.post("/users/find-email", {email: emailUser});

        // if (existUserEmail.data && existUserEmail.data.isAccountant == "y") {
        //     setFirstname(existUserEmail.data.firstname);
        //     setLastname(existUserEmail.data.lastname);
        //     setCell(existUserEmail.data.cell);
        // }
    }

    async function accountantVerify(accountant: any) {
        // if (accountant) {
        //     const {data} = await api.get(`/users/${accountant.id}`);
        //     var branchesCompany = JSON.parse(data.branchesIds);

        //     if (branchesCompany.includes(selectedCompany.id)) {
        //         setIsSubmit(false)
        //         setMsgError("Este contador já está na sua empresa!");
        //         setShowModalError(true);
        //         return false;
        //     }
        // }

        return true;
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        const existAccountantResponse = await api.post("/users/find-email", {email: email});
        const existAccountant = existAccountantResponse.data.isAccountant == "y" ? existAccountantResponse.data : null;

        if (!(await inputsVerify(existAccountant))) return;

        if (!verifyAccountantAdditionalInfo()) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = PermissionMenuService.filterPermissionMenu(aux);
        const permissions = PermissionMenuService.mapPermissionMenu(menusFiltered);
        const extraPermissions = PermissionMenuService.mapMenuExtraPermissions(menusFiltered);
        const fastAccess = mapMenusSystem(menuSystem);
        const filteredBranches = userBranches.filter((branch) => branch.checked);
        const branchesIds = filteredBranches.map((branch) => branch.id);

        if (!(await accountantVerify(existAccountant))) return;

        try {
            let accountantCompanies: any[] = [];
            if (!branchesIds.includes(selectedCompany.id)) {
                accountantCompanies = [
                    ...branchesIds,
                    selectedCompany.id
                ];
            } else {
                accountantCompanies = [
                    ...branchesIds
                ];
            }

            if (isAccountant) { // Cadastro de usuário contador
                const createdUser = await UserService.createAccountantUser({
                    existAccountant,
                    accountantCreatedFromIndication,
                    accountantNewCompaniesIds: accountantCompanies,
                    firstname,
                    lastname,
                    cell,
                    email,
                    canAccessMainCompany,
                    photoFile: imgFileInput.current && imgFileInput.current.files ? (imgFileInput.current.files[0] || null) : null,
                    currentCompany: selectedCompany,
                    permissionsForCurrentCompany: permissions,
                    fastAccessForCurrentCompany: fastAccess,
                });

                // Atualizar dados de contador no cadastro da empresa
                const response = await api.get<CompanyAccountant[]>(`companies/${selectedCompany.id}/accountants`);
                const companyAccountantsInfo = response.data;

                let currentAccountantInfo = companyAccountantsInfo.find((info) => Number(info.userId) === createdUser.id);
                
                if (!currentAccountantInfo) {
                    currentAccountantInfo = companyAccountantsInfo.find((info) => !info.userId && Number(info.email) === createdUser.email);
                }

                await CompanyService.saveCompanyAccountant({
                    id: currentAccountantInfo?.id ?? '',
                    companyId: selectedCompany.id,
                    userId: createdUser.id,
                    name: `${firstname} ${lastname}`,
                    cpf: accountantCpf,
                    cnpj: accountantCnpj,
                    crc: accountantCrc,
                    email,
                    cell,
                    phone: accountantPhone,
                    zipCode: accountantZipCode,
                    address: accountantAddress,
                    district: accountantDistrict,
                    city: accountantCity,
                    state: accountantState,
                    number: accountantNumber,
                    complement: accountantComplement,
                }, selectedCompany);

            } else { // criação normal de usuário
                const raw = {
                    permissions: JSON.stringify(permissions),
                    extraMenuPermissions: JSON.stringify(extraPermissions),
                    fastAccess: JSON.stringify(fastAccess),
                    userProfileId: profileId ?? null,
                    isAdmin: profileId === 0 ? yesOrNo.YES : yesOrNo.NO,
                    firstname: firstname ? firstname : null,
                    lastname: lastname ? lastname : null,
                    cell: cell ? cell : null,
                    email: email ? email : null,
                    login: !isAccountant ? login : null,
                    password: !isAccountant ? password : null,
                    canAccessMainCompany,
                    branchesIds: JSON.stringify(branchesIds),
                };

                const response = await api.post(`users`, raw);
                
                logService.logRegister({
                    itemId: response.data.id,
                    module: 'Usuários',
                    itemName: `${response.data.firstname} ${response.data.lastname ?? ''}`,
                });
    
                try {
                    await uploadPhoto(Number(response.data.id));
                } catch (error) {
                    console.log('upload', error);
                }
            }

            setMsgSuccess('Usuário criado com sucesso!');
            setShowModalSuccess(true);
        } catch (error) {
            console.log('error', error);
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalError(true);
        }

        setIsSubmit(false);
    }

    async function uploadPhoto(id: number): Promise<string|boolean> {
        if(imgFileInput && imgFileInput.current) {
            if(!imgFileInput.current.files || imgFileInput.current.files.length === 0) {
                return false;
            }
            const imageFile = imgFileInput.current.files[0];
            if(!imageFile) {
                return false;
            }

            var formFile = new FormData();
            formFile.append('image', imageFile, imageFile.name);
            
            const response = await api.post('/image', formFile);
            const raw = {
                photo: response.data
            }

            await api.put(`users/${id}`, raw);
            return response.data;
        }

        return false;
    }

    async function CheckCnpj(value: string) {
        if (value) {
            const searchResponse = await cnpjSearch(value ?? '');

            if (searchResponse.status === 'ERROR') {
                return;
            }

            setFirstname(searchResponse.nome);
            setEmail(searchResponse.email);
            setAccountantPhone(searchResponse.telefone)

            setAccountantZipCode(searchResponse.cep ?? '');
            if(searchResponse.cep) {
                infoAddress(searchResponse.cep ?? '');
            }
        }
    }

    async function infoAddress(zipcode: string) {        
        const address = await addressSearch(zipcode ?? '');

        setAccountantAddress(address.logradouro);
        setAccountantDistrict(address.bairro);
        setAccountantCity(address.localidade);
        setAccountantState(address.uf);
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalLimit
               showModalLimit={showModalLimit}
               setShowModalLimit={setShowModalLimit}
               messages={modalLimitMsg}
               onHide={handleCloseModalLimit}
            />
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/usuarios"
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">
                    <div className="col-lg-2 text-center">
                        <img
                            src={photo ? photo : toAbsoluteUrl("/media/users/300_21.jpg")}
                            className="img-fluid d-block"
                            alt=""
                        />    
                         <Button
                            className="mt-3 mb-2"
                            variant="primary"
                            onClick={handleUploadClick}
                        >
                            Upload
                        </Button>
                        <input
                            type="file"
                            className="d-none"
                            ref={imgFileInput}
                            onChange={(e) => handleChangeImgFile(e)}
                            accept="image/png, image/jpeg"
                        />
                        {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
                    </div>
                    <div className="col-4">
                        <TextField
                            label="Nome*"
                            size="small"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            error={firstnameInvalid}
                            value={firstname}
                            onChange={(e) => {
                                setFirstname(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label="Sobrenome"
                            size="small"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            value={lastname}
                            onChange={(e) => {
                                setLastname(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col d-flex align-items-start justify-content-end">
                        <Button
                            type='submit'
                            className='mt-4'
                            variant="primary"
                            disabled={isSubmit}
                        >
                            {isSubmit ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : <>
                                <span>
                                    Salvar
                                </span>
                            </>}
                        </Button>
                    </div>
                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="edituser-form-tabs">
                    <Tab eventKey="details" title="Dados">
                        <div className="row">
                            <div className="col-lg-4">
                                <TextField
                                    size="small"
                                    label="E-mail*"
                                    className={classes.error}
                                    margin="normal"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailInvalid(e.target.value ? false : true)
                                    }}
                                    onBlur={(e) => {
                                        if (isAccountant) {
                                            completeAccountant(e.target.value);
                                        }
                                    }}
                                    error={emailInvalid}
                                />
                            </div>
                            <div className="col-lg-4">
                                <PatternFormat
                                    label="Celular*"
                                    className={classes.error}
                                    format="(##) #####-####"
                                    mask="_"
                                    value={cell}
                                    onChange={(e) => {
                                        setCell(e.target.value)
                                        setCellInvalid(e.target.value ? false : true)
                                    }}
                                    error={cellInvalid}
                                />
                            </div>
                        </div>

                        {
                            !isAccountant
                            ?
                            <div className="row">
                                {/* <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Login*"
                                        margin="normal"
                                        variant="outlined"
                                        error={loginInvalid}
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                </div> */}
                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Senha*"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        value={password}
                                        autoComplete='off'
                                        error={passwordInvalid}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            : (
                                <>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <h4>Informações Adicionais do Contador</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <CompanyFormAccountant
                                                accountantCpf={accountantCpf}
                                                onChangeAccountantCpf={(value) => setAccountantCpf(value)}
                                                accountantCnpj={accountantCnpj}
                                                onChangeAccountantCnpj={(value) => setAccountantCnpj(value)}
                                                accountantCrc={accountantCrc}
                                                onChangeAccountantCrc={(value) => setAccountantCrc(value)}
                                                accountantPhone={accountantPhone}
                                                onChangeAccountantPhone={(value) => setAccountantPhone(value)}
                                                accountantZipCode={accountantZipCode}
                                                onChangeAccountantZipCode={(value) => setAccountantZipCode(value)}
                                                accountantAddress={accountantAddress}
                                                onChangeAccountantAddress={(value) => setAccountantAddress(value)}
                                                accountantNumber={accountantNumber}
                                                onChangeAccountantNumber={(value) => setAccountantNumber(value)}
                                                accountantDistrict={accountantDistrict}
                                                onChangeAccountantDistrict={(value) => setAccountantDistrict(value)}
                                                accountantComplement={accountantComplement}
                                                onChangeAccountantComplement={(value) => setAccountantComplement(value)}
                                                accountantCity={accountantCity}
                                                onChangeAccountantCity={(value) => setAccountantCity(value)}
                                                accountantState={accountantState}
                                                onChangeAccountantState={(value) => setAccountantState(value)}
                                                accountantNameHasError={accountantNameHasError}
                                                accountantEmailHasError={accountantEmailHasError}
                                                accountantCellHasError={accountantCellHasError}
                                                accountantCpfHasError={accountantCpfHasError}
                                                accountantCnpjHasError={accountantCnpjHasError}
                                                allStates={allStates}
                                                CheckCnpj={CheckCnpj}
                                                infoAddress={infoAddress}
                                                currentIndex={0}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </Tab>

                    <Tab eventKey="access" title="Acesso">
                        <div className="row">
                            <div className="col-lg-4">
                                <Autocomplete
                                    key={profileId}
                                    size="small"
                                    disableClearable={false}
                                    noOptionsText="Sem opções"
                                    options={profiles}
                                    getOptionLabel={({name}) => name}
                                    value={profiles.find(profile => profile.id === profileId) ?? null}
                                    onChange={(event, newInputValue) => handleChangeProfile(event, newInputValue)}
                                    renderInput={(params) =>
                                        <TextField
                                            size="small"
                                            { ...params }
                                            label="Perfil"
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <PermissionMenuSelector
                                    permissionMenus={permissionMenus}
                                    setPermissionMenus={setPermissionMenus}
                                    customChangePermissionHandler={handleChangePermission}
                                />
                            </div>
                        </div>
                    </Tab>

                    {!isAccountant && (
                        <Tab eventKey="fastAccess" title="Acesso rápido (Dashboard)">
                            <div className="row">
                                <div className="col-12">
                                    <Tabs defaultActiveKey="1" id="permissionTabs">
                                        {menuSystem.map((menu, i) => (
                                            <Tab key={i} eventKey={menu.id} title={menu.name}>
                                                <div className="row" key={`${i}`}>
                                                    <div className="col-12 d-flex align-items-center">
                                                        <Checkbox
                                                            checked={menu.checked ?? false}
                                                            inputProps={{
                                                            'aria-label': 'success checkbox',
                                                            }}
                                                            onChange={(e, checked) => handleChangePermissionFastAccess(checked, [i])}
                                                        />
                                                        <strong>Menu Ativo</strong>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row ml-2">
                                                            {menu.subMenu.length > 0 && menu.subMenu.map((submenu1: submenu, j) => (
                                                                <div className={submenu1.subMenu.length ? 'col-12' : 'col-lg-3'} key={`${i}-${j}`}>
                                                                    <Checkbox
                                                                        checked={submenu1.checked ?? false}
                                                                        inputProps={{
                                                                        'aria-label': 'success checkbox',
                                                                        }}
                                                                        onChange={(e, checked) => handleChangePermissionFastAccess(checked, [i, j])}
                                                                    />
                                                                    <span>{submenu1.name}</span>
                                                                    <div className="row ml-2">
                                                                        {submenu1.subMenu.map((submenu2: submenu, k) => (
                                                                            <div className="col-lg-3" key={`${i}-${j}-${k}`}>
                                                                                <Checkbox
                                                                                    checked={submenu2.checked ?? false}
                                                                                    inputProps={{
                                                                                    'aria-label': 'success checkbox',
                                                                                    }}
                                                                                    onChange={(e, checked) => handleChangePermissionFastAccess(checked, [i, j, k])}
                                                                                />
                                                                                <span>{submenu2.name}</span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        </Tab>
                    )}

                    {!!company && CompanyService.companyCanHaveBranches(company) && CompanyService.isMainCompany(selectedCompany) && (
                        <Tab eventKey="branches" title="Filiais">
                            <div className="row">
                                <div className="col">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Acesso</TableCell>
                                                <TableCell>Nome</TableCell>
                                                <TableCell>CPF/CNPJ</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e, checked) => handleCheckMainCompany(checked)}
                                                        checked={canAccessMainCompany === 'y'}
                                                    />
                                                </TableCell>
                                                <TableCell>Matriz / {CompanyService.getCorporateName(company)}</TableCell>
                                                <TableCell>{formatCpfCnpjDynamic(CompanyService.getCpfCnpj(company))}</TableCell>
                                            </TableRow>
                                            {userBranches.map((branch) => (
                                                <TableRow key={branch.data.id}>
                                                    <TableCell>
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(e, checked) => handleCheckBranch(checked, branch.id)}
                                                            checked={branch.checked}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{CompanyService.getCorporateName(branch.data)}</TableCell>
                                                    <TableCell>{formatCpfCnpjDynamic(CompanyService.getCpfCnpj(branch.data))}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </Tab>
                    )}
                </Tabs>
            </form>
        </div>
    );
}