import React, { useState, useLayoutEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import api from '../../services/Api';
import {
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCpfCnpj } from '../../utils/formatCpfCnpj';
import { Search } from '../../components/Search';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { BsVariant } from '../../types/BsVariant';
import { Seller } from '../../types/Seller';
import { yesOrNo } from '../../types/yesOrNo';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'Nº' },
    { reference: 'name', value: 'Nome' },
    { reference: 'cpf', value: 'CPF/CNPJ' },
    { reference: 'commission', value: 'Comissão' },
    { reference: 'situation', value: 'Situação' },
];

export function ListSellers() {
    const { user } = useSelector((state: any) => state.auth);

    const [sellers, setSellers] = useState<Seller[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalSellers, setCountTotalSellers] = useState(0);

    const [sellersFiltered, setSellersFiltered] = useState<Seller[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [sellers]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: Seller[], count: number }>("/sellers", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setSellers(rows);
        setSellersFiltered(rows);
        setCountTotalSellers(count);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = sellersFiltered;

        aux.forEach((seller) => {
            let name = seller.name;
            let cpf = formatCpfCnpj("CPF", seller.cpf);
            const isLegal = seller.typePeople === "legal";

            if (isLegal) {
                name = seller.corporateName;
                cpf = formatCpfCnpj("CNPJ", seller.cnpj);
            }

            const situation = seller.situation === "active" ? "Ativo" : "Inativo";

            let commission = "0";

            if (seller.sellerCommission !== null && seller.sellerCommission !== "") {
                commission = seller.sellerCommission;
            }

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(seller.id), id: true },
                { for: "name", value: name },
                { for: "cpf", value: cpf },
                { for: "commission", value: `${commission}%` },
                { for: "situation", value: situation },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [sellersFiltered]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = sellers.filter((seller) => seller.id !== Number(id));

            await api.delete(`sellers/${id}`);

            setSellers([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [sellers]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
        };

        reloadData();
    }, [sellers, searchQuery]);

    const consignmentButtonShowCondition = useCallback((id: string) => {
        const seller = sellers.find((row) => row.id === Number(id));

        if (!seller) return false;

        return seller.isConsignment === yesOrNo.YES && !!seller.userId;
    }, [sellers]);

    const handleClickConsignment = useCallback((id: string) => {
        const seller = sellers.find((row) => row.id === Number(id));

        if (!seller) return;

        pushHistory(`${pathname}/${seller.id}/consignacoes`);
    }, [sellers]);

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Adicionar vendedor
                            </Button>
                            : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    bodyData={bodyData}
                    headData={headData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalSellers}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    customButtons={[
                        {
                            class: 'btn-light-info',
                            content: (<i className='p-0 flaticon-coins'></i>),
                            variant: BsVariant.INFO,
                            popup: "Solicitações de Consignação",
                            onClick: handleClickConsignment,
                            showCondition: consignmentButtonShowCondition,
                        },
                    ]}
                />
            </div>
        </div>
    );
}