import React from 'react';
import { useSubheader } from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListDre } from '../pages/Dre/List-Dre';
// import { NewCarriers } from '../pages/New-Carriers';
// import { EditCarriers } from '../pages/Edit-Carriers';

export function Dre() {

    return  (
        <Switch>
            {/* <Route path="/transportadoras/adicionar" component={NewCarriers}/>
            <Route path="/transportadoras/:id" component={EditCarriers}/> */}
            <Route path="/dre" component={ListDre}/>
        </Switch>
    );
}