import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditUserProfile } from '../pages/UserProfiles/Edit-UserProfile';
import { ListUserProfiles } from '../pages/UserProfiles/List-UserProfiles';
import { NewUserProfile } from '../pages/UserProfiles/New-UserProfile';

export function UserProfile() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Perfis de Usuário");
    
    return (
        <Switch>
            <Route path="/perfis-usuario/adicionar" component={NewUserProfile}/>
            <Route path="/perfis-usuario/:id" component={EditUserProfile}/>
            <Route path="/perfis-usuario" component={ListUserProfiles}/>
        </Switch>
    );
}