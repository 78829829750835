import api from "../services/Api";

export async function getActivityById(id: string | number){
    const foundActivityResponse = await api.get(`companyNfseActivities/${id}`);

    const activityName = foundActivityResponse.data.description;
    const activityServiceCode = foundActivityResponse.data.serviceCode;
    const activityTributeCode = foundActivityResponse.data.tributeCode;

    const activityFull = `${activityServiceCode} - ${activityTributeCode} - ${activityName}`;

    return activityFull;

}