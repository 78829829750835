import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { formatCurrency } from "../../../utils/formatCurrency";

type BillsTableParams = {
    bills: any[];
};

export function BillsTable({ bills }: BillsTableParams) {   
    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = useCallback((next: number) => {
        setPages(next);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setRowsPerPage(value);
    }, []);

    return (
        <>
            <Table className="border ml-3 mt-0">
                <TableHead>
                    <TableRow>
                        <TableCell>Nº</TableCell>
                        <TableCell>Data de Emissão</TableCell>
                        <TableCell>Data de Vencimento</TableCell>
                        <TableCell>Dias Vencidos</TableCell>
                        <TableCell>Conta Bancária</TableCell>
                        <TableCell>Cliente/Fornecedor</TableCell>
                        <TableCell>Plano de Contas</TableCell>
                        <TableCell>Valor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { bills
                    .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                    .map((bill, index) => {
                        const today      = new Date();
                        const dueDate    = new Date(bill.dueDate);
                        const timeDiff   = Math.abs(dueDate.getTime() - today.getTime());
                        const diffInDay  = Math.ceil(timeDiff / (1000 * 3600 * 24)) // calculo para converter em dias
                        const customerName = bill.sellerEntity ? bill.sellerEntity.name : bill.customerEntity ? bill.customerEntity.name : '-';
                        const dateProp = bill.typeBill === 'toPay' ?  bill.issueDate :  bill.issuanceDate;
                        const issueDate = dateProp ? dateProp.split("-").reverse().join("/") : '-';

                        return (
                            <TableRow key={ index }>
                                <TableCell>{ bill.id }</TableCell>
                                <TableCell>{ issueDate }</TableCell>
                                <TableCell>{ bill.dueDate.split("-").reverse().join("/") }</TableCell>
                                <TableCell>{ diffInDay }</TableCell>
                                <TableCell>{ bill.bankAccountEntity ? bill.bankAccountEntity.nameBank : '-' }</TableCell>
                                <TableCell>{ customerName }</TableCell>
                                <TableCell>{ bill.dreSubCategoryEntity ? bill.dreSubCategoryEntity.name : '-' }</TableCell>
                                <TableCell>{ formatCurrency(bill.amount) }</TableCell>
                            </TableRow>
                        )
                    }) }
                </TableBody>
            </Table>
            <TablePagination
                labelRowsPerPage="Linhas por página"
                page={pages}
                component="div"
                count={bills.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                backIconButtonProps={{
                    'aria-label': 'Página Anterior',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Próxima Página',
                }}
                onChangePage={(_, next) => handleChangePage(next)}
                onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
            />
        </>
    );
}