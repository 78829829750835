import React, { useState, useEffect } from 'react';

import api from "../services/Api"
import axios from "axios";

import { useSubheader } from "../../_metronic/layout";

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Link,
    Grid
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { ListProducts } from './List-Products';
import logService from '../services/LogService';

import '../style.css';
import ApiResourceSelect from '../components/ApiResourceSelect';
import ProductService from '../services/ProductService';
import { ApiResourceSelectAddButton } from '../components/ApiResourceSelectAddButton';
import { NewProductModal } from '../components/Product/NewProductModal';
import useNewProductForm from '../hooks/newProductForm';
import { Product } from '../types/Product';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
    typeList: string,
    value: string,
    status: string,
}

type products = {
    id: number,
    name: string,
}

export function NewPriceList() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Lista de Preços");

    const [nameInvalid, setNameInvalid] = useState(false);
    const [typeInvalid, setTypeInvalid] = useState(false);
    const [valueInvalid, setValueInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");

    const [listName, setListName] = useState("");
    const [typeList, setTypeList] = useState("");
    const [valueType, setValueType] = useState("");


    const [productSelected, setProductSelected] = useState<products>({
        id: 0,
        name: ""
    });
    const [listProduct, setListProduct] = useState({
        list: [
            {
                productId: 0,
                productName: "",
                type: "",
                value: ""
            }
        ]
    });

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            status: "1"
        }
    });

    // Modal New Product
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = (createdProduct: Product) => {
        setProductSelected({
            id: createdProduct.id,
            name: createdProduct.name
        });
    };
    
    const handleClickAddProduct = (typedText: string) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setShowModalNewProduct(true);
    };

    function addProductToList() {
        var listProducts = listProduct.list;

        if (listProducts.length === 1 && listProducts[0].productId === 0) {
            listProducts[0] = {
                productId: productSelected.id,
                productName: productSelected.name,
                type: "",
                value: ""
            }
            removeProductSelected();

        } else if (productSelected.id !== 0) {
            listProducts.push({
                productId: productSelected.id,
                productName: productSelected.name,
                type: "",
                value: ""
            });
            removeProductSelected();
        }

        setListProduct({ list: listProducts });
    }

    function removeProductSelected() {
        setProductSelected({
            id: 0,
            name: ""
        })
    }

    function deleteProductList(index: number) {
        var listProducts = listProduct.list;

        listProducts.splice(index, 1);

        setListProduct({ list: listProducts });
    }

    function clearListProducts() {
        setListProduct({ list: [] });
    }

    function changeProductList(index: number, value: string, typeInput: string) {
        var listProducts = listProduct.list;

        if (typeInput === "type") {
            listProducts[index].type = value;

        } else {
            listProducts[index].value = value;
        }

        setListProduct({ list: listProducts });
    }


    function inputsVerify(): boolean {

        if (!listName) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da lista corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        if (!typeList) {
            setIsSubmit(false)
            setMsgError("Selecione o tipo corretamente")
            setShowModalExist(true);
            setTypeInvalid(true);

            return false

        }

        if (!valueType && typeList !== '3') {
            setIsSubmit(false)
            setMsgError("Selecione o valor correspondente")
            setShowModalExist(true);
            setValueInvalid(true);

            return false

        }


        if (listProduct.list.length) {

            if (listProduct.list[0].productId == 0) {

                setIsSubmit(false)
                setMsgError("Adicione pelo menos um produto a lista")
                setShowModalExist(true);

                return false

            } else {

                for (let i = 0; i < listProduct.list.length; i++) {
                    if (listProduct.list[i].type == "") {

                        setIsSubmit(false)
                        setMsgError("Preencha o tipo do produto corretamente")
                        setShowModalExist(true);

                        return false
                    } else if (listProduct.list[i].value == "") {

                        setIsSubmit(false)
                        setMsgError("Preencha o valor do produto corretamente")
                        setShowModalExist(true);

                        return false
                    }

                }

            }

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return
        
        var raw = JSON.stringify({
            "name": listName,
            "priceListType": typeList,
            "value": (!valueType ? null : parseFloat(valueType.replaceAll(".", "").replace(",", "."))),
            "status": data.status,
            "products": JSON.stringify(listProduct.list)
        });

        const createResponse = await api.post("/priceList", raw);

        logService.logRegister({
            itemId: createResponse.data.id,
            module: 'Lista de Preços',
            itemName: createResponse.data.name,
        });

        setShowModalSuccess(true);
        setIsSubmit(false);
    }

    async function apiSearchProducts(typedText: string) {
        const foundProducts = await ProductService.getAllProductsFiltered({ name: typedText });

        const listProductIds = listProduct.list.map(listItem => listItem.productId);

        return foundProducts.filter((prod) => !listProductIds.includes(prod.id));
    }

    return (
        <div className="row card card-body newProductWrapper">
            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
            />

            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/lista-de-precos";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/lista-de-precos";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">                
                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome da Lista*"
                            margin="normal"
                            variant="outlined"
                            value={listName}
                            onChange={(e) => {
                                setListName(e.target.value)
                                setNameInvalid(e.target.value ? false : true)
                            }}
                            error={nameInvalid}

                        />
                    </Grid>

                    <Grid item lg={3}>
                        <TextField
                            size="small"
                            className={classes.error}
                            select
                            label="Tipo*"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={typeList}
                            onChange={(e) => {
                                setTypeList(e.target.value)
                                setTypeInvalid(e.target.value ? false : true)
                            }}
                            error={typeInvalid}

                        >
                            <MenuItem key="0" value="" onClick={() => clearListProducts()}>
                                Selecione
                            </MenuItem>

                            <MenuItem key="1" value="1" onClick={() => clearListProducts()}>
                                Acréscimo em todos os produtos
                            </MenuItem>

                            <MenuItem key="2" value="2" onClick={() => clearListProducts()}>
                                Desconto em todos os produtos
                            </MenuItem>

                            <MenuItem key="3" value="3">
                                Lista dinâmica
                            </MenuItem>
                        </TextField>
                    </Grid>

                    {typeList === "1" || typeList === "2"
                        ?
                        <Grid item lg={2}>
                            <NumberFormat
                                className='inputSmaller'
                                placeholder="Valor*"
                                margin="normal"
                                customInput={TextField}
                                variant="outlined"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                value={valueType}
                                onChange={(e) => {
                                    setValueType(e.target.value)
                                    setValueInvalid(e.target.value ? false : true)
                                }}
                                error={valueInvalid}


                            />
                        </Grid>
                        : ''
                    }

                    <Grid item lg>
                        <TextField
                            select
                            size="small"
                            label="Status"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={watch("status")}
                            {...register("status")}
                        >
                            <MenuItem key="0" value="1">
                                Ativo
                            </MenuItem>

                            <MenuItem key="1" value="0">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item lg className="d-flex align-items-end justify-content-end">
                        <Button
                            type='submit'
                            className='mb-3'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                    </Grid>
                </Grid>

                {typeList === "3"
                    ?
                    <>
                        <Grid container spacing={3} className="mt-1">
                            <Grid item lg={4} className="d-flex align-items-center">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Produto"
                                    getOptionLabel={(option: {id: number, name: string}) => option.name}
                                    value={productSelected.id}
                                    onSelect={(option) => {
                                        option && setProductSelected({
                                            id: option.id,
                                            name: option.name
                                        })
                                    }}
                                    apiSearchHandler={apiSearchProducts}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!productSelected || !productSelected.id) return null;
                                        return loadedOptions.find((option) => option.id === Number(productSelected.id)) ?? ProductService.getProductById(productSelected.id)
                                    }}
                                    renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Produto" onClick={() => handleClickAddProduct(typedText)} />}
                                />

                                <Button variant="primary" className="ml-3 btn-sm" onClick={() => addProductToList()}><i className="flaticon2-add p-0"></i></Button>
                            </Grid>

                            <Grid item lg={12}>
                                <h4 className="mt-5">Produtos Selecionados</h4>
                                <hr />
                            </Grid>
                        </Grid>


                    </>
                    : ''
                }

                <hr />

                {
                    listProduct.list.map(({ productId, productName }, index) => {
                        return (
                            <React.Fragment key={productId}>
                                {
                                    (!productId)
                                        ? ''
                                        : <Grid container spacing={3} className="mb-1">
                                            <Grid item lg={4}>
                                                <TextField
                                                    size="small"
                                                    value={productName}
                                                    label="Nome do Produto"
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <TextField
                                                    select
                                                    size="small"
                                                    label="Tipo*"
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={e => changeProductList(index, e.target.value, "type")}
                                                >

                                                    <MenuItem key="1" value="1">
                                                        Acréscimo
                                                    </MenuItem>

                                                    <MenuItem key="2" value="2">
                                                        Desconto
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item lg={3}>
                                                <NumberFormat
                                                    className="inputSmaller"
                                                    placeholder="Valor*"
                                                    margin="normal"
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    onChange={e => changeProductList(index, e.target.value, "value")}
                                                />
                                            </Grid>
                                            <Grid item lg={2} className="d-flex align-items-center">
                                                <Button variant="link" onClick={() => deleteProductList(index)}><i className="flaticon-delete"></i></Button>
                                            </Grid>
                                        </Grid>
                                }
                            </React.Fragment>

                        )
                    })
                }
                <span className="mt-15">&nbsp;</span>
            </form>
        </div>
    );
}