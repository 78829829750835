export function verifyProductsJSON(products: string | any[]) {
    let productsArray = products;
    if(typeof productsArray === 'string') {
        productsArray = JSON.parse(productsArray);
    }

    productsArray = productsArray as any[];

    if(productsArray.length === 0) return false;

    return !!productsArray.find((product) => product.INSTANCE?.id !== undefined);
}