import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    TextField,
    TablePagination,
    TableContainer,
    Paper,
    Collapse,
    MenuItem,
} from '@material-ui/core';
import api from '../../../services/Api';
import { formatDate } from '../../../utils/dateFormat';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import UserService from '../../../services/UserService';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../../hooks/backendReload';
import { Search } from '../../../components/Search';
import { useSelector } from 'react-redux';
import { ProcessBpm } from '../../../types/ProcessBpm';
import ProcessBpmService from '../../../services/ProcessBpmService';
import { SolicitationBpm } from '../../../types/SolicitationBpm';

type Filters = {
    searchQuery: string;
    startDate: string;
    endDate: string;
    processFilter: string;
    situation: string;
    requester: string;
}

const situationColor: { [key: string]: "light" | "danger" | "success" } = {
    "active": "light",
    "late": "danger",
    "completed": "success",
}

const situationText: { [key: string]: string } = {
    "active": "Em progresso",
    "late": "Atrasada",
    "completed" : "Concluída",
}

const RequestProcess = () => {
    const { user } = useSelector((state: any) => state.auth);

    const [requests, setRequests] = useState<any>([]);
    const [requestsCount, setRequestsCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [situation, setSituation] = useState<string>("");
    const [requester, setRequester] = useState<string>("");

    const filtersRef = useRef<Filters | null>(null);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [pagesSolicitation, setPagesSolicitation] = useState(0);
    const [rowsPerPageSolicitation, setRowsPerPageSolicitation] = useState(10);
    const [sortDirectionSolicitation, setSortDirectionSolicitation] = useState<'ASC' | 'DESC'>('DESC');
    const [sortReferenceSolicitation, setSortReferenceSolicitation] = useState('id');
    const [pageChanged, setPageChanged] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [modalListProcess, setModalListProcess] = useState(false);

    const [process, setProcess] = useState("");
    const [processError, setProcessError] = useState(false);
    const [processFilter, setProcessFilter] = useState("");
    const [processErrorFilter, setProcessErrorFilter] = useState(false);

    
    const { push: pushHistory } = useHistory();

    const getSolicitation = useCallback((processId: number, solicitationId: number) => {
        pushHistory(`/bpm/solicitacao-formulario/${processId}/solicitacao/${solicitationId}`);
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPageSolicitation, pagesSolicitation, triggerLoad, pageChanged]);

    const handleLoadData = useCallback(async () => {
        setIsLoading(true);
        await loadData({ rowsPerPage: rowsPerPageSolicitation, currentPage: pagesSolicitation, sortDirection: sortDirectionSolicitation, sortReference: sortReferenceSolicitation });
        setIsLoading(false);

        if (pageChanged) {
            setPageChanged(false);
        }

        if (triggerLoad && setTriggerLoad) {
            setPagesSolicitation(0);
            setTriggerLoad(false);
        }
    }, [rowsPerPageSolicitation, pagesSolicitation, triggerLoad, pageChanged, sortDirectionSolicitation, sortReferenceSolicitation]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setIsLoadingData(true);
        const { data } = await api.get<{rows: SolicitationBpm[], count: number}>(`/bpm/solicitations`, {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setRequestsCount(count);
        setRequests(rows);
        setIsLoadingData(false);
    }, []);

    const handleChangeProcess = useCallback((process: ProcessBpm | null | undefined) => {
        setProcess(String(process?.id ?? ''));
    }, []);

    const handleChangeProcessFilter = useCallback((process: ProcessBpm | null | undefined) => {
        setProcessFilter(String(process?.id ?? ''));
    }, []);

    const handleChangePageSolicitation = useCallback((next: number) => {
        setPagesSolicitation(next);
    }, []);

    const handleRowsPerPageSolicitation = useCallback((value: number) => {
        setRowsPerPageSolicitation(value);
        setPagesSolicitation(0);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
            startDate, 
            endDate, 
            processFilter,
            situation,
            requester
        };

        reloadData();
    }, [
        searchQuery, startDate, endDate, processFilter, situation, requester
    ]);
    

    const clearSearch = () => {
        setSearchQuery('');
        setStartDate('');
        setEndDate('');
        setProcessFilter('');
        setSituation('');
        setRequester('');
    }
    
    return (
        <>
            <Modal
                centered
                aria-labelledby="contained-modal-warning"
                size="lg"
                show={modalListProcess}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Visualizar Solicitações do Processo
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="m-0">Selecione um processo para visualizar suas solicitações</p>
                    <ApiResourceSelect
                        label="Processos"
                        getOptionLabel={(option: ProcessBpm) => option.name}
                        value={process}
                        hasError={processError}
                        onSelect={handleChangeProcess}
                        apiSearchHandler={(typedText) => ProcessBpmService.getProcessFiltered({ name: typedText, situation: 'y' })}
                        getSelectedOption={(loadedOptions) => {
                            if (!process) return null;
                            return loadedOptions.find((option) => option.id === Number(process)) ?? ProcessBpmService.getProcessById(process)
                        }}
                        onChangeTextField={(e) => setProcessError(e.target.value ? false : true)}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => pushHistory(`/bpm/solicitacao-de-processos/${process}`)} disabled={!process}>Abrir</Button>
                    <Button variant="secondary" onClick={() => setModalListProcess(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <>
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2 mb-2"
                                onClick={() => setModalListProcess(true)}
                            >
                                Solicitações por Processo
                            </Button>
                        </>
                        : <></>
                    }
                </div>
                <div className="col-lg-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-3">
                        <TextField
                            label={"Inicio Período"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            label={"Fim Período"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Processos"
                            getOptionLabel={(option: ProcessBpm) => option.name}
                            value={processFilter}
                            hasError={processErrorFilter}
                            onSelect={handleChangeProcessFilter}
                            apiSearchHandler={(typedText) => ProcessBpmService.getProcessFiltered({ name: typedText, situation: 'y' })}
                            getSelectedOption={(loadedOptions) => {
                                if (!processFilter) return null;
                                return loadedOptions.find((option) => option.id === Number(processFilter)) ?? ProcessBpmService.getProcessById(processFilter)
                            }}
                            onChangeTextField={(e) => setProcessErrorFilter(e.target.value ? false : true)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            label="Situação"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={situation}
                            onChange={(evt) => setSituation(evt.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>
                            <MenuItem key="1" value="active">
                                Em Progresso
                            </MenuItem>
                            <MenuItem key="2" value="completed">
                                Concluída
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Solicitante"
                            noOptionsText="Nenhum usuário encontrado"
                            getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                            value={requester}
                            onSelect={(option) => setRequester(String(option?.id ?? 'all'))}
                            apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!requester || requester === 'all') return null;
                                return loadedOptions.find((option) => option.id === Number(requester)) ?? UserService.getUserById(requester)
                            }}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>
            <div className="col-lg-12 mt-3 shadow" style={{ overflow: "auto" }}>
                <Table stickyHeader className="wrap">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Processo</TableCell>
                            <TableCell>Solicitante</TableCell>
                            <TableCell>Início solicitação</TableCell>
                            {/*<TableCell>Cliente</TableCell>*/}
                            <TableCell>Situação solicitação</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isLoadingData && (
                            <TableRow>
                                <TableCell colSpan={99}>
                                    <div className="d-flex justify-content-center">
                                        <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {
                                !isLoadingData && requests.map((request: any, index: number) => {
                                    return (
                                        <TableRow key={request.id} hover>
                                            <TableCell>{request.id}</TableCell>
                                            <TableCell>{request.processEntity.name}</TableCell>
                                            <TableCell>{request.userEntity.firstname} {request.userEntity.lastname ?? ''}</TableCell>
                                            <TableCell>{formatDate(request.startSolicitation)}</TableCell>
                                            {/* <TableCell>{request.customer}</TableCell> */}
                                            <TableCell>
                                                <Badge variant={situationColor[request.statusSolicitation]}>
                                                    {situationText[request.statusSolicitation]}
                                                </Badge>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Visualizar solicitação">
                                                    <Button onClick={() => getSolicitation(request.ProcessBpmId, request.id)} size="sm">
                                                        <i className="flaticon-map p-0"></i>
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }

                        {!isLoadingData && requests.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={99} className="text-center">
                                    Nenhum resultado encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    labelRowsPerPage="Linhas por página"
                    page={pagesSolicitation}
                    component="div"
                    count={requestsCount}
                    rowsPerPage={rowsPerPageSolicitation}
                    rowsPerPageOptions={[5, 10, 25]}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima Página',
                    }}
                    onChangePage={(_, next) => handleChangePageSolicitation(next)}
                    onChangeRowsPerPage={(evt) => handleRowsPerPageSolicitation(Number(evt.target.value))}
                />
            </div>
        </>
    );
}

const defaults = {

}

const styles = {
    content: {
        overflow: "scroll",
    },
    header: {
        width: "100%",
        border: `1px solid #DCDCDC`,
        borderRadius: 6,
        marginTop: -20,
        marginBottom: 40,
    },
    filterButtons: {
        float: "right" as "right",
        marginTop: -45,
        position: "relative" as "relative",
        zIndex: 50,
    },
}

export default RequestProcess;