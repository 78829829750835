import React, { useCallback } from "react";
import { NewCustomerForm } from "../../components/Customer/NewCustomerForm";
import { useSubheader } from "../../../_metronic/layout";

export function NewCustomer() {
    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar um Cliente / Fornecedor");

    const handleCloseModalLimit = useCallback(() => {
        window.location.href = "/clientes";
    }, []);

    function handleCloseCustomerCreatedSuccessModal() {
        window.location.href = "/clientes";
    }

    return (
        <NewCustomerForm
            onCloseModalLimit={handleCloseModalLimit}
            onCloseCustomerCreatedSuccessModal={handleCloseCustomerCreatedSuccessModal}
        />
    );
}