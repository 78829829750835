import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export type ModalErrorProps = {
    msgError: string | string[];
    showModalError: boolean;
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>;
    redirect?: string;
}

export default function ModalError({ msgError, showModalError, setShowModalError, redirect }: ModalErrorProps) {
    function handleCloseModal() {
        setShowModalError(false);

        if (redirect?.length) {
            window.location.href = redirect;
        }
    }

    return (
        <Modal
            show={showModalError}
            onHide={handleCloseModal}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    Array.isArray(msgError)
                        ? msgError.map((msg) => (
                            <>
                                <b>{msg}</b>
                                <br />
                            </>
                        ))
                        : <b>{msgError}</b>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
            </Modal.Footer>
        </Modal >
    )
}