import { SubCategory } from "../types/Dre";
import api from "./Api";

class DreCategoryService {
    async getDreSubCategoryById(id: string | number) {
        const dreResponse = await api.get<SubCategory>(`/dre/sub/${id || '0'}`);

        return dreResponse.data;
    }

    async getDreSubCategoriesFiltered(filters: any, type: 'expense' | 'revenue' | 'both') {
        const dreResponse = await api.get<SubCategory[]>(type === 'both' ? 'dre/sub/all' : `/dre/sub/${type}`, { params: { filters } });

        return dreResponse.data;
    }

    async getDreSubCategoryByName(name: string) {
        const responseSaleDreSubcategory = await api.get('dre/sub/name', { params: { name } });
        return responseSaleDreSubcategory.data;
    }

    getDreSubCategoryName(dreSubCategoryName?: SubCategory, defaultValue: string = '') {
        if(!dreSubCategoryName) {
            return defaultValue ? defaultValue : '';
        }
        
        return dreSubCategoryName.name;
    }
}

export default new DreCategoryService();