export const arrangeAlphabetically = (array: string[]) => {
    const sorted = array.sort(
        (item, compare) => {
            if (item > compare) {
                return 1;
            }

            if (item < compare) {
                return -1;
            }

            return 0;
        }
    );

    return sorted;
}
