import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Zoom } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import ModalError from '../../../components/ModalError';
import { useCompanyBranch } from '../../../hooks/companyBranch';
import api from '../../../services/Api';
import ModalSuccess from '../../../components/ModalSuccess';
import { ChartOfAccounts } from '../../../types/ChartOfAccounts';
import { BankAccount } from '../../../types/BankAccount';
import { ValidationBadge } from "../../../components/ValidationBadge";
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ChartOfAccountsService from '../../../services/ChartOfAccountsService';

type Filters = {
    searchQuery: string;
}

type responseType = {
    status: string;
    message: string;
}

type Props = {
    setThirdStepCompleted: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ThirdStep({setThirdStepCompleted}: Props) {
    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [pageChanged, setPageChanged] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgModal, setMsgModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [countTotalRevenues, setCountTotalBankAccounts] = useState(0);

    const [submitErrorMsg, setSubmitErrorMsg] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModalLink, setShowModalLink] = useState(false);
    const [revenueToLink, setRevenueToLink] = useState<BankAccount>();
    const [chartOfAccountsId, setChartOfAccountsId] = useState(0);
    const [description, setDescription] = useState("");

    const [dreRevenues, setDreRevenues] = useState<BankAccount[]>([]);

    const { selectedCompany } = useCompanyBranch();
    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();
    const filtersRef = useRef<Filters | null>(null);

    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [sortReference, setSortReference] = useState('id');

    const isBackEndPagination = true;

    const handleChangePage = useCallback((next: number) => {
        setPages(next);
        setPageChanged(true);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setPages(0);
        setRowsPerPage(value);
        setPageChanged(true);
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPage, pages, triggerLoad, pageChanged,]);

    useEffect(() => {
        getRevenue();
    }, []);

    const getRevenue = useCallback(async () => {
        const { data } = await api.get<{ rows: BankAccount[], count: number }>(`/dre/sub/revenue`, {
            params: {
                skip: rowsPerPage * pages,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
                withChartOfAccountsLink: true,
            }
        });

        const { rows, count } = data;

        setCountTotalBankAccounts(count);
        setDreRevenues(rows ?? []);
    }, [rowsPerPage, pages, sortReference, sortDirection]);

    const handleLoadData = useCallback(async () => {
        if (((pageChanged) || (triggerLoad && setTriggerLoad))) {
            setIsLoadingData(true);
            await getRevenue();
            setIsLoadingData(false);

            if (pageChanged) {
                setPageChanged(false);
            }

            if (triggerLoad && setTriggerLoad) {
                setPages(0);
                setTriggerLoad(false);
            }
        }
    }, [rowsPerPage, pages, triggerLoad, pageChanged, sortDirection, sortReference]);

    const handleSort = useCallback((reference: string, active: boolean) => {
        if(active) {
            setSortDirection((prevState) => prevState === 'ASC' ? 'DESC' : 'ASC'); 
        }

        setSortReference(reference);

        if(setTriggerLoad) {
            setTriggerLoad(true);
        }
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
        };

        reloadData();
    }, [searchQuery]);

    const handleOpenLinkModal = useCallback(async (selectedBankAccount: BankAccount) => {
        setChartOfAccountsId(0);
        setDescription('');
        setRevenueToLink(selectedBankAccount);
        setSubmitErrorMsg('');
        setShowModalLink(true);
    }, []);

    const handleClickEditLink = useCallback(async (selectedBankAccount: BankAccount) => {
        setChartOfAccountsId(selectedBankAccount.chartOfAccountsLink?.chartOfAccountsId ?? 0);
        setDescription(selectedBankAccount.chartOfAccountsLink?.description || '');
        setRevenueToLink(selectedBankAccount);
        setSubmitErrorMsg('');
        setShowModalLink(true);
    }, []);

    const handleClickSaveLink = useCallback(async () => {
        if (!revenueToLink) return;

        setIsSubmitting(true);

        try {
            const selectedChartOfAccounts = await ChartOfAccountsService.getChartOfAccountsById(chartOfAccountsId);

            const raw = {
                chartOfAccountsId,
                linkedEntityId: revenueToLink.id,
                typeLink: 'revenue_dre_sub_category',
                description: description || null,
                chartOfAccountsName: `${selectedChartOfAccounts.code} - ${selectedChartOfAccounts.description}`,
                linkedEntityName: `${revenueToLink.name}`
            }

            let result: any = null;

            if (chartOfAccountsId && revenueToLink.chartOfAccountsLink) {
                result = await api.put(`/chartOfAccountsLink/${revenueToLink.chartOfAccountsLink.id}`, raw);
                result.data.chartOfAccounts = selectedChartOfAccounts;
                setThirdStepCompleted(true);

            } else if (chartOfAccountsId) {
                result = await api.post('/chartOfAccountsLink', raw);
                result.data.chartOfAccounts = selectedChartOfAccounts;
                setThirdStepCompleted(true);

            } else if(revenueToLink.chartOfAccountsLink?.id) {
                await api.delete(`/chartOfAccountsLink/${revenueToLink.chartOfAccountsLink.id}`);
                setThirdStepCompleted(false);
            }

            setDreRevenues((prevState) => prevState.map((bankAccount) => {
                if (bankAccount.id === revenueToLink.id) {
                    return {
                        ...bankAccount,
                        chartOfAccountsLink: result?.data ?? null,
                    }
                }

                return bankAccount;
            }))

            setShowModalLink(false);
        } catch (error) {
            console.log(error);
            setSubmitErrorMsg('Ocorreu um erro ao salvar o vínculo');
        }

        setIsSubmitting(false);
    }, [revenueToLink, chartOfAccountsId, description]);

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            {/* MODAL VINCULAR PLANO CONTÁBIL */}
            <Modal
                show={showModalLink}
                onHide={() => setShowModalLink(false)}
                aria-labelledby="contained-modal-warning"
                size='sm'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Vincular Plano de Contas a Receber
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-12">
                            <strong>Plano de contas de receita: {revenueToLink ? revenueToLink.name : ''}</strong>
                        </div>

                        <div className="col-12">
                            <ApiResourceSelect
                                label="Plano Contábil"
                                minLengthToSearch={2}
                                getOptionLabel={(option: any) => option ? `${option.code} - ${option.description}` : ''}
                                value={chartOfAccountsId}
                                onSelect={(option) => setChartOfAccountsId(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => (
                                    ChartOfAccountsService.getChartOfAccountsFiltered(selectedCompany?.id ?? 0, { search: typedText })
                                )}
                                getSelectedOption={(loadedOptions) => {
                                    if (!chartOfAccountsId) return null;
                                    return loadedOptions.find((option) => option.id === Number(chartOfAccountsId)) ?? ChartOfAccountsService.getChartOfAccountsById(chartOfAccountsId)
                                }}
                            />
                        </div>

                        <div className="col-12">
                            <TextField
                                size="small"
                                label="Complemento de histórico"
                                margin="normal"
                                variant="outlined"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />
                        </div>

                        {!!submitErrorMsg && (
                            <div className="col-12">
                                <span className='text-danger'>
                                    {submitErrorMsg}
                                </span>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalLink(false)}>Fechar</Button>
                    <Button
                        variant="primary"
                        onClick={handleClickSaveLink}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </>
                            :
                            <span>
                                Salvar
                            </span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>

            <div id='secondStep' className='stepper-content'>
                <div className="row d-flex align-items-center">
                    <div className="col-12">
                        <h3>Vincular Planos de Contas de Receita</h3>
                    </div>
                    <div className="col-lg-9"></div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={'id' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('id', 'id' === sortReference)}
                                >
                                    N°
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={'name' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('name', 'name' === sortReference)}
                                >
                                    Plano de Contas de Receita
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Plano Contábil</TableCell>
                            <TableCell>Complemento de Histórico</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isLoadingData && (
                            <TableRow>
                                <TableCell colSpan={99}>
                                    <div className="d-flex justify-content-center">
                                        <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {
                            dreRevenues.length
                                ?
                                !isLoadingData && dreRevenues.slice(isBackEndPagination ? 0 : pages * rowsPerPage, isBackEndPagination ? dreRevenues.length : pages * rowsPerPage + rowsPerPage).map((revenue) => {
                                    return (
                                        <TableRow key={revenue.id} hover>
                                            <TableCell>{revenue.id}</TableCell>
                                            <TableCell>{revenue.name}</TableCell>
                                            <TableCell>
                                                {(
                                                    revenue.chartOfAccountsLink?.chartOfAccounts
                                                        ? `${revenue.chartOfAccountsLink.chartOfAccounts.code} - ${revenue.chartOfAccountsLink.chartOfAccounts.description}`
                                                        : '--'
                                                )}
                                            </TableCell>
                                            <TableCell>{revenue.chartOfAccountsLink?.description ?? '--'}</TableCell>
                                            <TableCell>
                                                {revenue.chartOfAccountsLink
                                                    ? (
                                                        <>
                                                            <ValidationBadge variant="success" label="Vinculado" />

                                                            <Tooltip TransitionComponent={Zoom} title="Editar">
                                                                <Button
                                                                    className="btn-light-primary p-2 ml-3"
                                                                    variant="primary"
                                                                    type="button"
                                                                    onClick={() => handleClickEditLink(revenue)}
                                                                >
                                                                    <i className="flaticon2-edit p-0"></i>
                                                                </Button>
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                type="button"
                                                                className="mr-2 btn-light-primary"
                                                                onClick={() => handleOpenLinkModal(revenue)}
                                                            >
                                                                Vincular
                                                            </Button>
                                                        </>
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={5}>Nenhum dado até o momento...</TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>

                <TablePagination
                    labelRowsPerPage="Linhas por página"
                    page={pages}
                    component="div"
                    count={countTotalRevenues ?? dreRevenues.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 50, 100]}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima Página',
                    }}
                    onChangePage={(_, next) => handleChangePage(next)}
                    onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                />
            </div>
        </>
    )
};