/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { api as apiUrl } from "../../../../../app/services/ApiURL";
import useCompany from "../../../../../app/hooks/company";
import CompanyService from "../../../../../app/services/CompanyService";
import { formatCpfCnpjDynamic } from "../../../../../app/utils/formatCpfCnpj";
import { useCompanyBranch } from "../../../../../app/hooks/companyBranch";
import * as auth from "../../../../../app/modules/Auth";
import { CompanyBranchSelector } from "../../../../../app/components/CompanyBranchSelector";

export function UserProfileDropdown() {
  const [selectedCompany, setSelectedCompany] = useState();
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { companies, companyCanHaveBranches } = useCompany({ only: user.companyId, appendSubscriptionPlan: true });
  const [mainCompany] = companies;
  const { selectedCompany: currentCompany } = useCompanyBranch();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  const [hoverColor, setHoverColor] = useState('2CFFEA');

  const styleButton = {
    background: `#${hoverColor}`,
    color: "#000000",
    border: `1px solid #${hoverColor}`
  };

  useEffect(() => {
    setSelectedCompany(currentCompany);
  }, [currentCompany]);

  function handleOpenCompanyDialog() {
    setShowCompanyDialog(true);
  }

  function handleCloseCompanyDialog(selectedValue) {
    if (selectedValue.id !== selectedCompany.id) {
      dispatch(auth.actions.setSelectedCompanyId(selectedValue.id));
      window.location.href = (history.location.pathname);
    }

    setShowCompanyDialog(false);
  }

  return (
    <>
      <CompanyBranchSelector
        user={user}
        mainCompany={mainCompany}
        selectedCompany={selectedCompany}
        showDialog={showCompanyDialog}
        setShowDialog={setShowCompanyDialog}
        onClose={handleCloseCompanyDialog}
      />
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
            }
          >
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
              Olá,
            </span>{" "}
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
              {user.firstname} {user.lastname}
            </span>
            <span className="symbol symbol-35">
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
                {user.firstname[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img src={user.photo ? `${apiUrl}/${user.photo}` : toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                    {/* <img alt="Pic" src={user.pic} /> */}
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {user.firstname} {user.lastname}
                  </div>
                  {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span> */}
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${user.photo ? `${apiUrl}/${user.photo}` : toAbsoluteUrl(
                    "/media/misc/bg-1.jpg"
                  )})`,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  <span className="symbol-label text-success font-weight-bold font-size-h4">
                    {user.firstname[0]}
                  </span>
                  {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstname} {user.lastname}
                </div>
                <span className="label label-success label-lg font-weight-bold label-inline">
                  3 messages
                </span>
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            {mainCompany && companyCanHaveBranches(mainCompany) && (
              <div onClick={handleOpenCompanyDialog} className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="flaticon2-group text-secondary" />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">
                      {`${CompanyService.getCorporateName(selectedCompany)} - ${formatCpfCnpjDynamic(CompanyService.getCpfCnpj(selectedCompany))}`}
                    </div>
                    <div className="text-muted">
                      Clique para trocar de empresa
                      {/* <span className="label label-light-danger label-inline font-weight-bold">
                        update
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Link to="/minha-conta" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-secondary" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    Minha Conta
                  </div>
                  <div className="text-muted">
                    Configuração da sua conta
                  </div>
                </div>
              </div>
            </Link>

            {
              user.isAccountant == "n"
                ?
                <>
                  <Link to="/config/editar-dados-empresa" className="navi-item px-8 cursor-pointer">
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon-squares-1 text-secondary" />
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer">
                          Dados Da Empresa
                        </div>
                        <div className="text-muted">
                          Configurações da sua empresa e mais
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/faturas" className="navi-item px-8 cursor-pointer">
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-sheet text-secondary" />
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold cursor-pointer">
                          Faturas
                        </div>
                        <div className="text-muted">
                          Gerencie as faturas do seu plano
                          {/* <span className="label label-light-danger label-inline font-weight-bold">
                            update
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
                : <></>
            }

            

            {/* <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-mail text-warning"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Messages</div>
                  <div className="text-muted">Inbox and tasks</div>
                </div>
              </div>
            </a>

            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-rocket-1 text-danger"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Activities</div>
                  <div className="text-muted">Logs and notifications</div>
                </div>
              </div>
            </a>

            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-hourglass text-primary"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Tasks</div>
                  <div className="text-muted">latest tasks and projects</div>
                </div>
              </div>
            </a> */}
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer px-8 py-5">
              <Link
                to="/logout"
                style={styleButton}
                onMouseEnter={() => setHoverColor('00e6cf')}
                onMouseLeave={() => setHoverColor('2CFFEA')}
                className="btn font-weight-bold"
              >
                Sair
              </Link>
              <a href="/plano/pagamento" className="btn btn-clean font-weight-bold">
                Upgrade no Plano
              </a>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
