import React from "react";
import { useSelector } from "react-redux";
import { NewCompany } from "../Company/New-Company";

export function NewBranch() {
    const { user } = useSelector((state: any) => state.auth);

    return (
        <NewCompany mainCompanyId={user.companyId} />
    );
}