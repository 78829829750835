import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LinkedFieldError } from '../types/LinkedFieldError';

export type ModalLinkedFieldsErrorProps = {
    message?: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    errors: LinkedFieldError[];
    redirect?: string;
}

export default function ModalLinkedFieldsError({ message, showModal, setShowModal, errors, redirect }: ModalLinkedFieldsErrorProps) {
    function handleClickViewModule() {
        if (redirect?.length) {
            window.location.href = redirect;
        }
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <b>{message ?? 'Este registro possui um ou mais campos sem vínculo com um cadastro.'}</b>
                    <br/><b>Vincule um cadastro para os seguintes campos:</b>
                </p>

                <div className="mt-3">
                    {errors.map((error, index) => (
                        <p key={index}><b>{error.fieldName}:</b> {error.fieldValue}</p>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {redirect && <Button variant="primary" onClick={handleClickViewModule}>Ver registro</Button>}
                <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}