import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { MenuItem, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString } from '../../../utils/formatCurrency';
import { Autocomplete } from '@material-ui/lab';
import { Product } from '../../../types/Product';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ProductService from '../../../services/ProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';

type Filters = {
    code: string;
    stockLessThan: string;
    classificationTypeInput: string;
    productsInput: string;
    provider: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "code", value: "Código" },
    { reference: "product", value: "Produto" },
    { reference: "unity", value: "Unidade" },
    { reference: "stockLocation", value: "Localização no Estoque" },
    { reference: "cost", value: "Custo" },
    { reference: "balanceStock", value: "Saldo no Estoque" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "data", value: "Data" },
    { reference: "observations", value: "Observações" },
    { reference: "entry", value: "Entrada" },
    { reference: "output", value: "Saída" },
    { reference: "valueProduct", value: "Valor Unitário" },
    { reference: "totalValue", value: "Valor Total" },
];

export function ListReportInventoryStock() {
    const [foundProductApi, setProductApi] = useState<any[]>([]);
    const [countTotalFoundProducts, setCountTotalFoundProducts] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    
    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [productsInput, setProductsInput] = useState('');
    const [provider, setProvider] = useState('');
    const [stockLessThan, setStockLessThan] = useState('');
    const [classificationTypeInput, setClassificationTypeInput] = useState('');
    const [code, setCode] = useState('');

    const [isSearching, setIsSearching] = useState(false);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<{rows: Product[], count:number}>("/report/inventoryStock", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];
        let exportProductsData: ReportDataRow[] = [];
        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });

        });

        setExportTotals([
            { name: 'Total de produtos', value: count },
        ]);
        setExportBodyData(exportDataList);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setIsSearching(true);

        const { data } = await api.get<{rows: Product[], count:number}>("/report/inventoryStock", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setProductApi(rows);
        setCountTotalFoundProducts(count);
        setIsSearching(false);

        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundProductApi;

        aux.forEach((productApi) => {
            const data = mountBodyDataRow(productApi);

            list.push(data);
        });

        setBodyData(list);
    }, [foundProductApi]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Product) {
        const codeProduct = reportRow.code ? reportRow.code : ' - ';
        const productName = reportRow.name ? reportRow.name : ' - ';
        const stockLocation = reportRow.stockLocation ? reportRow.stockLocation : ' - ';
        const saleValue = reportRow.saleValue && reportRow.saleValue.length ? reportRow.saleValue : '0' ;
        const currentStock = reportRow.currentStock ? reportRow.currentStock : 0;
        const unity = reportRow.unit ? reportRow.unit : ' - ';

        const data: BodyDataBaseProps[] = [
            { for: "code", value: String(codeProduct) },
            { for: "product", value: String(productName) },
            { for: "unity", value: String(unity) },
            { for: "stockLocation", value: String(stockLocation)},
            { for: "cost", value: 'R$ ' + saleValue },
            { for: "balanceStock", value: String(currentStock) },
        ];

        return data;
    }

    const clearSearch = () => {

        setStockLessThan('');
        setClassificationTypeInput('');
        setProductsInput('');
        setProvider('');
        setCode('');
    };

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            code,
            stockLessThan,
            classificationTypeInput,
            productsInput: productsInput || 'all',
            provider
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);

    }, [
        code,
        stockLessThan,
        classificationTypeInput,
        productsInput,
        provider,
        lastSortDirection,
        lastSortReference,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Estoque-Inventário', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <div className="card card-body pt-4 newProductWrapper" >
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={productsInput}
                        onSelect={(option) => setProductsInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>
                
                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Código do Produto"
                        margin="normal"
                        variant="outlined"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Fornecedor"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={provider}
                        onSelect={(option) => setProvider(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!provider) return null;
                            return loadedOptions.find((option) => option.id === Number(provider)) ?? CustomerService.getCustomerById(provider)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        id='outlined-select-currency'
                        select
                        size="small"
                        label='Tipo de classificação'                        
                        margin='normal'
                        variant='outlined'
                        value={classificationTypeInput}
                        onChange={e => setClassificationTypeInput(e.target.value)}
                    >
                        <MenuItem key='' value=''>
                            Selecione
                        </MenuItem>

                        <MenuItem key='0' value='00 - Mercadoria para Revenda'>
                            00 - Mercadoria para Revenda
                        </MenuItem>

                        <MenuItem key='1' value='01 - Matéria-Prima'>
                            01 - Matéria-Prima
                        </MenuItem>

                        <MenuItem key='2' value='02 - Embalagem'>
                            02 - Embalagem
                        </MenuItem>

                        <MenuItem key='3' value='03 - Produto em Processo'>
                            03 - Produto em Processo
                        </MenuItem>

                        <MenuItem key='4' value='04 - Produto Acabado'>
                            04 - Produto Acabado
                        </MenuItem>

                        <MenuItem key='5' value='05 - Subproduto'>
                            05 - Subproduto
                        </MenuItem>

                        <MenuItem key='6' value='06 - Produto Intermediário'>
                            06 - Produto Intermediário
                        </MenuItem>

                        <MenuItem key='7' value='07 - Material de Uso e Consumo'>
                            07 - Material de Uso e Consumo
                        </MenuItem>

                        <MenuItem key='8' value='08 - Ativo Imobilizado'>
                            08 - Ativo Imobilizado
                        </MenuItem>

                        <MenuItem key='9' value='09 - Serviços'>
                            09 - Serviços
                        </MenuItem>

                        <MenuItem key='10' value='10 - Outros insumos'>
                            10 - Outros insumos
                        </MenuItem>

                        <MenuItem key='99' value='99 - Outras'>
                            99 - Outras
                        </MenuItem>
                    </TextField>
                </div>


                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Estoque menor que"
                        margin="normal"
                        variant="outlined"
                        value={stockLessThan}
                        onChange={(e) => setStockLessThan(e.target.value)}
                    />
                </div>
         
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Inventário do Estoque"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Estoque-Inventário', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={countTotalFoundProducts}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                    />
            </div>
        </div >
    );
}