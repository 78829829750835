import { useState } from "react";
import { NewProductDefaultData } from "../components/Product/NewProductForm/NewProductForm";

export default function useNewProductForm() {
    const [showModalNewProduct, setShowModalNewProduct] = useState(false);
    const [newProductDefaultData, setNewProductDefaultData] = useState<NewProductDefaultData>()

    return {
        showModalNewProduct,
        setShowModalNewProduct,
        newProductDefaultData,
        setNewProductDefaultData,
    };
}