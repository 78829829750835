import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Accordion, Button, Card, Spinner, Tab, Tabs } from "react-bootstrap";
import { MenuItem, TextField, Link, InputAdornment } from '@material-ui/core';


import api from "../../services/Api";

import { PaymentDetails, Product, useEntry } from './context';
import { ListProducts } from './ListProducts';
import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import { Installment } from './Installment';
import ModalError from '../../components/ModalError';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';
import LogService from '../../services/LogService';
import { getNameByTypePeople } from '../../utils/getNameByTypePeople';
import { freightModality, freightModalityById } from '../../utils/freightModality';
import EntryService from '../../services/EntryService';
import { useSelector } from 'react-redux';
import CustomerSelect from '../../components/CustomerSelect';
import CustomerService from '../../services/CustomerService';
import SellerService from '../../services/SellerService';
import CenterCostService from '../../services/CenterCostService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { CenterCost } from '../../types/CenterCost';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';

export function EditEntry() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [activeTab, setActiveTab] = useState("products");

    const [total, setTotal] = useState(0);
    const [model, setModel] = useState("");
    const [freight, setFreight] = useState("0,00");
    const [carrier, setCarrier] = useState("");
    const [discount, setDiscount] = useState("");
    const [comments, setComments] = useState("");
    const [provider, setProvider] = useState("");
    const [nfeSerial, setNfeSerial] = useState(0);
    const [nfeNumber, setNfeNumber] = useState(0);
    const [nfeKey, setNfeKey] = useState("");
    const [entryDate, setEntryDate] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [centerCost, setCenterCost] = useState("");
    const [typeFreigth, setTypeFreigth] = useState("");
    const [application, setApplication] = useState("");
    const [issuanceDate, setIssuanceDate] = useState("");
    const [internalComments, setInternalComments] = useState("");

    const [supplierId, setSupplierId] = useState<number>();
    const [sellerId, setSellerId] = useState(0);
    const [bcIcms, setBcIcms] = useState("0,00");
    const [valueIcms, setValueIcms] = useState("0,00");
    const [bcIcmsSt, setBcIcmsSt] = useState("0,00");
    const [valueIcmsSt, setValueIcmsSt] = useState("0,00");
    const [valueIpi, setValueIpi] = useState("0,00");
    const [valueProducts, setValueProducts] = useState("0,00");
    const [valueExpenses, setValueExpenses] = useState("0,00");
    const [valueSafe, setValueSafe] = useState("0,00");
    const [valuePis, setValuePis] = useState("0,00");
    const [valueCofins, setValueCofins] = useState("0,00");
    const [valueIrrf, setValueIrrf] = useState("0,00");
    const [valueCsll, setValueCsll] = useState("0,00");
    const [valueSocial, setValueSocial] = useState("0,00");

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});

    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{ id: string }>();
    const {  handleSubmit } = useForm();
    const { classes, products, dispatchProducts, installments, dispatchPayment } = useEntry();

    subHeader.setTitle("Editar Entrada");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setSupplierId(createdCustomer.id);
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);
    
    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        setEntryDate(format(Date.now(), "yyyy-MM-dd"));
        setIssuanceDate(format(Date.now(), "yyyy-MM-dd"));
    }, []);

    useEffect(() => {
        calcTotal();
        // dispatchPayment({
        //     type: "INITIAL",
        //     payload: {
        //         installments: [],
        //     }
        // });
    }, [products, valueProducts, discount, freight, valueExpenses, valueSafe]);

    useLayoutEffect(() => {
        async function loadData() {
            const { data } = await api.get(`entry/${id}`);
            const productsData: Product[] = JSON.parse(data.products);
            const installmentsData: PaymentDetails[] = JSON.parse(data.installments);

            // Products
            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: productsData,
                }
            });

            dispatchPayment({
                type: "INITIAL",
                payload: {
                    installments: installmentsData,
                }
            });

            setModel(data.model ?? '');
            setCarrier(data.carrier ?? '');
            setFreight(formatCurrency(data.freight ?? 0));
            setDiscount(formatCurrency(data.discount ?? 0));
            setProvider(data.provider ?? '');
            setComments(data.comments ?? '');
            setNfeSerial(data.nfeSerial ?? '');
            setNfeNumber(data.nfeNumber ?? '');
            setNfeKey(data.nfeKey ?? '');
            setEntryDate(data.entryDate ?? '');
            setCenterCost(data.centerCost ?? '');
            setTypeFreigth(data.typeFreigth ?? '');
            setApplication(data.application ?? '');
            setIssuanceDate(data.issuanceDate ?? '');
            setInternalComments(data.internalComments ?? '');
            setSupplierId(data.supplierId ? Number(data.supplierId) : 0);
            setSellerId(data.sellerId ? Number(data.sellerId) : 0);
            setBcIcms(formatCurrency(data.bcIcms));
            setValueIcms(formatCurrency(data.valueIcms));
            setBcIcmsSt(formatCurrency(data.bcIcmsSt));
            setValueIcmsSt(formatCurrency(data.valueIcmsSt));
            setValueIpi(formatCurrency(data.ipiPrice));
            setValueProducts(formatCurrency(data.valueProducts));
            setValueExpenses(formatCurrency(data.valueExpenses));
            setValueSafe(formatCurrency(data.valueSafe));
            setValuePis(formatCurrency(data.valuePis));
            setValueCofins(formatCurrency(data.valueCofins));
            setValueIrrf(formatCurrency(data.valueIrrf));
            setValueCsll(formatCurrency(data.valueCsll));
            setValueSocial(formatCurrency(data.valueSocial));

            setBeforeSubmitData(data);
        }

        loadData();
    }, []);

    function calcTotal() {
        var totalProducts = 0;
        var totalInvoice = 0;
        var discountValue = formatToFloat(discount) ?? 0;
        var freightValue = formatToFloat(freight) ?? 0;
        var expenses = formatToFloat(valueExpenses) ?? 0;
        var safe = formatToFloat(valueSafe) ?? 0;

        for (var product of products) {
            totalProducts += formatToFloat(product.total);
        }

        totalInvoice = totalProducts + freightValue + expenses + safe; // produtos, frete, despesas e seguro
        totalInvoice = totalInvoice - discountValue; // desconto em dinheiro

        setValueProducts(formatCurrency(totalProducts));
        setTotal(totalInvoice);
    }

    const onSubmit = async () => {
        setIsSubmit(true);

        if (!validateForm()) {
            setIsSubmit(false);
            return;
        }

        try {
            const raw = {
                total,
                model,
                carrier,
                provider,
                comments,
                nfeSerial,
                nfeNumber,
                nfeKey,
                centerCost: centerCost || null,
                typeFreigth,
                application,
                internalComments,
                entryDate,
                issuanceDate,
                freight: formatToFloat(freight),
                discount: formatToFloat(discount),
                products: JSON.stringify(products),
                installments: JSON.stringify(installments),
                supplierId: supplierId || null,
                sellerId: sellerId || null,
                bcIcms: formatToFloat(bcIcms),
                valueIcms: formatToFloat(valueIcms),
                bcIcmsSt: formatToFloat(bcIcmsSt),
                valueIcmsSt: formatToFloat(valueIcmsSt),
                ipiPrice: formatToFloat(valueIpi),
                valueExpenses: formatToFloat(valueExpenses),
                valueSafe: formatToFloat(valueSafe),
                valuePis: formatToFloat(valuePis),
                valueCofins: formatToFloat(valueCofins),
                valueIrrf: formatToFloat(valueIrrf),
                valueCsll: formatToFloat(valueCsll),
                valueSocial: formatToFloat(valueSocial),
            };

            const { data: entryEditted } = await api.put(`/entry/${id}`, raw);

            LogService.logEdit({
                itemId: entryEditted.id,
                itemName: 'Compra',
                module: 'Ordem de compra',
                oldData: beforeSubmitData,
                newData: entryEditted,
                formattedFields: {
                    supplierId: async (value) => await CustomerService.getNameByIdAsync(Number(value)),
                    sellerId: async (value) => {
                        const foundSeller = await SellerService.getSellerById(Number(value));

                        return getNameByTypePeople(foundSeller);
                    },
                    bcIcms: (value) => formatNumberToString(value),
                    valueIcms: (value) => formatNumberToString(value),
                    bcIcmsSt: (value) => formatNumberToString(value),
                    valueIcmsSt: (value) => formatNumberToString(value),
                    ipiPrice: (value) => formatNumberToString(value),
                    discount: (value) => formatNumberToString(value),
                    freight: (value) => formatNumberToString(value),
                    valueExpenses: (value) => formatNumberToString(value),
                    valueSafe: (value) => formatNumberToString(value),
                    valuePis: (value) => formatNumberToString(value),
                    valueCofins: (value) => formatNumberToString(value),
                    valueIrrf: (value) => formatNumberToString(value),
                    valueCsll: (value) => formatNumberToString(value),
                    valueSocial: (value) => formatNumberToString(value),
                    typeFreigth: (value) => freightModality(value),
                    entryDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    application: (value) => EntryService.getApplicationFormatted(value),
                    centerCost: async (value) => (await CenterCostService.getCenterCostById(Number(value)))?.name ?? '',
                    issuanceDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                },
                fieldsMap: {
                    supplierId: 'Fornecedor',
                    sellerId: 'Vendedor',
                    model: 'Modelo',
                    nfeSerial: 'Série NFe',
                    nfeNumber: 'Número NFe',
                    nfeKey: 'Chave NFe',
                    bcIcms: 'BC de ICMS',
                    valueIcms: 'Valor do ICMS',
                    bcIcmsSt: 'BC de ICMS-ST',
                    valueIcmsSt: 'Valor do ICMS-ST',
                    ipiPrice: 'Valor do IPI',
                    discount: 'Desconto',
                    freight: 'Valor do frete',
                    valueExpenses: 'Valor despesas',
                    valueSafe: 'Valor do seguro',
                    valuePis: 'Valor do PIS retido',
                    valueCofins: 'Valor COFINS retido',
                    valueIrrf: 'Valor IRRF retido',
                    valueCsll: 'Valor CSLL retido',
                    valueSocial: 'Prev. social retido',
                    typeFreigth: 'Modalidade de frete',
                    carrier: 'Transportadora',
                    entryDate: 'Data da entrada',
                    application: 'Aplicação',
                    centerCost: 'Centro de custos',
                    comments: 'Observações',
                    internalComments: 'Observações internas',
                    issuanceDate: 'Data da emissão NF',
                },
            });

            history.push("/entrada-de-mercadoria");
        } catch (error) {
            console.log(error);
        }
    }

    function validateForm() {
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        if (!supplierId) {
            setMsgError("Selecione o fornecedor!");
            setShowModalError(true);
            return false;
        }

        if(entryDate) {
            const dateIsValid = dateIsBetweenRange(entryDate, '', extractDateStringFromTimestamp());
            if(!dateIsValid) {
                setActiveTab("entry-details");
                setMsgError("A data de emissão não pode ser maior que a data atual!");
                setShowModalError(true);
                return false;
            }
        }

        if (!products.find((product) => product.INSTANCE?.id)) {
            setActiveTab("products");
            setMsgError("Selecione pelo menos um produto!");
            setShowModalError(true);
            return false;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalError(true);
                return false;
            }
        }

        if (total <= 0) {
            setActiveTab("total-entry");
            setMsgError("O valor total da entrada deve ser maior que zero!");
            setShowModalError(true);
            return false;
        }

        return true;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    return (
        <div className="card card-custom gutter-b mt-2 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType="supplier"
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header row m-0 pt-2 pb-0">
                    <div className="col-6 m-0 p-0">
                        <h4 className="card-label m-0 mt-2">
                            Detalhes da Entrada
                        </h4>
                    </div>

                    {
                        user.isAccountant == "n"
                        ?
                        <div className='col-lg-6 d-flex justify-content-end mb-3 pr-0'>
                            <Button
                                type='submit'
                                variant="primary"
                                disabled={isSubmit}
                            >

                                {
                                    isSubmit ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>

                                        </>
                                        :
                                        <span>Salvar</span>
                                }
                            </Button>
                        </div>
                        : <></>
                    }
                </div>

                <div className="card-body mt-0 pt-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <CustomerSelect
                                label="Fornecedor *"
                                customerId={supplierId}
                                setCustomerId={setSupplierId}
                                typeCustomer="supplier"
                                disabled={user.isAccountant == "y" ? true : false}
                                onClickAddCustomer={handleClickAddCustomer}
                            />
                        </div>

                        <div className="col-lg-6">
                            <ApiResourceSelect
                                label="Vendedor"
                                getOptionLabel={(option: any) => option.name}
                                value={sellerId}
                                onSelect={(option) => setSellerId(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if(!sellerId) return null;
                                    return loadedOptions.find((option) => option.id === Number(sellerId)) ?? SellerService.getSellerById(sellerId)
                                }}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                size="small"
                                label="Chave NFe"
                                margin="normal"
                                variant="outlined"
                                value={nfeKey}
                                onChange={(evt) => setNfeKey(evt.target.value)}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                label="Modelo"
                                margin="normal"
                                variant="outlined"
                                value={model}
                                onChange={(evt) => setModel(evt.target.value)}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                label="Série NFe"
                                margin="normal"
                                variant="outlined"
                                value={nfeSerial}
                                onChange={(evt) => setNfeSerial(Number(evt.target.value))}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                label="Número NFe"
                                margin="normal"
                                variant="outlined"
                                value={nfeNumber}
                                onChange={(evt) => setNfeNumber(Number(evt.target.value))}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(tab: string) => setActiveTab(tab)}
                                id='invoice-form-tabs'
                            >
                                <Tab
                                    eventKey='products'
                                    title='Produtos'
                                >
                                    <div className="row">
                                        {
                                            products.map(
                                                (prod, index) => (
                                                    <ListProducts
                                                        key={index}
                                                        product={prod}
                                                        index={index}
                                                        onClickAddProduct={handleClickAddProduct}
                                                    />
                                                )
                                            )
                                        }

                                        {
                                            user.isAccountant == "n"
                                            ?
                                            <div className="row col-lg-12 mt-3 ml-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-link-secondary p-0"
                                                    onClick={() => dispatchProducts({ type: "ADD" })}
                                                >
                                                    <ins>+ adicionar outro produto</ins>
                                                </button>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='total-entry'
                                    title='Totais da Entrada'
                                >
                                    <div className="row">

                                        {/* <div className="col-lg-2">
                                            <NumericFormat
                                                label="BC de ICMS"
                                                startAdornment="R$"
                                                value={bcIcms}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                label="Valor do ICMS"
                                                startAdornment="R$"
                                                value={valueIcms}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                label="BC de ICMS-ST"
                                                startAdornment="R$"
                                                value={bcIcmsSt}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                label="Valor do ICMS-ST"
                                                startAdornment="R$"
                                                value={valueIcmsSt}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                label="Valor do IPI"
                                                startAdornment="R$"
                                                value={valueIpi}
                                                disabled
                                            />
                                        </div> */}

                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Valor dos produtos"
                                                startAdornment="R$"
                                                value={valueProducts}
                                                disabled
                                            />
                                        </div>



                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Desconto (R$)"
                                                startAdornment="R$"
                                                value={discount}
                                                onChange={(e) => setDiscount(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Valor do Frete"
                                                startAdornment="R$"
                                                value={freight}
                                                onChange={(e) => setFreight(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Valor despesas"
                                                startAdornment="R$"
                                                value={valueExpenses}
                                                onChange={(e) => setValueExpenses(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Valor do Seguro"
                                                startAdornment="R$"
                                                value={valueSafe}
                                                onChange={(e) => setValueSafe(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>


                                        <div className="col-lg-4">
                                            <NumericFormat
                                                label="Valor total da nota"
                                                startAdornment="R$"
                                                value={formatCurrency(total)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-12 mt-10">

                                            <Accordion>

                                                <Accordion.Toggle as={Card.Header} eventKey="0" className="h5 mb-3 pl-0 pt-0" style={{ cursor: "pointer" }}>
                                                    <img src="/media/svg/icons/Navigation/Angle-double-right.svg" className="mr-3" />
                                                    Retenções
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey="0">

                                                    <div className="row">

                                                        <div className="col-lg-2">
                                                            <NumericFormat
                                                                label="Valor do PIS retido"
                                                                startAdornment="R$"
                                                                value={valuePis}
                                                                onChange={(e) => setValuePis(e.target.value)}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <NumericFormat
                                                                label="Valor COFINS retido"
                                                                startAdornment="R$"
                                                                value={valueCofins}
                                                                onChange={(e) => setValueCofins(e.target.value)}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <NumericFormat
                                                                label="Valor IRRF retido"
                                                                startAdornment="R$"
                                                                value={valueIrrf}
                                                                onChange={(e) => setValueIrrf(e.target.value)}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <NumericFormat
                                                                label="Valor CSLL retido"
                                                                startAdornment="R$"
                                                                value={valueCsll}
                                                                onChange={(e) => setValueCsll(e.target.value)}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col-lg-2">
                                                            <NumericFormat
                                                                label="Prev. social retido"
                                                                startAdornment="R$"
                                                                value={valueSocial}
                                                                onChange={(e) => setValueSocial(e.target.value)}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Accordion>

                                        </div>

                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='payment'
                                    title='Pagamento'
                                >
                                    <Installment total={total} />
                                </Tab>

                                <Tab
                                    eventKey='transport'
                                    title='Transporte'
                                >
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextField
                                                size="small"
                                                select
                                                label="Modalidade de Frete"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                value={typeFreigth}
                                                onChange={(evt) => setTypeFreigth(evt.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem value="sender">
                                                    Remetente
                                                </MenuItem>

                                                <MenuItem value="recipient">
                                                    Destinatário
                                                </MenuItem>

                                                <MenuItem value="free shipping">
                                                    Sem Frete
                                                </MenuItem>
                                            </TextField>
                                        </div>

                                        <div className="col-lg-8">
                                            <ApiResourceSelect
                                                label="Transportadora"
                                                freeSolo
                                                getOptionLabel={(option: Carrier) => option.name}
                                                value={carrier}
                                                onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                                onInputChange={(typedText) => setCarrier(typedText || '')}
                                                apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='entry-details'
                                    title='Detalhes da Entrada'
                                >
                                    <div className="row">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <TextField
                                                size="small"
                                                type="date"
                                                label="Data da entrada"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={entryDate}
                                                onChange={(e) => setEntryDate(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <TextField
                                                size="small"
                                                select
                                                label="Aplicação dos produtos"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                value={application}
                                                onChange={(evt) => setApplication(evt.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem value="merchandise for resale">
                                                    Mercadoria para Revenda
                                                </MenuItem>

                                                <MenuItem value="raw material">
                                                    Matéria-Prima
                                                </MenuItem>

                                                <MenuItem value="Material for use and consumption">
                                                    Material de Uso e Consumo
                                                </MenuItem>

                                                <MenuItem value="permanent assets">
                                                    Ativo Imobilizado
                                                </MenuItem>

                                                <MenuItem value="others">
                                                    Outras
                                                </MenuItem>
                                            </TextField>
                                        </div>

                                        <div className="col-lg-6">
                                            <ApiResourceSelect
                                                label="Centro de Custos"
                                                getOptionLabel={(option: CenterCost) => option.name}
                                                value={centerCost}
                                                onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                                apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                                getSelectedOption={(loadedOptions) => {
                                                    if(!centerCost) return null;
                                                    return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                label="Observações"
                                                multiline
                                                rows="10"
                                                placeholder="Esta informação será impressa nas observações da nota."
                                                margin="normal"
                                                variant="outlined"
                                                value={comments}
                                                onChange={(evt) => setComments(evt.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>


                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                label="Observações Internas"
                                                multiline
                                                rows="10"
                                                placeholder="Detalhes internos, não serão enviados para o fornecedor."
                                                margin="normal"
                                                variant="outlined"
                                                value={internalComments}
                                                onChange={(evt) => setInternalComments(evt.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                type="date"
                                                label="Data da emissão NF"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={issuanceDate}
                                                onChange={(e) => setIssuanceDate(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
