import { bypassAuthHeader } from "../utils/bypassAuthHeader";
import api from "./Api";

// 'Em andamento', 'Finalizado', 'Cancelado'
const defaultStatus = [
    {
        name: 'Em aberto', 
        color: 'secondary'
    },
    {
        name: 'Em andamento', 
        color: 'warning'
    },
    {
        name: 'Finalizado', 
        color: 'primary'
    },
    {
        name: 'Cancelado', 
        color: 'danger'
    },
];

type Params = {
    bypassAuth?: boolean;
}

class StatusService {
    async createDefaultStatus(companyId: number | string, { bypassAuth }: Params = {}) {
        for (var status of defaultStatus) {
            const rawStatus = {
                label: status.name,
                color: status.color,
                isDefault: "y",
                companyId: Number(companyId),
            }
            
            await api.post('/status', rawStatus, { headers: bypassAuth ? bypassAuthHeader : undefined });
        }
    }
}

export default new StatusService();