import React, {
    createContext,
    Dispatch,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import api from '../../services/Api';
import { Product as ProductHook } from '../../hooks/products';

export type Entry = {
    id: number;
};

export type Product = {
    INSTANCE: ProductHook;
    amount: number;
    ipi: string;
    icms: string;
    unitaryValue: number;
    cfop: string;
    ncm: string;
    total: number;
    hasError?: boolean;
};

export type PaymentDetails = {
    date: string;
    value: number;
    payment: string;
    comments: string;
};

type ProductsActionTypes = "CHANGE" | "ADD" | "DELETE" | "INITIAL";
type ProductsAction = {
    type: ProductsActionTypes;
    payload?: {
        index: number;
        product?: Product;
        products?: Product[];
    };
}

type PaymentActionTypes = "CHANGE" | "GENERATE" | "CHANGE_VALUE" | "CHANGE_DATE" | "INITIAL";
type PaymentAction = {
    type: PaymentActionTypes;
    payload: {
        index?: number;
        value?: number;
        date?: string;
        amount?: number;
        payment?: PaymentDetails;
        installments?: PaymentDetails[];
    };

};

const ActionObjects = {
    CHANGE: "CHANGE",
    CHANGE_VALUE: "CHANGE_VALUE",
    CHANGE_DATE: "CHANGE_DATE",
    DELETE: "DELETE",
    ADD: "ADD",
    GENERATE: "GENERATE",
    INITIAL: "INITIAL",
};

const productsReducer = (state: Product[], { type, payload }: ProductsAction): Product[] => {
    const aux = state;

    if (type === ActionObjects.INITIAL) {
        if (!payload || !payload.products) {
            throw new Error(`Type \'${ActionObjects.INITIAL}\' must contain payload params`);
        }

        return [...payload.products];
    }

    if (type === ActionObjects.CHANGE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        const { product, index } = payload;
        
        if (!product) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain product within payload`);
        }

        aux[index] = product;

        return [...aux];
    }
   
    if (type === ActionObjects.DELETE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        if (!payload.index) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        const filteredState = state.filter((_, i) => i !== payload.index);

        return [...filteredState];
    }

    if (type === ActionObjects.ADD) {
        const newState: Product = {
            INSTANCE: {} as ProductHook,
            amount: 0,
            cfop: "0",
            icms: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            unitaryValue: 0,
        }

        return [...aux, newState];
    }
 
    return [...aux];
}

const paymentReducer = (state: PaymentDetails[], { type, payload }: PaymentAction): PaymentDetails[] => {
    const aux = state;

    if (type === ActionObjects.INITIAL) {
        if (!payload || !payload.installments) {
            throw new Error(`Type \'${ActionObjects.INITIAL}\' must contain payload params`);
        }

        return [...payload.installments];
    }

    if (type === ActionObjects.CHANGE) {
        const { index, payment } = payload;

        if (!payment || index === undefined) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        aux[index] = payment;

        return [...aux];
    }

    if (type === ActionObjects.CHANGE_VALUE) {
        const { payment, value, index, amount } = payload;

        if (!payment || value === undefined || index === undefined || !amount) {
            throw new Error();
        }

        let newArray: PaymentDetails[] = [];
        const total = state.reduce((acc, { value }) => acc + value, 0);

        const beforeIndex = state.reduce((acc, { value }, i) => {
            if (i < index) {
                return acc + value;
            }

            return acc;
        }, 0);

        const partial = beforeIndex + value;

        if (partial > total) {
            alert("A soma das parcelas não pode ser maior que o valor total");
            return [];
        }

        const remainingInstallment = amount - index - 1;
        const newValueInstallment = (total - partial) / remainingInstallment;

        for (let i = 0; i < amount; i += 1) {
            if (i < index) {
                newArray.push({ ...aux[i] });
            }

            if (i === index) {
                newArray.push(payment);
            }

            if (i > index) {
                const newPaymentDetails: PaymentDetails = {
                    ...aux[i],
                    value: newValueInstallment
                };

                newArray.push(newPaymentDetails);
            }
        }

        return [...newArray];
    }

    if(type === ActionObjects.CHANGE_DATE) {
        const { payment, date, index } = payload;

        if (!payment || date === undefined || index === undefined) {
            throw new Error();
        }

        let newArray = state.map((paymentObj, i) => i === index ? {...paymentObj, date} : paymentObj);

        return [...newArray];
    }

    if (type === ActionObjects.GENERATE) {
        const { amount, value } = payload;

        if (amount === undefined || value === undefined) {
            throw new Error(`Type \'${ActionObjects.GENERATE}\' must contain payload params`);
        }

        let initial: PaymentDetails[] = [];
        const installmentValue = Number(value) / amount;

        const initialDate = new Date();

        for (let i = 0; i < amount; i += 1) {
            initialDate.setMonth(initialDate.getMonth() + 1);

            const day = String(initialDate.getDate()).padStart(2, '0');
            const month = String(initialDate.getMonth() + 1).padStart(2, '0');
            const year = initialDate.getFullYear();

            const date = year + '-' + month + '-' + day;

            const payment: PaymentDetails = {
                date,
                payment: "Cartão de Crédito",
                comments: "",
                value: installmentValue,
            };

            initial.push(payment);
        }

        return initial;
    }

    return [...aux];
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));


interface EntryContextData {
    products: Product[];
    installments: PaymentDetails[];
    entrys: Entry[];
    dispatchPayment: Dispatch<PaymentAction>;
    dispatchProducts: Dispatch<ProductsAction>;
    classes: ClassNameMap<"container" | "dense" | "error" | "menu" | "textField" | "formControl">;
}
const EntryContext = createContext({} as EntryContextData);


const EntryProvider: React.FC = ({ children }) => {
    const [products, dispatchProducts] = useReducer(productsReducer, [
        {
            icms: "0",
            cfop: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            amount: 1,
            unitaryValue: 0,
            INSTANCE: {} as ProductHook,
        }
    ]);
    const [installments, dispatchPayment] = useReducer(paymentReducer, []);

    const [entrys, setEntrys] = useState<Entry[]>([]);

    const classes = useStyles();

    useEffect(() => {
        async function loadData() {
            const { data } = await api.get<Entry[]>("purchase-order");

            setEntrys(data);
        }

        loadData();
    }, []);

    return (
        <EntryContext.Provider value={{
            classes,
            installments,
            products,
            entrys,
            dispatchPayment,
            dispatchProducts,
        }}>
            {children}
        </EntryContext.Provider>
    );
};

const useEntry = () => {
    const context = useContext(EntryContext);

    if (!context) {
        throw new Error();
    }

    return context;
}

export { EntryProvider, useEntry };
