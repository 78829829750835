import React from "react";
import { Route, Switch } from "react-router-dom";

import { useSubheader } from "../../_metronic/layout";
import { NewCategoryProduct } from "../pages/CategoryProduct/New-CategoryProduct";
import { ListCategoryProduct } from "../pages/CategoryProduct/List-CategoryProduct";
import { EditCategoryProduct } from "../pages/CategoryProduct/Edit-CategoryProduct";
// import Button from '@material-ui/core/Button';

export function CategoryProduct() {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Categorias de Produto");

    return (
        <Switch>
          <Route path="/categorias-produto/adicionar" component={NewCategoryProduct}/>  
          <Route path="/categorias-produto/:id" component={EditCategoryProduct}/>
          <Route path="/categorias-produto" component={ListCategoryProduct} />               
        </Switch>
      );
}
