import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import {ListAccountsBank} from '../pages/AccountBank/List-AccountsBank';

export function AccountsBank() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Contas Bancárias");
    
    return (
        <Switch>
            <Route path="/contas-bancarias" component={ListAccountsBank}/>
        </Switch>
    );
}