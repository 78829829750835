import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { format, parseISO, isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';

import {
	HeadDataBaseProps,
	BodyDataBaseProps,
	ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Search } from '../../components/Search';
import '../../style.css';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import ModalChangeStatus, { StatusHistory } from '../../components/ModalChangeStatus';
import { BsVariant } from '../../types/BsVariant';
import { Quotation, QuotationStatus } from '../../types/Quotation';
import { getSituationFromText, getSituationText } from '../../utils/getSituationText';
import quotationService, { Filters } from '../../services/QuotationService';
import api from '../../services/Api';
import { status } from '../Config/CompanyData/ManageStatus';
import useBackendLoad from '../../hooks/backendReload';
import QuotationService from '../../services/QuotationService';
import { useSelector } from 'react-redux';
import CustomerService from '../../services/CustomerService';

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'name', value: "Nome" },
    { reference: 'createdDate', value: "Data" },
    { reference: 'supplier', value: "Fornecedor Escolhido" },
    { reference: 'amount', value: "Valor Total" },
    { reference: 'situation', value: "Situação", situation: true, notSortable: true },
];

export function ListQuotation() {
    const { user } = useSelector((state: any) => state.auth);
    
	const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [quotations, setQuotations] = useState<Quotation[]>([]);
    const [allStatus, setAllStatus] = useState<status[]>([]);
    const [countTotalQuotations, setCountTotalQuotations] = useState(0);

    //Modal alterar status
    const [idChangeStatus, setIdChangeStatus] = useState(0);
    const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);

	// Campos da Busca
     const [searchQuery, setSearchQuery] = useState("");
     const [advancedSearch, setAdvancedSearch] = useState(false);
     const [situation, setSituation] = useState("");
     const [initialCreatedDate, setInitialCreatedDate] = useState("");
     const [finalCreatedDate, setFinalCreatedDate] = useState("");
     const [minValue, setMinValue] = useState("");
     const [maxValue, setMaxValue] = useState("");
     const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
	
	const { location: { pathname }, push: pushHistory } = useHistory();

	useEffect(() => {
        getAllStatus();
    }, []);
    
  // useEffect(() => {
  //     handleClickSearch();
  // }, [quotations]);

  const loadData = useCallback(async (dataParams: LoadDataParams) => {
      const { rows, count } = await quotationService.getAllQuotations(dataParams, filtersRef.current);

      setQuotations(rows);
      setCountTotalQuotations(count);
  }, []);

	useLayoutEffect(() => {
		async function mountBodyData() {
            const list: BodyDataBaseProps[][] = [];
            const aux = quotations;

            for(const quotation of aux) {
                const amount = formatCurrency(quotation.totalValue);
                const status =  getSituationText(quotation.status);

                // Formatando data
                const nextDate = parseISO(extractDateStringFromTimestamp(quotation.createdDate));
                const formatedNextDate = format(nextDate, "dd/MM/yyyy");

                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(quotation.id), id: true },
                    { for: "name", value: quotation.name ?? '' },
                    { for: "createdDate", value: formatedNextDate,},
                    { for: "supplier", value: CustomerService.getCustomerName(quotation.customerEntity) },
                    { for: "amount", value: amount },
                    { for: "situation", value: status },
                    { for: "customStatusLabel", value: quotation.customStatus?.label ?? '' },
                    { for: "customStatusColor", value: quotation.customStatus?.color ?? '' },
                ];

                list.push(data);
            };

            setBodyData(list);
        }

        mountBodyData();

	}, [quotations]);

   async function getAllStatus() {
        var statusData = await api.get("/status");
        setAllStatus(statusData.data);
    }
	
	const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setInitialCreatedDate('');
        setFinalCreatedDate('');
        setMinValue('');
        setMaxValue('');
    }

	const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, initialCreatedDate, finalCreatedDate, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue)
        };

        reloadData();
    }, [quotations, searchQuery, situation,
        initialCreatedDate, finalCreatedDate,
        minValue, maxValue]);

	const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = quotations.filter((quotation) => quotation.id !== Number(id));

            await quotationService.deleteQuotation(id);

            setQuotations([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [quotations]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

	const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickOpenChangeStatusModal = useCallback((id: string) => {
        const quotation = quotations.find(quoationObj => quoationObj.id === Number(id));
        if(!quotation) return;

        setStatusHistory(JSON.parse(quotation.situation));
        setIdChangeStatus(Number(id));
        setShowModalChangeStatus(true);
    }, [quotations]);

    const handleClickChangeStatus = useCallback(async (newStatus: StatusHistory) => {

        const aux = [...statusHistory];
        const lastStatus = aux[aux.length - 1];
        
        aux.push(newStatus);

        var status = newStatus.statusIsDefault === "y" ? getSituationFromText(newStatus.statusSituationLabel) : "custom";
        var customStatus = newStatus.statusIsDefault === "n" ? newStatus.statusSituationId : null;

        const customStatusObject = customStatus ? allStatus.find((statusObj) => statusObj.id === customStatus) : null;

        const raw: any = {
            status: status,
            customStatusId: customStatus,
            situation: JSON.stringify(aux),
        }
        if(newStatus.statusSituation !== QuotationStatus.attended) {
            raw.totalValue = null;
            raw.chosenSupplierId = null;
        }

        const updatedQuotation = await quotationService.updateQuotation(idChangeStatus, raw);

        QuotationService.dispatchStatusChangeNotificationAndEmail({
            quotationId: idChangeStatus,
            lastStatusHistory: lastStatus,
            newStatusHistory: newStatus,
        });

        setStatusHistory([...aux]);
        setQuotations((prevState) => (
            prevState.map((quotation) => {
                if(quotation.id !== idChangeStatus) {
                    return quotation;
                }

                return {
                    ...quotation,
                    situation: JSON.stringify(aux),
                    status: quotationService.convertStringToStatus(status ?? 'custom'),
                    totalValue: updatedQuotation.totalValue,
                    chosenSupplierId: updatedQuotation.chosenSupplierId,
                    customStatus: customStatusObject,
                };
            })
        ));
    }, [idChangeStatus, statusHistory, allStatus]);

	return (
		<div className="card card-body pt-4 newProductWrapper">
            <ModalChangeStatus
                showModal={showModalChangeStatus}
                setShowModal={setShowModalChangeStatus}
                history={statusHistory}
                onClickChangeStatus={handleClickChangeStatus}
                listStatus={allStatus}
            />
				<div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Nova Cotação
                            </Button>
                            : <></>
                        }
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={setAdvancedSearch}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                <Collapse in={advancedSearch}>
                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                variant="outlined"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Todos
                                </MenuItem>

                                {allStatus.map((status) => (
                                    <MenuItem key={status.id} value={status.isDefault === 'y' ? getSituationFromText(status.label) : status.id} onClick={(event) => {setSituation(event.currentTarget.getAttribute('data-value') ?? '')}}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Data Inicial"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={initialCreatedDate}
                                onChange={(e) => setInitialCreatedDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Data final"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={finalCreatedDate}
                                onChange={(e) => setFinalCreatedDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                className="inputSmaller"
                                margin="normal"
                                customInput={TextField}
                                variant="outlined"
                                withPrefix={false}
                                label="Valor mínimo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            R$
                                        </InputAdornment>
                                    ),
                                }}
                                value={minValue} 
                                onChange={(evt) => setMinValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                className="inputSmaller"
                                margin="normal"
                                customInput={TextField}
                                variant="outlined"
                                withPrefix={false}
                                label="Valor máximo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            R$
                                        </InputAdornment>
                                    ),
                                }}
                                value={maxValue} 
                                onChange={(evt) => setMaxValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                            >
                                Limpar
                            </Button>
                        </div>
                    </div>
            </Collapse>

			<div className="mt-3">
				<ListWithModalChangeSituation
                    situation
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
                    onClone={handleClickClone}
					onDelete={handleClickDelete}
                    customButtons={
                        user.isAccountant == "n"
                        ?
                        [
                            {
                                class: 'btn-primary',
                                content: (<i className='p-0 flaticon-list-2'></i>),
                                variant: BsVariant.PRIMARY,
                                popup: "Alterar Status",
                                onClick: handleClickOpenChangeStatusModal,
                            }
                        ]
                        : []
                    }
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalQuotations}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
		</div>
	);
}