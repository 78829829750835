import React from "react";
import { ResponsiveContainer, Tooltip, CartesianGrid, XAxis, YAxis, Legend, Bar, ComposedChart, Area } from "recharts";
import { formatCurrency } from "../utils/formatCurrency";

type CashFlowComposedChartProps = {
    cashFlow: cashFlow[];
    cashReceive: number;
    cashPay: number;
    tooltipTitleHandler: (element: any) => string;
}

export type cashFlow = {
    name: string,
    despesas: number,
    receitas: number,
    saldo: number,
}

const styleToolTip = {
    background: "#fff",
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "5px",
    outline: "none"
};

export function CashFlowComposedChart({
    cashFlow,
    cashReceive,
    cashPay,
    tooltipTitleHandler
}: CashFlowComposedChartProps) {
    const renderTooltipCashFlow = (element: any) => {
        return (
            <div style={styleToolTip}>
                <b>
                    {tooltipTitleHandler(element)}
                </b>
                <hr style={{ margin: "5px" }} />

                {
                    element.payload && element.payload.map((data: any, index: number) => {
                        return (
                            <p key={index} style={{ color: data.color }}>
                                <b>{data.name.charAt(0).toUpperCase() + data.name.slice(1)} <label style={{ opacity: 0.5 }}>(do período)</label>:</b> {formatCurrency(data.value)}
                            </p>
                        )
                    })
                }
            </div>
        )
    }

    const gradientOffset = () => {
        const dataMax = Math.max(...cashFlow.map((i) => i.saldo));
        const dataMin = Math.min(...cashFlow.map((i) => i.saldo));

        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    const off = gradientOffset();

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart width={1000} height={300} data={cashFlow} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" scale={"band"} />
                    <YAxis />
                    <Tooltip content={renderTooltipCashFlow} />
                    <Legend />
                    <Bar dataKey="receitas" barSize={10} fill="green" />
                    <Bar dataKey="despesas" barSize={10} fill="red" />
                    <defs>
                        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                            <stop offset={off} stopColor="green" stopOpacity={0.4} />
                            <stop offset={off} stopColor="red" stopOpacity={0.4} />
                        </linearGradient>
                    </defs>
                    <Area type="monotone" dataKey="saldo" stroke="blue" fill="url(#splitColor)" />
                </ComposedChart>
            </ResponsiveContainer>



            <div className="row col-12 d-flex flex-row justify-content-between align-items-center mt-3 pt-3 border-top">
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b>Receitas <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "green" }}>{formatCurrency(cashReceive)}</h3>
                </div>
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b>Despesas <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "red" }}>{formatCurrency(cashPay)}</h3>
                </div>
                <div className="col-sm-12 col-lg-4 d-flex flex-column align-items-center mb-1">
                    <b>Saldo <label style={{ opacity: 0.5, margin: 0 }}>(do período)</label></b>
                    <h3 className="mt-3" style={{ color: "blue" }}>{formatCurrency(cashReceive - cashPay)}</h3>
                </div>
            </div>
        </>
    );
}