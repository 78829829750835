import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import api from "../../../services/Api";
import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useBackendLoad from '../../../hooks/backendReload';
import CustomerService from '../../../services/CustomerService';
import SellerService from '../../../services/SellerService';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import { Seller } from '../../../types/Seller';
import { Link, } from 'react-router-dom';
import { formatDate } from '../../../utils/dateFormat';
import { formatNumberToString } from '../../../utils/formatCurrency';
import { ListInstallments } from './List-Installments';
import { NumericFormat } from '../../../components/NumericFormat';

type ReportRequestResponse = {
    rows: ReportCommissionRow[];
    count: number;
    totalCommissionValue: number;
    totalCommissionValueFromRequests: number;
    totalCommissionValueFromServiceOrders: number;
}

type ReportCommissionRow = {
    id: number;
    number: string;
    type: 'request' | 'serviceOrder';
    companyId: number;
    customerId: number;
    customerName: string;
    totalValue: number;
    date: string;
    sellerId: number;
    sellerName: string;
    sellerCommissionPercentage: number;
    sellerCommissionTotalValue: number;
    status: string;
    statusLabel: string;
    statusColor?: string;
    installments: ReportComissionRowInstallments[];
}

export type ReportComissionRowInstallments = {
    date: string;
    comments: string;
    paymentMethod: string;
    value: number;
    sellerCommissionValue: number;
}

type Filters = {
    type: string;
    sellerId: number;
    customerId: number;
    initialDate: string;
    finalDate: string;
    minCommissionPercentage: number;
    maxCommissionPercentage: number;
    minCommissionValue: number;
    maxCommissionValue: number;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "date", value: "Data de Registro" },
    { reference: "typeRegister", value: "Tipo de Registro", notSortable: true },
    { reference: "number", value: "Nº Pedido/OS" },
    { reference: "customerName", value: "Cliente" },
    { reference: "sellerName", value: "Vendedor" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "sellerCommissionPercentage", value: "Percentual da Comissão (%)" },
    { reference: "sellerCommissionTotalValue", value: "Valor total da Comissão (R$)" },
];

const installmentHeadData: HeadDataBaseProps[] = [
    { reference: "index", value: "N° Parcela" },
    { reference: "date", value: "Data de vencimento" },
    { reference: "value", value: "Valor" },
    { reference: "commissionValue", value: "Valor da Comissão" },
];

export function ListReportCommission() {
    const [reportCommissionRows, setReportCommissionRows] = useState<ReportCommissionRow[]>([]);
    const [countReportCommissionRows, setCountReportCommissionRows] = useState(0);
    const [totalCommissionValue, setTotalCommissionValue] = useState(0);
    const [totalCommissionValueFromRequests, setTotalCommissionValueFromRequests] = useState(0);
    const [totalCommissionValueFromServiceOrders, setTotalCommissionValueFromServiceOrders] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('date');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [type, setType] = useState('all');
    const [sellerId, setSellerId] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [minCommissionPercentage, setMinCommissionPercentage] = useState('');
    const [maxCommissionPercentage, setMaxCommissionPercentage] = useState('');
    const [minCommissionValue, setMinCommissionValue] = useState('');
    const [maxCommissionValue, setMaxCommissionValue] = useState('');

    const [showInstallments, setShowInstallments] = useState(false);
    const filtersRef = useRef<Filters | null>(null);
    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
        showInstallments,
    ) => {
        const { data } = await api.get<ReportRequestResponse>("/report/commission", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalCommissionValue,
            totalCommissionValueFromRequests,
            totalCommissionValueFromServiceOrders,
        } = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            let exportInstallmentData: ReportDataRow[] = [];
            const data = mountBodyDataRow(reportRow);
            const installments = reportRow.installments;

            data.push(
                {
                    for: "installments",
                    value: '',
                    secondaryRow: true,
                    jsx: <ListInstallments installments={installments} />
                },
            );

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            if (showInstallments) {
                exportInstallmentData = installments.map((installment, index) => {
                    return {
                        cells: [
                            { for: 'index', content: String(index + 1) },
                            { for: 'date', content: formatDate(installment.date) },
                            { for: 'value', content: formatCurrency(installment.value) },
                            { for: 'commissionValue', content: formatCurrency(installment.sellerCommissionValue) },
                        ],
                    };
                });
            }

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: installmentHeadData,
                    bodyData: exportInstallmentData,
                }
            });

            setExportTotals([
                { name: 'Total de Registros', value: count },
                { name: 'Valor Total de Comissões', value: formatCurrency(totalCommissionValue) },
                { name: 'Valor Total de Comissões de Pedidos', value: formatCurrency(totalCommissionValueFromRequests) },
                { name: 'Valor Total de Comissões de Ordens de Serviço', value: formatCurrency(totalCommissionValueFromServiceOrders) },
            ])
        });

        setExportBodyData(exportDataList);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportRequestResponse>("/report/commission", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalCommissionValue,
            totalCommissionValueFromRequests,
            totalCommissionValueFromServiceOrders,
        } = data;

        setReportCommissionRows(rows);
        setCountReportCommissionRows(count);
        setTotalCommissionValue(totalCommissionValue);
        setTotalCommissionValueFromRequests(totalCommissionValueFromRequests);
        setTotalCommissionValueFromServiceOrders(totalCommissionValueFromServiceOrders);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    const { user } = useSelector((state: any) => state.auth);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = reportCommissionRows;

        aux.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);
            const installments = reportRow.installments

            if (showInstallments == true) {
                data.push(
                    {
                        for: "installments",
                        value: '',
                        secondaryRow: true,
                        jsx: <ListInstallments installments={installments} />
                    },
                );
            }

            list.push(data);
        });

        setBodyData(list);
    }, [reportCommissionRows]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference, showInstallments);
    }, [lastSortDirection, lastSortReference, showInstallments]);

    function mountBodyDataRow(reportRow: ReportCommissionRow) {
        let data: BodyDataBaseProps[] = [
            {
                for: "id",
                value: String(reportRow.id),
                jsx: <Link to={reportRow.type === 'request' ? `/pedidos/${reportRow.id}` : `/ordem-de-servico/${reportRow.id}`}>{reportRow.id}</Link>,
                id: true
            },
            { for: "date", value: formatDate(reportRow.date) },
            { for: "typeRegister", value: reportRow.type === 'request' ? 'Pedido' : 'Ordem de Serviço' },
            { for: "number", value: reportRow.number },
            { for: "customerName", value: reportRow.customerName },
            { for: "sellerName", value: reportRow.sellerName },
            { for: "totalValue", value: formatCurrency(reportRow.totalValue) },
            { for: "sellerCommissionPercentage", value: formatNumberToString(reportRow.sellerCommissionPercentage * 100) },
            { for: "sellerCommissionTotalValue", value: formatCurrency(reportRow.sellerCommissionTotalValue) },
        ];

        return data;
    }

    const handleClickSearch = useCallback(async () => {
        const minCommissionPercentageNumber = formatToFloat(minCommissionPercentage);
        const maxCommissionPercentageNumber = formatToFloat(maxCommissionPercentage);

        filtersRef.current = {
            type, sellerId, customerId,
            initialDate, finalDate,
            minCommissionPercentage: minCommissionPercentageNumber > 0 ? minCommissionPercentageNumber / 100 : 0,
            maxCommissionPercentage: maxCommissionPercentageNumber > 0 ? maxCommissionPercentageNumber / 100 : 0,
            minCommissionValue: formatToFloat(minCommissionValue),
            maxCommissionValue: formatToFloat(maxCommissionValue),
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference, showInstallments);
    }, [
        showInstallments,
        type, sellerId, customerId,
        initialDate, finalDate,
        minCommissionPercentage, maxCommissionPercentage,
        minCommissionValue, maxCommissionValue,
    ]);

    const clearSearch = () => {
        setType('all');
        setSellerId(0);
        setCustomerId(0);
        setInitialDate('');
        setFinalDate('');
        setMinCommissionPercentage('');
        setMaxCommissionPercentage('');
        setMinCommissionValue('');
        setMaxCommissionValue('');
        setShowInstallments(false);
    }

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Comissões', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <div className="row d-flex align-items-center">
                <div className="col-lg-2">
                    <TextField
                        select
                        label="Tipo de Registro"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem key="0" value="all">Selecione</MenuItem>
                        <MenuItem key="1" value="request">Pedidos</MenuItem>
                        <MenuItem key="2" value="serviceOrder">Ordens de Serviço</MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-5">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerId}
                        onSelect={(option) => setCustomerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if (!customerId) return null;
                            return loadedOptions.find((option) => option.id === Number(customerId)) ?? CustomerService.getCustomerById(customerId)
                        }}
                    />
                </div>
                <div className="col-lg-5">
                    <ApiResourceSelect
                        label="Vendedor"
                        getOptionLabel={(option: Seller) => `${option.id} - ${option.name}`}
                        value={sellerId}
                        onSelect={(option) => setSellerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if (!sellerId) return null;
                            return loadedOptions.find((option) => option.id === Number(sellerId)) ?? SellerService.getSellerById(sellerId)
                        }}
                    />
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data de Registro"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={initialDate}
                        onChange={(e) => setInitialDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data de Registro"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={finalDate}
                        onChange={(e) => setFinalDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className="col-lg-3">
                    <NumericFormat
                        label="Porcentagem da Comissão"
                        value={minCommissionPercentage}
                        onChange={(e) => setMinCommissionPercentage(e.target.value)}
                        startAdornment="DE"
                    />
                </div>

                <div className="col-lg-3">
                    <NumericFormat
                        label="Porcentagem da Comissão"
                        value={maxCommissionPercentage}
                        onChange={(e) => setMaxCommissionPercentage(e.target.value)}
                        startAdornment="ATÉ"
                    />
                </div>

                <div className="col-lg-3">
                    <NumericFormat
                        label="Valor Total da Comissão"
                        value={minCommissionValue}
                        onChange={(e) => setMinCommissionValue(e.target.value)}
                        startAdornment="DE"
                    />
                </div>

                <div className="col-lg-3">
                    <NumericFormat
                        label="Valor Total da Comissão"
                        value={maxCommissionValue}
                        onChange={(e) => setMaxCommissionValue(e.target.value)}
                        startAdornment="ATÉ"
                    />
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showInstallments}
                                onChange={(_, checked) => setShowInstallments(checked)}
                            />
                        }
                        label="Exibir as parcelas"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Comissões"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Comissões', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countReportCommissionRows}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                    defaultSortReference="date"
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de Registros</TableCell>
                                    <TableCell colSpan={1}>{countReportCommissionRows}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total de Comissões</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalCommissionValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total de Comissões de Pedidos</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalCommissionValueFromRequests)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total de Comissões de Ordens de Serviço</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(totalCommissionValueFromServiceOrders)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}