export type Variant = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | undefined;

export const getVariant = (value: string) => {
    let v: Variant = undefined;

    const primaryTexts = ['Pago', 'Escolhido', 'Atendido', 'Aprovado', 'Finalizado', 'Vendido'];
    const secondaryTexts = ['Em aberto', 'Agendado', 'Em trâmite'];
    const successTexts = ['Lançado em Pedidos', 'Autorizado', 'Pendente', 'Devolvido'];
    const warningTexts = ['Em andamento', 'Vencendo', 'Respondido', 'Enviado', 'Atrasado'];
    const dangerTexts = ['Cancelado', 'Erro de autorização', 'Vencido', 'Falho'];

    if(primaryTexts.includes(value)) {
        return 'primary';
    }
    if(secondaryTexts.includes(value)) {
        return 'secondary';
    }
    if(successTexts.includes(value)) {
        return 'success';
    }
    if(warningTexts.includes(value)) {
        return 'warning';
    }
    if(dangerTexts.includes(value)) {
        return 'danger';
    }
}
