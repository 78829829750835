import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListConsignment } from '../pages/Seller/Consignment/List-Consignment';
import { NewConsignment } from '../pages/Seller/Consignment/New-Consignment';
import { EditConsignment } from '../pages/Seller/Consignment/Edit-Consignment';

export function SellerConsignments() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Consignações do Vendedor");
    
    return (
        <Switch>
            <Route path="/vendedores/:sellerId/consignacoes/adicionar" component={NewConsignment}/>
            <Route path="/vendedores/:sellerId/consignacoes/duplicar/:idToClone" component={NewConsignment}/>
            <Route path="/vendedores/:sellerId/consignacoes/:id" component={EditConsignment}/>
            <Route path="/vendedores/:sellerId/consignacoes" component={ListConsignment}/>
        </Switch>
    );
}