import React, { useState, useEffect, useRef } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid,
    Checkbox,
    MenuItem,
    FormControlLabel,
    IconButton
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import ModalError from '../../components/ModalError';
import api from '../../services/Api';
import { useHistory } from 'react-router';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { Profile } from '../../types/Profile';
import { Autocomplete } from '@material-ui/lab';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { frequency, yesOrNo, durationType as durationTypeEnum } from '../../types/SubscriptionPlan';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function NewSubscriptionPlan() {
    const history = useHistory();

    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Plano");

    const [profiles, setProfiles] = useState<Profile[]>([]);

    const [name, setName] = useState("");
    const [nameInvalid, setNameInvalid] = useState(false);

    const [activeTab, setActiveTab] = useState("details");
    const [value, setValue] = useState(0);
    const [description, setDescription] = useState("");
    const [profileId, setProfileId] = useState(0);
    const [maxCustomers, setMaxCustomers] = useState(0);
    const [maxProducts, setMaxProducts] = useState(0);
    const [maxNfe, setMaxNfe] = useState(0);
    const [maxNfce, setMaxNfce] = useState(0);
    const [maxCte, setMaxCte] = useState(0);
    const [maxMdfe, setMaxMdfe] = useState(0);
    const [maxNfse, setMaxNfse] = useState(0);
    const [maxBillets, setMaxBillets] = useState(0);
    const [maxEvents, setMaxEvents] = useState(0);
    const [maxUsers, setMaxUsers] = useState(0);
    const [maxLoggedUsers, setMaxLoggedUsers] = useState(0);
    const [maxStorage, setMaxStorage] = useState(0);
    const [maxBranches, setMaxBranches] = useState(0);
    const [paymentFrequency, setPaymentFrequency] = useState<string>(frequency.MONTHLY);
    const [paymentFrequencyDaysAmount, setPaymentFrequencyDaysAmount] = useState("");
    const [durationType, setDurationType] = useState<string>(durationTypeEnum.NO_DURATION);
    const [duration, setDuration] = useState("");
    const [visible, setVisible] = useState(yesOrNo.YES);

    const imgFileInput = useRef<HTMLInputElement>(null);
    const [img, setImg] = useState("");
    const [savedImg, setSavedImg] = useState("");

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const planIsFree = value <= 0;

    useEffect(() => {
        getProfiles();
    }, []);

    async function getProfiles() {
        const {data} = await api.get<Profile[]>('profiles');
        setProfiles(data);
    }

    function inputsVerify(): boolean {

        if (!name) {
            setMsgError("Preencha o nome corretamente")
            setNameInvalid(true);
            return false;

        }

        if (!profileId) {
            setMsgError("Selecione um perfil")
            return false;
        }

        if (!Number(duration) && durationType !== durationTypeEnum.NO_DURATION) {
            setActiveTab("details");
            setMsgError("A duração do plano deve ser maior que 0(zero)!")
            return false;
        }

        if (paymentFrequency === frequency.DAYS && !Number(paymentFrequencyDaysAmount)) {
            setActiveTab("details");
            setMsgError("Preencha a quantidade de dias da periodicidade corretamente");
            return false;
        }

        if(maxUsers === 0) {
            setActiveTab("limits");
            setMsgError("Máximo de usuários não pode ser igual à 0(zero)!")
            return false;
        }

        return true;

    }

    function handleChangeName(event: any) {
        setName(event.target.value);
        setNameInvalid(!event.target.value ? true : false);
    }

    function handleUploadClick() {
        if(imgFileInput && imgFileInput.current) {
            imgFileInput.current.click();
        }
    }

    function handleChangeImgFile(event: any) {
        const input: HTMLInputElement = event.target;
        if(!input.files || input.files.length === 0) return;
        const fileUrl = window.URL.createObjectURL(input.files[0]);
        setImg(fileUrl);
    }

    function handleChangeMoneyInput(event: any, setter: Function) {
        setter(formatToFloat(event.target.value));
    }

    function handleChangeLimitInput(event: any, setter: Function) {
        let val = parseInt(event.target.value);
        if(val < -1) {
            val = -1;
        }
        setter(val);
    }

    function handleChangeProfile(event: React.ChangeEvent<{}>, profile: Profile | null) {
        setProfileId(profile?.id ?? 0);
    }

    function handleChangeDurationType(newDurationType: string) {
        setDurationType(newDurationType);
        if(newDurationType === durationTypeEnum.NO_DURATION) {
            setDuration('');
        }
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) {
            setIsSubmit(false)
            setShowModalError(true);
            return;
        }

        const raw = {
            name,
            profileId,
            description,
            value,
            max_customers: maxCustomers,
            max_products: maxProducts,
            max_nfe: maxNfe,
            max_nfce: maxNfce,
            max_cte: maxCte,
            max_mdfe: maxMdfe,
            max_nfse: maxNfse,
            max_billets: maxBillets,
            max_events: maxEvents || -1,
            max_users: maxUsers,
            max_logged_users: maxLoggedUsers || -1,
            max_storage: maxStorage || -1,
            max_branches: maxBranches || 0,
            paymentFrequency: !planIsFree ? paymentFrequency : frequency.NONE,
            paymentFrequencyDaysAmount: Number(paymentFrequencyDaysAmount) || null,
            durationType: planIsFree ? durationType : durationTypeEnum.NO_DURATION,
            duration: planIsFree && duration ? duration : null,
            visible,
        };

        try {
            const response = await api.post('subscriptionPlans', raw);

            let uploadSuccess = true;
            try {
                uploadSuccess = await uploadLogo(response.data.id);
            } catch (error) {
                uploadSuccess = false;
                setMsgError('Erro ao salvar a imagem');
                setShowModalError(true);
            }

            history.push("/planos");
        } catch (error) {
            setMsgError('Erro ao salvar plano');
            setShowModalError(true);
            setIsSubmit(false);
        }
    }

    async function uploadLogo(id: number): Promise<boolean> {
        if(imgFileInput && imgFileInput.current) {
            if(!imgFileInput.current.files || imgFileInput.current.files.length === 0) {
                return false;
            }
            const imageFile = imgFileInput.current.files[0];
            if(!imageFile) {
                return false;
            }

            var formFile = new FormData();
            formFile.append('image', imageFile, imageFile.name);
            
            const response = await api.post('/image', formFile);
            const raw = {
                img: response.data
            }

            await api.put(`subscriptionPlans/${id}`, raw);
            return true;
        }

        return false;
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">

                    <div className="col-lg-4">
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome do Plano*"
                            margin="normal"
                            variant="outlined"
                            value={name}
                            onChange={(e) => handleChangeName(e)}
                            error={nameInvalid}
                        />
                    </div>

                    <div className="col-lg-2">
                        <NumericFormat 
                            className="inputSmaller"
                            label="Valor"
                            value={formatCurrency(value)}
                            onChange={(e) => handleChangeMoneyInput(e, setValue)}
                            endAdornment={(
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip-top'>
                                            Se o valor for igual a zero, o plano automaticamente será GRATUITO
                                        </Tooltip>
                                    }
                                >
                                    <IconButton
                                        edge="end"
                                    >
                                        <i className="flaticon-info"></i>
                                    </IconButton>
                                </OverlayTrigger>
                            )}
                        />
                    </div>

                    <div className="col-lg-3">
                        <Autocomplete
                            size="small"
                            noOptionsText="Sem opções"
                            options={profiles}
                            getOptionLabel={({name}) => name}
                            onChange={(event, newInputValue) => handleChangeProfile(event, newInputValue)}
                            renderInput={(params) =>
                                <TextField
                                    size="small"
                                    { ...params }
                                    label="Perfil"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            }
                        />
                    </div>

                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            id='outlined-select-currency'
                            select
                            label='Visível para empresas'
                            required
                            SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={(e) => setVisible(e.target.value as yesOrNo)}
                            value={visible}
                            >
                            <MenuItem value={yesOrNo.YES}>
                                    Sim
                            </MenuItem>
                            <MenuItem value={yesOrNo.NO}>
                                    Não
                            </MenuItem>
                        </TextField>
                    </div>

                    <div className="col-lg-1 d-flex align-items-start justify-content-end">
                        <Button
                            type='submit'
                            className='mt-4'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                    </div>

                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newsubscription-form-tabs">
                    <Tab eventKey="details" title="Detalhes">
                        <div className="row">
                            <div className="col">
                                <FormControlLabel
                                    value="end"
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            onChange={(e, checked) => {
                                                if (checked) {
                                                    setValue(0);
                                                }
                                            }}
                                            checked={planIsFree}
                                        />
                                    )}
                                    label="Plano gratuito"
                                    labelPlacement="end"
                                    className="ml-1"
                                />
                            </div>
                        </div>
                        <div className="row">
                            {!planIsFree && (
                                <>
                                    <div className="col-lg-4">
                                        <TextField
                                            value={paymentFrequency}
                                            onChange={(e) => {
                                                setPaymentFrequency(e.target.value);
                                                setPaymentFrequencyDaysAmount("");
                                            }}
                                            size="small"
                                            select
                                            label="Periodicidade"
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={frequency.MONTHLY}
                                        >
                                            <MenuItem value={frequency.DAYS}>
                                                Dias
                                            </MenuItem>
                                            <MenuItem value={frequency.MONTHLY}>
                                                Mensal
                                            </MenuItem>
                                            <MenuItem value={frequency.BIMONTHLY}>
                                                Bimestral
                                            </MenuItem>
                                            <MenuItem value={frequency.TRIMONTHLY}>
                                                Trimestral
                                            </MenuItem>
                                            <MenuItem value={frequency.QUARTERLY}>
                                                Quadrimestral
                                            </MenuItem>
                                            <MenuItem value={frequency.SEMIANNUAL}>
                                                Semestral
                                            </MenuItem>
                                            <MenuItem value={frequency.ANNUAL}>
                                                Anual
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    {paymentFrequency === frequency.DAYS && (
                                        <div className="col-lg-2">
                                            <NumericFormat
                                                label="Quantidade de dias"
                                                className="mx-2"
                                                withPrefix={false}
                                                thousandSeparator={''}
                                                decimalScale={0}
                                                value={paymentFrequencyDaysAmount}
                                                onChange={(e) => setPaymentFrequencyDaysAmount(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                            {planIsFree && (
                                <>
                                    <div className="col-lg-2">
                                        <TextField
                                            value={durationType}
                                            onChange={(e) => handleChangeDurationType(e.target.value)}
                                            size="small"
                                            select
                                            label="Tipo Duração"
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            <MenuItem value={durationTypeEnum.NO_DURATION}>
                                                Indeterminado
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.DAYS}>
                                                Dias
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.MONTHS}>
                                                Meses
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.TWO_MONTHS}>
                                                Bimestres
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.THREE_MONTHS}>
                                                Trimestres
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.FOUR_MONTHS}>
                                                Quadrimestres
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.SEMESTERS}>
                                                Semestres
                                            </MenuItem>
                                            <MenuItem value={durationTypeEnum.YEARS}>
                                                Anos
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className="col-lg-2">
                                        <NumericFormat
                                            label="Duração"
                                            className="mx-2"
                                            withPrefix={false}
                                            thousandSeparator={''}
                                            decimalScale={0}
                                            value={duration}
                                            onChange={(e) => setDuration(e.target.value)}
                                            disabled={durationType === durationTypeEnum.NO_DURATION}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <TextField
                                    label="Descrição"
                                    multiline
                                    rows="6"
                                    margin="normal"
                                    variant="outlined"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 text-center">
                                <img
                                    src={img ? img : toAbsoluteUrl("/media/placeholder.png")}
                                    className="img-fluid d-block border"
                                    alt=""
                                />    
                                <Button
                                    className="mt-3 mb-2"
                                    variant="primary"
                                    onClick={handleUploadClick}
                                >
                                    Upload Imagem
                                </Button>
                                <input
                                    type="file"
                                    className="d-none"
                                    ref={imgFileInput}
                                    onChange={(e) => handleChangeImgFile(e)}
                                    accept="image/png, image/jpeg"
                                />
                                {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="limits" title="Limites">
                        <div className="row">
                            <div className="col">
                                <span className="text-danger">-1 = Infinito</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Clientes/Fornecedores"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxCustomers}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxCustomers)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Produtos/Serviços"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxProducts}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxProducts)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. NFe emitidas por período"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxNfe}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxNfe)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. NFCe emitidas por período"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxNfce}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxNfce)}
                                />
                            </div>
                            {/* <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. CTe"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxCte}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxCte)}
                                />
                            </div> */}
                            {/* <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. MDFe"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxMdfe}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxMdfe)}
                                />
                            </div> */}
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. NFSe emitidas por período"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxNfse}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxNfse)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Boletos por período"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxBillets}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxBillets)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Usuários"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxUsers}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxUsers)}
                                />
                            </div>
                            <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Empresas Filiais"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxBranches}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxBranches)}
                                />
                            </div>
                            {/* <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Max. Usuários Logados"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxLoggedUsers}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxLoggedUsers)}
                                />
                            </div> */}
                            {/* <div className="col-lg-3">
                                <NumericFormat 
                                    className="inputSmaller"
                                    label="Armazenamento (Mb)"
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    withPrefix={false}
                                    allowNegative={true}
                                    value={maxStorage}
                                    onChange={(e) => handleChangeLimitInput(e, setMaxStorage)}
                                />
                            </div> */}
                        </div>
                    </Tab>
                </Tabs>
            </form>
        </div>
    );
}