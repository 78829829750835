
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import nfApiHelper from "../utils/nfApiHelper";

type ModalNfDetailsProps = {
    showModalDetails: boolean;
    setShowModalDetails: React.Dispatch<React.SetStateAction<boolean>>;
    nfceDetails?: any;
    cancelNote: boolean;
    setCancelNote: React.Dispatch<React.SetStateAction<boolean>>;
    cancelReason: string;
    setCancelReason: React.Dispatch<React.SetStateAction<string>>;
    cancelError: string;
    onConfirmCancel: () => Promise<void>;
}

export function ModalNfDetails({
    showModalDetails,
    setShowModalDetails,
    nfceDetails,
    cancelNote,
    setCancelNote,
    cancelReason,
    setCancelReason,
    cancelError,
    onConfirmCancel,
}: ModalNfDetailsProps) {
    return (
        <Modal
            show={showModalDetails}
            onHide={() => setShowModalDetails(false)}
            aria-labelledby="contained-modal-warning"
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Nota Fiscal Nº {nfceDetails?.id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {nfceDetails && (
                    <>
                        {nfApiHelper.isProccessing(nfceDetails.api_situation) && (
                            <div className="alert alert-info"><i className="la la-check"></i> NFC-e em processamento para autorização</div>
                        )}
                        {nfApiHelper.hasError(nfceDetails.api_situation) && (
                            <>
                                <div className="alert alert-danger"><i className="la la-times"></i> Erro de autorização </div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="default">
                                                <strong>Código</strong>
                                            </TableCell>
                                            <TableCell padding="default">
                                                <strong>Mensagem</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {nfceDetails.api_last_response['body']['status_sefaz']}
                                            </TableCell>
                                            <TableCell>
                                                {nfceDetails.api_last_response['body']['mensagem_sefaz']}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </>
                        )}
                        {nfApiHelper.isAuthorized(nfceDetails.api_situation) && (
                            <>
                                <div className="alert alert-success"><i className="la la-check"></i> NFC-e autorizada e emitida com sucesso </div>
                                <p>Chave NFE: {nfceDetails.api_last_response['body']['chave_nfe']}</p>
                                <p>Número da Nota: {nfceDetails.api_last_response['body']['numero']}</p>

                                {cancelNote && (
                                    <div className="row col-lg-8">
                                        <TextField
                                            label="Justificativa do cancelamento *"
                                            multiline
                                            rows="4"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            inputProps={{ maxLength: 5000 }}
                                            value={cancelReason}
                                            onChange={(e) => setCancelReason(e.target.value)}
                                            error={!!cancelError}
                                        />
                                        <small className="text-danger">{cancelError}</small>
                                    </div>
                                )}
                            </>
                        )}
                        {nfApiHelper.isCanceled(nfceDetails.api_situation) && (
                            <>
                                <div className="alert alert-danger"><i className="la la-times"></i> NFC-e cancelada </div>
                            </>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {nfceDetails && (
                    <>
                        {!cancelNote && nfApiHelper.isAuthorized(nfceDetails.api_situation) && (
                            <>
                                {!!nfceDetails.api_last_response['body']['caminho_danfe'] && !!nfceDetails.api_last_response && (
                                    <a href={`${nfceDetails.api_last_response['url']}${nfceDetails.api_last_response['body']['caminho_danfe']}`} target="_blank" className="btn btn-success">Imprimir DANFE</a>
                                )}
                                {!!nfceDetails.xmlUrl && (
                                    <a href={`${nfceDetails.xmlUrl}`} target="_blank" className="btn btn-success" download={nfceDetails.xmlFileName}>Baixar XML</a>
                                )}
                                <Button variant="danger" onClick={() => setCancelNote(true)}>Cancelar Nota</Button>
                            </>
                        )}
                        {!cancelNote && nfApiHelper.isCanceled(nfceDetails.api_situation) && !!nfceDetails.xmlCancelUrl && (
                            <a href={`${nfceDetails.xmlCancelUrl}`} target="_blank" className="btn btn-success" download={nfceDetails.xmlCancelFileName}>Baixar XML</a>
                        )}

                        {cancelNote && (
                            <>
                                <Button variant="danger" onClick={onConfirmCancel}>Cancelar Nota</Button>
                                <Button variant="secondary" onClick={() => setCancelNote(false)}>Voltar</Button>
                            </>
                        )}
                    </>
                )}
                <Button variant="secondary" onClick={() => setShowModalDetails(false)}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}