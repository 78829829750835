import api from "./Api";

type InvoiceEmailData = {
    invoice: any;
    refName: string;
    companyId: string;
    companyName: string;
    contactName: string;
    subject: string;
    message: string;
}

class EmailInvoiceService {
    async getAllEmailsByInvoice(invoiceId: string | number, type: string) {
        try {
            const { data } = await api.get<any[]>(`email-invoice/${type}/${invoiceId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendEmail(emails: string[], {
        invoice,
        refName,
        companyId,
        companyName,
        contactName,
        subject,
        message,
    }: InvoiceEmailData) {
        const raw = {
            to: emails,
            id: invoice.id,
            type: refName,
            companyName: companyName,
            companyId,
            message: message,
            subject: subject,
            contactName: contactName || companyName,
            billetUrl: invoice.billetUrl,
        };

        await api.post('email-invoice/send', raw);
    };
}

export default new EmailInvoiceService();