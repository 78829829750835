export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken, selectedCompanyId }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      if (selectedCompanyId && !config.url.startsWith('https://')) {
        config.headers['X-CompanyId'] = selectedCompanyId;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
