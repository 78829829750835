import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { getSituationText } from '../../../utils/getSituationText';
import { getSaleTypeText } from '../../../utils/getSaleTypeText';
import { formatStringDateToLocale } from '../../../utils/dateFormat';
import { extractDateStringFromTimestamp } from '../../../utils/dateTimeHelper';

type ReportInvoicingResponse = {
    rows: any;
    count: number;
    pdvTotalValue: number;
    nfeTotalValue: number;
    nfseTotalValue: number;
    nfceTotalValue: number;
    budgetTotalValue: number;
    requestsTotalValue: number;
    serviceOrderTotalValue: number;
    totalSalesValue: number;
}

type Filters = {
    customerInput: string;
    typeInput: string;
    status: string;
    dateMin: string;
    dateMax: string;
    situation?: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "sale", value: "Venda" },
    { reference: "customer", value: "Cliente" },
    { reference: "status", value: "Situação" },
    { reference: "invoicingType", value: "Tipo" },
    { reference: "value", value: "Valor" },
    { reference: "nfSituation", value: "Situação nota fiscal" },
    { reference: "date", value: "Data" },
];

export function ListReportInvoicing() {
    const [countTotalRecords, setCountTotalRecords] = useState(0);
    const [pdvTotalValue, setPdvTotalValue] = useState(0);
    const [nfeTotalValue, setNfeTotalValue] = useState(0);
    const [nfseTotalValue, setNfseTotalValue] = useState(0);
    const [nfceTotalValue, setNfceTotalValue] = useState(0);
    const [budgetTotalValue, setBudgetTotalValue] = useState(0);
    const [requestsTotalValue, setRequestsTotalValue] = useState(0);
    const [serviceOrderTotalValue, setServiceOrderTotalValue] = useState(0);
    const [totalSalesValue, setTotalSalesValue] = useState(0);
    const [status, setStatus] = useState('');
    const [sales, setSales] = useState<any>([]);
    const [countSalesPaginated, setCountSalesPaginated] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [customerInput, setCustomerInput] = useState('');
    const [typeInput, setTypeInput] = useState('');
    const [dateMin, setDateMin] = useState('');
    const [dateMax, setDateMax] = useState('');
    const [situation, setSituation] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportInvoicingResponse>("/report/invoicing", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows, 
            count, 
            budgetTotalValue, 
            nfceTotalValue, 
            nfeTotalValue, 
            nfseTotalValue, 
            pdvTotalValue, 
            requestsTotalValue, 
            serviceOrderTotalValue, 
            totalSalesValue
        } = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow: any) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell: any) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        });

        setExportTotals([
            { name: 'Quantidade de registros', value: count },
            { name: 'Valor total de pedidos', value: formatNumberToString(requestsTotalValue) },
            { name: 'Valor total de notas fiscais', value: formatNumberToString(nfeTotalValue) },
            { name: 'Valor total de notas de serviço', value: formatNumberToString(formatToFloat(nfseTotalValue)) },
            { name: 'Valor total de notas de consumidor', value: 'R$ ' + formatNumberToString(formatToFloat(nfceTotalValue)) },
            { name: 'Valor total de orçamentos', value: 'R$ ' + formatNumberToString(formatToFloat(budgetTotalValue)) },
            { name: 'Valor total de ordens de serviço', value: 'R$ ' + formatNumberToString(formatToFloat(serviceOrderTotalValue)) },
            { name: 'Valor total de vendas PDV', value: 'R$ ' + formatNumberToString(formatToFloat(pdvTotalValue)) },
            { name: 'Valor total de vendas', value: 'R$ ' + formatNumberToString(formatToFloat(totalSalesValue)) },
        ]);

        setExportBodyData(exportDataList);
        setCountTotalRecords(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportInvoicingResponse>("/report/invoicing", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows, 
            count, 
            budgetTotalValue, 
            nfceTotalValue, 
            nfeTotalValue, 
            nfseTotalValue, 
            pdvTotalValue, 
            requestsTotalValue, 
            serviceOrderTotalValue, 
            totalSalesValue
        } = data;

        setSales(rows);
        setCountSalesPaginated(count);
        setBudgetTotalValue(budgetTotalValue);
        setNfceTotalValue(nfceTotalValue);
        setNfeTotalValue(nfeTotalValue);
        setNfseTotalValue(nfseTotalValue);
        setPdvTotalValue(pdvTotalValue);
        setRequestsTotalValue(requestsTotalValue);
        setServiceOrderTotalValue(serviceOrderTotalValue);
        setTotalSalesValue(totalSalesValue);
        setLastSortDirection(sortDirection);
        setLastSortReference(sortReference);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];

        sales.forEach((product: any) => {
            const data = mountBodyDataRow(product);

            list.push(data);
        });

        setBodyData(list);
    }, [sales]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: any) {
        const data: BodyDataBaseProps[] = [
            { for: "sale", value: String(reportRow.id) ?? '-'},
            { for: "customer", value: CustomerService.getCustomerName(reportRow.customerEntity) ?? '-'},
            { for: "status", value: reportRow.status ? getSituationText(reportRow.status) : getSituationText(reportRow.situation) },
            { for: "invoicingType", value: getSaleTypeText(reportRow.saleType) ?? '-'},
            { for: "value", value: formatNumberToString(reportRow.totalValue | reportRow.valueService) ?? '-'},
            { for: "nfSituation", value: reportRow.api_situation ?? '-' },
            { for: "date", value: formatStringDateToLocale(extractDateStringFromTimestamp(reportRow.saleDate)) ?? '-' },
        ];

        return data;
    }

    const clearSearch = () => {
        setCustomerInput('');
        setTypeInput('');
        setStatus('');
        setDateMin('');
        setDateMax('');
    };

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customerInput,
            typeInput,
            status,
            dateMin,
            dateMax,
            situation
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        customerInput,
        typeInput,
        status,
        dateMin,
        dateMax,
        situation
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Faturamento', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <div className="card card-body pt-4 newProductWrapper" >
            <div className="row d-flex align-items-center">
                <div className="col-lg-6">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerInput}
                        onSelect={(option) => setCustomerInput(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customerInput) return null;
                            return loadedOptions.find((option) => option.id === Number(customerInput)) ?? CustomerService.getCustomerById(customerInput)
                        }}
                    />
                </div>

                <div className="col-lg-6">
                    <TextField
                        select
                        label="Status"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem key="0" value="all">Todos</MenuItem>
                        <MenuItem key="1" value="open">Em aberto</MenuItem>
                        <MenuItem key="2" value="inProgress">Em andamento</MenuItem>
                        <MenuItem key="3" value="attended">Finalizado</MenuItem>
                        <MenuItem key="4" value="canceled">Cancelado</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-5">
                    <TextField
                        select
                        size="small"
                        label="Tipo"
                        margin="normal"
                        variant="outlined"
                        value={typeInput}
                    >
                        <MenuItem key="0" value="all" onClick={() => setTypeInput('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="nfe" onClick={() => setTypeInput('nfe')}>
                            Nota Fiscal
                        </MenuItem>

                        <MenuItem key="2" value="request" onClick={() => setTypeInput('request')}>
                            Pedidos
                        </MenuItem>

                        <MenuItem key="3" value="budget" onClick={() => setTypeInput('budget')}>
                            Orçamento
                        </MenuItem>

                        <MenuItem key="4" value="pdv" onClick={() => setTypeInput('pdv')}>
                            PDV
                        </MenuItem>

                        <MenuItem key="5" value="nfce" onClick={() => setTypeInput('nfce')}>
                            Nota Fiscal do Consumidor
                        </MenuItem>

                        <MenuItem key="6" value="nfse" onClick={() => setTypeInput('nfse')}>
                            Nota Fiscal de Serviço
                        </MenuItem>
                        <MenuItem key="7" value="serviceOrder" onClick={() => setTypeInput('serviceOrder')}>
                            Ordem de Serviço
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-3">
                    <TextField
                        select
                        size="small"
                        label="Status nota fiscal"
                        margin="normal"
                        variant="outlined"
                        value={situation}
                    >
                        <MenuItem key="0" value="" onClick={() => setSituation('')}>
                            Nenhum
                        </MenuItem>
                        <MenuItem key="1" value="processando_autorizacao" onClick={() => setSituation('processando_autorizacao')}>
                            Em andamento
                        </MenuItem>
                        <MenuItem key="2" value="autorizado" onClick={() => setSituation('autorizado')}>
                            Autorizado
                        </MenuItem>
                        <MenuItem key="3" value="erro_autorizacao" onClick={() => setSituation('erro_autorizacao')}>
                            Erro de autorização
                        </MenuItem>
                        <MenuItem key="4" value="cancelado" onClick={() => setSituation('cancelado')}>
                            Cancelado
                        </MenuItem>
                        <MenuItem key="5" value="erro_api" onClick={() => setSituation('erro_api')}>
                            Denegado
                        </MenuItem>
                        <MenuItem key="6" value="aguardando" onClick={() => setSituation('aguardando')}>
                            Aguardando
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <TextField
                        type="Date"
                        label="Data de Emissão"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                DE
                            </InputAdornment>
                            ),
                        }}
                        value={dateMin}
                        onChange={(e: any) => setDateMin(e.target.value)}
                    />

                    <TextField
                        type="Date"
                        label="Data de Emissão"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        className="ms-20"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                ATÉ
                            </InputAdornment>
                            ),
                        }}
                        value={dateMax}
                        onChange={(e: any) => setDateMax(e.target.value)}
                    />

                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Faturamento"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Faturamento', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={countSalesPaginated}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                    />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de registros:</TableCell>
                                    <TableCell colSpan={1}>{countTotalRecords}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de pedidos:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(requestsTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de notas fiscais:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(nfeTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de notas de serviço:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(nfseTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de notas de consumidor:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(nfceTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de orçamentos:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(budgetTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de ordens de serviço:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(serviceOrderTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de vendas PDV:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(pdvTotalValue)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total de vendas:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(totalSalesValue)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}