import React, { useEffect, useState } from 'react';

import '../../style.css';
import ModalError from '../../components/ModalError';
import { useHistory, useParams } from 'react-router';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import quotationService from '../../services/QuotationService';
import { QuotationProduct, QuotationStatus, QuotationSupplier, QuotationSupplierStatus } from '../../types/Quotation';
import { Product as SelectableProduct } from '../../services/ProductService';
import { Situation } from '../../types/Situation';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { ListQuotationProducts } from './ListQuotationProducts';
import { TextField } from '@material-ui/core';
import { ListQuotationSuppliers } from './ListQuotationSuppliers';
import quotationSupplierService from '../../services/QuotationSupplierService';
import LogService from '../../services/LogService';

export function NewQuotation() {
  const [activeTab, setActiveTab] = useState("products");

  //Fields
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');
  const [products, setProducts] = useState<QuotationProduct[]>([{
    id: 0,
    productId: 0,
    amount: 0,
    INSTANCE: {} as SelectableProduct,
  }]);
  const [quotationSuppliers, setQuotationSuppliers] = useState<QuotationSupplier[]>([{
    id: 0,
    quotationId: 0,
    supplier: 0,
  }]);

  const [showModalError, setShowModalError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const { idToClone } = useParams<{ idToClone: string }>();
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if(!idToClone) return;
    
    async function getBillToCloneData() {
      const quotationToClone = await quotationService.getQuotationById(idToClone);

      if (!quotationToClone) return;

      setName(quotationToClone.name);
      setComments(quotationToClone.comments ?? '');
      setProducts(quotationToClone.products ? JSON.parse(quotationToClone.products) : [{
        id: 0,
        productId: 0,
        amount: 0,
        INSTANCE: {} as SelectableProduct,
      }]);

      const quotationSuppliers = await quotationSupplierService.getQuotationSuppliersByQuotationId(idToClone, { withSupplier: true });

      setQuotationSuppliers(
        quotationSuppliers.map((suppliers) => ({
          ...suppliers,
          id: 0,
          status: QuotationSupplierStatus.pending,
        }))
      );
    }

    getBillToCloneData();
  }, [idToClone]);

  async function onSubmit(event: any) {
    event.preventDefault();
    try {
      setIsSubmit(true);

      if (!inputsVerify() || !productsVerify()) {
        setIsSubmit(false);
        return;
      }

      const quotationProductsFiltered = products.filter(productObj => productObj.productId !== 0);
      const quotationProducts = quotationProductsFiltered.map((productObj, index) => ({
        ...productObj,
        id: index + 1,
      }));

      const situation: Situation[] = [];

      situation.push({
        dateSituation: format(Date.now(), "yyyy-MM-dd"),
        commentsSituation: "",
        statusSituation: QuotationStatus.open,
      });

      const raw = {
        name,
        comments: comments || null,
        responsibleName: `${user.firstname} ${user.lastname}`,
        responsibleId: user.id,

        products: JSON.stringify(quotationProducts),
        situation: JSON.stringify(situation),
        status: QuotationStatus.open,
      };

      const savedQuotation = await quotationService.createQuotation(raw);

      // Salvar fornecedores
      const quotationSuppliersFiltered = quotationSuppliers.filter(supplierObj => !!supplierObj.supplier);
      const quotationSuppliersMapped = quotationSuppliersFiltered.map(supplierObj => ({ ...supplierObj, quotationId: savedQuotation.id }));

      await quotationSupplierService.saveMultipleQuotationSuppliers(quotationSuppliersMapped);

      history.push("/cotacoes");

      LogService.logRegister({
        itemId: savedQuotation.id,
        module: 'Cotações',
        itemName: 'Cotação'
      });

    } catch (error) {
      console.log(error);
      setIsSubmit(false);
    }
  };

  function inputsVerify() {
    if (!name) {
      setMsgError('Informe o nome da cotação!');
      setShowModalError(true);
      return false;
    }

    return true;
  }

  function productsVerify() {
    if (!products || products.length === 0) {
      setActiveTab("products");
      setMsgError('Informe os itens da cotação!');
      setShowModalError(true);
      return false;
    }

    const hasSelectedProduct = products.find((productObj) => productObj.productId !== 0);
    if (!hasSelectedProduct) {
      setActiveTab("products");
      setMsgError('Informe os itens da cotação!');
      setShowModalError(true);
      return false;
    }

    return true;
  }

  const [isSubmit, setIsSubmit] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  return (
    <div className="card card-custom gutter-b mt-2 newProductWrapper">
      <ModalError
        showModalError={showModalError}
        setShowModalError={setShowModalError}
        msgError={msgError}
      />
      <form onSubmit={onSubmit}>
        <div className="card-header row m-0 pt-2 pb-0">
          <div className="col-6 m-0 p-0">
            <h4 className="card-label m-0 mt-2">
              Nova Cotação
            </h4>
          </div>
          <div className='col-lg-6 d-flex justify-content-end mb-3 pr-0'>
            <Button
              type='submit'
              variant="primary"
              disabled={isSubmit}
            >
              {
                isSubmit ?
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className='ml-2'>
                      Aguarde...
                    </span>
                  </>
                  :
                  <span>Salvar</span>
              }
            </Button>
          </div>
        </div>
        <div className="card-body mt-0 pt-0">
          <div className="row">
            <div className="col-lg-4">
              <TextField
                size="small"
                label="Nome"
                margin="normal"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col">
              <TextField
                size="small"
                label="Observações"
                margin="normal"
                variant="outlined"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>

          <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' className="mb-5">
            <Tab eventKey='products' title='Produtos'>
              <ListQuotationProducts
                quotationProducts={products}
                setQuotationProducts={setProducts}
              />
            </Tab>
            <Tab eventKey='suppliers' title='Fornecedores'>
              <ListQuotationSuppliers
                quotationSuppliers={quotationSuppliers}
                setQuotationSuppliers={setQuotationSuppliers}
              />
            </Tab>
          </Tabs>
        </div>

      </form>
    </div>
  );
}