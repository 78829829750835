import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Search } from '../../../components/Search';
import { ListWithModalChangeSituation } from '../../../components/ListWithModalChangeSituation';
import { BodyDataBaseProps, HeadDataBaseProps } from '../../../components/ListWithModalChangeSituation';
import api from '../../../services/Api';
import useBackendLoad from '../../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getDate } from '../../../utils/dateTimeHelper';

type Filters = {
	searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
	{ reference: "id", value: "Nº" },
	{ reference: "name", value: "Nome" },
	{ reference: "form_name", value: "Nome do formulário" },
	{ reference: "description", value: "Descrição" },
	{ reference: "createdAt", value: "Data de criação" },
	{ reference: "updatedAt", value: "Data de atualização" },
]

export function ListSolicitationProcessForm() {
	const { user } = useSelector((state: any) => state.auth);

	const { push: pushHistory, location: { pathname } } = useHistory();

	const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [processForm, setProcessForm] = useState<any[]>([]);

	const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

	const fetchProcessForm = async () => {
		try {
			const results = await api.get("/bpm/solicitacao-processo-formulario/displayAll");
			setProcessForm(results.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchProcessForm();
	}, []);

	useEffect(() => {
		const list: BodyDataBaseProps[][] = [];
		const aux = processForm;

		aux.forEach((processFormAux) => {
			const data: BodyDataBaseProps[] = [
				{ for: "id", value: String(processFormAux.id), id: true },
				{ for: "name", value: processFormAux.name },
				{ for: "form_name", value: processFormAux.form_name },
				{ for: "description", value: processFormAux.description },
				{ for: "createdAt", value: getDate({ initialDate: new Date(processFormAux.createdAt) }).dateStr },
				{ for: "updatedAt", value: getDate({ initialDate: new Date(processFormAux.updatedAt) }).dateStr },
			]

			list.push(data);
		})

		setBodyData(list);
	}, [processForm]);

	const handleClickAdd = useCallback(() => {
		pushHistory(`${pathname}/0`);
	}, []);

	const handleClickEdit = useCallback((id: string) => {
		pushHistory(`${pathname}/${id}`);
	}, []);

    return (
        <div className="card card-body pt-4">
			<div className="row d-flex align-items-center">
				<div className="col-lg-9 mt-3">
					{
						user.isAccountant == 'n'
						?
							<Button
								type="button"
								variant="success"
								className="mr-2"
								onClick={handleClickAdd}
							>
								Novo processo
							</Button>
						: <></>
					}
				</div>
				<div className="col-lg-3 mt-3">
					<Search
						query={searchQuery}
						setQuery={setSearchQuery}
						onClickSearch={() => console.log(searchQuery)}
					/>
				</div>
			</div>
			<div className="mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
					triggerLoad={triggerLoad}
					setTriggerLoad={setTriggerLoad}
				/>
			</div>
        </div>
    );
}