import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../_metronic/layout";

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Link,
    Grid,
    OutlinedInput
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Modal,
    Spinner
} from "react-bootstrap";

import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { ListProducts } from './List-Products';
import logService from '../services/LogService';

import api from "../services/Api"
import { formatCurrency, formatToFloat } from '../utils/formatCurrency';
import PriceListService from '../services/PriceListService';
import { getEnabledStatusText } from '../utils/getEnabledStatusText';
import '../style.css';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../components/ApiResourceSelect';
import ProductService from '../services/ProductService';
import { ApiResourceSelectAddButton } from '../components/ApiResourceSelectAddButton';
import { NewProductModal } from '../components/Product/NewProductModal';
import useNewProductForm from '../hooks/newProductForm';
import { Product } from '../types/Product';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
    typeList: string,
    value: string,
    status: string,
}

type urlParam = {
    id: string
}

type products = {
    id: number,
    name: string,
}

export function EditPriceList() {
    const { user } = useSelector((state: any) => state.auth);
    
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Detalhes da Lista de Preços");

    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");

    const [nameInvalid, setNameInvalid] = useState(false);
    const [typeInvalid, setTypeInvalid] = useState(false);
    const [valueInvalid, setValueInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [productSelected, setProductSelected] = useState<products>({
        id: 0,
        name: ""
    });
    const [listProduct, setListProduct] = useState({
        list: [
            {
                productId: 0,
                productName: "",
                type: "",
                value: ""
            }
        ]
    });

    const { id } = useParams<urlParam>();
    const [name, setName] = useState("");
    const [priceListType, setPriceListType] = useState("");
    const [status, setStatus] = useState("");
    const [valueList, setValueList] = useState("");

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    // Modal New Product
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = (createdProduct: Product) => {
        setProductSelected({
            id: createdProduct.id,
            name: createdProduct.name
        });
    };
    
    const handleClickAddProduct = (typedText: string) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        getPriceList();
    }, []);

    async function getPriceList() {
        var response = await api.get(`/priceList/${id}`);
        var valueListDb = (response.data.value == 0) ? "" : response.data.value;
        var listProductDb = (JSON.parse(response.data.products) === null ? [] : JSON.parse(response.data.products));

        setName(response.data.name);
        setPriceListType(response.data.priceListType);
        setStatus(response.data.status);
        setValueList(valueListDb);
        setListProduct({ list: listProductDb })

        setBeforeSubmitData(response.data);
    } 


    function addProductToList() {
        var listProducts = listProduct.list;

        if (listProducts.length === 1 && listProducts[0].productId === 0) {
            listProducts[0] = {
                productId: productSelected.id,
                productName: productSelected.name,
                type: "",
                value: ""
            }
            removeProductSelected();

        } else if (productSelected.id !== 0) {
            listProducts.push({
                productId: productSelected.id,
                productName: productSelected.name,
                type: "",
                value: ""
            });
            removeProductSelected();
        }

        setListProduct({ list: listProducts });
    }

    function removeProductSelected() {
        setProductSelected({
            id: 0,
            name: ""
        });
    }

    async function deleteProductList(index: number) {
        var listProducts = listProduct.list;

        listProducts.splice(index, 1);

        setListProduct({ list: listProducts });
    }

    function clearListProducts() {
        setListProduct({ list: [] });
    }

    function changeProductList(index: number, value: string, typeInput: string) {
        var listProducts = listProduct.list;

        if (typeInput === "type") {
            listProducts[index].type = value;

        } else {
            listProducts[index].value = value;
        }

        setListProduct({ list: listProducts });
    }

    function inputsVerify(): boolean {

        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da lista corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        if (!priceListType) {
            setIsSubmit(false)
            setMsgError("Selecione o tipo corretamente")
            setShowModalExist(true);
            setTypeInvalid(true);

            return false

        }

        if (!valueList && priceListType !== '3') {
            setIsSubmit(false)
            setMsgError("Selecione o valor correspondente")
            setShowModalExist(true);
            setValueInvalid(true);

            return false

        }


        if (listProduct.list.length) {

            for (let i = 0; i < listProduct.list.length; i++) {
                if (listProduct.list[i].type == "") {

                    setIsSubmit(false)
                    setMsgError("Preencha o tipo do produto corretamente")
                    setShowModalExist(true);

                    return false
                } else if (listProduct.list[i].value == "") {

                    setIsSubmit(false)
                    setMsgError("Preencha o valor do produto corretamente")
                    setShowModalExist(true);

                    return false
                }

            }

        } else if (listProduct.list.length == 0 && priceListType == '3') {

            setIsSubmit(false)
            setMsgError("Adicione pelo menos um produto a lista")
            setShowModalExist(true);

            return false

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name": name,
            "priceListType": priceListType,
            "value": (!valueList ? null : formatToFloat(valueList)),
            "status": status,
            "products": JSON.stringify(listProduct.list)
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        const editResponse = await api.post(`/priceList/${id}`, raw);

        logService.logEdit({
            itemId: editResponse.data.id,
            itemName: editResponse.data.name,
            module: 'Lista de Preços',
            oldData: beforeSubmitData,
            newData: editResponse.data,
            formattedFields: {
                priceListType: (value) => PriceListService.getPriceListTypeText(value),
                value: (value) => formatCurrency(value),
                status: (value) => getEnabledStatusText(value),
            },
            fieldsMap: {
                name: 'Nome',
                priceListType: 'Tipo',
                value: 'Valor',
                status: 'Status',
            },
        });

        setIsSubmit(false);
        setShowModalSuccess(true);
    }

    async function apiSearchProducts(typedText: string) {
        const foundProducts = await ProductService.getAllProductsFiltered({ name: typedText });

        const listProductIds = listProduct.list.map(listItem => listItem.productId);

        return foundProducts.filter((prod) => !listProductIds.includes(prod.id));
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
            />

            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/lista-de-precos";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/lista-de-precos";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">
                {
                    user.isAccountant == "n"
                    ?
                    <div className='col-lg-12 d-flex justify-content-end'>
                        <Button
                            type='submit'
                            className='mr-3'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                    </div>
                    : <></>
                }

                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            size="small"
                            className={classes.error}
                            value={name}
                            label="Nome da Listadasdasd*"
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                                setName(e.target.value)
                                setNameInvalid(e.target.value ? false : true)
                            }}
                            error={nameInvalid}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </Grid>

                    <Grid item lg={3}>
                        <TextField
                            size="small"
                            className={classes.error}
                            select
                            label="Tipo*"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={priceListType}
                            onChange={e => {
                                setPriceListType(e.target.value)
                                setTypeInvalid(e.target.value ? false : true)                            
                            }}
                            error={typeInvalid}
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="" onClick={() => clearListProducts()}>
                                Selecione
                            </MenuItem>

                            <MenuItem key="1" value="1" onClick={() => clearListProducts()}>
                                Acréscimo em todos os produtos
                            </MenuItem>

                            <MenuItem key="2" value="2" onClick={() => clearListProducts()}>
                                Desconto em todos os produtos
                            </MenuItem>

                            <MenuItem key="3" value="3" onClick={() => setValueList("")}>
                                Lista dinâmica
                            </MenuItem>
                        </TextField>
                    </Grid>

                    {priceListType === "1" || priceListType === "2"
                        ?
                        <Grid item lg={2} className="d-flex align-items-center">
                            <NumberFormat
                                className="inputSmaller"
                                label="Valor*"
                                margin="normal"
                                withPrefix={false}
                                customInput={TextField}
                                variant="outlined"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                value={valueList}
                                onChange={(e) => {
                                    setValueList(e.target.value)
                                    setValueInvalid(e.target.value ? false : true)
                                
                                }}
                                error={valueInvalid}
                                allowNegative={false}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </Grid>
                        : ''
                    }

                    <Grid item lg={3}>
                        <TextField
                            size='small'
                            select
                            label="Status"
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="1">
                                Ativo
                            </MenuItem>

                            <MenuItem key="1" value="0">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                {priceListType === "3"
                    ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item lg={4} className="d-flex align-items-center">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Produto"
                                    getOptionLabel={(option: {id: number, name: string}) => option.name}
                                    onSelect={(option) => {
                                        option && setProductSelected({
                                            id: option.id,
                                            name: option.name
                                        })
                                    }}
                                    apiSearchHandler={apiSearchProducts}
                                    disabled={user.isAccountant == "y" ? true : false}
                                    renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Produto" onClick={() => handleClickAddProduct(typedText)} />}
                                />

                                {
                                    user.isAccountant == "n"
                                    ?
                                    <Button variant="primary" className="ml-3" onClick={() => addProductToList()}><i className="flaticon2-add p-0"></i></Button>
                                    : <></>
                                }
                            </Grid>

                            <Grid item lg={12}>
                                <h4 className="mt-5">Produtos Selecionados</h4>
                                <hr />
                            </Grid>
                        </Grid>


                    </>
                    : ''
                }

                <hr />

                {
                    listProduct.list.map(({ productId, productName, type, value }, index) => {
                        return (
                            <>
                                {
                                    (!productId)
                                        ? ''
                                        : <Grid container spacing={3}>
                                            <Grid item lg={4}>
                                                <TextField
                                                    size="small"
                                                    value={productName}
                                                    label="Nome do Produto"
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <TextField
                                                    size="small"
                                                    select
                                                    label="Tipo*"
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={type}
                                                    onChange={e => changeProductList(index, e.target.value, "type")}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >

                                                    <MenuItem key="1" value="1">
                                                        Acréscimo
                                                    </MenuItem>

                                                    <MenuItem key="2" value="2">
                                                        Desconto
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item lg={3} className="d-flex align-items-center">
                                                <NumberFormat
                                                    className="inputSmaller"
                                                    placeholder="Valor*"
                                                    margin="normal"
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    value={value}
                                                    onChange={e => changeProductList(index, e.target.value, "value")}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>
                                            <Grid item lg={2} className="d-flex align-items-center">
                                                {
                                                    user.isAccountant == "n"
                                                    ?
                                                    <Button variant="link" onClick={() => deleteProductList(index)}><i className="flaticon-delete"></i></Button>
                                                    : <></>
                                                }
                                            </Grid>
                                        </Grid>
                                }
                            </>

                        )
                    })
                }
                <span className="mt-15">&nbsp;</span>
            </form>
        </div>
    );
}