import { RecurringExpenses } from "../types/RecurringExpenses";
import api from "./Api";

class RecurringExpenseService {
    async getRecurringExpensesFiltered(filters: any) {
        const expenseResponse = await api.get<RecurringExpenses[]>('/recurringExpenses', { params: { filters } });

        return expenseResponse.data;
    }

    async resolveBillsDueDates(bills?: any[]) {
        let billsArray: any[] = bills ?? [];
        if(!bills) {
            const { data } = await api.get<RecurringExpenses[]>("recurringExpenses");
            billsArray = data;
        }

        return billsArray;
    }
}

export default new RecurringExpenseService();