/**
 * Rotas que o usuário pode acessar quando a empresa está bloqueada por falta de pagamento
 */
export function getAllowedRoutesWhenPlanPaymentIsRequired(isAccountant: boolean = false) {
    if (isAccountant) {
        return [
            '/contador/empresa-bloqueada',
            '/verificar-whatsapp',
            '/logout',
            '/auth',
            '/faturas',
            '/minha-conta',
            '/painel',
            '/contador/empresa',
            '/indicacoes',
        ];
    } else {
        return [
            '/plano/pagamento',
            '/verificar-whatsapp',
            '/logout',
            '/auth',
            '/faturas',
        ];
    }
}