export enum yesOrNo {
    YES = "y", 
    NO = "n"
}

export type CashRegister = {
    id: number,
    userId: number,
    startValue: number,
    limitValue: number,
    bankAccount: number,
    currentValue: number,
    active: yesOrNo,
    createdDate: string,
    openedDate: string,
    closedDate: string,
}