import React, {useRef, useState} from 'react';
import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import '../../style.css';
import { formatCurrency } from '../../utils/formatCurrency';
import api from '../../services/Api';
import { useHistory } from 'react-router-dom';

import { Button, Modal, Spinner } from 'react-bootstrap';
import ModalError from '../../components/ModalError';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';


type ret = {
    checked: boolean,
    idBill: number,
    billet: number,
    nameBill: string,
    dueDate: string,
    occurrenceDate: string,
    customer: string,
    value: number,
    valuePayed: number,
    fee: number,
    discount:number,
    tax: number,
    acc: number,
    occurrence: string
}

export function ReturnBillsToReceive() {
    // HOOKS
    const history = useHistory();

    
    // STATES
    const [accountBank, setAccountBank] = useState("");
    const [fileImported, setFileImported] = useState(false);
    const [nameFile, setNameFile] = useState("");
    const [rets, setRets] = useState<ret[]>([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    
    async function conciliation() {
        setIsSubmit(true);
        
        var countRets = getCountRetsChecked();
        var retsSaved = 0;

        for (var ret of rets) {

            const retDate = ret.occurrenceDate.includes('/') ? ret.occurrenceDate.split('/').reverse().join('-') : ret.occurrenceDate;

            if (ret.checked) {
                const raw = {
                    feeValue: ret.fee,
                    discountValue: ret.discount,
                    taxValue: ret.tax,
                    accValue: ret.acc, 
                    payedDate: retDate,
                    remaining: ret.value - ret.valuePayed,
                    totalPaid: ret.valuePayed,
                    status: "paid"
                }
    
                await api.put(`/billsToReceive/${ret.idBill}`, raw).then(() => {
                    retsSaved++;
                });
            }
        }

        if(countRets == 0) {
            setMsgError("Selecione pelo menos um título para conciliar!");
            setShowModalError(true);
            setIsSubmit(false);

        } else if (countRets == retsSaved) {
            setShowModalSuccess(true);
            setFileImported(false);
            setNameFile("");
            setAccountBank("");
            setRets([]);
            setIsSubmit(false);
        }   
    }

    function getCountRetsChecked() {
        var count = 0;
        
        for (var ret of rets) {
            if (ret.checked) {
                count++;
            }
        }

        return count;
    }
    
    async function readDocument(document: any) {
        if (document.length) {
            try {
                setNameFile(document[0].name);
                var formData = new FormData();
                formData.append("file", document[0], document[0].name);                
                const {data} = await api.post('/billet/read-return', formData);
                    
                console.log(data);
                
                var retsArray = [];
                for (var returnData of data) {
                    if (returnData.ocorrencia == "06") {
                        const billSearch = {
                            bank: Number(accountBank),
                            value: parseFloat(returnData.valor),
                            document: Number(returnData.numeroDocumento),
                        }

                        const bill      = await api.post('/billsToReceive/search', billSearch);

                        if (bill.data.length) {
                            const customer  = await api.get(`/customer/${bill.data[0].customer}`);
                            
                            retsArray.push({
                                checked: false,
                                idBill: bill.data[0].id,
                                billet: bill.data[0].billetNumber,
                                nameBill: bill.data[0].name,
                                dueDate: returnData.dataVencimento,
                                occurrenceDate: returnData.dataOcorrencia,
                                customer: customer.data.typePeople == "physical" ? customer.data.name : customer.data.corporateName,
                                value: returnData.valor,
                                valuePayed: returnData.valorRecebido,
                                fee: returnData.valorMulta,
                                discount: returnData.valorDesconto,
                                tax: returnData.valorTarifa,
                                acc: returnData.valorMora,
                                occurrence: returnData.ocorrencia +" - "+ returnData.ocorrenciaDescricao
                            });
                            
                        } 
                    }
                }
                setRets(retsArray);


                setFileImported(true);
                
            } catch(error) {
                console.log(error);
            }            
        }
    }

    function formatDate(date: any)
    {
        var valueDate   = new Date(date);
        var month       = (valueDate.getMonth() + 1) < 10  ? "0" + (valueDate.getMonth() + 1) : (valueDate.getMonth() + 1);
        var day         = (valueDate.getDate() + 1)   < 10  ? "0" + (valueDate.getDate() + 1) : (valueDate.getDate() + 1);
        
        return day +"/"+ month +"/"+ valueDate.getFullYear();
    }
    
    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false); 
                    window.location.href = "/contas-a-receber";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados conciliados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/contas-a-receber";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>
            
            <div className="row mb-3">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={accountBank}
                        required
                        onSelect={(option) => {
                            setAccountBank(option ? String(option.id) : '');
                            
                            if (fileRef.current?.value) {
                                fileRef.current.value = ""
                            }
                        }}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!accountBank) return null;
                            return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                        }}
                    />
                </div>
            </div>

            {
                accountBank
                ?
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center p-5" style={{ border: "2px dashed #eee", background: "#fefefe" }}>
                            <label htmlFor="formFile">
                                <div className="w-100 d-flex justify-content-center">
                                    <img src="/media/icons/folder.png" className='mr-5' />
                                </div>
                                <h6 className="text-center mt-3">Importe o arquivo de retorno aqui</h6>
                                <input
                                    type='file'
                                    id='formFile'
                                    accept='.ret'
                                    onChange={(e) => readDocument(e.target.files)}
                                    style={{ display: "none" }}
                                    ref={fileRef}
                                />
                            </label>
                        </div>
                    </div>
                : <></>
            }        

            {
                fileImported
                ?
                <>
                    <h3 className='mt-3'>Arquivo {nameFile}:</h3>
                    <hr />
                    
                    <div className="col-lg-12 mt-3 border-top">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="default"></TableCell>
                                    <TableCell padding="default">
                                        <strong>Id</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Boleto</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Nome</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Cliente</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Vencimento</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Valor</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Valor pago</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Taxas</strong>
                                    </TableCell>
                                    <TableCell padding="default">
                                        <strong>Ocorrência</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    !rets.length
                                    ?
                                    <TableRow role="checkbox" tabIndex={-1}>
                                        <TableCell scope="row" padding="default" colSpan={10} className="text-center">
                                            Nenhuma informação encontrada para conciliar ou já foi conciliada...
                                        </TableCell>
                                    </TableRow>
                                    :
                                    <></>
                                }
                                
                                {
                                    rets.map((value, index) => {
                                        return (
                                            <TableRow key={"pay"+ index} hover role="checkbox" tabIndex={-1}>
                                                <TableCell scope="row" padding="default">
                                                    <FormControlLabel
                                                        label=""
                                                        value={value.checked}
                                                        control={<Checkbox color='primary' onChange={(e) => {
                                                            var retsArray = rets;
                                                            retsArray[index].checked = e.target.checked;
                                                            setRets(retsArray);
                                                        }} />}
                                                        labelPlacement='end'
                                                    />
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    { value.idBill }
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    { value.billet }
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    { value.nameBill }
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {value.customer}
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    { value.dueDate }
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {formatCurrency(value.value)}
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {formatCurrency(value.valuePayed)}
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {formatCurrency(value.tax)}
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {value.occurrence}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }                                
                            </TableBody>
                        </Table>

                        {/* <TablePagination
                            page={page}
                            component="div"
                            count={listOptions.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            backIconButtonProps={{
                                'aria-label': 'Página Anterior',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Próxima Página',
                            }}
                            onChangePage={(_, next) => handleChangePage(next)}
                            onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                        /> */}
                    </div>
                </>
                : <></>
            }    

            {
                fileImported
                ?
                <div className="row mt-3 d-flex justify-content-end">
                    <div className="col-lg-3 d-flex justify-content-end">
                        <Button
                            type='submit'
                            className='mr-3'
                            variant="primary"
                            disabled={isSubmit}
                            onClick={() => conciliation()}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Conciliar
                                </span>

                            </>}


                        </Button>
                    </div>
                </div>
                : <></>
            }
        </div>
    )
} 