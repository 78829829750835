import React, { useEffect, useState } from "react";
import { Dialog, DialogContentText, DialogTitle, List, ListItem, ListItemText } from "@material-ui/core";
import useCompany from "../hooks/company";
import CompanyService from "../services/CompanyService";
import { formatCpfCnpjDynamic } from "../utils/formatCpfCnpj";

export type CompanyBranchSelectorProps = {
    user: any;
    mainCompany: any;
    showDialog: boolean;
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCompany: any;
    onClose: (selectedCompany: any) => void;
}

export function CompanyBranchSelector({
    user,
    mainCompany,
    showDialog,
    setShowDialog,
    selectedCompany,
    onClose,
}: CompanyBranchSelectorProps) {
    const { companies } = useCompany({ mainCompanyId: mainCompany?.id });
    const [availableCompanies, setAvailableCompanies] = useState<any[]>([]);

    useEffect(() => {
        async function getSelectableCompanies() {
            if (!mainCompany || !selectedCompany || !user) return;

            const userBranchesIds = user.branchesIds ? JSON.parse(user.branchesIds) : [];

            var companiesArr: any = [];
            if (user.isAccountant == "y") {
                const accountantCompanies = await CompanyService.getCompaniesByIdsArray(userBranchesIds);
                accountantCompanies.forEach(company => {
                    if (company.id !== selectedCompany.id && userBranchesIds.includes(company.id) && !!CompanyService.getStatusValue(company)) {
                        companiesArr.push(company);
                    }
                });

            } else {
                companiesArr = mainCompany.id === selectedCompany.id || user.canAccessMainCompany === 'n' ? [] : [mainCompany];
                companies.forEach(company => {
                    if (company.id !== selectedCompany.id && userBranchesIds.includes(company.id) && !!CompanyService.getStatusValue(company)) {
                        companiesArr.push(company);
                    }
                });
            }

            setAvailableCompanies(companiesArr);
        }

        getSelectableCompanies();

    }, [mainCompany, selectedCompany, companies, user]);

    function handleCloseDialog() {
        onClose(selectedCompany);
    }

    function handleClickCompany(company: any) {
        onClose(company);
    }

    if (!mainCompany) return <></>;

    return (
        <Dialog
            open={showDialog}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Trocar de empresa</DialogTitle>
            {availableCompanies.length > 0 ? (
                <List>
                    {availableCompanies.map((company) => (
                        <ListItem
                            key={company.id}
                            button
                            onClick={() => handleClickCompany(company)}
                        >
                            <ListItemText primary={`${CompanyService.getCorporateName(company)} - ${formatCpfCnpjDynamic(CompanyService.getCpfCnpj(company))}`} />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <DialogContentText className="mx-2">
                    Nenhuma empresa filial disponível
                </DialogContentText>
            )}

        </Dialog>
    )
}