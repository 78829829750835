import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BudgetProvider } from '../pages/Budget/context';
import { NewBudgets } from '../pages/Budget/New-Budgets';
import { EditBudgets } from '../pages/Budget/Edit-Budgets';
import { ListBudgets } from '../pages/Budget/List-Budgets';

export function Budgets() {
    
    return (
        <BudgetProvider>
            <Switch>
                <Route path="/orcamentos/adicionar" component={NewBudgets}/>
                <Route path="/orcamentos/duplicar/:idToClone" component={NewBudgets}/>
                <Route path="/orcamentos/:id" component={EditBudgets}/>
                <Route path="/orcamentos" component={ListBudgets}/>
            </Switch>
        </BudgetProvider>
    );
}