import React from "react";

import {Route, Switch} from "react-router-dom";
import { ListPdv } from "../pages/Pdv/ListPdv";
import { NewPdv } from "../pages/Pdv/NewPdv";

export default function Pdv() {
    return (
        <Switch>
            <Route path="/pdv" exact component={ListPdv} />
        </Switch>
    );
}