import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

import { formatNumberToString } from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/dateFormat";


type ProductsTableParams = {
    products: any;
};

export function ProductsTable({ products }: ProductsTableParams) {

    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Observações</TableCell>
                    <TableCell>Entrada</TableCell>
                    <TableCell>Saída</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Valor Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map((product: any) => (
                    <TableRow>
                        <TableCell>{formatDate(product.date)}</TableCell>
                        <TableCell>{product.observation}</TableCell>
                        <TableCell>{formatNumberToString(product.entry)}</TableCell>
                        <TableCell>{formatNumberToString(product.output)}</TableCell>
                        <TableCell>{'R$ ' + formatNumberToString(Number(product.unitValue))}</TableCell>
                        <TableCell>{'R$ ' + formatNumberToString(Number(product.totalValue))}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}