export type dateTimeHelperParams = {
    initialDate?: Date,
    dateFormat?: string,
    timeFormat?: string,
};

export type dateTimeHelperReturn = {
    dateObj: Date,
    dateStr: string,
    timeStr: string,
    fullDateStr: string,
}

export function getDate({
    initialDate = new Date(),
    dateFormat = 'd/m/y',
    timeFormat = 'h:i:s',
}: dateTimeHelperParams = {
        initialDate: new Date(),
        dateFormat: 'd/m/y',
        timeFormat: 'h:i:s',
    }) {
    // initialDate = null,
    // dateFormat:string = 'd/m/y',
    // timeFormat:string = 'h:i:s'
    const dateObj = initialDate;

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');

    const dateStr = dateFormat.replaceAll('d', day).replaceAll('m', month).replaceAll('y', year);
    const timeStr = timeFormat.replaceAll('h', hours).replaceAll('i', minutes).replaceAll('s', seconds);

    // const dateStr = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth()+1).toString().padStart(2, '0')}/${now.getFullYear().toString().padStart(2, '0')}`;
    // const timeStr = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
    const fullDateStr = `${dateStr} ${timeStr}`;

    return {
        dateObj, // Objeto Date
        dateStr, // Dia mês e ano
        timeStr, // Hora minutos e segundos
        fullDateStr, // date + time
    }
}

export function dateIsBetweenRange(actualDate: string, dateMin: string, dateMax: string): boolean {
    if (!dateMin && !dateMax) {
        return true;
    }
    if (!actualDate) {
        return false;
    }
    const actualDateTimestamp = (new Date(actualDate)).getTime();
    if (dateMin) {
        const min = (new Date(dateMin)).getTime();
        if (!min || actualDateTimestamp < min) {
            return false;
        }
    }
    if (dateMax) {
        const max = (new Date(dateMax)).getTime();
        if (!max || actualDateTimestamp > max) {
            return false;
        }
    }
    return true;
}

/** @param month 1 - 12 */
export function getLastDayOfMonth(year: number, month: number) {
    return (new Date(year, month, 0)).getDate();
}

export function extractDateStringFromTimestamp(timestamp?: string) {
    let timestampString = timestamp || (new Date().toISOString());
    return timestampString.split('T')[0];
}

export function getNameMonth(dateNumber: string, typeReturn: "name" | "initials"): string {
    const monthNames = [
        { number: '01', name: 'Janeiro', initials: 'Jan' },
        { number: '02', name: 'Fevereiro', initials: 'Fev' },
        { number: '03', name: 'Março', initials: 'Mar' },
        { number: '04', name: 'Abril', initials: 'Abr' },
        { number: '05', name: 'Maio', initials: 'Mai' },
        { number: '06', name: 'Junho', initials: 'Jun' },
        { number: '07', name: 'Julho', initials: 'Jul' },
        { number: '08', name: 'Agosto', initials: 'Ago' },
        { number: '09', name: 'Setembro', initials: 'Set' },
        { number: '10', name: 'Outubro', initials: 'Out' },
        { number: '11', name: 'Novembro', initials: 'Nov' },
        { number: '12', name: 'Dezembro', initials: 'Dez' },
    ]

    const foundMonth = monthNames.find(month => month.number == dateNumber);

    if (!foundMonth) {
        return '';
    }

    return typeReturn == "name" ? foundMonth.name : foundMonth.initials;
}

export function subtractDate(date: string, daysSubtract: number): string {
    var dateTime = new Date(Number(date.split("-")[0]), Number(date.split("-")[1]) - 1, Number(date.split("-")[2]));
    dateTime.setDate(dateTime.getDate() - daysSubtract);

    var month = dateTime.getMonth() + 1 < 10 ? "0" + (dateTime.getMonth() + 1) : dateTime.getMonth() + 1;
    var day = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();

    return dateTime.getFullYear() + "-" + month + "-" + day;
}

export function subtractMonth(date: string, monthsSubtract: number): string {
    var dateTime = new Date(Number(date.split("-")[0]), Number(date.split("-")[1]) - 1, Number(date.split("-")[2]));
    dateTime.setMonth(dateTime.getMonth() - monthsSubtract);

    var month = dateTime.getMonth() + 1 < 10 ? "0" + (dateTime.getMonth() + 1) : dateTime.getMonth() + 1;
    var day = dateTime.getDate() < 10 ? "0" + (dateTime.getDate()) : dateTime.getDate();

    return dateTime.getFullYear() + "-" + month + "-" + day;
}

export function getNumberOfDaysBetweenDates(initialDate: Date, finalDate: Date) {
    // Dias em milisegundos
    const day = 1000 * 60 * 60 * 24;

    // Diferença de milisegundos
    const differenceMs = Math.abs(initialDate.getTime() - finalDate.getTime());

    // Diferença de dias
    return Math.round(differenceMs / day);

}