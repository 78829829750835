import api from "./Api";

class BrandService {
    async getBrandById(id: string | number) {
        const brandResponse = await api.get(`/brand/${id || '0'}`);

        return brandResponse.data;
    }

    async getBrandsFiltered(filters: any) {
        const brandResponse = await api.get<any[]>('/brand', { params: { filters } });

        return brandResponse.data;
    }
}

export default new BrandService();