import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EditMyAccount } from '../pages/MyAccount/Edit-MyAccount';

export function MyAccount() {
    return (
        <Switch>
            <Route path="/minha-conta" component={EditMyAccount}/>
        </Switch>
    );
}