import React, { useState} from 'react';
import {List} from '../../_metronic/layout/components/List/List';

const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

export function ListExtract() {
    const [data, setData] = useState({ extract: [] });

    return (
        <List 
            labelLinkAdd="Adicionar"
            linkAdd="/extrato/adicionar"
            headRows={headRows}
            hasExpenses={false}
            hasImport={false}
            hasPrintOut={true}
            hasMoreActions={false}
            data={data.extract}
        />
    );
}