class NfseService {

    getNature(value: string | number){

        const formated = value.toString();

        switch (formated) {
            case '1' :
                return 'Tributação no município';
            case '2' :
                return 'Tributação fora do município';
            case '3' :
                return 'Isenção';
            case '4' :
                return 'Imune';
            case '5' :
                return 'Processo Judicial';
            default:
                return '';
        };
    };

    getTaxRegime(value: string | number ){

        const formated = value.toString();

        switch (formated) {
            case '1':
                return 'Optante pelo simples nacional' ;
            case '2' :
                return 'Contribuinte sujeito a tributação normal' ;
            default:
                return '';
        };
    };

    getSpecialTaxRegime(value: string | number){

        const formated = value.toString();

        switch (formated) {
            case '1' :
                return 'Micro empresa municipal';
            case '2' :
                return 'Estimativa';
            case '3' :
                return 'Sociedade de profissionais';
            case '4' :
                return 'Cooperativa';
            case '5' :
                return 'Micro empresário individual (MEI)';
            case '6' :
                return 'Microempresa e empresário de pequeno porte';
            default:
                return 'Nenhum';
        };
    };

    getDefaultEmailMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da prestação de serviço.'
        );
    }

    getDefaultWhatsappMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da prestação de serviço.'
        );
    }

};



export default new NfseService();