import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { format, parseISO, isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { orderByIdDesc } from '../../utils/orderTable';

import api from '../../services/Api';
import {
    ObjectOrder,
    OrdersSituation,
    HeadDataBaseProps,
    BodyDataBaseProps,
    OnThrowToBillsData,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { ServiceOrderData } from '../../types/ServiceOrderData';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Search } from '../../components/Search';
import '../../style.css';
import { ServiceContractData } from '../../types/ServiceContractData';
import customerService from '../../services/CustomerService';
import { dateIsBetweenRange, extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import ModalChangeStatus, { StatusHistory } from '../../components/ModalChangeStatus';
import { BsVariant } from '../../types/BsVariant';
import { status } from '../Config/CompanyData/ManageStatus';
import useBackendLoad from '../../hooks/backendReload';
import { getSituationFromText, getSituationText } from '../../utils/getSituationText';
import NotificationService from '../../services/NotificationService';
import ServiceContractService from '../../services/ServiceContractService';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import useQueryParams from '../../hooks/queryParams';

type Filters = {
    searchQuery: string;
    situation: string;
    initialNextPaymentDate: string;
    finalNextPaymentDate: string;
    initialNextGenerateDate: string;
    finalNextGenerateDate: string;
    minValue: number;
    maxValue: number;
    bankAccount: number;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'description', value: "Descrição" },
    { reference: 'client', value: "Cliente" },
    { reference: 'nextPaymentDate', value: "Próximo Vencimento" },
    { reference: 'generateDate', value: "Próxima Geração", notSortable: true },
    { reference: 'finalDate', value: "Data de Vencimento do Contrato" },
    { reference: 'amount', value: "Valor Total" },
    { reference: 'situation', value: "Situação", situation: true, notSortable: true },
];

export function ListServiceContract() {
    const { user } = useSelector((state: any) => state.auth);

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [serviceContracts, setServiceContracts] = useState<ServiceContractData[]>([]);
    const [allStatus, setAllStatus] = useState<status[]>([]);
    const [countTotalServiceContracts, setCountTotalServiceContracts] = useState(0);

    //Modal alterar status
    const [idChangeStatus, setIdChangeStatus] = useState(0);
    const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);
    const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [initialNextPaymentDate, setInitialNextPaymentDate] = useState("");
    const [finalNextPaymentDate, setFinalNextPaymentDate] = useState("");
    const [initialNextGenerateDate, setInitialNextGenerateDate] = useState("");
    const [finalNextGenerateDate, setFinalNextGenerateDate] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [bankAccount, setBankAccount] = useState(0);
    const [searchRequested, setSearchRequested] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { getQueryParam } = useQueryParams();

    // useEffect(() => {
    //     reloadData();
    // }, [serviceContracts]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: ServiceContractData[], count: number }>("serviceContract", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        for (const contract of rows) {
            contract.customerName = customerService.getCustomerName(contract.customerEntity);

            const generateDateObj = new Date(`${extractDateStringFromTimestamp(contract.nextPaymentDate)} 00:00:00`);
            generateDateObj.setDate(generateDateObj.getDate() - Number(contract.daysBeforeExpire));
            contract.nextGenerateDate = extractDateStringFromTimestamp(generateDateObj.toISOString());
        }

        setServiceContracts(rows);
        setCountTotalServiceContracts(count);
    }, []);


    useEffect(() => {
        getAllStatus();
    }, []);

    useEffect(() => {
        const bankAccount = getQueryParam('bankAccount');
        if (bankAccount) {
            setBankAccount(Number(bankAccount));
            setAdvancedSearch(true);
            setSearchRequested(true);
        }
    }, [getQueryParam])

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = serviceContracts;

        aux.forEach((contract) => {
            const amount = formatCurrency(contract.totalValue);
            const status = getSituationText(contract.status);

            // Formatando data
            const nextDate = parseISO(extractDateStringFromTimestamp(contract.nextPaymentDate));
            const formatedNextDate = format(nextDate, "dd/MM/yyyy");

            const generateDate = parseISO(extractDateStringFromTimestamp(contract.nextGenerateDate));
            const formatedGenerateDate = format(generateDate, "dd/MM/yyyy");

            const finalDate = parseISO(extractDateStringFromTimestamp(contract.finalDate));
            const formatedFinalDate = format(finalDate, "dd/MM/yyyy");

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(contract.id), id: true },
                { for: "description", value: contract.description ?? '' },
                { for: "client", value: contract.customerName ?? '' },
                {
                    for: "nextpaymentDate",
                    value: (
                        contract.nextPaymentDate && contract.status !== 'attended' && contract.status !== 'canceled' ? formatedNextDate : ''
                    ),
                },
                {
                    for: "generateDate",
                    value: (
                        contract.nextPaymentDate && contract.status !== 'attended' && contract.status !== 'canceled' ? formatedGenerateDate : ''
                    )
                },
                {
                    for: "finalDate",
                    value: (
                        contract.finalDate ? formatedFinalDate : 'INDETERMINADO'
                    )
                },
                { for: "amount", value: amount },
                { for: "situation", value: status },
                { for: "customStatusLabel", value: contract.customStatus?.label ?? '' },
                { for: "customStatusColor", value: contract.customStatus?.color ?? '' },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [serviceContracts]);

    async function getAllStatus() {
        var statusData = await api.get("/status");
        setAllStatus(statusData.data);
    }

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setInitialNextPaymentDate('');
        setFinalNextPaymentDate('');
        setInitialNextGenerateDate('');
        setFinalNextGenerateDate('');
        setMinValue('');
        setMaxValue('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue),
            initialNextPaymentDate, finalNextPaymentDate, initialNextGenerateDate, finalNextGenerateDate,
            bankAccount,
        };

        reloadData();
    }, [serviceContracts, searchQuery, situation,
        initialNextPaymentDate, finalNextPaymentDate, initialNextGenerateDate, finalNextGenerateDate,
        minValue, maxValue, bankAccount]);

    useEffect(() => {
        if (searchRequested) {
            setSearchRequested(false);

            handleClickSearch();
        }
    }, [searchRequested, handleClickSearch]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = serviceContracts.filter((contract) => contract.id !== Number(id));

            await api.delete(`serviceContract/${id}`);

            setServiceContracts([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [serviceContracts]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickOpenChangeStatusModal = useCallback((id: string) => {
        const contract = serviceContracts.find(contractObj => contractObj.id === Number(id));
        if (!contract) return;

        setStatusHistory(JSON.parse(contract.situation));
        setIdChangeStatus(Number(id));
        setShowModalChangeStatus(true);
    }, [serviceContracts]);

    const handleClickChangeStatus = useCallback(async (newStatus: StatusHistory) => {
        if (!newStatus.statusSituationId) return;

        const aux = [...statusHistory];
        const lastStatus = aux[aux.length - 1];

        aux.push(newStatus);

        var status = newStatus.statusIsDefault === "y" ? getSituationFromText(newStatus.statusSituationLabel) : "custom";
        var customStatus = newStatus.statusIsDefault === "n" ? newStatus.statusSituationId : null;

        const customStatusObject = customStatus ? allStatus.find((statusObj) => statusObj.id === customStatus) : null;

        const raw: any = {
            status: status,
            customStatusId: customStatus,
            situation: JSON.stringify(aux),
        }
        if (newStatus.statusSituation === 'attended' || newStatus.statusSituation === 'canceled') {
            raw.nextPaymentDate = null;
        }

        await api.put(`serviceContract/${idChangeStatus}`, raw);

        ServiceContractService.dispatchStatusChangeNotificationAndEmail({
            serviceContractId: idChangeStatus,
            lastStatusHistory: lastStatus,
            newStatusHistory: newStatus,
        });

        setStatusHistory([...aux]);
        setServiceContracts((prevState) => (
            prevState.map((contract) => {
                if (contract.id !== idChangeStatus) {
                    return contract;
                }

                return {
                    ...contract,
                    situation: JSON.stringify(aux),
                    status: status,
                    customStatus: customStatusObject,
                }
            })
        ));
    }, [idChangeStatus, statusHistory, allStatus]);

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <ModalChangeStatus
                showModal={showModalChangeStatus}
                setShowModal={setShowModalChangeStatus}
                history={statusHistory}
                onClickChangeStatus={handleClickChangeStatus}
                listStatus={allStatus}
            />
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Novo Contrato
                            </Button>
                            : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={situation}
                        >
                            <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                Todos
                            </MenuItem>

                            {allStatus.map((status) => (
                                <MenuItem key={status.id} value={status.isDefault === 'y' ? getSituationFromText(status.label) : status.id} onClick={(event) => { setSituation(event.currentTarget.getAttribute('data-value') ?? '') }}>
                                    {status.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Início Prox. Vencimento"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={initialNextPaymentDate}
                            onChange={(e) => setInitialNextPaymentDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Fim Prox. Vencimento"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={finalNextPaymentDate}
                            onChange={(e) => setFinalNextPaymentDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Início Prox. Geração"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={initialNextGenerateDate}
                            onChange={(e) => setInitialNextGenerateDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Fim Prox. Geração"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={finalNextGenerateDate}
                            onChange={(e) => setFinalNextGenerateDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor mínimo"
                            startAdornment="R$"
                            value={minValue}
                            onChange={(evt) => setMinValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor máximo"
                            startAdornment="R$"
                            value={maxValue}
                            onChange={(evt) => setMaxValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-4">
                        <ApiResourceSelect
                            label="Conta bancária"
                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                            value={bankAccount}
                            onSelect={(option) => setBankAccount(Number(option?.id))}
                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if (!bankAccount) return null;
                                return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                            }}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    situation
                    headData={headData}
                    bodyData={bodyData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    onClone={handleClickClone}
                    customButtons={
                        user.isAccountant == "n"
                            ?
                            [
                                {
                                    class: 'btn-primary',
                                    content: (<i className='p-0 flaticon-list-2'></i>),
                                    variant: BsVariant.PRIMARY,
                                    popup: "Alterar Status",
                                    onClick: handleClickOpenChangeStatusModal,
                                }
                            ]
                            : []
                    }
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalServiceContracts}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                />
            </div>
        </div>
    );
}