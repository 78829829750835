import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';

import api from "../../services/Api";
import { Customer } from '../../types/Customer';
import { formatCpfCnpj } from '../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { Search } from '../../components/Search';
import { Collapse, MenuItem, TextField } from '@material-ui/core';
import useBackendLoad from '../../hooks/backendReload';
import { useSelector } from 'react-redux';
import CustomerService from '../../services/CustomerService';

type Filters = {
    searchQuery: string;
    situation: string;
    typeRegister: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
    { reference: "cpf", value: "CPF/CNPJ" },
    { reference: "phone", value: "Celular" },
    { reference: "situation", value: "Situação", situation: true, notSortable: true },
    { reference: "typeCustomer", value: "Tipo", notSortable: true },
];

export function ListCustomer() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [customersFiltered, setCustomersFiltered] = useState<Customer[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCustomers, setCountTotalCustomers] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [situation, setSituation] = useState("");
    const [typeRegister, setTypeRegister] = useState("both");

    const { push: pushHistory, location: { pathname } } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [customers]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Customer[], count: number}>("customer", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCustomers(rows);
        setCustomersFiltered(rows);
        setCountTotalCustomers(count);
    }, []);
    
    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = customersFiltered;

        aux.forEach((customer) => {
            let nameValue = customer.name;

            const cpfValue = customer.cpf ? formatCpfCnpj("CPF", customer.cpf) : formatCpfCnpj("CNPJ", customer.cnpj);
            const typeValue = CustomerService.getTypeRegisterText(customer.typeRegister);
            const situationValue = customer.registrationStatus === "active" ? "Ativo" : "Inativo";

            const formatedCell = formatPhoneNumber(customer.cell);

            if (customer.cnpj) {
                nameValue = customer.fantasyName;
            }

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(customer.id), id: true },
                { for: "name", value: nameValue },
                { for: "cpf", value: cpfValue },
                { for: "phone", value: formatedCell },
                { for: "situation", value: situationValue },
                { for: "typeCustomer", value: typeValue },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [customersFiltered]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = customers.filter((customer) => customer.id !== Number(id));

            await api.delete(`customer/${id}`);

            setCustomers([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [customers]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setTypeRegister('both');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation, typeRegister,
        };

        reloadData();
    }, [customers, searchQuery, situation, typeRegister]);

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ? 
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Novo cliente/fornecedor
                        </Button>
                        : <></>
                    }
                    <Button
                        type="button"
                        variant="secondary"
                        className="mr-2"
                        onClick={() => pushHistory(`${pathname}/importar-excel`)}
                    >
                        Importar Excel
                    </Button>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={situation}
                        >
                            <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                Nenhum
                            </MenuItem>

                            <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                                Ativo
                            </MenuItem>

                            <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Tipo de Cadastro"
                            margin="normal"
                            variant="outlined"
                            value={typeRegister}
                        >
                            <MenuItem key="0" value="both" onClick={() => setTypeRegister('both')}>
                                Ambos
                            </MenuItem>

                            <MenuItem key="1" value="customer" onClick={() => setTypeRegister('customer')}>
                                Clientes
                            </MenuItem>

                            <MenuItem key="2" value="supplier" onClick={() => setTypeRegister('supplier')}>
                                Fornecedores
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

			<div className="mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
					onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalCustomers}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
		</div>
    );
}