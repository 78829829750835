import React from "react";
import { Route, Switch } from "react-router-dom";

import { useSubheader } from "../../_metronic/layout";
import { NewBrand } from "../pages/Brand/New-Brand";
import { ListBrand } from "../pages/Brand/List-Brand";
import { EditBrand } from "../pages/Brand/Edit-Brand";
// import Button from '@material-ui/core/Button';

export function Brand() {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Marcas");

    return (
        <Switch>
          <Route path="/marcas/adicionar" component={NewBrand}/>  
          <Route path="/marcas/:id" component={EditBrand}/>
          <Route path="/marcas" component={ListBrand} />               
        </Switch>
      );
}
