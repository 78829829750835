import { Carrier } from "../types/Carrier";
import api from "./Api";

class CarrierService {
    async getCarriersFiltered(filters: any): Promise<Carrier[]> {
        const carrierResponse = await api.get<Carrier[]>('/carriers', { params: { filters } });

        const carriers = carrierResponse.data.map((carrier) => ({ ...carrier, name: this.getCarrierName(carrier) }));

        return carriers;
    }

    async getCarrierById(id: number | string) {
        const { data } = await api.get<Carrier>(`/carriers/${id || '0'}`);
        if(!data) return null;

        data.name = this.getCarrierName(data);
        return data;
    }

    getCarrierName(carrier?: Carrier, defaultValue: string = '') {
        if(!carrier || typeof carrier !== 'object') {
            return defaultValue ? defaultValue : '';
        }

        const name = carrier.typePeople === 'legal' ? carrier.corporateName : carrier.name;

        return name || '';
    }
}

export default new CarrierService();