export function getSaleTypeText(type: string = '') {
    switch(type) {
        case 'pdv':
            return 'PDV';
        case 'nfe':
            return 'NF-e';
        case 'nfse':
            return 'NFS-e';
        case 'nfce':
            return 'NFC-e';
        case 'budget':
            return 'Orçamento';
        case 'request':
            return 'Pedido';
        case 'service':
            return 'Serviço';
        case 'serviceOrder':
            return 'Ordem de Serviço';
        case 'contract':
            return 'Contrato';
        default:
            return type;
    }
}