import { makeStyles } from "@material-ui/core";
import React from "react";
import { getFileExtension } from "../utils/getFileExtension";
import { api as ApiUrl } from "../services/ApiURL";
import { Button } from "react-bootstrap";

type InputFileProps = {
    label: string,
    fileSelected: File | null,
    setFileSelected: React.Dispatch<React.SetStateAction<File | null>>,
    fileNameSaved?: string | null,
    accept?: string
    disabled?: boolean
}


const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: "flex",
        alignItems: "center"
    },
    containerAttachment: {
        backgroundColor: "#f9f9f9",
        border: "2px dotted #ccc",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        margin: "0",
        height: "auto"
    },
    itemFiles: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        border: "1px solid #eee",
        borderRadius: "10px",
        padding: "5px",
    },
    shortIconFile: {
        border: "1px solid #eee",
        borderRadius: "10px",
        padding: "2px",
        width: "100px",
    },
    fileName: {
        fontSize: "16px",
        marginLeft: "10px",
        marginBottom: "0",
    },
    fileSize: {
        fontSize: "10px",
        marginLeft: "10px",
        marginBottom: "0",
    }
}));

export default function UploadFile({
    label,
    fileSelected,
    setFileSelected,
    fileNameSaved,
    accept,
    disabled = false
}: InputFileProps) {
    const classStyles = useStyles();
    
    const handleChangePhotoFile = (fileList: FileList | null) => {
        setFileSelected(fileList && fileList.length > 0 ? fileList[0] : null);
    };

    const handleDownload = async (fileName: string) => {
        try {
          const response = await fetch(`${ApiUrl}/${fileName}`);
    
          if (!response.ok) {
            throw new Error(`Failed to download file: ${response.statusText}`);
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
    
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
    };

    return <div className="pl-3">
        <label className="m-0">{label}</label>
        <label htmlFor="inputFile" className={classStyles.inputFile}>
            <label htmlFor="inputFile" className="btn btn-sm btn-dark mr-3" style={{ marginBottom: "0 !important"}}>
                <i className="flaticon2-folder p-0 mr-3"></i>
                Selecione
            </label>
            {fileSelected ? fileSelected.name : "Nenhum arquivo selecionado"}
        </label>
        <input
            id="inputFile"
            type="file"
            onChange={(e) => handleChangePhotoFile(e.target.files)}
            accept={accept}
            disabled={disabled}
            hidden={true}
        />
        {
            fileSelected
            ?
            <div className="col-12 mt-3 d-flex flex-column pl-0">
                <b>Foto Selecionada</b>
                <div key='0-attachment' className={classStyles.itemFiles}>
                    <div className="d-flex flex-row align-items-center">
                        {
                            ["png", "jpg", "jpeg"].includes(getFileExtension(fileSelected.name))
                            ? <img src={URL.createObjectURL(fileSelected)} className={classStyles.shortIconFile} />
                            : <div style={{width: "100px", textAlign: "center"}}><i className="flaticon2-file p-0" style={{fontSize: "35px"}}></i></div>
                        }
                        <div className="d-flex flex-column">
                            <label className={classStyles.fileName}>{fileSelected.name}</label>
                            {/* <label className={classStyles.fileSize}>{humanFileSize(file.size, true)}</label> */}
                        </div>
                    </div>
                </div>
            </div>
            : <></>
        }
        {
            fileNameSaved
            ?
            <div className="col-12 mt-3 d-flex flex-column">
                <b>Anexos Salvos</b>
                <div key='0-attachment' className={classStyles.itemFiles}>
                    <div className="d-flex flex-row align-items-center">
                        {
                            ["png", "jpg", "jpeg"].includes(getFileExtension(fileNameSaved))
                            ? <img src={`${ApiUrl}/${fileNameSaved}`} className={classStyles.shortIconFile} />
                            : <div style={{width: "100px", textAlign: "center"}}><i className="flaticon2-file p-0" style={{fontSize: "35px"}}></i></div>
                        }
                        <div className="d-flex flex-column">
                            <label className={classStyles.fileName}>{fileNameSaved}</label>
                            {/* <label className={classStyles.fileSize}>{humanFileSize(file.size, true)}</label> */}
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <Button
                            type="button"
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleDownload(fileNameSaved)}
                            className="mr-3"
                            disabled={disabled}
                        >
                            <i className="flaticon2-download p-0"></i>
                        </Button>
                    </div>
                </div>
            </div>
            : <></>
        }
    </div>;
}