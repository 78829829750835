import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

import { formatNumberToString } from "../../../utils/formatCurrency";


type ProductsTableParams = {
    products: any;
};

export function ProductsTable({ products }: ProductsTableParams) {

    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Qtde.</TableCell>
                    <TableCell>Valor Unitário</TableCell>
                    <TableCell>IPI</TableCell>
                    <TableCell>Valor Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map((product: any) => (
                    <TableRow key={product.id}>
                        <TableCell>{product.INSTANCE.name ?? '-'}</TableCell>
                        <TableCell>{product.amount}</TableCell>
                        <TableCell>{'R$ ' + formatNumberToString(product.unitaryValue)}</TableCell>
                        <TableCell>{formatNumberToString(product.ipi)}</TableCell>
                        <TableCell>{'R$ ' + formatNumberToString(product.total)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}