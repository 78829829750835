import { useCallback, useEffect, useState } from "react";
import { Seller } from "../types/Seller";
import { SellerConsignmentProduct } from "../types/SellerConsignment";
import api from "../services/Api";

/**
 * Hook para puxar informações de um usuário que tem vinculo com vendedor,
 * incluindo informações de consignação caso ele seja um vendedor consignado
 * */
export default function useSellerUser() {
    const [userSellerInfo, setUserSellerInfo] = useState<Seller>();
    const [productsAvailableForConsignment, setProductsAvailableForConsignment] = useState<SellerConsignmentProduct[]>();

    useEffect(() => {
        async function loadSellerUserInfo() {
            const response = await api.get<Seller>('/sellers/auth');

            setUserSellerInfo(response.data);
            setProductsAvailableForConsignment([]);
        }

        loadSellerUserInfo();
    }, []);

    const filterProductsByStockLocationId = useCallback((stockLocationId: number) => {
        if (!userSellerInfo) {
            return;
        }

        // Filtrando produtos com o local de estoque indicado
        const filteredProducts = userSellerInfo.availableConsignmentProducts.filter((product) => (
            product.stockLocationId === stockLocationId
        ));

        // Agrupando produtos repetidos
        const groupedProducts: SellerConsignmentProduct[] = [];

        for (const product of filteredProducts) {
            const alreadyAdded = groupedProducts.find((prod) => prod.productId === product.productId && prod.stockLocationId === product.stockLocationId);
            if (!alreadyAdded) {
                groupedProducts.push(product);
            } else {
                alreadyAdded.amount += product.amount;
            }
        }

        setProductsAvailableForConsignment(groupedProducts);
    }, [userSellerInfo]);

    return { userSellerInfo, productsAvailableForConsignment, filterProductsByStockLocationId };
}