import { isAfter } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { partialPayments } from "../components/ListWithModalChangeSituation";

export default function useChangeBillSituation() {
    const [isReversalModalOpen, setIsReversalModalOpen] = useState(false);
    const [isSituationModalOpen, setIsSituationModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState(0);
    const [status, setStatus] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [supplier, setSupplier] = useState("");
    const [remaining, setRemaining] = useState(0);

    const [viewOnly, setViewOnly] = useState(false);
    const [total, setTotal] = useState(0);
    const [writeOffValue, setWriteOffValue] = useState(0);
    const [fee, setFee] = useState("R$0,00");
    const [tax, setTax] = useState("R$0,00");
    const [acc, setAcc] = useState("R$0,00");
    const [isPaid, setIsPaid] = useState(true);
    const [payment, setPayment] = useState("");
    const [discount, setDiscount] = useState("R$0,00");
    const [paymentDate, setPaymentDate] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [recordType, setRecordType] = useState("");
    const [valueToPay, setValueToPay] = useState("R$0,00");
    const [partialPayments, setPartialPayments] = useState<any>(null);

    const validateDiscount = useCallback(({valueNumber, feeNumber, taxNumber, accNumber, discountNumber}) => {
        const sum = valueNumber + feeNumber + taxNumber + accNumber;

        if (discountNumber > sum) {
            alert("Desconto não pode ser maior que o valor total");

            setDiscount("R$0,00");
            setIsSituationModalOpen(false);
        } else {
            setTotal(sum - discountNumber);
        }

        setWriteOffValue(valueNumber);
    }, []);

    const getChangedStatus = useCallback((changedRemaining) => {            
        let changedStatus;
        const [year, month, day] = dueDate.split("-");
        const date = new Date(`${year}-${month}-${day} 23:59:59`);
        
        if (changedRemaining === 0) {
            changedStatus = "paid";
        } else if (isAfter(Date.now(), date)) {
            changedStatus = "late";
        } else {
            changedStatus = "pending"
        }

        return changedStatus;
    }, []);

    return {
        isSituationModalOpen,
        setIsSituationModalOpen,
        isReversalModalOpen,
        setIsReversalModalOpen,
        viewOnly,
        setViewOnly,
        name,
        setName,
        supplier,
        setSupplier,
        dueDate,
        setDueDate,
        amount,
        setAmount,
        remaining,
        setRemaining,
        status,
        setStatus,
        paymentDate,
        setPaymentDate,
        payment,
        setPayment,
        bankAccount,
        setBankAccount,
        recordType,
        setRecordType,
        valueToPay,
        setValueToPay,
        writeOffValue,
        setWriteOffValue,
        fee,
        setFee,
        discount,
        setDiscount,
        tax,
        setTax,
        acc,
        setAcc,
        total,
        setTotal,
        partialPayments,
        setPartialPayments,
        isPaid,
        setIsPaid,
        validateDiscount,
        getChangedStatus,
    };
    
} 