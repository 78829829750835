import { yesOrNo } from "../types/yesOrNo";
import api from "./Api";

class LogService {
    /**
     * Cria um log do tipo "Criação"
     */
    async logRegister({ itemId, module, itemName }: {
        itemId: number | string;
        module: string;
        itemName: string;
    }) {
        return this.createLog({
            itemId: Number(itemId),
            edit: yesOrNo.NO,
            module,
            itemCreated: itemName,
        });
    }

    /**
     * Cria um log do tipo "Edição"
     */
    async logEdit({ itemId, module, itemName, fieldsMap, oldData, newData, formattedFields = {} }: {
        itemId: number | string;
        module: string;
        itemName: string;
        fieldsMap: { [key: string]: string };
        oldData: { [key: string]: any };
        newData: { [key: string]: any };
        formattedFields?: { [key: string]: (value: any) => any }
    }) {
        const changedFields = new Map<string, any>();
        const oldValues = [];
        const changedValues = [];

        for (const key in fieldsMap) {
            if (Object.prototype.hasOwnProperty.call(oldData, key) && Object.prototype.hasOwnProperty.call(newData, key)) {
                const field = String(key as keyof typeof fieldsMap);
                const fieldName = fieldsMap[field] as string;
                const oldValue = oldData[field] ?? '';
                const newValue = newData[field] ?? '';
                
                if(oldValue != newValue) {
                    changedFields.set(field, fieldName);
                    oldValues.push({
                        field,
                        fieldName,
                        value: oldValue,
                        valueFormatted: formattedFields[field] ? (await formattedFields[field](oldValue)) : oldValue,
                    });
                    changedValues.push({
                        field,
                        fieldName,
                        value: newValue,
                        valueFormatted: formattedFields[field] ? (await formattedFields[field](newValue)) : newValue,
                    });
                }
            }
        }

        if(changedFields.size === 0) {
            return {};
        }

        return this.createLog({
            itemId: Number(itemId),
            edit: yesOrNo.YES,
            module,
            itemEdited: itemName,
            field: JSON.stringify(Object.fromEntries(changedFields)),
            lastValue: JSON.stringify(oldValues),
            actualValue: JSON.stringify(changedValues),
        });
    }

    formatEditedValues(valuesJSON: string) {
        if(!valuesJSON) return '';

        const values = JSON.parse(valuesJSON);
        let formattedString = '';

        let i = 0;
        for (const valueObj of values) {
            if(i > 0) {
                formattedString += ' / ';
            }

            formattedString += `${valueObj.fieldName}: ${valueObj.valueFormatted ?? ''}`;

            i++;
        }

        return formattedString;
    }

    formatDescriptionValues(valuesJSON: string) {

        if(!valuesJSON) return '';

        const logDescriptionValues = JSON.parse(valuesJSON);

        let formattedObj: any = [];

        logDescriptionValues.forEach((valueObj: any) => {

            formattedObj.push({
                'fieldName': valueObj.fieldName,
                'valueFormatted': valueObj.valueFormatted
            })
            
        });

        return formattedObj;
    }

    private async createLog(data: any) {
        try {
            const response = await api.post<any>('log', data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new LogService();