import React, { useEffect, useRef } from "react";
const useEventListener = (eventName: string, handler: (event: any) => void, element: HTMLElement|Window|Document = window) => {
  const savedHandler = useRef<(event: any) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: any) => savedHandler.current ? savedHandler.current(event) : () => ({});
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export { useEventListener };