import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    TextField,

    TablePagination,
    Collapse,
    MenuItem,
    Grid,
    Select,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import api from '../../../services/Api';
import { formatDate } from '../../../utils/dateFormat';
import { Badge, Button, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import UserService from '../../../services/UserService';
import { useSelector } from 'react-redux';
import useBackendLoad from '../../../hooks/backendReload';
import { Search } from '../../../components/Search';
import ProcessBpmService from '../../../services/ProcessBpmService';
import { ProcessBpm } from '../../../types/ProcessBpm';
import { LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import { ActivityProcessBpm } from '../../../types/ActivityProcessBpm';
import { Autocomplete } from '@material-ui/lab';
import ModalError from '../../../components/ModalError';
import ModalSuccess from '../../../components/ModalSuccess';
import NumberFormat from 'react-number-format';
import { getDate } from '../../../utils/dateTimeHelper';
import BpmnWithFormViewer from '../../../components/BpmnWithFormViewer';

type params = {
    processId: string,
    solicitationId: string
}

type Activity = {
    id: number,
    process_id: number,
    solicitation_form_id: number,
    process_name: string,
    name: string,
    description: string,
    requester: string,
    responsable: string,
    customer: string,
    startSolicitation: Date,
    startActivity: Date,
    deadlineActivity: Date,
    delivery: Date,
    statusSolicitation: string,
    statusActivity: string,
}

type Filters = {
    searchQuery: string;
    startDate: string;
    endDate: string;
    processFilter: string;
    situation: string;
    requester: string;
    responsible: string;
}

const situationColor: { [key: string]: "light" | "danger" | "success" } = {
    "active": "light",
    "late": "danger",
    "completed": "success",
}

const situationText: { [key: string]: string } = {
    "active": "Em progresso",
    "late": "Atrasada",
    "completed": "Concluída"
}

const ActivityProcess = () => {
    const { user } = useSelector((state: any) => state.auth);
    const { processId, solicitationId } = useParams<params>();

    const [activities, setActivities] = useState<any>([]);
    const [activitiesCount, setActivitiesCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [situation, setSituation] = useState<string>("");
    const [requester, setRequester] = useState<string>("");

    const filtersRef = useRef<Filters | null>(null);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [pagesActivity, setPagesActivity] = useState(0);
    const [rowsPerPageActivity, setRowsPerPageActivity] = useState(10);
    const [sortDirectionActivity, setSortDirectionActivity] = useState<'ASC' | 'DESC'>('DESC');
    const [sortReferenceActivity, setSortReferenceActivity] = useState('id');
    const [pageChanged, setPageChanged] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingSolicitationForm, setIsLoadingSolicitationForm] = useState<boolean>(false);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [process, setProcess] = useState("");
    const [processError, setProcessError] = useState(false);
    const [processFilter, setProcessFilter] = useState("");
    const [processErrorFilter, setProcessErrorFilter] = useState(false);

    const [showNewSolicitationModal, setShowNewSolicitationModal] = useState<boolean>(false);
    const [showViewSolicitationModal, setShowViewSolicitationModal] = useState<boolean>(false);
    const [solicitationName, setSolicitationName] = useState<string>("");
    const [solicitationProcessId, setSolicitationProcessId] = useState<any>(0);
    const [processes, setProcesses] = useState<ProcessBpm[]>();

    const [showModalError, setShowModalError] = useState<boolean>(false);
    const [msgError, setMsgError] = useState<string>("");
    const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [value, setValue] = useState<any>([]);
    const [form, setForm] = useState<any>([]);
    const [solicitationProcess, setSolicitationProcess] = useState<any>([]);
    const [solicitationHistory, setSolicitationHistory] = useState<any>([]);
    const [currentStage, setCurrentStage] = useState<string>("");
    const [currentStageType, setCurrentStageType] = useState<number>(1);
    const [requesterName, setRequesterName] = useState("");
    const [endDateActivity, setEndDateActivity] = useState("");
    const [solicitationCreatedAt, setSolicitationCreatedAt] = useState("");
    const [statusSolicitation, setStatusSolicitation] = useState("");
    const [processName, setProcessName] = useState("");
    const [supervisorsName, setSupervisorsName] = useState("");
    const [supervisors, setSupervisors] = useState<any>([]);
    const [responsible, setResponsible] = useState<any>([]);
    const [logUserGroupPermition, setlogUserGroupPermition] = useState<any>([]);
    const [hideTabs, setHideTabs] = useState<number[]>([]);
    const [error, setError] = useState<any>(undefined);
    const [b, setB] = useState<boolean>(true);
    const [stagePermitions, setStagePermitions] = useState<any>([]);
    const [selectedSolicitationGroup, setSelectedSolicitationGroup] = useState<string>("");
    const [advanceToNextActivity, setAdvanceToNextActivity] = useState<boolean>(false);
    const permitionsGroup = ["Editar", "Não visualizar"];
    const processActivities: any = ["Início", "Fim", "Atividade", "Exclusivo", "Paralelo", "Inclusivo"];

    const [activeTab, setActiveTab] = useState<string>("form");
    const [selectedShape, setSelectedShape] = useState<any>(null);

    const { push: pushHistory } = useHistory();

    const addColumn = (groupIndex: number, tableIndex: number) => {
        setError(undefined);
        let numFields = form[groupIndex].tables[tableIndex].dataColumns.length;
        let data = [];
        for (let i = 0; i < numFields; i++) {
            data.push(null);
        }
        let val = value;
        val[groupIndex].tables[tableIndex].push(data);
        setValue(val);
        setUpdate();
    }

    const setUpdate = () => {
        setB(!b);
    }

    const displayTables = (groupIndex: number, tables: any = [], condition: boolean) => {
        return(
            tables.map((t: any, tableIndex: number) =>
                <div key={tableIndex} style={{marginTop: 35, width: "100%"}}>
                    <div className="d-flex flex-row align-items-center justify-content-between col-12">
                        <b style={{color: condition ? "#ccc" : "#555"}}>{t.name}</b>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Button
                                onClick={() => addColumn(groupIndex, tableIndex)}
                                disabled={condition}
                                className="d-flex flex-row align-items-center"
                                size="sm"
                                variant={condition ? "light" : "primary"}
                            >
                                <i className="flaticon2-plus p-0 mr-3" style={{fontSize: "12px"}} />
                                Adicionar
                            </Button>
                        </Tooltip>
                    </div>
                    <Grid item lg={12}>
                        { displayTableField(groupIndex, tableIndex) }
                    </Grid>
                </div>
            )
        );
    }

    const deleteColumn = async(groupIndex: number, tableIndex: number, columnIndex: number) => {
        try {
            setError(undefined);
            let val = value;
            let columnFields = val[groupIndex].tables[tableIndex][columnIndex];
            let columnFieldslength = columnFields.length;
            let updateColumn = false;
            for (let i = 0; i < columnFieldslength; i++) {
                let fieldType = form[groupIndex].tables[tableIndex].dataColumns[i].type;
                if (fieldType == "Anexo" && columnFields[i]) {
                    updateColumn = true;
                    let filename = columnFields[i];
                    await api.delete('/file', {data: { filename }})
                }
            }

            val[groupIndex].tables[tableIndex].splice(columnIndex, 1);
            let obj = {
                solicitationId,
                value: JSON.stringify(val),
                groupIndex,
                tableIndex,
            }
            let tableRes = await api.post("/bpm/solicitation/updateTable", obj);
            let tableData = JSON.parse(tableRes.data.solicitationData);
            setValue(tableData);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const displayTableField = (groupIndex: number, tableIndex: number) => {
        let dataColumns = form[groupIndex].tables[tableIndex].dataColumns;
        let valueTable = value[groupIndex].tables[tableIndex];
        let count = valueTable.length;
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[groupIndex]) ?
                logUserGroupPermition[groupIndex] == "Não visualizar" ? true : false
                : 
                false;
        return(
            valueTable.map((c: any, columnIndex: number) =>
                <div className="col-12" key={columnIndex}>
                    { /*<TableCell>{columnIndex + 1}</TableCell>*/ }
                    <div className='row'>
                        {
                            c.map((f: any, fieldIndex: number) => {
                                let type = dataColumns[fieldIndex].type;
                                let placeholder = dataColumns[fieldIndex].name;
                                let rows = dataColumns[fieldIndex].rows;
                                return(
                                    <div className="col" key={fieldIndex}>
                                        { tableFieldType(type, groupIndex, tableIndex, columnIndex, fieldIndex, placeholder, rows) }
                                    </div>
                                );
                            })
                        }
                            {
                                columnIndex ?
                                    <div className="col-1 pt-5">
                                        <Button
                                            variant={"danger"}
                                            onClick={() => {deleteColumn(groupIndex, tableIndex, columnIndex)}}
                                            disabled={condition}
                                            size="sm"
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                : <></>
                            }
                    </div>
                </div>
            )
        );
    }

    const displayError = (message?: string) => {
        return (
            message ?
                <small
                    style={{
                        fontWeight: "bold" as "bold",
                        color: "red"
                    }}
                >*{message}</small>
                : <></>
        );
    }

    const changeTableField = (gIndex: number, tIndex: number, cIndex: number, fIndex: number, impValue: any) => {
        let val: any = value;
        if (val[gIndex]) {
            if (val[gIndex].tables[tIndex]) {
                if (val[gIndex].tables[tIndex][cIndex]) {
                    val[gIndex].tables[tIndex][cIndex][fIndex] = impValue;
                    setValue(val);
                }
            }
        }
        setUpdate();
    }

    const tableFieldType = (type: string, gIndex: number, tIndex: number, cIndex: number, fIndex: number, placeholder?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Não visualizar" ? true : false
                : 
                false;
        let fieldValue = value[gIndex].tables[tIndex][cIndex][fIndex];
        let required = form[gIndex].tables[tIndex].dataColumns[fIndex].required;
        let err = error ? error[gIndex].tables[tIndex][cIndex][fIndex] : "";

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].tables[tIndex].dataColumns[fIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                placeholder={placeholder}
                                value={selValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{placeholder} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].tables[tIndex].dataColumns[fIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={placeholder}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].tables[tIndex].dataColumns[fIndex].extension;
                return(
                    <div>
                        <span>{placeholder}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small style={{marginLeft: 5}}>{fieldValue}</small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, fIndex, fieldValue, "table", tIndex, cIndex)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        // required={required}
                                        onChange={(e) => setFile(gIndex, fIndex, e, "table", tIndex, cIndex)}
                                        style={
                                            err ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].tables[tIndex][cIndex][fIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => changeTableField(gIndex, tIndex, cIndex, fIndex, !v)}
                                    disabled={condition}
                                    // required={required}
                                    id={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}>{placeholder}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const setData = (groupIndex: number, inputIndex: number, val: any) => {
        let valArr: any = value;
        valArr[groupIndex].inputs[inputIndex] = val;
        setValue(valArr);
        setUpdate();
    }

    const fieldType = (type: string, gIndex: number, iIndex: number, label?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Não visualizar" ? true : false
                : 
                false;
        let fieldValue = value[gIndex].inputs[iIndex];
        let required = form[gIndex].inputs[iIndex].required;
        let err = error ? error[gIndex].inputs[iIndex] : "";

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField                        
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].inputs[iIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                label={label}
                                value={selValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{label} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].inputs[iIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={label}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].inputs[iIndex].extension;
                return(
                    <div>
                        <span>{label}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small style={{marginLeft: 5}}>{fieldValue}</small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, iIndex, fieldValue)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        // required={required}
                                        onChange={(e) => setFile(gIndex, iIndex, e)}
                                        style={
                                            err ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].inputs[iIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => setData(gIndex, iIndex, !v)}
                                    disabled={condition}
                                    // required={required}
                                    id={`checkbox-${gIndex}${iIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${iIndex}`}>{label}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const displayInputs = (groupIndex: number, inputs: any = []) => {
        return(
            inputs.map((i: any, inputIndex: number) =>{
                let type = form[groupIndex].inputs[inputIndex].type;
                let label = form[groupIndex].inputs[inputIndex].name;
                let rows = form[groupIndex].inputs[inputIndex].rows;
                return(
                    <Grid item lg={12} key={i.id}>
                        { fieldType(type, groupIndex, inputIndex, label, rows) }
                    </Grid>
                );
            })
        );
    }

    const setFile = async(
        groupIndex: number,
        inputIndex: number,
        event: any,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            let file = event.target;
            if (!file.files || file.files.length == 0) {
                return;
            }
            let val = file.files[0];
            let fd = new FormData();
            fd.append("file", val, val.name);
            let upload = await api.post("/bpm/solicitation/file", fd);
            let fileUpload = upload.data;
            let valArr = value;
            if (type == "input") {
                valArr[groupIndex].inputs[inputIndex] = fileUpload;
            } else {
                if ((tableIndex != null || tableIndex != undefined) && (columnIndex != null || columnIndex != undefined)) {
                    valArr[groupIndex].tables[tableIndex][columnIndex][inputIndex] = fileUpload;
                }
            }

            let obj = {
                solicitationId,
                value: JSON.stringify(valArr),
            }
            let resp = await api.post("/bpm/solicitation/uploadSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);

            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const clearFile = async(
        groupIndex: number,
        inputIndex: number,
        filename: string,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            await api.delete('/file', {data: { filename }})
            let obj = {
                solicitationId,
                type,
                groupIndex,
                tableIndex,
                columnIndex,
                inputIndex,
            }
            let resp = await api.post("/bpm/solicitation/deleteSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);
            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const displayFormData = (g: any, groupIndex: number) => {
        let condition =
            permitionsGroup.includes(logUserGroupPermition[groupIndex]) ?
                logUserGroupPermition[groupIndex] == "Não visualizar" ? true : false
                : 
                false;
        
        return (
            <div key={g.id} className="mt-3">
                <h5 className="mb-0" style={{color: condition ? "#ccc" : "#555",}}>{selectedSolicitationGroup}</h5>
                <div style={{...styles.groupDivStyle}}>
                    <Grid container spacing={3}>
                        { displayInputs(groupIndex, g.inputs) }
                    </Grid>
                    <Grid container spacing={3}>
                        { displayTables(groupIndex, g.tables, condition) }
                    </Grid>
                </div>
            </div>
        );
    }

    const displayForm = () => {
        return(
            form.length ?
                <>
                    {
                        form.map((g: any, groupIndex: number) => {
                            if(stagePermitions[groupIndex] === 'Editar') {
                                return displayFormData(g, groupIndex);
                            } else {
                                return;
                            }

                            if (!Number(solicitationId)) {
                                return displayFormData(g, groupIndex);
                            } else {
                                if (
                                    (supervisors.includes(user.id) || permitionsGroup.includes(logUserGroupPermition[groupIndex])) 
                                    && logUserGroupPermition[groupIndex] != "Não Exibir"
                                ) {
                                    return displayFormData(g, groupIndex);
                                } else {
                                    return (<></>);
                                }
                            }
                        })
                    }
                </>
                : <></>
        );
    }

    const getSolicitation = useCallback((processId: number, solicitationId: number) => {
        setShowViewSolicitationModal(true);
        setIsLoadingSolicitationForm(true);

        const fetch = async() => {
            try {
                if (Number(solicitationId)) {
                    let resp = await api.get(`/bpm/displaySolicitation/${solicitationId}`);
                    let data = resp.data;
                    let sol = data.solicitation;
                    if (sol) {
                        setName(sol.name);
                        setValue(JSON.parse(sol.solicitationData));
                        let formArr = JSON.parse(sol.formData);
                        setForm(formArr);
                        let procArr = JSON.parse(sol.processData);
                        setSolicitationProcess(procArr);
                        setSolicitationHistory(data.history);
                        setCurrentStage(sol.processBpmStage);
                        setCurrentStageType(data.processType);
                        setRequester(sol.RequesterId);
                        setRequesterName(sol.requester);
                        setEndDateActivity(sol.endDate);
                        setSolicitationCreatedAt(formatDate(sol.startSolicitation));
                        setStatusSolicitation(sol.statusSolicitation);
                        setProcessName(data.processName);

                        procArr.forEach((process: any) => {
                            if (process.id === sol.processBpmStage) {
                                setSelectedSolicitationGroup(process.atividade);
                            }
                        })

                        const supervisorsNameConverted = sol.process_supervisors ? JSON.parse(sol.process_supervisors).map((supervisorName: any, index: number) => index == 0 ? `${supervisorName.firstname} ${supervisorName.lastname}` : `, ${supervisorName.firstname} ${supervisorName.lastname}`) : "";
                        setSupervisorsName(supervisorsNameConverted);

                        let superv = sol.process_supervisors ? JSON.parse(sol.process_supervisors) : [];
                        let supCount = superv.length;
                        let supArr = [];
                        if (supCount) {
                            for (let i = 0; i < supCount; i++) {
                                supArr.push(superv[i].id);
                            }
                        }
                        setSupervisors(supArr);

                        let procCount = procArr.length;
                        let respDataArr = [];
                        let permitions: any = {};
                        for (let i = 0; i < procCount; i++) {
                            if (procArr[i].id == sol.processBpmStage) {
                                respDataArr = procArr[i].responsaveis;
                                permitions = procArr[i].permissions ? procArr[i].permissions : {};
                            }
                        }
                        let respArr = [];
                        let rspCount = respDataArr.length;
                        for (let i = 0; i < rspCount; i++) {
                            respArr.push(respDataArr[i].id);
                        }
                        setResponsible(respArr);

                        let logPermition = [];
                        let permitionsArr: any = [];
                        Object.keys(permitions).map((index: any) => {
                            permitionsArr.push(permitions[index]);
                        });

                        setStagePermitions(permitionsArr);
                        if (respArr.includes(user.id)) {
                            let formCount = formArr.length;
                            for (let i = 0; i < formCount; i++) {
                                if (permitionsArr[i]) {
                                    logPermition.push(permitionsArr[i]);
                                } else {
                                    logPermition.push("Não visualizar");
                                }
                            }
                        }
                        setlogUserGroupPermition(logPermition);
                    }
                } else {
                    if (Number(processId)) {
                        let resp = await api.get(`/bpm/form/displayProcessForm/${processId}`);
                        let data = resp.data;
                        setName(data.form[0].name);
                        setSolicitationProcess(JSON.parse(data.process_data));
                        let groupArr = [];
                        let groupData = data.groups;
                        let gCount = groupData.length;
                        let val = value;

                        let usPermition = [];
                        for (let i = 0; i < gCount; i++) {
                            let group = groupData[i];
                            groupArr.push({
                                name: group.name,
                                index: i,
                                savedId: group.id,
                                inputs: [],
                                tables: [],
                            });
                            val.push({
                                inputs: [],
                                tables: [],
                            });

                            usPermition.push("Editar");
                        }
                        setlogUserGroupPermition(usPermition);
                        setResponsible([user.id]);
                        setSupervisors([]);
        
                        let fieldData = data.fields;
                        let fCount = fieldData.length;
                        let tableData = data.tables;
                        let tCount = tableData.length;
                        let tableFieldData = data.tableFields;
                        let tfCount = tableFieldData.length;
                        for (let i = 0; i < gCount; i++) {
                            let groupArrI =  groupArr[i];
                            let group = groupData[i];
        
                            let input: any = [];
                            let fCounter = 0;
                            let vInput = [];
                            for (let j = 0; j < fCount; j++) {
                                let field = fieldData[j];
                                if (field.groupFormId == group.id) {
                                    let option = field.options.split("*|||*");
                                    let required = field.isRequired == 'y' ? true : false;
                                    let extension = field.extensions;
                                    input.push({
                                        id: fCounter,
                                        name: field.textLabel,
                                        type: field.type,
                                        size: field.length,
                                        rows: 3,
                                        columns: field.lengthColumn,
                                        mask: field.mask,
                                        option,
                                        extension,
                                        required,
                                        savedId: field.id,
                                    })
                                    fCounter++;
                                    vInput.push(field.type == "Caixa_verificacao" ? false : null);
                                }
                            }
                            groupArrI.inputs = input;
                            val[i].inputs = vInput;
        
                            let tables: any = [];
                            let stCounter = 0;
                            let tInput = [];
                            for (let k = 0; k < tCount; k++) {
                                let table = tableData[k];
                                if (table.groupFormId == group.id) {
                                    tables.push({
                                        id: stCounter,
                                        name: table.name,
                                        dataColumns: [],
                                        columns: 0,
                                        rows: table.countRows,
                                        savedId: table.id,
                                    });
        
                                    let dataColumns: any = [];
                                    let dColumnsCounter = 0;
                                    let tfInput: any = [[]];
                                    for (let l = 0; l < tfCount; l++) {
                                        let tField = tableFieldData[l];
                                        if (tField.TableFieldBpmId == table.id) {
                                            let option = tField.options.split("*|||*");
                                            let required = tField.isRequired == 'y' ? true : false;
                                            let extension = tField.extensions;
                                            dataColumns.push({
                                                id: dColumnsCounter,
                                                name: tField.textLabel,
                                                type: tField.type,
                                                size: tField.length,
                                                rows: 3,
                                                columns: tField.lengthColumn,
                                                mask: tField.mask,
                                                option,
                                                extension,
                                                required,
                                                savedId: tField.id,
                                            });
                                            dColumnsCounter++;
                                            tfInput[0].push(tField.type == "Caixa_verificacao" ? false : null);
                                        }
                                    }
                                    tInput.push(tfInput);
                                    tables[stCounter].dataColumns = dataColumns;
                                    stCounter++;
                                }
                            }
                            val[i].tables = tInput;
                            groupArrI.tables = tables;
                        }
                        setValue(val);
                        setForm(groupArr);
                    }
                }

                setIsLoadingSolicitationForm(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, []);

    const fetchProcesses = async () => {
        try {
            const results = await api.get('/bpm/solicitacao-processo-formulario/autocomplete');
            setProcesses(results.data);
        } catch (error) {
            console.log(error);
            setIsLoadingSolicitationForm(false);
        }
    }

    useEffect(() => {
        fetchProcesses();
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPageActivity, pagesActivity, triggerLoad, pageChanged]);

    const handleLoadData = useCallback(async () => {
        setIsLoading(true);
        await loadData({ rowsPerPage: rowsPerPageActivity, currentPage: pagesActivity, sortDirection: sortDirectionActivity, sortReference: sortReferenceActivity });
        setIsLoading(false);

        if (pageChanged) {
            setPageChanged(false);
        }

        if (triggerLoad && setTriggerLoad) {
            setPagesActivity(0);
            setTriggerLoad(false);
        }
    }, [rowsPerPageActivity, pagesActivity, triggerLoad, pageChanged, sortDirectionActivity, sortReferenceActivity]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setIsLoadingData(true);
        const { data } = await api.get<{rows: ActivityProcessBpm[], count: number}>(`/bpm/activities`, {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setActivitiesCount(count);
        setActivities(rows);
        setIsLoadingData(false);
    }, []);

    const getResponsables = (data: any) => {
        let arr = data ? JSON.parse(data) : [];
        let count = arr.length;
        let responsables = [];
        for (let i = 0; i < count; i++) {
            responsables.push(`${arr[i].firstname} ${arr[i].lastname}`);
        }

        return responsables.join(',');
    }

    const getActivity = (dataId: string, data:any) => {
        let activity = "";
        let arr = data ? JSON.parse(data) : [];
        let count = arr.length;
        for (let i = 0; i < count; i++) {
            if (arr[i].id == dataId) {
                activity = arr[i].atividade;
                break;
            }
        }

        return activity;
    }

    const handleChangeProcessFilter = useCallback((process: ProcessBpm | null | undefined) => {
        setProcessFilter(String(process?.id ?? ''));
    }, []);

    const handleChangePageActivity = useCallback((next: number) => {
        setPagesActivity(next);
    }, []);

    const handleRowsPerPageActivity = useCallback((value: number) => {
        setRowsPerPageActivity(value);
        setPagesActivity(0);
    }, []);

    const handleClickSearch = useCallback(async () => {
        console.log("pesquisar");
        return;

        filtersRef.current = {
            searchQuery,
            startDate, 
            endDate, 
            processFilter,
            situation,
            requester,
            responsible
        };

        reloadData();
    }, [
        searchQuery, startDate, endDate, processFilter, situation, requester, responsible
    ]);
    

    const clearSearch = () => {
        setSearchQuery('');
        setStartDate('');
        setEndDate('');
        setProcessFilter('');
        setSituation('');
        setRequester('');
    }

    const createSolicitation = async () => {
        setIsSubmit(true);

        try {
            let raw = {
                solicitationName,
                processId: solicitationProcessId,
            }

            await api.post('/bpm/solicitation/NewSolicitation', raw);

            setIsSubmit(false);
            setShowNewSolicitationModal(false);
            setShowModalSuccess(true);
        } catch (error) {
            setIsSubmit(false);
            setShowNewSolicitationModal(false);
            setShowModalError(true);
            setMsgError("Erro ao criar solicitação!");
            console.log(error);
        }
    }

    function closeModalSuccess() {
        setShowModalSuccess(false);
        handleLoadData();
    }

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalSuccess
                msgModal={'Solicitação criada com sucesso!'}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                onClose={closeModalSuccess}
            />
            <Modal
                size="lg"
                show={showViewSolicitationModal}
                backdrop="static"
                onHide={() => setShowViewSolicitationModal(false)}
                scrollable
            >
                <Modal.Header>
                    <Modal.Title>
                        {
                            isLoadingSolicitationForm
                            ? <>Carregando...</>
                            : <>{name}</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoadingSolicitationForm
                        ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" />
                            </div>
                        :
                            <>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(tab: string) => setActiveTab(tab)}
                                    id="solicitation-tabs"
                                >
                                    <Tab eventKey="form" title="Formulário">
                                        { displayForm() }
                                    </Tab>

                                    <Tab eventKey="history" title="Histórico">
                                        Aqui vai o histórico
                                    </Tab>

                                    <Tab eventKey="diagram" title="Diagrama">
                                        <BpmnWithFormViewer
                                            tableRows={solicitationProcess}
                                            setTableRows={setSolicitationProcess}
                                            processActivities={processActivities}
                                            createWithForm={false}
                                            displayOnly={true}
                                            selectedShape={selectedShape}
                                            setSelectedShape={setSelectedShape}
                                        />
                                    </Tab>
                                </Tabs>
                                {/* { displayGatewayNextStage() } */}
                                <div className="mt-3 text-right">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={advanceToNextActivity}
                                                onChange={(e) => setAdvanceToNextActivity(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Ir para a próxima atividade"
                                    />
                                </div>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary">Salvar</Button>
                    <Button variant="secondary" onClick={() => setShowViewSolicitationModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showNewSolicitationModal}
                onHide={() => setShowNewSolicitationModal(false)}
            >
                <Modal.Header>
                    <Modal.Title>Nova solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container>
                        <Grid item lg={12}>
                            <TextField
                                label="Nome da solicitação"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                type="text"
                                value={solicitationName}
                                onChange={(e) => setSolicitationName(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <TextField
                                select
                                label="Processo"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={solicitationProcessId}
                                onChange={(e) => setSolicitationProcessId(e.target.value)}
                            >
                                {
                                    processes
                                    ? processes.map((process) => 
                                        <MenuItem key={process.id} value={process.id}>
                                            {process.name}
                                        </MenuItem>
                                    )
                                    : (
                                        "Não há processos!"
                                    )
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => createSolicitation()} disabled={isSubmit}>
                        {isSubmit ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className="ml-2">
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Salvar
                            </span>
                        </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowNewSolicitationModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <div className="row d-flex align-items-center justify-content-end">
                <div className="col-lg-9">
                    {
						user.isAccountant == 'n'
						?
                        (processes
                            ?
                                <Button
                                    type="button"
                                    variant="success"
                                    className="mr-2"
                                    onClick={() => setShowNewSolicitationModal(true)}
                                >
                                    Nova solicitação
                                </Button>
                            : <></>)
						: <></>
					}
                </div>

                <div className="col-lg-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-3">
                        <TextField
                            label={"Inicio Período"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            label={"Fim Período"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Processos"
                            getOptionLabel={(option: ProcessBpm) => option.name}
                            value={processFilter}
                            hasError={processErrorFilter}
                            onSelect={handleChangeProcessFilter}
                            apiSearchHandler={(typedText) => ProcessBpmService.getProcessFiltered({ name: typedText, situation: 'y' })}
                            getSelectedOption={(loadedOptions) => {
                                if (!processFilter) return null;
                                return loadedOptions.find((option) => option.id === Number(processFilter)) ?? ProcessBpmService.getProcessById(processFilter)
                            }}
                            onChangeTextField={(e) => setProcessErrorFilter(e.target.value ? false : true)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            label="Situação Solicitação"
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={situation}
                            onChange={(evt) => setSituation(evt.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>
                            <MenuItem key="1" value="active">
                                Em Progresso
                            </MenuItem>
                            <MenuItem key="2" value="completed">
                                Concluída
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Solicitante"
                            noOptionsText="Nenhum usuário encontrado"
                            getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                            value={requester}
                            onSelect={(option) => setRequester(String(option?.id ?? 'all'))}
                            apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!requester || requester === 'all') return null;
                                return loadedOptions.find((option) => option.id === Number(requester)) ?? UserService.getUserById(requester)
                            }}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>
            <div className="col-lg-12 mt-3 shadow" style={{ overflow: "auto" }}>
                <Table stickyHeader className="wrap">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Processo</TableCell>
                            <TableCell>Atividade</TableCell>
                            {/* <TableCell>Descrição</TableCell> */}
                            <TableCell>Solicitante</TableCell>
                            <TableCell>Responsável</TableCell>
                            {/*<TableCell>Cliente</TableCell>*/}
                            {/* <TableCell>Início solicitação</TableCell> */}
                            <TableCell>Início atividade</TableCell>
                            <TableCell>Prazo atividade</TableCell>
                            {/*<TableCell>Entrega</TableCell>*/}
                            <TableCell>Situação solicitação</TableCell>
                            {/*<TableCell>Situação atividade</TableCell>*/}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isLoadingData && (
                            <TableRow>
                                <TableCell colSpan={99}>
                                    <div className="d-flex justify-content-center">
                                        <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {
                            !isLoadingData && activities.slice(pagesActivity * rowsPerPageActivity, pagesActivity * rowsPerPageActivity + rowsPerPageActivity).map((activity: any, index: number) => {
                                return (
                                    <TableRow key={activity.id} hover>
                                        <TableCell>{activity.id}</TableCell>
                                        <TableCell>{activity.processEntity.name}</TableCell>
                                        <TableCell>{getActivity(activity.processBpmStage, activity.processData)}</TableCell>
                                        <TableCell>{activity.userEntity.firstname} {activity.userEntity.lastname ?? ''}</TableCell>
                                        <TableCell>{getResponsables(activity.Responsables)}</TableCell>
                                        {/*<TableCell>{activity.customer}</TableCell>*/}
                                        {/* <TableCell>{activity.solicitation_created_at}</TableCell> */}
                                        <TableCell>{getDate({ initialDate: new Date(activity.updatedDate) }).dateStr}</TableCell>
                                        <TableCell>{getDate({ initialDate: new Date(activity.endDate) }).dateStr}</TableCell>
                                        {/*<TableCell>{activity.delivery}</TableCell>*/}
                                        <TableCell>
                                            <Badge variant={situationColor[activity.statusSolicitation]}>
                                                {situationText[activity.statusSolicitation]}
                                            </Badge>
                                        </TableCell>
                                        {/*<TableCell>
                                            <Badge variant={situationColor[activity.statusActivity]}>
                                                {situationText[activity.statusActivity]}
                                            </Badge>
                                        </TableCell>*/}
                                        <TableCell>
                                            <Tooltip title="Visualizar solicitação">
                                                <Button onClick={() => getSolicitation(activity.ProcessBpmId, activity.id)} size="sm">
                                                    <i className="flaticon-map p-0"></i>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                        {!isLoadingData && activities.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={99} className="text-center">
                                    Nenhum resultado encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    labelRowsPerPage="Linhas por página"
                    page={pagesActivity}
                    component="div"
                    count={activitiesCount}
                    rowsPerPage={rowsPerPageActivity}
                    rowsPerPageOptions={[5, 10, 25]}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima Página',
                    }}
                    onChangePage={(_, next) => handleChangePageActivity(next)}
                    onChangeRowsPerPage={(evt) => handleRowsPerPageActivity(Number(evt.target.value))}
                />
            </div>
        </>
    );
}

const defaults = {

}

const styles = {
    infos: {
        backgroundColor: "#fdfdfd", 
        borderLeft: "3px solid #2CFFEA",
        marginTop: "5px",
        padding: "15px",
    },
    buttonGroupStyle: {
        border: "none",
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        float: "left" as "left",
        marginRight: 6,
    },
    buttonGroupIcon: {
        color: "#555",
        fontSize: 15,
        marginTop: 5,
    },
    groupDivStyle: {
        width: "100%",
    },
    table: {
        border: "1px solid #DCDCDC",
    },
    tableButton: {
        width: 45,
        height: 45,
    },
    header: {
        width: "100%",
        height: 40,
        border: `1px solid #DCDCDC`,
        borderRadius: 6,
        marginBottom: 30,
    },
    headerButton: {
        border: "none",
        backgroundColor: "transparent",
        marginLeft: 15,
        marginTop: 8,
        color: "#555",
        padding: 0,
        borderBottom: "none",
        borderRadius: 0,
    },
    selected: {
        fontWeight: 600,
        color: "#222",
        borderBottom: "2px solid #222",
    },
    saveButton: {
        marginRight: 5,
        marginTop: -5,
        float: "right" as "right",
    },
    displayFileField: {
        width: "100%",
        height: 54,
        border: "1px solid #BBB",
        borderRadius: 4,
        lineHeight: "50px",
    },
    fileDeleteButton: {
        float:"right" as "right",
        marginRight: 5,
        marginTop:3,
    }
}

// const styles = {
//     content: {
//         overflow: "scroll",
//     },
//     header: {
//         width: "100%",
//         border: `1px solid #DCDCDC`,
//         borderRadius: 6,
//         marginTop: -20,
//         marginBottom: 40,
//     },
//     filterButtons: {
//         float: "right" as "right",
//         marginTop: -45,
//         position: "relative" as "relative",
//         zIndex: 50,
//     },
// }

export default ActivityProcess;