import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from "../../services/Api";
import { List, Option } from '../../components/List';


type ConditionPayment = {
    id: number;
    name: string;
    status: "active" | "inactive";
    amountPaymentInstallment: number;
};


const headData = [
    "Id",
    "Nome",
    "Quantidade de parcelas",
    "Situação",
];

export function ListConditionPayment() {
    const [list, setList] = useState<Option[]>([]);
    const [conditionPayments, setConditionPayments] = useState<ConditionPayment[]>([]);

    const { location: { pathname }, push: pushHistory } = useHistory();

    useEffect(() => {
        async function loadData() {
            const response = await api.get("conditionPayment");

            setConditionPayments(response.data);
        }

        loadData();
    }, []);

    useLayoutEffect(() => {
        const newList: Option[] = [];

        const sortedConditionPayments = conditionPayments.sort(
            (condition, condition_) => {
                if (condition.id < condition_.id) {
                    return 1;
                }

                if (condition.id > condition_.id) {
                    return -1;
                }

                return 0;
            }
        );

        sortedConditionPayments.forEach(({ id, name, amountPaymentInstallment, status: statusResponse }) => {
            const status = statusResponse === "active" ? "Ativo" : "Inativo";

            const prodData: Option = {
                key: [
                    String(id),
                    name,
                    String(amountPaymentInstallment),
                    status,
                ]
            }

            newList.push(prodData);
        });

        setList(newList);
    }, [conditionPayments]);

    async function handleDelete(id: string) {
        try {
            await api.delete(`conditionPayment/${id}`);

            setConditionPayments(state => [...state.filter((condition) => condition.id !== Number(id))]);
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    return (
        <List
            options={list}
            headData={headData}
            onDelete={handleDelete}
            onClickEdit={handleEdit}
            addButtonLink="parametros/condicoes-de-pagamento/adicionar"
            addButtonLinkName="Nova condição"
        />
    );
}