import { BillsCashFlow } from "../types/BillsCashFlow";

export function getBalanceAccountBankCashFlow(currentBill: BillsCashFlow, openingBalance: number, allBills: BillsCashFlow[], pending: boolean, paid: boolean): number {
    let balance = openingBalance;
    for (var bill of allBills) {
        let amount = 0;
        if (bill.status == "paid") {
            amount = bill.totalPaid ? bill.totalPaid : bill.amount;
        } else {
            amount = bill.amount;
        }

        if ((pending == false && paid == false) || (pending == true && paid == true)) {
            if (bill.amount) {

                if (bill.typeBills == "pay") {
                    balance -= amount;

                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        } else if (pending) {
            if (bill.status != "paid") {
                if (bill.typeBills == "pay") {
                    balance -= amount;

                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        } else if (paid) {
            if (bill.status == "paid") {
                if (bill.typeBills == "pay") {
                    balance -= amount;

                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        }

        if (bill.id === currentBill.id && bill.typeBills === currentBill.typeBills) {
            break;
        }
    }

    return balance;
}

export function getBeforeBalanceCashFlow(date: string, openingBalance: number, allBills: BillsCashFlow[], pending: boolean, paid: boolean): number {
    var bills = allBills.filter((billsData) => new Date(billsData.date) <= new Date(date));

    var balance = openingBalance;

    for (var bill of bills) {
        var amount = 0;
        if (bill.status == "paid") {
            amount = bill.totalPaid ? bill.totalPaid : bill.amount;
        } else {
            amount = bill.amount;
        }
        
        if ((pending == false && paid == false) || (pending == true && paid == true)) {
            if (bill.amount) {
                if (bill.typeBills == "pay") {
                    balance -= amount;
                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        } else if (pending) {
            if (bill.status != "paid") {
                if (bill.typeBills == "pay") {
                    balance -= amount;
                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        } else if (paid) {
            if (bill.status == "paid") {
                if (bill.typeBills == "pay") {
                    balance -= amount;
                } else if (bill.typeBills == "receive") {
                    balance += amount;
                }
            }
        }
    }

    return balance;
}