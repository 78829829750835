import { Seller } from "../types/Seller";
import { formatToFloat } from "../utils/formatCurrency";
import api from "./Api";

class SellerService {
    async getSellersFiltered(filters: any) {
        const sellerResponse = await api.get<Seller[]>('/sellers', { params: { filters } });

        const sellers: Seller[] = sellerResponse.data.map((seller) => ({ ...seller, name: this.getName(seller) }));

        return sellers;
    }

    async getSellerById(id: number | string, withConsignment: boolean = false) {
        const { data } = await api.get<Seller>(`/sellers/${id || '0'}`, { params: { withConsignment: withConsignment || undefined } });
        if (!data) return null;

        data.name = this.getName(data);
        return data;
    }

    getName(seller?: any, defaultValue: string = '') {
        if (!seller || typeof seller !== 'object') {
            return defaultValue ? defaultValue : '';
        }

        return seller.typePeople === 'legal' ? seller.corporateName : seller.name;
    }

    async getCommissionPercentage(sellerId: string | number, sellerObj?: Seller) {
        if (!sellerId) {
            return 0;
        }

        const seller = sellerObj ?? await this.getSellerById(sellerId);

        if (!seller) {
            return 0;
        }

        const commissionPercentage = seller.sellerCommission ? formatToFloat(seller.sellerCommission) / 100 : 0;

        return commissionPercentage;
    }

    async getNameByIdAsync(id: number | string) {
        const seller = await this.getSellerById(id);

        if (!seller) {
            return '';
        }

        const sellerName = seller.typePeople === 'legal' ? seller.corporateName : seller.name;

        return sellerName;
    }

    getCommissioningText(value: string): string {
        switch (value) {
            case 'full release on billing':
                return 'Integral';
            case 'partial release on billing':
                return 'Parcial';
            default:
                return 'N/A';
        }
    }
}

export default new SellerService();