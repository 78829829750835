import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ListStockLocations } from '../pages/Parameters/ListStockLocations';

import { NewCategory } from '../pages/Category/New-Category';
import { ListCategory } from '../pages/Category/List-Category';
import { EditCategory } from '../pages/Category/Edit-Category';

import { NewCenterCost } from '../pages/CostCenter/New-CenterCost';
import { ListCenterCost } from '../pages/CostCenter/List-CenterCost';
import { EditCenterCost } from '../pages/CostCenter/Edit-CenterCost';

import { NewConditionPayment } from '../pages/ConditionPayment/New-ConditionPayment';
import { ListConditionPayment } from '../pages/ConditionPayment/List-ConditionPayment';
import { EditConditionPayment } from '../pages/ConditionPayment/Edit-ConditionPayment';


export function Parameters() {
    
    return (
        <Switch>
            <Route path="/parametros/locais-de-estoque" component={ListStockLocations} />
            
            <Route path="/parametros/categorias/adicionar" component={NewCategory}/>
            <Route path="/parametros/categorias/:id" component={EditCategory}/>
            <Route path="/parametros/categorias" component={ListCategory}/>

            <Route path="/parametros/centro-de-custos/adicionar" component={NewCenterCost}/>
            <Route path="/parametros/centro-de-custos/:id" component={EditCenterCost}/>
            <Route path="/parametros/centro-de-custos" component={ListCenterCost}/>

            <Route path="/parametros/condicoes-de-pagamento/adicionar" component={NewConditionPayment}/>
            <Route path="/parametros/condicoes-de-pagamento/:id" component={EditConditionPayment}/>
            <Route path="/parametros/condicoes-de-pagamento" component={ListConditionPayment}/>
        </Switch>
    );
}