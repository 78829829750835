export function formatNcm(ncm: string): string {
    if(!ncm) return '';
    
    return ncm.replace(/\D/g, '')
    .replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');

}

export function sanitizeNcm(formattedNcm: string): string {
    if(!formattedNcm) return '';

    return formattedNcm.replace(/\./g, '');
}