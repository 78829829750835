import api from "./Api";

class GridService {
    async getGridById(id: string | number) {
        const gridResponse = await api.get(`/grid/${id || '0'}`);

        return gridResponse.data;
    }

    async getGridsFiltered(filters: any) {
        const gridResponse = await api.get<any[]>('/grid', { params: { filters } });

        return gridResponse.data;
    }
}

export default new GridService();