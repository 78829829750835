import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyService from "../services/CompanyService";

type UseCompanyBranchParams = {
    withNfApiConfig?: boolean,
};

export function useCompanyBranch({ withNfApiConfig }: UseCompanyBranchParams = {}) {
    const { selectedCompanyId } = useSelector((state: any) => state.auth);

    const [selectedCompany, setSelectedCompany] = useState<any>();

    const loadCompany = useCallback(async () => {
        if (!selectedCompanyId) return;

        const foundCompany = await CompanyService.getCompanyById(selectedCompanyId, { withNfApiConfig });

        setSelectedCompany(foundCompany);
    }, [selectedCompanyId]);

    useEffect(() => {
        loadCompany();
    }, [loadCompany]);

    return { selectedCompany, selectedCompanyId, setSelectedCompany, reloadCompanyData: loadCompany };
}