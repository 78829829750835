import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
    Line
} from "@react-pdf/renderer";
import { usePdfStyles } from "../../../hooks/pdfStyles";

type PdfReportHeaderParams = {
    company: any;
    children?: React.ReactNode;
}

export function PdfReportHeader({ company, children }: PdfReportHeaderParams) {
    const styles = usePdfStyles();

    const today = new Date().toLocaleDateString();

    return (
        <View style={styles.header}>
            <View style={styles.photo}>
                <Image source={{ uri: `${process.env.REACT_APP_API_URL}/${company.logo}`, method: "GET", headers: {}, body: "" }} style={styles.customerPhoto} />
            </View>
            <View style={styles.infoHeader}>
                <View style={styles.titleHeader}>
                    <Text>{children}</Text>
                </View>
                <Text style={styles.titleInfo}>{today}</Text>
            </View>
        </View>
    );
}