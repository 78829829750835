import React from "react";
// import {ListsWidget10, ListsWidget11, AdvanceTablesWidget1, MixedWidget6, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14} from "../widgets";

export function Demo2Dashboard() {
    return <>
        {/* begin::Dashboard */}

        {/* begin::Row */}
        <div className="row">
            <div className="col-lg-6">
                {/* <ListsWidget10 className="card-stretch gutter-b" /> */}
            </div>
            <div className="col-lg-6">
                {/* <ListsWidget11 className="card-stretch gutter-b" /> */}
            </div>
        </div>
        {/* end::Row */}

        {/* end::Dashboard */}
    </>;
}
