import React, { useEffect, useRef, useState } from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import { v4 as uuidv4 } from 'uuid';
import BpmnModal from './BpmnModal';

interface TableRowData {
  id: string,
  tipo: string,
  atividade?: string,
  responsaveis?: Responsible[],
  destino?: string[],
  tipo_id?: number,
  alinhamento?: number,
  prazo?: number,
  permissions?: { [key: string]: string };
  container?: object,
  subcontainers?: string[],
  width?: number,
  height?: number,
  x?: number,
  y?: number,
  parent?: object,
  children?: object,
}

interface Responsible{
  id: string;
  firstname: string;
}

const cores: { [key: string]: string } = {
  'Início' : 'Cyan',
  'Fim' : 'red',
  'Atividade' : 'MediumSlateBlue',
  'Exclusivo' : 'Gold',
  'Paralelo' : 'Gold',
  'Inclusivo' : 'Gold',
  'Participante' : 'White',
  'BancoDeDados' : 'White',
  'Objeto' : 'White',
  'Grupo' : 'White',
  'Evento': 'White',
  'EventoCatch': 'White',
  'SubProcesso': 'White',
  'Faixa' : 'White',
  'FluxoSequencia': 'Black',
  'CaixaTexto': 'White',
};

const tags: { [key: string]: string } = {
  'Início': 'StartEvent',
  'Fim': 'EndEvent',
  'Atividade': 'Task',
  'Exclusivo': 'ExclusiveGateway',
  'Paralelo': 'ParallelGateway',
  'Inclusivo': 'InclusiveGateway',
  'Participante': 'Participant',
  'BancoDeDados': 'DataStoreReference',
  'Objeto': 'DataObjectReference',
  'Grupo': 'Group',
  'Evento': 'IntermediateThrowEvent',
  'EventoCatch': 'IntermediateCatchEvent',
  'SubProcesso': 'SubProcess',
  'Faixa': 'Lane',
  'FluxoSequencia': 'SequenceFlow',
  'CaixaTexto': 'TextAnnotation',
};

const excludedElements = [
  'Participante',
  'Faixa',
]

const invertObject = (obj: { [key: string]: string }) => {
  const inverted: { [key: string]: string } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      inverted[obj[key]] = key;
    }
  }
  return inverted;
};

const invertedTags = invertObject(tags);

const gatewayTypes = ['ExclusiveGateway', 'InclusiveGateway', 'ParallelGateway'];

interface BpmnViewerProps {
  tableRows: TableRowData[];
  setTableRows: React.Dispatch<React.SetStateAction<TableRowData[]>>;
  processActivities: string[];
  displayOnly?: boolean;
}

const BpmnViewer: React.FC<BpmnViewerProps> = ({
  tableRows,
  setTableRows,
  processActivities,
  displayOnly = false,
}) => {
  const bpmnModelerRef = useRef<BpmnModeler | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [shapeCount, setShapeCount] = useState<any>(0);
  const [b, setB] = useState<boolean>(true);
  const [selectedShape, setSelectedShape] = useState<any>(null);

  const setUp = () => {
    setB(!b);
  }

  useEffect(() => {
    if (containerRef.current) {
      const bpmnModeler = new BpmnModeler({
        container: containerRef.current,
      });

      bpmnModelerRef.current = bpmnModeler;

      const createDiagram = async () => {
        try {
          await bpmnModeler.createDiagram();
          const elementRegistry = bpmnModeler.get('elementRegistry') as any;
          const modeling = bpmnModeler.get('modeling') as any;
          const eventBus = bpmnModeler.get('eventBus') as any;
          const elementFactory = bpmnModeler.get('elementFactory') as any;
          const canvas = bpmnModeler.get('canvas') as any;
          const rootElement = canvas.getRootElement();
          //const rootElement = elementRegistry.get('Process_1');
          // console.log(eventBus);

          // Obter todos os elementos StartEvent
          const startEvents = elementRegistry.filter((element: any) => element.type === 'bpmn:StartEvent');

          // Remover todos os elementos StartEvent
          startEvents.forEach((startEvent: any) => {
            modeling.removeElements([startEvent]);
          });

          if (!rootElement) {
            throw new Error('No process found in the diagram.');
          }
          const elementsMap: { [key: string]: any } = {};

          // Função para gerar um ID único
          const generateUniqueId = (prefix: string) => {
            return `${prefix}_${Math.random().toString(36).substr(2, 9)}`;
          };

          // Adicionar todos os elementos primeiro
          let cont = 1;
          tableRows.forEach(element => {
            // console.log("elemento: ", element)
            if (element.tipo !== "Participante") {
              const elementId = element.atividade || generateUniqueId(tags[element.tipo]);
              let bloco;
              let shape = elementFactory.createShape({
                type: `bpmn:${tags[element.tipo ? element.tipo : 0]}`,
              });
              shape.width = element.width;
              shape.height = element.height;
              shape.x = element.x || 180;
              shape.y = element.y || 120 * cont;
              let coords = {
                x: element.x || 180,
                y: element.y || 120 * cont
              };

              if (element.tipo === "Participante") {
                shape = elementFactory.createShape({
                  type: `bpmn:Participant`,
                });
                shape.width = 980;
                shape.height = 560;
                shape.x = 620;
                shape.y = 300;
                coords.x = 620;
                coords.y = 300;
              }

              bloco = modeling.createShape(
                shape,
                coords,
                rootElement
              );

              if (bloco) {
                // Definir um ID personalizado para o elemento
                modeling.updateProperties(bloco, {
                  id: element.id,
                  name: element.atividade,
                });

                let gfx = elementRegistry.getGraphics(bloco.id);
                if (gfx) {
                  Array.from(gfx.children).forEach((child: any) => {
                    Array.from(child.children).forEach((grandchild: any) => {
                      if (grandchild.nodeType === Node.ELEMENT_NODE && grandchild.tagName !== 'text') {
                        grandchild.style.fill = cores[element.tipo];
                      }
                    });
                  });
                }
                elementsMap[elementId] = bloco;
              }
            }
            cont += 1;
          });

          // Criar conexões entre os elementos
          tableRows.forEach(element => {
            if (element.destino && element.atividade) {
              element.destino.forEach(dest => {
                const sourceElement = elementsMap[element.atividade!];
                const targetElement = elementsMap[dest];

                // Verificar se ambos os elementos existem antes de tentar criar a conexão
                if (sourceElement && targetElement) {
                  modeling.connect(sourceElement, targetElement, {
                    type: 'bpmn:SequenceFlow',
                  });
                } else {
                  console.error(`Erro ao conectar: ${element.atividade} -> ${dest}`);
                }
              });
            }
          });

          // Tratamento da troca de elementos
          eventBus.on('commandStack.element.updateProperties.execute', (event: any) => {
            // console.log('replace')
            const { oldElement, newElement } = event;
            const oldAtividade = tableRows.find(row => row.id === oldElement ? oldElement.id : 0)?.atividade;

            if (oldAtividade !== undefined) {
              setTableRows((prevRows) => {
                return prevRows.map((row) => {
                  // Remover o elemento antigo e adicionar o novo
                  if (row.id === oldElement.id) {
                    return {
                      ...row,
                      id: newElement.id,
                      tipo: Object.keys(tags).find(key => tags[key] === newElement.businessObject.$type.split(':')[1])!,
                      atividade: newElement.businessObject.name || '',
                      tipo_id: gatewayTypes.includes(newElement.businessObject.$type.split(':')[1]) ? 2 : 1
                    };
                  }

                  // Atualizar o array 'destino' de outros elementos que referenciam a atividade antiga
                  const updatedDestino = row.destino
                    ? row.destino.map(dest => dest === oldAtividade ? newElement.businessObject.name || '' : dest)
                    : row.destino;

                  return { ...row, destino: updatedDestino };
                });
              });
            }
          });

          // Adicionar ouvinte para mudanças nos elementos do diagrama
          eventBus.on('shape.added', (event: any) => {
            const { element } = event;
            if (element.businessObject.$type != "bpmn:Participant") {
              // console.log(element);
              if (element.businessObject.$type.startsWith('bpmn:')) {
                let elementType = invertedTags[element.businessObject.$type.split(':')[1]];
                let count = shapeCount;
                const newElement: TableRowData = {
                  id: uuidv4(),
                  tipo: Object.keys(tags).find(key => tags[key] === element.businessObject.$type.split(':')[1])!,
                  atividade: `${elementType}_${count}`,
                  destino: [],
                  tipo_id: gatewayTypes.includes(element.businessObject.$type.split(':')[1]) ? 2 : 1,
                  width: element.width,
                  height: element.height,
                  x: element.x,
                  y: element.y,
                  parent: element.parent || undefined,
                  children: element.children || undefined,
                };
                // console.log("novo elemento: ", newElement);
                count++;
                setShapeCount(count);
                setTableRows(prevRows => [...prevRows, newElement]);

                // Estilizar o novo elemento
                let gfx = elementRegistry.getGraphics(element.id);
                if (gfx) {
                  Array.from(gfx.children).forEach((child: any) => {
                    Array.from(child.children).forEach((grandchild: any) => {
                      if (grandchild.nodeType === Node.ELEMENT_NODE && grandchild.tagName !== 'text') {
                        grandchild.style.fill = cores[newElement.tipo];
                      }
                    });
                  });
                }
              }
            }
          });

          eventBus.on('shape.removed', (event: any) => {
            const { element } = event;
            let activity = element.businessObject.name;
            let updatedDataWithUpdatedDestinos = tableRows.map(item => {
              if (item.destino) {
                return {
                  ...item,
                  destino: item.destino.filter(destino => destino !== activity)
                };
              }
              return item;
            });
            setTableRows(updatedDataWithUpdatedDestinos);
            setTableRows(prevRows => prevRows.filter(row => row.id !== element.id));
          });

          eventBus.on('connection.added', (event: any) => {
            const { element } = event;
            const source = tableRows.find(row => row.id === element.source.id);
            const target = tableRows.find(row => row.id === element.target.id);

            if (source && target) {
              if (processActivities.includes(target.tipo || "")) {
                setTableRows(prevRows =>
                  prevRows.map(row => {
                    if (row.id === source.id) {
                      const newDestino = [...(row.destino || []), target.atividade || ''];
                      return { ...row, destino: newDestino };
                    }
                    return row;
                  })
                );
              }
            }
          });

          // Ouvinte para mudanças no nome do elemento
          eventBus.on('element.changed', (event: any) => {
            // console.log('change')
            const { element } = event;
            setTableRows((prevRows) => {
              return prevRows.map((row) => {
                const updatedRow = row.id === element.id
                  ? {
                      ...row,
                      width: element.width,
                      height: element.height,
                      x: element.x,
                      y: element.y,
                    }
                  : row;

                return { ...updatedRow };
              })
            });

            if (element.businessObject && element.businessObject.name) {
              // Armazenar a atividade antiga
              const oldAtividade = tableRows.find(row => row.id === element.id)?.atividade;
              // console.log("oldAtividade: ", oldAtividade);
              if (oldAtividade !== undefined) {
                setTableRows((prevRows) => {
                  return prevRows.map((row) => {
                    // Atualizar o atributo 'atividade' do elemento alterado
                    const updatedRow = row.id === element.id
                      ? { ...row, atividade: element.businessObject.name || '' }
                      : row;

                    // Atualizar o array 'destino' de outros elementos que referenciam a atividade antiga
                    const updatedDestino = row.destino
                      ? row.destino.map(dest => dest === oldAtividade ? element.businessObject.name || '' : dest)
                      : row.destino;

                    // Retornar a linha atualizada
                    return { ...updatedRow, destino: updatedDestino };
                  });
                });
              }
            }
          });
          displayUpdatedCanvas();
        } catch (error) {
          console.error('Failed to create diagram:', error);
        }
      };

      createDiagram();
      setUp();
    }
  }, [tableRows, setTableRows]);

  const displayUpdatedCanvas = async() => {
    let el: any = document.querySelectorAll(".bjs-container");
    let count = el.length ? el.length - 1 : el.length;
    for (let i = 0; i < count; i++) {
      el[i].remove();
    }
    let participantButton = document.querySelector(".bpmn-icon-participant");
    let subProcessButton = document.querySelector(".bpmn-icon-subprocess-expanded");
    let intermediateEventButton = document.querySelector(".bpmn-icon-intermediate-event-none");
    let dataObjectButton = document.querySelector(".bpmn-icon-data-object");
    let dataStoreButton = document.querySelector(".bpmn-icon-data-store");
    let groupButton = document.querySelector(".bpmn-icon-group");
    let spaceTool = document.querySelector(".bpmn-icon-space-tool");
    let conectionM = document.querySelector(".bpmn-icon-connection-multi");
    let handTool = document.querySelector(".bpmn-icon-hand-tool");
    let textAnnot = document.querySelector(".bpmn-icon-text-annotation");
    let lassoTool = document.querySelector(".bpmn-icon-lasso-tool");
    let logo = document.querySelector(".bjs-powered-by");

    participantButton?.remove();
    subProcessButton?.remove();
    intermediateEventButton?.remove();
    dataObjectButton?.remove();
    dataStoreButton?.remove();
    groupButton?.remove();
    spaceTool?.remove();
    conectionM?.remove();
    handTool?.remove();
    textAnnot?.remove();
    lassoTool?.remove();
    logo?.remove();

    if (displayOnly) {
      let sideBar = document.querySelector(".djs-palette");
      sideBar?.remove();
      let itemPad: any = document.querySelector(".djs-context-pad-parent");
      if (itemPad) {
        itemPad.style.display = "none";
      }
    }
  }

  return (
    <div
      style={{height: '600px', width: '100%' }}
      ref={containerRef}
    ></div>
  );
};

export default BpmnViewer;
