import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import api from '../../services/Api';
import { User } from '../../types/User';
import { bypassAuthHeader } from '../../utils/bypassAuthHeader';

export default function NewPassword () {
    const { idUser, companyId } = useParams<{idUser: string, companyId: string}>();
    const history = useHistory();

    const [userEmail, setUserEmail] = useState('');
    
    // Inputs
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    // Errors
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState("");


    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function verifyUserPassword() {
            const { data: user } = await api.get<User>(`/users/${idUser}`, {
                headers: bypassAuthHeader,
            });

            const { data: userHasPassword } = await api.get(`/users/${idUser}/password`, {
                headers: bypassAuthHeader,
            });

            if (userHasPassword) {
                window.location.href = "/auth/login";
            }

            setUserEmail(user.email);
        }

        verifyUserPassword();
    }, [history, idUser]);

    function inputsVerify () {
        if (!password) {
            setPasswordError(true);
            setPasswordErrorMessage("Por favor, Informe sua senha!");

            return false;
        }

        if (!passwordConfirm) {
            setPasswordConfirmError(true);
            setPasswordConfirmErrorMessage("Por favor, confirme sua senha!");

            return false;
        }

        if (passwordConfirm != password) {
            setPasswordConfirmError(true);
            setPasswordConfirmErrorMessage("Por favor, repita sua senha corretamente!");
            
            return false;
        }
        
        return true;
    }

    async function onSubmit () {
        if (!(inputsVerify())) return;

        setIsLoading(true);
        
        try {
            await api.put(`/users/${idUser}`, {password, companyId}, {
                headers: bypassAuthHeader,
            });
            setIsLoading(false);
            
            history.push("/auth/login");
        } catch(e) {
            console.log(e);
        }

        setIsLoading(false);
    }
    
    return (
        <div className="d-flex flex-column flex-root">
            {/*begin::Login*/}
            <div
                className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                id="kt_login"
            >
            {/*begin::Aside*/}
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-14-login.jpg")})`}}
            >
                {/*begin: Aside Container*/}
                <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                    <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl("/media/logos/header/logo-header-gold.png")}
                    />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                    <h3 className="font-size-h1 mb-5 text-white">
                    Bem-vindo!
                    </h3>
                    <p className="font-weight-lighter text-white">
                    Milliontech, o sistema que faltava para você ter mais LUCRO e LIBERDADE
                    </p>
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                    <div className="font-weight-bold	text-white">
                        {new Date().getFullYear()} &copy; Milliontech
                    </div>
                </div>
                {/* end:: Aside footer for desktop */}
                </div>
                {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                {/* begin::Content body */}
                <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                    <div className="login-form login-signin" style={{ display: "block" }}>
                        <div className="text-center mb-10 mb-lg-20">
                            <h3 className="font-size-h1">Criar senha</h3>
                            <p className="text-muted font-weight-bold">Digite a sua senha</p>
                        </div>

                        <div
                            id="kt_login_signin_form"
                            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        >
                            {/* begin: Alert */}
                            {/* {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                            )} */}
                            {/* end: Alert */}
                            {/* begin: Success */}
                            {/* {successMessage && (
                            <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
                                <div className="alert-text font-weight-bold">{successMessage}</div>
                            </div>
                            )} */}
                            {/* end: Alert */}

                            <div className="form-group">
                                <label>Seu email de acesso: (você poderá mudar isso no seu perfil)</label>
                                <input
                                    placeholder="Email"
                                    type="text"
                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                    name="email"
                                    value={userEmail}
                                    disabled
                                />
                            </div>

                            {/* begin: Password */}
                            <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Senha"
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${passwordError ? 'is-invalid' : ''}`}
                                name="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordError(e.target.value ? false : true);
                                }}
                            />
                            {passwordError ? (
                                <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{passwordErrorMessage}</div>
                                </div>
                            ) : null}
                            </div>
                            {/* end: Password */}

                            {/* begin: Confirm Password */}
                            <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Confirmar Senha"
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${passwordConfirmError ? 'is-invalid' : ''}`}
                                name="changepassword"
                                value={passwordConfirm}
                                onChange={(e) => {
                                    setPasswordConfirm(e.target.value);
                                    setPasswordConfirmError(e.target.value ? false : true);
                                }}
                            />
                            {passwordConfirmError ? (
                                <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {passwordConfirmErrorMessage}
                                </div>
                                </div>
                            ) : null}
                            </div>
                            {/* end: Confirm Password */}

                            <div className="form-group d-flex flex-wrap flex-center">
                                <button
                                    type="button"
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                    onClick={onSubmit}
                                >
                                    <span>Enviar</span>
                                    {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*end::Content body*/}

                {/* begin::Mobile footer */}
                <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                    <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                        &copy; {new Date().getFullYear()} Milliontech
                    </div>
                </div>
                {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
            </div>
            {/*end::Login*/}
        </div>
    )
}