import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { SuperInvoice } from '../pages/Invoice/SuperInvoice';
import { CompanyInvoice } from '../pages/Invoice/CompanyInvoice';

export function Invoice() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Faturas");
    
    return (
        <Switch>
            <Route path="/faturas/super" component={SuperInvoice}/>
            <Route path="/faturas" component={CompanyInvoice}/>
        </Switch>
    );
}