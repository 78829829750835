import { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../_core/MetronicLayout";

export function useHeaderLayoutProps() {
    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            headerClasses: uiService.getClasses("header", true),
            headerAttributes: uiService.getAttributes("header"),
            headerContainerClasses: uiService.getClasses("header_container", true),
            menuHeaderDisplay: objectPath.get(
              uiService.config,
              "header.menu.self.display"
            ),
          };
    }, [uiService]);

    return layoutProps;
}