import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { ChangeEvent, Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';

export type Field = {
    id: number,
    name: string,
    type: string,
    size: number,
    rows: number,
    columns: number,
    required: boolean | string,
    mask: string,
    option: any,
    decimal: number,
    integerDecimal: number,
    extensions: any,
    otherExtension: string,
}

type Props = {
    addField: (index: number) => void,
    saveEditField: (groupIndex: number, fieldIndex: number) => void,
    removeField: (indexGroup: number, idFieldOrTable: number) => void,
    groupIndex: number,
    showModalField: boolean,
    setShowModalField: React.Dispatch<React.SetStateAction<boolean>>,
    toEditField: boolean,
    idField: number,
    nameField: string,
    setNameField: React.Dispatch<React.SetStateAction<string>>,
    typeField: string,
    setTypeField: React.Dispatch<React.SetStateAction<string>>,
    sizeField: number,
    setSizeField: React.Dispatch<React.SetStateAction<number>>,
    rowsField: number,
    setRowsField: React.Dispatch<React.SetStateAction<number>>,
    colunsField: number,
    setColunsField: React.Dispatch<React.SetStateAction<number>>,
    requiredField: string,
    setRequiredField: React.Dispatch<React.SetStateAction<string>>,
    modalTitle: string,
    maskField: string,
    setMaskField: React.Dispatch<React.SetStateAction<string>>,
    optionsField: any,
    setOptionsField: React.Dispatch<React.SetStateAction<any>>,
    decimalField: number,
    setDecimalField: React.Dispatch<React.SetStateAction<any>>,
    integerDecimalField: number,
    setIntegerDecimalField: React.Dispatch<React.SetStateAction<any>>,
    extensionField: any,
    setExtensionField: React.Dispatch<React.SetStateAction<any>>,
    extensionList: any,
    handleDecimalFieldChange: (e: ChangeEvent<HTMLInputElement>) => void,
    isExtensionChecked: boolean,
    setIsExtensionChecked: React.Dispatch<React.SetStateAction<boolean>>,
    otherExtensionField: string,
    setOtherExtensionField: React.Dispatch<React.SetStateAction<string>>,
}

export function ModalField ({
    addField,
    saveEditField,
    removeField,
    groupIndex,
    showModalField,
    setShowModalField,
    toEditField,
    idField,
    nameField,
    setNameField,
    typeField,
    setTypeField,
    sizeField,
    setSizeField,
    rowsField,
    setRowsField,
    colunsField,
    setColunsField,
    requiredField,
    setRequiredField,
    modalTitle,
    maskField,
    setMaskField,
    optionsField,
    setOptionsField,
    decimalField,
    setDecimalField,
    integerDecimalField,
    setIntegerDecimalField,
    extensionField,
    setExtensionField,
    extensionList,
    handleDecimalFieldChange,
    isExtensionChecked,
    setIsExtensionChecked,
    otherExtensionField,
    setOtherExtensionField,
}: Props) {
    //const [options, setOptions] = useState<string[]>([]);
    //const [maskFormat, setMaskFormat] = useState<string>("");

    const handleAddOption = () => {
      setOptionsField([...optionsField, ""]);
    };

    const handleOptionChange = (index: number, value: string) => {
      const newOptions = [...optionsField];
      newOptions[index] = value;
      setOptionsField(newOptions);
    };

    const deleteOption = (index: number) => {
      const oldOptions = [...optionsField];
      oldOptions.splice(index, 1);
      setOptionsField(oldOptions);
    }

    const handleCheckOtherExtension = (e: any) => {
      setIsExtensionChecked(e.target.checked);

      if(!e.target.checked) {
        setOtherExtensionField("");
      }
    }
    
    return (
      <Modal
        size="lg"
        show={showModalField}
        onHide={() => setShowModalField(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <TextField
                type="string"
                label="Nome do Campo"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={nameField}
                onChange={(e) => setNameField(e.target.value)}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                select
                label="Tipo"
                size="small"
                margin="normal"
                variant="outlined"
                value={typeField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setTypeField(e.target.value)}
              >
                <MenuItem key="0" value="Texto">
                  Texto
                </MenuItem>

                <MenuItem key="1" value="Inteiro">
                  Número Inteiro
                </MenuItem>

                <MenuItem key="2" value="Decimal">
                  Número Decimal
                </MenuItem>

                <MenuItem key="3" value="OPCOES">
                  Opções
                </MenuItem>

                <MenuItem key="4" value="Mascara">
                  Máscara
                </MenuItem>

                <MenuItem key="5" value="Data">
                  Data
                </MenuItem>

                <MenuItem key="6" value="Anexo">
                  Anexo
                </MenuItem>

                <MenuItem key="7" value="Caixa_verificacao">
                  Caixa de Verificação
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid className={typeField === "Texto" ? "" : "d-none"} item lg={3}>
              <TextField
                type="number"
                label="Tamanho"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 }
                }}
                value={sizeField}
                onChange={(e) => setSizeField(Number(e.target.value) < 0 ? 1 : Number(e.target.value))}
              />
            </Grid>
              <Grid className={typeField === "Texto" ? "" : "d-none"} item lg={3}>
              <TextField
                type="number"
                label="Linhas"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 }
                }}
                value={rowsField}
                onChange={(e) => setRowsField(Number(e.target.value) < 0 ? 1 : Number(e.target.value))}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                type="number"
                label="Colunas"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1, max: 12 }
                }}
                value={colunsField}
                onChange={(e) => setColunsField(Number(e.target.value) < 0 ? 1 : Number(e.target.value))}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                select
                label="Obrigatório"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={requiredField}
                onChange={(e) => setRequiredField(e.target.value)}
              >
                <MenuItem key="0" value="y">
                  Sim
                </MenuItem>

                <MenuItem key="1" value="n">
                  Não
                </MenuItem>
              </TextField>
            </Grid>
            {typeField === "Decimal" && (
              <>
                <Grid item lg={3}>
                  <TextField
                      type="number"
                      label="Casas antes da vírgula"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1 }
                      }}
                      value={integerDecimalField}
                      onChange={(e) => setIntegerDecimalField(Number(e.target.value) < 0 ? 1 : Number(e.target.value))}
                    />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                      type="number"
                      label="Casas decimais"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1, max: 5 }
                      }}
                      value={decimalField}
                      onChange={handleDecimalFieldChange}
                    />
                </Grid>
              </>
            )}
              {typeField === "OPCOES" && (
                <>
                  {optionsField.map((option: string, index: number) => (
                    <Fragment key={index}>
                      <Grid item lg={11}>
                        <TextField
                          key={index}
                          type="string"
                          label={`Opção ${index + 1}`}
                          size="small"
                          margin="normal"
                          variant="outlined"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item lg={1}>
                        <Tooltip title="Remover">
                          <Button onClick={() => deleteOption(index)} className="mt-5" size="sm" variant="danger">
                            <i className="flaticon2-trash p-0"></i>
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item lg={12}>
                    <Button onClick={handleAddOption}>+ Adicionar Opção</Button>
                  </Grid>
                </>
              )}

              {typeField === "Mascara" && (
                <Grid item lg={12}>
                  <TextField
                    type="string"
                    label="Formato da Máscara"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    value={maskField}
                    onChange={(e) => setMaskField(e.target.value)}
                    placeholder="Exemplo: (11) 9XXXX-XXXX"
                  />
                  <Typography variant="body2" color="textSecondary">
                    Exemplos de uso para máscaras:
                    <br />
                    CPF: 000.000.000-00
                    <br />
                    CNPJ: 00.000.000/0000-00
                    <br />
                    Telefone: (00) 0000-0000
                    <br />
                    Celular: (00) 0 0000-0000
                  </Typography>
                </Grid>
              )}

              {typeField === "Anexo" && (
                <>
                  <Grid item lg={6}>
                    <TextField
                        label="Tipo(s) de arquivo"
                        size="small"
                        margin={"normal"}
                        variant={"outlined"}
                        select
                        style={{width: "100%"}}
                        SelectProps={{
                          multiple: true,
                          value: extensionField,
                          onChange: (e) => {setExtensionField(e.target.value)}
                        }}
                    >
                        {
                            extensionList.map((d: any, index: number) =>
                                <MenuItem key={index} value={d}>{d}</MenuItem>
                            )
                        }
                    </TextField>
                  </Grid>
                  <Grid item lg={3}>
                    <FormControlLabel control={<Checkbox checked={isExtensionChecked} onChange={handleCheckOtherExtension} color="primary" />} label="Outra extensão" />
                  </Grid>
                  <Grid className={isExtensionChecked ? "" : "d-none"} item lg={3}>
                    <TextField
                      type="string"
                      label="Extensão"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={otherExtensionField}
                      onChange={(e) => setOtherExtensionField(e.target.value)}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </Modal.Body>
        <Modal.Footer className={`flex flex-row align-items-center ${toEditField ? 'justify-content-between' : 'justify-content-end'}`}>
          {
            toEditField
            ?
            <Button
              variant="danger"
              onClick={() => removeField(groupIndex, idField)}
            >
              Deletar
            </Button>
            : <></>
          }
          <div className='flex flex-row align-items-center'>
            <Button
              variant="secondary"
              onClick={() => setShowModalField(!showModalField)}
              className='mr-3'
            >
              Fechar
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                toEditField
                  ? saveEditField(groupIndex, idField)
                  : addField(groupIndex)
              }
            >
              {toEditField ? "Salvar" : "Adicionar"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
}