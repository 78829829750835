import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { SubCategory } from "../../../types/Dre";
import api from "../../../services/Api";
import { Autocomplete } from "@material-ui/lab";
import ApiResourceSelect from "../../../components/ApiResourceSelect";
import DreCategoryService from "../../../services/DreCategoryService";
import { StockLocations } from "../../../types/StockLocations";
import StockLocationService from "../../../services/StockLocationService";

export type ConfigPdvProps = {
    dreSubCategoryId: number;
    setDreSubCategoryId: React.Dispatch<React.SetStateAction<number>>;
    stockLocationId: number;
    setStockLocationId: React.Dispatch<React.SetStateAction<number>>;
};

export function ConfigPdv({
    dreSubCategoryId,
    setDreSubCategoryId,
    stockLocationId,
    setStockLocationId,
}: ConfigPdvProps) {
    return (
        <div className="row">
            <div className="col-lg-6">
                <ApiResourceSelect
                    label="Plano de Contas para as vendas do PDV"
                    allowClear={false}
                    getOptionLabel={(option: SubCategory) => option.name}
                    value={dreSubCategoryId}
                    onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                    getSelectedOption={(loadedOptions) => {
                        if(!dreSubCategoryId) return null;
                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                    }}
                />
                <ApiResourceSelect
                    label="Local do estoque para as vendas do PDV"
                    allowClear={false}
                    getOptionLabel={(option: StockLocations) => option.name}
                    value={stockLocationId ?? ''}
                    onSelect={(option) => setStockLocationId(option?.id ?? 0)}
                    apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                    getSelectedOption={(loadedOptions) => {
                        if(!stockLocationId) return null;
                        return loadedOptions.find((option) => option.id === Number(stockLocationId)) ?? StockLocationService.getStockLocationById(stockLocationId)
                    }}
                />
            </div>
        </div>
    );
}