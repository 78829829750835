import { Customer } from "../hooks/customer";

export type RecurringExpenses = {
    id: number;
    name: string;
    payment: string;
    nextPaymentDate: string;
    docNumber: string;
    comments: string;
    amount: number;
    companyId: number;
    dreSubCategoryId: number;
    bankAccount: string;
    nameBank: string;
    categoryName: string;
    centerCost: number;
    frequency: frequency;
    expireDay: string;
    daysBeforeExpire: string;
    payedDate: string;
    occurrence: "unique";
    beneficiaryType: "supplier" | "seller";
    supplier: number;
    seller: number;
    sellerName?: string;
    supplierName?: string;
    idBillTransfer: number;
    totalPaid: number;
    taxValue: number;
    accValue: number;
    discountValue: number;
    customerEntity: Customer;
    sellerEntity: any;

    nextGenerateDate?: string
};

export enum frequency {
    MONTHLY = 'monthly',
    BIMONTHLY = 'bimonthly',
    TRIMONTHLY = 'trimonthly',
    QUARTERLY = 'quarterly',
    SEMIANNUAL = 'semiannual',
    ANNUAL = 'annual',
    OTHER = 'other', // Não está sendo usado
}