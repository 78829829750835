import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

export type HeadDataBaseProps = {
    reference: string;
    value: string;
    situation?: boolean;
};

export type BodyDataBaseProps = {
    id?: boolean;
    for: string;
    value: string | JSX.Element;
};

type Props = {
    headData: HeadDataBaseProps[],
    bodyData: BodyDataBaseProps[][],
}

export default function ListSimple({ headData, bodyData }: Props) {
    return (
        <Table>
            <TableHead >
                <TableRow>
                    {
                        headData.map((head, index) => (
                            <TableCell
                                key={index}
                                padding="default"
                            >
                                <strong>
                                    {head.value}
                                </strong>
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {bodyData.map((row, index) => (
                    <TableRow key={index}>
                        {
                            row.map((cell, i) => {

                                return (
                                    <TableCell key={i}>
                                        {cell.value}
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}