export const paymentOptions = [
    {id: "Banco", value: "Banco"},
    {id: "BNDES", value: "BNDES"},
    {id: "Boleto", value: "Boleto"},
    {id: "Cartão de Crédito", value: "Cartão de Crédito"},
    {id: "Cartão de Débito", value: "Cartão de Débito"},
    {id: "Carteira Digital", value: "Carteira Digital"},
    {id: "CH Descontado", value: "CH Descontado"},
    {id: "Cheque", value: "Cheque"},
    {id: "Crédito Loja", value: "Crédito Loja"},
    {id: "DDA", value: "DDA"},
    {id: "Débito Automático", value: "Débito Automático"},
    {id: "Depósito Bancário", value: "Depósito Bancário"},
    {id: "Depósito em C/C", value: "Depósito em C/C"},
    {id: "Depósito Identificado", value: "Depósito Identificado"},
    {id: "Dinheiro", value: "Dinheiro"},
    {id: "Doc", value: "Doc"},
    {id: "DP Descontada", value: "DP Descontada"},
    {id: "Duplicata Mercantil", value: "Duplicata Mercantil"},
    {id: "Faturado", value: "Faturado"},
    {id: "Faturar", value: "Faturar"},
    {id: "Lotérica", value: "Lotérica"},
    {id: "Outros", value: "Outros"},
    {id: "Pagamento online", value: "Pagamento online"},
    {id: "Permuta", value: "Permuta"},
    {id: "PIX", value: "PIX"},
    {id: "Programa de fidelidade, Cashback, Crédito Virtual", value: "Programa de fidelidade, Cashback, Crédito Virtual"},
    {id: "Ted", value: "Ted"},
    {id: "Transferência", value: "Transferência"},
    {id: "Transferência bancária, Carteira Digital", value: "Transferência bancária, Carteira Digital"},
    {id: "Vale Alimentação", value: "Vale Alimentação"},
    {id: "Vale Combustível", value: "Vale Combustível"},
    {id: "Vale Presente", value: "Vale Presente"},
    {id: "Vale Refeição", value: "Vale Refeição"},
];