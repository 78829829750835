import { ChartOfAccounts } from "../types/ChartOfAccounts";
import api from "./Api";

class ChartOfAccountsService {
    async getChartOfAccountsById(id: string | number) {
        const response = await api.get<ChartOfAccounts>(`/chartOfAccounts/${id || '0'}`);

        return response.data;
    }

    async getChartOfAccountsFiltered(companyId: string | number, filters: any) {
        const response = await api.get<ChartOfAccounts[]>(`/chartOfAccounts/all/${companyId}`, { params: { filters } });

        return response.data;
    }
}

export default new ChartOfAccountsService();