export enum whatsappStatus {
    SUCCESS = "success",
    FAILED = "failed",
}

export type WhatsappHistory = {
    number: string,
    created_at: string,
    message: string,
    status: whatsappStatus,
}