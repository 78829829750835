import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CompanySubscriptionPlanService from '../../services/CompanySubscriptionPlanService';
import { CompanySubscriptionPlanPaymentIsRequiredReason } from '../../types/CompanySubscriptionPlanPaymentIsRequiredReason';
import { useCompanyBranch } from '../../hooks/companyBranch';

export function AlertCompanyPaymentRequired() {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();

    const [reason, setReason] = useState<CompanySubscriptionPlanPaymentIsRequiredReason>('');

    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        async function loadData() {
            if (!user || !selectedCompany) {
                return;
            }

            const companyPaymentRequired = await CompanySubscriptionPlanService.planPaymentIsRequired(selectedCompany.id);

            const plan = companyPaymentRequired.data;

            if (companyPaymentRequired.isRequired === false) {
                window.location.href = '/';
            }

            setReason(companyPaymentRequired.reason);
            setDataLoaded(true);
        }

        loadData();
    }, [user, selectedCompany]);

    const requiresNewPlan = reason === 'noPlan' || reason === 'planWithoutApiSubscription' || reason === 'planExpired';

    return (
        <>
            <div className="card card-body pt-4">
                {reason === 'subscriptionPaymentRequired' && (
                    <>
                        <div className="col-lg-12 mt-3">
                            <div className="alert alert-danger">A empresa selecionada tem uma fatura com o pagamento atrasado, contate a empresa para que ela confirme o pagamento para então liberar acesso ao sistema!</div>
                        </div>
                    </>
                )}

                {dataLoaded && reason && reason !== 'subscriptionPaymentRequired' && (
                    <>
                        {requiresNewPlan && (
                            <div className="row">
                                <div className="col-lg-12 mt-3">
                                    <div className="alert alert-danger">A empresa selecionada ainda não assinou um plano ou o plano expirou, contate a empresa para que ela assine um plano para então liberar acesso ao sistema!</div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}