import { IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import React, { useState, useRef, useCallback } from 'react';
import { Badge, Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from '../../../services/Api';
import { formatCurrency } from '../../../utils/formatCurrency';
import { ReceivedNfe } from '../../../types/ReceivedNfe';
import { formatDate } from '../../../utils/dateFormat';
import { ReceivedNfeForm } from './ImportManifest-Entry';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { colorSuccess, textDanger, textSuccess } from '../../../utils/styles';

type ManifestNfeProps = {
    selectedCompany: any;
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>;
    setMsgError: React.Dispatch<React.SetStateAction<string>>;
    setShowModalSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setMsgSuccess: React.Dispatch<React.SetStateAction<string>>;
    setModalSuccessRedirectUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    receivedNfes: ReceivedNfeForm[];
    setReceivedNfes: React.Dispatch<React.SetStateAction<ReceivedNfeForm[]>>;
    maxVersion: number;
};

export function ManifestNfe({
    selectedCompany,
    setShowModalError,
    setMsgError,
    setShowModalSuccess,
    setMsgSuccess,
    setModalSuccessRedirectUrl,
    receivedNfes,
    setReceivedNfes,
    maxVersion,
}: ManifestNfeProps) {
    const [isSubmittingConfirmation, setIsSubmittingConfirmation] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { location: { pathname }, push: pushHistory } = useHistory();

    async function onSubmit() {
        setIsSubmittingConfirmation(true);

        if (!inputsVerify()) {
            setIsSubmittingConfirmation(false);
            setShowModalError(true);
            return;
        }

        try {
            const raw = {
                receivedNfes,
            };

            // Salvar ação de manifestação
            await api.post<ReceivedNfe[]>('nfeManifest', raw);

            // Atualizar numero maximo da versao da empresa
            await api.put(`companies/${selectedCompany.id}`, {
                nfeManifestMaxVersion: maxVersion,
            });

            setShowModalSuccess(true);
            setMsgSuccess('Ações confirmadas com sucesso!');
            setModalSuccessRedirectUrl('/entrada-de-mercadoria/importar-receita');
        } catch (error) {
            setMsgError('Ocorreu um erro ao confirmar as ações');
            setShowModalError(true);
        }

        setIsSubmittingConfirmation(false);
    }

    function inputsVerify(): boolean {
        setReceivedNfes((prevState) => prevState.map((nfe) => ({ ...nfe, selectedActionError: false })));

        const auxNfes = receivedNfes;

        // Validando ação selecionada de cada nfe
        let hasActionError = false;
        const mappedNfes = auxNfes.map(
            (nfe) => {
                if (!nfe.completed && !nfe.selectedAction) {
                    hasActionError = true;
                    return { ...nfe, selectedActionError: true };
                }

                return nfe;
            }
        )

        if (hasActionError) {
            setMsgError('Selecione uma ação para as notas recebidas');
            setReceivedNfes(mappedNfes);
            return false;
        }

        return true;
    }

    const handleChangeNfeAction = useCallback((nfeKey: string, selectedAction: string) => {
        setReceivedNfes((prevState) => prevState.map(
            (nfe) => {
                if (nfe.nfeKey === nfeKey) {
                    return {
                        ...nfe,
                        selectedAction: selectedAction || undefined,
                        selectedActionError: false,
                    };
                }

                return nfe;
            }
        ));
    }, [receivedNfes]);

    const handleChangePage = useCallback(async (next: number) => {
        setCurrentPage(next);
    }, []);

    const handleRowsPerPage = useCallback(async (value: number) => {
        setCurrentPage(0);
        setRowsPerPage(value);
    }, []);

    return (
        <>
            <div className="row mt-3">
                <div className="col bg-warning-o-30 p-5 mb-3">
                    <h3>
                        {receivedNfes.length > 0 && (<span>Foram encontradas ({receivedNfes.length}) Notas Fiscais pendentes</span>)}
                        {receivedNfes.length === 0 && (<span>Nenhuma Nota Fiscal pendente encontrada para o seu CNPJ</span>)}
                    </h3>

                    {receivedNfes.length > 0 && (
                        <p>Informe para a Receita se a nota foi emitida corretamente para sua empresa ou não, clicando na opção ações de cada nota fiscal.</p>
                    )}
                    {receivedNfes.length === 0 && (
                        <p>O sistema da Receita pode levar até 2 horas para atualizar os dados de recebimento de notas fiscais. Altere o NSU atual ou tente novamente mais tarde.</p>
                    )}
                </div>
            </div>
            {receivedNfes.length > 0 && (
                <>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-9 mt-3">
                            <Button
                                type='button'
                                variant="primary"
                                disabled={isSubmittingConfirmation}
                                onClick={onSubmit}
                            >
                                {
                                    isSubmittingConfirmation ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>
                                        </>
                                        :
                                        <span>Confirmar Ações</span>
                                }
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <TableContainer style={{ maxHeight: '75vh' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NSU</TableCell>
                                            <TableCell>Chave da NFe</TableCell>
                                            <TableCell>Data de Emissão</TableCell>
                                            <TableCell>Fornecedor</TableCell>
                                            <TableCell>Valor Total</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {receivedNfes.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((nfe, index: any) => (
                                            <TableRow key={index}>
                                                <TableCell>{nfe.version}</TableCell>
                                                <TableCell>
                                                    {nfe.nfeKey}
                                                    {nfe.existingEntryId && (
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip-top'>
                                                                    Entrada de Mercadoria N° {nfe.existingEntryId} está cadastrada com esta chave
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Badge pill variant="warning" className="ml-3 text-light">
                                                                <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                        </OverlayTrigger>
                                                    )}
                                                </TableCell>
                                                <TableCell>{formatDate(new Date(nfe.issuanceDate))}</TableCell>
                                                <TableCell>{nfe.supplierName}</TableCell>
                                                <TableCell>{formatCurrency(nfe.totalValue)}</TableCell>
                                                <TableCell>
                                                    <Badge
                                                        variant={nfe.status === 'autorizada' ? 'success' : 'danger'}
                                                        style={nfe.status === 'autorizada' ? colorSuccess : undefined}
                                                    >
                                                        {capitalizeFirstLetter(nfe.status)}
                                                    </Badge>
                                                </TableCell>
                                                <TableCell>
                                                    {!nfe.completed && (
                                                        <TextField
                                                            size="small"
                                                            id='outlined-select-currency'
                                                            select
                                                            label='Ação'
                                                            margin='normal'
                                                            variant='outlined'
                                                            onChange={(e) => handleChangeNfeAction(nfe.nfeKey, e.target.value)}
                                                            value={nfe.selectedAction ?? ''}
                                                            style={{ minWidth: '150px' }}
                                                            error={nfe.selectedActionError ?? false}
                                                        >
                                                            <MenuItem value="">
                                                                Selecione uma ação
                                                            </MenuItem>
                                                            <MenuItem style={{ color: 'green' }} value="confirm">
                                                                Confirmar
                                                            </MenuItem>
                                                            {nfe.status === 'autorizada' && (
                                                                <MenuItem style={{ color: 'red' }} value="reject">
                                                                    Recusar
                                                                </MenuItem>
                                                            )}
                                                        </TextField>
                                                    )}

                                                    {/* {nfe.completed && nfe.manifestSituation === 'confirmacao' && (
                                                        <TextField
                                                            size="small"
                                                            id='outlined-select-currency'
                                                            margin='normal'
                                                            variant='outlined'
                                                            value='Confirmada'
                                                            style={{ minWidth: '150px' }}
                                                            disabled
                                                        />
                                                    )} */}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                labelRowsPerPage="Linhas por página"
                                page={currentPage}
                                component="div"
                                count={receivedNfes.length}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[15, 25, 50]}
                                backIconButtonProps={{
                                    'aria-label': 'Página Anterior',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Próxima Página',
                                }}
                                onChangePage={(_, next) => handleChangePage(next)}
                                onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}