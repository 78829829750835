import { Collapse, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useCompany from '../../hooks/company';
import api from '../../services/Api';
import { BsVariant } from '../../types/BsVariant';
import { SubscriptionPlan } from '../../types/SubscriptionPlan';
import { getDate } from '../../utils/dateTimeHelper';
import { formatCurrency } from '../../utils/formatCurrency';
import { Search } from '../../components/Search';
import { __String } from 'typescript';
import { useSelector } from 'react-redux';
import CompanyService, { Filters } from '../../services/CompanyService';
import useBackendLoad from '../../hooks/backendReload';
import { Autocomplete } from '@material-ui/lab';
import ApiResourceSelect from '../../components/ApiResourceSelect';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "createdDate", value: "Data Cadastro" },
    { reference: "name", value: "Nome" },
    { reference: "phone", value: "Telefone" },
    { reference: "city", value: "Cidade" },
    { reference: "status", value: "Status", notSortable: true },
];

export function ListBranch() {    
    const { location: { pathname }, push: pushHistory } = useHistory();
    const { user } = useSelector((state: any) => state.auth);

    const [companies, setCompanies] = useState<any[]>([]);
    const [countTotalBranches, setCountTotalBranches] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [filterCompany, setFilterCompany] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { getStatusText, getStatusValue } = useCompany();
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [blockMsg, setBlockMsg] = useState("");
    const [companyToBlockId, setCompanyToBlockId] = useState("");

    const [showBlockModal, setShowBlockModal] = useState(false);

    const loadData = useCallback(async (dataParams: LoadDataParams) => {
        const { rows, count } = await CompanyService.getCompanyBranchesWithPagination(user?.companyId, dataParams, filtersRef.current, { withSubscriptionPlan: true });

        setCompanies(rows);
        setCountTotalBranches(count);
    }, [user]);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [companies]);

    useEffect(() => {
        (async () => {
            const list: BodyDataBaseProps[][] = [];
            const aux = companies;

            for (const item of aux) {
                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(item.id), id: true },
                    { for: "createdDate", value: getDate({ initialDate: new Date(item.createdDate) }).dateStr },
                    { for: "name", value: item.name },
                    { for: "phone", value: String(item.phone ?? '') },
                    { for: "city", value: String(item.city ?? '') },
                    { for: "status", value: String(getStatusText(item)) },
                    { for: "actionButton", value: getStatusValue(item) === 0 ? 'n' : 'y', hidden: true },
                    { for: "actionButtonAlt", value: getStatusValue(item) === 0 ? 'y' : 'n', hidden: true },
                ];

                list.push(data);
            }

            setBodyData(list);
        })();
    }, [companies]);

    const disableButtonCondition = useCallback((id: string) => {
        const row = companies.find(obj => String(obj.id) === id);
        return getStatusValue(row) !== 0;
    }, [companies]);
    const enableButtonCondition = useCallback((id: string) => {
        const row = companies.find(obj => String(obj.id) === id);
        return getStatusValue(row) === 0;
    }, [companies]);

    const handleClickDelete = useCallback(async (id: string) => {
        await api.delete(`companies/${id}`);
        const aux = companies.filter(company => String(company.id) !== id);

        setCompanies([...aux]);
    }, [companies]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickDisable = useCallback((id: string) => {
        setShowBlockModal(true);
        setCompanyToBlockId(id);
        setBlockMsg("");
    }, []);

    const handleClickDisableConfirm = useCallback(async () => {

        await api.put(`companies/${companyToBlockId}`, {
            status: 0,
            blockMessage: blockMsg,
            noApi: true,
        });

        setShowBlockModal(false);

        const aux = companies;
        const auxIndex = companies.findIndex(company => company.id === Number(companyToBlockId));

        aux[auxIndex].status = 0;
        aux[auxIndex].blockMessage = blockMsg;

        setCompanies([...aux]);
    }, [companyToBlockId, blockMsg, companies]);

    const handleClickEnableConfirm = useCallback(async (id: string) => {

        await api.put(`companies/${id}`, {
            status: 1,
            blockMessage: "",
        });

        const aux = companies;
        const auxIndex = companies.findIndex(company => company.id === Number(id));

        aux[auxIndex].status = 1;
        aux[auxIndex].blockMessage = "";

        setCompanies([...aux]);
    }, [companies]);

    const clearSearch = () => {
        setSearchQuery('');
        setFilterCompany('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = { searchQuery, company: filterCompany };

        reloadData();
    }, [companies, searchQuery, filterCompany]);

    return (
        <>
            <Modal
                show={showBlockModal}
                onHide={() => setShowBlockModal(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Desativar Empresa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Mensagem de bloqueio</h3>
                    <div className="form-group">
                        <TextField
                            id="outlined-select-currency"
                            size="small"
                            label="Mensagem"
                            margin="normal"
                            variant="outlined"
                            value={blockMsg}
                            onChange={e => setBlockMsg(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowBlockModal(!showBlockModal)}>Fechar</Button>
                    <Button variant="danger" onClick={handleClickDisableConfirm}>Desativar</Button>
                </Modal.Footer>
            </Modal>
            <div className="card card-body pt-4">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Adicionar Empresa
                            </Button>
                            : <></>
                        }
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={user.isSuper === 'y' ? setAdvancedSearch : undefined}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                {user.isSuper === 'y' && (
                    <Collapse in={advancedSearch}>
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6">
                                <ApiResourceSelect
                                    label="Empresa"
                                    getOptionLabel={(option: any) => option.name}
                                    value={filterCompany}
                                    onSelect={(option) => setFilterCompany(String(option?.id ?? ''))}
                                    apiSearchHandler={(typedText) => CompanyService.getCompaniesFiltered({ filters: { name: typedText } })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!filterCompany) return null;
                                        return loadedOptions.find((option) => option.id === Number(filterCompany)) ?? CompanyService.getCompanyById(filterCompany)
                                    }}
                                />
                            </div>

                            <div className="col-12 d-flex justify-content-end">
                                <Button
                                    onClick={handleClickSearch}
                                    className="mr-3"
                                >
                                    Pesquisar
                                </Button>

                                <Button
                                    onClick={clearSearch}
                                >
                                    Limpar
                                </Button>
                            </div>
                        </div>
                    </Collapse>
                )}

                <div className="mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                        customButtons={
                            user.isAccountant == "n"
                            ?
                            [
                                {
                                    class: 'btn-light-danger',
                                    content: 'OFF',
                                    variant: BsVariant.INFO,
                                    popup: "Desativar empresa",
                                    onClick: handleClickDisable,
                                    showCondition: disableButtonCondition,
                                },
                                {
                                    class: 'btn-light-success',
                                    content: 'ON',
                                    variant: BsVariant.INFO,
                                    popup: "Ativar empresa",
                                    onClick: handleClickEnableConfirm,
                                    showCondition: enableButtonCondition,
                                },
                            ]
                            : []
                        }
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalBranches}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
        </>
    );
}