import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format, isAfter } from 'date-fns';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { orderByIdDesc } from '../../utils/orderTable';
import api from "../../services/Api";
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import {
    ObjectOrder,
    OrdersSituation,
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    OnThrowToBillsData,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { Search } from '../../components/Search';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import '../../style.css';
import { SubCategory } from '../../types/Dre';
import useBackendLoad from '../../hooks/backendReload';
import { BsVariant } from '../../types/BsVariant';
import ModalConfirm from '../../components/ModalConfirm';
import EntryService from '../../services/EntryService';
import { freightModality, freightModalityKeyById } from '../../utils/freightModality';
import { YesOrNoBadge } from '../../components/YesOrNoBadge';
import CustomerService from '../../services/CustomerService';
import { Customer } from '../../types/Customer';
import { useLinkedFieldsError } from '../../hooks/linkedFieldsError';
import PurchaseOrderService from '../../services/PurchaseOrderService';
import ModalLinkedFieldsError from '../../components/ModalLinkedFieldsError';
import { useSelector } from 'react-redux';

type Filters = {
    searchQuery: string;
    status: string;
    initialDate: string;
    finalDate: string;
    minValue: number;
    maxValue: number;
}

type PurchaseOrder = {
    id: number;
    status: string;
    supplierId: number;
    supplierName: string;
    seller: string | null;
    providerName: string;
    orderDate: string;
    products: string;
    situation: string;
    ipiPrice: number;
    freightPrice: number;
    discountMoney: number;
    discountPercentage: number;
    totalWeight: number;
    modalityFreight: number;
    carrier: string;
    note: string;
    insideNote: string;
    totalPrice: number;
    numberOrder: string;
    installments: string;
    movedToStock: string;
    generatedEntryId: number;
    reference: string;

    customerEntity: Customer;
};


const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: "Nº" },
    { reference: 'date', value: "Data" },
    { reference: 'client', value: "Fornecedor" },
    { reference: 'reference', value: "Referência" },
    { reference: 'amount', value: "Valor Total" },
    { reference: 'situation', value: "Situação", situation: true, notSortable: true },
    { reference: 'generatedEntryId', value: "Entrada Emitida", notSortable: true },
];


export function ListPurchaseOrder() {
    const { user } = useSelector((state: any) => state.auth);

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [situationData, setSituationData] = useState<ObjectOrder[]>([]);
    const [countTotalPurchaseOrders, setCountTotalPurchaseOrders] = useState(0);

    const [showModalConfirmGenerateEntry, setShowModalConfirmGenerateEntry] = useState(false);
    const [purchaseOrderToGenerateEntry, setPurchaseOrderToGenerateEntry] = useState<PurchaseOrder>();

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [status, setStatus] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const [linkedFieldsErrorMessage, setLinkedFieldsErrorMessage] = useState('');
    const [linkedFieldsRedirectUrl, setLinkedFieldsRedirectUrl] = useState('');
    const {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    } = useLinkedFieldsError();

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [purchaseOrder]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: PurchaseOrder[], count: number }>("purchase-order", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        const initialSituationData = rows.map(({ id, installments, situation: situationResponse }) => {
            const situation: OrdersSituation[] = JSON.parse(situationResponse);

            return {
                id,
                situation,
                installments,
            };
        });

        for (const request of rows) {
            request.supplierName = CustomerService.getCustomerName(request.customerEntity, request.supplierName);
        }

        setSituationData(initialSituationData);
        setPurchaseOrder(rows);
        setCountTotalPurchaseOrders(count);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = purchaseOrder;

        aux.forEach((order) => {
            const amount = formatCurrency(order.totalPrice);
            const status = order.status === "open" ? "Em aberto"
                : order.status === "progress" ? "Em andamento"
                    : order.status === "attended" ? "Atendido"
                        : order.status === "canceled" ? "Cancelado"
                            : "";

            // Formatando data
            const [year, month, day] = order.orderDate.split("-");
            const date = new Date(Number(year), Number(month) - 1, Number(day));
            const formatedDate = format(date, "dd/MM/yyyy");

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(order.id), id: true },
                { for: "date", value: formatedDate },
                { for: "client", value: order.supplierName },
                { for: "reference", value: order.reference ?? '' },
                { for: "amount", value: amount },
                { for: "situation", value: status },
                {
                    for: "generatedEntryId",
                    value: order.generatedEntryId ? String(order.generatedEntryId) : '',
                    jsx: (
                        <>
                            <YesOrNoBadge value={!!order.generatedEntryId} />
                            {!!order.generatedEntryId && <>
                                &nbsp; / <Link to={`entrada-de-mercadoria/${order.generatedEntryId}`}> #{order.generatedEntryId}</Link>
                            </>}
                        </>
                    )
                },
                { for: "billCreated", value: status === 'Atendido' ? 'y' : 'n', hidden: true },
                { for: "movedToStock", value: order.movedToStock, hidden: true },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [purchaseOrder]);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, status, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue),
            initialDate, finalDate,
        };

        reloadData();
    }, [purchaseOrder, searchQuery, status, initialDate, finalDate, minValue, maxValue]);

    const clearSearch = () => {
        setSearchQuery('');
        setStatus('');
        setInitialDate('');
        setFinalDate('');
        setMinValue('');
        setMaxValue('');
    }

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = purchaseOrder.filter((order) => order.id !== Number(id));

            await api.delete(`purchase-order/${id}`);

            setPurchaseOrder([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [purchaseOrder]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickGenerateEntry = useCallback(async (id: string) => {
        const canDoAction = handleBeforeAction(Number(id));
        if(!canDoAction) {
            return;
        }

        const foundOrder = purchaseOrder.find(order => order.id === Number(id));
        if (!foundOrder) return;

        setPurchaseOrderToGenerateEntry(foundOrder);
        setShowModalConfirmGenerateEntry(true);
    }, [purchaseOrder]);

    const generateEntryButtonShowCondition = useCallback((id: string) => {
        const foundOrder = purchaseOrder.find(order => order.id === Number(id));
        if (!foundOrder) return false;

        return !foundOrder.generatedEntryId;
    }, [purchaseOrder]);

    const handleConfirmGenerateEntry = useCallback(async () => {
        if (!purchaseOrderToGenerateEntry) {
            setShowModalConfirmGenerateEntry(false);
            return;
        }

        const createdEntry = await EntryService.createEntry({
            purchaseOrderId: purchaseOrderToGenerateEntry.id,
            supplierId: Number(purchaseOrderToGenerateEntry.supplierId),
            sellerId: Number(purchaseOrderToGenerateEntry.seller),
            products: purchaseOrderToGenerateEntry.products,
            installments: purchaseOrderToGenerateEntry.installments,
            ipiPrice: purchaseOrderToGenerateEntry.ipiPrice,
            freight: purchaseOrderToGenerateEntry.freightPrice,
            discount: purchaseOrderToGenerateEntry.discountMoney,
            discountPercentage: purchaseOrderToGenerateEntry.discountPercentage,
            totalWeight: purchaseOrderToGenerateEntry.totalWeight,
            total: purchaseOrderToGenerateEntry.totalPrice,
            typeFreigth: freightModalityKeyById(purchaseOrderToGenerateEntry.modalityFreight),
            carrier: purchaseOrderToGenerateEntry.carrier,
            entryDate: purchaseOrderToGenerateEntry.orderDate,
            issuanceDate: purchaseOrderToGenerateEntry.orderDate,
            internalComments: purchaseOrderToGenerateEntry.insideNote,
            comments: purchaseOrderToGenerateEntry.note,
            commentsSituation: `Entrada emitida a partir da ordem de compra n° ${purchaseOrderToGenerateEntry.id}`
        });

        const orderRaw = {
            generatedEntryId: createdEntry.id,
        };

        await api.put(`purchase-order/${purchaseOrderToGenerateEntry.id}`, orderRaw);

        setPurchaseOrder((prevState) => prevState.map((order) => {
            if (order.id === purchaseOrderToGenerateEntry.id) {
                return { ...order, generatedEntryId: createdEntry.id };
            }
            return order;
        }));

        setShowModalConfirmGenerateEntry(false)
        setPurchaseOrderToGenerateEntry(undefined);
        // reloadData();
    }, [purchaseOrderToGenerateEntry]);

    const handleChangeOrder = useCallback(async (id: number, situation: OrdersSituation) => {
        try {
            const aux = purchaseOrder;
            const aux2 = situationData;
            const order = aux.find((order) => order.id === id);
            const sitData = aux2.find((situ) => situ.id === id);

            if (!order || !sitData) {
                throw new Error();
            }

            const hasSituation = sitData.situation;

            if (!hasSituation) {
                throw new Error();
            }

            hasSituation.push(situation);

            const raw = {
                ...order,
                status: situation.statusSituation,
                situation: JSON.stringify(sitData.situation),
            };

            order.status = situation.statusSituation;
            order.situation = JSON.stringify(sitData.situation);

            await api.put(`purchase-order/${id}`, raw);

            setSituationData(aux2);
            setPurchaseOrder([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [purchaseOrder, situationData]);

    const handleThrowToBillsToPay = useCallback(async ({
        id,
        revenue,
        subCategoryId,
        centerCost,
        installments,
    }: OnThrowToBillsData) => {
        try {
            const aux = purchaseOrder;
            const auxSituation = situationData;

            const soIndex = aux.findIndex((po) => po.id === id);
            const situationIndex = auxSituation.findIndex((situ) => situ.id === id);

            if (soIndex < 0 || situationIndex < 0) {
                throw new Error();
            }

            installments.forEach(async ({
                date,
                value,
                isPaid,
                payment,
                comments,
                bankAccount,
                paymentDate,
            }) => {
                const [day, month, year] = date.split("/");
                const formated = new Date(`${year}-${month}-${day} 23:59:59`);
                const late = isAfter(Date.now(), formated);

                const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);

                const raw = {
                    payment,
                    comments,
                    centerCost: centerCost ? Number(centerCost) : null,
                    bankAccount: bankAccount ? bankAccount : null,
                    nameBank: bankAccount ? bank.data.nameBank : null,
                    amount: value,
                    dueDate: year + "-" + month + "-" + day,
                    payedDate: isPaid ? paymentDate : null,
                    totalPaid: isPaid ? value : null,
                    remaining: value,
                    recordType: "account",
                    categoryName: revenue,
                    dreSubCategoryId: subCategoryId,
                    occurrence: installments.length > 1 ? "parcelada" : "unica",
                    supplier: aux[soIndex].supplierId,
                    docNumber: aux[soIndex].numberOrder,
                    issuanceDate: aux[soIndex].orderDate,
                    name: `Ordem de compra ${aux[soIndex].id}`,
                    status: isPaid ? 'paid' : late ? 'late' : 'pending',
                };

                await api.post("billsToPay", raw);
            });

            const situation: OrdersSituation = {
                statusSituation: "attended",
                commentsSituation: "Lançado em contas",
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
            };

            const hasSituation = auxSituation[situationIndex].situation;

            if (!hasSituation) {
                throw new Error();
            }

            hasSituation.push(situation);

            const updatedSituation = auxSituation[situationIndex].situation;

            aux[soIndex].situation = JSON.stringify(updatedSituation);
            aux[soIndex].status = "attended";


            await api.put(`purchase-order/${id}`, aux[soIndex]);

            setSituationData([...auxSituation]);
            setPurchaseOrder([...aux]);
        } catch (error) {
            console.log(error);
        }
    }, [purchaseOrder, situationData]);

    const updateStockMoved = useCallback((id: string, movedToStock: string) => {
        const aux = purchaseOrder;
        const auxIndex = aux.findIndex((obj) => obj.id === Number(id));

        aux[auxIndex].movedToStock = movedToStock;

        setPurchaseOrder([...aux]);
    }, [purchaseOrder, situationData]);

    const handleBeforeOpenSendEmailOrWhatsappModal = useCallback((id: number, type: 'email' | 'whatsapp') => {
        const foundPurchase = purchaseOrder.find((purchaseObj) => purchaseObj.id === Number(id));

        if(!foundPurchase) {
            return false;
        }

        const linkErrors = PurchaseOrderService.verifySupplierLink(foundPurchase);

        if (linkErrors.length > 0) {
            setLinkedFieldsErrors(linkErrors);
            setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
            setLinkedFieldsErrorMessage(`Para enviar ${type === 'email' ? 'email' : 'whatsapp'} é necessário vincular um cliente cadastrado ao Pedido!`);
            setShowModalLinkedFieldsError(true);
            return false;
        }

        return true;
    }, [purchaseOrder]);

    const handleBeforeAction = useCallback((id: number) => {
        const foundPurchase = purchaseOrder.find((purchaseObj) => purchaseObj.id === Number(id));

        if(!foundPurchase) {
            return false;
        }

        const linkErrors = PurchaseOrderService.verifyLinkedFields(foundPurchase);

        if (linkErrors.length > 0) {
            setLinkedFieldsErrors(linkErrors);
            setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
            setLinkedFieldsErrorMessage(`Para realizar esta ação é necessário completar o cadastro do Pedido!`);
            setShowModalLinkedFieldsError(true);
            return false;
        }

        return true;
    }, [purchaseOrder]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <ModalLinkedFieldsError
                message={linkedFieldsErrorMessage}
                errors={linkedFieldsErrors}
                showModal={showModalLinkedFieldsError}
                setShowModal={setShowModalLinkedFieldsError}
                redirect={linkedFieldsRedirectUrl}
            />
            <ModalConfirm
                confirmText="Deseja emitr a entrada de mercadoria a partir desta ordem de compra?"
                confirmButtonLabel="Emitir"
                showModal={showModalConfirmGenerateEntry}
                setShowModal={setShowModalConfirmGenerateEntry}
                onConfirm={handleConfirmGenerateEntry}
            />
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Adicionar ordem de compra
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={status}
                        >
                            <MenuItem key="0" value="" onClick={() => setStatus('')}>
                                Todos
                            </MenuItem>

                            <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                                Em aberto
                            </MenuItem>

                            {/* <MenuItem key="2" value="inactive" onClick={() => setStatus('inactive')}>
                                Em Andamento
                            </MenuItem> */}

                            <MenuItem key="2" value="attended" onClick={() => setStatus('attended')}>
                                Atendido
                            </MenuItem>

                            {/* <MenuItem key="2" value="inactive" onClick={() => setStatus('inactive')}>
                                Cancelado
                            </MenuItem>

                            <MenuItem key="2" value="inactive" onClick={() => setStatus('inactive')}>
                                Agrupado
                            </MenuItem> */}
                        </TextField>
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Data inicial"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={initialDate}
                            onChange={(e) => setInitialDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            size="small"
                            type="date"
                            label="Data final"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={finalDate}
                            onChange={(e) => setFinalDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            className="inputSmaller"
                            margin="normal"
                            customInput={TextField}
                            variant="outlined"
                            withPrefix={false}
                            label="Valor mínimo"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                            value={minValue}
                            onChange={(evt) => setMinValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            className="inputSmaller"
                            margin="normal"
                            customInput={TextField}
                            variant="outlined"
                            withPrefix={false}
                            label="Valor máximo"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                            value={maxValue}
                            onChange={(evt) => setMaxValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    order
                    typeOrder="purchase"
                    headData={headData}
                    bodyData={bodyData}
                    onEdit={handleClickEdit}
                    objectOrder={situationData}
                    onClone={handleClickClone}
                    onDelete={handleClickDelete}
                    onChangeOrder={handleChangeOrder}
                    onThrowToBills={handleThrowToBillsToPay}
                    onStockMoved={updateStockMoved}
                    onBeforeOpenSendEmailModal={(id) => handleBeforeOpenSendEmailOrWhatsappModal(id, 'email')}
                    onBeforeOpenSendWhatsappModal={(id) => handleBeforeOpenSendEmailOrWhatsappModal(id, 'whatsapp')}
                    onBeforeAction={handleBeforeAction}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalPurchaseOrders}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    customButtons={
                        user.isAccountant == "n"
                        ?
                        [
                            {
                                class: 'btn-light-primary',
                                content: (<i className='p-0 flaticon2-box-1'></i>),
                                variant: BsVariant.SECONDARY,
                                popup: "Emitir Entrada",
                                onClick: handleClickGenerateEntry,
                                showCondition: generateEntryButtonShowCondition,
                            },
                        ]
                        : []
                    }
                />
            </div>
        </div>
    );
}