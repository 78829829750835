import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { format, isBefore } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner, Tabs, Tab, Modal } from 'react-bootstrap';
import { MenuItem, TextField } from '@material-ui/core';

import api from '../../services/Api';
import { InstallmentDetails, Product, useBudget } from './context';
import { Installment } from './Installment';
import { ListProducts } from './ListProducts';
import { NumericFormat } from '../../components/NumericFormat';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import '../../style.css';
import LogService from '../../services/LogService';
import CustomerSelect from '../../components/CustomerSelect';
import BudgetService from '../../services/BudgetService';
import ModalLinkedFieldsError from '../../components/ModalLinkedFieldsError';
import { useLinkedFieldsError } from '../../hooks/linkedFieldsError';
import { Budget } from '../../types/Budget';
import PriceListService from '../../services/PriceListService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import SellerService from '../../services/SellerService';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { PriceList } from '../../types/PriceList';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import useNewProductForm from '../../hooks/newProductForm';
import { NewProductModal } from '../../components/Product/NewProductModal';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import CustomerService from '../../services/CustomerService';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import useSellerUser from '../../hooks/sellerUser';
import { yesOrNo } from '../../types/yesOrNo';
import SellerConsignmentService from '../../services/SellerConsignmentService';
import { SellerConsignmentStockLocationSelector } from '../../components/SellerConsignmentStockLocationSelector';
import { uploadMultipleFiles } from '../../utils/FilesHelper';
import UploadFiles from '../../components/UploadFiles';

export function NewBudgets() {
    const [isSubmit, setIsSubmit] = useState(false);

    const [deadLine, setDeadLine] = useState("");
    const [validity, setValidity] = useState("");
    const [budgetDate, setbudgetDate] = useState("");

    const [priceList, setPriceList] = useState<PriceList>({} as PriceList);
    const [activeTab, setActiveTab] = useState("details");

    const [note, setNote] = useState("");
    const [reference, setReference] = useState("");
    const [insideNote, setInsideNote] = useState("");
    const [grossWeight, setGrossWeight] = useState("0");
    const [liquidWeight, setLiquidWeight] = useState("0");
    const [carrier, setCarrier] = useState("");
    const [customerId, setCustomerId] = useState<number>();
    const [customerName, setCustomerName] = useState("");
    const [seller, setSeller] = useState("");
    const [freigth, setFreigth] = useState("0");
    const [totalValue, setTotalValue] = useState("0");
    const [budgetNumber, setbudgetNumber] = useState("");
    const [discountMoney, setDiscountMoney] = useState("0");
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [installmentAmount, setInstallmentAmount] = useState(0);
    const [modalityFreight, setModalityFreight] = useState("recipient");
    const [totalValueProducts, setTotalValueProducts] = useState("0");
    const [saveEmit, setSaveEmit] = useState(false);
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");

    const [customerHasError, setCustomerHasError] = useState(false);

    const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();
    const [sellerConsignmentStockLocationId, setSellerConsignmentStockLocationId] = useState(0);
    const [budgetDataLoaded, setBudgetDataLoaded] = useState(false);

    const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
    const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

    const {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    } = useLinkedFieldsError();

    const { idToClone } = useParams<{ idToClone: string }>();
    const history = useHistory();
    const { userSellerInfo, productsAvailableForConsignment, filterProductsByStockLocationId } = useSellerUser();
    const selectedSellerIsAuthConsignmentSeller = !!userSellerInfo && userSellerInfo.isConsignment === yesOrNo.YES && userSellerInfo.id === Number(seller);
    const { handleSubmit } = useForm();
    const {
        classes,
        products,
        installments,
        dispatchProducts,
        dispatchInstallments,
    } = useBudget();

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
        if (customerId) {
            const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
            setCustomerLimitCreditInformation(limitCreditInfo);
        } else {
            setCustomerLimitCreditInformation(undefined);
        }
    }, []);

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomerId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        if (userSellerInfo) {
            setSeller(String(userSellerInfo.id));
        }
    }, [userSellerInfo]);

    useEffect(() => {
        function handleConsignmentSeller() {
            if (!userSellerInfo || userSellerInfo.isConsignment === yesOrNo.NO) {
                return;
            }

            filterProductsByStockLocationId(sellerConsignmentStockLocationId);

            if (!budgetDataLoaded) {
                return;
            }

            dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
            dispatchProducts({ type: "ADD" });
            dispatchInstallments({
                type: "GENERATE",
                payload: {
                    amount: 0,
                    value: 0,
                }
            });
        }

        handleConsignmentSeller();
    }, [userSellerInfo, seller, sellerConsignmentStockLocationId, filterProductsByStockLocationId]);

    useEffect(() => {
        dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchProducts({ type: "ADD" });
        dispatchInstallments({
            type: "GENERATE",
            payload: {
                amount: 0,
                value: 0,
            }
        });
        setbudgetDate(format(Date.now(), "yyyy-MM-dd"));

        const date = new Date();
        const formated = format(date, "yyyyMMddHHmmss")

        setbudgetNumber(formated);
    }, []);

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        if (priceList.priceListType === "1") {
            totalProduct = Number(totalProduct) + Number(priceList.value);
        }

        if (priceList.priceListType === "2") {
            totalProduct = Number(totalProduct) - Number(priceList.value);
        }

        const discountNumber = discountMoney ? formatToFloat(discountMoney) : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freigth ? formatToFloat(freigth) : 0;

        const partial = Number(totalProduct) + Number(freigthNumber);

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setTotalValueProducts(formatCurrency(totalProduct));

            setFreigth("0");
            setTotalValue("0");
            setDiscountMoney("0");

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
            total = total - (total * (discountPercentageFloat / 100));
        }


        setTotalValueProducts(formatCurrency(totalProduct));
        setTotalValue(formatCurrency(total));
    }, [products, discountMoney, discountPercentage, freigth, priceList]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    useEffect(() => {
        if (!idToClone) return;

        async function getBudgetToCloneData() {
            const { data: budgetToClone } = await api.get<Budget>(`budgets/${idToClone}`);

            if (!budgetToClone) return;

            const dataProducts: Product[] = JSON.parse(budgetToClone.products);
            const dataInstallments: InstallmentDetails[] = JSON.parse(budgetToClone.installments);

            const dataAmountInstallments = dataInstallments.length;

            const mappedProducts = dataProducts.map(prod => {
                return {
                    ...prod,
                    isLinked: prod.isLinked ?? !!prod.INSTANCE.id,
                    name: prod.name ?? prod.INSTANCE.name
                };
            });

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: mappedProducts,
                },
            });

            // dispatchInstallments({
            //     type: "INITIAL",
            //     payload: {
            //         installments: dataInstallments,
            //     }
            // });

            const dataPriceList = await PriceListService.getPriceListById(budgetToClone.priceList);

            if (dataPriceList) {
                setPriceList(dataPriceList);
            }

            setNote(budgetToClone.note);
            setSeller(String(budgetToClone.seller));
            setCarrier(budgetToClone.carrier);
            setCustomerId(budgetToClone.customerId ?? undefined);
            setCustomerName(budgetToClone.customerName ?? '');
            // setDeadLine(budgetToClone.deadLine);
            setReference(budgetToClone.reference);
            setInsideNote(budgetToClone.insideNote);
            setInstallmentAmount(dataAmountInstallments);
            setModalityFreight(budgetToClone.modalityFreight);
            setSellerConsignmentStockLocationId(budgetToClone.sellerConsignmentStockLocationId || 0);

            setFreigth(formatCurrency(budgetToClone.freigth));
            setTotalValue(formatCurrency(budgetToClone.totalValue));

            setDiscountMoney(formatCurrency(budgetToClone.discountMoney));
            setDiscountPercentage(budgetToClone.discountPercentage);
            setGrossWeight(budgetToClone.grossWeight);
            setLiquidWeight(budgetToClone.liquidWeight);

            setBudgetDataLoaded(true);
            handleCustomerChanged(budgetToClone.customerId ?? undefined, budgetToClone.customerName);
        }

        getBudgetToCloneData();
    }, [idToClone]);

    const handleSetCarrier = useCallback((value: string | null) => {
        if (value) {
            return setCarrier(value);
        }

        return setCarrier("");
    }, []);

    const handleSetDeadLine = useCallback((value: string) => {
        const [year, month, day] = value.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const dateNow = new Date();

        //input
        let finalDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay());

        //data atual
        let finalDateNow = new Date(dateNow.getFullYear() + '-' + dateNow.getMonth() + '-' + dateNow.getDay());


        if (isBefore(finalDate, finalDateNow)) {
            alert("Não é possível adicionar uma data que já passou!");
            return setDeadLine("");
        }

        setDeadLine(value);
    }, []);

    const handleSetValidity = useCallback((value: string) => {
        const [year, month, day] = value.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const dateNow = new Date();

        //input
        let finalDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay());

        //data atual
        let finalDateNow = new Date(dateNow.getFullYear() + '-' + dateNow.getMonth() + '-' + dateNow.getDay());


        if (isBefore(finalDate, finalDateNow)) {
            alert("Não é possível adicionar uma data que já passou!");
            return setValidity("");
        }

        setValidity(value);
    }, []);

    const handleSetPriceList = useCallback((value: PriceList | null) => {
        if (value) {
            return setPriceList(value);
        }

        return setPriceList({} as PriceList);
    }, []);

    function hasInstallments() {
        var isSubmit = true;

        if (!installments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas do pedido!");
            setShowModalAttention(true);
            isSubmit = false;
        } else {
            installments.map((installment: any, index: number) => {
                if (!installment.value) {
                    setActiveTab("payment");
                    setMsgError("Informe o valor das parcelas do pedido!");
                    setShowModalAttention(true);
                    isSubmit = false;
                }
            });
        }

        if (!isSubmit) return isSubmit;

        const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

        if (sumInstallments !== formatToFloat(totalValue)) {
            setMsgError("A soma das parcelas deve concidir com o valor total do pedido!");
            isSubmit = false;
            setActiveTab('payment');
        }

        return isSubmit;
    }

    function inputsVerify() {
        setCustomerHasError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        if (!customerId && !customerName) {
            setMsgError("Selecione o cliente");
            setCustomerHasError(true);
            return false;
        }

        if (saveEmit && customerId && customerLimitCreditInformation) {
            const totalRequest = formatToFloat(totalValue ?? 0);

            if ((totalRequest + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
                // Se emitir o orçamento ultrapassar o limite de crédito do cliente
                setMsgError("Não é possível emitir o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
                setCustomerHasError(true);
                return false;
            }
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setMsgError("Selecione pelo menos 1(um) Produto!");
            setActiveTab('products');
            return false;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (selectedSellerIsAuthConsignmentSeller && productsAvailableForConsignment) {
            for (const product of products) {
                const valid = SellerConsignmentService.validateConsignmentProductQuantity(product.INSTANCE, product.amount, sellerConsignmentStockLocationId, productsAvailableForConsignment);
                if (!valid) {
                    setActiveTab('products');
                    setMsgError(`O produto ${product.name} não tem estoque em consignação o suficiente para realizar a venda!`);
                    setShowModalAttention(true);
                    return false;
                }
            }
        }

        if (!hasInstallments()) {
            return false;
        }

        return true;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const onSubmit = async (data: any) => {
        setIsSubmit(true);

        if (!inputsVerify()) {
            setShowModalAttention(true);
            setSaveEmit(false);
            setIsSubmit(false);
            return;
        }


        let filesPath: any  = [];
        if (filesSelected) {
            filesPath = await uploadMultipleFiles(filesSelected, setShowModalAttention, setMsgError, setIsSubmit);                
            if (filesPath === false) {
                return;
            }
        }
        
        // Situação que aparece na listagem
        const situation: OrdersSituation[] = [];

        situation.push({
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
            commentsSituation: saveEmit ? "Criação e emissão do pedido" : "Criação do pedido",
            statusSituation: saveEmit ? "launched" : "open",
        });

        try {
            const raw = {
                carrier,
                grossWeight: grossWeight,
                liquidWeight: liquidWeight,
                customerId: customerId,
                customerName: !customerId ? customerName : null,
                seller: Number(seller),
                validity,
                deadLine: deadLine,
                reference: reference,
                note: note,
                insideNote: insideNote,
                budgetDate,
                budgetNumber,
                modalityFreight,
                freigth: formatToFloat(freigth),
                totalValue: formatToFloat(totalValue),
                discountMoney: formatToFloat(discountMoney),
                discountPercentage: formatToFloat(discountPercentage),
                // priceList: priceList.name,
                
                status: saveEmit ? "launched" : "open",
                sellerConsignmentStockLocationId: sellerConsignmentStockLocationId || null,
                products: JSON.stringify(products),
                situation: JSON.stringify(situation),
                installments: JSON.stringify(installments),
                attachments: JSON.stringify(filesPath)
            };

            if (saveEmit) {
                const linkErrors = BudgetService.verifyLinkedFields(raw);

                if (linkErrors.length > 0) {
                    setLinkedFieldsErrors(linkErrors);
                    setShowModalLinkedFieldsError(true);
                    setSaveEmit(false);
                    setIsSubmit(false);
                    return;
                }
            }

            const res = await api.post("budgets", raw);

            if (saveEmit) {
                const raw = {
                    ...data,
                    customerId: customerId,
                    seller: Number(seller),
                    requestDate: budgetDate,
                    requestNumber: budgetNumber,
                    deadLine: deadLine,
                    modalityFreight,
                    freigth: formatToFloat(freigth),
                    totalValue: formatToFloat(totalValue),
                    discountMoney: formatToFloat(discountMoney),
                    discountPercentage: formatToFloat(discountPercentage),

                    status: "open",
                    products: JSON.stringify(products),
                    situation: JSON.stringify(situation),
                    installments: JSON.stringify(installments),
                    movedToStock: "n",
                    attachments: JSON.stringify(filesPath)
                };

                await api.post("requests", raw);

                setSaveEmit(false);
            }

            history.push("/orcamentos");

            LogService.logRegister({
                itemId: res.data.id,
                module: 'Orçamentos',
                itemName: res.data.reference || 'Orçamento',
            });


        } catch (error) {
            console.log(error);
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalAttention(true);
        }

        setIsSubmit(false);
    };

    return (
        <div className="card card-custom gutter-b mt-2 newProductWrapper">
            <ModalLinkedFieldsError
                message="Para emitir é necessário completar o cadastro do Orçamento!"
                errors={linkedFieldsErrors}
                showModal={showModalLinkedFieldsError}
                setShowModal={setShowModalLinkedFieldsError}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header row m-0 pt-2 pb-0">
                    <div className="col-6 m-0 p-0">
                        <h4 className="card-label m-0 mt-2">
                            Detalhes do orçamento
                        </h4>
                    </div>
                    <div className='col-lg-6 d-flex justify-content-end mb-3'>
                        <Button
                            type='submit'
                            className='mr-3'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {
                                isSubmit ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </>
                                    :
                                    <span>Salvar</span>
                            }
                        </Button>

                        <Button
                            type="submit"
                            size="lg"
                            variant="secondary"
                            onClick={() => setSaveEmit(true)}
                        >
                            {
                                saveEmit ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </>
                                    :
                                    <span>Salvar e Emitir</span>
                            }
                        </Button>
                    </div>
                </div>

                <div className="card-body mt-0 pt-0">
                    <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

                    {selectedSellerIsAuthConsignmentSeller && (
                        <SellerConsignmentStockLocationSelector
                            stockLocationId={sellerConsignmentStockLocationId}
                            setStockLocationId={setSellerConsignmentStockLocationId}
                        />
                    )}

                    <div className="row">
                        <div className="col-lg-6">
                            <CustomerSelect
                                label="Cliente"
                                hasError={customerHasError}
                                isEdit={false}
                                customerId={customerId}
                                setCustomerId={setCustomerId}
                                customerName={customerName}
                                setCustomerName={setCustomerName}
                                onCustomerChanged={handleCustomerChanged}
                                allowIncomplete
                                onClickAddCustomer={handleClickAddCustomer}
                            />

                        </div>

                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Vendedor"
                                getOptionLabel={(option: any) => option.name}
                                value={seller}
                                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if (!seller) return null;
                                    return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                }}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                disabled
                                label="Nº pedido"
                                margin="normal"
                                variant="outlined"
                                value={budgetNumber}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(tab: string) => setActiveTab(tab)}
                                id='newproduct-form-tabs'
                            >

                                <Tab
                                    eventKey='details'
                                    title='Detalhes'
                                >
                                    <div className="row">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <TextField
                                                size="small"
                                                disabled
                                                type="date"
                                                label="Data do pedido"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={budgetDate}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <TextField
                                                size="small"
                                                type="date"
                                                margin="normal"
                                                variant="outlined"
                                                label="Prazo de Entrega"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={deadLine}
                                                onChange={(evt) => handleSetDeadLine(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <TextField
                                                size="small"
                                                type="date"
                                                margin="normal"
                                                variant="outlined"
                                                label="Validade"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={validity}
                                                onChange={(evt) => handleSetValidity(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                label="Referência"
                                                margin="normal"
                                                variant="outlined"
                                                value={reference}
                                                onChange={(evt) => setReference(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                label="Observações"
                                                multiline
                                                rows="2"
                                                placeholder="Esta informação será impressa nas observações da nota."
                                                margin="normal"
                                                variant="outlined"
                                                value={note}
                                                onChange={(evt) => setNote(evt.target.value)}
                                            />
                                        </div>


                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                multiline
                                                rows="2"
                                                margin="normal"
                                                variant="outlined"
                                                label="Observações Internas"
                                                placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                                value={insideNote}
                                                onChange={(evt) => setInsideNote(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-6 ml-2">
                                            <UploadFiles
                                                label="Proposta/Anexos"
                                                filesSelected={filesSelected}
                                                setFilesSelected={setFilesSelected}
                                                filesNamesSaved={filesNamesSaved}
                                                setFilesNamesSaved={setFilesNamesSaved}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='products'
                                    title='Produtos'
                                >
                                    <div className="row">
                                        {
                                            products.map(
                                                (prod, index) => (
                                                    <ListProducts
                                                        key={index}
                                                        product={prod}
                                                        index={index}
                                                        onClickAddProduct={handleClickAddProduct}
                                                        sellerConsignmentProducts={selectedSellerIsAuthConsignmentSeller ? productsAvailableForConsignment : undefined}
                                                    />
                                                ))
                                        }

                                        <div className="row col-lg-12 mt-3 ml-2">
                                            <button
                                                type="button"
                                                className="btn btn-link-secondary p-0"
                                                onClick={() => dispatchProducts({ type: "ADD" })}
                                            >
                                                <ins>+ adicionar outro produto</ins>
                                            </button>
                                        </div>

                                        <div className="col-lg-6">
                                            <ApiResourceSelect
                                                label="Lista de preços"
                                                getOptionLabel={(option: PriceList) => option.name}
                                                value={priceList}
                                                onSelect={(option) => handleSetPriceList(option)}
                                                apiSearchHandler={(typedText) => PriceListService.getPriceListsFiltered({ name: typedText })}
                                                disabled={formatToFloat(totalValueProducts) === 0}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='total-budget'
                                    title='Totais do Pedido'
                                >
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Valor Total dos produtos"
                                                startAdornment="R$"
                                                disabled
                                                value={totalValueProducts}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <TextField
                                                size="small"
                                                label="Valor do IPI"
                                                margin="normal"
                                                variant="outlined"
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Valor Total do frete"
                                                value={freigth}
                                                onChange={(evt) => setFreigth(evt.target.value)}
                                                startAdornment="R$"
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Desconto (R$)"
                                                value={discountMoney}
                                                onChange={(evt) => setDiscountMoney(evt.target.value)}
                                                startAdornment="R$"
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Desconto (%)"
                                                value={discountPercentage}
                                                startAdornment="%"
                                                onChange={(evt) => setDiscountPercentage(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Peso Bruto"
                                                startAdornment="KG"
                                                value={grossWeight}
                                                onChange={(evt) => setGrossWeight(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Peso Líquido"
                                                startAdornment="KG"
                                                value={liquidWeight}
                                                onChange={(evt) => setLiquidWeight(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3 ">
                                            <NumericFormat
                                                label="Valor total"
                                                disabled
                                                value={totalValue}
                                                startAdornment="R$"
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='payment'
                                    title='Pagamento'
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <TextField
                                                size="small"
                                                required
                                                type="number"
                                                margin="normal"
                                                variant="outlined"
                                                label="Quantidade de Parcelas"
                                                inputProps={{ min: 0 }}
                                                value={installmentAmount}
                                                onChange={(evt) => setInstallmentAmount(Number(evt.target.value))}
                                            />
                                        </div>

                                        <div className="col-md-3 d-flex align-items-center justify-content-center">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                block
                                                onClick={() => dispatchInstallments({
                                                    type: "GENERATE",
                                                    payload: {
                                                        amount: installmentAmount,
                                                        value: formatToFloat(totalValue),
                                                    }
                                                })}
                                            >
                                                Gerar parcelas
                                            </Button>
                                        </div>

                                        <div className="row col-lg-12 mt-10">
                                            {
                                                installments.length > 0 && installments.map((installment, index) => (
                                                    <Installment key={index} index={index} installment={installment} installmentAmount={installmentAmount} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='transport'
                                    title='Transporte'
                                >
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextField
                                                size="small"
                                                select
                                                label="Modalidade de Frete"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                value={modalityFreight}
                                                onChange={e => setModalityFreight(e.target.value)}
                                            >
                                                <MenuItem key="0" value="sender">
                                                    Remetente
                                                </MenuItem>

                                                <MenuItem key="1" value="recipient">
                                                    Destinatário
                                                </MenuItem>

                                                <MenuItem key="5" value="freeShipping">
                                                    Sem Frete
                                                </MenuItem>
                                            </TextField>
                                        </div>

                                        <div className="col-lg-8">
                                            <ApiResourceSelect
                                                label="Transportadora"
                                                freeSolo
                                                getOptionLabel={(option: Carrier) => option.name}
                                                value={carrier}
                                                onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                                onInputChange={(typedText) => setCarrier(typedText || '')}
                                                apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
