import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListReportAccountBank } from '../pages/Reports/ReportAccountBank/List-ReportAccountBank';
import { ListReportCarrier } from '../pages/Reports/ReportCarrier/List-ReportCarrier';
import { ListReportCustomer } from '../pages/Reports/ReportCustomer/List-ReportCustomer';
import { ListReportPrice } from '../pages/Reports/ReportPrice/List-ReportPrice';
import { ListReportSeller } from '../pages/Reports/ReportSeller/List-ReportSeller';
import { ListReportPurchaseOrder } from '../pages/Reports/ReportPurchase/List-ReportPurchaseOrder';
import { ListReportServiceOrder } from '../pages/Reports/ReportServiceOrder/List-ReportServiceOrder';
import { ListReportNfse } from '../pages/Reports/ReportNfse/List-ReportNfse';
import { ListReportServiceContract } from '../pages/Reports/ReportServiceContract/List-ReportServiceContract';
import { ListReportBalanceStock } from '../pages/Reports/ReportBalanceStock/List-ReportBalanceStock';
import { ListReportMovementStock } from '../pages/Reports/ReportMovementStock/List-ReportMovementStock';
import { ListReportBudget } from '../pages/Reports/ReportBudget/List-ReportBudget';
import { ListReportBillsToPay } from '../pages/Reports/ReportBillsToPay/List-ReportBillsToPay';
import { ListReportExtract } from '../pages/Reports/ReportExtract/List-ReportExtract';
import { ListReportBillsToReceive } from '../pages/Reports/ReportBillsToReceive/List-ReportBillsToReceive';
import { ListReportDefaulterCustomer } from '../pages/Reports/ReportDefaulterCustomer/List-ReportDefaulterCustomer';
import { ListReportCenterCost } from '../pages/Reports/ReportCenterCost/List-ReportCenterCost';
import { ListReportRequests } from '../pages/Reports/ReportRequests/List-ReportRequests';
import { ListReportNFE } from '../pages/Reports/ReportNFE/List-ReportNFE';
import { ListReportNFCE } from '../pages/Reports/ReportNFCE/List-ReportNFCE';
import { ListReportPDV } from '../pages/Reports/ReportPDV/List-ReportPDV';
import { ListReportSoldServices } from '../pages/Reports/ReportSoldServices/List-ReportSoldServices';
import { ListReportInventoryStock } from '../pages/Reports/ReportInventoryStock/List-ReportInventoryStock';
import { ListReportSoldProducts } from '../pages/Reports/ReportSoldProducts/List-ReportSoldProducts';
import { ListReportEntry } from '../pages/Reports/ReportEntry/List-ReportEntry';
import { ListReportBillet } from '../pages/Reports/ReportBillet/List-ReportBillet';
import { ListReportProfitMargin } from '../pages/Reports/reportProfitMargin/List-ReportProfitMargin';
import { ListReportCustomerMostBuyer } from '../pages/Reports/ReportCustomersMostBuyer/List-ReportCustomersMostBuyer';
import { ListReportInvoicing } from '../pages/Reports/ReportInvoicing/List-ReportInvoicing';
import { ListReportDreCategory } from '../pages/Reports/ReportDreCategory/List-ReportDreCategory';
import { ListReportCommission } from '../pages/Reports/ReportComission/List-ReportCommission';

export function Report() {

    return (
        <Switch>
            {/* ESTOQUE */}
            <Route path="/relatorios/estoque-saldo" component={ListReportBalanceStock} />
            <Route path="/relatorios/estoque-custo" component={ListReportMovementStock} />
            <Route path="/relatorios/estoque-inventario" component={ListReportInventoryStock} />

            
            {/* CADASTROS */}
            <Route path="/relatorios/clientes-fornecedores" component={ListReportCustomer} />
            <Route path="/relatorios/precos" component={ListReportPrice} />
            <Route path="/relatorios/transportadoras" component={ListReportCarrier} />
            <Route path="/relatorios/vendedores" component={ListReportSeller} />
            <Route path="/relatorios/contas-bancarias" component={ListReportAccountBank} />

            {/* FINANCEIRO */}
            <Route path="/relatorios/contas-pagar" component={ListReportBillsToPay} />
            <Route path="/relatorios/contas-receber" component={ListReportBillsToReceive} />
            <Route path="/relatorios/extrato" component={ListReportExtract} />
            <Route path="/relatorios/inadimplentes" component={ListReportDefaulterCustomer} />
            <Route path="/relatorios/centro-custos" component={ListReportCenterCost} />
            <Route path="/relatorios/titulos" component={ListReportBillet} />
            <Route path="/relatorios/plano-de-contas" component={ListReportDreCategory} />
            <Route path="/relatorios/comissoes" component={ListReportCommission} />


            {/* VENDAS */}
            <Route path="/relatorios/orcamentos" component={ListReportBudget} />
            <Route path="/relatorios/pedidos" component={ListReportRequests} />
            <Route path="/relatorios/nfe" component={ListReportNFE} />
            <Route path="/relatorios/nfce" component={ListReportNFCE} />
            <Route path="/relatorios/pdv" component={ListReportPDV} />
            <Route path="/relatorios/produtos-vendidos" component={ListReportSoldProducts} />
            <Route path="/relatorios/compras-clientes" component={ListReportCustomerMostBuyer} />
            <Route path="/relatorios/faturamento" component={ListReportInvoicing} />
            <Route path="/relatorios/margem-lucro" component={ListReportProfitMargin} />

            {/* COMPRAS */}
            <Route path="/relatorios/ordem-compra" component={ListReportPurchaseOrder} />
            <Route path="/relatorios/entrada-mercadoria" component={ListReportEntry} />


            {/* SERVIÇOS */}
            <Route path="/relatorios/nfse" component={ListReportNfse} />
            <Route path="/relatorios/ordem-servico" component={ListReportServiceOrder} />
            <Route path="/relatorios/contratos" component={ListReportServiceContract} />
            <Route path="/relatorios/servicos-vendidos" component={ListReportSoldServices} />

        </Switch>
    );
}