export function getTaxRegimeText(taxRegime: string) {
    switch (taxRegime) {
        case 'simple national':
            return 'Simples Nacional';
        case 'normal regime':
            return 'Regime Normal';

        default:
            return '';
    }
}