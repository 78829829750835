import { LinkedFieldError } from "../types/LinkedFieldError";

class PurchaseOrderService {
    verifyLinkedFields(request: any) {
        const errors: LinkedFieldError[] = [];

        if(!request.supplierId && request.supplierName) {
            errors.push({
                fieldName: 'Fornecedor',
                fieldValue: request.supplierName,
            });
        }

        const products = JSON.parse(request.products ?? '[]');
        for (const product of products) {
            if(product.name && !product.isLinked) {
                errors.push({
                    fieldName: 'Produto',
                    fieldValue: product.name,
                });
            }
        }

        return errors;
    }

    verifySupplierLink(request: any) {
        const errors: LinkedFieldError[] = [];

        if(!request.supplierId && request.supplierName) {
            errors.push({
                fieldName: 'Fornecedor',
                fieldValue: request.supplierName,
            });
        }

        return errors;
    }
}

export default new PurchaseOrderService();