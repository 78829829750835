import React, { useCallback, useState } from "react";
import { Product } from "../../types/Product";
import { Modal } from "react-bootstrap";
import ReactPortal from "../ReactPortal";
import { IconButton } from "@material-ui/core";
import { NewProductDefaultData, NewProductForm } from "./NewProductForm/NewProductForm";

type NewProductModalProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCreateProduct?: (createdProduct: Product) => void;
    defaultData?: NewProductDefaultData;
    allowChangeType?: boolean;
};

export function NewProductModal({
    showModal,
    setShowModal,
    onCreateProduct,
    defaultData,
    allowChangeType,
}: NewProductModalProps) {
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleProductCreated = useCallback((createdProduct: Product) => {
        if (onCreateProduct) {
            onCreateProduct(createdProduct);
        }
    }, [onCreateProduct]);

    return (
        <ReactPortal containerId="portal-new-Product-modal">
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                centered
                className="newProductWrapper"
                dialogClassName="wide-modal"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adicionar um Produto / Serviço
                    </Modal.Title>

                    <IconButton
                        edge="end"
                        onClick={handleCloseModal}
                        title="Fechar"
                        className="py-0"
                    >
                        <i className="flaticon-close text-body"></i>
                    </IconButton>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <NewProductForm
                        availableTabs={{
                            variation: false,
                            productStructure: false,
                            nfe: true,
                        }}
                        defaultData={defaultData}
                        allowChangeType={allowChangeType}
                        onCloseModalLimit={handleCloseModal}
                        onProductCreated={handleProductCreated}
                        onCloseProductCreatedSuccessModal={handleCloseModal}
                    />
                </Modal.Body>
            </Modal>
        </ReactPortal>
    )
}