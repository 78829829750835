import { makeStyles, Popper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { EmailToCustomer, ModalSendEmail } from '../../components/ModalSendEmail';
import { ModalSendWhatsapp, WhatsappToCustomer } from '../../components/ModalSendWhatsapp';
import ModalSuccess from '../../components/ModalSuccess';
import emailQuotationSupplierService from '../../services/EmailQuotationSupplierService';
import whatsappQuotationSupplierService from '../../services/WhatsappQuotationSupplierService';
import quotationService from '../../services/QuotationService';
import quotationSupplierService from '../../services/QuotationSupplierService';
import { EmailsHistory } from '../../types/EmailsHistory';
import { QuotationProduct, QuotationStatus, QuotationSupplier, QuotationSupplierStatus } from '../../types/Quotation';
import { WhatsappHistory } from '../../types/WhatsappHistory';
import { formatCurrency, formatNumberToString } from '../../utils/formatCurrency';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { getSituationText } from '../../utils/getSituationText';
import { getVariant } from '../../utils/getVariant';
import { colorBlue, colorSuccess } from '../../utils/styles';
import ModalConfirm from '../../components/ModalConfirm';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import api from '../../services/Api';
import { Situation } from '../../types/Situation';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfQuotationSupplier from './PdfQuotationSupplier/PdfQuotationSupplier';
import QuotationService from '../../services/QuotationService';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { Customer } from '../../types/Customer';
import CustomerService from '../../services/CustomerService';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import ModalError from '../../components/ModalError';

const useStyles = makeStyles(theme => ({
    tableCellTextField: {
        padding: 4,
    },
    popper: {
        width: 'fit-content !important',
        zIndex: 999,
    },
}));

export type ListQuotationSuppliersProps = {
    quotationSuppliers: QuotationSupplier[];
    setQuotationSuppliers: React.Dispatch<React.SetStateAction<QuotationSupplier[]>>;
    setQuotationSuppliersIdsToDelete?: React.Dispatch<React.SetStateAction<number[]>>;
    showExtraColumns?: boolean;
    quotationIsFinished?: boolean;
};

export function ListQuotationSuppliers({
    quotationSuppliers,
    setQuotationSuppliers,
    setQuotationSuppliersIdsToDelete,
    showExtraColumns = false,
    quotationIsFinished = false,
}: ListQuotationSuppliersProps) {
    const { user } = useSelector((state: any) => state.auth);

    const classes = useStyles();
    const history = useHistory();

    const [quotationSupplierDetails, setQuotationSupplierDetails] = useState<QuotationSupplier>();
    const [showAnswerDetailsModal, setShowAnswerDetailsModal] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [showModalEmail, setShowModalEmail] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [infoEmail, setInfoEmail] = useState<EmailToCustomer>({
        refId: '',
        refName: '',
        toName: '',
        customerName: '',
        customerId: '',
        email: '',
        contactName: '',
        message: '',
        emailSubject: '',
    });
    const [emailsHistory, setEmailsHistory] = useState<EmailsHistory[]>([]);

    const [showModalWhatsapp, setShowModalWhatsapp] = useState(false);
    const [infoWhatsapp, setInfoWhatsapp] = useState<WhatsappToCustomer>({
        refId: '',
        refName: '',
        customerName: '',
        customerId: '',
        number: '',
        message: '',
    });
    const [whatsappHistory, setWhatsappHistory] = useState<WhatsappHistory[]>([]);

    // Modal New Customer
    const [indexQuotationSupplierAddingRow, setIndexQuotationSupplierAddingRow] = useState(0);
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        selectSupplier(createdCustomer, indexQuotationSupplierAddingRow)
    };
    
    const handleClickAddCustomer = (typedText: string, indexQuotationSupplier: number) => {
        setNewCustomerDefaultData({
            name: typedText,
            typeRegister: 'supplier',
        });
        setIndexQuotationSupplierAddingRow(indexQuotationSupplier);
        setShowModalNewCustomer(true);
    };

    async function getEmails(quotationSupplierId: number) {
        setEmailsHistory([]);
        const emails = await emailQuotationSupplierService.getAllEmailsByQuotationSupplierId(quotationSupplierId);
        setEmailsHistory(emails);
    }

    async function getWhatsapps(quotationSupplierId: number) {
        setWhatsappHistory([]);
        const history = await whatsappQuotationSupplierService.getAllWhatsappsByQuotationSupplierId(quotationSupplierId);
        setWhatsappHistory(history);
    }

    function addSupplier() {
        var suppliersArray = quotationSuppliers;

        suppliersArray.push({
            id: 0,
            quotationId: 0,
            supplier: 0,
        });

        setQuotationSuppliers([...suppliersArray]);
    }

    function deleteSupplier(index: number) {
        var suppliersArray = quotationSuppliers;
        const quotationSupplier = suppliersArray[index];
        if (quotationSupplier.id !== 0 && setQuotationSuppliersIdsToDelete) {
            setQuotationSuppliersIdsToDelete((prevState) => ([...prevState, quotationSupplier.id]));
        }
        suppliersArray.splice(index, 1);
        setQuotationSuppliers([...suppliersArray]);
    }

    const selectSupplier = useCallback(async (value: any, index: number) => {
        var supplierArray = quotationSuppliers;

        if (value) {
            supplierArray[index].supplier = value.id;
            supplierArray[index].status = QuotationSupplierStatus.pending;
            supplierArray[index].INSTANCE = {
                name: value.name,
                email: value.email,
                cell: value.cell,
            };

        } else {
            supplierArray[index].supplier = 0;
            supplierArray[index].status = undefined;
            supplierArray[index].INSTANCE = undefined;
        }

        setQuotationSuppliers([...supplierArray]);
    }, [quotationSuppliers]);

    function handleOpenSendEmailModal(quotationSupplier: QuotationSupplier) {
        setShowModalEmail(true);
        getEmails(quotationSupplier.id);
        setInfoEmail({
            refId: String(quotationSupplier.id),
            refName: 'quotationSupplier',
            toName: '',
            customerName: quotationSupplier.INSTANCE?.name ?? '',
            customerId: String(quotationSupplier.supplier),
            email: quotationSupplier.INSTANCE?.email ?? '',
            contactName: '',
            message: quotationSupplierService.getDefaultEmailMessage(),
            emailSubject: 'Cotação de Preços',
        });
    }

    const handleClickSendEmail = useCallback(async () => {
        try {
            const emails = infoEmail.email.split(';').map(email => email.trim());
            const quotationSupplier = quotationSuppliers.find(obj => obj.id === Number(infoEmail.refId));
            if (!quotationSupplier) return;

            const quotation = await quotationService.getQuotationById(quotationSupplier.quotationId);
            if (!quotation) return;

            await emailQuotationSupplierService.sendEmail(emails, {
                quotationSupplier,
                customerId: infoEmail.customerId,
                customerName: infoEmail.customerName,
                contactName: infoEmail.contactName,
                message: infoEmail.message,
                refName: infoEmail.refName,
                subject: infoEmail.emailSubject,
                companyId: quotation.companyId,
                link: `${window.location.origin}/responder-cotacao/${quotation.id}/${quotationSupplier.id}`
            });
    
            // Atualizar Status
            await updateStatus(quotationSupplier.id, QuotationSupplierStatus.sent);

            setShowModalSuccess(true);
        } catch (error) {
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalError(true);
        }

        setShowModalEmail(false);
    }, [quotationSuppliers, infoEmail]);

    function handleOpenSendWhatsappModal(quotationSupplier: QuotationSupplier) {
        setShowModalWhatsapp(true);
        getWhatsapps(quotationSupplier.id);
        setInfoWhatsapp({
            refId: String(quotationSupplier.id),
            refName: 'quotationSupplier',
            customerName: quotationSupplier.INSTANCE?.name ?? '',
            customerId: String(quotationSupplier.supplier),
            number: quotationSupplier.INSTANCE?.cell ?? '',
            message: quotationSupplierService.getDefaultWhatsappMessage(quotationSupplier.INSTANCE?.name ?? ''),
        });
    }

    const handleClickSendWhatsapp = useCallback(async () => {
        try {
            const quotationSupplier = quotationSuppliers.find(obj => obj.id === Number(infoWhatsapp.refId));
            if (!quotationSupplier) return;

            const quotation = await quotationService.getQuotationById(quotationSupplier.quotationId);
            if (!quotation) return;

            await whatsappQuotationSupplierService.sendWhatsapp(infoWhatsapp.number, {
                quotationSupplier,
                customerId: infoWhatsapp.customerId,
                customerName: infoWhatsapp.customerName,
                message: infoWhatsapp.message,
                refName: infoWhatsapp.refName,
                companyId: quotation.companyId,
                link: `${window.location.origin}/responder-cotacao/${quotation.id}/${quotationSupplier.id}`
            });

            // Atualizar Status
            await updateStatus(quotationSupplier.id, QuotationSupplierStatus.sent);

            setShowModalSuccess(true);
            setMessageSuccess('Mensagem enviada com sucesso!');
        } catch (error) {
            setMsgError('Ocorreu um erro ao processar a requisição');
            setShowModalError(true);
        }

        setShowModalWhatsapp(false);
    }, [quotationSuppliers, infoWhatsapp]);

    function handleOpenAnswerDetailsModal(quotationSupplier: QuotationSupplier) {
        setShowAnswerDetailsModal(true);
        setQuotationSupplierDetails(quotationSupplier);
    }

    const handleOpenCancelModal = useCallback((quotationSupplier: QuotationSupplier) => {
        setShowModalCancel(true);
        setQuotationSupplierDetails(quotationSupplier);
    }, []);

    const handleConfirmCancel = useCallback(async () => {
        if (!quotationSupplierDetails) return;

        try {
            await updateStatus(quotationSupplierDetails.id, QuotationSupplierStatus.canceled);

            setShowModalCancel(false);
        } catch (error) {
            console.log('error', error);
        }
    }, [quotationSupplierDetails]);

    const handleOpenApproveModal = useCallback((quotationSupplier: QuotationSupplier) => {
        setShowModalApprove(true);
        setQuotationSupplierDetails(quotationSupplier);
    }, []);

    const handleConfirmApprove = useCallback(async () => {
        if (!quotationSupplierDetails) return;

        try {
            const today = format(Date.now(), "yyyy-MM-dd");
            const dateObj = new Date();
            dateObj.setMonth(dateObj.getMonth() + 1);
            const paymentDate = extractDateStringFromTimestamp(dateObj.toISOString());

            // Atualizar Status do fornecedor
            await updateStatus(quotationSupplierDetails.id, QuotationSupplierStatus.chosen);

            // Atualizar status e detalhes da cotação
            const quotation = await quotationService.getQuotationById(quotationSupplierDetails.quotationId);
            const quotationSituation: any[] = quotation?.situation ? JSON.parse(quotation.situation) : [];
            const lastStatus = quotationSituation[quotationSituation.length - 1];

            const newStatus = {
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                datetimeSituation: getDate({dateFormat: 'y-m-d'}).fullDateStr,
                commentsSituation: '',
                statusSituation: QuotationStatus.attended,
                statusSituationLabel: getSituationText(QuotationStatus.attended)
            };
            quotationSituation.push(newStatus);

            QuotationService.dispatchStatusChangeNotificationAndEmail({
                quotationId: quotationSupplierDetails.quotationId,
                lastStatusHistory: lastStatus,
                newStatusHistory: newStatus,
            });

            const updatedQuotation = await quotationService.updateQuotation(quotationSupplierDetails.quotationId, {
                situation: JSON.stringify(quotationSituation),
                status: QuotationStatus.attended,
                totalValue: quotationSupplierDetails.totalValue,
                chosenSupplierId: quotationSupplierDetails.supplier,
            });

            //Gerar ordem de compra
            const situation: any[] = [];
            situation.push({
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                commentsSituation: "",
                statusSituation: "open",
            });

            const quotationProducts: QuotationProduct[] = quotationSupplierDetails.products ? JSON.parse(quotationSupplierDetails.products) : [];
            const orderProducts = quotationProducts.map(product => ({
                icms: '0',
                cfop: "0",
                ipi: '0',
                ncm: '',
                total: product.amount * (product.unitaryValue ?? 0),
                amount: Number(product.amount),
                unitaryValue: product.unitaryValue ?? 0,
                INSTANCE: product.INSTANCE,
            }));

            const raw = {
                orderDate: today,
                companyId: updatedQuotation.companyId,
                insideNotes: `Cotação n° ${updatedQuotation.id}`,
                supplierId: quotationSupplierDetails.supplier,
                note: null,
                freightPrice: quotationSupplierDetails.freightTotal ?? 0,
                status: "open",
                totalPrice: quotationSupplierDetails.totalValue ?? 0,
                products: JSON.stringify(orderProducts),
                situation: JSON.stringify(situation),
                installments: JSON.stringify([{
                    date: paymentDate,
                    value: parseFloat(String(quotationSupplierDetails.totalValue ?? 0)),
                    comments: quotationSupplierDetails.paymentDetails ?? '',
                    payment: quotationSupplierDetails.paymentMethod ?? '',
                }]),
                movedToStock: "n"
            };

            await api.post("purchase-order", raw);

            history.push("/cotacoes");

            setShowModalApprove(false);
        } catch (error) {
            console.log('error', error);
        }
    }, [quotationSupplierDetails]);

    async function updateStatus(quotationSupplierId: number, newStatus: QuotationSupplierStatus) {
        const updatedSupplier = await quotationSupplierService.updateQuotationSupplierSituation(quotationSupplierId, {
            newStatus,
        });

        if (updatedSupplier) {
            setQuotationSuppliers((prevState) => prevState.map((supplier) => {
                if (supplier.id === updatedSupplier?.id) {
                    return {
                        ...supplier,
                        ...updatedSupplier,
                        INSTANCE: supplier.INSTANCE,
                        status: updatedSupplier.status,
                        situation: updatedSupplier.situation ? JSON.parse(updatedSupplier.situation) : [],
                    };
                }
                return supplier;
            }));
        }
    }

    return (
        <>
            <ModalError
                setShowModalError={setShowModalError}
                showModalError={showModalError}
                msgError={msgError}
            />
            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
            />
            <ModalSendEmail
                showModal={showModalEmail}
                setShowModal={setShowModalEmail}
                onClickSend={handleClickSendEmail}
                emailsHistory={emailsHistory}
                infoEmail={infoEmail}
                setInfoEmail={setInfoEmail}
            />
            <ModalSendWhatsapp
                showModal={showModalWhatsapp}
                setShowModal={setShowModalWhatsapp}
                onClickSend={handleClickSendWhatsapp}
                whatsappHistory={whatsappHistory}
                infoWhatsapp={infoWhatsapp}
                setInfoWhatsapp={setInfoWhatsapp}
            />
            <ModalConfirm
                showModal={showModalCancel}
                setShowModal={setShowModalCancel}
                onConfirm={handleConfirmCancel}
                confirmButtonLabel={'Sim'}
                cancelButtonLabel={'Não'}
                confirmText={'Tem certeza que deseja cancelar a cotação com este fornecedor ?'}
            />
            <ModalConfirm
                showModal={showModalApprove}
                setShowModal={setShowModalApprove}
                onConfirm={handleConfirmApprove}
                confirmButtonLabel={'Aceitar'}
                cancelButtonLabel={'Cancelar'}
                confirmText={'Tem certeza que deseja aceitar a proposta ? Esta ação irá gerar a ordem de compra com os valores desta cotação.'}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType="supplier"
            />

            {/* Modal Detalhes da responsta */}
            <Modal
                centered
                aria-labelledby="contained-modal-warning"
                size="xl"
                show={showAnswerDetailsModal}
                onHide={() => setShowAnswerDetailsModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Detalhes da Resposta
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!quotationSupplierDetails && <p>Carregando...</p>}
                    {quotationSupplierDetails && (
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <p><strong>Forma de Pagamento: </strong>{quotationSupplierDetails.paymentMethod ?? ''}</p>
                                    <p><strong>Detalhes Pagamento: </strong>{quotationSupplierDetails.paymentDetails ?? ''}</p>
                                    <p><strong>Total produtos: </strong>{formatCurrency(quotationSupplierDetails.productsTotal ?? 0)}</p>
                                    {/* <p><strong>Total Despesas: </strong>{formatCurrency(quotationSupplierDetails.expensesTotal ?? 0)}</p> */}
                                </div>
                                <div className="col-6">
                                    <p><strong>Responsável: </strong>{quotationSupplierDetails.responsible ?? ''}</p>
                                    <p><strong>Total frete: </strong>{formatCurrency(quotationSupplierDetails.freightTotal ?? 0)}</p>
                                    <p><strong>Valor Total: </strong>{formatCurrency(quotationSupplierDetails.totalValue ?? 0)}</p>
                                </div>
                            </div>

                            <div className="mt-3">
                                <h3 className="border-bottom mt-3 pb-3">Itens</h3>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Produto</TableCell>
                                            <TableCell>Quantidade</TableCell>
                                            <TableCell>Valor unitário</TableCell>
                                            <TableCell>Frete</TableCell>
                                            {/* <TableCell>Despesas</TableCell> */}
                                            <TableCell>Total</TableCell>
                                            <TableCell>Obs</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!quotationSupplierDetails.products && JSON.parse(quotationSupplierDetails.products).map((product: QuotationProduct) => (
                                            <TableRow key={product.id}>
                                                <TableCell>{product.INSTANCE.name}</TableCell>
                                                <TableCell>{product.amount}</TableCell>
                                                <TableCell>{formatCurrency(product.unitaryValue ?? 0)}</TableCell>
                                                <TableCell>{formatCurrency(product.freightValue ?? 0)}</TableCell>
                                                {/* <TableCell>{formatCurrency(product.expensesValue ?? 0)}</TableCell> */}
                                                {/* <TableCell>{formatNumberToString(product.icmsTax ?? 0)}</TableCell>
                                                <TableCell>{formatNumberToString(product.ipiTax ?? 0)}</TableCell> */}
                                                <TableCell>{formatCurrency(product.total ?? 0)}</TableCell>
                                                <TableCell>{product.comments ?? ''}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAnswerDetailsModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col">
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Fornecedor</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Whatsapp</TableCell>
                                {showExtraColumns && (
                                    <>
                                        <TableCell>Situação</TableCell>
                                        <TableCell>Total</TableCell>
                                    </>
                                )}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                quotationSuppliers.map((supplier, index: number) => {
                                    const statusText = getSituationText(supplier.status);
                                    const variant = getVariant(statusText);
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tableCellTextField}>
                                                {!supplier.id && (
                                                    <ApiResourceSelect
                                                        label="Fornecedor"
                                                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                                        value={supplier.supplier}
                                                        onSelect={(option) => selectSupplier(option, index)}
                                                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                                                        getSelectedOption={(loadedOptions) => {
                                                            if(!supplier.supplier) return null;
                                                            return loadedOptions.find((option) => option.id === Number(supplier.supplier)) ?? CustomerService.getCustomerById(supplier.supplier)
                                                        }}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                        renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText, index)} />}
                                                    />
                                                )}
                                                {!!supplier.id && (
                                                    <span>{supplier.INSTANCE?.name ?? ''}</span>
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                {supplier.INSTANCE?.email}
                                            </TableCell>

                                            <TableCell>
                                                {supplier.INSTANCE?.cell && formatPhoneNumber(supplier.INSTANCE.cell)}
                                            </TableCell>

                                            {showExtraColumns && (
                                                <>
                                                    <TableCell>
                                                        {supplier.status && (
                                                            <Badge
                                                                variant={variant}
                                                                style={
                                                                    variant == "success"
                                                                        ? colorSuccess
                                                                        : (
                                                                            variant === 'primary'
                                                                                ? colorBlue
                                                                                : {}
                                                                        )
                                                                }
                                                            >
                                                                {getSituationText(supplier.status)}
                                                            </Badge>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{supplier.totalValue ? formatCurrency(supplier.totalValue) : ''}</TableCell>
                                                </>
                                            )}

                                            <TableCell>
                                                {(!!supplier.id && user.isAccountant == "n") && (
                                                    <>
                                                        {quotationSupplierService.isAnswered(supplier) && (
                                                            <>
                                                            
                                                                <Tooltip TransitionComponent={Zoom} title="Detalhes da Resposta">
                                                                    <Button
                                                                        className="btn p-2 mr-3"
                                                                        type="button"
                                                                        variant="info"
                                                                        onClick={() => handleOpenAnswerDetailsModal(supplier)}
                                                                    >
                                                                        <i className="flaticon-eye p-0" style={{ color: "#fff" }}></i>
                                                                    </Button>
                                                                </Tooltip>
                                                                <PDFDownloadLink
                                                                    document={<PdfQuotationSupplier quotationSupplierId={supplier.id} />}
                                                                    fileName={`cotacao_${supplier.quotationId}`}>
                                                                    <Tooltip TransitionComponent={Zoom} title="Baixar pdf">
                                                                        <Button
                                                                            className="btn-light-secondary p-2 mr-3"
                                                                            type="button"
                                                                        >
                                                                            <i className="flaticon2-printer p-0"></i>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </PDFDownloadLink>
                                                                {!quotationIsFinished && (
                                                                    <Tooltip TransitionComponent={Zoom} title="Aceitar">
                                                                        <Button
                                                                            className="btn p-2 mr-3"
                                                                            type="button"
                                                                            style={{ background: "#50cd89", border: "none" }}
                                                                            onClick={() => handleOpenApproveModal(supplier)}
                                                                        >
                                                                            <i className="flaticon2-checkmark p-0" style={{ color: "#fff" }}></i>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        )}
                                                        {!quotationIsFinished && (
                                                            <>
                                                                <Tooltip TransitionComponent={Zoom} title="Enviar Whatsapp">
                                                                    <Button
                                                                        className="btn p-2 mr-3"
                                                                        type="button"
                                                                        style={{ background: "#50cd89", border: "none" }}
                                                                        onClick={() => handleOpenSendWhatsappModal(supplier)}
                                                                    >
                                                                        <img src="/media/icons/whatsapp.png" alt="Whatsapp" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip TransitionComponent={Zoom} title="Enviar email">
                                                                    <Button
                                                                        className="btn p-2 mr-3"
                                                                        type="button"
                                                                        style={{ background: "#6993ff", border: "none" }}
                                                                        onClick={() => handleOpenSendEmailModal(supplier)}
                                                                    >
                                                                        <i className="flaticon2-email p-0" style={{ color: "#fff" }}></i>
                                                                    </Button>
                                                                </Tooltip>
                                                                {!quotationSupplierService.isCanceled(supplier) && (
                                                                    <Tooltip TransitionComponent={Zoom} title="Cancelar">
                                                                        <Button
                                                                            className="btn p-2 mr-3"
                                                                            type="button"
                                                                            variant='danger'
                                                                            onClick={() => handleOpenCancelModal(supplier)}
                                                                        >
                                                                            <i className="flaticon2-cross p-0" style={{ color: "#fff" }}></i>
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {(!quotationIsFinished && user.isAccountant == "n") && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-link"
                                                        onClick={() => deleteSupplier(index)}
                                                        title="Remover"
                                                    >
                                                        <i className="flaticon-delete"></i>
                                                    </button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </div>

                {(!quotationIsFinished && user.isAccountant == "n") && (
                    <div className="row col-lg-12 mt-3 ml-2">
                        <button
                            type="button"
                            className="btn btn-link-secondary p-0"
                            onClick={() => addSupplier()}
                        >
                            <ins>+ adicionar outro fornecedor</ins>
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}