import { LinkedFieldError } from "../types/LinkedFieldError";

class BudgetService {
    verifyLinkedFields(budget: any) {
        const errors: LinkedFieldError[] = [];

        if(!budget.customerId && budget.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: budget.customerName,
            });
        }

        const products = JSON.parse(budget.products ?? '[]');
        for (const product of products) {
            if(product.name && !product.isLinked) {
                errors.push({
                    fieldName: 'Produto',
                    fieldValue: product.name,
                });
            }
        }

        return errors;
    }

    verifyCustomerLink(budget: any) {
        const errors: LinkedFieldError[] = [];

        if(!budget.customerId && budget.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: budget.customerName,
            });
        }

        return errors;
    }
}

export default new BudgetService();