import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Link
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal
} from "react-bootstrap";

import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';
import ModalError from '../../components/ModalError';

import '../../style.css';
import { extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import LogService from '../../services/LogService';
import { SubCategory } from '../../types/Dre';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import useCurrentBalance from '../../hooks/useCurrentBalance';
import DreCategoryService from '../../services/DreCategoryService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import { BankAccount } from '../../types/BankAccount';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import useAddAccountBank from '../../hooks/addAccountBank';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));


export function NewTransferBillsToReceive() {
    const [activeTab, setActiveTab] = useState("detalhes-receita");

    const [isSubmit, setIsSubmit] = useState(false);
    const [isPaid, setIsPaid] = useState(true);
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)
    const [dreSubCategoryExpenseId, setDreSubCategoryExpenseId] = useState(0)
    
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isGenerateInstallment, setIsGenerateInstallment] = useState(false);
    const [occurrence, setOccurrence] = useState("unica");
    const [amount, setAmount] = useState("0");
    const [isInstallment, setIsInstallment] = useState(false);
    const [fromAccount, setFromAccount] = useState("");
    const [toAccount, setToAccount] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [customer, setCustomer] = useState("");
    const [issuanceDate, setIssuanceDate] = useState("");

    const [loadingExpense, setLoadingExpenseBalance] = useState(false);
    const [loadingRevenue, setLoadingRevenueBalance] = useState(false);
    const [expenseBalance, setExpenseBalance] = useState(0);
    const [revenueBalance, setRevenueBalance] = useState(0);

    const [installments, setInstallments] = useState({
        installments: [
            {
                date: "",
                value: 0,
                typePayment: ""
            }
        ]
    });
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, watch, handleSubmit } = useForm();

    // custom hook
    const { getAccountBalance } = useCurrentBalance();


    useEffect(() => {
        setIssuanceDate(extractDateStringFromTimestamp());
        getCategories();
    }, []);

    subHeader.setTitle("Adicionar Conta a Receber");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const [typeAccountBankBeingCreated, setTypeAccountBankBeingCreated] = useState<"from" | "to">("from");
    const handleCreateToAccountBank = (accountBank: BankAccount) => {
        if (typeAccountBankBeingCreated === 'from') {
            setFromAccount(String(accountBank.id));
        } else {
            setToAccount(String(accountBank.id));
        }
    }

    // function formatToFloat(value: any) {
    //     var auxValue = value;

    //     if (auxValue) {
    //         if (auxValue.includes('R$')) {
    //             auxValue = auxValue.replace('R$', '');
    //         }
            
    //         if (auxValue.includes(',') && !auxValue.includes('.')) {
    //            return parseFloat(auxValue.replace(',', '.'));
               
    //         } else {
    //            return parseFloat(auxValue.replace('.', '').replace(',', '.'));
    //         }
    //     } else {
    //         return auxValue;
    //     }
    // }

    async function getCategories() {
        var idTransferEnter = await DreCategoryService.getDreSubCategoryByName('Transferência de Entrada');
        var idTransferOutput = await DreCategoryService.getDreSubCategoryByName('Transferência de Saída');

        setDreSubCategoryId(idTransferEnter.id);
        setDreSubCategoryExpenseId(idTransferOutput.id);
    }

    function changeOccurrence(type: any)
    {
        if (type == "parcelada") {
            setIsInstallment(true);

        } else {
            setIsInstallment(false);
        }
    }

    function convertToFloat(value: any)
    {
        var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
        if (valueConverted.includes(".") && valueConverted.includes(",")) {
            valueConverted = valueConverted.replace(".", "").replace(",", ".");
        } else {
            valueConverted = valueConverted.replace(",", ".");
        }

        return parseFloat(valueConverted);
    }

    function generateInstallment()
    {
        if (occurrence == "parcelada") {
            if (amount && amount != "0") {
                var allInstallments     = [];
                var countInstallments   = watch('installments');
                var valueExpense        = convertToFloat(amount);
                var resultDivided       = valueExpense / watch('installments');
                var currentDate         = new Date();
    
                for (var index = 0; index < countInstallments; index++) {
                    var month = currentDate.getMonth() + 1;
                    
                    allInstallments.push({
                        date: currentDate.getFullYear() +"-"+ (month < 10 ? "0"+ month : month) +"-"+ currentDate.getDate(),
                        value: resultDivided,
                        typePayment: "Boleto",
                    });

                    currentDate.setMonth(currentDate.getMonth() + 1);
                }
    
                setInstallments({ installments: allInstallments });
                setIsGenerateInstallment(true);

            } else {
                setMsgError("Informe o valor da receita para gerar parcelas!");
                setShowModalError(true);
                setInstallments({ installments: [] });
                setIsGenerateInstallment(false);
            }
            
            

        } else {
            setInstallments({ installments: [] });
            setIsGenerateInstallment(false);
        }
    }

    async function calcAvailableMoney(accountBankId: number, setLoading: any, setAvailableMoney: any) {
        setLoading(true);

        var totalValueAccount = await getAccountBalance(accountBankId);
        setAvailableMoney(totalValueAccount);
        
        setLoading(false);
    }

    const onSubmit = async (dataForm: any) => {
        try {
            setIsSubmit(true);

            const balanceFromAccount = await getAccountBalance(fromAccount);
            const today = new Date().toISOString().split('T')[0];

            if (formatToFloat(amount) > balanceFromAccount) {
                setActiveTab("detalhes-receita");
                setIsSubmit(false);
                setMsgError(`Não é possivel transferir mais que ${formatCurrency(balanceFromAccount)} da conta origem!`);
                setShowModalError(true);
                return false;
            }

            if (!inputsVerify(dataForm)) return;

            const fromBank = await api.get(`/accountBank/${parseInt(fromAccount)}`);
            const toBank = await api.get(`/accountBank/${parseInt(toAccount)}`);

            const transferDate = String(dataForm.dueDate).length > 0 ? dataForm.dueDate : null;

            const payData = {
                name: dataForm.name,
                amount: formatToFloat(amount),
                remaining: formatToFloat(amount),
                totalPaid: isPaid ? formatToFloat(amount) : null,
                payedDate: isPaid ? transferDate : null,
                status: "paid",
                bankAccount: String(dataForm.bankAccount).length > 0 ? parseInt(fromAccount) : null,
                nameBank: String(dataForm.bankAccount).length > 0 ? fromBank.data.nameBank : null,
                centerCost: String(dataForm.centerCost).length > 0 ? parseInt(centerCost) : null,
                docNumber: String(dataForm.docNumber).length > 0 ? dataForm.docNumber : null,
                comments: String(dataForm.comments).length > 0 ? dataForm.comments : null,
                barCode: String(dataForm.barCode).length > 0 ? dataForm.barCode : null,
                dueDate: transferDate,
                payment: String(dataForm.payment).length > 0 ? dataForm.payment : null,
                supplier: null,
                issueDate: String(issuanceDate).length > 0 ? issuanceDate : null,
                occurrence: "unica",
                categoryName: String(dataForm.categoryName).length > 0 ? dataForm.categoryName : null,
                dreSubCategoryId: dreSubCategoryExpenseId > 0 ? dreSubCategoryExpenseId : null,
                recordType: "transfer"
            }

            var idPay = 0;
            await api.post("billsToPay", payData).then(async(payResponse) => {
                idPay = payResponse.data.id;
                
                LogService.logRegister({
                    itemId: payResponse.data.id,
                    module: 'Contas a Pagar',
                    itemName: payResponse.data.name + ' - Unitário'
                });
            });

            const receiveData = {
                isPaid,
                name: dataForm.name,
                amount: formatToFloat(amount),
                totalPaid: isPaid ? formatToFloat(amount) : null,
                payedDate: isPaid ? transferDate : null,
                remaining: formatToFloat(amount),
                status: isPaid ? "paid" : "pending",
                bankAccount: String(dataForm.bankAccount).length > 0 ? parseInt(toAccount) : null,
                nameBank: String(dataForm.bankAccount).length > 0 ? toBank.data.nameBank : null,
                categoryName: String(dataForm.categoryName).length > 0 ? dataForm.categoryName : null,
                centerCost: String(dataForm.centerCost).length > 0 ? parseInt(centerCost) : null,
                comments: String(dataForm.comments).length > 0 ? dataForm.comments : null,
                customer: null,
                docNumber: String(dataForm.docNumber).length > 0 ? dataForm.docNumber : null,
                dueDate: transferDate,
                issuanceDate: String(issuanceDate).length > 0 ? issuanceDate : null,
                occurrence: "unica",
                payment: String(dataForm.payment).length > 0 ? dataForm.payment : null,
                dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
                recordType: "transfer",
            }

            await api.post("billsToReceive", receiveData).then(async(receiveResponse) => {
                await api.put(`/billsToReceive/${receiveResponse.data.id}`, { idBillTransfer: idPay});
                await api.put(`/billsToPay/${idPay}`, { idBillTransfer: receiveResponse.data.id});
                
                LogService.logRegister({
                    itemId: receiveResponse.data.id,
                    module: 'Contas a Receber',
                    itemName: receiveResponse.data.name
                });
            });

            history.push("/contas-a-receber");
            setIsSubmit(false);

        } catch(error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    function inputsVerify(dataForm: any) {
        if(!dataForm.name) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Nome da receita é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if (fromAccount == toAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Não é possivel fazer transferência para mesma conta!");
            setShowModalError(true);
            return false;
        }

        if(!fromAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Selecione a conta de origem!");
            setShowModalError(true);
            return false;
        }

        if(!toAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Selecione a conta de destino!");
            setShowModalError(true);
            return false;
        }

        // if(!dataForm.categoryName) {
        //     setIsSubmit(false);
        //     setMsgError("Campo Plano de Contas é obrigatório!");
        //     setShowModalError(true);
        //     return false;
        // }

        if(!dreSubCategoryId) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas de Entrada é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dreSubCategoryExpenseId) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas de Saída é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dataForm.payment) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!dataForm.dueDate) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo data de transferência é obrigatório!");
            setShowModalError(true);
            return false;
        }

        if(!amount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            return false;
        }
        if(Number(amount) <= 0) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            return false;
        }

        return true;
    }

    return (

        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateToAccountBank}
            />

            <form onSubmit={handleSubmit(onSubmit)}
                  className={'makeStyles-container-12'}
                  autoComplete="off"
            >
                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs">
                    <Tab eventKey="detalhes-receita" title="Detalhes da Receita">

                        <div className="row">

                            <div className="col-lg-3">
                                <TextField
                                    {...register("name")}
                                    label="Nome da Receita"
                                    margin="normal"
                                    variant="outlined"
                                    required
                                    size="small"
                                />
                            </div>

                            <div className="col-lg-3 d-flex flex-column align-items-start">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Conta Origem"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={fromAccount}
                                    required
                                    onSelect={(option) => {
                                        setFromAccount(option ? String(option.id) : '');

                                        if (option) {
                                            calcAvailableMoney(option.id, setLoadingExpenseBalance, setExpenseBalance);
                                        } else {
                                            setExpenseBalance(0);
                                        }
                                    }}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!fromAccount) return null;
                                        return loadedOptions.find((option) => option.id === Number(fromAccount)) ?? BankAccountService.getBankAccountById(fromAccount)
                                    }}
                                    renderAddButton={(
                                        <ApiResourceSelectAddButton
                                            label="Adicionar Conta Bancária"
                                            onClick={() => {
                                                setShowModalAddAccountBank(true);
                                                setTypeAccountBankBeingCreated('from');
                                            }}
                                        />
                                    )}
                                />

                                {
                                    fromAccount
                                    ?
                                        loadingExpense
                                        ?
                                            <Spinner animation="border" variant="dark" size="sm" />
                                        :   <p><b>Saldo:</b> {formatCurrency(expenseBalance)}</p>
                                    : <></>
                                }
                            </div>

                            <div className="col-lg-3 d-flex flex-column align-items-start">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Conta Destino"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={toAccount}
                                    required
                                    onSelect={(option) => {
                                        setToAccount(option ? String(option.id) : '');

                                        if (option) {
                                            calcAvailableMoney(option.id, setLoadingRevenueBalance, setRevenueBalance);
                                        } else {
                                            setRevenueBalance(0);
                                        }
                                    }}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!toAccount) return null;
                                        return loadedOptions.find((option) => option.id === Number(toAccount)) ?? BankAccountService.getBankAccountById(toAccount)
                                    }}
                                    renderAddButton={(
                                        <ApiResourceSelectAddButton
                                            label="Adicionar Conta Bancária"
                                            onClick={() => {
                                                setShowModalAddAccountBank(true);
                                                setTypeAccountBankBeingCreated('to');
                                            }}
                                        />
                                    )}
                                />

                                {
                                    toAccount
                                    ?
                                        loadingRevenue
                                        ?
                                            <Spinner animation="border" variant="dark" size="sm" />
                                        :   <p><b>Saldo:</b> {formatCurrency(revenueBalance)}</p>
                                    : <></>
                                }
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    {...register("payment")}
                                    select
                                    label="Forma de Pagamento"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    required
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>

                                    {
                                        paymentOptions.map((payment, index) => (
                                            <MenuItem key={index + 1} value={payment.value}>
                                                {payment.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas de Entrada"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryId}
                                    onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas de Saída"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryExpenseId}
                                    onSelect={(option) => setDreSubCategoryExpenseId(option?.id ?? 0)}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat
                                    label="Valor"
                                    required
                                    startAdornment="R$"
                                    value={amount}
                                    onChange={(evt) => setAmount(evt.target.value)}
                                />
                            </div>
                            
                            <div className="col-lg-3">
                                <TextField
                                    {...register("dueDate")}
                                    type="date"
                                    label="Data de Transferência"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 d-flex align-items-center">
                            <FormControlLabel
                                label="Pago"
                                className="ml-1"
                                checked={isPaid}
                                onChange={() => setIsPaid(state => !state)}
                                control={
                                    <Checkbox color="primary" disabled/>
                                }
                            />
                        </div>

                    </Tab>

                    <Tab eventKey="detalhes-conta" title="Detalhes da Conta">

                        <div className="row">
                            <div className="col-lg-6">
                                <TextField
                                    {...register("docNumber")}
                                    label="Nº do documento"
                                    className="ml-0"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>

                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    label="Centro de Custos"
                                    getOptionLabel={(option: CenterCost) => option.name}
                                    value={centerCost}
                                    onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!centerCost) return null;
                                        return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                    }}
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-5">
                                <label htmlFor="formFile" className="mt-3">Anexo</label>
                                <input className="form-control" type="file" id="formFile"/>
                                <label htmlFor="formFile" className="mt-3">
                                    Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.
                                </label>
                            </div>
                        </div>

                        <TextField
                            { ...register("comments") }
                            label="Observações"
                            multiline
                            rows="2"
                            className="ml-0"
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </Tab>

                </Tabs>

                <div className='col-lg-6 mt-15 d-flex flex-row'>

                    <Button
                        type='submit'
                        className='mr-3'
                        variant="primary"
                        disabled={isSubmit}
                    >

                        {isSubmit ? <>

                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>

                        </> : <>

                            <span>
                                Salvar
                            </span>

                        </>}


                    </Button>

                    <Link href='/contas-a-receber' className='btn btn-secondary'>

                        Cancelar

                    </Link>

                </div>

            </form>
        </div>
    );
}
