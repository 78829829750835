import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, IconButton, makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import { Button, Modal, OverlayTrigger, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { formatCurrency, formatToFloat } from '../utils/formatCurrency';
import { getSituationText } from '../utils/getSituationText';
import { parseNumber } from '../utils/parseNumber';
import { paymentOptions } from '../utils/paymentOptions';
import { NumericFormat } from './NumericFormat';
import { partialPayments } from './ListWithModalChangeSituation';
import { useSelector } from 'react-redux';
import ApiResourceSelect from './ApiResourceSelect';
import BankAccountService from '../services/BankAccountService';
import UserService from '../services/UserService';
import { extraPermissionEnum, menuIdEnum } from '../services/PermissionMenuService';
import BillToPayService from '../services/BillToPayService';
import BillToReceiveService from '../services/BillToReceiveService';
import { useCompanyBranch } from '../hooks/companyBranch';
import { BankAccount } from '../types/BankAccount';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    btlr: {
        borderTopLeftRadius: 0
    },
    absoluteNav: {
        position: "absolute",
        top: "-50px",
        left: 0,
        background: "#fff",
        borderRadius: "0.42rem 0.42rem 0px 0px",
        height: "50px",
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type partialPaymentsView = partialPayments & {
    bankAccountName?: string;
}

export type ModalChangeBillSituationProps = {
    isSituationModalOpen: boolean;
    handleCloseModal: () => void;
    viewOnly: boolean;
    situationModalTitle: string;
    billsToPay: boolean;
    name: string;
    status?: string;
    supplier: string;
    dueDate: string;
    amount: number;
    remaining: number;
    paymentDate: string;
    setPaymentDate: React.Dispatch<React.SetStateAction<string>>;
    payment: string;
    setPayment: React.Dispatch<React.SetStateAction<string>>;
    bankAccount: string;
    handleChangeBankAccount: (bankAccount: BankAccount | null | undefined) => void | Promise<void>;
    recordType?: string;
    valueToPay: string;
    setValueToPay: React.Dispatch<React.SetStateAction<string>>;
    fee?: string;
    setFee: React.Dispatch<React.SetStateAction<string>>;
    discount: string;
    setDiscount: React.Dispatch<React.SetStateAction<string>>;
    tax: string;
    setTax: React.Dispatch<React.SetStateAction<string>>;
    acc: string;
    setAcc: React.Dispatch<React.SetStateAction<string>>;
    total: number;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    writeOffValue: number;
    partialPayments: string;
    isPaid: boolean;
    setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
    handleChangeSituation: () => any;
    isSubmitting?: boolean;
}

export default function ModalChangeBillSituation({
    isSituationModalOpen,
    handleCloseModal,
    viewOnly,
    situationModalTitle,
    billsToPay,
    name,
    status,
    supplier,
    dueDate,
    amount,
    remaining,
    paymentDate,
    setPaymentDate,
    payment,
    setPayment,
    bankAccount,
    handleChangeBankAccount,
    recordType,
    valueToPay,
    setValueToPay,
    fee,
    setFee,
    discount,
    setDiscount,
    tax,
    setTax,
    acc,
    setAcc,
    handleChangeSituation,
    total,
    setTotal,
    writeOffValue,
    partialPayments,
    isPaid,
    setIsPaid,
    isSubmitting,
}: ModalChangeBillSituationProps) {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();

    const classes = useStyles();

    const [activeTab, setActiveTab] = useState("baixa-manual");
    const [pagesPartial, setPagesPartial] = useState(0);
    const [rowsPerPagePartial, setRowsPerPagePartial] = useState(10);

    const [partialJson, setPartialJson] = useState<partialPaymentsView[]>([]);
    const [totalWriteOff, setTotalWriteOff] = useState(0);
    const [totalFeeValue, setTotalFeeValue] = useState(0);
    const [totalDiscountValue, setTotalDiscountValue] = useState(0);
    const [totalTaxValue, setTotalTaxValue] = useState(0);
    const [totalAccValue, setTotalAccValue] = useState(0);
    const [totalPartials, setTotalPartials] = useState(0);

    useEffect(() => {
        async function mapPartialPayments() {
            const partials: partialPayments[] = partialPayments ? JSON.parse(partialPayments) : [];

            const mapped: partialPaymentsView[] = await Promise.all(
                partials.map(async (partial) => {
                    const bank = await BankAccountService.getBankAccountById(partial.bankAccount);
                    return {
                        ...partial,
                        bankAccountName: bank ? `${bank.nameBank} - ${bank.name}` : '',
                    };
                })
            );

            setPartialJson(mapped);
        }

        mapPartialPayments();
    }, [partialPayments]);

    useEffect(() => {
        var auxTotalWriteOff = 0;
        var auxTotalFeeValue = 0;
        var auxTotalDiscountValue = 0;
        var auxTotalTaxValue = 0;
        var auxTotalAccValue = 0;
        var auxTotalPartials = 0;
        
        if (partialJson.length) {
            for (var partial of partialJson) {
                auxTotalWriteOff += partial.writeOffValue;
                auxTotalFeeValue += partial.feeValue;
                auxTotalDiscountValue += partial.discountValue;
                auxTotalTaxValue += partial.taxValue;
                auxTotalAccValue += partial.accValue;
                auxTotalPartials += partial.totalPaid;
            }
        }

        setTotalWriteOff(auxTotalWriteOff);
        setTotalFeeValue(auxTotalFeeValue);
        setTotalDiscountValue(auxTotalDiscountValue);
        setTotalTaxValue(auxTotalTaxValue);
        setTotalAccValue(auxTotalAccValue);
        setTotalPartials(auxTotalPartials);

    }, [partialJson]);

    const handleChangePagePartial = useCallback((next: number) => {
        setPagesPartial(next);
    }, []);

    const handleRowsPerPagePartial = useCallback((value: number) => {
        setPagesPartial(0);
        setRowsPerPagePartial(value);
    }, []);

    // Se valor a ser pago for menor que valor restante
    const handleSetValueToPay = useCallback((value: string) => {
        const valueNumber = formatToFloat(value);

        if (valueNumber > remaining) {
            setValueToPay("");
            alert("Valor a ser pago não pode ser maior que o valor total");

            // setIsSituationModalOpen(false);
        } else {
            setValueToPay(value);
            setIsPaid(valueNumber == remaining ? true : false);
        }
    }, [total, remaining]);

    const handleChangeSituationWrapper = useCallback(async () => {
        const hasPermission = billsToPay
            ? await BillToPayService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate, selectedCompany)
            : await BillToReceiveService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate, selectedCompany);

        if (!hasPermission) {
            alert("O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema.");
            return;
        }

        handleChangeSituation();
    }, [paymentDate, valueToPay, total, handleChangeSituation]);

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"

            size="xl"
            show={isSituationModalOpen}
            onHide={handleCloseModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    { viewOnly === true ? situationModalTitle?.replaceAll('Liquidar', 'Visualizar') : situationModalTitle }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="newProductWrapper">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <h6>{billsToPay ? 'Nome da despesa' : 'Nome do crédito'}</h6>
                            <p>{name}</p>
                        </div>

                        <div className="col-lg-4">
                            <h6>Beneficiário</h6>
                            <p>{supplier}</p>
                        </div>

                        <div className="col-lg-4">
                            <h6>Status</h6>
                            <p>{getSituationText(status)}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <h6>Vencimento</h6>
                            <p>{dueDate}</p>
                        </div>

                        <div className="col-lg-4">
                            <h6>{billsToPay ? 'Valor da despesa' : 'Valor do crédito'}</h6>
                            <p>{formatCurrency(amount)}</p>
                        </div>

                        <div className="col-lg-4">
                            <h6>Valor em aberto</h6>
                            <p>{formatCurrency(remaining)}</p>
                        </div>
                    </div>
                </div>
                
                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs">
                    {
                        partialJson.length
                        ?
                        <Tab eventKey="resumo" title="Resumo de pagamento" className="mb-15">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Data</TableCell>
                                        <TableCell>Valor baixa</TableCell>
                                        <TableCell>Juros</TableCell>
                                        <TableCell>Desconto</TableCell>
                                        <TableCell>Taxa</TableCell>
                                        <TableCell>Acréscimo</TableCell>
                                        <TableCell>Valor pago</TableCell>
                                        <TableCell>Conta bancária</TableCell>
                                        <TableCell>Forma pag.</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        partialJson.slice(pagesPartial * rowsPerPagePartial, pagesPartial * rowsPerPagePartial + rowsPerPagePartial).map((value, index: number) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{value.payedDate.split('-').reverse().join('/')}</TableCell>
                                                    <TableCell>{formatCurrency(value.writeOffValue)}</TableCell>
                                                    <TableCell>{formatCurrency(value.feeValue)}</TableCell>
                                                    <TableCell>{formatCurrency(value.discountValue)}</TableCell>
                                                    <TableCell>{formatCurrency(value.taxValue)}</TableCell>
                                                    <TableCell>{formatCurrency(value.accValue)}</TableCell>
                                                    <TableCell>{formatCurrency(value.totalPaid)}</TableCell>
                                                    <TableCell>{value.bankAccountName ?? ''}</TableCell>
                                                    <TableCell>{value.payment}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }                                            
                                </TableBody>
                            </Table>

                            <TablePagination
                                labelRowsPerPage="Linhas por página"
                                page={pagesPartial}
                                component="div"
                                count={partialJson.length}
                                rowsPerPage={rowsPerPagePartial}
                                rowsPerPageOptions={[5, 10, 25]}
                                backIconButtonProps={{
                                    'aria-label': 'Página Anterior',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Próxima Página',
                                }}
                                onChangePage={(_, next) => handleChangePagePartial(next)}
                                onChangeRowsPerPage={(evt) => handleRowsPerPagePartial(Number(evt.target.value))}
                            />

                            <div className="row mt-3 pt-3 border-top col-lg-12 d-flex justify-content-end">
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Total baixa
                                    </strong>
                                    <p>{formatCurrency(totalWriteOff)}</p>
                                </div>
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Juros
                                    </strong>
                                    <p>{formatCurrency(totalFeeValue)}</p>
                                </div>
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Desconto
                                    </strong>
                                    <p>{formatCurrency(totalDiscountValue)}</p>
                                </div>
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Taxa
                                    </strong>
                                    <p>{formatCurrency(totalTaxValue)}</p>
                                </div>
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Acréscimo
                                    </strong>
                                    <p>{formatCurrency(totalAccValue)}</p>
                                </div>
                                <div className="col-lg-2 pr-0 d-flex flex-column align-items-end justify-content-center">
                                    <strong>
                                        Total pago
                                    </strong>
                                    <p>{formatCurrency(totalPartials)}</p>
                                </div>
                            </div>
                        </Tab>
                        : <></>
                    }

                    <Tab eventKey="baixa-manual" title="Baixa manual" className="mb-15">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <TextField
                                        label="Data de pagamento"
                                        type="date"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        value={paymentDate}
                                        onChange={(evt) => setPaymentDate(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <ApiResourceSelect
                                        label="Conta bancária"
                                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                        value={bankAccount}
                                        required
                                        disabled={viewOnly || recordType === 'billet' || user.isAccountant == "y" ? true : false}
                                        onSelect={handleChangeBankAccount}
                                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                        getSelectedOption={(loadedOptions) => {
                                            if(!bankAccount) return null;
                                            return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                                        }}
                                        endAdornment={recordType === 'billet' ? (
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={
                                                    <Tooltip id='tooltip-top'>
                                                        Esta conta é do tipo boleto, alterar a conta bancária implica alterar dados cruciais, faça isso na página de editar conta a receber
                                                    </Tooltip>
                                                }
                                            >
                                                <IconButton
                                                    edge="end"
                                                >
                                                    <i className="flaticon-info"></i>
                                                </IconButton>
                                            </OverlayTrigger>
                                        ) : undefined}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <TextField
                                        select
                                        label="Forma de pagamento"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        value={payment}
                                        onChange={(evt) => setPayment(evt.target.value)}
                                        disabled={viewOnly || recordType === 'billet' || user.isAccountant == "y" ? true : false}
                                    >
                                        <MenuItem key='first'>Selecione</MenuItem>
                                        {
                                            paymentOptions.map((payment, index) => {
                                                return (
                                                    <MenuItem key={index} value={payment.value}>
                                                        {payment.value}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <NumericFormat
                                        label="Valor da baixa"
                                        startAdornment="R$"
                                        value={valueToPay}
                                        onChange={(evt) => handleSetValueToPay(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <NumericFormat
                                        label="Juros/Multa"
                                        startAdornment="R$"
                                        value={fee}
                                        onChange={(evt) => setFee(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <NumericFormat
                                        label="Desconto"
                                        startAdornment="R$"
                                        value={discount}
                                        onChange={(evt) => setDiscount(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <NumericFormat
                                        label="Taxa"
                                        startAdornment="R$"
                                        value={tax}
                                        onChange={(evt) => setTax(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <NumericFormat
                                        label="Acréscimo"
                                        startAdornment="R$"
                                        value={acc}
                                        onChange={(evt) => setAcc(evt.target.value)}
                                        disabled={viewOnly || user.isAccountant == "y" ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="row mt-3 pt-3 border-top d-flex justify-content-between">
                                <div className="col-lg-2">
                                    <FormControlLabel
                                        label="Baixar conta"
                                        className="ml-1 mt-1"
                                        checked={isPaid}
                                        // onChange={() => setIsPaid((state) => !state)}
                                        control={
                                            <Checkbox
                                                disabled={user.isAccountant == "y" ? true : false}
                                                color="primary"
                                            />
                                        }
                                    />
                                </div>

                                <div className="col-lg-4 d-flex justify-content-end">
                                    <div className={isPaid ? "col-lg-12 d-flex flex-column align-items-end justify-content-center" : "col-lg-6 d-flex flex-column align-items-end justify-content-center"}>
                                        <strong>
                                            Valor
                                            {billsToPay 
                                            ? status == "paid" ? " pago" : " a pagar" 
                                            : status == "paid" ? " recebido" : " a receber"
                                            }
                                        </strong>
                                        {
                                            partialJson.length 
                                            ?
                                            <p>{formatCurrency(status == "paid" ? totalPartials : total)}</p>
                                            :
                                            <p>{formatCurrency(total)}</p>
                                        }
                                    </div>

                                    {
                                        isPaid
                                        ? <></>
                                        : 
                                        <div className="col-lg-6 pr-0 d-flex flex-column align-items-end justify-content-center">
                                            <strong>
                                                Valor restante
                                            </strong>
                                            <p>{formatCurrency(remaining - writeOffValue)}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
                
            </Modal.Body>

            <Modal.Footer>
                {(viewOnly === false && user.isAccountant == "n") && (
                    <Button
                        variant="primary"
                        onClick={() => handleChangeSituationWrapper()}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : 
                            <>
                                <span>
                                    {isPaid ? "Liquidar conta" : "Lançar pagamento parcial"}
                                </span>
                            </>}
                    </Button>
                )}
                <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    )
}