import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

type ProductsTableParams = {
    products: any[];
    services: any[];
    typeService: string;
};

export function ProductsAndServicesTable({ products, services, typeService }: ProductsTableParams) {
    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Produto/Serviço</TableCell>
                    <TableCell>Tipo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map((product: any) => (
                    <TableRow key={product.id}>
                        <TableCell>{product.INSTANCE.name ?? '-'}</TableCell>
                        <TableCell>{typeService}</TableCell>
                    </TableRow>
                ))}

                {services.map((service: any) => (
                    <TableRow key={service.id}>
                        <TableCell>{service.INSTANCE.name ?? '-'}</TableCell>
                        <TableCell>{typeService}</TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
    );
}