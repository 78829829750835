import React from "react";
import { ValidationBadge } from "./ValidationBadge";

export type NfValidationErrors = {
    type: 'error' | 'warning';
    message: string;
}

type ValidationMessageListProps = {
    errors: NfValidationErrors[];
    warnings?: NfValidationErrors[];
}

export function ValidationMessageList({ errors, warnings }: ValidationMessageListProps) {
    return (
        <>
            {errors.length > 0 ? (
                errors.map((error, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col-12">
                            <ValidationBadge
                                variant={error.type === 'error' ? 'danger' : 'warning'}
                                label={error.message}
                            />
                        </div>
                    </div>
                ))
            ) : (
                <div className="row">
                    <div className="col-12">
                        <ValidationBadge variant="success" label="Preenchido" />
                    </div>
                </div>
            )}
            {warnings && warnings.map((warning, index) => (
                <div className="row mb-2" key={index}>
                    <div className="col-12">
                        <ValidationBadge
                            variant={'warning'}
                            label={warning.message}
                        />
                    </div>
                </div>
            ))}
        </>
    );
}