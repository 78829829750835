import { PriceList } from "../types/PriceList";
import api from "./Api";

class PriceListService {
    async getPriceListById(id: string | number) {
        const priceListResponse = await api.get<PriceList>(`/priceList/${id || '0'}`);

        return priceListResponse.data;
    }

    async getPriceListsFiltered(filters: any) {
        const priceListResponse = await api.get<PriceList[]>('/priceList', { params: { filters } });

        return priceListResponse.data;
    }

    getPriceListTypeText(type: number | string): string {
        switch (Number(type)) {
            case 1:
                return 'Acréscimo';
            case 2:
                return 'Desconto';
            case 3:
                return 'Dinâmica';
        
            default:
                return 'N/A';
        }
    }

    getPriceListStatusText(status: number | string): string {
        switch (Number(status)) {
            case 0:
                return 'Inativo';
            case 1:
                return 'Ativo';
        
            default:
                return 'N/A';
        }
    }
}

export default new PriceListService();