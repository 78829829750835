import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { SellerConsigmentHistory } from "../../../types/SellerConsignment";
import { formatDate } from "../../../utils/dateFormat";
import { getDate } from "../../../utils/dateTimeHelper";

type SellerConsignmentHistoryListProps = {
    history: SellerConsigmentHistory[];
}

export function SellerConsignmentHistoryList({
    history,
}: SellerConsignmentHistoryListProps) {
    const [pagesConsigmentHistory, setPagesConsigmentHistory] = useState(0);
    const [rowsPerPageConsigmentHistory, setRowsPerPageConsigmentHistory] = useState(5);

    const handleChangePageConsigmentHistory = useCallback((next: number) => {
        setPagesConsigmentHistory(next);
    }, []);

    const handleRowsPerPageConsigmentHistory = useCallback((value: number) => {
        setPagesConsigmentHistory(0);
        setRowsPerPageConsigmentHistory(value);
    }, []);

    return (
        <>
            <TableContainer style={{ maxHeight: '75vh' }}>
                <Table stickyHeader className="wrap">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Produto / Quantidade</TableCell>
                            <TableCell>Descrição</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {history.length > 0 && history.map((historyItem, index) => (
                            <TableRow key={index}>
                                <TableCell>{getDate({ initialDate: new Date(historyItem.date) }).fullDateStr}</TableCell>
                                <TableCell>{historyItem.type}</TableCell>
                                <TableCell>{historyItem.product}</TableCell>
                                <TableCell>{historyItem.description}</TableCell>
                            </TableRow>
                        ))}

                        {history.length === 0 && (
                            <TableCell colSpan={99}>
                                <div className="text-center">
                                    Produtos vendidos e alterações de status aparecerão aqui...
                                </div>
                            </TableCell>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                labelRowsPerPage="Linhas por página"
                page={pagesConsigmentHistory}
                component="div"
                count={history.length}
                rowsPerPage={rowsPerPageConsigmentHistory}
                rowsPerPageOptions={[15, 50, 100]}
                backIconButtonProps={{
                    'aria-label': 'Página Anterior',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Próxima Página',
                }}
                onChangePage={(_, next) => handleChangePageConsigmentHistory(next)}
                onChangeRowsPerPage={(evt) => handleRowsPerPageConsigmentHistory(Number(evt.target.value))}
            />
        </>
    );
}