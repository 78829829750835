import api from "./Api";

type ServiceContractEmailData = {
    serviceContractId: string|number;
    customerId: string;
    customerName: string;
    contactName: string;
    subject: string;
    refName: string;
    message: string;
    link?: string;
    attachment?: string;
    companyId: number;
}

class EmailServiceContractService {
    async getAllEmailsByServiceContractId(serviceContractId: number | string) {
        try {
            const { data } = await api.get<any[]>(`email-service-contract/serviceContract/${serviceContractId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendEmail(emails: string[], {
        serviceContractId,
        customerId,
        customerName,
        contactName,
        subject,
        refName,
        message,
        link,
        attachment,
        companyId,
    }: ServiceContractEmailData) {
        const raw = {
            to: emails,
            subject: subject,
            name: refName,
            id: serviceContractId,
            nameContact: contactName,
            nameCustomer: customerName,
            message: message,
            link: link,
            companyId: companyId,
            customerId: customerId,
            attachment: attachment,
        };
    
        await api.post('email-service-contract/send', raw);
    };
}

export default new EmailServiceContractService();