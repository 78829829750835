import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Tabs, Tab, Modal, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Grid, MenuItem, TextField, Popper, FormControlLabel, Checkbox } from '@material-ui/core';


import api from '../../services/Api';
import { Installment } from './Installment';
import { ListProducts } from './ListProducts';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { UnderlineLink } from '../../components/UnderlineLink';
import { ServiceOrderData } from '../../types/ServiceOrderData';
import { useProductsInstallments, Product, InstallmentDetails } from './context';

import '../../style.css';
import LogService from '../../services/LogService';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import { getTypeService } from '../../utils/getTypeService';
import CustomerSelect from '../../components/CustomerSelect';
import { yesOrNo } from '../../types/yesOrNo';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { useStyles } from '../../hooks/styles';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import { GenericObject } from '../../types/GenericObject';
import UploadFiles from '../../components/UploadFiles';
import { deleteMultipleFiles, uploadMultipleFiles } from '../../utils/FilesHelper';

export function EditServiceOrder() {
  const { user } = useSelector((state: any) => state.auth);

  const [activeTab, setActiveTab] = useState("service");

  const [isSubmit, setIsSubmit] = useState(false);
  const [beforeSubmitData, setBeforeSubmitData] = useState<GenericObject>({});
  const [date, setDate] = useState('');
  const [numberSO, setNumberSO] = useState(0);
  const [customerId, setCustomerId] = useState<number>();
  const [customerName, setCustomerName] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState(0);

  const [type, setType] = useState("");
  const [issues, setIssues] = useState("");
  const [report, setReport] = useState("");
  const [seller, setSeller] = useState("");
  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");
  const [warranty, setWarranty] = useState("");
  const [situation, setSituation] = useState("");
  const [equipment, setEquipment] = useState("");
  const [receivement, setReceivement] = useState("");
  const [referencies, setReferencies] = useState("");
  const [startDate, setStartDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [internalComments, setInternalComments] = useState("");
  const [accomplishmentDate, setAccomplishmentDate] = useState("");
  const [accomplishmentTime, setAccomplishmentTime] = useState("");
  const [sellerCommissioning, setSellerCommissioning] = useState(false);

  const [discount, setDiscount] = useState("R$0,00");
  const [totalValue, setTotalValue] = useState("R$0,00");
  const [expenditure, setExpenditure] = useState("R$0,00");
  const [totalValueServices, setTotalValueServices] = useState("R$0,00");
  const [totalValueProducts, setTotalValueProducts] = useState("R$0,00");
  const [msgError, setMsgError] = useState('');
  const [showModalExist, setShowModalExist] = useState(false);

  // Input Verify
  const [customerError, setCustomerError] = useState(false);
  const [sellerError, setSellerError] = useState(false);
  const [installmentsError, setInstallmentsError] = useState(false);
  const [productsError, setProductsError] = useState(true);
  const [servicesError, setServicesError] = useState(true);
  const [accomplishmentDateError, setAccomplishmentDateError] = useState(false);

  const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();

  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    products,
    services,
    installments,
    dispatchProducts,
    dispatchServices,
    dispatchInstallments,
  } = useProductsInstallments();

  // Modal New Customer
  const {
    showModalNewCustomer, setShowModalNewCustomer,
    newCustomerDefaultData, setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
    if (customerId) {
      const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
      setCustomerLimitCreditInformation(limitCreditInfo);
    } else {
      setCustomerLimitCreditInformation(undefined);
    }
  }, []);

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomerId(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product, isService?: boolean } | null>(null);
  const {
    showModalNewProduct, setShowModalNewProduct,
    newProductDefaultData, setNewProductDefaultData,
  } = useNewProductForm();

  const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
    if (!productBeingCreated) return;

    const selectableProduct: SelectableProduct = {
      ...createdProduct as any,
      saleValue: formatToFloat(createdProduct.saleValue),
    };

    const amount = productBeingCreated.product.amount || 1;

    if (productBeingCreated.isService) {
      dispatchServices({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
            totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
            INSTANCE: selectableProduct,
          },
        }
      });
    } else {
      dispatchProducts({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
            totalValue: productBeingCreated.product.totalValue || (selectableProduct.saleValue * amount),
            INSTANCE: selectableProduct,
          },
        }
      });
    }
  }, [productBeingCreated]);

  const handleClickAddProduct = (typedText: string, index: number, product: Product, isService?: boolean) => {
    setNewProductDefaultData({ nameProduct: typedText, selectedType: isService ? 'service' : 'product' });
    setProductBeingCreated({ index, product, isService })
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    const totalService = services.reduce((acc, { totalValue }) => acc + totalValue, 0);
    const totalProduct = products.reduce((acc, { totalValue }) => acc + totalValue, 0);
    const discountNumber = formatToFloat(discount);
    const expenditureNumber = formatToFloat(expenditure);

    const partial = totalService + totalProduct + expenditureNumber;

    if (discountNumber > partial) {
      alert("Desconto não pode ser maior que o valor total");

      setTotalValueServices(formatCurrency(totalService));
      setTotalValueProducts(formatCurrency(totalProduct));

      setDiscount("R$0,00");
      setTotalValue("R$0,00");

      return;
    }

    const total = partial - discountNumber;

    setTotalValueServices(formatCurrency(totalService));
    setTotalValueProducts(formatCurrency(totalProduct));
    setTotalValue(formatCurrency(total));
  }, [services, products, expenditure, discount]);

  useLayoutEffect(() => {
    async function loadData() {
      const { data } = await api.get<ServiceOrderData>(`service-order/${id}`);

      const services: Product[] = JSON.parse(data.services);
      const products: Product[] = JSON.parse(data.products);
      const installments: InstallmentDetails[] = JSON.parse(data.installments);

      dispatchServices({
        type: "INITIAL",
        payload: {
          index: 0,
          products: services,
        }
      });

      dispatchProducts({
        type: "INITIAL",
        payload: {
          index: 0,
          products,
        },
      });

      dispatchInstallments({
        type: "INITIAL",
        payload: {
          installments,
        }
      });

      const discountData = formatCurrency(data.discount);
      const totalValueData = formatCurrency(data.totalValue);
      const expenditureData = formatCurrency(data.expenditure);
      // const totalValueServicesData = formatCurrency(data.discount);
      // const totalValueProductsData = formatCurrency(data.discount);

      setDate(data.date ?? '');
      setType(data.type ?? '');
      setIssues(data.issues ?? '');
      setReport(data.report ?? '');
      setSeller(String(data.seller ?? ''));
      setStatus(data.status ?? '');
      setDiscount(discountData ?? '');
      setNumberSO(data.numberSO ?? '');
      setCustomerId(data.customerId ?? undefined);
      setCustomerName(data.customerName ?? '');
      setComments(data.comments ?? '');
      setWarranty(data.warranty ?? '');
      setSituation(data.situation ?? '');
      setEquipment(data.equipment ?? '');
      setTotalValue(totalValueData);
      setExpenditure(expenditureData);
      setReceivement(data.receivement ?? '');
      setReferencies(data.referencies ?? '');
      setStartDate(data.startDate ?? '');
      setDeliveryDate(data.deliveryDate ?? '');
      setInternalComments(data.internalComments ?? '');
      setInstallmentAmount(data.installmentAmount ?? '');
      setAccomplishmentDate(data.accomplishmentDate ?? '');
      setAccomplishmentTime(data.accomplishmentTime ?? '');
      setSellerCommissioning(data.sellerCommissioning === 'y');
      // setTotalValueServices(totalValueServicesData);
      // setTotalValueProducts(totalValueProductsData);

      setBeforeSubmitData(data);
      handleCustomerChanged(data.customerId ?? undefined, data.customerName);
      setFilesNamesSaved(data.attachments ? JSON.parse(data.attachments) : []);
    }

    loadData();
  }, [])

  function inputsVerify() {
    setSellerError(false);
    setProductsError(false);
    setAccomplishmentDateError(false);
    setCustomerError(false);
    setInstallmentsError(false);
    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      setServiceError(i, service, false);
    }
    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      setProductError(i, product, false);
    }

    if (accomplishmentDate) {
      const dateIsValid = dateIsBetweenRange(accomplishmentDate, '', extractDateStringFromTimestamp());
      if (!dateIsValid) {
        setMsgError("A data de emissão não pode ser maior que o horário atual!");
        setAccomplishmentDateError(true);
        setActiveTab("detailsServiceOrder");
        setShowModalExist(true);
        return false;
      }
    }

    if (!customerId && !customerName) {
      setMsgError("Campo Cliente é obrigatório!")
      setShowModalExist(true);
      setCustomerError(true);
      return false
    }

    if (customerId && customerLimitCreditInformation && beforeSubmitData.customerId !== customerId) {
      const totalOrder = formatToFloat(totalValue ?? 0);

      if ((totalOrder + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
        // Se salvar a OS ultrapassar o limite de crédito do cliente
        setMsgError("Não é possível salvar a ordem de serviço pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
        setShowModalExist(true);
        setCustomerError(true);
        return false;
      }
    }

    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      if (service.totalValue && !service.INSTANCE?.id && !service.name) {
        setServiceError(i, service, true);
        setMsgError('Informe um serviço!');
        setActiveTab('service');
        setShowModalExist(true);
        return false;
      }
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (product.totalValue && !product.INSTANCE?.id && !product.name) {
        setProductError(i, product, true);
        setMsgError('Informe um produto!');
        setActiveTab('products');
        setShowModalExist(true);
        return false;
      }
    }

    if (!installments.length) {
      setActiveTab("payment");
      setMsgError("Informe as parcelas da ordem de serviço!");
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

    if (sumInstallments !== formatToFloat(totalValue)) {
      setActiveTab("payment");
      setMsgError("A soma das parcelas deve concidir com o valor total da ordem de serviço!");
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    // if (!productsError) {
    //   setMsgError("Selecione pelo menos 1(um) Produto!");
    //   setShowModalExist(true);
    //   return false;
    // }

    const hasServices = services.find((service) => service.name || service.INSTANCE.id);

    if (!hasServices) {
      setMsgError("Selecione pelo menos 1(um) Serviço!");
      setShowModalExist(true);
      return false;
    }

    return true

  }

  function setServiceError(index: number, service: Product, error?: boolean) {
    dispatchServices({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...service,
          hasError: error,
        },
      }
    });
  }

  function setProductError(index: number, product: Product, error?: boolean) {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...product,
          hasError: error,
        },
      }
    });
  }

  const isValid = (data: any) => {
    setProductsError(data)
    console.log(productsError)
  };

  const isValid2 = (data: any) => {
    setServicesError(data);
    console.log(servicesError);
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    setIsSubmit(true);

    if (!inputsVerify()) { 
      setIsSubmit(false);
      return;
    }

    try {
      const { data } = await api.get<ServiceOrderData>(`service-order/${id}`);
      const filesRemained = await deleteMultipleFiles(data.attachments ? JSON.parse(data.attachments) : [], filesNamesSaved, setShowModalExist, setMsgError, setIsSubmit);
      let filesPath: any  = [];
      if (filesSelected) {
          filesPath = await uploadMultipleFiles(filesSelected, setShowModalExist, setMsgError, setIsSubmit);                
          if (filesPath === false) {
              return;
          }
      } else {
          filesPath = [];
      }
      
      const raw = {
        // numberSO,
        type,
        date,
        deliveryDate,
        warranty,
        accomplishmentDate,
        accomplishmentTime,
        referencies,
        comments,
        internalComments,
        equipment,
        issues,
        receivement,
        report,
        customerId: customerId ?? null,
        customerName: !customerId ? customerName : null,
        seller: seller ? Number(seller) : null,
        sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
        status: "open",
        installmentAmount,
        discount: formatToFloat(discount),
        totalValue: formatToFloat(totalValue),
        expenditure: formatToFloat(expenditure),

        products: JSON.stringify(products),
        services: JSON.stringify(services),
        installments: JSON.stringify(installments),
        attachments: JSON.stringify([...filesRemained, ...filesPath])
      };

      const response = await api.put(`service-order/${id}`, raw);

      const previousCustomerEntity = await CustomerService.getCustomerById(beforeSubmitData.customerId);
      const newCustomerEntity = await CustomerService.getCustomerById(response.data.customerId);

      LogService.logEdit({
        itemId: response.data.id,
        itemName: 'Serviço',
        module: 'Ordem de Serviço',
        oldData: {
          ...beforeSubmitData,
          customer: CustomerService.getCustomerName(previousCustomerEntity, beforeSubmitData.customerName)
        },
        newData: {
          ...response.data,
          customer: CustomerService.getCustomerName(newCustomerEntity, response.data.customerName)
        },
        formattedFields: {
          accomplishmentDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          deliveryDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          createdAt: (value) => value ? (new Date(value)).toLocaleDateString() : '',
          seller: (value) => SellerService.getNameByIdAsync(value),
          expenditure: (value) => formatNumberToString(value),
          discount: (value) => formatNumberToString(value),
          type: (value) => getTypeService(value)
        },
        fieldsMap: {
          type: 'Tipo da OS',
          customer: 'Cliente',
          seller: 'Vendedor',
          numberSO: 'Nº da OS',
          createdAt: 'Previsão data de início',
          deliveryDate: 'Data de entrega',
          warranty: 'Garantia',
          accomplishmentDate: 'Data da relização',
          accomplishmentTime: 'Hora da realização',
          referencies: 'Referência',
          comments: 'Observações',
          internalComments: 'Observações internas',
          equipment: 'Equipamento',
          issues: 'Problemas',
          receivement: 'Observações do recebimento',
          report: 'Laudo técnico',
          expenditure: 'Valor das despesas',
          discount: 'Valor do desconto',
        },
      });

      setIsSubmit(false);
      history.push("/ordem-de-servico");

    } catch (error) {
      console.log(error);
      setMsgError('Ocorreu um erro ao processar a requisição');
      setShowModalExist(true);
    }
  };

  return (
    <div className="card card-custom gutter-b p-6 newProductWrapper">
      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={(evt) => onSubmit(evt)}>
        <div className="card-body p-0">
          <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

          <Grid container spacing={3}>
            <Grid item lg={3}>
              <TextField
                select
                size="small"
                label="Tipo da Os"
                margin="normal"
                variant="outlined"
                className={classes.textField}
                value={type}
                onChange={(event) => setType(event.target.value)}
                disabled={user.isAccountant == "y" ? true : false}
              >
                <MenuItem value="serviceOrder">
                  Ordem de serviço
                </MenuItem>

                <MenuItem value="budget">
                  Orçamento
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={3}>
              <CustomerSelect
                label="Cliente"
                hasError={customerError}
                isEdit
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerName={customerName}
                setCustomerName={setCustomerName}
                onCustomerChanged={handleCustomerChanged}
                entityId={id}
                entityType='service'
                disabled={user.isAccountant == "y" ? true : false}
                allowIncomplete
                onClickAddCustomer={handleClickAddCustomer}
              />
            </Grid>

            <Grid item lg={3}>
              <ApiResourceSelect
                style={{ width: "100%" }}
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                hasError={sellerError}
                textFieldClass={classes.error}
                onChangeTextField={(e) => setSellerError(e.target.value ? false : true)}
                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                }}
                disabled={user.isAccountant == "y" ? true : false}
              />
            </Grid>

            <Grid item lg>
              <TextField
                disabled
                size="small"
                type='text'
                margin="normal"
                variant="outlined"
                label="Número da OS"
                className={classes.textField}

                value={numberSO}
              />
            </Grid>

            {
              user.isAccountant == "n"
                ?
                <Grid item lg={1} className="d-flex align-items-end">
                  <Button
                    type='submit'
                    className='mb-2'
                    variant="primary"
                    disabled={isSubmit}
                  >

                    {
                        isSubmit 
                        ?
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>

                        </>
                        :
                        <span>Salvar</span>
                    }
                  </Button>
                </Grid>
                : <></>
            }

            {/* <UnderlineLink text="+ cadastrar cliente" onClick={() => dispatchServices({ type: "ADD" })} /> */}
          </Grid>
        </div>

        <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' className="mb-3 mt-7">

          <Tab eventKey='service' title='Serviços'>

            <div className="card-body p-0">
              {services.map((service, index) => (
                <ListProducts
                  key={index}
                  index={index}
                  product={service}
                  service
                  isContent={(contentExists: boolean) => isValid2(contentExists)}
                  isEdit
                  entityId={id}
                  onClickAddProduct={handleClickAddProduct}
                />
              ))
              }

              {
                user.isAccountant == "n"
                  ?
                  <UnderlineLink text="adicionar novo serviço" onClick={() => dispatchServices({ type: "ADD" })} />
                  : <></>
              }
            </div>

          </Tab>

          <Tab eventKey='products' title='Produtos'>
            <div className="card-body p-0">
              {products.map((product, index) => (
                <ListProducts
                  key={index}
                  index={index}
                  product={product}
                  isContent={(contentExists: boolean) => isValid(contentExists)}
                  isEdit
                  entityId={id}
                  onClickAddProduct={handleClickAddProduct}
                />
              ))
              }

              <UnderlineLink text="adicionar novo produto" onClick={() => dispatchProducts({ type: "ADD" })} />
            </div>
          </Tab>

          <Tab eventKey='detailsServiceOrder' title='Detalhes da OS'>
            <div className="card-body pl-4 pr-6">
              <Grid container spacing={6}>
                <Grid item>
                  <TextField
                    disabled
                    type='date'
                    size="small"
                    margin="normal"
                    label="Data da OS"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={date}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type='datetime-local'
                    margin="normal"
                    label="Previsão data de início"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type='datetime-local'
                    margin="normal"
                    label="Data da entrega"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={deliveryDate}
                    onChange={(event) => setDeliveryDate(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type='text'
                    margin="normal"
                    label="Garantia"
                    variant="outlined"
                    className={classes.textField}
                    value={warranty}
                    onChange={(event) => setWarranty(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type='date'
                    margin="normal"
                    variant="outlined"
                    label="Data da realização"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={accomplishmentDate}
                    onChange={(event) => setAccomplishmentDate(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                    error={accomplishmentDateError}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type='text'
                    margin="normal"
                    variant="outlined"
                    label="Hora da realização"
                    className={classes.textField}
                    value={accomplishmentTime}
                    onChange={(event) => setAccomplishmentTime(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    size="small"
                    type='text'
                    margin="normal"
                    variant="outlined"
                    label="Referência"
                    className={classes.textField}
                    value={referencies}
                    onChange={(event) => setReferencies(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações"
                    className={classes.textField}
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações internas"
                    className={classes.textField}
                    value={internalComments}
                    onChange={(event) => setInternalComments(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Equipamento"
                    className={classes.textField}
                    value={equipment}
                    onChange={(event) => setEquipment(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Problemas"
                    className={classes.textField}
                    value={issues}
                    onChange={(event) => setIssues(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    label="Observações do recebimento"
                    value={receivement}
                    onChange={(event) => setReceivement(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    label="Laudo técnico / Diagnóstico"
                    value={report}
                    onChange={(event) => setReport(event.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </Grid>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey='serviceOrder' title='Totais da Ordem de Serviço'>
            <div className="card-body p-6">
              <Grid container spacing={5}>
                <div className="col p-1">
                  <NumericFormat
                    disabled
                    className="inputSmaller"
                    label="Valor dos serviços"
                    value={totalValueServices}
                  />
                </div>
                <div className="col p-1">
                  <NumericFormat
                    disabled
                    className="inputSmaller"
                    label="Valor dos produtos"
                    value={totalValueProducts}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    label="Valor das despesas"
                    className="inputSmaller"
                    value={expenditure}
                    onChange={(evt) => setExpenditure(evt.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    label="Valor do desconto"
                    className="inputSmaller"
                    value={discount}
                    onChange={(evt) => setDiscount(evt.target.value)}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    disabled
                    label="Total"
                    value={totalValue}
                    className="inputSmaller"
                  />
                </div>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey='payment' title='Pagamento'>
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        disabled={user.isAccountant == "y" ? true : false}
                        color="primary"
                        checked={sellerCommissioning}
                        onChange={(e, checked) => setSellerCommissioning(checked)}
                        className={classes.textField}
                      />
                    }
                    label="Emitir comissionamento (vendedor)"
                    labelPlacement="end"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <TextField
                    type="number"
                    margin="normal"
                    variant="outlined"
                    label="Quantidade de Parcelas"
                    size="small"
                    value={installmentAmount}
                    onChange={(evt) => {
                      setInstallmentAmount(Number(evt.target.value))
                      setInstallmentsError(evt.target.value ? false : true)
                    }}
                    error={installmentsError}
                    className={classes.error}
                    disabled={user.isAccountant == "y" ? true : false}
                  />
                </div>

                {
                  user.isAccountant == "n"
                    ?
                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                      <Button
                        variant="primary"
                        size="sm"
                        block
                        onClick={() => dispatchInstallments({
                          type: "GENERATE",
                          payload: {
                            amount: installmentAmount,
                            value: formatToFloat(totalValue),
                          }
                        })}
                      >
                        Gerar parcelas
                      </Button>
                    </div>
                    : <></>
                }

                <div className="row col-lg-12 mt-10">
                  {installments.length > 0 && installments.map((installment, index) => (
                    <Installment
                      key={index}
                      index={index}
                      installment={installment}
                      installmentAmount={installmentAmount}
                    />
                  ))
                  }
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey='attachments' title='Anexo'>
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 ml-2">
                  <UploadFiles
                      label="Contrato/Anexos"
                      filesSelected={filesSelected}
                      setFilesSelected={setFilesSelected}
                      filesNamesSaved={filesNamesSaved}
                      setFilesNamesSaved={setFilesNamesSaved}
                      disabled={user.isAccountant == "y" ? true : false}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </form>
    </div>
  );
}
