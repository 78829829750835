import React, { useState, useLayoutEffect, useCallback, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { saveAs } from 'file-saver';

import api from '../../../services/Api';
import {
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { SellerConsignment, SellerConsignmentStatus } from '../../../types/SellerConsignment';
import { formatDate } from '../../../utils/dateFormat';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import { Seller } from '../../../types/Seller';
import SellerService from '../../../services/SellerService';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import SellerConsignmentService from '../../../services/SellerConsignmentService';
import { NumericFormat } from '../../../components/NumericFormat';
import { BsVariant } from '../../../types/BsVariant';
import { pdf } from '@react-pdf/renderer';
import PdfConsignment from './PdfConsignment/PdfConsignment';
import usePdfConsignment from './PdfConsignment/usePdfConsignment';
import ModalConfirm from '../../../components/ModalConfirm';

type Filters = {
    searchQuery: string;
    situation: string;
    minValue: number;
    maxValue: number;
    minQuantity: number;
    maxQuantity: number;
    initialCreatedDate: string;
    finalCreatedDate: string;
    minStartDate: string;
    maxStartDate: string;
    minFinalDate: string;
    maxFinalDate: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'Nº' },
    { reference: 'description', value: 'Descrição' },
    { reference: 'createdDate', value: 'Data de Criação' },
    { reference: 'finalDate', value: 'Data final' },
    { reference: 'totalQuantityProducts', value: 'Qtde. Total dos Produtos' },
    { reference: 'totalValueProducts', value: 'Valor Total dos Produtos' },
    { reference: 'situation', value: 'Situação', situation: true, notSortable: true },
];

export function ListConsignment() {
    const { user } = useSelector((state: any) => state.auth);

    const { sellerId } = useParams<{ sellerId: string }>();

    const [seller, setSeller] = useState<Seller>();
    const [consignments, setConsignments] = useState<SellerConsignment[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalConsignments, setCountTotalConsignments] = useState(0);

    const [showModalConfirmReturn, setShowModalConfirmReturn] = useState(false);
    const [consignmentIdToReturn, setConsignmentIdToReturn] = useState(0);

    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const [situation, setSituation] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [minQuantity, setMinQuantity] = useState("");
    const [maxQuantity, setMaxQuantity] = useState("");
    const [initialCreatedDate, setInitialCreatedDate] = useState("");
    const [finalCreatedDate, setFinalCreatedDate] = useState("");
    const [minStartDate, setMinStartDate] = useState("");
    const [maxStartDate, setMaxStartDate] = useState("");
    const [minFinalDate, setMinFinalDate] = useState("");
    const [maxFinalDate, setMaxFinalDate] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // PDF
    const { loadPdfData, isLoadingPdfData, pdfData } = usePdfConsignment();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        if (!sellerId) return;

        const { data } = await api.get<{ rows: SellerConsignment[], count: number }>(`sellers/${sellerId}/consignments`, {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setConsignments(rows);
        setCountTotalConsignments(count);
    }, [sellerId]);

    useEffect(() => {
        async function loadSeller() {
            if (!sellerId) {
                return;
            }

            setSeller(await SellerService.getSellerById(sellerId) ?? undefined);
        }

        loadSeller();
    }, [sellerId]);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = consignments;

        aux.forEach((consignment) => {

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(consignment.id), id: true },
                { for: "description", value: consignment.description },
                { for: "createdDate", value: formatDate(consignment.createdDate) },
                { for: "finalDate", value: formatDate(consignment.finalDate) },
                { for: "totalQuantityProducts", value: String(consignment.totalQuantityProducts) },
                { for: "totalValueProducts", value: formatCurrency(consignment.totalValueProducts) },
                { for: "situation", value: SellerConsignmentService.getSituationText(consignment.status) },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [consignments]);

    useEffect(() => {
        async function onPdfDataLoaded() {
            if (!pdfData) {
                return;
            }

            const blob = await pdf((
                <PdfConsignment
                    data={pdfData}
                />
            )).toBlob();
            saveAs(blob, `Consignação ${pdfData.sellerConsignmentId}`);
        }

        onPdfDataLoaded();
    }, [pdfData]);

    const handleClickDownloadPdf = useCallback(async (id: string) => {
        await loadPdfData(Number(id));
    }, [loadPdfData]);

    const returnButtonShowCondition = useCallback((id: string) => {
        const foundRow = consignments.find((row) => row.id === Number(id));

        if (!foundRow) return false;

        return foundRow.status !== SellerConsignmentStatus.returned && foundRow.status !== SellerConsignmentStatus.sold;
    }, [consignments]);

    const handleClickReturnButton = useCallback(async (id: string) => {
        setConsignmentIdToReturn(Number(id));
        setShowModalConfirmReturn(true);
    }, []);

    const handleConfirmReturnConsignment = useCallback(async () => {
        await api.patch(`/sellerConsignment/${consignmentIdToReturn}/statusReturned`);

        setConsignments(consignments.map((consigment) => {
            if (consigment.id === consignmentIdToReturn) {
                return {
                    ...consigment,
                    status: SellerConsignmentStatus.returned,
                };
            }
            return consigment;
        }));

        setConsignmentIdToReturn(0);
        setShowModalConfirmReturn(false);
    }, [consignmentIdToReturn, consignments]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = consignments.filter((consignment) => consignment.id !== Number(id));

            await api.delete(`consignments/${id}`);

            setConsignments([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [consignments]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setMinValue('');
        setMaxValue('');
        setMinQuantity('');
        setMaxQuantity('');
        setInitialCreatedDate('');
        setFinalCreatedDate('');
        setMinStartDate('');
        setMaxStartDate('');
        setMinFinalDate('');
        setMaxFinalDate('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, situation,
            minValue: formatToFloat(minValue),
            maxValue: formatToFloat(maxValue),
            minQuantity: formatToFloat(minQuantity),
            maxQuantity: formatToFloat(maxQuantity),
            initialCreatedDate, finalCreatedDate,
            minStartDate, maxStartDate,
            minFinalDate, maxFinalDate,
        };

        reloadData();
    }, [consignments, searchQuery, searchQuery, situation,
        minValue, maxValue, minQuantity, maxQuantity,
        initialCreatedDate, finalCreatedDate, minStartDate, maxStartDate,
        minFinalDate, maxFinalDate]);

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <ModalConfirm
                showModal={showModalConfirmReturn}
                setShowModal={setShowModalConfirmReturn}
                onConfirm={handleConfirmReturnConsignment}
                confirmText="Tem certeza que deseja devolver os produtos que ainda não foram vendidos?"
                confirmButtonLabel="Devolver"
            />

            <div className="row">
                <div className="col">
                    <h4>
                        Solicitações de Consignação - {SellerService.getName(seller)}
                    </h4>
                </div>
            </div>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Nova Solicitação de Consignação
                            </Button>
                            : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={situation}
                            onChange={(e) => setSituation(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>

                            <MenuItem value={SellerConsignmentStatus.pending}>
                                Em Trâmite
                            </MenuItem>

                            <MenuItem value={SellerConsignmentStatus.returned}>
                                Devolvido
                            </MenuItem>

                            <MenuItem value={SellerConsignmentStatus.late}>
                                Atrasado
                            </MenuItem>

                            <MenuItem value={SellerConsignmentStatus.sold}>
                                Vendido
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Quantidade Total"
                            startAdornment="DE"
                            thousandSeparator={''}
                            decimalScale={0}
                            value={minQuantity}
                            onChange={(evt) => setMinQuantity(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Quantidade Total"
                            startAdornment="ATÉ"
                            thousandSeparator={''}
                            decimalScale={0}
                            value={maxQuantity}
                            onChange={(evt) => setMaxQuantity(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor Total"
                            startAdornment="DE"
                            value={minValue}
                            onChange={(evt) => setMinValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor Total"
                            startAdornment="ATÉ"
                            value={maxValue}
                            onChange={(evt) => setMaxValue(evt.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            size="small"
                            type="date"
                            label="Data de criação"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">DE</InputAdornment>
                                ),
                            }}
                            value={initialCreatedDate}
                            onChange={(e) => setInitialCreatedDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            size="small"
                            type="date"
                            label="Data de criação"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">ATÉ</InputAdornment>
                                ),
                            }}
                            value={finalCreatedDate}
                            onChange={(e) => setFinalCreatedDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            size="small"
                            type="date"
                            label="Data final"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">DE</InputAdornment>
                                ),
                            }}
                            value={minFinalDate}
                            onChange={(e) => setMinFinalDate(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            size="small"
                            type="date"
                            label="Data final"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">ATÉ</InputAdornment>
                                ),
                            }}
                            value={maxFinalDate}
                            onChange={(e) => setMaxFinalDate(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    situation
                    bodyData={bodyData}
                    headData={headData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    onClone={handleClickClone}
                    sortable
                    loadData={loadData}
                    totalCount={countTotalConsignments}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    customButtons={[
                        {
                            class: 'btn-secondary',
                            content: (
                                <>
                                    {isLoadingPdfData && (<Spinner animation="border" size="sm" className="mr-3" />)}
                                    <i className="flaticon2-printer p-0"></i>
                                </>
                            ),
                            variant: BsVariant.SECONDARY,
                            popup: "Imprimir PDF",
                            onClick: handleClickDownloadPdf,
                        },
                        {
                            class: 'btn-light-primary',
                            content: (<i className='p-0 flaticon2-reply'></i>),
                            variant: BsVariant.PRIMARY,
                            popup: "Devolver produtos não vendidos",
                            onClick: handleClickReturnButton,
                            showCondition: returnButtonShowCondition,
                        },
                    ]}
                />
            </div>
        </div>
    );
}