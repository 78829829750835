import axios from "axios";

type SuccessData = {
    status: 'SUCCESS';
    nome: any;
    fantasia: any;
    email: any;
    telefone: any;
    cep: any;
    logradouro: any;
    numero: any;
    bairro: any;
    complemento: any;
    municipio: any;
    uf: any;
}

type ErrorData = {
    status: 'ERROR';
    message: string;
}

export async function cnpjSearch(cnpj: string) {
    if (!cnpj) {
        return {
            status: 'ERROR',
            message: 'Campo CNPJ é obrigatório!'
        } as ErrorData;
    }

    const cnpjFormated = cnpj.replace(/[^0-9]/g, "");

    const response = await axios.get<SuccessData | ErrorData>(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${cnpjFormated}`);

    return response.data;
}