import React, { useState, useEffect, useCallback } from 'react';

import {
    makeStyles,
    TextField,
    MenuItem,
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab
} from "react-bootstrap";

import ModalError from '../../../components/ModalError';
import api from '../../../services/Api';
import { useHistory } from 'react-router';
import { Profile } from '../../../types/Profile';
import { SubscriptionPlan } from '../../../types/SubscriptionPlan';
import { sanitizeInput, SanitizeInputType } from '../../../utils/sanitizeInput';
import CompanyService from '../../../services/CompanyService';
import ModalLimit from '../../../components/ModalLimit';
import useCompanyFormDetails from '../../../components/CompanyForm/CompanyFormDetails/useCompanyFormDetails';
import { CompanyFormDetails } from '../../../components/CompanyForm/CompanyFormDetails';
import StatusService from '../../../services/StatusService';
import ModalConfirm from '../../../components/ModalConfirm';
import { useSelector } from 'react-redux';
import { yesOrNo } from '../../../types/yesOrNo';
import UserService from '../../../services/UserService';
import { cnpjSearch } from '../../../utils/cnpjSearch';
import { ExecuteCreateCompanyPlanFunction } from '../../../components/SubscriptionPlanCheckout';

enum typeIndication {
    MANUAL = "manual",
    LINK = "link",
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function NewIndications() {
    const history = useHistory();

    const classes = useStyles();

    const { user } = useSelector((state: any) => state.auth);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [showModalConfirmCompanyExists, setShowModalConfirmCompanyExists] = useState(false);
    const [companyExistsId, setCompanyExistsId] = useState(0);

    const [canCreateBranch, setCanCreateBranch] = useState(true);
    const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
    const [showModalLimit, setShowModalLimit] = useState(false);

    const [profiles, setProfiles] = useState<Profile[]>([]);

    const [activeTab, setActiveTab] = useState("details");

    const [typePeople, setTypePeople] = useState("legal");
    const [name, setName] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [whoPay, setWhoPay] = useState("company");

    const [nameInvalid, setNameInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);

    /* Plano */
    const [subscriptionPlanCheckoutSubmitted, setSubscriptionPlanCheckoutSubmitted] = useState(false);
    const [chosenSubscriptionPlan, setChosenSubscriptionPlan] = useState<SubscriptionPlan>();
    const [permissions, setPermissions] = useState<number[]>([]);
    const [executeCreateCompanyPlan, setExecuteCreateCompanyPlan] = useState<ExecuteCreateCompanyPlanFunction>();

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitConfirmCompanyExists, setIsSubmitConfirmCompanyExists] = useState(false);

    const  {
        cpf, setCpf,
        cnpj, setCnpj,
        municipalRegistration, setMunicipalRegistration,
        stateRegistration, setStateRegistration,
        taxRegime, setTaxRegime,
        cnae, setCnae,
        rntrc, setRntrc,
        responsible, setResponsible,
        cell, setCell,
        phone, setPhone,
        email, setEmail,
        website, setWebsite,
        zipCode, setZipCode,
        address, setAddress,
        district, setDistrict,
        city, setCity,
        state, setState,
        number, setNumber,
        complement, setComplement,
        cpfInvalid, setCpfInvalid,
        cnpjInvalid, setCnpjInvalid,
        responsibleInvalid, setResponsibleInvalid,
        cellInvalid, setCellInvalid,
        emailInvalid, setEmailInvalid,
        zipCodeInvalid, setZipCodeInvalid,
        addressInvalid, setAddressInvalid,
        numberInvalid, setNumberInvalid,
        districtInvalid, setDistrictInvalid,
        cityInvalid, setCityInvalid,
        stateInvalid, setStateInvalid,
        isSearchingCnpj, setIsSearchingCnpj,
        allStates, setAllStates,

        infoAddress,
        getErrorsCompanyDetailsInputs,
        verifyCnpjExists,
        verifyCpfExists,
    } = useCompanyFormDetails({ typePeople });

    const handleCloseModalLimit = useCallback(() => {
        if(!canCreateBranch) {
            history.push("/empresas-filiais");
        }
    }, [canCreateBranch]);

    function cleanInputs() {
        setName("");
        setCpf("");
        setCorporateName("");
        setCnpj("");
        setMunicipalRegistration("");
        setStateRegistration("");
        setTaxRegime("");
        setCnae("");
        setRntrc("");
    }

    async function CheckCnpj(value: string, type?: string) {
        if (value && !isSearchingCnpj) {
            setIsSearchingCnpj(true);

            const cnpjResponse = await cnpjSearch(value);

            const exists = await verifyCompanyExistsByCnpjOrCpf('CNPJ');

            if (cnpjResponse.status === 'ERROR' && !exists) {
                setMsgError(cnpjResponse.message);
                setShowModalError(true);
                setCnpjInvalid(true);
                setCnpj('');
                setIsSearchingCnpj(false);
                return;
            }

            if (cnpjResponse.status !== 'ERROR') {
                setCorporateName(cnpjResponse.nome || '');
                setName(cnpjResponse.fantasia || '');
                setEmail(cnpjResponse.email || '');
                setPhone(cnpjResponse.telefone || '');
    
                setZipCode(cnpjResponse.cep || '');
                setAddress(cnpjResponse.logradouro || '');
                setDistrict(cnpjResponse.bairro || '');
                setCity(cnpjResponse.municipio || '');
                setState(cnpjResponse.uf || '');
                setNumber(cnpjResponse.numero || '');
                setComplement(cnpjResponse.complemento || '');
            }

            setIsSearchingCnpj(false);
        }
    }

    const verifyCompanyExistsByCnpjOrCpf = useCallback(async (fieldType: 'CNPJ' | 'CPF') => {
        let exists = false;
        let companyExists: any = {};

        if (fieldType === 'CNPJ') {
            exists = !(await verifyCnpjExists());
            const companyResponse = await CompanyService.getCompaniesFiltered({ filters: { cnpj: sanitizeInput(SanitizeInputType.NUMERIC, cnpj) } });
            companyExists = companyResponse[0];
        } else {
            exists = !(await verifyCpfExists());
            const companyResponse = await CompanyService.getCompaniesFiltered({ filters: { cpf: sanitizeInput(SanitizeInputType.NUMERIC, cpf) } });
            companyExists = companyResponse[0];
        }

        if (exists && user) {
            const companyId = Number(companyExists?.id ?? 0);

            const accountantCompanies: number[] = JSON.parse(user.branchesIds);

            if (accountantCompanies.includes(companyId)) {
                setShowModalError(true);
                setMsgError('Você já indicou ou já está vinculado com a empresa informada!');
                setCnpj('');
                setCpf('');
                return true;
            }


            setCompanyExistsId(companyExists?.id ?? 0)
            setShowModalConfirmCompanyExists(true);

            return true;
        }

        return false;

    }, [cnpj, cpf, user])

    const handleBlurCnpjOrCpf = useCallback((fieldType: 'CNPJ' | 'CPF') => {
        if (fieldType === 'CNPJ' && !isSearchingCnpj) {
            CheckCnpj(cnpj);
        } else {
            verifyCompanyExistsByCnpjOrCpf('CPF');
        }
    }, [isSearchingCnpj, cnpj, verifyCompanyExistsByCnpjOrCpf]);

    async function inputsVerify(): Promise<boolean> {
        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setNameInvalid(true);
            return false
        }

        if(typePeople === 'legal') {
            if (!corporateName) {
                setIsSubmit(false)
                setMsgError("Preencha a razão social corretamente")
                setShowModalError(true);
                setCorporateNameInvalid(true);
                return false
            }
        }

        const detailsError = await getErrorsCompanyDetailsInputs();

        if (detailsError) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError(detailsError);
            setShowModalError(true);
            return false;
        }

        const emailUserExists = await UserService.verifyEmailExists(email);
        if(emailUserExists) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("E-mail já existe");
            setShowModalError(true);
            return false;
        }

        const loginUserExists = await UserService.verifyLoginExists(email);
        if(loginUserExists) {
            setActiveTab("access");
            setIsSubmit(false)
            setMsgError("O email digitado não pode ser usado, informe um email diferente");
            setShowModalError(true);
            return false;
        }

        // if (!whoPay) {
        //     setActiveTab("subscriptionPlan");
        //     setIsSubmit(false)
        //     setMsgError("Selecione quem irá pagar o plano");
        //     setShowModalError(true);
        //     return false;
        // }

        // if (whoPay === 'accountant') {    
        //     if (!subscriptionPlanCheckoutSubmitted) {
        //         setActiveTab("subscriptionPlan");
        //         setIsSubmit(false);
        //         setMsgError("Assine um plano para completar o seu cadastro!");
        //         setShowModalError(true);
        //         return false;
        //     }
        // }
        return true;

    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!(await inputsVerify())) return;

        const raw = {
            profileId: 0,
            permissions: '[]',
            isSuper: 'n',
            typePeople: typePeople,
            name: name ? name : null,
            corporateName: corporateName ? corporateName : null,
            cpf: cpf ? sanitizeInput(SanitizeInputType.NUMERIC, cpf) : null,
            cnpj: cnpj ? sanitizeInput(SanitizeInputType.NUMERIC, cnpj) : null,
            stateRegistration: stateRegistration ? stateRegistration : null,
            municipalRegistration: municipalRegistration ? municipalRegistration : null,
            taxRegime: taxRegime ? taxRegime : null,
            cnae: cnae ? cnae : null,
            rntrc: rntrc ? rntrc : null,
            responsible: responsible ? responsible : null,
            cell: cell ? cell : null,
            phone: phone ? phone : null,
            email: email ? email : null,
            website: website ? website : null,
            zipCode: zipCode ? zipCode : null,
            address: address ? address : null,
            number: number ? number : null,
            district: district ? district : null,
            complement: complement ? complement : null,
            city: city ? city : null,
            state: state ? state : null,
        };

        try {
            
            const response = await api.post(`companies`, raw);

            const responsibleSplit = responsible.trim().split(' ');
            const firstname = responsibleSplit.shift();
            const lastname = responsibleSplit.join(' ');

            // Criar Usuario
            const rawUser = {
                companyId: response.data.id,
                permissions: '[]',
                extraMenuPermissions: '[]',
                firstname: firstname,
                lastname: lastname,
                email,
                cell,
                login: email,
                password: "",
                userProfileId: 0,
                isAdmin: 'y',
                isSuper: 'n',
                theme: 1,
                menuTheme: 1,
                menuType: 'top',
                accessRoute: '',
            };
            await api.post(`users`, rawUser);

            // Criando status padrões
            await StatusService.createDefaultStatus(response.data.id);

            // Inserir na tabela de indicações
            const rawIndication = {
                companyId: response.data.id,
                typeIndication: typeIndication.MANUAL,
                whoPay: whoPay
            };
            await api.post(`/indications`, rawIndication);


            setShowModalSuccess(true);
            history.push("/indicacoes");
        } catch (error) {
            console.log('error', error);
        }

        setIsSubmit(false);
    }

    async function onSubmitCompanyExists() {
        setIsSubmitConfirmCompanyExists(true);

        try {
            const rawIndication = {
                companyId: companyExistsId,
                typeIndication: typeIndication.MANUAL,
                whoPay: 'company',
                companyAlreadyExists: yesOrNo.YES,
            };
            await api.post(`/indications`, rawIndication);

            setIsSubmitConfirmCompanyExists(false);
            setShowModalSuccess(true);
            history.push("/indicacoes");
        } catch (error) {
            console.log('error', error);
            setIsSubmitConfirmCompanyExists(false);
        }

    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false); 
                    window.location.href = "/indications";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados criados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/indications";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalLimit
               showModalLimit={showModalLimit}
               setShowModalLimit={setShowModalLimit}
               messages={modalLimitMsg}
               onHide={handleCloseModalLimit}
            />
            <ModalConfirm
                showModal={showModalConfirmCompanyExists}
                setShowModal={setShowModalConfirmCompanyExists}
                confirmText="A empresa que você está tentando adicionar já possui cadastro na Milliontech, será enviado uma solicitação para que a empresa vincule você como contador dela."
                confirmButtonLabel="Confirmar"
                onConfirm={onSubmitCompanyExists}
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            id="outlined-select-currency"
                            // required
                            select
                            size="small"
                            label="Tipo de pessoa*"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={typePeople}
                            onChange={e => { setTypePeople(e.target.value); cleanInputs() }}
                        >
                            <MenuItem key="0" value="physical" onClick={() => setTypePeople("physical")}>
                                Pessoa Física
                            </MenuItem>

                            <MenuItem key="1" value="legal" onClick={() => setTypePeople("legal")}>
                                Pessoa Jurídica
                            </MenuItem>

                        </TextField>
                    </div>

                    {typePeople === 'physical'
                        ?
                        <>
                            <div className="col-6">
                                <TextField
                                    label="Nome*"
                                    // required
                                    size="small"
                                    className={classes.error}
                                    margin="normal"
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameInvalid(e.target.value ? false : true)
                                    }}
                                    error={nameInvalid}

                                />
                            </div>

                            <div className="col-lg-3 d-flex align-items-start justify-content-end">
                                <Button
                                    type='submit'
                                    className='mt-4'
                                    variant="primary"
                                    disabled={isSubmit}
                                >

                                    {isSubmit ? <>

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </> : <>

                                        <span>
                                            Salvar
                                        </span>

                                    </>}


                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-3">
                                <TextField
                                    value={corporateName}
                                    label="Razão social*"
                                    // required
                                    size="small"
                                    className={classes.error}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setCorporateName(e.target.value)
                                        setCorporateNameInvalid(e.target.value ? false : true)
                                    }}
                                    error={corporateNameInvalid}
                                />
                            </div>
                            <div className="col-4">
                                <TextField
                                    value={name}
                                    label="Nome fantasia*"
                                    // required
                                    size="small"
                                    className={classes.error}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameInvalid(e.target.value ? false : true)
                                    }}
                                    error={nameInvalid}
                                />
                            </div>

                            <div className="col-lg-2 d-flex align-items-start justify-content-end">
                                <Button
                                    type='submit'
                                    className='mt-4'
                                    variant="primary"
                                    disabled={isSubmit}
                                >

                                    {isSubmit ? <>

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </> : <>

                                        <span>
                                            Salvar
                                        </span>

                                    </>}


                                </Button>
                            </div>
                        </>
                    }

                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="editcompany-form-tabs">
                    <Tab eventKey="details" title="Dados da Empresa">
                        <CompanyFormDetails
                            typePeople={typePeople}
                            cpf={cpf}
                            setCpf={setCpf}
                            cnpj={cnpj}
                            setCnpj={setCnpj}
                            municipalRegistration={municipalRegistration}
                            setMunicipalRegistration={setMunicipalRegistration}
                            stateRegistration={stateRegistration}
                            setStateRegistration={setStateRegistration}
                            taxRegime={taxRegime}
                            setTaxRegime={setTaxRegime}
                            cnae={cnae}
                            setCnae={setCnae}
                            rntrc={rntrc}
                            setRntrc={setRntrc}
                            responsible={responsible}
                            setResponsible={setResponsible}
                            cell={cell}
                            setCell={setCell}
                            phone={phone}
                            setPhone={setPhone}
                            email={email}
                            setEmail={setEmail}
                            website={website}
                            setWebsite={setWebsite}
                            zipCode={zipCode}
                            setZipCode={setZipCode}
                            address={address}
                            setAddress={setAddress}
                            district={district}
                            setDistrict={setDistrict}
                            city={city}
                            setCity={setCity}
                            state={state}
                            setState={setState}
                            number={number}
                            setNumber={setNumber}
                            complement={complement}
                            setComplement={setComplement}
                            cpfInvalid={cpfInvalid}
                            setCpfInvalid={setCpfInvalid}
                            cnpjInvalid={cnpjInvalid}
                            setCnpjInvalid={setCnpjInvalid}
                            responsibleInvalid={responsibleInvalid}
                            setResponsibleInvalid={setResponsibleInvalid}
                            cellInvalid={cellInvalid}
                            setCellInvalid={setCellInvalid}
                            emailInvalid={emailInvalid}
                            setEmailInvalid={setEmailInvalid}
                            zipCodeInvalid={zipCodeInvalid}
                            setZipCodeInvalid={setZipCodeInvalid}
                            addressInvalid={addressInvalid}
                            setAddressInvalid={setAddressInvalid}
                            numberInvalid={numberInvalid}
                            setNumberInvalid={setNumberInvalid}
                            districtInvalid={districtInvalid}
                            setDistrictInvalid={setDistrictInvalid}
                            cityInvalid={cityInvalid}
                            setCityInvalid={setCityInvalid}
                            stateInvalid={stateInvalid}
                            setStateInvalid={setStateInvalid}
                            allStates={allStates}
                            setAllStates={setAllStates}
                            CheckCnpj={CheckCnpj}
                            infoAddress={infoAddress}
                            onBlurCnpjOrCpf={handleBlurCnpjOrCpf}
                        />
                    </Tab>

                    {/* <Tab eventKey="subscriptionPlan" title="Plano">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Quem pagará o plano*"
                                    size="small"
                                    SelectProps={{
                                        autoWidth: false,
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    value={whoPay}
                                    onChange={e => setWhoPay(e.target.value)}
                                >
                                    <MenuItem key="1" value="accountant">
                                        Contador
                                    </MenuItem>

                                    <MenuItem key="2" value="company">
                                        Empresa
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>

                        {whoPay === 'accountant' && (
                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <h5>Selecione uma das opções de plano abaixo</h5>
                                </div>
                                <div className="col-12">
                                    <SubscriptionPlanSelector
                                        selectedSubscriptionPlan={selectedSubscriptionPlan}
                                        handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
                                    />
                                </div>
                            </div>
                        )}
                        {whoPay === 'company' && (
                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <h5>A empresa indicada receberá a opção para escolher o plano dela</h5>
                                </div>
                            </div>
                        )}
                    </Tab> */}
                </Tabs>
            </form>
        </div>
    );
}