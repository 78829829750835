import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSubheader } from "../../../_metronic/layout";

import api from "../../services/Api";
import axios from "axios";

import {
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    IconButton
} from '@material-ui/core';

import {
    Button,
    Card,
    Modal,
    Spinner,
    Tabs,
    Tab,
} from "react-bootstrap";

import { Autocomplete } from '@material-ui/lab';
import { NumericFormat } from '../../components/NumericFormat';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import logService from '../../services/LogService';
import userService from '../../services/UserService';
import { getTypePeopleText } from '../../utils/getTypePeopleText';
import { formatCpfCnpj } from '../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { formatZipcode } from '../../utils/formatZipcode';
import { getEnabledStatusText } from '../../utils/getEnabledStatusText';
import UserService from '../../services/UserService';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { useStyles } from '../../hooks/styles';
import { yesOrNo } from '../../types/yesOrNo';
import SellerService from '../../services/SellerService';
import { Seller } from '../../types/Seller';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import ModalSuccess from '../../components/ModalSuccess';

type Inputs = {
    typePeople: string,
    cpf: string,
    name: string,
    cnpj: string,
    corporateName: string,
    fantasyName: string,
    zipCode: string,
    address: string,
    number: string,
    district: string,
    complement: string,
    city: string,
    state: string,
    contactPeople: string,
    phone: string,
    extension: string,
    fax: string,
    cell: string,
    email: string,
    webSite: string,
    bank: string,
    agency: string,
    currentAccount: string,
    salary: string,
    sellerCommission: string,
    userId: string,
    situation: string,
    note: string
}

type urlParam = {
    id: string
}

export function EditSeller() {
    const { user } = useSelector((state: any) => state.auth);

    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Detalhes do Vendedor");

    const [isSubmit, setIsSubmit] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [nameInvalid, setNameInvalid] = useState(false);
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
    const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [userIdInvalid, setUserIdInvalid] = useState(false);

    const { id } = useParams<urlParam>();

    const [activeTab, setActiveTab] = useState("details");
    const [typePeople, setTypePeople] = useState("legal");
    const [cpf, setCpf] = useState("");
    const [name, setName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [fantasyName, setFantasyName] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [contactPeople, setContactPeople] = useState("");
    const [phone, setPhone] = useState("");
    const [extension, setExtension] = useState("");
    const [fax, setFax] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [webSite, setWebSite] = useState("");
    const [bank, setBank] = useState("");
    const [agency, setAgency] = useState("");
    const [currentAccount, setCurrentAccount] = useState("");
    const [salary, setSalary] = useState("");
    const [sellerCommission, setSellerCommission] = useState("");
    const [userId, setUserId] = useState("");
    const [isConsignment, setIsConsignment] = useState<yesOrNo>(yesOrNo.NO);
    const [situation, setSituation] = useState("active");
    const [note, setNote] = useState("");

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const [allStates, setAllStates] = useState({ states: [] });

    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [oldzipCode, setOldZipCode] = useState("");
    const [oldaddress, setOldAddress] = useState("");
    const [olddistrict, setOldDistrict] = useState("");
    const [oldcity, setOldCity] = useState("");
    const [oldstate, setOldState] = useState("");

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    useEffect(() => {
        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: statesBrazil.data });
        }

        getStates();
        getSeller();
    }, []);

    async function getSeller() {
        const seller = await SellerService.getSellerById(id);

        if (!seller) return;

        setTypePeople(seller.typePeople ?? 'legal');
        setCpf(seller.cpf ?? '');
        setName(seller.name ?? '');
        setCnpj(seller.cnpj ?? '');
        setCorporateName(seller.corporateName ?? '');
        setFantasyName(seller.fantasyName ?? '');
        setZipCode(seller.zipCode ?? '');
        setAddress(seller.address ?? '');
        setNumber(seller.number ?? '');
        setComplement(seller.complement ?? '');
        setDistrict(seller.district ?? '');
        setCity(seller.city ?? '');
        setState(seller.state ?? '');
        setContactPeople(seller.contactPeople ?? '');
        setPhone(seller.phone ?? '');
        setExtension(seller.extension ?? '');
        setFax(seller.fax ?? '');
        setCell(seller.cell ?? '');
        setEmail(seller.email ?? '');
        setWebSite(seller.webSite ?? '');
        setBank(seller.bank ?? '');
        setAgency(seller.agency ?? '');
        setCurrentAccount(seller.currentAccount ?? '');
        setSalary(seller.salary ?? '');
        setSellerCommission(seller.sellerCommission ?? '');
        setUserId(seller.userId ? String(seller.userId) : '');
        setIsConsignment(seller.isConsignment ?? yesOrNo.NO);
        setSituation(seller.situation ?? 'inactive');
        setNote(seller.note ?? '');

        setBeforeSubmitData(seller);
    }

    function infoAddress(zipCodeCnpj?: string) {
        const checkCep = async () => {
            var zipCodeChanged = "";

            if (zipCodeCnpj) {
                zipCodeChanged = zipCodeCnpj.replace(/[^0-9]/g, "");

            } else {
                zipCodeChanged = zipCode.replace(/[^0-9]/g, "");
            }


            const cep = await axios.get(`https://viacep.com.br/ws/${zipCodeChanged}/json/`);
            setAddress(cep.data.logradouro);
            setDistrict(cep.data.bairro);
            setCity(cep.data.localidade);
            setState(cep.data.uf);
        }
        checkCep();
    }

    async function CheckCnpj(value: string) {
        const cnpjFormated = value.replace(/[^0-9]/g, "");

        var response = await axios.get(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${cnpjFormated}`);

        if (response.data.status === 'ERROR') {
            setMsgError(response.data.message);
            setShowModalExist(true);
            setCnpjInvalid(true);
            setCnpj('');
            return;
        }

        setCorporateName(response.data.nome);
        setFantasyName(response.data.fantasia);

        setZipCode(response.data.cep);
        setAddress(response.data.logradouro);
        setDistrict(response.data.bairro);
        setCity(response.data.municipio);
        setState(response.data.uf);
        setNumber(response.data.numero);
        setComplement(response.data.complemento);

        infoAddress(response.data.cep);
    }

    async function checkUserSellerExist(currentId: string) {
        // if (userId) {
        //     var response = await api.get<Seller[]>(`/sellers/userIdExist/${userId}`);
        //     if (response.data.length > 0 && response.data[0].id !== Number(currentId)) {
        //         return false;
        //     }
        // }

        return true;
    }

    async function checkEmailSellerExist(currentId: string) {
        // if (email) {
        //     var response = await api.get<Seller[]>(`/sellers/emailExist/${email}`);
        //     if (response.data.length > 0 && response.data[0].id !== Number(currentId)) {
        //         return false;
        //     }
        // }

        return true;
    }

    async function checkCpfSellerExist() {
        // if (cpf) {
        //     var cpfFormated = cpf.replace(/[^0-9]/g, "");

        //     var response = await api.get(`/sellers/cpfExist/${cpfFormated}`);

        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setMsgError("Cpf informado já existe!");
        //         setShowModalExist(true);
        //         setCpf("");
        //     }
        // }
    }

    async function checkCnpjSellerExist() {
        // if (cnpj) {
        //     var cnpjFormated = cnpj.replace(/[^0-9]/g, "");

        //     var response = await api.get(`/sellers/cnpjExist/${cnpjFormated}`);

        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setMsgError("Oops, CNPJ já foi cadastrado!");
        //         setShowModalExist(true);
        //         setCnpj("");
        //     }
        // }
        // CheckCnpj(cnpj);
    }

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "phone" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if ("email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    async function inputsVerify(): Promise<boolean> {
        if (typePeople === "physical") {

            if (!cpf || sanitizeInput("cpf", cpf).length !== 11) {
                setMsgError("Preencha o cpf corretamente");
                setCpfInvalid(true);
                return false;
            } else if (!name) {
                setMsgError("Preencha o nome corretamente");
                setNameInvalid(true);
                return false;
            }
        } else if (typePeople === "legal") {

            if (!cnpj || sanitizeInput("cnpj", cnpj).length !== 14) {
                setMsgError("Preencha o CNPJ corretamente");
                setCnpjInvalid(true);
                return false;
            } else if (!corporateName) {
                setMsgError("Preencha a razão social da empresa");
                setCorporateNameInvalid(true);
                return false;
            } else if (!fantasyName) {
                setMsgError("Preencha o nome fantasia da empresa");
                setFantasyNameInvalid(true);
                return false;
            }
        }

        if (!phone || sanitizeInput("phone", phone).length !== 10) {
            setActiveTab("details");
            setMsgError("Preencha o telefone corretamente");
            setPhoneInvalid(true);
            return false;
        }

        if (!email || sanitizeInput("email", email) === "false") {
            setActiveTab("details");
            setMsgError("Preencha o email corretamente");
            setEmailInvalid(true);
            return false;
        }

        // const emailCanBeUsed = await checkEmailSellerExist(id);

        // if (!emailCanBeUsed) {
        //     setMsgError("Este email já está cadastrado!");
        //     setEmailInvalid(true);
        //     return false;
        // }

        if (isConsignment === yesOrNo.YES && !userId) {
            setActiveTab("additional-info");
            setMsgError("Para definir que o vendedor realiza venda consignada é preciso vincular a um usuário!");
            setUserIdInvalid(true);
            return false;
        }

        if (userId) {
            const userCanBeLinked = await checkUserSellerExist(id);
    
            if (!userCanBeLinked) {
                setActiveTab("additional-info");
                setMsgError("Não é possível vincular ao usuário selecionado pois ele já está vinculado à outro vendedor!");
                setUserIdInvalid(true);
                return false;
            }
        }


        return true;

    }

    async function onSubmit(data: Inputs) {
        setIsSubmit(true);

        const isValid = await inputsVerify();

        if (!isValid) {
            setShowModalExist(true);
            setIsSubmit(false);
            return;
        };

        var raw = JSON.stringify({
            "typePeople": typePeople,
            "cpf": cpf ? cpf : null,
            "name": name,
            "cnpj": cnpj ? cnpj : null,
            "corporateName": corporateName,
            "fantasyName": fantasyName,
            "zipCode": zipCode,
            "address": address,
            "number": number,
            "district": district,
            "complement": complement,
            "city": city,
            "state": state,
            "contactPeople": contactPeople,
            "phone": phone,
            "extension": extension,
            "fax": fax,
            "cell": cell,
            "email": email,
            "webSite": webSite,
            "bank": bank,
            "agency": agency,
            "currentAccount": currentAccount,
            "salary": salary,
            "sellerCommission": sellerCommission,
            "userId": userId ? Number(userId) : null,
            "isConsignment": isConsignment,
            "situation": situation,
            "note": note
        });

        try {
            const editResponse = await api.post(`/sellers/${id}`, raw);

            logService.logEdit({
                itemId: editResponse.data.id,
                itemName: editResponse.data.typePeople === 'physical' ? editResponse.data.name : editResponse.data.corporateName,
                module: 'Vendedores',
                oldData: beforeSubmitData,
                newData: editResponse.data,
                formattedFields: {
                    typePeople: (value) => getTypePeopleText(value),
                    cpf: (value) => formatCpfCnpj('CPF', value),
                    cnpj: (value) => formatCpfCnpj('CNPJ', value),
                    phone: (value) => formatPhoneNumber(value),
                    zipCode: (value) => formatZipcode(value),
                    situation: (value) => getEnabledStatusText(value),
                    userId: async (value) => value ? await userService.getNameById(value) : '',
                    isConsignment: async (value) => value ? getYesOrNoText(value) : '',
                },
                fieldsMap: {
                    typePeople: 'Tipo de pessoa',
                    cpf: 'CPF',
                    name: 'Nome',
                    rg: 'RG',
                    cnpj: 'CNPJ',
                    corporateName: 'Razão social',
                    fantasyName: 'Nome fantasia',
                    stateRegistration: 'Inscrição Estadual',
                    registerNational: 'RNTC (ANTT)',
                    number: 'Número',
                    complement: 'Complemento',
                    email: 'E-mail',
                    phone: 'Telefone',
                    note: 'Observações',
                    zipCode: 'CEP',
                    address: 'Endereço',
                    district: 'Bairro',
                    city: 'Cidade',
                    state: 'Estado',
                    contactPeople: 'Pessoa de Contato',
                    extension: 'Ramal',
                    fax: 'Fax',
                    cell: 'celular',
                    webSite: 'Website',
                    bank: 'Banco',
                    agency: 'Agência',
                    currentAccount: 'Conta corrente',
                    salary: 'Salário',
                    sellerCommission: 'Comissão',
                    userId: 'Vinculo usuário',
                    situation: 'Situação',
                    isConsignment: 'Venda consignada',
                },
            });

            setShowModalSuccess(true);
        } catch (error: any) {
            setShowModalExist(true);
            setMsgError('Ocorreu um erro ao processar a requisição');
        }

        setIsSubmit(false);
    }

    function formatToFloat(value: string) {
        if (value.includes(',') && !value.includes('.')) {
            return parseFloat(value.replace(',', '.'));

        } else {
            return parseFloat(value.replace('.', '').replace(',', '.'));
        }
    }

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })

    return (
        <div className="row card card-body pt-2 newProductWrapper">
            <ModalSuccess
                msgModal="Vendedor salvo com sucesso!"
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/vendedores"
            />

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">

                <div className="col-lg-12">
                    <Grid container spacing={3}>
                        <Grid item lg={2}>
                            <TextField
                                size="small"
                                select
                                label="Tipo de pessoa"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                value={typePeople}
                                onChange={(e) => {
                                    setTypePeople(e.target.value);

                                    setCpf("");
                                    setName("");

                                    setCnpj("");
                                    setCorporateName("");
                                    setFantasyName("");
                                }}
                                disabled={user.isAccountant == "y" ? true : false}
                            >
                                <MenuItem key="0" value="legal">
                                    Pessoa Jurídica
                                </MenuItem>

                                <MenuItem key="1" value="physical">
                                    Pessoa Física
                                </MenuItem>

                            </TextField>
                        </Grid>

                        {typePeople === "physical"
                            ?
                            <>
                                <Grid item lg={3}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        format="###.###.###-##"
                                        label="CPF"
                                        required
                                        mask="_"
                                        value={cpf}
                                        onChange={(e) => {
                                            setCpf(e.target.value)
                                            setCpfInvalid(e.target.value ? false : true)
                                        }}
                                        error={cpfInvalid}
                                        onBlur={() => checkCpfSellerExist()}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={6}>
                                    <TextField
                                        size="small"
                                        label="Nome"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={nameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Grid item lg={1} className="d-flex align-items-end justify-content-end">
                                            <Button
                                                type='submit'
                                                className='mb-2'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Salvar
                                                    </span>

                                                </>}


                                            </Button>
                                        </Grid>
                                        : <></>
                                }
                            </>
                            :
                            <>
                                <Grid item lg={3} className="d-flex align-items-center">
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        label="CNPJ"
                                        required
                                        format="##.###.###/####-##"
                                        mask="_"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => CheckCnpj(cnpj)}
                                                    >
                                                        <i className="flaticon-search"></i>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={cnpj}
                                        onChange={(e) => {
                                            setCnpj(e.target.value)
                                            setCnpjInvalid(e.target.value ? false : true)
                                        }}
                                        error={cnpjInvalid}
                                        onBlur={() => checkCnpjSellerExist()}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        value={corporateName}
                                        label="Razão social"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setCorporateName(e.target.value)
                                            setCorporateNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={corporateNameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        value={fantasyName}
                                        label="Nome fantasia"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setFantasyName(e.target.value)
                                            setFantasyNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={fantasyNameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Grid item lg={1} className="d-flex align-items-end justify-content-end">
                                            <Button
                                                type='submit'
                                                className='mb-2'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Salvar
                                                    </span>

                                                </>}


                                            </Button>
                                        </Grid>
                                        : <></>
                                }
                            </>
                        }
                    </Grid>

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(tab: string) => setActiveTab(tab)}
                        id='newproduct-form-tabs'
                        className="mt-3"
                    >
                        <Tab
                            eventKey='details'
                            title="Detalhes"
                        >
                            <Grid container spacing={3} className="mt-2">
                                <Grid item lg={3} className="d-flex justify-content-between align-items-center">
                                    <NumericFormat
                                        margin="normal"
                                        className="mr-3 inputSmaller"
                                        customInput={TextField}
                                        variant="outlined"
                                        format="#####-###"
                                        label="CEP"
                                        mask="_"
                                        value={zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />

                                    {
                                        user.isAccountant == "n"
                                            ?
                                            <Button variant="primary" onClick={() => infoAddress()}><i className="flaticon-search p-0"></i></Button>
                                            : <></>
                                    }
                                </Grid>

                                <Grid item lg={4}>
                                    <TextField
                                        size="small"
                                        label="Endereço"
                                        value={address}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setAddress(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={2}>
                                    <TextField
                                        size="small"
                                        label="Número"
                                        margin="normal"
                                        variant="outlined"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        label="Bairro"
                                        value={district}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setDistrict(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item lg={5}>
                                    <TextField
                                        size="small"
                                        label="Complemento"
                                        margin="normal"
                                        variant="outlined"
                                        value={complement}
                                        onChange={(e) => setComplement(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <TextField
                                        size="small"
                                        label="Cidade"
                                        value={city}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setCity(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3} className="d-flex align-items-center">
                                    <Autocomplete
                                        size="small"
                                        inputValue={state}
                                        defaultValue={{ sigla: state }}
                                        options={allStates.states}
                                        getOptionLabel={({ sigla }) => sigla}
                                        style={{ width: "100%", marginTop: "6px" }}
                                        onInputChange={(event, newInputValue) => {
                                            setState(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small" {...params}
                                            label="Estado"
                                            variant="outlined"
                                        />
                                        }
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={3} className="mt-2">
                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Pessoa de Contato"
                                        margin="normal"
                                        variant="outlined"
                                        value={contactPeople}
                                        onChange={(e) => setContactPeople(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        format="(##) ####-####"
                                        label="Telefone"
                                        required
                                        mask="_"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                            setPhoneInvalid(e.target.value ? false : true)
                                        }}
                                        error={phoneInvalid}
                                        //onBlur={() => checkPhoneSellerExist()}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <TextField
                                        size="small"
                                        label="Ramal"
                                        margin="normal"
                                        variant="outlined"
                                        value={extension}
                                        onChange={(e) => setExtension(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <TextField
                                        size="small"
                                        label="Fax"
                                        margin="normal"
                                        variant="outlined"
                                        value={fax}
                                        onChange={(e) => setFax(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <NumericFormat
                                        className="inputSmaller"
                                        margin="normal"
                                        customInput={TextField}
                                        variant="outlined"
                                        format="(##) 9####-####"
                                        label="Celular"
                                        mask="_"
                                        value={cell}
                                        onChange={(e) => setCell(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="E-mail"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailInvalid(e.target.value ? false : true)
                                        }}
                                        error={emailInvalid}
                                        //onBlur={() => checkEmailCustomerExist()}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Website"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="www.suaempresa.com.br"
                                        value={webSite}
                                        onChange={(e) => setWebSite(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>
                            </Grid>
                        </Tab>

                        <Tab
                            eventKey='bank-info'
                            title="Dados Bancários"
                        >
                            <Card.Body className="p-0 mt-2">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Banco"
                                            margin="normal"
                                            variant="outlined"
                                            value={bank}
                                            onChange={(e) => setBank(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Agência"
                                            margin="normal"
                                            variant="outlined"
                                            value={agency}
                                            onChange={(e) => setAgency(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Conta corrente"
                                            margin="normal"
                                            variant="outlined"
                                            value={currentAccount}
                                            onChange={(e) => setCurrentAccount(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <NumericFormat
                                            label="Salário"
                                            startAdornment="R$"
                                            value={salary}
                                            onChange={(e) => setSalary(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Tab>
                        <Tab
                            eventKey='additional-info'
                            title="Dados Adicionais"
                        >
                            <Card.Body className="p-0 mt-2">
                                <div className="row align-items-center">
                                    <div className="col-lg-3">
                                        <NumericFormat
                                            label="Comissão do Vendedor"
                                            startAdornment="R$"
                                            value={sellerCommission}
                                            onChange={(e) => setSellerCommission(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setSellerCommission("100");
                                                } else {
                                                    setSellerCommission(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-4">
                                        <ApiResourceSelect
                                            label="Vincular ao usuário"
                                            getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                                            value={userId}
                                            hasError={userIdInvalid}
                                            disabled={user.isAccountant == "y" ? true : false}
                                            onSelect={(option) => setUserId(String(option?.id ?? ''))}
                                            apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText, isAccountant: yesOrNo.NO })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!userId) return null;
                                                return loadedOptions.find((option) => option.id === Number(userId)) ?? UserService.getUserById(userId)
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-2">
                                        <FormControlLabel
                                            value="end"
                                            control={(
                                                <Checkbox
                                                    color="primary"
                                                    onChange={e => setIsConsignment(e.target.checked ? yesOrNo.YES : yesOrNo.NO)}
                                                    checked={isConsignment === yesOrNo.YES}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            )}
                                            label="Venda consignada"
                                            labelPlacement="end"
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            select
                                            label="Situação"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={situation}
                                            onChange={(e) => setSituation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="1" value="active">
                                                Ativo
                                            </MenuItem>

                                            <MenuItem key="0" value="inactive">
                                                Inativo
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className="col-lg-12">
                                        <TextField
                                            size="small"
                                            label="Observações"
                                            multiline
                                            rows="4"
                                            margin="normal"
                                            variant="outlined"
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Tab>

                    </Tabs>

                </div>

            </form>
        </div>
    );
}