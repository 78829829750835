import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListRecurringExpenses } from '../pages/RecurringExpenses/List-RecurringExpenses';
import { EditRecurringExpenses } from '../pages/RecurringExpenses/Edit-RecurringExpenses';
import { NewRecurringExpenses } from '../pages/RecurringExpenses/New-RecurringExpenses';

import { useSubheader } from '../../_metronic/layout';

export function RecurringExpenses() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Despesas Recorrentes");
    
    return (
        <Switch>
            <Route path="/despesas-recorrentes/adicionar" component={NewRecurringExpenses}/>
            <Route path="/despesas-recorrentes/duplicar/:idToClone" component={NewRecurringExpenses}/>
            <Route path="/despesas-recorrentes/:id" component={EditRecurringExpenses}/>
            <Route path="/despesas-recorrentes" component={ListRecurringExpenses}/>
        </Switch>
    );
}