import React from "react";
import { CustomerLimitCreditInformation } from "../types/CustomerLimitCreditInformation";
import { formatCurrency } from "../utils/formatCurrency";
import ApiResourceSelect from "./ApiResourceSelect";
import { StockLocations } from "../types/StockLocations";
import StockLocationService from "../services/StockLocationService";

type SellerConsignmentStockLocationSelectorProps = {
    stockLocationId: number;
    setStockLocationId: React.Dispatch<React.SetStateAction<number>>;
}

export function SellerConsignmentStockLocationSelector({ 
    stockLocationId,
    setStockLocationId,
 }: SellerConsignmentStockLocationSelectorProps) {
    return (
        <div className="row">
            <div className="col bg-warning-o-30 p-5 mb-3">
                <h4>
                    Você está definido como um vendedor consignado no sistema, selecione o local de estoque de origem para os produtos deste pedido de venda
                </h4>
                <ApiResourceSelect
                    style={{ width: '100%' }}
                    label="Local de estoque *"
                    getOptionLabel={(option: StockLocations) => option.name}
                    value={stockLocationId}
                    onSelect={(option) => setStockLocationId(option?.id ?? 0)}
                    apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                    getSelectedOption={(loadedOptions) => {
                        if (!stockLocationId) return null;
                        return loadedOptions.find((option) => option.id === stockLocationId) ?? StockLocationService.getStockLocationById(stockLocationId)
                    }}
                />
            </div>
        </div>
    );
}