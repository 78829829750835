import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Link,
    Grid
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import logService from '../../services/LogService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
    description: string,
}

type subCategory = {
    id: number,
    uuidCategory: string,
    nameSubCategory: string,
}

type urlParam = {
    id: string
}

export function EditCategoryProduct() {
    const { user } = useSelector((state: any) => state.auth);
    
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Categoria de Produto");

    const [nameInvalid, setNameInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [categoryName, setCategoryName] = useState("");
    const [description, setDescription] = useState("");
    const [uuidCategory, setUuidCategory] = useState("");
    const { id } = useParams<urlParam>();

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const [subCategories, setSubCategories] = useState({
        list: [
            {
                id: 0,
                nameSubCategory: "",
            }
        ]
    });

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    useEffect(() => {
        getCategoryProduct();
    }, []);

    async function getCategoryProduct () {
        var category = await api.get(`/categoryproduct/${id}`);

        const uuidCategoryQuick = category.data.uuidCategory;

        setCategoryName(category.data.nameCategory);
        setDescription(category.data.description);
        setUuidCategory(uuidCategoryQuick);

        setBeforeSubmitData(category.data);

        var subCategory = await api.get(`/subcategoryproduct/category/${uuidCategoryQuick}`);
        
        var subCategoriesMapped = subCategory.data.map((subCategoryObj: subCategory) => {
            return {
                id: subCategoryObj.id,
                nameSubCategory: subCategoryObj.nameSubCategory,
            }
        })

        setSubCategories({list: subCategoriesMapped});
    }

    function addSubCategory() {
        var subCategoriesList = subCategories.list;

        subCategoriesList.push({
            id: 0,
            nameSubCategory: "",
        });

        setSubCategories({ list: subCategoriesList });
    }
  
    function deleteSubCategory(index: number, idSubCategory: number) {
        api.delete(`/subcategoryproduct/${idSubCategory}`);

        var subCategoriesList = subCategories.list;
        subCategoriesList.splice(index, 1);

        setSubCategories({ list: subCategoriesList });
    }

    function subCategoriesVerify(): boolean {
        var subCategoriesList = subCategories.list;

        if (subCategoriesList.length) {
            for (let index = 0; index < subCategoriesList.length; index++) {
               if (!subCategoriesList[index].nameSubCategory) {
                    setIsSubmit(false)
                    setMsgError("Preencha o nome da sub categoria corretamente")
                    setShowModalExist(true);

                    return false

                }
            }
        }
        return true

    }

    function inputsVerify(): boolean {

        if (!categoryName) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da categoria corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
            "nameCategory": categoryName,
            "description": description,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        const editResponse = await api.post(`/categoryproduct/${id}`, raw);

        logService.logEdit({
            itemId: editResponse.data.id,
            itemName: editResponse.data.nameCategory,
            module: 'Categorias de Produto',
            oldData: beforeSubmitData,
            newData: editResponse.data,
            fieldsMap: {
                nameCategory: 'Nome',
                description: 'Descrição',
            },
        });

        await saveSubCategories(uuidCategory).then(() => {
            window.location.href = "/categorias-produto"                
        });
    }

    async function saveSubCategories(uuidCategory: string) {
        var subCategoryList = subCategories.list;
        subCategoryList.forEach(async (subCategoryObj, index) => {
            if(subCategoryObj.id === 0 && subCategoryObj.nameSubCategory !== "") {

                var rawSubCategory = JSON.stringify({
                    "uuidCategory": uuidCategory,
                    "nameSubCategory": subCategoryObj.nameSubCategory,
                });

                const responseVariation = await api.post("/subcategoryproduct", rawSubCategory);
            }
        })
    }

    return (
        <div className="row card card-body pt-2 newProductWrapper">
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/categorias-produto";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/categorias-produto";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">
                {
                    user.isAccountant == "n"
                    ?
                    <div className='col-lg-12 d-flex justify-content-end p-0 mb-3'>
                        <Button
                            type='submit'
                            className='mr-0 mt-0'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                    </div>
                    : <></>
                }

                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome da Categoria*"
                            margin="normal"
                            variant="outlined"
                            value={categoryName}
                            onChange={(e) => {
                                setCategoryName(e.target.value)
                                setNameInvalid(e.target.value ? false : true)
                            }}
                            error={nameInvalid}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Descrição*"
                            margin="normal"
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    {
                    subCategories.list.map(({ id, nameSubCategory}, index) => {
                        return (
                            <Grid key={index} container spacing={1}>
                                <Grid item lg={6}>
                                    <TextField
                                        size="small"
                                        className={classes.error}
                                        label="Nome da sub categoria"
                                        margin="normal"
                                        variant="outlined"
                                        value={subCategories.list[index].nameSubCategory}
                                        onChange={(e) => {
                                            var subCategoriesList = subCategories.list;
    
                                            subCategoriesList[index].nameSubCategory = e.target.value;
    
                                            setSubCategories({ list: subCategoriesList });
                                        }}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                {
                                    user.isAccountant == "n"
                                    ?
                                    <Grid item lg={2} className="d-flex align-items-center">
                                        <Button size="sm" variant='danger' onClick={() => deleteSubCategory(index, id)}>Excluir</Button>
                                    </Grid>
                                    : <></>
                                }
                            </Grid>
                        )
                    })
                    }

                </Grid>


                {
                    user.isAccountant == "n"
                    ?
                    <div className='col-lg-5 pl-0 mt-5'>
                        <Button variant='primary' className='mr-3' onClick={() => addSubCategory()}>
                            Adicionar sub categoria
                        </Button>
                    </div>
                    : <></>
                }
            </form>
        </div>
    );
}