export function getTypeOrderText(type: string | undefined | null) {
    if (type == "request") {
        return "Pedido de venda";
    } else if (type == "purchase") {
        return "Pedido de compra";
    } else if (type == "service") {
        return "Ordem de serviço";
    } else if (type == "entry") {
        return "Entrada de mercadoria";
    } else if (type == "budget") {
        return "Orçamento";
    } else if (type == "pdv") {
        return "Venda PDV";
    } else {
        return '';
    }
} 