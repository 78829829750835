import React from "react";
import { Button, Spinner } from "react-bootstrap";

type UploadXmlParams = {
    uploadedXmlRef: React.RefObject<HTMLInputElement>;
    isSubmittingFile: boolean;
    handleClickUploadXml: () => void;
};

export default function UploadXml({
    uploadedXmlRef,
    isSubmittingFile,
    handleClickUploadXml,
}: UploadXmlParams) {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12 form-group">
                    <label htmlFor="formFile" className="mt-3">
                        Upload do arquivo .XML
                    </label>
                    <input className="form-control" type="file" accept="text/xml" ref={uploadedXmlRef} />
                </div>
            </div>
            <div className="row">
                <div className='col-lg-6 mb-3 pr-0'>
                    <Button
                        type='button'
                        variant="primary"
                        disabled={isSubmittingFile}
                        onClick={handleClickUploadXml}
                    >
                        {
                            isSubmittingFile ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className='ml-2'>
                                        Aguarde...
                                    </span>
                                </>
                                :
                                <span>Continuar</span>
                        }
                    </Button>
                </div>
            </div>
        </>
    );
}