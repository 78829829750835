import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { NumericFormat } from '../../components/NumericFormat';
import { useProductsInstallments, Product } from './context';
import { Product as SelectableProduct } from '../../services/ProductService';

import '../../style.css';
import { formatToFloat } from '../../utils/formatCurrency';
import { useSelector } from 'react-redux';
import ProductSelect from '../../components/ProductSelect';

const useStyles = makeStyles(theme => ({
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
    }));

interface ListProductsProps {
    index: number;
    product: Product;
    service?: boolean;
    err?: boolean;
    onClickAddProduct?: (typedText: string, index: number, product: Product) => void;
};

export const ListProducts: React.FC<ListProductsProps> = ({
    index,
    service,
    product,
    err,
    onClickAddProduct,
}) => {
    const { user } = useSelector((state: any) => state.auth);

    const {  dispatchServices } = useProductsInstallments();

    const handleChangeProduct = (value: SelectableProduct) => {
        if (service)
            dispatchServices({
                type: "CHANGE",
                payload: {
                    index,
                    product: {
                        amount: 1,
                        INSTANCE: value,
                        totalValue: value.saleValue,
                        unitaryValue: value.saleValue,
                        notes: '',
                    },
                }
            });
    };

    const handleChangeValues = (amount: number, unitaryValue: any, notes: string) => {
        dispatchServices({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    ...product,
                    amount: amount,
                    unitaryValue: formatToFloat(unitaryValue),
                    totalValue: amount * formatToFloat(unitaryValue),
                    notes: notes ?? '',
                }
            }
        })
    } 

    return (
        <div className="newProductWrapper">
            <div className="row col-lg-12">
                <div className="col-lg-4 d-flex align-items-center p-1 ">
                    <div className="mr-2 d-flex justify-content-center align-items-center bg-secondary p-3 mt-2">
                        <label className="text-white m-0">{index + 1}.</label>
                    </div>

                    <ProductSelect
                        label="Serviço *"
                        selectedProduct={product}
                        onChangeProduct={(prod) => prod && handleChangeProduct(prod)}
                        type="service"
                        // hasError={err}
                        disabled={user.isAccountant == "y" ? true : false}
                        hasError={product.hasError}
                        onClickAddProduct={onClickAddProduct ? (typedText) => onClickAddProduct(typedText, index, product) : undefined}
                    />
                </div>

                <div className="col p-1">
                    <TextField
                        label={service ? 'Horas/Qtde.' : 'Qtde.'}
                        type="number"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={product.amount}
                        onChange={
                            (evt) => handleChangeValues(Number(evt.target.value), product.unitaryValue, product.notes)
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                    />
                </div>

                <div className="col p-1">
                    <NumericFormat
                        label="Valor unitário"
                        startAdornment="R$"
                        value={product.unitaryValue}
                        onChange={
                            (evt) => handleChangeValues(Number(product.amount), evt.target.value, product.notes)
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                    />
                </div>

                <div className="col p-1">
                    <NumericFormat
                        label="Valor total*"
                        startAdornment="R$"
                        disabled
                        value={product.totalValue}
                        error={err}
                    />
                </div>

                {
                    (index > 0 && user.isAccountant == "n") && (
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => dispatchServices({
                                type: "DELETE",
                                payload: {
                                    index
                                }
                            })}
                        >
                            <i className="flaticon-delete"></i>
                        </button>
                    )
                }
            </div>
            <div className="row col-lg-12">
                <div className="col-lg-5">
                    <span className="mr-2"></span>
                    <TextField
                        size="small"
                        label="Observação do serviço"
                        margin="normal"
                        variant="outlined"
                        value={product.notes ?? ''}
                        onChange={
                            (evt) => handleChangeValues(Number(product.amount), product.unitaryValue, evt.target.value)
                        }
                        disabled={user.isAccountant == "y" ? true : false}
                    />
                </div>
            </div>
        </div>
    );
};