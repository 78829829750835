import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import api from '../../services/Api';
import {
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import { formatCurrency } from '../../utils/formatCurrency';
import { formatToFloat } from '../../utils/formatCurrency';
import { dateIsBetweenRange, extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import '../../style.css';
import { Search } from '../../components/Search';
import { Collapse, InputAdornment, MenuItem, TextField, Link } from '@material-ui/core';
import { NumericFormat } from '../../components/NumericFormat';
import { Autocomplete } from '@material-ui/lab';
import { paymentOptions } from '../../utils/paymentOptions';
import useBackendLoad from '../../hooks/backendReload';
import { RecurringExpenses } from '../../types/RecurringExpenses';
import RecurringExpenseService from '../../services/RecurringExpenseService';
import CustomerService from '../../services/CustomerService';
import SellerService from '../../services/SellerService';
import { format, parseISO, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { SubCategory } from '../../types/Dre';
import DreCategoryService from '../../services/DreCategoryService';
import { banks } from '../../utils/banks';
import CenterCostService from '../../services/CenterCostService';
import { CenterCost } from '../../types/CenterCost';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import useQueryParams from '../../hooks/queryParams';

type Filters = {
    searchQuery: string;
    valueMin: number;
    valueMax: number;
    dreSubCategoryId: number;
    centerCost: number;
    payment: string;
    dueDateMin: string;
    dueDateMax: string;
    bankAccount: number;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Id" },
    { reference: "description", value: "Descrição" },
    { reference: "name", value: "Beneficiário" },
    { reference: "nextPaymentDate", value: "Data de vencimento" },
    { reference: "generateDate", value: "Próxima Geração" },
    { reference: "value", value: "Valor" },
];

export function ListRecurringExpenses() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [recurringExpenses, setRecurringExpenses] = useState<RecurringExpenses[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalRecurringExpenses, setCountTotalRecurringExpenses] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [centerCost, setCenterCost] = useState(0);
    const [payment, setPayment] = useState("");
    const [dueDateMin, setDueDateMin] = useState("");
    const [dueDateMax, setDueDateMax] = useState("");
    const [bankAccount, setBankAccount] = useState(0);
    const [searchRequested, setSearchRequested] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { getQueryParam } = useQueryParams();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: RecurringExpenses[], count: number }>("recurringExpenses", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        const foundExpenses = await RecurringExpenseService.resolveBillsDueDates(rows);
        
        for (const expense of foundExpenses) {
            if (expense.supplier) {
                expense.supplierName = CustomerService.getCustomerName(expense.customerEntity);
            }
            if (expense.seller) {
                expense.sellerName = SellerService.getName(expense.sellerEntity);
            }

            const generateDateObj = new Date(`${extractDateStringFromTimestamp(expense.nextPaymentDate)} 00:00:00`);
            generateDateObj.setDate(generateDateObj.getDate() - Number(expense.daysBeforeExpire));
            expense.nextGenerateDate = extractDateStringFromTimestamp(generateDateObj.toISOString());
        }

        setCountTotalRecurringExpenses(count);
        setRecurringExpenses(foundExpenses);

    }, []);

    useEffect(() => {
        const bankAccount = getQueryParam('bankAccount');
        if (bankAccount) {
            setBankAccount(Number(bankAccount));
            setAdvancedSearch(true);
            setSearchRequested(true);
        }
    }, [getQueryParam])

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = recurringExpenses;

        // Formatando data
        
        aux.forEach((expense) => {
            const amount = expense.totalPaid ? formatCurrency(expense.totalPaid) : formatCurrency(expense.amount);

            const nextDate = parseISO(extractDateStringFromTimestamp(expense.nextPaymentDate));
            const formatedNextDate = format(nextDate, "dd/MM/yyyy");

            const generateDate = parseISO(extractDateStringFromTimestamp(expense.nextGenerateDate));
			const formatedGenerateDate = format(generateDate, "dd/MM/yyyy");

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(expense.id), id: true },
                { for: "description", value: expense.name},
                { for: "name", value: expense.sellerName || (expense.supplierName || "-") },
                { for: "nextPaymentDate", value: formatedNextDate ?? "-" },
                { for: "generateDate", value: formatedGenerateDate ?? "-" },
                { for: "value", value: amount },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [recurringExpenses]);

    const handleClickEdit = (id: string) => {
        pushHistory(`${pathname}/${id}`);
    };

    const handleClickClone = (id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    };

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            await api.delete(`recurringExpenses/${id}`);

            setRecurringExpenses(prevState => prevState.filter(expense => expense.id !== Number(id)));

        } catch (error) {
            console.log(error);
        }
    }, [recurringExpenses]);


    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const clearSearch = () => {
        setSearchQuery('');
        setDreSubCategoryId(0);
        setPayment('');
        setCenterCost(0);
        setValueMin(0);
        setValueMax(0);
        setDueDateMin("");
        setDueDateMax("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, 
            valueMin, valueMax, dueDateMin, dueDateMax,
            bankAccount, payment, centerCost, dreSubCategoryId,
        };

        reloadData();
    }, [
        recurringExpenses, searchQuery, 
        valueMin, valueMax, dueDateMin, dueDateMax,
        bankAccount, payment, centerCost, dreSubCategoryId,
    ]);

    useEffect(() => {
        if (searchRequested) {
            setSearchRequested(false);

            handleClickSearch();
        }
    }, [searchRequested, handleClickSearch]);

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Adicionar Despesa Recorrente
                        </Button>
                        : <></>
                    }
                    <Link href='/contas-a-pagar' className='btn btn-secondary'>
                        Voltar
                    </Link>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor"
                            startAdornment="DE"
                            value={valueMin}
                            onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor"
                            startAdornment="ATÉ"
                            value={valueMax}
                            onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
                        />
                    </div>
                    <div className="col-lg-4">
                        <ApiResourceSelect
                            label="Plano de Contas"
                            getOptionLabel={(option: SubCategory) => option.name}
                            value={dreSubCategoryId}
                            onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                            getSelectedOption={(loadedOptions) => {
                                if(!dreSubCategoryId) return null;
                                return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                            }}
                        />
                    </div>
                    <div className="col-lg-4">
                        <ApiResourceSelect
                            label="Conta bancária"
                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                            value={bankAccount}
                            onSelect={(option) => setBankAccount(Number(option?.id))}
                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!bankAccount) return null;
                                return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Centro de Custos"
                            getOptionLabel={(option: CenterCost) => option.name}
                            value={centerCost}
                            onSelect={(option) => setCenterCost(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!centerCost) return null;
                                return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <Autocomplete
                            size="small"
                            noOptionsText="Sem opções"
                            options={paymentOptions}
                            value={paymentOptions.find(payOption => payOption.value === payment) ?? null}
                            getOptionLabel={({ value }) => value}
                            onChange={(event, newInputValue) => setPayment(newInputValue?.value ?? '')}
                            renderInput={(params) =>
                                <TextField
                                    size="small"
                                    {...params}
                                    label="Forma de Pagamento"
                                    margin="normal"
                                    variant="outlined"
                                />
                            }
                        />
                    </div>
                    
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMin}
                            onChange={(e) => setDueDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMax}
                            onChange={(e) => setDueDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    recurringExpenses
                    sortable={true}
                    headData={headData}
                    bodyData={bodyData}
                    onEdit={handleClickEdit}
                    onClone={handleClickClone}
                    onDeleteExpense={handleClickDelete}
                    loadData={loadData}
                    totalCount={countTotalRecurringExpenses}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    minWidth={100}
                />
            </div>
        </div>
    );
}
