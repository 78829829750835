import React, { useCallback } from "react";
import { NewProductForm } from "../../components/Product/NewProductForm/NewProductForm";

export function NewProduct() {
   const handleCloseModalLimit = useCallback(() => {
      window.location.href = "/produtos";
   }, []);

   function handleCloseProductCreatedSuccessModal() {
      window.location.href = "/produtos";
   }

   return (
      <NewProductForm
         availableTabs={{
            variation: true,
            productStructure: true,
            nfe: true,
         }}
         onCloseModalLimit={handleCloseModalLimit}
         onCloseProductCreatedSuccessModal={handleCloseProductCreatedSuccessModal}
      />
   );
}