import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import {Workbook, Worksheet} from 'exceljs';
import { saveAs } from 'file-saver';
import ModalError from '../../../components/ModalError';
import { useCompanyBranch } from '../../../hooks/companyBranch';
import api from '../../../services/Api';
import ModalSuccess from '../../../components/ModalSuccess';
import { ChartOfAccounts } from '../../../types/ChartOfAccounts';
import ModalConfirm from '../../../components/ModalConfirm';
import { formatToFloat } from '../../../utils/formatCurrency';

type Filters = {
    searchQuery: string;
}

type responseType = {
    status: string;
    message: string;
}

type Props = {
    setFirstStepCompleted: React.Dispatch<React.SetStateAction<boolean>>
}

export default function FirstStep({setFirstStepCompleted}: Props) {
    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [pageChanged, setPageChanged] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    
    const [searchQuery, setSearchQuery] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgModal, setMsgModal] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [countTotalChartOfAccounts, setCountTotalChartOfAccounts] = useState(0);

    const [showModalConfirmNewImport, setShowModalConfirmNewImport] = useState(false);

    const uploadSheetInputRef = useRef<HTMLInputElement>(null);
    const [chartOfAccounts, setChartOfAccounts] = useState<any[]>([]);
    const [isImporting, setIsImporting] = useState(false);

    const { selectedCompany } = useCompanyBranch();
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
    const filtersRef = useRef<Filters | null>(null);

    const [sortDirection, setSortDirection] = useState<'ASC'|'DESC'>('ASC');
    const [sortReference, setSortReference] = useState('classification');

    const isBackEndPagination = true;

    const handleChangePage = useCallback((next: number) => {
        setPages(next);
        setPageChanged(true);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setPages(0);
        setRowsPerPage(value);
        setPageChanged(true);
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPage, pages, triggerLoad, pageChanged, selectedCompany]);

    useEffect(() => {
        if (selectedCompany) {
            getChartOfAccounts(selectedCompany.id);        
        }
    }, [selectedCompany]);

    const getChartOfAccounts = useCallback(async (selectedCompanyId: number) => {
        const { data } = await api.get<{rows: ChartOfAccounts[], count: number}>(`/chartOfAccounts/all/${selectedCompanyId}`, {
            params: {
                skip: rowsPerPage * pages,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });
        
        const {rows, count} = data;

        setCountTotalChartOfAccounts(count);
        setChartOfAccounts(rows);
    }, [rowsPerPage, pages, sortReference, sortDirection]);

    const handleLoadData = useCallback(async () => {
        if(((pageChanged) || (triggerLoad && setTriggerLoad)) && selectedCompany) {
            setIsLoadingData(true);
            await getChartOfAccounts(selectedCompany.id);
            setIsLoadingData(false);

            if(pageChanged) {
                setPageChanged(false);
            }

            if(triggerLoad && setTriggerLoad) {
                setPages(0);
                setTriggerLoad(false);
            }
        }
    }, [rowsPerPage, pages, triggerLoad, pageChanged, sortDirection, sortReference, selectedCompany]);
    
    
    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
        };

        reloadData();
    }, [searchQuery]);


    const downloadModel = useCallback(async() => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();

        worksheet.columns = [
            { header: 'Código', key: 'code', width: 20, style: { font: {bold: true}} },
            { header: 'Tipo', key: 'type', width: 10, style: { font: {bold: true}} },
            { header: 'Classificação', key: 'classification', width: 32, outlineLevel: 1, style: { font: {bold: true}} },
            { header: 'Descrição', key: 'description', width: 100, outlineLevel: 1, style: { font: {bold: true}} },
            { header: 'Grau', key: 'degree', width: 10, outlineLevel: 1, style: { font: {bold: true}} }
        ];

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'planilha-modelo.xlsx');
    }, []);

    const handleSort = useCallback((reference: string, active: boolean) => {
        if(active) {
            setSortDirection((prevState) => prevState === 'ASC' ? 'DESC' : 'ASC'); 
        }

        setSortReference(reference);

        if(setTriggerLoad) {
            setTriggerLoad(true);
        }
    }, []);

    const validateSheet = useCallback((row, rows) => {
        // VERIFICAÇÕES COLUNA DE CÓDIGO
        if (!row[1]) {
            return {
                status: "error",
                message: "Coluna código deve ser preenchida!",
            };
        }

        if (isNaN(row[1])) {
            return {
                status: "error",
                message: "Coluna código deve conter apenas números!",
            };
        }
        
        var codes: number[] = [];
        rows.eachRow((data: any, rowIndex: number) => {
            if (rowIndex > 1) {
                codes.push(data.values[1]);
            }
        });
        
        var duplicatesCodes = codes.filter((data: any, index: number) => codes.indexOf(data) !== index);
        if (duplicatesCodes.length) {
            return {
                status: "error",
                message: "Coluna código tem números repetidos!",
            };
        }
        // ===================



        // VERIFICAÇÕES COLUNA DE TIPO
        if (!row[2]) {
            return {
                status: "error",
                message: "Coluna tipo deve ser preenchida!",
            };
        }
        
        if (!['A', 'S'].includes(row[2].toUpperCase())) {
            return {
                status: "error",
                message: "Coluna tipo deve ser A ou S!",
            };
        }
        // ===================




        // VERIFICAÇÕES COLUNA CLASSIFICAÇÃO
        if (!row[3]) {
            return {
                status: "error",
                message: "Coluna classificação deve ser preenchida!",
            };
        }
        
        const regexClassification = /((\b[0-9]+)(\.([0-9]+))+)|([0-9]+)/g;
        var classification = row[3].toString().replace(regexClassification, "");
        if (classification.length) {
            return {
                status: "error",
                message: "Coluna classificação deve conter somente números com ou sem pontos!",
            };
        }
        // ===================


        // VERIFICAÇÕES COLUNA DESCRIÇÃO
        if (!row[4]) {
            return {
                status: "error",
                message: "Coluna descrição deve ser preenchida!",
            };
        }
        // ===================


        // VERIFICAÇÕES COLUNA GRAU
        if (!row[5]) {
            return {
                status: "error",
                message: "Coluna grau deve ser preenchida!",
            };
        }
        
        if (isNaN(row[5])) {
            return {
                status: "error",
                message: "Coluna grau deve conter apenas números!",
            };
        }
        // ===================



        return {
            status: "success",
            message: "",
        };
    }, []);

    const verifyImportedSheet = useCallback(async () => {
        if(!selectedCompany) return;

        const { data: sheetAlreadyImported } = await api.get(`/chartOfAccounts/wasSheetImported/${selectedCompany.id}`);

        if(sheetAlreadyImported) {
            setShowModalConfirmNewImport(true);
        } else {
            handleClickImportSheet();
        }
    }, [selectedCompany]);

    const handleClickImportSheet = useCallback(() => {
        setShowModalConfirmNewImport(false);

        if(uploadSheetInputRef.current) {
            if(uploadSheetInputRef.current.files?.length) {
                const emptyData = new DataTransfer();
                uploadSheetInputRef.current.files = emptyData.files;
            }

            uploadSheetInputRef.current.click();
        }
    }, []);

    const importSheet = useCallback((file) => {
        if (file.length && selectedCompany) {
            const workbook = new Workbook();
            const reader = new FileReader();

            reader.readAsArrayBuffer(file[0]);
            reader.onload = () => {
                const buffer: any = reader.result;

                try {
                    workbook.xlsx.load(buffer).then(workbook => {
                        workbook.eachSheet(async(sheet, id) => {

                            var response: responseType = {
                                status: "success",
                                message: ""
                            };
                            sheet.eachRow((row, rowIndex) => {
                                if (rowIndex > 1) {
                                    var responseValidate = validateSheet(row.values, sheet);
        
                                    if (responseValidate.status == "error") {
                                        response = responseValidate;
                                    }
                                }

                            });

                            if (response.status == "success") {
                                setIsImporting(true);
                                await api.delete(`/chartOfAccounts/${selectedCompany.id}`);

                                const chartsOfAccountsToCreateList: any[] = [];
                                
                                sheet.eachRow(async (row: any, rowIndex) => {
                                    if (rowIndex > 1) {
                                        chartsOfAccountsToCreateList.push({
                                            companyId: Number(selectedCompany.id),
                                            code: Number(row.values[1]),
                                            type: row.values[2].toUpperCase(),
                                            classification: row.values[3],
                                            description: row.values[4],
                                            degree: row.values[5] ? formatToFloat(row.values[5]) : 0,
                                        });
                                    }
                                });

                                for (const chartOfAccountsToCreate of chartsOfAccountsToCreateList) {
                                    await api.post('/chartOfAccounts', chartOfAccountsToCreate);
                                }

                                getChartOfAccounts(selectedCompany.id);
                                
                                setIsImporting(false);
                                setMsgModal("Planilha importada com sucesso!");
                                setShowModal(true);
                                setFirstStepCompleted(true);

                            } else {
                                setMsgError(response.message);
                                setShowModalError(true);
                            }
                        })
                    });

                } catch (error) {
                    console.log(error);
                }                
            }

        }
    }, [selectedCompany]);
    
    return (
        <>
            <ModalConfirm
                confirmText="Importar uma nova planilha irá reiniciar TODO o processo de contabilidade, sendo necessário repetir as etapas seguintes do processo, tem certeza que deseja prosseguir com a importação?"
                showModal={showModalConfirmNewImport}
                setShowModal={setShowModalConfirmNewImport}
                onConfirm={handleClickImportSheet}
            />

            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            <div id='firstStep' className='stepper-content'>
                <div className="row d-flex align-items-center col-12">
                    <h3>Importar Plano Contábil</h3>
                </div>

                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 my-3">
                        <input 
                            ref={uploadSheetInputRef}
                            id="uploadSheet"
                            type="file" 
                            accept='.xlsx'
                            style={{ display: "none" }}
                            onChange={(e) => importSheet(e.target.files)}
                            disabled={isImporting}
                        />
                        <Button
                            className="mt-3 mb-2 mr-2"
                            variant="primary"
                            onClick={verifyImportedSheet}
                            disabled={isImporting}
                        >
                            {isImporting ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde Importando Planilha...
                                </span>
                            </> : <>
                                <span>
                                    <i className='flaticon2-paper'></i>
                                    Importar Planilha
                                </span>
                            </>}
                        </Button>
                        <Button
                            type="button"
                            className="mr-2 btn-light-primary"
                            onClick={downloadModel}
                        >
                            <i className='flaticon2-download-1'></i>
                            Baixar modelo
                        </Button>
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={'code' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('code', 'code' === sortReference)}
                                >
                                    Código
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={'type' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('type', 'type' === sortReference)}
                                >
                                    Tipo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={'classification' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('classification', 'classification' === sortReference)}
                                >
                                    Classificação
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={'degree' === sortReference}
                                    direction={sortDirection.toLowerCase() as 'asc'|'desc'}
                                    onClick={() => handleSort('degree', 'degree' === sortReference)}
                                >
                                    Grau
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                            {isLoadingData && (
                                <TableRow>
                                    <TableCell colSpan={99}>
                                        <div className="d-flex justify-content-center">
                                            <Spinner
                                                as="span"
                                                variant="primary"
                                                animation="border"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}

                            {
                                chartOfAccounts.length || triggerLoad
                                ?
                                !isLoadingData && chartOfAccounts.slice(isBackEndPagination ? 0 : pages * rowsPerPage, isBackEndPagination ? chartOfAccounts.length : pages * rowsPerPage + rowsPerPage).map((account: any) => {
                                        return (
                                            <TableRow key={account.id} hover>
                                                <TableCell>{account.code}</TableCell>
                                                <TableCell>{account.type}</TableCell>
                                                <TableCell>{account.classification}</TableCell>
                                                <TableCell>{account.description}</TableCell>
                                                <TableCell>{account.degree}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                : 
                                <TableRow>
                                    <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={5}>Nenhum dado até o momento...</TableCell>
                                </TableRow>
                            }
                    </TableBody>
                </Table>

                <TablePagination
                    labelRowsPerPage="Linhas por página"
                    page={pages}
                    component="div"
                    count={countTotalChartOfAccounts ?? chartOfAccounts.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 50, 100]}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Próxima Página',
                    }}
                    onChangePage={(_, next) => handleChangePage(next)}
                    onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                />
            </div>
        </>
    )
};