import api from "../services/Api";

export async function uploadImage(
    file: File | null, 
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>,
    setMsgError: React.Dispatch<React.SetStateAction<string>>,
    setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<string | null | boolean> {
    let filePath = null;

    if (file) {
        try {
            var formFile = new FormData();
            formFile.append('image', file, file.name);
            const response = await api.post('/image', formFile);
            filePath = response.data;
        } catch(error) {
            console.error(error);
            setShowModalError(true);
            setMsgError("Ocorreu um erro ao realizar o upload!");
            setIsSubmit(true);
            return false;
        }
    }

    return filePath;
}

export async function uploadFile(
    file: File | null, 
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>,
    setMsgError: React.Dispatch<React.SetStateAction<string>>,
    setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<string | null | boolean> {
    let filePath = null;

    if (file) {
        try {
            if (!isSizeFileValid(file)) {
                setShowModalError(true);
                setMsgError("Tamanho do arquivo excede o limite de 8MB!");
                setIsSubmit(false);
                return false;
            }
            
            var formFile = new FormData();
            formFile.append('image', file, file.name);
            const response = await api.post('/image', formFile);
            filePath = response.data;
        } catch(error) {
            console.error(error);
            setShowModalError(true);
            setMsgError("Ocorreu um erro ao realizar o upload!");
            setIsSubmit(false);
            return false;
        }
    }

    return filePath;
}

export async function uploadMultipleFiles(
    files: File[] | null, 
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>,
    setMsgError: React.Dispatch<React.SetStateAction<string>>,
    setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<string[] | null | boolean> {
    let filePath: string[] = [];
    if (files) {
        try {
            for (const file of files) {
                if (!isSizeFileValid(file)) {
                    setShowModalError(true);
                    setMsgError("Tamanho do arquivo excede o limite de 8MB!");
                    setIsSubmit(false);
                    return false;
                }
                
                const formData = new FormData();
                formData.append('file', file, file.name);

                const response = await api.post('/file', formData);
                filePath.push(response.data);
            }

            return filePath;
        } catch (error) {
            console.error(error);
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao realizar o upload!');
            setIsSubmit(false);
            return false;
        }
    } else {
        return null;
    }
}

export async function deleteMultipleFiles(
    files: string[], 
    filesToSave: string[],
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>,
    setMsgError: React.Dispatch<React.SetStateAction<string>>,
    setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>,
) {
    if (files === filesToSave) {
        return filesToSave;
    }

    const filesToRemove = files.filter((file) => !filesToSave.includes(file));
    try {
        for (const filename of filesToRemove) {
            const response = await api.delete('/file', { data: { filename } });
        }

        return filesToSave;

    } catch(error) {
        console.error(error);
        setShowModalError(true);
        setMsgError('Ocorreu um erro ao deletar arquivo!');
        setIsSubmit(false);
        return files;
    }
}

export function humanFileSize(bytes: number, si=false, dp=1): string {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
}

function isSizeFileValid(file: File): boolean {
    const maxSize = 1000000 * 8;

    if (file.size > maxSize) {
        return false;
    }

    return true;
}