import axios from "axios";

type AddressSearchResponse = {
    logradouro: any;
    bairro: any;
    localidade: any;
    uf: any;
}


export async function addressSearch(zipcode: string) {
    const zipcodeFormatted = (zipcode).replace(/[^0-9]/g, "");

    const response = await axios.get<AddressSearchResponse>(`https://viacep.com.br/ws/${zipcodeFormatted}/json/`);

    return response.data;
}