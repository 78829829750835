import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { formatCurrency } from '../../../utils/formatCurrency';
import api from "../../../services/Api";

import { Request } from '../../../types/Request'
import { ListProducts } from './List-Products'

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useBackendLoad from '../../../hooks/backendReload';
import CustomerService from '../../../services/CustomerService';
import SellerService from '../../../services/SellerService';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import { Seller } from '../../../types/Seller';
import { Link, useHistory } from 'react-router-dom';

type ReportRequestResponse = {
    rows: Request[];
    count: number;
}

type Filters = {
    status: string;
    customerId: number;
    sellerId: number;
    dateInitial: string;
    dateEnd: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "requestNumber", value: "Nº do Pedido" },
    { reference: "clientName", value: "Cliente" },
    { reference: "sellerName", value: "Vendedor" },
    { reference: "status", value: "Situação" },
    { reference: "requestDate", value: "Data do Pedido" },
    { reference: "totalValue", value: "Valor Total" },
];

const productHeadData: HeadDataBaseProps[] = [
    { reference: "code", value: "Cod" },
    { reference: "name", value: "Nome" },
    { reference: "saleValue", value: "Valor" },
    { reference: "type", value: "Tipo" },
];

export function ListReportRequests() {
    const [requests, setRequests] = useState<Request[]>([]);
    const [countRequestsPaginated, setCountRequestsPaginated] = useState(0);
    const [countRequests, setCountRequests] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [customerId, setCustomerId] = useState(0)
    const [dateInitial, setDateInitial] = useState('')
    const [dateEnd, setDateEnd] = useState('')
    const [status, setStatus] = useState('')
    const [sellerId, setSellerId] = useState(0)
    const [showProducts, setShowProducts] = useState(false)
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
        showProducts,
    ) => {
        const { data } = await api.get<ReportRequestResponse>("/report/requests", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            let exportProductData: ReportDataRow[] = [];
            const data = mountBodyDataRow(reportRow);
            const products = JSON.parse(reportRow.products)

            data.push(
                {
                    for: "products/services",
                    value: '',
                    secondaryRow: true,
                    jsx: <ListProducts products={products}/>
                },
            );

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            console.log(showProducts);

            if (showProducts) {
                exportProductData = products.map((product: any) => {
                    return {
                        cells: [
                            {for: 'code', content: product.INSTANCE.code ?? ''},
                            {for: 'name', content: product.INSTANCE.name ?? ''},
                            {for: 'saleValue', content: formatCurrency(product.INSTANCE.saleValue) ?? ''},
                            {for: 'type', content: product.INSTANCE.type == 'product' ? 'Produto' : 'Serviço'},
                        ],
                    };
                });
            }

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productHeadData,
                    bodyData: exportProductData,
                }
            });

            setExportTotals([
                { name: 'Total de Pedidos', value: count },
            ])
        });

        setExportBodyData(exportDataList);
        setCountRequests(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportRequestResponse>("/report/requests", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setRequests(rows);
        setCountRequestsPaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    const { user } = useSelector((state: any) => state.auth);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = requests;

        aux.forEach((request) => {
            const data = mountBodyDataRow(request);
            const products = JSON.parse(request.products)

            if (showProducts == true) {
                data.push(
                    {
                        for: "products/services",
                        value: '',
                        secondaryRow: true,
                        jsx: <ListProducts products={products}/>
                    },
                );
            }

            list.push(data);
        });

        setBodyData(list);
    }, [requests]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference, showProducts);
    }, [lastSortDirection, lastSortReference, showProducts]);

    function mountBodyDataRow(reportRow: Request) {
        let data: BodyDataBaseProps[] = [
            {
                for: "id",
                value: String(reportRow.id),
                jsx: <Link to={`/pedidos/${reportRow.id}`}>{reportRow.id}</Link>,
                id: true
            },
            { for: "requestNumber", value: reportRow.requestNumber },
            { for: "clientName", value: CustomerService.getCustomerName(reportRow.customerEntity, reportRow.customerName) },
            { for: "sellerName", value: SellerService.getName(reportRow.sellerEntity) },
            { for: "status", value: reportRow.status == 'open' ? 'Em aberto' : 'Atendido' },
            { for: "requestDate", value: String(reportRow.requestDate.split("-").reverse().join("/")) },
            { for: "totalValue", value: formatCurrency(reportRow.totalValue) },
        ];

        return data;
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            status,
            customerId,
            sellerId,
            dateInitial,
            dateEnd
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference, showProducts);
    }, [
        showProducts,
        status,
        customerId,
        sellerId,
        dateInitial,
        dateEnd,
    ]);

    const clearSearch = () => {
        setCustomerId(0)
        setDateInitial('')
        setDateEnd('')
        setSellerId(0)
        setStatus('')
        setShowProducts(false);
    }

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Pedidos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-6">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customerId}
                        onSelect={(option) => setCustomerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customerId) return null;
                            return loadedOptions.find((option) => option.id === Number(customerId)) ?? CustomerService.getCustomerById(customerId)
                        }}
                    />
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data do Pedido"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={dateInitial}
                        onChange={(e) => setDateInitial(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data do Pedido"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={dateEnd}
                        onChange={(e) => setDateEnd(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="col-lg-6">
                    <ApiResourceSelect
                        label="Vendedor"
                        getOptionLabel={(option: Seller) => option.name}
                        value={sellerId}
                        onSelect={(option) => setSellerId(option?.id || 0)}
                        apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText, type: 'seller' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!sellerId) return null;
                            return loadedOptions.find((option) => option.id === Number(sellerId)) ?? CustomerService.getCustomerById(sellerId)
                        }}
                    />
                </div>

                <div className="col-lg-6">
                    <TextField
                        select
                        label="Situação"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                    >
                        <MenuItem key="0" value="">Selecione</MenuItem>
                        <MenuItem key="1" value="attended">Atendido</MenuItem>
                        <MenuItem key="2" value="open">Em aberto</MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showProducts}
                                onChange={(_, checked) => setShowProducts(checked)}
                            />
                        }
                        label="Exibir os produtos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Pedidos"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Pedidos', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countRequestsPaginated}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de pedidos</TableCell>
                                    <TableCell colSpan={1}>{countRequests}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}