import React, { useState, useEffect } from 'react';
import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid
} from '@material-ui/core';

import {
    Button,
    ButtonToolbar,
    Accordion,
    Card,
    Spinner,
    Modal
} from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';
import { request } from 'http';
import logService from '../../services/LogService';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
}

export function NewBrand() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar Marca");
    
    const [nameInvalid, setNameInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [brandName, setBrandName] = useState("");

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    useEffect(() => {
        
    }, []);

    function inputsVerify(): boolean {

        if (!brandName) {
            setIsSubmit(false)
            setMsgError("Preencha o nome da marca corretamente")
            setShowModalExist(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    async function onSubmit (data: Inputs) {
        setIsSubmit(true)

        if (!inputsVerify()) return

        const uuidBrand = uuidv4();

        var raw = JSON.stringify({
            "uuidBrand": uuidBrand,
            "nameBrand": brandName,
        });

        const createResponse = await api.post("/brand", raw);

        logService.logRegister({
            itemId: createResponse.data.id,
            module: 'Marcas',
            itemName: createResponse.data.nameBrand,
        });

        setShowModalSuccess(true);
        setIsSubmit(false);
    }

    return (
        <div className="row card card-body pt-2 newProductWrapper">
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/marcas";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados salvos com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/marcas";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">
                <div className='col-lg-12 d-flex justify-content-end p-0 mb-3'>
                    <Button
                        type='submit'
                        className='mr-0 mt-0'
                        variant="primary"
                        disabled={isSubmit}
                    >

                        {isSubmit ? <>

                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>

                        </> : <>

                            <span>
                                Salvar
                            </span>

                        </>}


                    </Button>
                </div>

                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome da Marca*"
                            margin="normal"
                            variant="outlined"
                            value={brandName}
                            onChange={(e) => {
                                setBrandName(e.target.value)
                                setNameInvalid(e.target.value ? false : true)
                            }}
                            error={nameInvalid}

                        />
                    </Grid>
                </Grid>
                <span className="mt-15">&nbsp;</span>
            </form>
        </div>
    );
}