import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { Search } from '../../components/Search';
import useBackendLoad from '../../hooks/backendReload';
import api from '../../services/Api';
import { SubscriptionPlan } from '../../types/SubscriptionPlan';
import { formatCurrency } from '../../utils/formatCurrency';
import { BsVariant } from '../../types/BsVariant';
import ModalError from '../../components/ModalError';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "name", value: "Nome" },
    { reference: "value", value: "Valor" },
    { reference: "maxCustomers", value: "Max Clientes/Fornecedores" },
    { reference: "maxProducts", value: "Max Produtos/Serviços" },
    { reference: "maxUsers", value: "Max Usuários" },
    // { reference: "maxNfe", value: "Max NF-e" },
    // { reference: "maxNfce", value: "Max NFC-e" },
    // { reference: "maxCte", value: "Max CT-e" },
    // { reference: "maxMdfe", value: "Max MDF-e" },
    // { reference: "maxNfse", value: "Max NFS-e" },
];

export function ListSubscriptionPlans() {
    const { user } = useSelector((state: any) => state.auth);
    
    const { location: { pathname }, push: pushHistory } = useHistory();

    const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
    const [countTotalPlans, setCountTotalPlans] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    
    // Campos da Busca
    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: SubscriptionPlan[], count: number}>('subscriptionPlans', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setSubscriptionPlans(rows);
        setCountTotalPlans(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [subscriptionPlans]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = subscriptionPlans;
    
        aux.forEach((item) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(item.id), id: true },
                { for: "name", value: item.name },
                { for: "value", value: item.value > 0 ? formatCurrency(item.value) : 'Gratuito' },
                { for: "maxCustomers", value: String(item.max_customers) },
                { for: "maxProducts", value: String(item.max_products) },
                { for: "maxUsers", value: String(item.max_users) },
                // { for: "maxNfe", value: String(item.max_nfe) },
                // { for: "maxNfce", value: String(item.max_nfce) },
                // { for: "maxCte", value: String(item.max_cte) },
                // { for: "maxMdfe", value: String(item.max_mdfe) },
                // { for: "maxNfse", value: String(item.max_nfse) },
            ];
    
            list.push(data);
        });
    
        setBodyData(list);
    }, [subscriptionPlans]);

    const handleClickDelete = useCallback(async (id: string) => {
        const hasCompanyUsing = await api.get(`/subscriptionPlans/${id}/has-company-using`);
        
        if (hasCompanyUsing.data) {
            setMsgError("Este plano contém empresas utilizando!");
            setShowModalError(true);
            return;
        }
        
        await api.delete(`subscriptionPlans/${id}`);
        const aux = subscriptionPlans.filter(plan => String(plan.id) !== id);

        setSubscriptionPlans([...aux]);
    }, [subscriptionPlans]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = { searchQuery };

        reloadData();
    }, [subscriptionPlans, searchQuery]);

    const clearSearch = () => {
        setSearchQuery('');
    }

    return (
        <div className="card card-body pt-4">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Adicionar Plano
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>

			<div className="mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
                    customButtons={
                        user.isAccountant == "n"
                        ?
                        [
                            {
                                class: 'btn-light-primary',
                                content: (<i className="flaticon2-edit p-0"></i>),
                                variant: BsVariant.PRIMARY,
                                popup: "Editar",
                                onClick: handleClickEdit,
                            },
                            {
                                class: 'btn-light-danger',
                                content: (<i className="flaticon-delete p-0"></i>),
                                variant: BsVariant.DANGER,
                                popup: "Deletar",
                                onClick: handleClickDelete,
                            },
                        ]
                        : []
                    }
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalPlans}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
		</div>
    );
}