import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { paymentOptions } from '../../../utils/paymentOptions';
import { BankAccount } from '../../../hooks/bankAccount';
import { getDate, subtractDate } from '../../../utils/dateTimeHelper';
import { formatCurrency, formatMoneyToInteger, formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import { SubCategory } from '../../../types/Dre';
import { getBalanceAccountBank, getBeforeBalance } from '../../../utils/extract';
import { CenterCost } from '../../../types/CenterCost';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CenterCostService from '../../../services/CenterCostService';
import BankAccountService from '../../../services/BankAccountService';
import DreCategoryService from '../../../services/DreCategoryService';
import { Link } from 'react-router-dom';
import { limitString } from '../../../utils/limitString';
import { ExtractRow } from '../../../types/ExtractRow';
import useQueryParams from '../../../hooks/queryParams';

type ExtractResponse = {
    previousBalance: number;
    bankAccountsBalance: number;
    count: number;
    totalEntry: number;
    totalOutput: number;
    currentBalance: number;
    rows: ExtractRow[];
}

const headData: HeadDataBaseProps[] = [
    { reference: 'payedDate', value: 'Data' },
    { reference: 'billName', value: 'Lançamento' },
    { reference: 'beneficiaryName', value: 'Beneficiário' },
    { reference: 'centerCostName', value: 'Centro de Custo' },
    { reference: 'paymentMethod', value: 'Forma de Pagamento' },
    { reference: 'dreSubCategoryName', value: 'Plano de Contas' },
    { reference: 'accountBank', value: 'Conta Bancária' },
    { reference: 'input', value: 'Entrada' },
    { reference: 'output', value: 'Saída' },
    { reference: 'balance', value: 'Saldo' },
];

export function ListReportExtract() {
    const [extractRows, setExtractRows] = useState<ExtractRow[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [shouldTriggerSearch, setShouldTriggerSearch] = useState(false);

    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [billName, setBillName] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [accountBank, setAccountBank] = useState("");
    const [typeBill, setTypeBill] = useState("all");
    const [typePayment, setTypePayment] = useState("");

    const [openAccount, setOpenAccount] = useState<any>(0);
    const [entries, setEntries] = useState(0);
    const [outputs, setOutputs] = useState(0);
    const [actualBalance, setActualBalance] = useState(0);
    const [subCategoryId, setSubCategoryId] = useState("");

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);
    const { getQueryParam } = useQueryParams();


    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = extractRows;    
       
        aux.forEach((extractRow) => { 
            const data: BodyDataBaseProps[] = [
                { for: "payedDate", value: extractRow.payedDate.split("-").reverse().join("/") },
                {
                    for: "billName",
                    value: extractRow.billName,
                    jsx: (
                        extractRow.billId
                        ? (
                            <Link
                                to={`/${extractRow.billType == "receive" ? 'contas-a-receber' : 'contas-a-pagar'}/${extractRow.billId}`}
                                title={extractRow.billName}
                            >
                                {limitString(extractRow.billName, 40)}
                            </Link>
                        ) : <>{limitString(extractRow.billName, 40)}</>
                    ),
                },  
                { for: "beneficiaryName", value: String(extractRow.beneficiaryName || '-') },
                { for: "centerCostName", value: extractRow.centerCostName || '-' },
                { for: "paymentMethod", value: extractRow.paymentMethod || '-' },
                { for: "dreSubCategoryName", value: extractRow.dreSubCategoryName || '-' },
                { for: "accountBank", value: extractRow.bankAccount ? `${extractRow.bankAccount.nameBank} - ${extractRow.bankAccount.name}` : '-' },
                { for: "input", value: extractRow.billName === 'SALDO ANTERIOR' ? '-' : (extractRow.billType == "receive" ? formatCurrency(extractRow.value) : '--') },
                { for: "output", value: extractRow.billName === 'SALDO ANTERIOR' ? '-' : (extractRow.billType == "pay" ? formatCurrency(extractRow.value) : '--') },
                { for: "balance", value: formatCurrency(extractRow.currentBalance) },
            ];
            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            list.push(data);
    
            exportDataList.push({
                cells: exportCells,
            });
        });

        setBodyData(list);
        setExportBodyData(exportDataList);
    }, [extractRows]);

    useEffect(() => {
        async function handleQueryParamsFilters() {
            const initialDate = getQueryParam('initialDate') ?? '';
            const finalDate = getQueryParam('finalDate') ?? '';
            const subCategoryId = getQueryParam('subCategoryId') ?? '';
            const centerCost = getQueryParam('centerCost') ?? '';

            let hasParamsFilters = false;

            if (initialDate) {
                setInitialDate(initialDate);
                hasParamsFilters = true;
            }

            if (finalDate) {
                setFinalDate(finalDate);
                hasParamsFilters = true;
            }

            if (subCategoryId) {
                setSubCategoryId(subCategoryId);
                hasParamsFilters = true;
            }

            if (centerCost) {
                setCenterCost(centerCost);
                hasParamsFilters = true;
            }

            if (hasParamsFilters) {
                setShouldTriggerSearch(true);
            }

        }

        handleQueryParamsFilters();
    }, [getQueryParam]);

    useEffect(() => {
        if (shouldTriggerSearch) {
            handleClickSearch();
            setShouldTriggerSearch(false);
        }
    }, [shouldTriggerSearch]);

    const clearSearch = () => {
        setBeneficiaryName('');
        setBillName('');
        setInitialDate('');
        setFinalDate('');
        setCenterCost('');
        setAccountBank('');
        setTypePayment('');
        setTypeBill('');
        setSubCategoryId('');
    }

    async function handleClickSearch() {
        setIsSearching(true);

        try {
            let resultFinal: ExtractRow[] = [];
            
            const filters = {
                beneficiaryName,
                billName,
                initialDate,
                finalDate,
                centerCost,
                accountBank,
                typePayment,
                typeBill,
                subCategoryId
            };

            var extractResponse = await api.get<ExtractResponse>('report/extract', { params: { filters } });
            const {
                previousBalance,
                bankAccountsBalance,
                count,
                totalEntry,
                totalOutput,
                currentBalance,
                rows,
            } = extractResponse.data;

            resultFinal.push({
                payedDate: rows.length > 0 ? subtractDate(initialDate || rows[0].payedDate, 1) : '',
                billId: 0,
                billName: 'SALDO ANTERIOR',
                billType: 'receive',
                billRecordType: 'account',
                beneficiaryName: '',
                centerCostName: '',
                paymentMethod: '',
                dreSubCategoryName: '',
                bankAccount: null,
                value: previousBalance,
                currentBalance: previousBalance,
            });

            resultFinal = [...resultFinal, ...rows];

            setOpenAccount(formatToFloat(bankAccountsBalance));
            setActualBalance(currentBalance);
            setExtractRows(resultFinal);
            setEntries(totalEntry)
            setOutputs(totalOutput);

            setExportTotals([
                { name: 'Entradas', value: formatCurrency(totalEntry) },
                { name: 'Saídas', value: formatCurrency(totalOutput) },
                { name: 'Saldo Atual', value: formatCurrency(currentBalance) },
                { name: 'Valor Inicial Conta', value: formatCurrency(previousBalance) },
                // { name: 'Saldo Anterior', value: formatCurrency(lastEntry - lastOutput) },
                // { name: 'Saldo no Período', value: formatCurrency(entry - output) },
            ]);
        } catch (error) {
            console.log("Error: "+ error);
        }

        setIsSearching(false);
    }

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Extratos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Beneficiário"
                        margin="normal"
                        variant="outlined"
                        value={beneficiaryName}
                        onChange={(e) => setBeneficiaryName(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Lançamento"
                        margin="normal"
                        variant="outlined"
                        value={billName}
                        onChange={(e) => setBillName(e.target.value)}
                    />
                </div>
                
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Período"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={initialDate}
                        onChange={(e) => setInitialDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Período"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={finalDate}
                        onChange={(e) => setFinalDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Centro de Custos"
                        getOptionLabel={(option: CenterCost) => option.name}
                        value={centerCost}
                        onSelect={(option) => setCenterCost(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!centerCost) return null;
                            return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        label="Forma de Pagamento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={typePayment}
                    >
                        <MenuItem key="0" value="">
                            Selecione
                        </MenuItem>

                        {
                            paymentOptions.map((payment, index) => (
                                <MenuItem key={index + 1} value={payment.value} onClick={() => setTypePayment(payment.value)}>
                                    {payment.value}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Plano de Contas"
                        getOptionLabel={(option: SubCategory) => option.name}
                        value={subCategoryId}
                        onSelect={(option) => setSubCategoryId(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'both')}
                        getSelectedOption={(loadedOptions) => {
                            if(!subCategoryId) return null;
                            return loadedOptions.find((option) => option.id === Number(subCategoryId)) ?? DreCategoryService.getDreSubCategoryById(subCategoryId)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={accountBank}
                        onSelect={(option) => setAccountBank(String(option?.id || ''))}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!accountBank) return null;
                            return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo da Conta"
                        margin="normal"
                        variant="outlined"
                        value={typeBill}
                    >
                        <MenuItem key="0" value="all" onClick={() => setTypeBill('all')}>
                            Todos
                        </MenuItem>
                        
                        <MenuItem key="1" value="receive" onClick={() => setTypeBill('receive')}>
                            Somente Receitas
                        </MenuItem>

                        <MenuItem key="2" value="expense" onClick={() => setTypeBill('expense')}>
                            Somente Despesas
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Extrato"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Extratos', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                {/* <TableRow key="0">
                                    <TableCell colSpan={1}>Saldo Anterior</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(lastBalance)}</TableCell>
                                </TableRow> */}
                                <TableRow key="1">
                                    <TableCell colSpan={1}>Entradas</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(entries)}</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                    <TableCell colSpan={1}>Saídas</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(Number(outputs))}</TableCell>
                                </TableRow>
                                {/* <TableRow key="3">
                                    <TableCell colSpan={1}>Saldo no Período</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(Number(periodBalance))}</TableCell>
                                </TableRow> */}
                                {/* <TableRow key="4">
                                    <TableCell colSpan={1}>Saldo Final</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(finalBalance)}</TableCell>
                                </TableRow> */}
                                <TableRow key="5">
                                    <TableCell colSpan={1}>Saldo Atual</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(actualBalance)}</TableCell>
                                </TableRow>
                                <TableRow key="6">
                                    <TableCell colSpan={1}>Valor Inicial Conta</TableCell>
                                    <TableCell colSpan={1}>{formatCurrency(openAccount)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}