import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListBillsToPay } from '../pages/BillsToPay/List-BillsToPay';
import { EditBillsToPay } from '../pages/BillsToPay/Edit-BillsToPay';
import { NewBillsToPay } from '../pages/BillsToPay/New-BillsToPay';

import { useSubheader } from '../../_metronic/layout';

export function BillsToPay() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Contas a Pagar");
    
    return (
        <Switch>
            <Route path="/contas-a-pagar/adicionar" component={NewBillsToPay}/>
            <Route path="/contas-a-pagar/duplicar/:idToClone" component={NewBillsToPay}/>
            <Route path="/contas-a-pagar/:id" component={EditBillsToPay}/>
            <Route path="/contas-a-pagar" component={ListBillsToPay}/>
        </Switch>
    );
}