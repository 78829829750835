import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { formatCurrency } from "../../../utils/formatCurrency";

type BillsTableParams = {
    bills: any[];
};

export function BillsTable({ bills }: BillsTableParams) {    
    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Nº</TableCell>
                    <TableCell>Data de Emissão</TableCell>
                    <TableCell>Data de Vencimento</TableCell>
                    <TableCell>Dias Vencidos</TableCell>
                    <TableCell>Valor Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {bills.map(function (bill) {
                    const today      = new Date();
                    const dueDate    = new Date(bill.dueDate);
                    const timeDiff   = Math.abs(dueDate.getTime() - today.getTime());
                    const diffInDay  = Math.ceil(timeDiff / (1000 * 3600 * 24)) // calculo para converter em dias
                    
                    return (
                        <TableRow key={bill.id}>
                            <TableCell>{bill.id}</TableCell>
                            <TableCell>{bill.issuanceDate.split("-").reverse().join("/")}</TableCell>
                            <TableCell>{bill.dueDate.split("-").reverse().join("/")}</TableCell>
                            <TableCell>{diffInDay}</TableCell>
                            <TableCell>{formatCurrency(bill.amount)}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}