import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EntryProvider } from '../pages/Entry/context';
import { EditEntry } from '../pages/Entry/Edit-Entry';
import { ImportManifestEntry } from '../pages/Entry/ImportManifest/ImportManifest-Entry';
import { ImportXmlEntry } from '../pages/Entry/ImportXml-Entry';
import { ListEntry } from '../pages/Entry/List-Entry';
import { NewEntry } from '../pages/Entry/New-Entry';

export function Entry() {
    
    return (
        <EntryProvider>
            <Switch>
                <Route path="/entrada-de-mercadoria/adicionar" component={NewEntry}/>
                <Route path="/entrada-de-mercadoria/importar-xml" component={ImportXmlEntry}/>
                <Route path="/entrada-de-mercadoria/importar-receita" component={ImportManifestEntry}/>
                <Route path="/entrada-de-mercadoria/:id" component={EditEntry}/>
                <Route path="/entrada-de-mercadoria" component={ListEntry}/>
            </Switch>
        </EntryProvider>
    );
}