import React, { createContext, useContext, useState } from 'react';

export type BankAccount = {
    id: number;
    name: string;
    nameBank: string;
    numberBank: string;
    account: string;
    accountDigit: string;
    standardAccountForRecipes: string;
    standardExpenseAccount: string;
    cashierMoney: number;
    openingBalance: string;
    companyId: number;
    idTCS: number;
    initialDate: string;
    agency: string;
    agencyDigit: string;
    situation: string;
    willGenerateBills: string;
    walletType: string;
    wallet: string;
    codeAssignor: string;
    paymentPlace: string;
    walletVariation: string;
    covenantCode: string;
    instructions: string;
    demonstrative: string;
    percentageOfFinePerMonth: string;
    interestPercentage: string;
    interestDays: string;
    shipmentSequence: number;
    billetSequence: number;
    defaultLayout: string;
    accept: String;
    titleSpecies: string;
    color: string
};

interface BankAccountContextData {
    bankAccounts: BankAccount[];
    bankAccountsNames: string[];
    bankAccountsId: string[];
}


const BankAccountContext = createContext<BankAccountContextData>({} as BankAccountContextData);

const BankAccountProvider: React.FC = ({ children }) => {
    const [bankAccountsNames] = useState(['']);
    const [bankAccountsId] = useState(['']);
    const [bankAccounts] = useState<BankAccount[]>([]);

    return (
        <BankAccountContext.Provider value={{ bankAccounts, bankAccountsNames, bankAccountsId }}>
            { children }
        </BankAccountContext.Provider>
    );
}

const useBankAccount = () => {
    const context = useContext(BankAccountContext);

    if (!context) {
        throw new Error("The method useBankAccount must be used within a BankAccountProvider");
    }

    return context;
}

export { BankAccountProvider, useBankAccount };
