import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { BsVariant } from '../types/BsVariant';

export type ModalConfirmProps = {
    confirmText?: string;
    confirmButtonLabel?: string;
    confirmButtonVariant?: BsVariant;
    cancelButtonLabel?: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void|Promise<void>;
}

export default function ModalConfirm({
    confirmText,
    confirmButtonLabel,
    confirmButtonVariant,
    cancelButtonLabel,
    showModal,
    setShowModal,
    onConfirm,
}: ModalConfirmProps) {
    const [isPeformingConfirmAction, setIsPeformingConfirmAction] = useState(false);

    async function handleClickConfirm() {
        setIsPeformingConfirmAction(true);

        await onConfirm();

        setIsPeformingConfirmAction(false);
    }

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"

            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <strong>{confirmText || 'Tem certeza que deseja realizar esta ação ?'}</strong>
            </Modal.Body>

            <Modal.Footer>
            <Button variant={confirmButtonVariant ?? 'primary'} onClick={handleClickConfirm}>
                    {
                        isPeformingConfirmAction ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </>
                            :
                            <span>{confirmButtonLabel ?? 'Sim'}</span>
                    }
                </Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>{cancelButtonLabel ?? 'Cancelar'}</Button>
            </Modal.Footer>
        </Modal>
    )
}