import React from 'react';
import { Grid } from '@material-ui/core';

const styles = {
    headStyle: {
        background: "#EBEBEB",
        border: "1px solid #BDBDBD",
    },
};

export const CategoryListTitle: React.FC = ({ children }) => {
    return (
        <Grid container direction="row" alignItems="center" justify="center" style={styles.headStyle}>
            <Grid item>
                <div className="p-4 m-0">
                    <strong className="font-weight-black">{children}</strong>
                </div>
            </Grid>
        </Grid>
    );
};