import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab
} from "react-bootstrap";

import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import api from '../../services/Api';
import { api as ApiUrl } from '../../services/ApiURL';
import { useHistory, useParams } from 'react-router';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { yesOrNo } from '../../types/yesOrNo';
import useCompany from '../../hooks/company';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from "../../modules/Auth";
import { useCompanyBranch } from '../../hooks/companyBranch';
import UserService from '../../services/UserService';
import { PatternFormat } from '../../components/PatternFormat';
import { PermissionMenu } from '../../types/PermissionMenu';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));


type submenu = {
    id: number,
    name: string,
    menuId: number,
    checked: boolean,
    subMenu: submenu[]
}

type menuSystem = {
    id: number,
    menuId: number,
    name: string,
    checked: boolean,
    subMenu: submenu[]
}

export function EditMyAccount() {
    const dispatch = useDispatch();

    const history = useHistory();

    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Editar Usuário");

    const { user } = useSelector((state: any) => state.auth);
    const { companies } = useCompany({only: user.companyId});
    const [ company ] = companies;
    const { selectedCompany } = useCompanyBranch();
    const [activeTab, setActiveTab] = useState("details");

    /* Photo */
    const imgFileInput = useRef<HTMLInputElement>(null);
    const [photo, setPhoto] = useState("");
    const [savedPhoto, setSavedPhoto] = useState("");

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(yesOrNo.NO);

    /* Permissoes */
    const { menus } = useMenu();
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>([]);
    const [savedPermissions, setSavedPermissions] = useState<Array<number>>([]);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [changePassword, setChangePassword] = useState(false);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [cellInvalid, setCellInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [menuSystem, setMenuSystem] = useState<menuSystem[]>([]);

    useEffect(() => {
        
        async function loadData() {
            const response = await api.get(`users/${user.id}`);
            const data = response.data;

            setFirstname(data.firstname ?? '');
            setLastname(data.lastname ?? '');
            setEmail(data.email ?? '');
            setCell(data.cell ?? '');
            setLogin(data.login ?? '');
            setIsAdmin(data.isAdmin ?? yesOrNo.NO);
            setSavedPermissions(JSON.parse(data.permissions));
        

            if(data.photo) {
                setPhoto(`${ApiUrl}/${data.photo}`);
                setSavedPhoto(data.photo);
            }

            
        }

        async function getMenusSystem() {
            var userPermissions: number[] = JSON.parse(user.permissions);
            var fastAccess: number[] = JSON.parse(user.fastAccess);

            if (user.isAccountant == "y" && selectedCompany) {
                userPermissions = JSON.parse(JSON.parse(user.permissions).find((permission: any) => permission.companyId == selectedCompany.id).permissions);
                fastAccess = JSON.parse(JSON.parse(user.fastAccess).find((fastAccess: any) => fastAccess.companyId == selectedCompany.id).fastAccess);
            }

            var header = menus.filter((menuHeader: any) => menuHeader.menuId == null).sort((data1: any, data2: any) => {
                return data1.order - data2.order;
            });

            var menusArr: any = [];
            header.map(async(menuHeader: any, indexHeader: number) => {
                var moduleArr = {
                    id: menuHeader.id,
                    menuId: menuHeader.menuId,
                    name: menuHeader.name,
                    checked: fastAccess ? fastAccess.find((moduleId: number) => moduleId == menuHeader.id) : false,
                    subMenu: []
                }

                var submenu1Data = menus.filter((submenu1: any) => submenu1.menuId == menuHeader.id).sort((data1: any, data2: any) => {
                    return data1.order - data2.order;
                });   
                
                var submenuArr: any = [];
                submenu1Data.map(async(submenu1Data: any, indexSubmenu1: number) => {
                    var submenu1Arr = {
                        id: submenu1Data.id,
                        menuId: submenu1Data.menuId,
                        name: submenu1Data.name,
                        checked: fastAccess ? fastAccess.find((moduleId: number) => moduleId == submenu1Data.id) : false,
                        subMenu: []
                    }

                    var submenu2Arr: any = [];
                    menus.filter((submenu2Data: any) => submenu2Data.menuId == submenu1Data.id).map(async(submenu2Data: any, indexSubmenu2: number) => {
                        if(userPermissions.includes(submenu2Data.id)) {
                            submenu2Arr.push({
                                id: submenu2Data.id,
                                menuId: submenu2Data.menuId,
                                name: submenu2Data.name,
                                checked: fastAccess ? fastAccess.find((moduleId: number) => moduleId == submenu2Data.id) : false
                            });
                        }
                    });

                    submenu1Arr = {
                        ...submenu1Arr,
                        subMenu: submenu2Arr
                    }

                    if(userPermissions.includes(submenu1Arr.id)) {
                        submenuArr.push(submenu1Arr);
                    }
                });


                moduleArr = {
                    ...moduleArr,
                    subMenu: submenuArr
                }
                if(userPermissions.includes(moduleArr.id)) {
                    menusArr.push(moduleArr);
                }
            });

            setMenuSystem(menusArr);
        }

        loadData();
        getMenusSystem();
    }, [selectedCompany]);

    useEffect(() => {
        if(!menus.length) return;
        if(!user) return;
        if(!company) return;

        const companyPermissions: Array<number> = JSON.parse(company.permissions);
        const filtered = filterCompanyPermissionMenu(menus as PermissionMenu[], companyPermissions);
        const permissions = mapPermissionsChecked(savedPermissions, filtered);
        setPermissionMenus([...permissions]);
    }, [menus, user, company, savedPermissions]);

    async function inputsVerify(): Promise<boolean> {
        if (!firstname) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            return false
        }
        if (!cell) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o celular corretamente")
            setShowModalError(true);
            setCellInvalid(true);
            return false
        }

        const cellExists = await UserService.verifyCellExists(cell, user.id);
        if(cellExists) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Celular já existe");
            setShowModalError(true);
            return false;
        }

        if (!email) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o e-mail corretamente")
            setShowModalError(true);
            setEmailInvalid(true);
            return false
        }

        const emailExists = await UserService.verifyEmailExists(email, user.id);
        if(emailExists) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("E-mail já existe");
            setShowModalError(true);
            return false;
        }
        
        if(user.isAccoutant == "n" && !login) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o Login corretamente")
            setShowModalError(true);
            return false;
        }
        if(changePassword && !password) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha a senha corretamente")
            setShowModalError(true);
            return false;
        }

        return true;
    }

    function handleUploadClick() {
        if(imgFileInput && imgFileInput.current) {
            imgFileInput.current.click();
        }
    }

    function handleChangeImgFile(event: any) {
        const input: HTMLInputElement = event.target;
        if(!input.files || input.files.length === 0) return;
        const fileUrl = window.URL.createObjectURL(input.files[0]);
        setPhoto(fileUrl);
    }

    function filterCompanyPermissionMenu(menus: PermissionMenu[], companyPermissions: Array<number>): PermissionMenu[] {
        const filtered = menus.filter((menu) => companyPermissions.includes(menu.id));
        if(!filtered || filtered.length === 0) {
            return [];
        }
        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = filterCompanyPermissionMenu(filtered[i].submenus as PermissionMenu[], companyPermissions).filter(() => true);
            }
        }
        return filtered;
    }

    function filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
        const filtered = menus.filter((menu) => menu.checked);
        if(!filtered || filtered.length === 0) {
            return [];
        }
        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = filterPermissionMenu(filtered[i].submenus as PermissionMenu[]).filter(() => true);
            }
        }
        return filtered;
    }

    function mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
        let mapped = menus.map((menu) => menu.id);
        for(let i = 0; i < menus.length; i++) {
            if(menus[i].submenus?.length) {
                mapped = mapped.concat(mapPermissionMenu(menus[i].submenus as PermissionMenu[]));
            }
        }
        return mapped;
    }

    function mapPermissionsChecked(permissions: Array<number>, menus: PermissionMenu[]) {
        const aux = menus;
        for(let i = 0; i < aux.length; i++) {
            aux[i].checked = permissions.includes(aux[i].id);
            if(aux[i].submenus?.length) {
                aux[i].submenus = mapPermissionsChecked(permissions, aux[i].submenus as PermissionMenu[]);
            }
        }
        return aux;
    }

    function mapMenusSystem(menus: any): Array<number> {
        let mapped = menus.filter((menuChecked: any) => menuChecked.checked).map((menu: any) => menu.id);

        for(let i = 0; i < menus.length; i++) {
            if(menus[i].subMenu?.length) {
                mapped = mapped.concat(mapMenusSystem(menus[i].subMenu as submenu[]));
            }
        }

        return mapped;
    }

    const handleChangePermission = useCallback((checked: boolean, indexes: Array<number>) => {
        const aux = menuSystem;
        
        if(indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].checked = checked;

            aux[i1].subMenu = modifyPermissionSubmenus(aux[i1]);
        } else if(indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].subMenu as submenu[];

            aux[i2].checked = checked || aux[i2].checked;
            submenu1[j2].checked = checked;

            submenu1[j2].subMenu = modifyPermissionSubmenus(submenu1[j2]);
            aux[i2].subMenu = submenu1;
        } else if(indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].subMenu as submenu[];
            const submenu2 = submenu1[j3].subMenu as submenu[];

            aux[i3].checked = checked || aux[i3].checked;
            submenu1[j3].checked = checked || submenu1[j3].checked;
            submenu2[k3].checked = checked;

            submenu2[k3].subMenu = modifyPermissionSubmenus(submenu2[k3]);
            (aux[i3].subMenu || [])[j3].subMenu = submenu2;
        }

        setMenuSystem([...aux]);
    }, [menuSystem]);

    function modifyPermissionSubmenus(menu: submenu): submenu[] {
        const checked = menu.checked ?? false;
        const submenus = menu.subMenu?.filter(() => true) as submenu[];
        if(!submenus || submenus.length === 0) {
            return [];
        }
        for (let i = 0; i < submenus.length; i++) {
            submenus[i].checked = checked;
            submenus[i].subMenu = modifyPermissionSubmenus(submenus[i]);
        }
        return submenus;
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!(await inputsVerify())) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = filterPermissionMenu(aux);
        const permissions = mapPermissionMenu(menusFiltered);
        const fastAccess = mapMenusSystem(menuSystem);

        const raw = {
            // permissions: JSON.stringify(permissions),
            fastAccess: JSON.stringify(fastAccess),
            firstname: firstname ? firstname : null,
            lastname: lastname ? lastname : null,
            cell: cell ? cell : null,
            email: email ? email : null,
            login: login ? login : null,
            password: changePassword && password ? password : undefined,
        };

        try {
            await api.put(`users/${user.id}`, raw);

            // Upload foto
            let uploadSuccess = true;
            try {
                await uploadPhoto(user.id);
            } catch (error) {
                uploadSuccess = false;
            }
            if(uploadSuccess && savedPhoto) {
                await removeOldPhoto(savedPhoto);
            }

            dispatch(auth.actions.setUser({
                ...user,
                ...raw,
            }));

            setShowModalSuccess(true);

        } catch (error) {
            console.log('error', error);
        }

        setIsSubmit(false);
    }

    async function removeOldPhoto(filename: string) {
        await api.delete('/file', {data: { filename }})
    }

    async function uploadPhoto(id: number): Promise<string|boolean> {
        if(imgFileInput && imgFileInput.current) {
            if(!imgFileInput.current.files || imgFileInput.current.files.length === 0) {
                return false;
            }
            const imageFile = imgFileInput.current.files[0];
            if(!imageFile) {
                return false;
            }

            var formFile = new FormData();
            formFile.append('image', imageFile, imageFile.name);
            
            const response = await api.post('/image', formFile);
            const raw = {
                photo: response.data
            }

            await api.put(`users/${user.id}`, raw);
            return response.data;
        }

        return false;
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.reload();
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.reload();
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">
                    <div className="col-lg-2 text-center">
                        <img
                            src={photo ? photo : toAbsoluteUrl("/media/users/300_21.jpg")}
                            className="img-fluid d-block"
                            alt=""
                        />    
                        <Button
                            className="mt-3 mb-2"
                            variant="primary"
                            onClick={handleUploadClick}
                        >
                            Upload
                        </Button>
                        <input
                            type="file"
                            className="d-none"
                            ref={imgFileInput}
                            onChange={(e) => handleChangeImgFile(e)}
                            accept="image/png, image/jpeg"
                        />
                        {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
                    </div>
                    <div className="col-4">
                        <TextField
                            label="Nome"
                            required
                            size="small"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            value={firstname}
                            onChange={(e) => {
                                setFirstname(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label="Sobrenome"
                            size="small"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            value={lastname}
                            onChange={(e) => {
                                setLastname(e.target.value)
                            }}
                        />
                    </div>
                    <div className="col d-flex align-items-start justify-content-end">
                        <Button
                            type='submit'
                            className='mt-4'
                            variant="primary"
                            disabled={isSubmit}
                        >
                            {isSubmit ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : <>
                                <span>
                                    Salvar
                                </span>
                            </>}
                        </Button>
                    </div>
                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="edituser-form-tabs" className="mt-3">
                    <Tab eventKey="details" title="Dados">
                        <div className="row">
                            <div className="col-lg-4">
                                <TextField
                                    size="small"
                                    label="E-mail*"
                                    className={classes.error}
                                    margin="normal"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailInvalid(e.target.value ? false : true)
                                    }}
                                    error={emailInvalid}
                                />
                            </div>
                            <div className="col-lg-4">
                                <PatternFormat
                                    label="Celular"
                                    className={classes.error }
                                    format="(##) #####-####"
                                    mask="_"
                                    value={cell}
                                    onChange={(e) => {
                                        setCell(e.target.value)
                                        setCellInvalid(e.target.value ? false : true)
                                    }}
                                    error={cellInvalid}
                                />
                            </div>

                            {
                                user.isAccountant == "n"
                                ?
                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Login"
                                        margin="normal"
                                        variant="outlined"
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                </div>
                                : <></>
                            }
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                            <div className="col-lg-2 d-flex align-items-center pl-0">
                                <FormControlLabel
                                    value="end"
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            onChange={e => setChangePassword(e.target.checked)}
                                            checked={changePassword}
                                        />
                                    )}
                                    label="Alterar Senha"
                                    labelPlacement="end"
                                    className="ml-1"
                                />
                            </div>
                            {changePassword && (
                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Senha"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>
                    </Tab>

                    <Tab eventKey="fastAccess" title="Acesso rápido (Dashboard)">


                        <div className="row">
                            <div className="col-12">
                                <Tabs defaultActiveKey="1" id="permissionTabs">
                                    {menuSystem.map((menu, i) => (
                                        <Tab key={i} eventKey={menu.id} title={menu.name}>
                                            <div className="row" key={`${i}`}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <Checkbox
                                                        checked={menu.checked ?? false}
                                                        inputProps={{
                                                        'aria-label': 'success checkbox',
                                                        }}
                                                        onChange={(e, checked) => handleChangePermission(checked, [i])}
                                                    />
                                                    <strong>Menu Ativo</strong>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row ml-2">
                                                        {menu.subMenu.length > 0 && menu.subMenu.map((submenu1: submenu, j) => (
                                                            <div className={submenu1.subMenu.length ? 'col-12' : 'col-lg-3'} key={`${i}-${j}`}>
                                                                <Checkbox
                                                                    checked={submenu1.checked ?? false}
                                                                    inputProps={{
                                                                    'aria-label': 'success checkbox',
                                                                    }}
                                                                    onChange={(e, checked) => handleChangePermission(checked, [i, j])}
                                                                />
                                                                <span>{submenu1.name}</span>
                                                                <div className="row ml-2">
                                                                    {submenu1.subMenu.map((submenu2: submenu, k) => (
                                                                        <div className="col-lg-3" key={`${i}-${j}-${k}`}>
                                                                            <Checkbox
                                                                                checked={submenu2.checked ?? false}
                                                                                inputProps={{
                                                                                'aria-label': 'success checkbox',
                                                                                }}
                                                                                onChange={(e, checked) => handleChangePermission(checked, [i, j, k])}
                                                                            />
                                                                            <span>{submenu2.name}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    ))}
                                </Tabs>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </form>
        </div>
    );
}