import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PurchaseOrderProvider } from '../pages/PurchaseOrder/context';
import { NewPurchaseOrder } from '../pages/PurchaseOrder/New-PurchaseOrder';
import { ListPurchaseOrder } from '../pages/PurchaseOrder/List-PurchaseOrder';
import { EditPurchaseOrder } from '../pages/PurchaseOrder/Edit-PurchaseOrder';

export function PurchaseOrder() {
    
    return (
        <PurchaseOrderProvider>
            <Switch>
                <Route path="/ordem-de-compra/Adicionar" component={NewPurchaseOrder}/>
                <Route path="/ordem-de-compra/duplicar/:idToClone" component={NewPurchaseOrder}/>
                <Route path="/ordem-de-compra/:id" component={EditPurchaseOrder}/>
                <Route path="/ordem-de-compra" component={ListPurchaseOrder}/>
            </Switch>
        </PurchaseOrderProvider>
    );
}