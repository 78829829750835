import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../hooks/backendReload';
import { CompanySubscriptionPlanPayment, invoicePaymentMethod, invoiceStatus } from '../../types/CompanySubscriptionPlanPayment';
import api from '../../services/Api';
import { getSituationText } from '../../utils/getSituationText';
import CompanySubscriptionPlanPaymentService from '../../services/CompanySubscriptionPlanPaymentService';
import { formatDate } from '../../utils/dateFormat';
import { Search } from '../../components/Search';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { Company } from '../../types/Company';
import CompanyService from '../../services/CompanyService';
import { SubscriptionPlan } from '../../types/SubscriptionPlan';
import SubscriptionPlanService from '../../services/SubscriptionPlanService';
import { BsVariant } from '../../types/BsVariant';
import useSendEmailAndWhatsapp from '../../hooks/sendEmailAndWhatsapp';
import { ModalSendEmail } from '../../components/ModalSendEmail';
import { ModalSendWhatsapp } from '../../components/ModalSendWhatsapp';
import ModalSuccess from '../../components/ModalSuccess';
import EmailInvoiceService from '../../services/EmailInvoiceService';
import WhatsappInvoiceService from '../../services/WhatsappInvoiceService';
import ModalError from '../../components/ModalError';
import { ModalChangePlan } from '../../components/ModalChangePlan';

type Filters = {
    searchQuery: string;
    company: number;
    planId: number;
    paymentDateMin: string;
    paymentDateMax: string;
    dueDateMin: string;
    dueDateMax: string;
    paymentMethod: string;
    status: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "company", value: "Empresa" },
    { reference: "planName", value: "Plano" },
    { reference: "paymentMethod", value: "Forma de Pagamento" },
    { reference: "paymentDate", value: "Competência" },
    { reference: "dueDate", value: "Data de Vencimento" },
    { reference: "status", value: "Status", situation: true, notSortable: true },
];

export function SuperInvoice() {

    const [companyPlanPayments, setCompanyPlanPayments] = useState<CompanySubscriptionPlanPayment[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCompanyPlanPayments, setCountTotalCompanyPlanPayments] = useState(0);

    const [searchQuery, setSearchQuery] = useState('');
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const [companyFilter, setCompanyFilter] = useState(0);
    const [planId, setPlanId] = useState(0);
    const [paymentDateMin, setPaymentDateMin] = useState('');
    const [paymentDateMax, setPaymentDateMax] = useState('');
    const [dueDateMin, setDueDateMin] = useState('');
    const [dueDateMax, setDueDateMax] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [status, setStatus] = useState('');

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState("");

    const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
    const [invoiceSelected, setInvoiceSelected] = useState<CompanySubscriptionPlanPayment | null>(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
    const [paymentMethodInvoice, setPaymentMethodInvoice] = useState<string>('');
    const [paymentDateInvoice, setPaymentDateInvoice] = useState<string>('');

    const [expirationDate, setExpirationDate] = useState("");
    const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlan | null>(null);
    const [dueDateInvoice, setDueDateInvoice] = useState("");
    const [statusInvoice, setStatusInvoice] = useState("");
    //Envio de Email/Whatspp
    const {
        showModalEmail, setShowModalEmail,
        infoEmail, setInfoEmail,
        emailsHistory, setEmailsHistory,
        showModalWhatsapp, setShowModalWhatsapp,
        infoWhatsapp, setInfoWhatsapp,
        whatsappHistory, setWhatsappHistory,
    } = useSendEmailAndWhatsapp();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: CompanySubscriptionPlanPayment[], count: number }>("companySubscriptionPlanPayment", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setCompanyPlanPayments(rows);
        setCountTotalCompanyPlanPayments(count);
    }, []);

    useLayoutEffect(() => {
        getSubscriptionPlans();

        const list: BodyDataBaseProps[][] = [];
        const aux = companyPlanPayments;

        aux.forEach((companyPlanPayment) => {
            const situation = getSituationText(companyPlanPayment.status);
            let compPlanPay = companyPlanPayment ? companyPlanPayment : null;
            let comp = compPlanPay ? compPlanPay.company : null;
            let plan = compPlanPay ? compPlanPay.subscriptionPlan : null;

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(companyPlanPayment.id), id: true },
                { for: "company", value: comp ? comp.name : '' },
                { for: "planName", value: plan ? plan.name : '' },
                { for: "paymentMethod", value: CompanySubscriptionPlanPaymentService.getPaymentMethodText(companyPlanPayment.paymentMethod) },
                { for: "paymentDate", value: formatDate(companyPlanPayment.paymentDate, true) },
                { for: "dueDate", value: formatDate(companyPlanPayment.dueDate, true) },
                { for: "status", value: CompanySubscriptionPlanPaymentService.getIsPaymentLate(companyPlanPayment, true) ? 'Atrasado' : situation },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [companyPlanPayments]);

    const clearSearch = () => {
        setSearchQuery('');
        setCompanyFilter(0);
        setPlanId(0);
        setPaymentDateMin('');
        setPaymentDateMax('');
        setDueDateMin('');
        setDueDateMax('');
        setPaymentMethod('');
        setStatus('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, company: companyFilter, planId,
            paymentDateMin, paymentDateMax,
            dueDateMin, dueDateMax,
            paymentMethod, status,
        };

        reloadData();
    }, [
        companyPlanPayments, searchQuery, companyFilter,
        planId, paymentDateMin, paymentDateMax,
        dueDateMin, dueDateMax, paymentMethod,
        status,
    ]);

    const billetButtonShowCondition = useCallback((id: string) => {
        const companyPlanPayment = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!companyPlanPayment) return false;

        return companyPlanPayment.paymentMethod === invoicePaymentMethod.boleto;
    }, [companyPlanPayments]);

    const handleClickBilletButton = useCallback((id: string) => {
        const row = companyPlanPayments.find((payment) => payment.id === Number(id));

        if (!row) return;

        window.open(row.billetUrl, '_blank', 'width=800,height=800');
    }, [companyPlanPayments]);

    const handleClickEditButton = useCallback((id: string) => {
        const row = companyPlanPayments.find((payment) => payment.id === Number(id));
        
        if (!row) return;

        const subscriptionPlanData = subscriptionPlans.find((sub) => sub.id === row.companyPlan.planId);

        setIsChangePlanModalOpen(true);
        setInvoiceSelected(row);
        setExpirationDate(row.companyPlan.expirationDate);
        setSubscriptionPlan(subscriptionPlanData ? subscriptionPlanData : null);
        setPaymentMethodInvoice(row.paymentMethod);
        setPaymentDateInvoice((row.paymentDate).toString().split('T')[0]);
        setDueDateInvoice((row.dueDate).toString().split('T')[0]);
        setStatusInvoice(row.status);
    }, [companyPlanPayments]);

    const sendEmailAndWhatsappButtonCondition = useCallback((id: string) => {
        const row = companyPlanPayments.find(payment => payment.id === Number(id));

        if(!row) return false;

        return row.status === invoiceStatus.pending || row.status === invoiceStatus.failed;
    }, [companyPlanPayments]);

    async function getEmails(companySubscriptionPlanPaymentId: number) {
        setEmailsHistory([]);
        const emails = await EmailInvoiceService.getAllEmailsByInvoice(companySubscriptionPlanPaymentId, 'companySubscriptionPlanPayment');
        setEmailsHistory(emails);
    }

    async function getWhatsapps(companySubscriptionPlanPaymentId: number) {
        setWhatsappHistory([]);
        const history = await WhatsappInvoiceService.getAllWhatsappsByInvoice(companySubscriptionPlanPaymentId, 'companySubscriptionPlanPayment');
        setWhatsappHistory(history);
    }

    async function handleOpenSendEmailModal(id: string) {
        const row = companyPlanPayments.find(payment => payment.id === Number(id));

        if(!row) return false;

        setShowModalEmail(true);
        getEmails(row.id);
        setInfoEmail({
            refId: String(row.id),
            refName: 'companySubscriptionPlanPayment',
            toName: '',
            customerName: row.company.name,
            customerId: String(row.companyId),
            email: row.company.email,
            contactName: row.company.name,
            message: await CompanySubscriptionPlanPaymentService.getDefaultPendingPaymentMessage(),
            emailSubject: 'Milliontech - Fatura do seu Plano',
        });
    }

    const handleClickSendEmail = useCallback(async () => {
        try {
            const emails = infoEmail.email.split(';').map(email => email.trim());

            const row = companyPlanPayments.find(payment => payment.id === Number(infoEmail.refId));

            if(!row) return false;

            await EmailInvoiceService.sendEmail(emails, {
                invoice: row,
                refName: 'companySubscriptionPlanPayment',
                companyId: infoEmail.customerId,
                companyName: infoEmail.customerName,
                contactName: infoEmail.contactName,
                message: infoEmail.message,
                subject: infoEmail.emailSubject,
            });

            setShowModalSuccess(true);
            setMessageSuccess('E-mail enviado com sucesso!');
        } catch (error: any) {
            setMsgError(error.response?.data?.message ?? 'Falha ao enviar o e-mail!');
            setShowModalError(true);
        }

        setShowModalEmail(false);
    }, [infoEmail, companyPlanPayments]);

    async function handleOpenSendWhatsappModal(id: string) {
        const row = companyPlanPayments.find(payment => payment.id === Number(id));

        if(!row) return false;

        setShowModalWhatsapp(true);
        getWhatsapps(row.id);
        setInfoWhatsapp({
            refId: String(row.id),
            refName: 'companySubscriptionPlanPayment',
            customerName: row.company.name,
            customerId: String(row.companyId),
            number: row.company.cell ?? '',
            message: await CompanySubscriptionPlanPaymentService.getDefaultPendingPaymentMessage(),
        });
    }

    const handleClickSendWhatsapp = useCallback(async () => {
        try {
            const row = companyPlanPayments.find(payment => payment.id === Number(infoWhatsapp.refId));
        
            if(!row) return;

            await WhatsappInvoiceService.sendWhatsapp(infoWhatsapp.number, {
                invoice: row,
                refName: 'companySubscriptionPlanPayment',
                companyId: infoWhatsapp.customerId,
                companyName: infoWhatsapp.customerName,
                message: infoWhatsapp.message,
            });

            setShowModalSuccess(true);
            setMessageSuccess('Mensagem enviada com sucesso!');
        } catch (error) {
            setMsgError('Falha ao enviar a mensagem!');
            setShowModalError(true);
        }

        setShowModalWhatsapp(false);
    }, [infoWhatsapp, companyPlanPayments]);

    const getSubscriptionPlans = useCallback(async () => {
        const {data} = await api.get<SubscriptionPlan[]>('subscriptionPlans');
        setSubscriptionPlans(data);
    }, []);


    return (
        <div className="card card-body pt-4">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
            />

            <ModalSendEmail
                showModal={showModalEmail}
                setShowModal={setShowModalEmail}
                onClickSend={handleClickSendEmail}
                emailsHistory={emailsHistory}
                infoEmail={infoEmail}
                setInfoEmail={setInfoEmail}
            />
            <ModalSendWhatsapp
                showModal={showModalWhatsapp}
                setShowModal={setShowModalWhatsapp}
                onClickSend={handleClickSendWhatsapp}
                whatsappHistory={whatsappHistory}
                infoWhatsapp={infoWhatsapp}
                setInfoWhatsapp={setInfoWhatsapp}
            />

            <ModalChangePlan
                isChangePlanModalOpen={isChangePlanModalOpen}
                setIsChangePlanModalOpen={setIsChangePlanModalOpen}
                invoiceSelected={invoiceSelected}
                subscriptionPlans={subscriptionPlans}
                expirationDate={expirationDate}
                setExpirationDate={setExpirationDate}
                subscriptionPlan={subscriptionPlan}
                setSubscriptionPlan={setSubscriptionPlan}
                paymentMethodInvoice={paymentMethodInvoice}
                setPaymentMethodInvoice={setPaymentMethodInvoice}
                paymentDateInvoice={paymentDateInvoice}
                setPaymentDateInvoice={setPaymentDateInvoice}
                dueDateInvoice={dueDateInvoice}
                setDueDateInvoice={setDueDateInvoice}
                statusInvoice={statusInvoice}
                setStatusInvoice={setStatusInvoice}
                reloadData={reloadData}
            />

            <div className="row d-flex align-items-center">
                <div className="col-9">
                    <h4>
                        Faturas
                    </h4>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Empresa"
                            getOptionLabel={(option: Company) => option.name}
                            value={companyFilter}
                            onSelect={(option) => setCompanyFilter(Number(option?.id ?? 0))}
                            apiSearchHandler={(typedText) => CompanyService.getCompaniesFiltered({ filters: { name: typedText } })}
                            getSelectedOption={(loadedOptions) => {
                                if (!companyFilter) return null;
                                return loadedOptions.find((option) => option.id === companyFilter) ?? CompanyService.getCompanyById(companyFilter)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Plano"
                            getOptionLabel={(option: SubscriptionPlan) => option.name}
                            value={planId}
                            onSelect={(option) => setPlanId(Number(option?.id ?? 0))}
                            apiSearchHandler={(typedText) => SubscriptionPlanService.getSubscriptionPlanFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if (!planId) return null;
                                return loadedOptions.find((option) => option.id === planId) ?? SubscriptionPlanService.getSubscriptionPlanById(planId)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Status"
                            margin="normal"
                            variant="outlined"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>

                            <MenuItem key="1" value="paid">
                                Pago
                            </MenuItem>

                            <MenuItem key="2" value="pending">
                                Pendente
                            </MenuItem>

                            <MenuItem key="3" value="late">
                                Atrasado
                            </MenuItem>

                            <MenuItem key="4" value="canceled">
                                Cancelado
                            </MenuItem>

                            <MenuItem key="5" value="scheduled">
                                Agendado
                            </MenuItem>

                            <MenuItem key="6" value="failed">
                                Falho
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Forma de Pagamento"
                            margin="normal"
                            variant="outlined"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>

                            <MenuItem key="1" value="boleto">
                                Boleto
                            </MenuItem>

                            <MenuItem key="2" value="credit_card">
                                Cartão de Crédito
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Competência"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={paymentDateMin}
                            onChange={(e) => setPaymentDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Competência"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={paymentDateMax}
                            onChange={(e) => setPaymentDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMin}
                            onChange={(e) => setDueDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data de Vencimento"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={dueDateMax}
                            onChange={(e) => setDueDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    totalCount={countTotalCompanyPlanPayments}
                    sortable={true}
                    loadData={loadData}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    situation
                    customButtons={[
                        {
                            class: 'btn-green p-2 mr-3',
                            content: (<img src="/media/icons/whatsapp.png" alt="Whatsapp" />),
                            variant: BsVariant.SUCCESS,
                            popup: "Envio de whatsapp",
                            onClick: handleOpenSendWhatsappModal,
                            showCondition: sendEmailAndWhatsappButtonCondition,
                        },
                        {
                            class: 'btn-blue p-2 mr-3',
                            content: (<i className="flaticon2-email p-0" style={{ color: "#fff" }}></i>),
                            variant: BsVariant.SUCCESS,
                            popup: "Envio de email",
                            onClick: handleOpenSendEmailModal,
                            showCondition: sendEmailAndWhatsappButtonCondition,
                        },
                        {
                            class: 'btn-light-warning',
                            content: (<i className="flaticon2-sheet p-0"></i>),
                            variant: BsVariant.WARNING,
                            popup: "Visualizar Boleto",
                            showCondition: billetButtonShowCondition,
                            onClick: handleClickBilletButton,
                        },
                        {
                            class: 'btn-light-primary',
                            content: (<i className="flaticon2-edit p-0"></i>),
                            variant: BsVariant.PRIMARY,
                            popup: "Editar Fatura",
                            onClick: handleClickEditButton,
                        },
                    ]}
                />
            </div>
        </div>
    );
}