import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';

export function ListProducts(props: any) {

    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Cod.</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Valor Unidade</TableCell>
                    <TableCell>Valor Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.items.map((item: any) => (
                    <TableRow key={item.product.id}>
                        <TableCell>{item.product.code}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.product.name}</TableCell>
                        <TableCell>{formatCurrency(item.unitValue)}</TableCell>
                        <TableCell>{formatCurrency(item.totalValue)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}