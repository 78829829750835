import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export type ModalSeeLogsProps = {
    showSeeLog: boolean;
    setShowModalLog: React.Dispatch<React.SetStateAction<boolean>>;
    contentLastValues: any;
    contentActualValues: any;
    contentId: string | number | undefined;
}

export default function ModalSeeLog({showSeeLog, setShowModalLog, contentLastValues, contentActualValues, contentId}: ModalSeeLogsProps) {

    return (
        <Modal
            id='modalLog'
            centered
            show={showSeeLog}
            onHide={() => setShowModalLog(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-seccondary mr-3"></i>
                    Mais informações
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">id</TableCell>
                            <TableCell align="right">Dados Antigos</TableCell>
                            <TableCell align="right">Dados Atuais</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow>
                                <TableCell align="left">{contentId}</TableCell>
                                <TableCell align="left">{contentLastValues ? contentLastValues.map((last: any) => (
                                    
                                    <div>
                                        <b>{last.fieldName}: </b>
                                        {last.valueFormatted} <br/>
                                    </div>

                                )) : ''
                                    
                                } </TableCell>

                                <TableCell align="left">{contentActualValues ? contentActualValues.map((actual: any) => (

                                    <div>
                                        <b>{actual.fieldName}: </b>
                                        {actual.valueFormatted} <br/>
                                    </div>

                                )) : ''
                                } </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalLog(false)}>Voltar</Button>
            </Modal.Footer>
        </Modal>
    )
}