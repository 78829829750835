import { useCallback, useState } from "react";

export function useDownloadResource() {
    const [resourceUrl, setResourceUrl] = useState('');

    const handleExecuteDownload = useCallback(() => {
        setResourceUrl('');
    }, [])

    return {
        resourceUrl,
        setResourceUrl,
        handleExecuteDownload,
    };
}