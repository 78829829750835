import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCpfCnpj } from '../../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import useAllUf from '../../../hooks/uf';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import { Seller } from '../../../types/Seller';

type ReportSellerResponse = {
    rows: Seller[];
    count: number;
}

type Filters = {
    name: string;
    city: string;
    state: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'Nº' },
    { reference: 'name', value: 'Nome' },
    { reference: 'cpf', value: 'CNPJ/CPF', notSortable: true },
    { reference: 'phone', value: 'Telefone', notSortable: true },
    { reference: 'city', value: 'Cidade' },
    { reference: 'state', value: 'Estado' },
    { reference: 'sellerCommission', value: 'Comissão (%)' },
];

export function ListReportSeller() {
    const [foundSeller, setFoundSellers] = useState<any[]>([]);
    const [countTotalSellers, setCountTotalSellers] = useState(0);
    const [countTotalSellersPaginated, setCountTotalSellersPaginated] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { allStates } = useAllUf();
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportSellerResponse>("/report/seller", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        })

        setExportTotals([
            { name: 'Total de vendedores', value: count },
        ])

        setExportBodyData(exportDataList);
        setCountTotalSellers(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportSellerResponse>("/report/seller", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundSellers(rows);
        setCountTotalSellersPaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundSeller;

        aux.forEach((seller) => {
            const data = mountBodyDataRow(seller);

            list.push(data);
        });

        setBodyData(list);
    }, [foundSeller]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Seller) {
        let name = reportRow.name;
        let cpf = formatCpfCnpj("CPF", reportRow.cpf);
        const isLegal = reportRow.typePeople === "legal";

        if (isLegal) {
            name = reportRow.corporateName;
            cpf = formatCpfCnpj("CNPJ", reportRow.cnpj);
        }

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "name", value: name },
            { for: "cpf", value: cpf },
            { for: "phone", value: formatPhoneNumber(reportRow.phone) },
            { for: "city", value: reportRow.city },
            { for: "phone", value: reportRow.state },
            { for: "sellerCommission", value: reportRow.sellerCommission ? formatCurrency(formatToFloat(reportRow.sellerCommission)).replace('R$', '') : '' },
        ];
        return data;
    }

    const clearSearch = () => {
        setName('');
        setCity('');
        setState('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            name,
            city,
            state,
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        name,
        city,
        state,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Vendedores', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Nome do vendedor"
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Cidade"
                        margin="normal"
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="col-lg-4">
                    <Autocomplete
                        options={allStates.states}
                        getOptionLabel={({ sigla }) => sigla}
                        value={allStates.states.find(({ sigla }) => sigla === state) ?? null}
                        onInputChange={(event, newInputValue) => setState(newInputValue)}
                        renderInput={(params) => <TextField {...params}
                            size="small"
                            label="Estado"
                            margin="normal"
                            variant="outlined"
                        />
                        }
                    />
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Vendedores"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Vendedores', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalSellersPaginated}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de vendedores</TableCell>
                                    <TableCell colSpan={1}>{countTotalSellers}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}