import React, { useEffect, useState } from 'react';
import { makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import {
    btnSuccess,
    btnWarning,
    colorSuccess,
    colorBlue, 
} from '../../../utils/styles';
import api from '../../../services/Api';
import '../../../style.css';
import { getVariant, Variant } from '../../../utils/getVariant';

export type status = {
    id: number,
    label: string,
    color: string,
    isDefault: string,
    companyId: number
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    btlr: {
        borderTopLeftRadius: 0
    },
    absoluteNav: {
        position: "absolute",
        top: "-50px",
        left: 0,
        background: "#fff",
        borderRadius: "0.42rem 0.42rem 0px 0px",
        height: "50px",
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function ManageStatus() {
    const classes = useStyles();
    
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    
    const [name, setName] = useState("");
    const [color, setColor] = useState("");

    const [idDelete, setIdDelete] = useState(0);

    const [idEdit, setIdEdit] = useState(0);
    const [nameEdit, setNameEdit] = useState("");
    const [colorEdit, setColorEdit] = useState("");

    const [errorName, setErrorName] = useState(false);
    const [errorNameEdit, setErrorNameEdit] = useState(false);
    const [errorColor, setErrorColor] = useState(false);
    const [errorColorEdit, setErrorColorEdit] = useState(false);

    const [allStatus, setAllStatus] = useState<status[]>([]);

    useEffect(() => {
        getAllStatus();
    }, []);

    async function getAllStatus() {
        var statusData = await api.get("/status");
        setAllStatus(statusData.data);
    }

    function editStatus(status: status) {
        setIdEdit(status.id);
        setNameEdit(status.label);
        setColorEdit(status.color);
        setShowModalEdit(true);
    }

    async function submitStatus() {
        if (!name || !color) {
            alert("Preencha todos campos para criar o status!");
            setErrorName(name ? false : true);
            setErrorColor(color ? false : true);
            return;
        }
        
        try {
            setIsSubmitting(true);
            var statusResponse = await api.post('/status', { label: name, color});

            var statusArr = allStatus;
            statusArr.push(statusResponse.data);
            setAllStatus(statusArr);
            cleanForms();

        } catch (e) {
            console.log(e);
        }

        setIsSubmitting(false);
    }

    async function submitEditStatus() {
        if (!nameEdit || !colorEdit) {
            alert("Preencha todos campos para editar o status!");
            setErrorNameEdit(nameEdit ? false : true);
            setErrorColorEdit(colorEdit ? false : true);
            return;
        }
        
        try {
            setIsSubmitting(true);
            await api.put(`/status/${idEdit}`, { label: nameEdit, color: colorEdit});

            var statusArr = allStatus;

            for (var indexStatus = 0; indexStatus < statusArr.length; indexStatus++) {
                if (statusArr[indexStatus].id == idEdit) {
                    statusArr[indexStatus].label = nameEdit;
                    statusArr[indexStatus].color = colorEdit;
                }
            }

            statusArr.sort(function (status1, status2) {
                if ( status1 < status2 ){
                    return -1;
                }
                if ( status1 > status2 ){
                    return 1;
                }
                return 0;
            })
            
            setAllStatus(statusArr);
            cleanForms();           

        } catch (e) {
            console.log(e);
        }

        setIsSubmitting(false);
    }

    async function deleteStatus() {
        setIsDeleting(true);

        try {
            await api.delete(`/status/${idDelete}`);

            var statusArr = allStatus;
            var indexStatusToDelete = statusArr.findIndex((status) => status.id === idDelete);

            if (indexStatusToDelete > -1) {
                statusArr.splice(indexStatusToDelete, 1);
            }

            setAllStatus(statusArr);
            cleanForms();
            await cleanIdStatusInReleated(idDelete);

        } catch (e) {
            console.log(e);
        }
        
        setIsDeleting(false);
    }

    async function cleanIdStatusInReleated(idStatus: number) {
        try {
            var serviceOrders = await api.get("/service-order");
            var orderFiltered = serviceOrders.data.filter((orderData: any) => orderData.customStatusId == idStatus);

            for (var order of orderFiltered) {
                api.put(`/service-order/${order.id}`, {customStatusId: null});
            }

        }catch (e) {
            console.log(e);
        }
    }

    function cleanForms() {
        setName("");
        setErrorName(false);
        
        setNameEdit("");
        setErrorNameEdit(false);
        
        setColor("");
        setErrorColor(false);
        
        setColorEdit("");
        setErrorColorEdit(false);

        setShowModal(false);
        setShowModalEdit(false);
        setShowModalDelete(false);
    }
    
    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Adicionar Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                size="small"
                                label="Nome do Status"
                                margin="normal"
                                variant="outlined"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setErrorName(e.target.value ? false : true);
                                }}
                                error={errorName}
                            />
                        </div>
                        <div className="col-lg-6">
                            <TextField
                                select
                                label="Cor do Status"
                                size="small"
                                margin="normal"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                variant="outlined"
                                value={color}
                                onChange={e => {
                                    setColor(e.target.value);
                                    setErrorColor(e.target.value ? false : true);
                                }}
                                error={errorColor}
                            >
                                <MenuItem key="0" value="primary">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#6993ff", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Azul
                                    </div>
                                </MenuItem>

                                <MenuItem key="1" value="secondary">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#E4E6EF", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Cinza
                                    </div>
                                </MenuItem>

                                <MenuItem key="2" value="success">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#1bc5bd", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Verde
                                    </div>
                                </MenuItem>

                                <MenuItem key="3" value="warning">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#FFA800", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Laranja
                                    </div>
                                </MenuItem>

                                <MenuItem key="4" value="danger">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#F64E60", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Vermelho
                                    </div>
                                </MenuItem>

                            </TextField>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => submitStatus()}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : 
                            <>
                                <span>
                                    Salvar
                                </span>
                            </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(!showModal)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalEdit}
                onHide={() => setShowModalEdit(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Editar Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                size="small"
                                label="Nome do Status"
                                margin="normal"
                                variant="outlined"
                                value={nameEdit}
                                onChange={(e) => {
                                    setNameEdit(e.target.value);
                                    setErrorNameEdit(e.target.value ? false : true);
                                }}
                                error={errorNameEdit}
                            />
                        </div>
                        <div className="col-lg-6">
                            <TextField
                                select
                                label="Cor do Status"
                                size="small"
                                margin="normal"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                variant="outlined"
                                value={colorEdit}
                                onChange={e => {
                                    setColorEdit(e.target.value);
                                    setErrorColorEdit(e.target.value ? false : true);
                                }}
                                error={errorColorEdit}
                            >
                                <MenuItem key="0" value="primary">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#6993ff", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Azul
                                    </div>
                                </MenuItem>

                                <MenuItem key="1" value="secondary">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#E4E6EF", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Cinza
                                    </div>
                                </MenuItem>

                                <MenuItem key="2" value="success">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#1bc5bd", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Verde
                                    </div>
                                </MenuItem>

                                <MenuItem key="3" value="warning">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#FFA800", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Laranja
                                    </div>
                                </MenuItem>

                                <MenuItem key="4" value="danger">
                                    <div className='d-flex flex-row'>
                                        <div style={{ background: "#F64E60", width: "15px", height: "15px", marginRight: "5px", borderRadius: "50px" }}></div> Vermelho
                                    </div>
                                </MenuItem>

                            </TextField>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => submitEditStatus()}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : 
                            <>
                                <span>
                                    Salvar
                                </span>
                            </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModalEdit(!showModalEdit)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalDelete}
                onHide={() => setShowModalDelete(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Deletar Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja deletar este status?</p>
                    <p className="text-danger">* Ao deletar, todos os registros que estavam com este status passarão a ter o status "STATUS EXCLUÍDO"</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() => deleteStatus()}
                        disabled={isDeleting}
                    >
                        {isDeleting ? 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> : 
                            <>
                                <span>
                                    Deletar
                                </span>
                            </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModalDelete(!showModalDelete)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            
            <div className="row col-3">
                <Button
                    type="button"
                    variant="primary"
                    onClick={() => setShowModal(true)}
                >+ Adicionar Status</Button>
            </div>

            {
                allStatus.length
                ?
                <div className="row">
                    <div className="col-lg-12 mt-10">
                        <h3>Lista de Status</h3>
                    </div>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Pré-Visualização</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                allStatus.map((status) => {
                                    const variant: any = status.color;

                                    return (
                                        <TableRow key={status.id}>
                                            <TableCell>{status.label}</TableCell>
                                            <TableCell>
                                                <Badge
                                                    variant={variant}
                                                    style={
                                                        variant == "success"
                                                            ? colorSuccess
                                                            : (
                                                                variant === 'primary'
                                                                    ? colorBlue
                                                                    : {}
                                                            )
                                                    }
                                                >
                                                    {status.label}
                                                </Badge>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    status.isDefault == "n"
                                                    ?
                                                    <>
                                                        <Tooltip TransitionComponent={Zoom} title="Estoque">
                                                            <Button
                                                                className="btn-light-primary p-2 mr-3"
                                                                type="button"
                                                                onClick={() => editStatus(status)}
                                                            >
                                                                <i className="flaticon2-edit p-0"></i>
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip TransitionComponent={Zoom} title="Estoque">
                                                            <Button
                                                                className="btn-light-danger p-2 mr-3"
                                                                type="button"
                                                                onClick={() => {
                                                                    setShowModalDelete(true);
                                                                    setIdDelete(status.id);
                                                                }}
                                                            >
                                                                <i className="flaticon-delete p-0"></i>
                                                            </Button>
                                                        </Tooltip>
                                                    </>
                                                    : <></>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
                : <></>
            }
        </div>
    );
}