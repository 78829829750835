import { Seller } from "../types/Seller";
import { SellerConsignmentProduct } from "../types/SellerConsignment";
import { yesOrNo } from "../types/yesOrNo";
import { formatToFloat } from "../utils/formatCurrency";
import { getTypeOrderIsEntry } from "../utils/getTypeOrderIsEntry";
import { getTypeOrderText } from "../utils/getTypeOrderText";
import { verifyProductsJSON } from "../utils/verifyProductsJSON";
import api from "./Api";
import RequestService from "./RequestService";
import SellerConsignmentService from "./SellerConsignmentService";

type CreateStockHistoricParams = {
    productId: number;
    stockLocationId: number;
    productValue: number;
    type: string;
    observation: string
    total: number;
    totalLocation: number;
    hasVariation: string;
    variationId?: number;
    requestOrPurchaseId?: number;
}

type LaunchInStockParams = {
    typeOrder?: string;
    isOs?: boolean;
    orderSelected: string;
    stockLocationMove: string;
    observationMove?: string;
}

type TotalStockResponse = {
    totalEntry: number;
    totalOutput: number;
    totalStock: number;
    totalInConsignment: number;
    totalBalance: number;
}

class StockService {
    async createStockHistoric({
        productId,
        stockLocationId,
        productValue,
        type,
        observation,
        total,
        totalLocation,
        hasVariation,
        variationId,
        requestOrPurchaseId,
    }: CreateStockHistoricParams) {
        const today = new Date();
        const month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
        const day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

        const stockId = stockLocationId;
        const stockResult = stockId ? await api.get(`/stockLocation/${String(stockId)}`) : null;
        const stockName = stockResult ? stockResult.data.name : '';

        const rawHistoric = {
            productId,
            date: today.getFullYear() + "-" + month + "-" + day,
            observation,
            stockId: stockId,
            stockName: stockName,
            value: productValue,
            type: type,
            totalLocation,
            total,
            hasVariation,
            variationId,
            requestOrPurchaseId,
        }

        const createdHistoric = await api.post("/stockProductHistoric", rawHistoric);
        return createdHistoric;
    }

    async getTotalStock(productId: any, variationId?: any) {
        if (variationId) {
            const result = await api.get<TotalStockResponse>(`/stockProductHistoric/product/${productId}/variation/${variationId}/totalStock`);
            return result.data;

        } else {
            const result = await api.get<TotalStockResponse>(`/stockProductHistoric/product/${productId}/totalStock`);
            return result.data;
        }
    }

    async getTotalLocalStock(productId: any, stockId: any, variationId?: any) {
        if (variationId) {
            const result = await api.get<TotalStockResponse>(`/stockProductHistoric/product/${productId}/variation/${variationId}/stock/${stockId}/totalStock`);
            return result.data;

        } else {
            const result = await api.get<TotalStockResponse>(`/stockProductHistoric/product/${productId}/stock/${stockId}/totalLocalStock`);
            return result.data;
        }
    }

    async getTotalLocalStockAll(stockId: any) {
        const result = await api.get<TotalStockResponse>(`/stockProductHistoric/stock/${stockId}/totalLocalStockAll`);
        return result.data;
    }

    async launchInStock({
        products,
        typeOrder,
        isOs,
        orderSelected,
        stockLocationMove,
        observationMove,
    }: LaunchInStockParams & { products: any[] }) {
        const dateStockMove = new Date();
        const month = dateStockMove.getMonth() + 1 < 10 ? "0" + (dateStockMove.getMonth() + 1) : (dateStockMove.getMonth() + 1);
        const day = dateStockMove.getDate() < 10 ? "0" + dateStockMove.getDate() : dateStockMove.getDate();

        if (!products) {
            return { success: false, message: 'Não há produtos para movimentar!' };
        }

        if (!verifyProductsJSON(products)) {
            return { success: false, message: 'Não há produtos para movimentar!' };
        }

        if (typeOrder === 'request') {
            // Verifica se é uma venda consignada, se for, validar se o vendedor tem estoque consignado o suficiente
            const validateResult = await RequestService.validateRequestSellerConsignmentMoveToStock(orderSelected);
            if (!validateResult.success) {
                return validateResult;
            }
        }

        const stockResult = await api.get(`/stockLocation/${stockLocationMove}`);
        const stockName = stockResult.data.name ?? '';

        products.map((product: any, index: number) => {
            if (product.INSTANCE.hasVariation == "y") {
                this.saveVariationsInStock(product, { typeOrder, isOs, orderSelected, stockLocationMove, observationMove });

            } else if (product.INSTANCE.type == "kit") {
                this.saveProductKitInStock(product, { typeOrder, isOs, orderSelected, stockLocationMove, observationMove });

            } else {
                this.getTotalStock(product.INSTANCE.id).then((totalStock) => {
                    this.getTotalLocalStock(product.INSTANCE.id, stockLocationMove).then((totalLocalStock) => {
                        let rawHistoric: any = {
                            productId: Number(product.INSTANCE.id),
                            date: dateStockMove.getFullYear() + "-" + month + "-" + day,
                            observation: observationMove ?? null,
                            stockId: Number(stockLocationMove),
                            stockName: stockName,
                            value: product.unitaryValue ? product.unitaryValue : null,
                            totalLocation: totalLocalStock.totalBalance - product.amount,
                            total: totalStock.totalBalance - product.amount,
                            hasVariation: "n",
                            requestOrPurchaseId: Number(orderSelected),
                            type: getTypeOrderText(typeOrder),
                        };

                        const isEntry = getTypeOrderIsEntry(typeOrder);
                        if (isEntry) {
                            rawHistoric.entry = product.amount;
                            rawHistoric.totalLocation = totalLocalStock.totalBalance + product.amount;
                            rawHistoric.total = totalStock.totalBalance + product.amount;
                        } else {
                            rawHistoric.output = product.amount;
                            rawHistoric.totalLocation = totalLocalStock.totalBalance - product.amount;
                            rawHistoric.total = totalStock.totalBalance - product.amount;
                        }

                        if (rawHistoric) {
                            api.post("/stockProductHistoric", rawHistoric);
                        }
                    });
                });
            }
        });

        const rawOrder = {
            movedToStock: "y"
        }

        if (typeOrder == "service" && isOs) {
            api.put(`/service-order/${orderSelected}`, rawOrder);
        } else if (typeOrder == "request") {
            api.put(`/requests/${orderSelected}`, rawOrder);
            SellerConsignmentService.saveConsignmentRequestStockMoved(orderSelected);
        } else if (typeOrder == "purchase") {
            api.put(`/purchase-order/${orderSelected}`, rawOrder);
        } else if (typeOrder == "entry") {
            api.put(`/entry/${orderSelected}`, rawOrder);
        }

        return { success: true, message: '' };
    }

    private async saveVariationsInStock(product: any, { typeOrder, isOs, orderSelected, stockLocationMove, observationMove }: LaunchInStockParams) {
        const { data } = await api.get(`/variations/${product.INSTANCE.id}`);

        const dateStockMove = new Date();
        const month = dateStockMove.getMonth() + 1 < 10 ? "0" + (dateStockMove.getMonth() + 1) : (dateStockMove.getMonth() + 1);
        const day = dateStockMove.getDate() < 10 ? "0" + dateStockMove.getDate() : dateStockMove.getDate();

        const stockResult = await api.get(`/stockLocation/${stockLocationMove}`);
        const stockName = stockResult.data.name ?? '';
        this.getTotalStock(data.productId, data.id).then((totalStock) => {
            this.getTotalLocalStock(data.productId, stockLocationMove, data.id).then((totalLocalStock) => {
                let rawHistoric: any = {
                    productId: Number(data.productId),
                    date: dateStockMove.getFullYear() + "-" + month + "-" + day,
                    observation: observationMove ?? null,
                    stockId: Number(stockLocationMove),
                    stockName: stockName,
                    value: formatToFloat(data.saleValue) ? formatToFloat(data.saleValue) : null,
                    hasVariation: "y",
                    variationId: data.id,
                    requestOrPurchaseId: Number(orderSelected),
                    type: getTypeOrderText(typeOrder),
                };

                const isEntry = getTypeOrderIsEntry(typeOrder);
                if (isEntry) {
                    rawHistoric.entry = product.amount;
                    rawHistoric.totalLocation = totalLocalStock.totalBalance + product.amount;
                    rawHistoric.total = totalStock.totalBalance + product.amount;
                } else {
                    rawHistoric.output = product.amount;
                    rawHistoric.totalLocation = totalLocalStock.totalBalance - product.amount;
                    rawHistoric.total = totalStock.totalBalance - product.amount;
                }

                if (rawHistoric) {
                    api.post("/stockProductHistoric", rawHistoric);
                }
            })
        });
    }

    private async saveProductKitInStock(product: any, { typeOrder, isOs, orderSelected, stockLocationMove, observationMove }: LaunchInStockParams) {
        const { data } = await api.get(`/products/${product.INSTANCE.id}`);

        const products = JSON.parse(data.kit);

        const dateStockMove = new Date();
        const month = dateStockMove.getMonth() + 1 < 10 ? "0" + (dateStockMove.getMonth() + 1) : (dateStockMove.getMonth() + 1);
        const day = dateStockMove.getDate() < 10 ? "0" + dateStockMove.getDate() : dateStockMove.getDate();

        products.map((value: any, index: number) => {
            this.getTotalStock(value.productId).then((totalStock) => {
                this.getTotalLocalStock(value.productId, stockLocationMove).then((totalLocalStock) => {
                    api.get(`/products/${value.productId}`).then((productResponse: any) => {
                        let rawHistoric: any = {
                            productId: productResponse.data.id,
                            date: dateStockMove.getFullYear() + "-" + month + "-" + day,
                            observation: observationMove ?? null,
                            stockId: Number(stockLocationMove),
                            value: productResponse.data.saleValue ? formatToFloat(productResponse.data.saleValue) : null,
                            hasVariation: "n",
                            requestOrPurchaseId: Number(orderSelected),
                            type: getTypeOrderText(typeOrder),
                        }

                        const isEntry = getTypeOrderIsEntry(typeOrder);
                        if (isEntry) {
                            rawHistoric.entry = value.productCount;
                            rawHistoric.totalLocation = totalLocalStock.totalBalance + value.productCount;
                            rawHistoric.total = totalStock.totalBalance + value.productCount;
                        } else {
                            rawHistoric.output = value.productCount;
                            rawHistoric.totalLocation = totalLocalStock.totalBalance - value.productCount;
                            rawHistoric.total = totalStock.totalBalance - value.productCount;
                        }

                        if (rawHistoric) {
                            api.post("/stockProductHistoric", rawHistoric);
                        }
                    });
                });
            });

        });

    }
}

export default new StockService();