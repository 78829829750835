import { Currency } from "../types/CurrencyProps";

export const { format: formatCurrency } = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
});

export const currencyFormat = (value: number, { locale = 'pt-br', style = 'currency', currency = 'BRL', prefixSymbol = true, monetarySymbol = 'R$' }: Currency) => {
    const { format: formatCurrency } = new Intl.NumberFormat(locale, {
        style: style,
        currency: currency
    });

    if (prefixSymbol) {
        return formatCurrency(value);
    }

    const asCurrency = formatCurrency(value);
    let symbol = monetarySymbol || '';

    switch(locale) {
        case 'pt-br': 
            symbol = 'R$';
        break;
    }

    return asCurrency.replace(symbol, '');
}

export const formatNumberToString = (number: number) => {
    const asCurrency = formatCurrency(number);
    return asCurrency.replace('R$ ', '').replace('R$', '');
}

export function formatIntegerToMoney(value: number, prefix = false): string {
    let stringVal = String(value).padStart(2, '0');
    let strPos = stringVal.length - 2;
    let floatVal = parseFloat(stringVal.substr(0,strPos)+"."+stringVal.substr(strPos));

    if(prefix) {
        return floatVal.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    } else {
        return floatVal.toLocaleString('pt-br', {minimumFractionDigits: 2});
    }
}

export function formatMoneyToInteger(value: string | number): number {
    const stringValue = typeof value === 'number' ? value.toFixed(2) : String(value);
    const stringFormatted = stringValue.replaceAll('R$ ', '').replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '');
    return parseInt(stringFormatted);
}

export function formatIntegerToFloat(value: number): number {
    let stringVal = String(value).padStart(2, '0');
    let strPos = stringVal.length - 2;
    let floatVal = parseFloat(stringVal.substr(0,strPos)+"."+stringVal.substr(strPos));

    return floatVal;
}

export function formatFloatToInteger(value: number): number {
    const float_val = formatToFloat(value);
    let string_val = float_val.toFixed(2);
    let int_val = parseInt(string_val.replace('.', ''));

    return int_val;
}

export function formatToFloat(value: any) {
    if(isNaN(value) === false) {
        return Number(value) || 0;
    }
    
    var auxValue = value ? String(value) : '0';

    const regex  = /[A-z]|[!@#$%¨&*()-+;/\\'" ]/g; 
    
    auxValue = auxValue.replace(regex, '');
    auxValue = auxValue || '0';

    if (auxValue.includes(',') && !auxValue.includes('.')) {
        const floatValue = parseFloat(auxValue.replace(',', '.'));
        return floatValue ? floatValue : 0;
       
    } else {
        const floatValue = parseFloat(auxValue.replaceAll('.', '').replace(',', '.'));
        return floatValue ? floatValue : 0;
    }
}