import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import { Grid, GridProps } from '@material-ui/core';

import api from '../../services/Api';
import {
    Category,
    RevenueAppearInsideDefaultOptions,
} from '../../hooks/parameters';
import { useSelector } from 'react-redux';


const GridItem = styled(Grid)<GridProps>(() => ({
    height: '39.38px',
    border: '1px solid #BDBDBD',
    borderTop: 0,
    transition: 'background 0.2s',
    '&:hover': {
        background: "#F9F9F9"
    }
}));


interface RevenueListItemProps {
    type: "others" | "retention" | "no-group";
}


export const RevenueListItem: React.FC<RevenueListItemProps> = ({ type }) => {
    const { user } = useSelector((state: any) => state.auth);

    const [revenueToShow, setRevenueToshow] = useState(['']);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        async function loadData() {
            const response = await api.get("categories");

            setCategories(response.data);
        }

        loadData();

        switch (type) {
            case "others":
                setRevenueToshow(RevenueAppearInsideDefaultOptions.others);
                break;
            case "retention":
                setRevenueToshow(RevenueAppearInsideDefaultOptions.profitRetention);
                break;
            case "no-group":
                setRevenueToshow(RevenueAppearInsideDefaultOptions.revenueWithNoFinancialGroup);
                break;
            default:
                break;
        }
    }, []);

    const handleDelete = async (id: number) => {
        await api.delete(`category/${id}`);

        setCategories((state) => [...state.filter(category => category.id === id)]);
    };

    const handleHoverDelete = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
        evt.currentTarget.style.transition = 'background 0.2';
        evt.currentTarget.style.background = '#E0E0E0';
    }, []);

    const handleLeaveDelete = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
        evt.currentTarget.style.transition = 'background 0.2';
        evt.currentTarget.style.background = 'transparent';
    }, []);

    return (
        <div>
            {
                revenueToShow.map((revenue: string) => {
                    const haveInside = categories.find(
                        cat => cat.appearInside.toUpperCase() === revenue.toUpperCase()
                    );

                    if (haveInside) {
                        return (
                            <>
                                <GridItem key={revenue} item>
                                    <a className="btn" style={{ width: '100%', textAlign: 'left' }}>
                                        <span className="p-6 mb-0 ml-12">
                                            {revenue}
                                        </span>
                                    </a>
                                </GridItem>
            
                                <GridItem
                                    item
                                    key={haveInside.description}
                                >
                                    <Link to={`/parametros/categorias/${haveInside.id}`} className="btn" style={{ width: '80%', textAlign: 'left' }}>
                                        <span className="p-6 mb-0 ml-20">
                                            {haveInside.description}
                                        </span>
                                    </Link>
            
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <button
                                            className="btn font-weight-bold px-3 py-1"
                                            onClick={() => handleDelete(haveInside.id)}
                                            onMouseOver={(evt) => handleHoverDelete(evt)}
                                            onMouseLeave={(evt) => handleLeaveDelete(evt)}
                                        >
                                            x
                                        </button>
                                        : <></>
                                    }
                                </GridItem>
                            </>
                        );
                    }

                    return (
                        <GridItem key={revenue} item>
                            <a className="btn" style={{ width: '100%', textAlign: 'left' }}>
                                <span className="p-6 mb-0 ml-12">
                                    {revenue}
                                </span>
                            </a>
                        </GridItem>
                    );
                })
            }
        </div>
    );
}
