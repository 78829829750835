import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditNfe } from '../pages/Nfe/Edit-Nfe';
import {ListNfe} from '../pages/Nfe/List-Nfe';
import {NewNfe} from '../pages/Nfe/New-Nfe';

export function Nfe() {
    
    return (
        <Switch>
            <Route path="/notas-fiscais/:typeOrder/:orderId/adicionar" component={NewNfe}/>
            <Route path="/notas-fiscais/adicionar" component={NewNfe}/>
            <Route path="/notas-fiscais/duplicar/:idToClone" component={NewNfe}/>
            <Route path="/notas-fiscais/:id" component={EditNfe}/>
            <Route path="/notas-fiscais" component={ListNfe}/>
        </Switch>
    );
}