import { NfApiSituation } from "../types/NfApiSituation";

const isProccessing = (api_situation: string|null) => {
    return api_situation === NfApiSituation.PROCESSING.toString();
}

const isAuthorized = (api_situation: string|null) => {
    return api_situation === NfApiSituation.AUTHORIZED.toString();
}

const isCanceled = (api_situation: string|null) => {
    return api_situation === NfApiSituation.CANCELED.toString() || api_situation === NfApiSituation.PROCESSING_TIMEOUT.toString();
}

const hasDetails = (api_situation: string|null) => {
    return api_situation !== null && ![NfApiSituation.ERROR_API.toString()].includes(api_situation);
}

const canEdit = (api_situation: string|null) => {
    return api_situation === null || ![
        NfApiSituation.AUTHORIZED.toString(),
        NfApiSituation.PROCESSING.toString(),
        NfApiSituation.CANCELED.toString(),
        NfApiSituation.PROCESSING_TIMEOUT.toString()
    ].includes(api_situation);
}
const canIssue = (api_situation: string|null) => {
    return api_situation === null || ![
        NfApiSituation.AUTHORIZED.toString(),
        NfApiSituation.PROCESSING.toString(),
        NfApiSituation.CANCELED.toString(),
        NfApiSituation.PROCESSING_TIMEOUT.toString(),
    ].includes(api_situation);
}
const hasError = (api_situation: string|null) => {
    return api_situation === NfApiSituation.ERROR_AUTHORIZATION;
}

const getStatus = (api_situation: string|null) => {
    switch(api_situation) {
        case NfApiSituation.PROCESSING.toString():
            return 'Em andamento';
        case NfApiSituation.AUTHORIZED.toString():
            return 'Autorizado';
        case NfApiSituation.ERROR_AUTHORIZATION.toString():
            return 'Erro de autorização';
        case NfApiSituation.CANCELED.toString():
            return 'Cancelado';
        case NfApiSituation.DENIED.toString():
            return 'Denegado';
        case NfApiSituation.PROCESSING_TIMEOUT.toString():
            return 'Cancelado';
        default:
            return 'Aguardando';
    }
}

export default {
    isProccessing,
    isAuthorized,
    isCanceled,
    hasDetails,
    canEdit,
    canIssue,
    hasError,
    getStatus
};