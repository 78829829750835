import React from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useStyles } from "../../../hooks/styles";
import { Button } from "react-bootstrap";
import { addressSearch } from "../../../utils/addressSearch";
import { Autocomplete } from "@material-ui/lab";

type CompanyFormAccountantProps = {
    accountantId?: string;
    accountantName?: string;
    onChangeAccountantName?: (value: string) => void;
    accountantCpf: string;
    onChangeAccountantCpf: (value: string) => void;
    accountantCnpj: string;
    onChangeAccountantCnpj: (value: string) => void;
    accountantCrc: string;
    onChangeAccountantCrc: (value: string) => void;
    accountantEmail?: string;
    onChangeAccountantEmail?: (value: string) => void;
    accountantPhone: string;
    onChangeAccountantPhone: (value: string) => void;
    accountantCell?: string;
    onChangeAccountantCell?: (value: string) => void;
    accountantZipCode: string;
    onChangeAccountantZipCode: (value: string) => void;
    accountantAddress: string;
    onChangeAccountantAddress: (value: string) => void;
    accountantNumber: string;
    onChangeAccountantNumber: (value: string) => void;
    accountantDistrict: string;
    onChangeAccountantDistrict: (value: string) => void;
    accountantComplement: string;
    onChangeAccountantComplement: (value: string) => void;
    accountantCity: string;
    onChangeAccountantCity: (value: string) => void;
    accountantState: string;
    onChangeAccountantState: (value: string) => void;
    accountantNameHasError?: boolean;
    accountantEmailHasError?: boolean;
    accountantCellHasError?: boolean;
    accountantCpfHasError?: boolean;
    accountantCnpjHasError?: boolean;
    allStates: { states: any[] };
    CheckCnpj: (value: string) => Promise<void>;
    infoAddress: (value: string) => Promise<void>;
    currentIndex: number;
    onAccountantRemove?: (index: number) => void;
    currentUserIsAccountant?: boolean;
}

export function CompanyFormAccountant({
    accountantId,
    accountantName, onChangeAccountantName,
    accountantCpf, onChangeAccountantCpf,
    accountantCnpj, onChangeAccountantCnpj,
    accountantCrc, onChangeAccountantCrc,
    accountantEmail, onChangeAccountantEmail,
    accountantPhone, onChangeAccountantPhone,
    accountantCell, onChangeAccountantCell,
    accountantZipCode, onChangeAccountantZipCode,
    accountantAddress, onChangeAccountantAddress,
    accountantNumber, onChangeAccountantNumber,
    accountantDistrict, onChangeAccountantDistrict,
    accountantComplement, onChangeAccountantComplement,
    accountantCity, onChangeAccountantCity,
    accountantState, onChangeAccountantState,

    accountantNameHasError,
    accountantEmailHasError,
    accountantCellHasError,
    accountantCpfHasError,
    accountantCnpjHasError,

    allStates,
    CheckCnpj,
    infoAddress,
    currentIndex,
    onAccountantRemove,
    currentUserIsAccountant,
}: CompanyFormAccountantProps) {
    const classes = useStyles();

    const accountantNameFieldVisible = accountantName !== undefined && onChangeAccountantName !== undefined;
    const accountantEmailFieldVisible = accountantEmail !== undefined && onChangeAccountantEmail !== undefined;
    const accountantCellFieldVisible = accountantCell !== undefined && onChangeAccountantCell !== undefined;

    return (
        <>
            {!!onAccountantRemove && !accountantId && !currentUserIsAccountant && (
                <div className="row mt-3">
                    <div className="col d-flex justify-content-end align-items-center">
                        <Button
                            variant='danger'
                            onClick={() => onAccountantRemove(currentIndex)}
                        >
                            Excluir
                        </Button>
                    </div>
                </div>
            )}
            <div className="row">
                {accountantNameFieldVisible && (
                    <div className="col-lg-3">
                        <TextField
                            value={accountantName}
                            label="Nome do Contador*"
                            size="small"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                                onChangeAccountantName!(e.target.value)
                            }}
                            error={accountantNameHasError}
                            disabled={currentUserIsAccountant}
                        />
                    </div>
                )}

                <div className="col-lg-3">
                    <NumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        format="###.###.###-##"
                        label="CPF"
                        mask="_"
                        value={accountantCpf}
                        onChange={(e) => {
                            onChangeAccountantCpf(e.target.value);
                        }}
                        error={accountantCpfHasError}
                        disabled={currentUserIsAccountant}
                    />
                </div>
                <div className="col-lg-3">
                    <NumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="CNPJ"
                        format="##.###.###/####-##"
                        mask="_"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="CNPJ search"
                                        onClick={() => CheckCnpj(accountantCnpj)}
                                    >
                                        <i className="flaticon-search"></i>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={accountantCnpj}
                        onChange={(e) => {
                            onChangeAccountantCnpj(e.target.value)
                        }}
                        error={accountantCnpjHasError}
                        disabled={currentUserIsAccountant}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        value={accountantCrc}
                        label="CRC"
                        size="small"
                        className={classes.error}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => {
                            onChangeAccountantCrc(e.target.value)
                        }}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                {accountantEmailFieldVisible && (
                    <div className="col-lg-4">
                        <TextField
                            size="small"
                            label="E-mail*"
                            className={classes.error}
                            margin="normal"
                            variant="outlined"
                            value={accountantEmail}
                            onChange={(e) => {
                                onChangeAccountantEmail!(e.target.value)
                            }}
                            error={accountantEmailHasError}
                            disabled={currentUserIsAccountant}
                        />
                    </div>
                )}

                <div className="col-lg-4">
                    <NumberFormat
                        margin="normal"
                        className={classes.error + ' inputSmaller'}
                        customInput={TextField}
                        variant="outlined"
                        format="(##) ####-####"
                        label="Telefone"
                        mask="_"
                        value={accountantPhone}
                        onChange={(e) => {
                            onChangeAccountantPhone(e.target.value)
                        }}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                {accountantCellFieldVisible && (
                    <div className="col-lg-4">
                        <NumberFormat
                            margin="normal"
                            className={classes.error + ' inputSmaller'}
                            customInput={TextField}
                            variant="outlined"
                            format="(##) #####-####"
                            label="Celular*"
                            mask="_"
                            value={accountantCell}
                            onChange={(e) => {
                                onChangeAccountantCell!(e.target.value)
                            }}
                            error={accountantCellHasError}
                            disabled={currentUserIsAccountant}
                        />
                    </div>
                )}

                <div className="col-lg-4 d-flex justify-content-between align-items-center">
                    <NumberFormat
                        margin="normal"
                        customInput={TextField}
                        className="mr-3 inputSmaller"
                        variant="outlined"
                        format="#####-###"
                        label="CEP"
                        mask="_"
                        value={accountantZipCode}
                        onChange={(e) => onChangeAccountantZipCode(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />

                    <Button variant="primary" onClick={() => infoAddress(accountantZipCode)} className="btn-sm"><i className="flaticon-search p-0"></i></Button>
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Endereço"
                        margin="normal"
                        variant="outlined"
                        value={accountantAddress}
                        onChange={(e) => onChangeAccountantAddress(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Número"
                        margin="normal"
                        variant="outlined"
                        value={accountantNumber}
                        onChange={(e) => onChangeAccountantNumber(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Bairro"
                        margin="normal"
                        variant="outlined"
                        value={accountantDistrict}
                        onChange={(e) => onChangeAccountantDistrict(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Complemento"
                        margin="normal"
                        variant="outlined"
                        value={accountantComplement}
                        onChange={(e) => onChangeAccountantComplement(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Cidade"
                        margin="normal"
                        variant="outlined"
                        value={accountantCity}
                        onChange={(e) => onChangeAccountantCity(e.target.value)}
                        disabled={currentUserIsAccountant}
                    />
                </div>

                <div className="col-lg-4 pt-3">
                    <Autocomplete
                        size="small"
                        value={{ sigla: accountantState }}
                        options={allStates.states}
                        getOptionLabel={({ sigla }) => sigla}
                        style={{ width: "100%", marginTop: "6px" }}
                        onInputChange={(event, newInputValue) => {
                            onChangeAccountantState(newInputValue);
                        }}
                        disabled={currentUserIsAccountant}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Estado"
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            </div>
        </>
    )
}