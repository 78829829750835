import React from "react";
import { CustomerLimitCreditInformation } from "../types/CustomerLimitCreditInformation";
import { formatCurrency } from "../utils/formatCurrency";

type CustomerLimitCreditAlertProps = {
    customerLimitCreditInformation?: CustomerLimitCreditInformation;
}

export function CustomerLimitCreditAlert({ customerLimitCreditInformation }: CustomerLimitCreditAlertProps) {
    if (!customerLimitCreditInformation) {
        return null;
    }

    return (
        <div className="row">
            <div className="col bg-warning-o-30 p-5 mb-3">
                <h4>
                    O cliente selecionado possui limite de crédito definido.
                    {' '} <strong>Limite: </strong> {formatCurrency(customerLimitCreditInformation.limit)}
                    {' '} <strong>Débito atual: </strong>
                    <span
                        className={`${customerLimitCreditInformation.currentCreditThisPeriod > customerLimitCreditInformation.limit ? 'text-danger' : ''}`}
                    >
                        {formatCurrency(customerLimitCreditInformation.currentCreditThisPeriod)}
                    </span>
                </h4>
            </div>
        </div>
    );
}