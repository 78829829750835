import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import {ListSellers} from '../pages/Seller/List-Sellers';
import {NewSeller} from '../pages/Seller/New-Seller';
import {EditSeller} from '../pages/Seller/Edit-Seller';

export function Sellers() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Vendedores");
    
    return (
        <Switch>
            <Route path="/vendedores/adicionar" component={NewSeller}/>
            <Route path="/vendedores/:id" component={EditSeller}/>
            <Route path="/vendedores" component={ListSellers}/>
        </Switch>
    );
}