import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button } from "react-bootstrap";
import { humanFileSize } from "../utils/FilesHelper";
import { api as ApiUrl } from "../services/ApiURL";
import { getFileExtension } from "../utils/getFileExtension";

type InputFileProps = {
    label: string,
    filesSelected: File[] | null,
    setFilesSelected: React.Dispatch<React.SetStateAction<File[] | null>>,
    accept?: string
    disabled?: boolean,
    filesNamesSaved: string[],
    setFilesNamesSaved: React.Dispatch<React.SetStateAction<string[]>>
}

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: "flex",
        alignItems: "center"
    },
    containerAttachment: {
        backgroundColor: "#f9f9f9",
        border: "2px dotted #ccc",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        margin: "0",
        height: "auto"
    },
    itemFiles: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
        border: "1px solid #eee",
        borderRadius: "10px",
        padding: "5px",
    },
    shortIconFile: {
        border: "1px solid #eee",
        borderRadius: "10px",
        padding: "2px",
        width: "100px",
    },
    fileName: {
        fontSize: "16px",
        marginLeft: "10px",
        marginBottom: "0",
    },
    fileSize: {
        fontSize: "10px",
        marginLeft: "10px",
        marginBottom: "0",
    }
}));

export default function UploadFiles({
    label,
    filesSelected,
    setFilesSelected,
    accept,
    disabled = false,
    filesNamesSaved,
    setFilesNamesSaved,
}: InputFileProps) {
    const classStyles = useStyles();

    const handleChangePhotoFile = (fileList: FileList | null) => {
        if (fileList) {
            let arrayFiles = Array.from(fileList) as File[];
            let newArrayFiles = [...arrayFiles];

            if (newArrayFiles.length) {
                setFilesSelected((prevState) => prevState ? [...prevState, ...newArrayFiles] : newArrayFiles);
            }
        }
    };

    const removeFileSelected = (index: number) => {
        setFilesSelected((prevState) => {
          if (prevState) {
            const updatedFiles = [...prevState];
            updatedFiles.splice(index, 1);
            return updatedFiles;
          } else {
            return null;
          }
        });
    };

    const removeFileSaved = (index: number) => {
        setFilesNamesSaved((prevState) => {
            if (prevState) {
              const updatedFiles = [...prevState];
              updatedFiles.splice(index, 1);
              return updatedFiles;
            } else {
              return prevState;
            }
        });
    };

    const handleDownload = async (fileName: string) => {
        try {
          const response = await fetch(`${ApiUrl}/${fileName}`);
    
          if (!response.ok) {
            throw new Error(`Failed to download file: ${response.statusText}`);
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.click();
    
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
    };

    return <>
        <label className="m-0">{label}</label>
        <label htmlFor="inputMultipleFiles" className={classStyles.inputFile}>
            <label htmlFor="inputMultipleFiles" className="btn btn-sm btn-dark mr-3" style={{ marginBottom: "0 !important"}}>
                <i className="flaticon2-folder p-0 mr-3"></i>
                Selecione
            </label>
            {filesSelected ? `${filesSelected.length} arquivos selecionados` : 'Nenhum arquivo selecionado'}
        </label>

        {
            filesSelected?.length
            ?
            <div className="col-12 mt-3 d-flex flex-column">
                <b>Lista de Arquivos Selecionados</b>
                {
                    filesSelected.map((file, index) => {
                        return <div key={`${index}-attachment`} className={classStyles.itemFiles}>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    ["png", "jpg", "jpeg"].includes(getFileExtension(file.name))
                                    ? <img src={URL.createObjectURL(file)} className={classStyles.shortIconFile} />
                                    : <div style={{width: "100px", textAlign: "center"}}><i className="flaticon2-file p-0" style={{fontSize: "35px"}}></i></div>
                                }
                                <div className="d-flex flex-column">
                                    <label className={classStyles.fileName}>{file.name}</label>
                                    {/* <label className={classStyles.fileSize}>{humanFileSize(file.size, true)}</label> */}
                                </div>
                            </div>
                            <Button
                                type="button"
                                variant="outline-light"
                                size="sm"
                                onClick={() => removeFileSelected(index)}
                            >
                                <i className="flaticon2-delete p-0"></i>
                            </Button>
                        </div>
                    })
                }
            </div>
            : <></>
        }
        <input
            id="inputMultipleFiles"
            type="file"
            onChange={(e) => handleChangePhotoFile(e.target.files)}
            accept={accept}
            disabled={disabled}
            multiple={true}
            hidden={true}
        />
        {
            filesNamesSaved.length
            ?
            <div className="col-12 mt-3 d-flex flex-column">
                <b>Anexos Salvos</b>
                {
                    filesNamesSaved.map((fileName, index) => {
                        return <div key={`${index}-attachment`} className={classStyles.itemFiles}>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    ["png", "jpg", "jpeg"].includes(getFileExtension(fileName))
                                    ? <img src={`${ApiUrl}/${fileName}`} className={classStyles.shortIconFile} />
                                    : <div style={{width: "100px", textAlign: "center"}}><i className="flaticon2-file p-0" style={{fontSize: "35px"}}></i></div>
                                }
                                <div className="d-flex flex-column">
                                    <label className={classStyles.fileName}>{fileName}</label>
                                    {/* <label className={classStyles.fileSize}>{humanFileSize(file.size, true)}</label> */}
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <Button
                                    type="button"
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => handleDownload(fileName)}
                                    className="mr-3"
                                    disabled={disabled}
                                >
                                    <i className="flaticon2-download p-0"></i>
                                </Button>
                                <Button
                                    type="button"
                                    variant="outline-light"
                                    size="sm"
                                    onClick={() => removeFileSaved(index)}
                                    disabled={disabled}
                                >
                                    <i className="flaticon2-delete p-0"></i>
                                </Button>
                            </div>
                        </div>
                    })
                }
            </div>
            : <></>
        }
    </>;
}