import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NewCustomer } from '../pages/Customers/New-Customer';
import { EditCustomer } from '../pages/Customers/Edit-Customer';
import { ListCustomer } from '../pages/Customers/List-Customer';
import { ImportExcelCustomer } from '../pages/Customers/ImportExcel-Customer';

export function Customer() {
    
    return (
        <Switch>
            <Route path='/clientes/importar-excel' component={ImportExcelCustomer} />
            <Route path="/clientes/Adicionar" component={NewCustomer}/>
            <Route path="/clientes/:id" component={EditCustomer}/>
            <Route path="/clientes" component={ListCustomer}/>
        </Switch>
    );
}