import { useCallback, useState } from "react";
import api from "../services/Api";
import { createSameOriginUrl } from "../utils/createSameOriginUrl";
import { getFileNameFromUrl } from "../utils/getFileNameFromUrl";

export default function useNfceDetails() {
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [cancelNote, setCancelNote] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelError, setCancelError] = useState("");
    const [nfceDetails, setNfceDetails] = useState<any>();

    const cancelNfce = useCallback(async () => {
        setCancelError('');
        if (!cancelReason) {
            setCancelError('Preencha a justificativa do cancelamento');
            return;
        }
        if (cancelReason.length < 15 || cancelReason.length > 255) {
            setCancelError('Campo justificativa deve ter entre 15 e 255 caracteres');
            return;
        }

        try {
            const response = await api.post(`nfce/cancel/${nfceDetails.id ?? 0}`, {
                reason: cancelReason,
            });

            setNfceDetails({
                ...response.data,
                api_last_response: JSON.parse(response.data.api_last_response),
            });

            setCancelNote(false);
            setCancelReason('');

            return response;

        } catch (error: any) {
            setCancelError(error.response?.data?.message ?? 'Erro ao cancelar a nota');
            return;
        }
    }, [cancelReason, nfceDetails]);

    const getNfe = useCallback(async (id: string | number) => {
        const response = await api.get(`nfce/${id}`);

        if(!response.data) {
            setNfceDetails(null);
        }

        const apiResponse = response.data.api_last_response ? JSON.parse(response.data.api_last_response) : null;

        let xmlUrl = '';
        let xmlFileName = '';
        let xmlCancelUrl = '';
        let xmlCancelFileName = '';

        //caminho_xml_cancelamento

        if(apiResponse?.body?.caminho_xml_nota_fiscal) {
            xmlUrl = await createSameOriginUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_nota_fiscal}`);
            xmlFileName = getFileNameFromUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_nota_fiscal}`);
        }

        if(apiResponse?.body?.caminho_xml_cancelamento) {
            xmlCancelUrl = await createSameOriginUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_cancelamento}`);
            xmlCancelFileName = getFileNameFromUrl(`${apiResponse.url}${apiResponse.body.caminho_xml_cancelamento}`);
        }

        setNfceDetails({
            ...response.data,
            xmlUrl,
            xmlFileName,
            xmlCancelUrl,
            xmlCancelFileName,
            api_last_response: JSON.parse(response.data.api_last_response),
        });
    }, []);

    return {
        showModalDetails, setShowModalDetails,
        cancelNote, setCancelNote,
        cancelReason, setCancelReason,
        nfceDetails, setNfceDetails,
        cancelError, setCancelError,
        cancelNfce,
        getNfe,
    };
}