import { Product } from "../services/ProductService";

export type SellerConsigmentHistory = {
    date: Date;
    type: string;
    product: string;
    description: string;
};

export type SellerConsignmentProduct = {
    id: number;
    productId: number;
    amount: number;
    stockLocationId: number;
    comments?: string;
    INSTANCE: Product;
    consignmentId?: number;
}

export enum SellerConsignmentStatus {
    pending = 'pending',
    returned = 'returned',
    late = 'late',
    sold = 'sold',
}

export type SellerConsignment = {
    id: number;
    sellerId: number;
    description: string;
    products: string;
    startDate: Date;
    finalDate: Date;
    totalValueProducts: number;
    totalQuantityProducts: number;
    notes: string;
    situation: string;
    status: SellerConsignmentStatus;
    createdDate: Date;

    history: SellerConsigmentHistory[];
}