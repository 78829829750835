import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { ServiceContractProvider } from '../pages/ServiceContract/context';
import { EditServiceContract } from '../pages/ServiceContract/Edit-ServiceContract';
import { ListServiceContract } from '../pages/ServiceContract/List-ServiceContract';
import { NewServiceContract } from '../pages/ServiceContract/New-ServiceContract';

export function ServiceContract() {
  return (
    <ServiceContractProvider>
      <Switch>
        <Route path='/contrato-servico/adicionar' component={NewServiceContract} />
        <Route path='/contrato-servico/duplicar/:idToClone' component={NewServiceContract} />
        <Route path='/contrato-servico/:id' component={EditServiceContract} />
        <Route path='/contrato-servico' component={ListServiceContract} />
      </Switch>
    </ServiceContractProvider>
  )
}
