import { formatToFloat } from "./formatCurrency";

export default function calculateInstallmentsTotals(installmentsArray: any, totalValue: number){
    if (installmentsArray && installmentsArray.length) {
        let sum = installmentsArray.reduce((sumVal: any, a: any) => sumVal + a.value, 0);
        let tval = formatToFloat(totalValue);
        if (sum > tval) {
            let diff = sum - tval;
            installmentsArray[0].value -= diff;
        } else if (sum < tval) {
            let diff = tval - sum;
            installmentsArray[0].value += diff;
        }
        installmentsArray[0].value = Number(installmentsArray[0].value.toFixed(2));
    }

    return installmentsArray;
}
