import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Checkbox, 
        FormControlLabel, 
        MenuItem, 
        Table, 
        TableBody, 
        TableCell, 
        TableContainer, 
        TableHead, 
        TableRow, 
        TextField 
} from '@material-ui/core';
import ModalError from '../../components/ModalError';
import api from '../../services/Api';
import { formatCurrency } from '../../utils/formatCurrency';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import fs from 'fs';
import CustomerService from '../../services/CustomerService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import { BankAccount } from '../../types/BankAccount';
import { createSameOriginUrl } from '../../utils/createSameOriginUrl';
import { BillsToReceive } from '../../types/BillsToReceive';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../hooks/backendReload';

type billsToReceive = {
    id: number,
    check: boolean,
    billet: number,
    name: string,
    customer: number | string,
    customerName: string,
    dueDate: string,
    doc: string,
    value: number,
    occurrence: string,
    generateRemittance: string;
    remittanceDocument: string;
    composeIds: string|undefined;

    docDownloadUrl?: string;
    docDownloadName?: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "billetNumber", value: "Boleto" },
    { reference: "name", value: "Cliente" },
    { reference: "date", value: "Vencimento" },
    { reference: "doc", value: "Doc" },
    { reference: "value", value: "Valor" },
    { reference: "occurrence", value: "Ocorrência" },
    { reference: "remittanceDocument", value: "Doc Gerado", notSortable: true },
];

export function ListRemittanceBillsToReceive() {    
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    // STATES
    const [generatedRemittance, setGeneratedRemittance] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [accountBank, setAccountBank] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [billsToReceive, setBillsToReceive] = useState<billsToReceive[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalBillsToReceive, setCountTotalBillsToReceive] = useState(0);

    const [showModalDocDetails, setShowModalDocDetails] = useState(false);
    const [billDetails, setBillDetails] = useState<billsToReceive>();
    const [composeBills, setComposeBills] = useState<billsToReceive[]>([]);

    const searchBank = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {        
        setSearching(true);
        if (accountBank) {
            const raw = {
                bank: Number(accountBank),
                text: searchText,
                filterGenerated: 'y',
            };
            
            const { data } = await api.post<{rows: BillsToReceive[], count: number}>('/billsToReceive/search/remittance', raw, {
                params: {
                    skip: rowsPerPage * currentPage,
                    take: rowsPerPage,
                    sortReference: sortReference,
                    sortDirection: sortDirection,
                }
            });

            const { rows: billsToReceive, count } = data;

            const bills = [];
            for (var bill of billsToReceive) {
                const nameCustomer = CustomerService.getCustomerName(bill.customerEntity);

                bills.push({
                    id: bill.id,
                    check: false,
                    billet: bill.billetNumber,
                    name: bill.name,
                    customer: bill.customer,
                    customerName: nameCustomer,
                    dueDate: bill.dueDate,
                    doc: bill.name,
                    value: bill.amount,
                    occurrence: bill.occurrence,
                    generateRemittance: bill.generateRemittance,
                    remittanceDocument: bill.remittanceDocument,
                    composeIds: bill.remittanceComposeIds,
                });
            }
            setBillsToReceive(bills);
            setCountTotalBillsToReceive(count);

        } else {
            setMsgError("Selecione a conta bancária para pesquisar!");
            setShowModalError(true);
        }
        setSearching(false);
    }, [accountBank, searchText]);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];

        for (const bill of billsToReceive) {
            const data: BodyDataBaseProps[] = [
                { for: "billetNumber", value: String(bill.billet), id: true },
                { for: "name", value: bill.customerName },
                { for: "date", value:  formatDate(bill.dueDate)},
                { for: "doc", value: bill.doc ?? "-" },
                { for: "value", value: formatCurrency(bill.value) },
                { for: "occurrence", value: bill.occurrence ?? "-" },
                { 
                    for: "remittanceDocument",
                    value: bill.generateRemittance,
                    jsx: (
                        <>
                        {bill.generateRemittance === 'y' && (
                            <>
                                {bill.remittanceDocument ? (
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        onClick={() => handleClickDocDetails(bill)}
                                    >
                                        Ver Doc
                                    </Button>
                                ) : <strong>Gerado sem Doc</strong>}
                            </>
                        )}
                        </>
                    )
                },
            ];

            list.push(data);
        }

        setBodyData(list);
    }, [billsToReceive]);

    // {value.generateRemittance === 'y' && (
    //     <>
    //         {value.remittanceDocument ? (
    //             <Button
    //                 size="sm"
    //                 variant="primary"
    //                 onClick={() => handleClickDocDetails(value)}
    //             >
    //                 Ver Doc
    //             </Button>
    //         ) : <strong>Gerado sem Doc</strong>}
    //     </>
    // )}

    function handleSearchBank(accountBank?: BankAccount | null) {
        if (accountBank !== undefined) {
            setAccountBank(String(accountBank?.id ?? ''));
        }
        
        reloadData();
    }

    async function handleClickDocDetails(bill: billsToReceive) {
        if(bill.composeIds) {
            const ids = JSON.parse(bill.composeIds);
            const composes = billsToReceive.filter((billObj) => ids.includes(billObj.id));
            setComposeBills(composes);
        }

        if (bill.remittanceDocument) {
            bill.docDownloadUrl = await createSameOriginUrl(`${process.env.REACT_APP_BILLET_API_URL}/${bill.remittanceDocument}`);

            const indexOfDirectorySeparator = bill.remittanceDocument.lastIndexOf('/');
            bill.docDownloadName = indexOfDirectorySeparator !== -1 ? bill.remittanceDocument.substring(indexOfDirectorySeparator + 1) : bill.remittanceDocument;
        }

        setBillDetails(bill);
        setShowModalDocDetails(true);
    }

    function formatDate(date: any)
    {
        var valueDate   = new Date(date);
        var month       = (valueDate.getMonth() + 1) < 10  ? "0" + (valueDate.getMonth() + 1) : (valueDate.getMonth() + 1);
        var day         = (valueDate.getDate() + 1)   < 10  ? "0" + (valueDate.getDate() + 1) : (valueDate.getDate() + 1);
        
        return day +"/"+ month +"/"+ valueDate.getFullYear();
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <Modal
                size="lg"
                show={showModalDocDetails}
                onHide={() => setShowModalDocDetails(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Ver Doc Gerado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!composeBills && composeBills.length > 0 && (
                        <>
                        <b>Títulos que compõem o documento gerado</b>
                            <Table>
                                <TableHead >
                                    <TableRow>
                                        <TableCell padding="default">
                                            <strong>Boleto</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Cliente</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Vencimento</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Doc</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Valor</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Ocorrência</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {composeBills.map((bill, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <span>{bill.billet}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{bill.customerName}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{formatDate(bill.dueDate)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{bill.doc ?? '-'}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{formatCurrency(bill.value)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{bill.occurrence ?? '-'}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                <a href={`${billDetails?.docDownloadUrl}`} download={billDetails?.docDownloadName} className="btn btn-primary" target="_blank">Baixar</a>
                    <Button variant="secondary" onClick={() => setShowModalDocDetails(!showModalDocDetails)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <div className="row">

                <div className="col-lg-3">
                    <ApiResourceSelect
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={accountBank}
                        required
                        onSelect={(option) => handleSearchBank(option)}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!accountBank) return null;
                            return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                        }}
                    />
                </div>

                <div className="col-lg-3">
                    <TextField 
                        size="small"
                        variant="outlined"
                        label="Pesquise"
                        margin="normal"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>

                <div className="col-lg-2 d-flex align-items-start">
                    <Button
                        type='button'
                        variant="primary"
                        className="mt-5 ml-3"
                        onClick={() => handleSearchBank()}
                    >                                   
                        {
                            searching
                            ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            :
                                <i className="flaticon2-search p-0" style={{ color: "#fff" }}></i>
                        }         
                    </Button>
                </div>
            </div>

            
            {
                generatedRemittance
                ?
                    <div className="mt-3">
                        <div className='text-center p-5 mt-5' style={{ background: "#DFEDD6" }}>
                            <b>Remessa gerada com sucesso!</b>
                            <p className="m-0">Importe essa remessa em seu banco para registrar os boletos</p>
                        </div>
                    </div>
                :
                <>
                    {
                        accountBank
                        ?
                            <div className="col-lg-12 mt-3 border-top">
                                <ListWithModalChangeSituation
                                    headData={headData}
                                    bodyData={bodyData}
                                    sortable={true}
                                    lastCell={false}
                                    loadData={searchBank}
                                    totalCount={countTotalBillsToReceive}
                                    triggerLoad={triggerLoad}
                                    setTriggerLoad={setTriggerLoad}
                                    defaultSortReference="billetNumber"
                                />
                            </div>
                        :
                            <div className="mt-3">
                                <div className='bg-warning-o-30 text-center p-5 mt-5'>
                                    Selecione um <b>banco</b> para visualizar as remessas!
                                </div>
                            </div>
                    }
                </>
            }

            
        </div>
    );
}
