import { useState } from "react";
import { SellerConsignment, SellerConsignmentProduct } from "../../../../types/SellerConsignment";
import api from "../../../../services/Api";
import { setAxiosBypassAuth } from "../../../../utils/setAxiosBypassAuth";
import StockLocationService from "../../../../services/StockLocationService";
import SellerService from "../../../../services/SellerService";

export type PdfConsignmentData = {
    sellerConsignmentId: number;
    products: ProductsMapped[];
    totalQuantityProducts: number;
    totalValueProducts: number;
    company?: {
        nameCompany: string;
        typeCompany: string;
        cnpjCompany: string;
        cpfCompany: string;
        addressCompany: string;
        numberCompany: string;
        neighborhoodCompany: string;
        cityCompany: string;
        ufCompany: string;
        zipCodeCompany: string;
        phoneCompany: string;
        emailCompany: string;
        siteCompany: string;
        photoCompany: string;
    }
    seller?: {
        name: string;
        fantasyName: string;
        cpf: string;
        cnpj: string;
        tell: string;
        email: string;
        cell: string;
        address: string;
        number: string;
        district: string;
        complement: string;
        zipCode: string;
        city: string;
        uf: string;
        typePeople: string;
        companyId: number;
    }
};

export type ProductsMapped = SellerConsignmentProduct & {
    stockLocationName: string;
};

const axiosDefaults = {
    headers: {
        'x-bypass-auth': '0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5'
    },
};

export default function usePdfConsignment() {
    const [pdfData, setPdfData] = useState<PdfConsignmentData | null>(null);
    const [isLoadingPdfData, setIsLoadingPdfData] = useState(false);

    async function loadPdfData(sellerConsignmentId: number) {
        setIsLoadingPdfData(true);

        const { data: savedConsignment } = await api.get<SellerConsignment>(`/sellerConsignment/${sellerConsignmentId}`, axiosDefaults);

        if (!savedConsignment) {
            return;
        }

        const savedProducts = JSON.parse(savedConsignment.products || '[]') as SellerConsignmentProduct[];
        const mappedProducts: ProductsMapped[] = [];

        setAxiosBypassAuth(true);

        for (const product of savedProducts) {
            const stockLocation = await StockLocationService.getStockLocationById(product.stockLocationId);

            mappedProducts.push({
                ...product,
                stockLocationName: stockLocation.name,
            })
        }

        setAxiosBypassAuth(false);

        const sellerData = await getSeller(savedConsignment.sellerId);

        const data: PdfConsignmentData = {
            sellerConsignmentId,
            products: mappedProducts,
            totalQuantityProducts: savedConsignment.totalQuantityProducts,
            totalValueProducts: savedConsignment.totalValueProducts,
            company: await getCompany(sellerData?.companyId ?? 0),
            seller: sellerData,
        }

        setPdfData(data);
        setIsLoadingPdfData(false);
    }

    async function getSeller(sellerId: number) {
        try {
            setAxiosBypassAuth(true);
            const seller = await SellerService.getSellerById(sellerId);
            setAxiosBypassAuth(false);

            if (!seller) {
                return;
            }

            return {
                typePeople: seller.typePeople,
                name: SellerService.getName(seller),
                fantasyName: seller.fantasyName,
                cpf: seller.cpf,
                cnpj: seller.cnpj,
                tell: seller.phone,
                email: seller.email,
                cell: seller.cell,
                address: seller.address,
                number: seller.number,
                district: seller.district,
                complement: seller.complement,
                zipCode: seller.zipCode,
                city: seller.city,
                uf: seller.state,
                companyId: seller.companyId,
            }
        } catch (error) {
            setAxiosBypassAuth(false);
            throw error;
        }
    }

    async function getCompany(companyId: number) {
        const company = await api.get(`/companies/${companyId}`, axiosDefaults);

        if (!company) return;

        return {
            nameCompany: company.data.name,
            typeCompany: company.data.typePeople,
            cnpjCompany: company.data.cnpj,
            cpfCompany: company.data.cpf,
            addressCompany: company.data.address,
            numberCompany: company.data.number,
            neighborhoodCompany: company.data.district,
            cityCompany: company.data.city,
            ufCompany: company.data.state,
            zipCodeCompany: company.data.zipCode,
            phoneCompany: company.data.phone,
            emailCompany: company.data.email,
            siteCompany: company.data.website,
            photoCompany: company.data.logo,
        };
    }

    return {
        pdfData,
        isLoadingPdfData,
        loadPdfData,
    }
}