import { Collapse, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

export type ModalSendWhatsappMultipleProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    onClickSend: () => any;
    infoMessages: WhatsappToCustomer[];
}

export type WhatsappToCustomer = {
    refId: string,
    refName: string
    customerId: string,
    customerName: string,
    customerCell: string,
};

const useStyles = makeStyles(theme => ({
    tableCellTextField: {
        padding: 4,
    },
}));

export function ModalSendWhatsappMultiple({
    showModal,
    setShowModal,
    message,
    setMessage,
    onClickSend,
    infoMessages,
}: ModalSendWhatsappMultipleProps) {
    const classes = useStyles();

    const [collapseInfoMessages, setCollapseInfoMessages] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const handleClickSendMessages = async () => {
        setIsSending(true);

        await onClickSend();

        setIsSending(false);
    };

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"
            size="lg"
            show={showModal}
            onHide={() => {
                setCollapseInfoMessages(false);
                setShowModal(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Enviar por Whatsapp
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-lg-12">
                        <TextField
                            size="small"
                            multiline
                            rows="2"
                            margin="normal"
                            variant="outlined"
                            label="Mensagem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <Button
                            variant="primary"
                            onClick={() => setCollapseInfoMessages((prevState) => !prevState)}
                        >
                            Destinatários ({infoMessages.length})
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Collapse in={collapseInfoMessages}>
                            <TableContainer style={{ maxHeight: '75vh' }}>
                                <Table stickyHeader className="wrap">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Referência</TableCell>
                                            <TableCell>Cliente</TableCell>
                                            <TableCell>Número</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {infoMessages.map((infoMessage, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{infoMessage.refId}</TableCell>
                                                <TableCell>{infoMessage.customerName}</TableCell>
                                                <TableCell>{infoMessage.customerCell}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleClickSendMessages}>
                    {
                        isSending ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </>
                        :
                            <span>Enviar</span>
                    }
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowModal(false);
                }}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}