import api from "../services/Api";

async function getCarrierById(id: string | number){

    return await api.get(`/carriers/${id}`)
        .then(result => {
            const isLegal = result.data.typePeople === "legal";

            if(isLegal){

                return result.data.corporateName;

            }else{

                return result.data.name;

            };

        })
        .catch(error =>  console.log(error) );
};

export { getCarrierById };