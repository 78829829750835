import api from "./Api";

class CityService {
    async getCityByName(name: string) {
        const { data } = await api.get('city/name', { params: { name } });
        return data;
    }
    async getCityByCode(code: string | number) {
        const { data } = await api.get('city/code', { params: { code } });
        return data;
    }
}

export default new CityService();