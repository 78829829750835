export enum SanitizeInputType {
    NUMERIC,
    EMAIL
}

export const sanitizeInput = (type: SanitizeInputType, value: string): string => {
    if (type === SanitizeInputType.NUMERIC) {

        var str = value
        str = str.replaceAll(/[^0-9]+/g, "")

        return str

    } else if (type === SanitizeInputType.EMAIL) {

        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regex.test(String(value).toLowerCase()).toString()

    }

    return ""

}