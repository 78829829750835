export function freightModality(value: any): string {
    switch(value) {
        case 'freeShipping':
            return 'Sem frete';
        case 'free shipping':
            return 'Sem frete';
        case 'recipient':
            return 'Destinatário';
        case 'sender':
            return 'Remetente';
        default:
            return '';
    }
};

export function freightModalityById(value: number | string): string {

    const stringValue = value.toString();

    switch(stringValue) {
        case '0' :
            return 'Emitente'
        case '1' :
            return 'Destinatário';
        case '2' :
            return 'Terceiro';
        case '3' :
            return 'Próprio por conta do Remetente';
        case '4' :
            return 'Próprio por conta Destinatário';
        case '9' :
            return 'Sem Frete';
        default:
            return '';
    }
}

export function freightModalityKeyById(value: number | string): string {

    const stringValue = String(value);

    switch(stringValue) {
        case '0' :
            return 'sender'
        case '1' :
            return 'recipient';
        case '9' :
            return 'free shipping';
        default:
            return '';
    }
}