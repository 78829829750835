export function formatDate(value: any, withoutTimeZone: boolean = false) {
    let formatValue = new Date(Number(value.toString().split('-')[0]),
                            Number(value.toString().split('-')[1]) - 1,
                            Number(value.toString().split('-')[2]),
                            );

    if (value.toString().includes('T')) {
        const dateParsed = value.toString().split('T')[0];
        const timeParsed = value.toString().split('T')[1].replace(".000Z", "");

        formatValue = new Date(Number(dateParsed.split('-')[0]), 
                                    Number(dateParsed.split('-')[1]) - 1, 
                                    Number(dateParsed.split('-')[2]),
                                    Number(timeParsed.split(':')[0]),
                                    Number(timeParsed.split(':')[1]),
                                    Number(timeParsed.split(':')[2])
                                );
    }

    const date = formatValue.toLocaleDateString('pt-BR', withoutTimeZone ? undefined : { timeZone: 'UTC' });

    return date;

}

export function initializeDate(value: any) {
    let formatValue = new Date(Number(value.toString().split('-')[0]),
                            Number(value.toString().split('-')[1]) - 1,
                            Number(value.toString().split('-')[2]),
                            );

    if (value.toString().includes('T')) {
        const dateParsed = value.toString().split('T')[0];
        const timeParsed = value.toString().split('T')[1].replace(".000Z", "");

        formatValue = new Date(Number(dateParsed.split('-')[0]), 
                                    Number(dateParsed.split('-')[1]) - 1, 
                                    Number(dateParsed.split('-')[2]),
                                    Number(timeParsed.split(':')[0]),
                                    Number(timeParsed.split(':')[1]),
                                    Number(timeParsed.split(':')[2])
                                );
    }

    return formatValue;
}

export function formatStringDateToLocale(value: string) {
    if(!value) return '';

    const [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
}