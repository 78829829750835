import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListIndications } from '../pages/Accounting/Indications/List-Indications';
import { NewIndications } from '../pages/Accounting/Indications/New-Indications';
import { DetailsIndications } from '../pages/Accounting/Indications/Details-Indications';

export function Indications() {
    return (
        <Switch>
            <Route path="/indicacoes/adicionar" component={NewIndications}/>
            <Route path="/indicacoes/:id" component={DetailsIndications}/>
            <Route path="/indicacoes" component={ListIndications}/>
        </Switch>
    );
}