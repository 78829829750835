export function convertPaymentMethodKeyToName(key: string) {
    switch(key) {
        case 'money':
            return 'Dinheiro';
        case 'credit_card':
            return 'Cartão de Crédito';
        case 'debit_card':
            return 'Cartão de Débito';
        case 'pix':
            return 'PIX';
        case 'other':
            return 'Outro';
    }
}