import axios from "axios";
import { api } from "../../../services/ApiURL";

export const LOGIN_URL = `${api}/auth/login`;
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const RESET_PASSWORD_URL = "auth/reset-password";
export const ME_URL = `${api}/auth/me`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(token, password) {
  return axios.post(RESET_PASSWORD_URL, { token, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
