import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { makeStyles, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency, formatNumberToString } from '../../../utils/formatCurrency';
import { Autocomplete } from '@material-ui/lab';
import { useProducts } from '../../../hooks/products';
import { useCustomer } from '../../../hooks/customer';
import { Product } from '../../../types/Product';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ProductService from '../../../services/ProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import SellerService from '../../../services/SellerService';

// import { ProductsTable } from './ProductsTable';

const headData: HeadDataBaseProps[] = [
    { reference: "service", value: "Serviço" },
    { reference: "code", value: "Código" },
    { reference: "countService", value: "Qtde. Vendido" },
    { reference: "saleValue", value: "Valor Vendido" },
    { reference: "unitaryValue", value: "Valor Médio Venda" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "data", value: "Data" },
    { reference: "observations", value: "Observações" },
    { reference: "entry", value: "Entrada" },
    { reference: "output", value: "Saída" },
    { reference: "valueProduct", value: "Valor Unitário" },
    { reference: "totalValue", value: "Valor Total" },
];

type Filters = {
    dateMin: string,
    dateMax: string,
    customer: number,
    seller: number,
    status: string,
    service: string,
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));

export function ListReportSoldServices() {
    const [foundServicePaginate, setFoundServicePaginate] = useState<any[]>([]);
    const [foundService, setFoundService] = useState<any[]>([]);
    const [countTotalService, setCountTotalService] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [customer, setCustomer] = useState<any>('');
    const [customerError, setCustomerError] = useState(false);
    
    const [service, setService] = useState<any>('');
    const [serviceError, setServiceError] = useState(false);
    const [seller, setSeller] = useState<any>('');
    const [status, setStatus] = useState<any>('all');

    const [isSearching, setIsSearching] = useState(false);
    const [dateMin, setDateMin] = useState('');
    const [dateMax, setDateMax] = useState('');

    const [uTotalSale, setUTotalSale] = useState<any>();
    const [uTotalAmount, setUTotalAmount] = useState<any>();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);
    const classes = useStyles();
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundServicePaginate;
        let exportProductsData: ReportDataRow[] = [];
        let uTotalAmount = 0;
        let uTotalSale = 0;

        aux.forEach((service) => {
            const data: BodyDataBaseProps[] = [
                { for: "service", value: service.name },
                { for: "code", value: service.code },
                { for: "countService", value: formatNumberToString(service.countSold) },
                { for: "saleValue", value: formatCurrency(service.totalSold) },
                { for: "unitaryValue", value: formatCurrency(service.countSold ? Number(service.totalSold) / Number(service.countSold) : 0) },
            ];

            list.push(data);
        });

        foundService.forEach((service) => {
            const data: BodyDataBaseProps[] = [
                { for: "service", value: service.name },
                { for: "code", value: service.code },
                { for: "countService", value: formatNumberToString(service.countSold) },
                { for: "saleValue", value: formatCurrency(service.totalSold) },
                { for: "unitaryValue", value: formatCurrency(service.countSold ? Number(service.totalSold) / Number(service.countSold) : 0) },
            ];

            uTotalAmount += Number(service.countSold);
            uTotalSale += Number(service.totalSold);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });

        });
        
        setExportTotals([
            { name: 'Total de serviços', value: foundService.length },
            { name: 'Quantidade vendida', value: Number(uTotalAmount) },
            { name: 'Valor total vendido', value: 'R$ ' + formatNumberToString(Number(uTotalSale)) },
        ]);

        setUTotalSale(uTotalSale);
        setUTotalAmount(uTotalAmount);
        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundServicePaginate, foundService]);

    const clearSearch = () => {

        setDateMin('');
        setDateMax('');
        setStatus('all');
        setService('');
        setSeller('');
        setCustomer('');
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Product[], count:number}>("/report/soldServices", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows} = data;

        const soldServices = await api.get("/report/soldServices", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundServicePaginate(data.rows ? rows : []);
        
        setFoundService(soldServices.data);
        setCountTotalService(soldServices.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            dateMin,
            dateMax,
            customer,
            seller,
            status: status != "all" ? status : "",
            service,
        };
        reloadData();

    }, [
        dateMin,
        dateMax,
        customer,
        seller,
        status,
        service,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Serviços Mais Vendidos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <div className="card card-body pt-4 newProductWrapper" >
            <div className="row d-flex align-items-center">

                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Serviço"
                        getOptionLabel={(option: Product) => option.name}
                        value={service}
                        onSelect={(option) => setService(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => ProductService.getServicesFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!service) return null;
                            return loadedOptions.find((option) => option.id === Number(service)) ?? ProductService.getProductById(service)
                        }}
                        onChangeTextField={(e) => setServiceError(e.target.value ? false : true)}
                        textFieldClass={classes.error}
                        hasError={serviceError}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                        onChangeTextField={(e) => setCustomerError(e.target.value ? false : true)}
                        textFieldClass={classes.error}
                        hasError={customerError}
                    />
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <TextField
                        type="Date"
                        label="Período - Min"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMin}
                        onChange={(e: any) => setDateMin(e.target.value)}
                    />

                    <TextField
                        type="Date"
                        label="Período - Máx"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        className="ms-20"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMax}
                        onChange={(e: any) => setDateMax(e.target.value)}
                    />

                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Vendedor"
                        getOptionLabel={(option: any) => option.name}
                        value={seller}
                        onSelect={(option) => setSeller(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!seller) return null;
                            return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                        }}
                    />
                </div>


                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Status"
                        margin="normal"
                        variant="outlined"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="progress" onClick={() => setStatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="3" value="attended" onClick={() => setStatus('attended')}>
                            Finalizado
                        </MenuItem>

                        <MenuItem key="4" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>
            
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Serviços Mais Vendidos"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Serviços Mais Vendidos', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalService}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                    defaultSortReference="service"
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de serviços:</TableCell>
                                    <TableCell colSpan={1}>{foundService.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade vendida:</TableCell>
                                    <TableCell colSpan={1}>{uTotalAmount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor total vendido:</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' + formatNumberToString(uTotalSale)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}