import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Modal } from 'react-bootstrap';
import {
    Table,
    Paper,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
} from '@material-ui/core';

import { getVariant } from '../utils/getVariant';

export type Option = {
    key: string[];
}

export type Situation = {
    dateSituation: Date;
    commentsSituation: string;
    statusSituation: string;
};


interface ListProps {
    options: Option[];
    headData: string[];
    situation?: boolean;
    addButtonLink?: string;
    addButtonLinkName?: string;
    onClickEdit(id: string): void;
    expenditureButtonLink?: string;
    moreActionsButtonLink?: string;
    onDelete(id: string): Promise<void>;
}

export function List({
    headData,
    onDelete,
    onClickEdit,
    addButtonLink,
    situation = false,
    options: listOptions,
    expenditureButtonLink,
    moreActionsButtonLink,
    addButtonLinkName = 'Adicionar',
}: ListProps) {
    const [page, setPage] = useState(0);
    const [toDelete, setToDelete] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { location: { pathname }, push: historyPush } = useHistory();

    const handleChangePage = useCallback((next: number) => {
        setPage(next);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setRowsPerPage(value);
    }, []);

    const handleClickDelete = useCallback((id: string) => {
        setToDelete(id);
        setIsModalOpen(true);
    }, []);

    const handleOnDelete = async () => {
        await onDelete(toDelete);

        setIsModalOpen(false);
    };

    return (
        <div className="card card-body pt-4">
            <div className="col-lg-12 mt-3">
                { addButtonLink &&
                    <Button
                        type="button"
                        variant="success"
                        className="mr-2"
                        onClick={() => historyPush(`/${addButtonLink}`)}
                    >
                        { addButtonLinkName }
                    </Button>
                }
                { moreActionsButtonLink &&
                    <Button
                        type="button"
                        variant="secondary"
                        className="mr-2"
                        onClick={() => historyPush(`/${moreActionsButtonLink}`)}
                    >
                        Mais ações
                    </Button>
                }
                { expenditureButtonLink &&
                    <Button
                        type="button"
                        variant="secondary"
                        className="mr-2"
                        onClick={() => historyPush(`/${expenditureButtonLink}`)}
                    >
                        Despesas
                    </Button>
                }
            </div>

            <div className="col-lg-12 mt-3">
                <Paper>
                    {/* Modal para confirmar deleção */}
                    <Modal
                        centered
                        aria-labelledby="contained-modal-warning"

                        show={isModalOpen}
                        onHide={() => setIsModalOpen(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="d-flex align-items-center">
                                <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                                Atenção
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <strong>Tem certeza que deseja excluir este registro ?</strong>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="danger" onClick={handleOnDelete}>Excluir</Button>
                            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                        </Modal.Footer>
                    </Modal>

                    <Table aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {
                                    headData && headData.map((head) => (
                                        <TableCell key={head} padding="default">
                                            <strong>{head}</strong>
                                        </TableCell>
                                    ))
                                }

                                <TableCell padding="default"></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                listOptions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((option, index) => (
                                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                                        {
                                            option.key.map((key, i) => {
                                                if (situation) {
                                                    if (i === 4) {
                                                        const v = getVariant(key);

                                                        return (
                                                            <TableCell 
                                                                key={key}
                                                                scope="row"
                                                                padding="default"
                                                                size="medium"
                                                                style={{ cursor: "pointer" }}
                                                                // onClick={() => handleSetSituationModal(index)}
                                                            >
                                                                <Badge
                                                                    variant={v}
                                                                >
                                                                    {key}
                                                                </Badge>
                                                            </TableCell>
                                                        );
                                                    }
                                                }

                                                return (
                                                    <TableCell key={key} scope="row" padding="default" size="medium">
                                                        {key}
                                                    </TableCell>
                                                );
                                            })
                                        }

                                        <TableCell align="left">
                                            <Button
                                                className="btn-light-primary p-2 mr-3"
                                                variant="primary"
                                                type="button"
                                                onClick={() => onClickEdit(option.key[0])}
                                            >
                                                <i className="flaticon2-edit p-0"></i>
                                            </Button>

                                            <Button
                                                className="btn-light-danger font-weight-bold p-2"
                                                variant="danger"
                                                type="button"
                                                onClick={() => handleClickDelete(option.key[0])}
                                            >
                                                <i className="flaticon-delete p-0"></i>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <TablePagination
                        page={page}
                        component="div"
                        count={listOptions.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        backIconButtonProps={{
                            'aria-label': 'Página Anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Próxima Página',
                        }}
                        onChangePage={(_, next) => handleChangePage(next)}
                        onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                    />
                </Paper>
            </div>
        </div>
    );
}
