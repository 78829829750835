import { StyleSheet } from "@react-pdf/renderer";

export function usePdfStyles() {
    // Create styles
    const styles = StyleSheet.create({
        header: {
            marginLeft: 16,
            marginRight: 16,
            marginTop: 16,
            marginBottom: 8,
            fontSize: 11,
            alignItems: "flex-start",
            flexDirection: "row",
        },
        section: {
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 8,
            fontSize: 11,
        },
        titleSection: {
            paddingBottom: 6,
            borderBottom: 0.7,
            fontSize: 8,
        },
        titleInfo: {
            fontSize: 6,
            fontWeight: 800,
            paddingBottom: 5,
        },
        table: {
            flexDirection: "row",
            flexWrap: "wrap",
            paddingTop: 8,
        },
        row: {
            flexDirection: "row",
            alignItems: "center",
        },
        rowPayment: {
            flexDirection: "row",
            alignItems: "flex-start",
        },
        rowProduct: {
            flexDirection: "row",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "#ccc",
            borderStyle: "dashed",
            paddingTop: 3,
            paddingBottom: 2,
        },
        rowInstallment: {
            flexDirection: "row",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "#ccc",
            borderStyle: "dashed",
            paddingTop: 3,
            paddingBottom: 2,
        },
        column: {
            flexDirection: "column",
            alignItems: "center",
        },
        customerPhoto: {
            width: '85%',
        },
        photo: {
            width: '15%',
            paddingTop: 8,
            paddingBottom: 8,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        },
        titleHeader: {
            width: "100%",
            marginBottom: 8,
            paddingBottom: 5,
            borderBottom: 0.7,
            borderColor: "#ccc"
        },
        infoHeader: {
            width: '85%',
            paddingTop: 8,
            paddingBottom: 8,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        },
        left: {
            width: '50%',
            paddingTop: 8,
            flexDirection: "column",
        },
        right: {
            width: '50%',
            paddingTop: 8,
            flexDirection: "column",
        },
        leftPayment: {
            width: '80%',
            paddingTop: 8,
            flexDirection: "column",
        },
        rightPayment: {
            width: '20%',
            paddingTop: 8,
            flexDirection: "column",
        },
        viewer: {
            width: window.innerWidth, //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
        totals: {
            paddingTop: 16,
        },
        footer: {
            paddingTop: 24,
        },
        flexRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            borderBottom: 1,
            borderColor: "#ccc",
            borderStyle: "dashed",
        },
        flexCell: {
            flex: 'auto',
            flexGrow: 1,
            margin: 8,
            fontSize: 6,
            fontWeight: "extrabold"
        },
        flexRowSub: {
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            borderBottom: 1,
            borderColor: "#ccc",
            borderStyle: "dashed",
            marginLeft: 16,
        },
        flexCellSub: {
            flex: 'auto',
            flexGrow: 1,
            fontSize: 6,
            fontWeight: "extrabold",
            margin: 8,
        },
        cell1: {
            width: '3%',
            fontSize: 6,
        },
        cell2: {
            width: '47%',
            fontSize: 6,
        },
        cell3: {
            width: '5%',
            fontSize: 6,
        },
        cell4: {
            width: '5%',
            fontSize: 6,
        },
        cell5: {
            width: '10%',
            fontSize: 6,
        },
        cell6: {
            width: '10%',
            fontSize: 6,
        },
        cell7: {
            width: '10%',
            fontSize: 6,
        },
        cell8: {
            width: '10%',
            fontSize: 6,
        },
        cell9: {
            width: '25%',
            fontSize: 6,
        },
        cell10: {
            width: '24%',
            fontSize: 6,
        },
        cell11: {
            width: '23%',
            fontSize: 6,
        },
        cell12: {
            width: '34%',
            fontSize: 6,
        },
        borderBottom: {
            borderBottom: 1,
            borderColor: "#ccc",
            borderStyle: "dashed",
        }
    });

    return styles;
}