import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSubheader } from '../../../_metronic/layout';
import { useHistory, Link, useParams } from 'react-router-dom';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';


import api from "../../services/Api";
import {
    Category,
    RevenueAppearInsideDefaultOptions,
    RevenueFinancialGroupDefaultOptions,
    ExpenditureAppearInsideDefaultOptions,
    ExpenditureFinancialGroupDefaultOptions,
} from '../../hooks/parameters';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));


export function EditCategory() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [description, setDescription] = useState('');
    const [appearInside, setAppearInside] = useState('');
    const [financialGroup, setFinancialGroup] = useState('');
    const [visibilityDRE, setVisibilityDRE] = useState<"y" | "n">('y');
    const [typeCategory, setTypeCategory] = useState<"revenue" | "expenditure">('revenue');

    const [reveneuToShow, setReveneuToShow] = useState(['']);
    const [isExpenditure, setIsExpenditure] = useState(false);
    const [expenditureToShow, setExpenditureToShow] = useState(['']);
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();
    
    subHeader.setTitle("Adicionar uma Categoria");


    const handleSetIsExpenditure = useCallback((evt: string) => {
        if (evt === "revenue") {
            setIsExpenditure(false);
            setTypeCategory("revenue");
        } else {
            setIsExpenditure(true);
            setTypeCategory("expenditure");
        }
    }, []);


    const handleSetReveneuToShow = useCallback((value: string) => {
        setFinancialGroup(value);

        switch (value) {
            case "Retenção de Lucros":
                setReveneuToShow(RevenueAppearInsideDefaultOptions.profitRetention);
                break;
            default:
                setReveneuToShow(RevenueAppearInsideDefaultOptions.others);
                break;
        }
    }, [RevenueAppearInsideDefaultOptions]);


    const handleSetExpenditureToShow = useCallback((value: string) => {
        setFinancialGroup(value);

        switch (value) {
            case "custos":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.expenditure);
                break;
            case "Deduções":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            case "Despesas administrativas":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.adminExpenditure);
                break;
            case "Despesas com pessoal":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.employeesExpenditure);
                break;
            case "Despesas financeiras":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.financialExpenditure);
                break;
            case "Distribuição de lucros":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.profitDistribuition);
                break;
            case "Outras despesas":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            case "Provisão para imposto de renda e contribuição social sobre lucro":
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
            default:
                setExpenditureToShow(ExpenditureAppearInsideDefaultOptions.others);
                break;
        }
    }, [ExpenditureAppearInsideDefaultOptions]);


    useLayoutEffect(() => {
        async function loadData() {
            const response = await api.get<Category>(`category/${id}`);

            setDescription(response.data.description);
            setAppearInside(response.data.appearInside);
            setTypeCategory(response.data.typeCategory);
            setVisibilityDRE(response.data.visibilityDRE);

            const initialTypeCategory = response.data.typeCategory;

            if (initialTypeCategory === "expenditure") {
                setIsExpenditure(true);
                handleSetExpenditureToShow(response.data.financialGroup);
            } else {
                setIsExpenditure(false);
                handleSetReveneuToShow(response.data.financialGroup);
            }
        }

        loadData();
    }, []);


    const handleSubmit = async () => {
        try {
            const category: Category = {
                id: Number(id),
                appearInside,
                description,
                financialGroup,
                typeCategory,
                visibilityDRE,
            };

            await api.post("category", category);

            history.push('/parametros/categorias');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="row card card-body pt-4">
            <form className={classes.container} noValidate>
                <div className="row col-lg-12">
                    <div className="col-lg-9">
                        <TextField
                            label="Descrição da Categoria"
                            className={classes.textField}
                            variant="outlined"
                            margin="normal"
                            required
                            value={description}
                            onChange={(evt) => setDescription(evt.target.value)}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            label="Tipo de Categoria"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            select
                            value={typeCategory}
                            onChange={(evt) => handleSetIsExpenditure(evt.target.value)}
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="revenue">
                                Receita
                            </MenuItem>

                            <MenuItem key="1" value="expenditure">
                                Despesa
                            </MenuItem>
                        </TextField>
                    </div>
                </div>

                <div className="row col-lg-12">
                    <div className="col-lg-4">
                        <TextField
                            select
                            label="Aparecer dentro de"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={appearInside}
                            onChange={(evt) => setAppearInside(evt.target.value)}
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>

                            {
                                isExpenditure ?
                                    expenditureToShow.map((data, index) => (
                                        <MenuItem key={index + 1} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                                :
                                    reveneuToShow.map((data, index) => (
                                        <MenuItem key={index + 1} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-5">
                        <TextField
                            label="Grupo Financeiro"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            select
                            value={financialGroup}
                            onChange={
                                (evt) => isExpenditure
                                    ? handleSetExpenditureToShow(evt.target.value)
                                    : handleSetReveneuToShow(evt.target.value)
                            }
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>

                            {
                                isExpenditure ? 
                                    ExpenditureFinancialGroupDefaultOptions.map((data, index) => (
                                        <MenuItem key={index + 1} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                                :
                                    RevenueFinancialGroupDefaultOptions.map((data, index) => (
                                        <MenuItem key={index + 1} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            select
                            label="Visível no DRE"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            required
                            value={visibilityDRE}
                            onChange={
                                ({ target: { value } }) => setVisibilityDRE(value === 'Sim' ? 'y' : 'n')
                            }
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="y">
                                Sim
                            </MenuItem>

                            <MenuItem key="1" value="n">
                                Não
                            </MenuItem>
                        </TextField>
                    </div>
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            variant="primary"
                            className="mr-3"
                            onClick={handleSubmit}
                        >
                            Salvar
                        </Button>
                        : <></>
                    }
                    <Link to="/parametros/categorias" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </div>
    );
}