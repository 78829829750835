import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Button,
    Modal,
    ButtonToolbar,
    Badge,
} from 'react-bootstrap';
import { Collapse, TextField, Tooltip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/Api';
import { useSelector } from "react-redux";
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import UserService from '../../../services/UserService';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import './Solicitation.css';

type params = {
    processId: string,
}

type Filters = {
    searchQuery: string;
}

const columnWidth = 250;

export function ProcessRequest() {
    const { user } = useSelector((state: any) => state.auth);
    const { push: pushHistory, location: { pathname } } = useHistory();
    const { processId } = useParams<params>();
    const [displayModal, setDisplayModal] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [searchRes, setSearchRes] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [procSupervisors, setProcSupervisors] = useState<number[]>([]);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [username, setUsername] = useState<string>("");


    const filtersRef = useRef<Filters | null>(null);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    useEffect(() => {
        const fetch = async() => {
            try {
                if (Number(processId)) {
                    let formResp = await api.get(`/bpm/form/processForms/${processId}`);
                    setSearchRes(formResp.data);
                    await setSolicitations();
                }
            setPageLoaded(true);
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, []);

    const setSolicitations = async(s: string = "", e: string = "", u: string = "") => {
        let start = s ? s : startDate ? startDate : "all";
        let end = e ? e : endDate ? endDate : "all";
        let us = u ? u : username ? username : "all";
        let res = await api.get(`/bpm/displaySolicitations/${processId}/start/${start}/end/${end}/user/${us}`);
        let data = res.data;
        if (data[0]) {
            let supervisors = data[0].process_supervisors ? JSON.parse(data[0].process_supervisors) : [];
            let superCount = supervisors.length;
            let dataCount = data.length;
            let processResp = await api.get(`/bpm/process/display/${processId}`);
            let processStages = processResp.data.data ? JSON.parse(processResp.data.data) : [];
            let pDataCount = processStages.length;
            let stages: any = [];
            let supervisorsName: any = [];
            let supsIds = procSupervisors;
            for (let i = 0; i < pDataCount; i++) {
                for (let k = 0; k < superCount; k++) {
                    supsIds.push(supervisors[k].id);
                    let supName = `${supervisors[k].firstname} ${supervisors[k].lastname}`;
                    if (!supervisorsName.includes(supName)) {
                        supervisorsName.push(supName);
                    }
                }

                if (i == 0) {
                    for (let j = 0; j < pDataCount ; j++) {
                        if (processStages[j].tipo == "Início") {
                            let respArr = getActivityResponsables(processStages[j]);
                            stages.push({
                                id: processStages[j].id,
                                columnTitle: processStages[j].atividade,
                                items: [],
                                type: processStages[j].tipo,
                                permitions: respArr,
                                target: processStages[j].destino,
                            });
                        }
                    }
                } else {
                    if (stages[i-1]) {
                        let dest = stages[i-1].target;
                        if (dest && dest.length) {
                            let destLength = dest.length;
                            for (let j = 0; j < destLength ; j++) {
                                let target = dest[j];
                                for (let k = 0; k < pDataCount; k++) {
                                    let repetitive = checkRepetitiveStatges(stages, target);
                                    if (!repetitive && target == processStages[k].atividade) {
                                        let respArr = getActivityResponsables(processStages[k]);
                                        stages.push({
                                            id: processStages[k].id,
                                            columnTitle: processStages[k].atividade,
                                            items: [],
                                            type: processStages[k].tipo,
                                            permitions: respArr,
                                            target: processStages[k].destino,
                                        });
                                    }
                                }
                            }
                        }
                    } else {
                        let repetitive = checkRepetitiveStatges(stages, processStages[i].atividade);
                        if (!repetitive) {
                            let respArr = getActivityResponsables(processStages[i]);

                            stages.push({
                                id: processStages[i].id,
                                columnTitle: processStages[i].atividade,
                                items: [],
                                type: processStages[i].tipo,
                                permitions: respArr,
                            });
                        }
                    }
                }
            }
            setProcSupervisors(supsIds);

            for (let i = 0; i < pDataCount; i++) {
                for (let j = 0; j < dataCount; j++) {
                    if (!stages[i]) {
                        continue;
                    }
                    if (data[j].processBpmStage == stages[i].id) {
                        let responsables = "";
                        let responsablesData = data[j].Responsables ? JSON.parse(data[j].Responsables) : [];
                        let respCount = responsablesData.length;
                        for (let k = 0; k < respCount; k++) {
                            responsables += k == 0 ? `${responsablesData[k].firstname} ${responsablesData[k].lastname}` : `, ${responsablesData[k].firstname} ${responsablesData[k].lastname}`;
                        }

                        let reqNameArr = data[j].requester_name.split(' ');
                        let name = `${reqNameArr[0][0] ? reqNameArr[0][0] : ""} ${reqNameArr[1][0] ? reqNameArr[1][0] : ""}`;
                        let icon = name.toUpperCase();

                        stages[i].items.push({
                            id: data[j].id,
                            db_start_date: data[j].startSolicitation,
                            db_end_date: data[j].endDate,
                            startDate: data[j].solicitation_created_at,
                            endDate: data[j].solicitation_end_date,
                            icon,
                            requester:  data[j].requester_name,
                            responsables,
                            supervisors: supervisorsName.join(','),
                            processId: data[j].ProcessBpmId,
                        });
                    }
                }
            }
            setList(stages);
            let containerWidth = stages.length * (columnWidth + 15);
            setContainerWidth(containerWidth);
        }
    }

    const checkRepetitiveStatges = (data: any = [], activity: string) => {
        let dataLength = data.length;
        for (let i = 0; i < dataLength; i++) {
            if (data[i].columnTitle == activity) {
                return true;
            }
        }
        return false
    }

    const getActivityResponsables = (data: any) => {
        let respArr: any = [];
        let responsable = data.responsaveis;
        let supervisors = data.process_supervisors ? JSON.parse(data.process_supervisors) : []
        let superLength = supervisors.length;

        for (let l = 0; l < superLength; l++) {
            if (!respArr.includes(supervisors[l].id)) {
                respArr.push(supervisors[l].id);
            }
        }

        if (responsable) {
            let respCount = responsable.length;
            for (let l = 0; l < respCount; l++) {
                respArr.push(responsable[l].id);
            }
        }

        return respArr;
    }

    useEffect(() => {
        const fetch = async() => {
            try {
                if (pageLoaded) {
                    if (search) {
                        let searchRes = await api.get(`/bpm/form/processForms/${processId}/search/${search}`);
                        setSearchRes(searchRes.data);
                    } else {
                        let formResp = await api.get(`/bpm/form/processForms/${processId}`);
                        setSearchRes(formResp.data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, [search]);

    const openForm = async() => {
        let resp = await api.get(`/bpm/process/checkFirstActivityResponsables/${processId}`);
        let responsables = resp.data;
        if (responsables.includes(user.id)) {
            let res = await api.post("/bpm/solicitation/NewSolicitation", { processId });
            let id = res.data.id;
            await setSolicitations();
            pushHistory(`/bpm/solicitacao-formulario/${processId}/solicitacao/${id}`);
        } else {
            setDisplayModal(true);
        }
    }

    const applyFilter = async() => {
        if (startDate || endDate || username) {
            await setSolicitations();
        }
    }

    const clearFilter = async() => {
        setStartDate("");
        setEndDate("");
        setUsername("");
        let v = "all";
        await setSolicitations(v, v, v);
    }

    const displayItem = (data: any, color: any) => {
        
        return (
            <div 
                onClick={() => pushHistory(`/bpm/solicitacao-formulario/${data.processId}/solicitacao/${data.id}`)}
                style={{...kanbanStyles.card, borderBottom: `3px solid ${color}`}}
                className="card-kanban"
            >
                <div style={kanbanStyles.cardHeader}>
                    <Tooltip title='Número de Identificação'>
                        <Badge
                            variant="light"
                            style={{cursor: "default"}}
                        >
                            #{data.id}
                        </Badge>
                    </Tooltip>

                    <Tooltip title='Data de criação'>
                        <label style={kanbanStyles.createdAt}>
                            {data.startDate}
                        </label>
                    </Tooltip>
                </div>
                <div style={kanbanStyles.cardBody}>
                    <div style={kanbanStyles.responsibles}>
                        {
                            data.responsables
                            ?
                            <Tooltip title='Responsável'>
                                <b>{data.responsables}</b>
                            </Tooltip>
                            :
                            <p className="d-flex flex-row align-items-start text-warning m-0">
                                <i className='flaticon2-warning mr-2 p-0 text-warning'></i>
                                Responsabilidade dos Supervisores
                            </p>
                        }
                        {/* <Tooltip title='Responsável'>
                            <b style={{...kanbanStyles.responsible, cursor: "default", backgroundColor: "#7239ea", marginLeft: "-15px"}}>
                                DD
                            </b>
                        </Tooltip>
                        <Tooltip title='Responsável'>
                            <b style={{...kanbanStyles.responsible, cursor: "default", backgroundColor: "#f15700", marginLeft: "-15px"}}>
                                VT
                            </b>
                        </Tooltip>
                        <Tooltip title='Responsável'>
                            <b style={{...kanbanStyles.responsible, cursor: "default", backgroundColor: "#17c653", marginLeft: "-15px"}}>
                                +3
                            </b>
                        </Tooltip> */}
                    </div>
                </div>
                <div style={kanbanStyles.cardFooter}>
                    <Tooltip title='Data Limite Entrega'>
                        <b style={{...kanbanStyles.limitDate, color}}>
                            <i className="flaticon2-time" style={{...kanbanStyles.iconLimitDate, color}}></i>
                            {data.endDate}
                        </b>
                    </Tooltip>
                </div>
            </div>
        );
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery
        };

        reloadData();
    }, [
        searchQuery
    ]);

    const clearSearch = () => {
        setSearchQuery('');
        setStartDate('');
        setEndDate('');
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <>
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2 mb-2"
                                onClick={() => openForm()}
                            >
                                Nova Solicitação
                            </Button>
                        </>
                        : <></>
                    }
                </div>
                <div className="col-lg-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-3">
                        <TextField
                            label={"Data inicial"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            label={"Prazo"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div style={kanbanStyles.container} className='container-kanban'>
                {
                    list.map((l: any) =>
                        <div style={kanbanStyles.column}>                    
                            <div style={{
                                ...kanbanStyles.columnHeader, 
                                backgroundColor: "#fdfdfd", 
                                borderBottom: l.type == "Início" ? "3px solid #2CFFEA" : l.type == "Fim" ? "3px solid #F64E60 " : l.type == "Atividade" ? "3px solid #8950FC" : "3px solid #F0C56D",
                            }}>
                                <b style={kanbanStyles.titleHeader}>{l.columnTitle}</b>
                                <Tooltip title='Quantidade de Atividades'>
                                    <Badge
                                        variant="light"
                                        style={{backgroundColor: "#fff", border: "1px solid #f2f2f2", cursor: "default"}}
                                    >
                                        {l.items.filter((item: any) => procSupervisors.includes(user.id) || l.permitions.includes(user.id)).length}
                                    </Badge>
                                </Tooltip>
                            </div>
                            <div style={{...kanbanStyles.body}} className="d-flex flex-column body-column-kanban">
                                {
                                    procSupervisors.includes(user.id) || l.permitions.includes(user.id) ?
                                        l.items.map((i: any) => {
                                            let today = new Date().getTime();
                                            let endDay = new Date(i.db_end_date).getTime();
                                            let color = endDay < today ? "#f00" : "#555";

                                            return displayItem(i, color);
                                        })
                                        : <></>
                                }
                            </div>
                        </div>
                    )
                }
            </div>

            
            <Modal
                show={displayModal}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Aviso!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{color: "red", fontWeight: "bold" as "bold"}}>
                        *Voce não é responsável para iniciar esta solicitação
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setDisplayModal(false)}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const kanbanStyles = {
    container: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        overflow: "auto"
    },
    column: {
        backgroundColor: "#f7f7f7",
        minWidth: "250px",
        borderRadius: "10px",
    },
    columnHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        minHeight: "115px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        boxShadow: "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset"
    },
    titleHeader: {
        margin: "0",
        width: "80%"
    },
    body: {
        gap: "10px",
        padding: "8px",
        cursor: "default",
        height: "70vh",
        overflow: "auto"
    },
    card: {
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
        padding: "8px",
        cursor: "pointer"
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    cardBody: {
        marginTop: "5px",
        marginBottom: "5px",
    },
    cardFooter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end"
    },
    createdAt: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        color: "#aaa",
        fontSize: "0.75rem",
        margin: "0",
    },
    limitDate: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        fontSize: "0.75rem",
        margin: "0",
    },
    iconLimitDate: {
        fontSize: "0.75rem",
    },
    responsibles: {
        display: "flex",
        alignItems: "center",
    },
    responsible: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 7px",
        borderRadius: "100%",
        border: "2px solid #fff",
        color: "#fff"
    }
}