import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export type ModalDeleteProps = {
    msgDelete?: string;
    showModalDelete: boolean;
    setShowModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
    onDelete: () => void;
}

export default function ModalDelete({msgDelete, showModalDelete, setShowModalDelete, onDelete}: ModalDeleteProps) {
    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"

            show={showModalDelete}
            onHide={() => setShowModalDelete(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <strong>{msgDelete || 'Tem certeza que deseja excluir este registro ?'}</strong>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={onDelete}>Excluir</Button>
                <Button variant="secondary" onClick={() => setShowModalDelete(false)}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    )
}