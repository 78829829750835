import { Seller } from "../types/Seller";
import { SellerConsignmentProduct, SellerConsignmentStatus } from "../types/SellerConsignment";
import api from "./Api";
import { Product } from "./ProductService";

class SellerConsignmentService {
    getSituationText(status: SellerConsignmentStatus) {
        switch(status) {
            case SellerConsignmentStatus.pending:
                return 'Em trâmite';
            case SellerConsignmentStatus.returned:
                return 'Devolvido';
            case SellerConsignmentStatus.late:
                return 'Atrasado';
            case SellerConsignmentStatus.sold:
                return 'Vendido';
            default:
                return '';
        }
    }

    /**
     * Retorna true caso o o vendedor consignado tenha quantidade suficiente do produto para venda
     */
    validateConsignmentProductQuantity(product: Product, amountToSell: number, stockLocationId: number, sellerAvailableProducts: SellerConsignmentProduct[]) {
        const totalAmountAvailable = sellerAvailableProducts.reduce((total, availableProduct) => {
            if (availableProduct.productId === product.id && availableProduct.stockLocationId === stockLocationId) {
                return total + availableProduct.amount;
            }
        
            return total;
        }, 0);

        return amountToSell <= totalAmountAvailable;
    }

    /** Salvar histórico dos produtos consignados vendidos (retirados do estoque) */
    async saveConsignmentRequestStockMoved(requestId: string | number) {
        return api.post(`/sellerConsignment/request`, {
            requestId,
        })
    }
}

export default new SellerConsignmentService();