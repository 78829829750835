import { useState } from "react";
import { NewCustomerDefaultData } from "../components/Customer/NewCustomerForm";

export default function useNewCustomerForm() {
    const [showModalNewCustomer, setShowModalNewCustomer] = useState(false);
    const [newCustomerDefaultData, setNewCustomerDefaultData] = useState<NewCustomerDefaultData>()

    return {
        showModalNewCustomer,
        setShowModalNewCustomer,
        newCustomerDefaultData,
        setNewCustomerDefaultData,
    };
}