import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      paddingTop: 8,
    },
  });

export type TableProps = {
    children?: React.ReactNode,
};

export default function Table({ children }: TableProps) {
    return (
        <View style={styles.tableContainer}>
            {children}
        </View>
    );
};