import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
    Line
} from "@react-pdf/renderer";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { formatCpfCnpj } from "../../../../utils/formatCpfCnpj";
import Table from "../../../../components/Pdf/Table";
import TableRow from "../../../../components/Pdf/TableRow";
import TableCell from "../../../../components/Pdf/TableCell";
import { usePdfStyles } from "../../../../hooks/pdfStyles";
import { PdfConsignmentData } from "./usePdfConsignment";


type PdfConsignmentProps = {
    data: PdfConsignmentData;
}

// Create Document Component
export default function PdfConsignment({ data }: PdfConsignmentProps) {
    const styles = usePdfStyles();

    const sellerConsignmentId = data.sellerConsignmentId;
    const products = data.products;

    // COMPANY DATA
    const nameCompany = data.company?.nameCompany ?? '';
    const typeCompany = data.company?.typeCompany ?? '';
    const cnpjCompany = data.company?.cnpjCompany ?? '';
    const cpfCompany = data.company?.cpfCompany ?? '';
    const addressCompany = data.company?.addressCompany ?? '';
    const numberCompany = data.company?.numberCompany ?? '';
    const neighborhoodCompany = data.company?.neighborhoodCompany ?? '';
    const cityCompany = data.company?.cityCompany ?? '';
    const ufCompany = data.company?.ufCompany ?? '';
    const zipCodeCompany = data.company?.zipCodeCompany ?? '';
    const phoneCompany = data.company?.phoneCompany ?? '';
    const emailCompany = data.company?.emailCompany ?? '';
    const siteCompany = data.company?.siteCompany ?? '';
    const photoCompany = data.company?.photoCompany ?? '';


    // SELLER DATA 
    const name = data.seller?.name ?? '';
    const fantasyName = data.seller?.fantasyName ?? '';
    const cpf = data.seller?.cpf ?? '';
    const cnpj = data.seller?.cnpj ?? '';
    const tell = data.seller?.tell ?? '';
    const email = data.seller?.email ?? '';
    const cell = data.seller?.cell ?? '';
    const address = data.seller?.address ?? '';
    const number = data.seller?.number ?? '';
    const district = data.seller?.district ?? '';
    const complement = data.seller?.complement ?? '';
    const zipCode = data.seller?.zipCode ?? '';
    const city = data.seller?.city ?? '';
    const uf = data.seller?.uf ?? '';
    const typePeople = data.seller?.typePeople ?? '';
    // ========= SELLER ===  

    // DETAILS
    const totalQuantityProducts = data.totalQuantityProducts;
    const totalValueProducts = data.totalValueProducts;
    //  ======== DETAILS ===

    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4">
                <View style={styles.header}>
                    <View style={styles.photo}>
                        <Image source={{ uri: `${process.env.REACT_APP_API_URL}/${photoCompany}`, method: "GET", headers: {}, body: "" }} style={styles.customerPhoto} />
                    </View>
                    <View style={styles.infoHeader}>
                        <View style={styles.titleHeader}>
                            <Text>Solicitação de Consignação N° {sellerConsignmentId}</Text>
                        </View>
                        <Text style={styles.titleInfo}>{nameCompany} - {typeCompany == "legal" ? formatCpfCnpj('CNPJ', cnpjCompany) : formatCpfCnpj('CPF', cpfCompany)}</Text>
                        <Text style={styles.titleInfo}>{addressCompany}, {numberCompany} {neighborhoodCompany} - {cityCompany} - {ufCompany} CEP: {zipCodeCompany}</Text>
                        <Text style={styles.titleInfo}>Fone: {phoneCompany} E-mail: {emailCompany} Site: {siteCompany}</Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.titleSection}>DADOS DO VENDEDOR</Text>

                    <View style={styles.row}>
                        <View style={styles.left}>
                            {
                                typePeople == "physical"
                                    ?
                                    <>
                                        <Text style={styles.titleInfo}>NOME: {name}</Text>
                                    </>
                                    :
                                    <>
                                        <Text style={styles.titleInfo}>RAZÃO SOCIAL: {name}</Text>
                                        <Text style={styles.titleInfo}>CNPJ: {formatCpfCnpj('CNPJ', cnpj)}</Text>
                                    </>
                            }
                            <Text style={styles.titleInfo}>TELEFONE: {tell}</Text>
                            <Text style={styles.titleInfo}>CELULAR: {cell}</Text>
                            <Text style={styles.titleInfo}>NÚMERO: {number}</Text>
                            <Text style={styles.titleInfo}>COMPLEMENTO: {complement}</Text>
                            <Text style={styles.titleInfo}>CIDADE: {city}</Text>
                        </View>

                        <View style={styles.right}>
                            {
                                typePeople == "physical"
                                    ?
                                    <>
                                        <Text style={styles.titleInfo}>CPF: {formatCpfCnpj('CPF', cpf)}</Text>
                                    </>
                                    :
                                    <>
                                        <Text style={styles.titleInfo}>NOME FANTASIA: {fantasyName}</Text>
                                    </>
                            }
                            <Text style={styles.titleInfo}>EMAIL: {email}</Text>
                            <Text style={styles.titleInfo}>ENDEREÇO: {address}</Text>
                            <Text style={styles.titleInfo}>BAIRRO: {district}</Text>
                            <Text style={styles.titleInfo}>CEP: {zipCode}</Text>
                            <Text style={styles.titleInfo}>UF: {uf}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.titleSection}>PRODUTOS</Text>

                    <Table>
                        <TableRow>
                            <TableCell width={styles.cell1.width}></TableCell>
                            <TableCell width={styles.cell12.width}>DESCRIÇÃO</TableCell>
                            <TableCell width={styles.cell5.width}>QTDE.</TableCell>
                            <TableCell width={styles.cell11.width}>LOCAL DE ESTOQUE</TableCell>
                        </TableRow>
                    </Table>

                    <Table>
                        {
                            products.map((product, index: number) => {
                                return (
                                    <View key={product.id} style={styles.rowProduct}>
                                        <Text style={styles.cell1}>{index + 1}.</Text>
                                        <Text style={styles.cell12}>{product.INSTANCE.name}</Text>
                                        <Text style={styles.cell5}>{product.amount}</Text>
                                        <Text style={styles.cell11}>{product.stockLocationName ?? ''}</Text>
                                    </View>
                                )
                            })
                        }
                    </Table>
                </View>

                <View style={styles.section}>
                    <Text style={styles.titleSection}></Text>

                    <View style={styles.rowPayment}>
                        <View style={styles.left}>
                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>VALOR TOTAL DOS PRODUTOS</Text>
                                </View>
                                <View style={styles.rightPayment}>
                                    <Text style={styles.titleInfo}>{formatCurrency(totalValueProducts)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.right}>
                            <View style={styles.rowPayment}>
                                <View style={styles.leftPayment}>
                                    <Text style={styles.titleInfo}>NÚMERO TOTAL DE ITENS</Text>
                                </View>
                                <View style={styles.rightPayment}>
                                    <Text style={styles.titleInfo}>{totalQuantityProducts} produto(s)</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

