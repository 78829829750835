export const frequencyToMonths = (frequency: string) => {
    switch (frequency) {
        case 'monthly':
            return 1;
        case 'bimonthly':
            return 2;
        case 'trimonthly':
            return 3;
        case 'quarterly':
            return 4;
        case 'semiannual':
            return 6;
        case 'annual':
            return 12;
    
        default:
            return 1;
    }
}

export function formatFrequency(value: string) {
    switch (value) {
        case 'monthly':
            return 'Mensal';
        case 'bimonthly':
            return 'Bimestral';
        case 'trimonthly':
            return 'Trimestral';
        case 'quarterly':
            return 'Quadrimestral';
        case 'semiannual':
            return 'Semi-Anual';
        case 'annual':
            return 'Anual';
    
        default:
            return 1;
    }
}