import { Quotation, QuotationStatus, QuotationSupplier, QuotationSupplierStatus } from "../types/Quotation";
import api from "./Api";

type QuotationSupplierWhatsappData = {
    quotationSupplier: QuotationSupplier;
    customerId: string;
    customerName: string;
    refName: string;
    message: string;
    link?: string;
    companyId: number;
}

class WhatsappQuotationSupplierService {
    async getAllWhatsappsByQuotationSupplierId(quotationSupplierId: number | string) {
        try {
            const { data } = await api.get<any[]>(`whatsapp-quotation-supplier/quotationSupplier/${quotationSupplierId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendWhatsapp(number: string, {
        quotationSupplier,
        customerId,
        customerName,
        refName,
        message,
        link,
        companyId,
    }: QuotationSupplierWhatsappData) {
        const raw = {
            number: number,
            name: refName,
            id: quotationSupplier.id,
            nameCustomer: customerName,
            message: message,
            link: link,
            companyId: companyId,
            supplierId: quotationSupplier.supplier,
            type: 'quotationSupplier',
        };

        await api.post('whatsapp-quotation-supplier/send', raw);
    };
}

export default new WhatsappQuotationSupplierService();