import { CategoryProduct } from "../hooks/products";
import api from "./Api";

class SubCategoryProductService {
    async getSubCategoryById(id: string | number) {
        const subCategoryResponse = await api.get<CategoryProduct>(`/subcategoryproduct/${id || '0'}`);

        return subCategoryResponse.data;
    }

    async getSubCategoriesFiltered(categoryUuid: string | number, filters: any) {
        if(!categoryUuid) return [];

        const subCategoryResponse = await api.get<CategoryProduct[]>(`/subcategoryproduct/category/${categoryUuid}`, { params: { filters } });

        return subCategoryResponse.data;
    }
}

export default new SubCategoryProductService();