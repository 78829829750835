export function getYesOrNoText(value: any) {
    if(!value) return 'Não';

    const formattedValue = String(value).trim().toLowerCase();

    if(formattedValue === 'y' || formattedValue === 'yes' || formattedValue === '1' || formattedValue === 'true') {
        return 'Sim';
    } else {
        return 'Não';
    }
}