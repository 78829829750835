import React from 'react';
import {
    makeStyles,
    MenuItem,
    TextField,
    Link
} from '@material-ui/core';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";

import '../../style.css';
import LogService from '../../services/LogService';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

export function NewCenterCost() {
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, handleSubmit } = useForm();


    subHeader.setTitle("Adicionar um Centro de Custos");


    const onSubmit = async (data: any) => {
        try {
            const result = await api.post("centerCost", data);
            
            LogService.logRegister({
                itemId: result.data.id,
                module: 'Centro de Custos',
                itemName: result.data.name
            });

            history.push("/parametros/centro-de-custos");

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
                <div className="row col-lg-12">
                    <div className="col-lg-9"> 
                        <TextField
                            { ...register("name") }
                            label="Descrição"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("status") }
                            select
                            label="Status"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        >
                            <MenuItem key="0" value="active">
                                Ativo
                            </MenuItem>                                

                            <MenuItem key="1" value="inactive">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div>          
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    <Button type="submit" variant="primary" className="mr-3">Salvar</Button>
                    <Link href="/parametros/centro-de-custos" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </div>
    );
}