import {
    Checkbox, FormControlLabel, MenuItem, Tooltip as MaterialToolTip,
    Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Zoom, IconButton, TableContainer
} from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { Badge, Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from '../../../services/Api';
import { StockLocations } from '../../../types/StockLocations';
import { formatCurrency } from '../../../utils/formatCurrency';
import { formatDate } from '../../../utils/dateFormat';
import { ReceivedNfeForm } from './ImportManifest-Entry';
import EntryService from '../../../services/EntryService';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { colorSuccess } from '../../../utils/styles';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import StockLocationService from '../../../services/StockLocationService';

type ImportNfeProps = {
    selectedCompany: any;
    setShowModalError: React.Dispatch<React.SetStateAction<boolean>>;
    setMsgError: React.Dispatch<React.SetStateAction<string>>;
    setShowModalSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setMsgSuccess: React.Dispatch<React.SetStateAction<string>>;
    setModalSuccessRedirectUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
    confirmedNfes: ReceivedNfeForm[];
    setConfirmedNfes: React.Dispatch<React.SetStateAction<ReceivedNfeForm[]>>;
    maxVersion: number;
};

export function ImportNfe({
    selectedCompany,
    setShowModalError,
    setMsgError,
    setShowModalSuccess,
    setMsgSuccess,
    setModalSuccessRedirectUrl,
    confirmedNfes,
    setConfirmedNfes,
    maxVersion,
}: ImportNfeProps) {
    const [isSubmittingConfirmation, setIsSubmittingConfirmation] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [launchInStock, setLaunchInStock] = useState(false);
    const [stockLocationIdForNewProducts, setStockLocationIdForNewProducts] = useState<number | null>(null);
    const [stockLocationIdForNewProductsError, setStockLocationIdForNewProductsError] = useState(false);
    const [selectedNfeKeys, setSelectedNfeKeys] = useState<string[]>([]);
    const [selectAllNfe, setSelectAllNfe] = useState(false);

    const { location: { pathname }, push: pushHistory } = useHistory();

    async function onSubmit() {
        setIsSubmittingConfirmation(true);

        if (!inputsVerify()) {
            setIsSubmittingConfirmation(false);
            setShowModalError(true);
            return;
        }

        try {
            for (const nfeKey of selectedNfeKeys) {
                const confirmedNfe = confirmedNfes.find((nfe) => nfe.nfeKey === nfeKey);

                if (!confirmedNfe) continue;

                const { data: uploadedXmlFile } = await api.get<{ content: string, path: string }>(`nfeManifest/${nfeKey}/xml`);

                // Gerar Entrada de mercadoria
                await EntryService.importEntryFromXml({
                    uploadedXmlFile,
                    stockLocationId: stockLocationIdForNewProducts,
                    launchInStock,
                    selectedCompany,
                });
            }

            // Atualizar numero maximo da versao da empresa
            // await api.put(`companies/${selectedCompany.id}`, {
            //     nfeManifestMaxVersion: maxVersion,
            // });

            setShowModalSuccess(true);
            setMsgSuccess('Importação realizada com sucesso!');
            setModalSuccessRedirectUrl('/entrada-de-mercadoria');
        } catch (error) {
            setMsgError('Ocorreu um erro ao realizar a importação pela receita');
            setShowModalError(true);
        }

        setIsSubmittingConfirmation(false);
    }

    function inputsVerify(): boolean {
        setConfirmedNfes((prevState) => prevState.map((nfe) => ({ ...nfe, selectedActionError: false })));

        setStockLocationIdForNewProductsError(false);

        if (!stockLocationIdForNewProducts) {
            setMsgError('Selecione um local de estoque');
            setStockLocationIdForNewProductsError(true);
            return false;
        }

        if (selectedNfeKeys.length === 0) {
            setMsgError('Selecione pelo menos uma nota fiscal para importar');
            return false;
        }

        return true;
    }

    const handleSelectNfe = useCallback((checked: boolean, nfeKey: string) => {
        if (checked) {
            setSelectedNfeKeys((prevState) => [...prevState, nfeKey]);
        } else {
            setSelectedNfeKeys((prevState) => prevState.filter((key) => key !== nfeKey));
        }
    }, []);

    const handleSelectAllNfes = useCallback((checked: boolean) => {
        if (checked) {
            const keys = confirmedNfes.map((nfe) => nfe.nfeKey);
            setSelectedNfeKeys(keys);
            setSelectAllNfe(true);
        } else {
            setSelectedNfeKeys([]);
            setSelectAllNfe(false);
        }
    }, [confirmedNfes]);

    const handleChangePage = useCallback(async (next: number) => {
        setCurrentPage(next);
    }, []);

    const handleRowsPerPage = useCallback(async (value: number) => {
        setCurrentPage(0);
        setRowsPerPage(value);
    }, []);

    return (
        <>
            <div className="row mt-3">
                <div className="col bg-warning-o-30 p-5 mb-3">
                    <h3>
                        {confirmedNfes.length > 0 && (<span>Foram encontradas ({confirmedNfes.length}) Notas Fiscais confirmadas</span>)}
                        {confirmedNfes.length === 0 && (<span>Nenhuma Nota Fiscal manifestada encontrada para o seu CNPJ</span>)}
                    </h3>

                    {confirmedNfes.length > 0 && (
                        <p>
                            Selecione as notas fiscais que você deseja importar como entrada de mercadoria. O fornecedor, a transportadora e os produtos serão inseridos automaticamente no sistema, caso ainda não exista cadastro.
                            <br /> Notas confirmadas recentemente podem levar alguns minutos para aparecerem aqui.
                        </p>
                    )}
                    {confirmedNfes.length === 0 && (
                        <p>
                            O sistema da Receita pode levar até 2 horas para atualizar os dados de recebimento de notas fiscais. Altere o NSU atual ou tente novamente mais tarde.
                            <br /> Notas confirmadas recentemente podem levar alguns minutos para aparecerem aqui.
                        </p>
                    )}
                </div>
            </div>
            {confirmedNfes.length > 0 && (
                <>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-9 mt-3">
                            <Button
                                type='button'
                                variant="primary"
                                disabled={isSubmittingConfirmation}
                                onClick={onSubmit}
                            >
                                {
                                    isSubmittingConfirmation ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>
                                        </>
                                        :
                                        <span>Importar Notas Fiscais</span>
                                }
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-3 align-items-center">
                        <div className="col-lg-4">
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={launchInStock}
                                        onChange={(_, checked) => setLaunchInStock(checked)}
                                    />
                                }
                                label="Lançar produtos no estoque"
                                labelPlacement="end"
                            />
                        </div>
                        <div className="col-lg-6">
                            <ApiResourceSelect
                                label="Local do estoque para lançamentos *"
                                getOptionLabel={(option: StockLocations) => option.name}
                                value={stockLocationIdForNewProducts ?? ''}
                                hasError={stockLocationIdForNewProductsError}
                                onSelect={(option) => setStockLocationIdForNewProducts(option?.id ?? 0)}
                                apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if(!stockLocationIdForNewProducts) return null;
                                    return loadedOptions.find((option) => option.id === Number(stockLocationIdForNewProducts)) ?? StockLocationService.getStockLocationById(stockLocationIdForNewProducts)
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <TableContainer style={{ maxHeight: '75vh' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NSU</TableCell>
                                            <TableCell>
                                                <MaterialToolTip TransitionComponent={Zoom} title='Selecionar Todas'>
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e, checked) => handleSelectAllNfes(checked)}
                                                        checked={selectAllNfe}
                                                    />
                                                </MaterialToolTip>
                                            </TableCell>
                                            <TableCell>Chave da NFe</TableCell>
                                            <TableCell>Data de Emissão</TableCell>
                                            <TableCell>Fornecedor</TableCell>
                                            <TableCell>Valor Total</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {confirmedNfes.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((nfe, index: any) => (
                                            <TableRow key={index}>
                                                <TableCell>{nfe.version}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={(e, checked) => handleSelectNfe(checked, nfe.nfeKey)}
                                                        checked={selectedNfeKeys.includes(nfe.nfeKey)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {nfe.nfeKey}
                                                    {nfe.existingEntryId && (
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip-top'>
                                                                    Entrada de Mercadoria N° {nfe.existingEntryId} está cadastrada com esta chave
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Badge pill variant="warning" className="ml-3 text-light">
                                                                <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                                            </Badge>
                                                        </OverlayTrigger>
                                                    )}
                                                </TableCell>
                                                <TableCell>{formatDate(new Date(nfe.issuanceDate))}</TableCell>
                                                <TableCell>{nfe.supplierName}</TableCell>
                                                <TableCell>{formatCurrency(nfe.totalValue)}</TableCell>
                                                <TableCell>
                                                    <Badge
                                                        variant={nfe.status === 'autorizada' ? 'success' : 'danger'}
                                                        style={nfe.status === 'autorizada' ? colorSuccess : undefined}
                                                    >
                                                        {capitalizeFirstLetter(nfe.status)}
                                                    </Badge>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                labelRowsPerPage="Linhas por página"
                                page={currentPage}
                                component="div"
                                count={confirmedNfes.length}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[15, 25, 50]}
                                backIconButtonProps={{
                                    'aria-label': 'Página Anterior',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Próxima Página',
                                }}
                                onChangePage={(_, next) => handleChangePage(next)}
                                onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}