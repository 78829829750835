import React from 'react';
import { useSubheader } from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListCashFlow } from '../pages/CashFlow/List-CashFlow';

export function CashFlow() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Fluxo de Caixa");

    return  (
        <Switch>
            {/* <Route path="/fluxo-de-caixa/adicionar" component={NewCarriers}/>
            <Route path="/fluxo-de-caixa/:id" component={EditCarriers}/> */}
            <Route path="/fluxo-de-caixa" component={ListCashFlow}/>
        </Switch>
    );
}