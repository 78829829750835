import React from 'react';
import { useSubheader } from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListBillsToReceive } from '../pages/BillsToReceive/List-BillsToReceive';
import { EditBillsToReceive } from '../pages/BillsToReceive/Edit-BillsToReceive';
import { NewBillsToReceive } from '../pages/BillsToReceive/New-BillsToReceive';
import { NewRemittanceBillsToReceive } from '../pages/BillsToReceive/New-RemittanceBillsToReceive';
import { ListRemittanceBillsToReceive } from '../pages/BillsToReceive/List-RemittanceBillsToReceive';
import { ReturnBillsToReceive } from '../pages/BillsToReceive/ReturnBillsToReceive';
import { NewTransferBillsToReceive } from '../pages/BillsToReceive/New-TransferBillsToReceive';

export function BillsToReceive() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Contas a Receber");
    
    return (
        <Switch>
            <Route path="/contas-a-receber/retorno" component={ReturnBillsToReceive}/>
            <Route path="/contas-a-receber/remessa" component={NewRemittanceBillsToReceive}/>
            <Route path="/contas-a-receber/remessas-geradas" component={ListRemittanceBillsToReceive}/>
            <Route path="/contas-a-receber/transferencia/adicionar" component={NewTransferBillsToReceive}/>
            <Route path="/contas-a-receber/adicionar" component={NewBillsToReceive}/>
            <Route path="/contas-a-receber/duplicar/:idToClone" component={NewBillsToReceive}/>
            <Route path="/contas-a-receber/:id" component={EditBillsToReceive}/>
            <Route path="/contas-a-receber" component={ListBillsToReceive}/>
        </Switch>
    );
}