import { BankAccount } from "./BankAccount";
import { Customer } from "./Customer";
import { yesOrNo } from "./yesOrNo";

export type ServiceContractData = {
    id: number;
    companyId: number;
    number: number;
    description: string;
    comments: string;
    internalComments: string;
    customer: number;
    nextPaymentDate: string;
    frequency: frequency;
    startDate: string;
    finalDate: string;
    expireDay: string;
    daysBeforeExpire: string;
    billType: string;
    bankAccount: number;
    generateServiceOrder: yesOrNo;
    sendEmail: yesOrNo;
    referencies: string;
    centerCost: number;
    seller: number;
    services: string;
    generateNfse: yesOrNo;
    nfseData: string;
    warranty: string;
    categoryName: string;
    totalValue: number;
    situation: string;
    status: string;
    createdAt: string;
    sellerCommissioning: string;
    sendWhats: string;
    readjustments: string;
    readjustmentPeriod: number;
    readjustmentPercentage: number;
    nextReadjustmentDate: string;
    nextReadjustmentPercentage: number;
    dreSubCategoryId: number;
    attachments: string;

    customerName?: string;
    nextGenerateDate?: string
    nfseDataParsed?: NfseData;
    customStatus: any;

    customerEntity: Customer;
    bankAccountEntity: BankAccount;
};

export enum frequency {
    MONTHLY = 'monthly',
    BIMONTHLY = 'bimonthly',
    TRIMONTHLY = 'trimonthly',
    QUARTERLY = 'quarterly',
    SEMIANNUAL = 'semiannual',
    ANNUAL = 'annual',
    OTHER = 'other', // Não está sendo usado
}

export type NfseData = {
    activity: string;
    nature: number;
    placeDelivery: string;
    aliquot: number;
    holdIss: string;
};

export type Readjustments = {
    date: string;
    percentage: number;
};