import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useBackendLoad from '../../../hooks/backendReload';
import { BankAccount } from '../../../types/BankAccount';

type ReportBankAccountReponse = {
    rows: BankAccount[];
    count: number;
}

type Filters = {
    name: string;
    walletType: string;
    willGenerateBills: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'Nº' },
    { reference: 'name', value: 'Nome da Conta' },
    { reference: 'nameBank', value: 'Banco' },
    { reference: 'openingBalance', value: 'Saldo Inicial' },
    { reference: 'wallet', value: 'Carteira' },
    { reference: 'agency', value: 'Agência' },
    { reference: 'account', value: 'Conta' },
];

export function ListReportAccountBank() {
    const [foundAccounts, setFoundAccounts] = useState<any[]>([]);
    const [countTotalAccountsPaginated, setCountTotalAccountsPaginated] = useState(0);
    const [countTotalAccounts, setCountTotalAccounts] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [name, setName] = useState('');
    const [walletType, setWalletType] = useState('');
    const [willGenerateBills, setWillGenerateBills] = useState('');

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportBankAccountReponse>("/report/accountBank", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        })

        setExportTotals([
            { name: 'Total de contas bancárias', value: count },
        ])

        setExportBodyData(exportDataList);
        setCountTotalAccounts(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportBankAccountReponse>("/report/accountBank", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundAccounts(rows);
        setCountTotalAccountsPaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundAccounts;

        aux.forEach((account) => {
            const data = mountBodyDataRow(account);

            list.push(data);
        });

        setBodyData(list);
    }, [foundAccounts]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: BankAccount) {
        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "name", value: reportRow.name },
            { for: "nameBank", value: reportRow.nameBank },
            { for: "openingBalance", value: reportRow.openingBalance },
            { for: "wallet", value: reportRow.wallet },
            { for: "agency", value: reportRow.agency },
            { for: "account", value: reportRow.account },
        ];

        return data;
    }

    const clearSearch = () => {
        setName('');
        setWalletType('');
        setWillGenerateBills('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            name,
            walletType,
            willGenerateBills
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);

    }, [
        name,
        walletType,
        willGenerateBills
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Contas Bancárias', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Nome da conta"
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo de carteira"
                        margin="normal"
                        variant="outlined"
                        value={walletType}
                    >
                        <MenuItem key="0" value="all" onClick={() => setWalletType('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="with record" onClick={() => setWalletType('with record')}>
                            Com Registro
                        </MenuItem>

                        <MenuItem key="2" value="no registry" onClick={() => setWalletType('no registry')}>
                            Sem Registro
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Emite boletos bancários?"
                        margin="normal"
                        variant="outlined"
                        value={willGenerateBills}
                    >
                        <MenuItem key="0" value="all" onClick={() => setWillGenerateBills('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="y" onClick={() => setWillGenerateBills('y')}>
                            Sim
                        </MenuItem>

                        <MenuItem key="2" value="n" onClick={() => setWillGenerateBills('n')}>
                            Não
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Contas Bancárias"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Contas Bancárias', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                   headData={headData}
                   bodyData={bodyData}
                   loadData={loadData}
                   sortable={true}
                   totalCount={countTotalAccountsPaginated}
                   triggerLoad={triggerLoad}
                   setTriggerLoad={setTriggerLoad}
                   lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de contas bancárias</TableCell>
                                    <TableCell colSpan={1}>{countTotalAccounts}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}