import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { yesOrNo } from "../../../types/yesOrNo";

type SubscriptionPlanConfigProps = {
    requireUserCellValidation: yesOrNo;
    setRequireUserCellValidation: React.Dispatch<React.SetStateAction<yesOrNo>>
}

export function CompanyAndUserConfig({
    requireUserCellValidation,
    setRequireUserCellValidation,
}: SubscriptionPlanConfigProps) {
    return (
        <>
            <div className="row">
                <div className="col">
                    <FormControlLabel
                        value="end"
                        control={(
                            <Checkbox
                                color="primary"
                                onChange={e => setRequireUserCellValidation(e.target.checked ? yesOrNo.YES : yesOrNo.NO)}
                                checked={requireUserCellValidation === yesOrNo.YES}
                            />
                        )}
                        label="Novos usuários precisam verificar o número do celular"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>
            </div>
        </>
    );
}