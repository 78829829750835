import React, { useState, useEffect, useCallback } from 'react';
import { format, isBefore } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner, Tabs, Tab, Modal } from 'react-bootstrap';
import {
    MenuItem,
    Checkbox,
    TextField,
    FormControlLabel,
    makeStyles,
    InputAdornment,
} from '@material-ui/core';

import api from '../../services/Api';
import { Product, useRequest } from './context';
import { Installment } from './Installment';
import { ListProducts } from './ListProducts';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import { NumericFormat } from '../../components/NumericFormat';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import LogService from '../../services/LogService';
import CustomerSelect from '../../components/CustomerSelect';
import ModalLinkedFieldsError from '../../components/ModalLinkedFieldsError';
import { useLinkedFieldsError } from '../../hooks/linkedFieldsError';
import RequestService from '../../services/RequestService';
import { Request } from '../../types/Request';
import { yesOrNo } from '../../types/yesOrNo';
import useThrowToBills from '../../hooks/throwToBills';
import { SubCategory } from '../../types/Dre';
import { ModalThrowToBills } from '../../components/ModalThrowToBills';
import SellerService from '../../services/SellerService';
import ModalError from '../../components/ModalError';
import { PriceList } from '../../types/PriceList';
import PriceListService from '../../services/PriceListService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import { useStyles } from '../../hooks/styles';
import { CustomerLimitCreditInformation } from '../../types/CustomerLimitCreditInformation';
import CustomerService from '../../services/CustomerService';
import { CustomerLimitCreditAlert } from '../../components/CustomerLimitCreditAlert';
import useSellerUser from '../../hooks/sellerUser';
import SellerConsignmentService from '../../services/SellerConsignmentService';
import { SellerConsignmentStockLocationSelector } from '../../components/SellerConsignmentStockLocationSelector';
import UploadFiles from '../../components/UploadFiles';
import { uploadMultipleFiles } from '../../utils/FilesHelper';

type InstallmentDetails = {
    date: string;
    value: number;
    comments: string;
    payment: string;
};

export function NewRequests() {
    const customClasses = useStyles();

    const [currentTab, setCurrentTab] = useState('details');

    const [isSubmit, setIsSubmit] = useState(false);

    const [deadLine, setDeadLine] = useState("");
    const [requestDate, setRequestDate] = useState("");

    const [priceList, setPriceList] = useState<PriceList>({} as PriceList);

    const [carrier, setCarrier] = useState("");
    const [reference, setReference] = useState("");
    const [note, setNote] = useState("");
    const [insideNote, setInsideNote] = useState("");
    const [priceListRequest, setPriceListRequest] = useState("");
    const [customerId, setCustomerId] = useState<number>();
    const [customerName, setCustomerName] = useState("");
    const [seller, setSeller] = useState("");
    const [freigth, setFreigth] = useState("0");
    const [totalValue, setTotalValue] = useState("0");
    const [sellerCommissioning, setSellerCommissioning] = useState(false);
    const [requestNumber, setRequestNumber] = useState("");
    const [discountMoney, setDiscountMoney] = useState("0");
    const [installmentAmount, setInstallmentAmount] = useState(0);
    const [installmentError, setInstallmentError] = useState(false);
    const [modalityFreight, setModalityFreight] = useState("recipient");
    const [totalValueProducts, setTotalValueProducts] = useState("0");
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [grossWeight, setGrossWeight] = useState("");
    const [liquidWeight, setLiquidWeight] = useState("");
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [isThrowToBills, setIsThrowToBills] = useState(false);

    const [customerLimitCreditInformation, setCustomerLimitCreditInformation] = useState<CustomerLimitCreditInformation>();
    const [sellerConsignmentStockLocationId, setSellerConsignmentStockLocationId] = useState(0);
    const [requestDataLoaded, setRequestDataLoaded] = useState(false);

    const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
    const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

    //Lançar em contas
    const {
        isThrowToBillsModalOpen, setIsThrowToBillsModalOpen,
        dreSubCategoryId, setDreSubCategoryId,
        centerCost, setCenterCost,
        installments: billInstallments, setInstallments: setBillInstalmments,
        dreSubCategoryIdCommission, setDreSubCategoryIdCommission,
        centerCostCommission, setCenterCostCommission,
        installmentsCommission, setInstallmentsCommission,
        revenue, setRevenue,
        handleSetIsPaid,
        handleSetInstallmentBankAccount,
        handleSetInstallmentPaymentDate,
        handleSetIsPaidCommission,
        handleSetInstallmentBankAccountCommission,
        handleSetInstallmentPaymentMethodCommission,
        handleSetInstallmentPaymentDateCommission,
        handleSetBillInstallments,
    } = useThrowToBills();

    // Inputs Verify
    const [customerError, setCustomerError] = useState(false);
    const [productsError, setProductsError] = useState(false);

    const {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    } = useLinkedFieldsError();

    const { idToClone } = useParams<{ idToClone: string }>();
    const history = useHistory();
    const { userSellerInfo, productsAvailableForConsignment, filterProductsByStockLocationId } = useSellerUser();
    const selectedSellerIsAuthConsignmentSeller = !!userSellerInfo && userSellerInfo.isConsignment === yesOrNo.YES && userSellerInfo.id === Number(seller);
    const {
        classes,
        products,
        installments,
        dispatchProducts,
        dispatchInstallments,
    } = useRequest();

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCustomerChanged = useCallback(async (customerId: number | undefined, customerName: string) => {
        if (customerId) {
            const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customerId);
            setCustomerLimitCreditInformation(limitCreditInfo);
        } else {
            setCustomerLimitCreditInformation(undefined);
        }
    }, []);

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomerId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        if (userSellerInfo) {
            setSeller(String(userSellerInfo.id));
        }
    }, [userSellerInfo]);

    useEffect(() => {
        function handleConsignmentSeller() {
            if (!userSellerInfo || userSellerInfo.isConsignment === yesOrNo.NO) {
                return;
            }

            filterProductsByStockLocationId(sellerConsignmentStockLocationId);

            if (!requestDataLoaded) {
                return;
            }

            dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
            dispatchProducts({ type: "ADD" });
            dispatchInstallments({
                type: "GENERATE",
                payload: {
                    amount: 0,
                    value: 0,
                }
            });
        }

        handleConsignmentSeller();
    }, [userSellerInfo, seller, sellerConsignmentStockLocationId, filterProductsByStockLocationId]);

    useEffect(() => {
        dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchProducts({ type: "ADD" });
        dispatchInstallments({
            type: "GENERATE",
            payload: {
                amount: 0,
                value: 0,
            }
        });
        setRequestDate(format(Date.now(), "yyyy-MM-dd"));

        const date = new Date();
        const formated = format(date, "yyyyMMddHHmmss")

        setRequestNumber(formated);
    }, []);

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        if (priceList.priceListType === "1") {
            totalProduct = Number(totalProduct) + Number(priceList.value);
        }

        if (priceList.priceListType === "2") {
            totalProduct = Number(totalProduct) - Number(priceList.value);
        }

        const discountNumber = discountMoney ? formatToFloat(discountMoney) : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freigth ? formatToFloat(freigth) : 0;

        const partial = Number(totalProduct) + Number(freigthNumber);

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setTotalValueProducts(formatCurrency(totalProduct));

            setFreigth("0");
            setTotalValue("0");
            setDiscountMoney("0");

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
            total = total - (total * (discountPercentageFloat / 100));
        }


        setTotalValueProducts(formatCurrency(totalProduct));
        setTotalValue(formatCurrency(total));
    }, [products, discountMoney, discountPercentage, freigth, priceList]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    useEffect(() => {
        if (!idToClone) return;

        async function getRequestToCloneData() {
            const { data: requestToClone } = await api.get<Request>(`requests/${idToClone}`);

            if (!requestToClone) return;

            const dataProducts: Product[] = JSON.parse(requestToClone.products);
            const dataInstallments: InstallmentDetails[] = JSON.parse(requestToClone.installments);

            const dataAmountInstallments = dataInstallments.length;

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: dataProducts,
                },
            });

            // dispatchInstallments({
            //     type: "INITIAL",
            //     payload: {
            //         installments: dataInstallments,
            //     }
            // });

            const dataPriceList = await PriceListService.getPriceListById(requestToClone.priceList);

            if (dataPriceList) {
                setPriceList(dataPriceList);
            }

            setNote(requestToClone.note);
            setSeller(String(requestToClone.seller));
            setCarrier(requestToClone.carrier);
            setCustomerId(requestToClone.customerId ?? undefined);
            setCustomerName(requestToClone.customerName ?? '');
            // setDeadLine(requestToClone.deadLine);
            setReference(requestToClone.reference);
            setInsideNote(requestToClone.insideNote);
            setInstallmentAmount(dataAmountInstallments);
            setModalityFreight(requestToClone.modalityFreight);
            setSellerCommissioning(requestToClone.sellerCommissioning === 'y');
            setSellerConsignmentStockLocationId(requestToClone.sellerConsignmentStockLocationId || 0);

            setFreigth(formatCurrency(requestToClone.freigth));
            setTotalValue(formatCurrency(requestToClone.totalValue));
            setDiscountMoney(formatCurrency(requestToClone.discountMoney));
            setDiscountPercentage(requestToClone.discountPercentage);
            setGrossWeight(requestToClone.grossWeight);
            setLiquidWeight(requestToClone.liquidWeight);

            setRequestDataLoaded(true);
            handleCustomerChanged(requestToClone.customerId ?? undefined, requestToClone.customerName);
        }

        getRequestToCloneData();
    }, [idToClone]);

    async function throwToBills() {
        setInstallmentError(false);

        if (!validateInputs()) return;

        const request = {
            customerId,
            customerName,
            products: JSON.stringify(products),
        };

        const linkErrors = RequestService.verifyLinkedFields(request);

        if (linkErrors.length > 0) {
            setLinkedFieldsErrors(linkErrors);
            setShowModalLinkedFieldsError(true);
            return;
        }

        handleSetBillInstallments({
            order: true,
            typeOrder: 'request',
            orderObject: {
                installments: JSON.stringify(installments),
                sellerHasCommission: sellerCommissioning,
                sellerId: Number(seller),
                sellerCommissionPercentage: await SellerService.getCommissionPercentage(seller ?? '')
            },
        });

        setIsThrowToBillsModalOpen(true);
    }

    function validateInputs() {
        setCustomerError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            dispatchProductError(i, product, false);
        }

        if (!customerId && !customerName) {
            setIsThrowToBillsModalOpen(false);
            setMsgError("Selecione o cliente!");
            setShowModalAttention(true);
            setCustomerError(true);
            return false;
        }

        if (customerId && customerLimitCreditInformation) {
            const totalRequest = formatToFloat(totalValue ?? 0);

            if ((totalRequest + customerLimitCreditInformation.currentCreditThisPeriod) > customerLimitCreditInformation.limit) {
                // Se salvar o pedido ultrapassar o limite de crédito do cliente
                setIsThrowToBillsModalOpen(false);
                setMsgError("Não é possível salvar o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema.");
                setShowModalAttention(true);
                setCustomerError(true);
                return false;
            }
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setIsThrowToBillsModalOpen(false);
            setCurrentTab('products');
            setMsgError("Selecione pelo menos 1(um) Produto!");
            setShowModalAttention(true);
            return false;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                dispatchProductError(i, product, true);
                setMsgError('Informe um produto!');
                setCurrentTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (selectedSellerIsAuthConsignmentSeller && productsAvailableForConsignment) {
            for (const product of products) {
                const valid = SellerConsignmentService.validateConsignmentProductQuantity(product.INSTANCE, product.amount, sellerConsignmentStockLocationId, productsAvailableForConsignment);
                if (!valid) {
                    setIsThrowToBillsModalOpen(false);
                    setCurrentTab('products');
                    setMsgError(`O produto ${product.name} não tem estoque em consignação o suficiente para realizar a venda!`);
                    setShowModalAttention(true);
                    return false;
                }
            }
        }

        if (!installments.length) {
            setMsgError('Informe as parcelas!');
            setCurrentTab('payment');
            setShowModalAttention(true);
            return false;
        } else {
            for (const installment of installments) {
                if (!installment.payment) {
                    setMsgError('Preencha a forma de pagamento nas parcelas!');
                    setCurrentTab('payment');
                    setShowModalAttention(true);
                    return false;
                }
            }
        }

        return true;
    }

    function dispatchProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const isValid = (data: any) => {
        setProductsError(data)
        console.log(productsError)
    };

    const handleSetDeadLine = useCallback((value: string) => {
        const [year, month, day] = value.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const dateNow = new Date();

        //input
        let finalDate = new Date(date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay());

        //data atual
        let finalDateNow = new Date(dateNow.getFullYear() + '-' + dateNow.getMonth() + '-' + dateNow.getDay());


        if (isBefore(finalDate, finalDateNow)) {
            alert("Não é possível adicionar uma data que já passou!");
            return setDeadLine("");
        }

        setDeadLine(value);
    }, []);

    const handleSetPriceList = useCallback((value: PriceList | null) => {
        if (value) {
            return setPriceList(value);
        }

        return setPriceList({} as PriceList);
    }, []);

    function hasInstallments() {
        var isSubmit = true;

        if (!installments.length) {
            setMsgError("Informe as parcelas do pedido!");
            setShowModalAttention(true);
            setInstallmentError(true);
            isSubmit = false;
            setCurrentTab('payment');
        }

        if (!isSubmit) return isSubmit;

        const sumInstallments = installments.reduce((sum, installment) => sum + installment.value, 0);

        if (sumInstallments !== formatToFloat(totalValue)) {
            setMsgError("A soma das parcelas deve concidir com o valor total do pedido!");
            setShowModalAttention(true);
            setInstallmentError(true);
            isSubmit = false;
            setCurrentTab('payment');
        }

        return isSubmit;
    }

    async function onSubmit(isEmited?: boolean) {
        setInstallmentError(false);

        if (!validateInputs()) {
            setIsThrowToBills(false);
            setIsSubmit(false);
            return;
        }

        if (hasInstallments()) {
            setIsThrowToBills(true);
            let filesPath: any  = [];
            if (filesSelected) {
                filesPath = await uploadMultipleFiles(filesSelected, setShowModalAttention, setMsgError, setIsSubmit);                
                if (filesPath === false) {
                    return;
                }
            }
            
            // Situação que aparece na listagem
            const situation: OrdersSituation[] = [];

            situation.push({
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                commentsSituation: "Criação do pedido",
                statusSituation: "open",
            });

            try {
                const raw = {
                    carrier,
                    reference,
                    note,
                    insideNote,
                    priceList: priceList?.id || null,
                    requestDate,
                    deadLine,
                    requestNumber,
                    modalityFreight,
                    customerId: customerId,
                    customerName: !customerId ? customerName : null,
                    seller: Number(seller) ?? null,
                    freigth: formatToFloat(freigth ?? 0),
                    totalValue: formatToFloat(totalValue ?? 0),
                    discountMoney: formatToFloat(discountMoney ?? 0),
                    discountPercentage: formatToFloat(discountPercentage ?? 0),
                    grossWeight: formatToFloat(grossWeight ?? 0),
                    liquidWeight: formatToFloat(liquidWeight ?? 0),
                    sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,

                    status: isEmited ? "attended" : "open",
                    sellerConsignmentStockLocationId: sellerConsignmentStockLocationId || null,
                    products: JSON.stringify(products),
                    situation: JSON.stringify(situation),
                    installments: JSON.stringify(installments),
                    movedToStock: "n",
                    attachments: JSON.stringify(filesPath)
                };

                if (isEmited) {
                    const linkErrors = RequestService.verifyLinkedFields(raw);

                    if (linkErrors.length > 0) {
                        setLinkedFieldsErrors(linkErrors);
                        setShowModalLinkedFieldsError(true);
                        setIsSubmit(false);
                        return;
                    }
                }

                const requestResponse = await api.post("requests", raw);

                if (isEmited) {
                    if (isEmited) {
                        await RequestService.throwToBills({
                            requestId: Number(requestResponse.data.id),
                            installments: billInstallments,
                            subCategoryId: dreSubCategoryId,
                            centerCost,
                            installmentsCommission,
                            centerCostCommission,
                            subCategoryIdCommission: dreSubCategoryIdCommission,
                            requestObj: requestResponse.data,
                        });
                    }
                }
                history.push("/pedidos");

                LogService.logRegister({
                    itemId: requestResponse.data.id,
                    module: 'Pedidos',
                    itemName: requestResponse.data.reference || 'Pedido',
                });

            } catch (error) {
                console.log(error);
                setMsgError('Ocorreu um erro ao processar a requisição');
                setShowModalAttention(true);
            }
        }
        setIsThrowToBills(false);
        setIsSubmit(false);
    };

    return (
        <div className="card card-custom gutter-b mt-2 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalAttention}
                setShowModalError={setShowModalAttention}
            />

            <ModalLinkedFieldsError
                message="Para lançar em contas é necessário completar o cadastro do Pedido!"
                errors={linkedFieldsErrors}
                showModal={showModalLinkedFieldsError}
                setShowModal={setShowModalLinkedFieldsError}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            {/* Modal de lançar em contas */}
            <ModalThrowToBills
                isThrowToBillsModalOpen={isThrowToBillsModalOpen}
                setIsThrowToBillsModalOpen={setIsThrowToBillsModalOpen}
                dreCategoryType="revenue"
                dreSubCategoryId={dreSubCategoryId}
                setDreSubCategoryId={setDreSubCategoryId}
                dreSubCategoryIdCommission={dreSubCategoryIdCommission}
                setDreSubCategoryIdCommission={setDreSubCategoryIdCommission}
                setCenterCost={setCenterCost}
                setCenterCostCommission={setCenterCostCommission}
                installments={billInstallments}
                installmentsCommission={installmentsCommission}
                onThrowToBills={() => onSubmit(true)}
                isThrowToBills={isThrowToBills}
                onSetInstallmentBankAccount={handleSetInstallmentBankAccount}
                onSetInstallmentPaymentDate={handleSetInstallmentPaymentDate}
                onSetIsPaid={handleSetIsPaid}
                onSetInstallmentBankAccountCommission={handleSetInstallmentBankAccountCommission}
                onSetInstallmentPaymentMethodCommission={handleSetInstallmentPaymentMethodCommission}
                onSetInstallmentPaymentDateCommission={handleSetInstallmentPaymentDateCommission}
                onSetIsPaidCommission={handleSetIsPaidCommission}
            />

            <div>
                <div className="card-header row m-0 pt-2 pb-0">
                    <div className="col-6 m-0 p-0">
                        <h4 className="card-label m-0 mt-2">
                            Detalhes do Pedido
                        </h4>
                    </div>
                    <div className='col-lg-6 d-flex justify-content-end mb-3'>
                        <Button
                            className='mr-3'
                            variant="primary"
                            onClick={() => { setIsSubmit(true); onSubmit() }}
                            disabled={isSubmit}
                        >

                            {
                                isSubmit ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </>
                                    :
                                    <span>Salvar</span>
                            }
                        </Button>

                        <Button
                            size="lg"
                            variant="secondary"
                            onClick={() => throwToBills()}
                        >
                            <span>Lançar em contas</span>
                        </Button>
                    </div>
                </div>

                <div className="card-body mt-0 pt-0">
                    <CustomerLimitCreditAlert customerLimitCreditInformation={customerLimitCreditInformation} />

                    {selectedSellerIsAuthConsignmentSeller && (
                        <SellerConsignmentStockLocationSelector
                            stockLocationId={sellerConsignmentStockLocationId}
                            setStockLocationId={setSellerConsignmentStockLocationId}
                        />
                    )}

                    <div className="row">
                        <div className="col-lg-6">
                            <CustomerSelect
                                label="Cliente"
                                hasError={customerError}
                                isEdit={false}
                                customerId={customerId}
                                setCustomerId={setCustomerId}
                                onCustomerChanged={handleCustomerChanged}
                                customerName={customerName}
                                setCustomerName={setCustomerName}
                                allowIncomplete={true}
                                onClickAddCustomer={handleClickAddCustomer}
                            />

                        </div>

                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Vendedor"
                                getOptionLabel={(option: any) => option.name}
                                value={seller}
                                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if (!seller) return null;
                                    return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                }}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                disabled
                                label="Nº pedido"
                                margin="normal"
                                variant="outlined"
                                value={requestNumber}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs
                                activeKey={currentTab}
                                onSelect={(key: string) => setCurrentTab(key)}
                                id='newproduct-form-tabs'
                            >

                                <Tab
                                    eventKey='details'
                                    title='Detalhes'
                                >
                                    <div className="row">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <TextField
                                                size="small"
                                                type="date"
                                                label="Data do pedido"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled
                                                value={requestDate}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <TextField
                                                size="small"
                                                type="date"
                                                margin="normal"
                                                variant="outlined"
                                                label="Prazo de Entrega"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={deadLine}
                                                onChange={(evt) => handleSetDeadLine(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-8">
                                            <TextField
                                                size="small"
                                                label="Referência"
                                                margin="normal"
                                                variant="outlined"
                                                value={reference}
                                                onChange={(evt) => setReference(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                label="Observações"
                                                multiline
                                                rows="2"
                                                placeholder="Esta informação será impressa nas observações da nota."
                                                margin="normal"
                                                variant="outlined"
                                                value={note}
                                                onChange={(evt) => setNote(evt.target.value)}
                                            />
                                        </div>


                                        <div className="col-lg-6">
                                            <TextField
                                                size="small"
                                                multiline
                                                rows="2"
                                                margin="normal"
                                                variant="outlined"
                                                label="Observações Internas"
                                                placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                                value={insideNote}
                                                onChange={(evt) => setInsideNote(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-6 ml-2">
                                            <UploadFiles
                                                label="Contrato/Anexos"
                                                filesSelected={filesSelected}
                                                setFilesSelected={setFilesSelected}
                                                filesNamesSaved={filesNamesSaved}
                                                setFilesNamesSaved={setFilesNamesSaved}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='products'
                                    title='Produtos'
                                >
                                    <div className="row">
                                        {
                                            products.map(
                                                (prod, index) => (
                                                    <ListProducts
                                                        key={index}
                                                        product={prod}
                                                        index={index}
                                                        onClickAddProduct={handleClickAddProduct}
                                                        sellerConsignmentProducts={selectedSellerIsAuthConsignmentSeller ? productsAvailableForConsignment : undefined}
                                                    />
                                                )
                                            )
                                        }

                                        <div className="row col-lg-12 mt-3 ml-2">
                                            <button
                                                type="button"
                                                className="btn btn-link-secondary p-0"
                                                onClick={() => dispatchProducts({ type: "ADD" })}
                                            >
                                                <ins>+ adicionar outro produto</ins>
                                            </button>
                                        </div>

                                        <div className="col-lg-6">
                                            <ApiResourceSelect
                                                label="Lista de preços"
                                                getOptionLabel={(option: PriceList) => option.name}
                                                value={priceList}
                                                onSelect={(option) => handleSetPriceList(option)}
                                                apiSearchHandler={(typedText) => PriceListService.getPriceListsFiltered({ name: typedText })}
                                                disabled={formatToFloat(totalValueProducts) === 0}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='total-request'
                                    title='Totais do Pedido'
                                >
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <NumericFormat
                                                disabled
                                                label="Valor Total dos produtos"
                                                value={totalValueProducts}
                                                startAdornment="R$"
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <TextField
                                                size="small"
                                                label="Valor do IPI"
                                                margin="normal"
                                                variant="outlined"
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Valor Total do frete"
                                                startAdornment="R$"
                                                value={freigth}
                                                onChange={(evt) => setFreigth(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Desconto"
                                                startAdornment="R$"
                                                value={discountMoney}
                                                onChange={(evt) => setDiscountMoney(evt.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Desconto"
                                                startAdornment="%"
                                                value={discountPercentage}
                                                onChange={(e) => setDiscountPercentage(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Peso Bruto"
                                                value={grossWeight}
                                                onChange={(e) => setGrossWeight(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                label="Peso Líquido"
                                                value={liquidWeight}
                                                onChange={(e) => setLiquidWeight(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-lg-3 ">
                                            <NumericFormat
                                                disabled
                                                label="Valor total"
                                                value={totalValue}
                                                startAdornment="R$"
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='payment'
                                    title='Pagamento'
                                >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={sellerCommissioning}
                                                        onChange={(e, checked) => setSellerCommissioning(checked)}
                                                        className={classes.textField}
                                                    />
                                                }
                                                label="Emitir comissionamento (vendedor)"
                                                labelPlacement="end"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <TextField
                                                size="small"
                                                type="number"
                                                margin="normal"
                                                variant="outlined"
                                                label="Quantidade de Parcelas*"
                                                value={installmentAmount}
                                                onChange={(evt) => setInstallmentAmount(Number(evt.target.value))}
                                                error={installmentError}
                                            />
                                        </div>

                                        <div className="col-md-3 d-flex align-items-center justify-content-center">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                block
                                                onClick={() => dispatchInstallments({
                                                    type: "GENERATE",
                                                    payload: {
                                                        amount: installmentAmount,
                                                        value: formatToFloat(totalValue),
                                                    }
                                                })}
                                            >
                                                Gerar parcelas
                                            </Button>
                                        </div>

                                        <div className="row col-lg-12 mt-10">
                                            {
                                                installments.length > 0 && installments.map((installment, index) => (
                                                    <Installment
                                                        key={index}
                                                        index={index}
                                                        installment={installment}
                                                        installmentAmount={installmentAmount}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='transport'
                                    title='Transporte'
                                >
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextField
                                                size="small"
                                                select
                                                label="Modalidade de Frete"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                value={modalityFreight}
                                                onChange={e => setModalityFreight(e.target.value)}
                                            >
                                                <MenuItem key="0" value="sender">
                                                    Remetente
                                                </MenuItem>

                                                <MenuItem key="1" value="recipient">
                                                    Destinatário
                                                </MenuItem>

                                                <MenuItem key="5" value="freeShipping">
                                                    Sem Frete
                                                </MenuItem>
                                            </TextField>
                                        </div>

                                        <div className="col-lg-8">
                                            <ApiResourceSelect
                                                label="Transportadora"
                                                freeSolo
                                                getOptionLabel={(option: Carrier) => option.name}
                                                value={carrier}
                                                onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                                onInputChange={(typedText) => setCarrier(typedText || '')}
                                                apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
