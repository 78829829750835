import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Customer } from '../../../types/Customer';
import { formatCpfCnpj } from '../../../utils/formatCpfCnpj';
import { Checkbox, Collapse, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import customerService from '../../../services/CustomerService';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import useAllUf from '../../../hooks/uf';
import useBackendLoad from '../../../hooks/backendReload';
import { ContactsTable } from './ContactsTable';
import contactService from '../../../services/ContactService';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { Contact } from '../../../../../../api/src/entity/Contact';
import { ReportDetails } from '../../../components/ReportDetails';

type ReportCustomerResponse = {
    rows: Customer[];
    count: number;
    activeCount: number;
    inactiveCount: number;
}

type Filters = {
    name: string,
    city: string,
    state: string,
    situation: string,
    typeRegister: string,
    startOfActivityMin: string,
    startOfActivityMax: string,
    finalOfActivityMin: string,
    finalOfActivityMax: string
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "typeRegister", value: "Tipo", notSortable: true }, 
    { reference: "name", value: "Nome" },
    { reference: "cpf", value: "CPF/CNPJ", notSortable: true },
    { reference: "phone", value: "Telefones", notSortable: true },
    { reference: "situation", value: "Situação", situation: true, notSortable: true },
    { reference: "startOfActivity", value: "Data Início" },
    { reference: "finalOfActivity", value: "Data Fim" },
];
const contactsHeadData: HeadDataBaseProps[] = [
    { reference: "type", value: "Tipo" },
    { reference: "name", value: "Nome" },
    { reference: "phone", value: "Telefone" },
    { reference: "cell", value: "Celular" },
    { reference: "email", value: "Email" },
];

export function ListReportCustomer() {
    const [foundCustomers, setFoundCustomers] = useState<Customer[]>([]);
    const [countTotalCustomers, setCountTotalCustomers] = useState(0);
    const [countTotalActiveCustomers, setCountTotalActiveCustomers] = useState(0);
    const [countTotalInactiveCustomers, setCountTotalInactiveCustomers] = useState(0);
    const [countTotalPaginatedCustomers, setCountTotalPaginatedCustomers] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [situation, setSituation] = useState('all');
    const [typeRegister, setTypeRegister] = useState('');
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [startOfActivityMin, setStartOfActivityMin] = useState('');
    const [startOfActivityMax, setStartOfActivityMax] = useState('');
    const [finalOfActivityMin, setFinalOfActivityMin] = useState('');
    const [finalOfActivityMax, setFinalOfActivityMax] = useState('');
    const [showContacts, setShowContacts] = useState(false);
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    const { allStates } = useAllUf();

    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportCustomerResponse>("/report/customer", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count, activeCount, inactiveCount} = data;

        const exportDataList: ReportDataRow[] = [];
        let exportContactsData: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const { data, contacts } = mountBodyDataRow(reportRow);

            data.push({
                for: "contacts",
                value: '',
                secondaryRow: true,
                jsx: <ContactsTable contacts={ contacts } />
            });

            exportContactsData = contacts.map((contact) => {
                return {
                    cells: [
                        {for: 'type', content: contactService.getTypeContactText(contact.typeContact)},
                        {for: 'name', content: contact.name},
                        {for: 'phone', content: formatPhoneNumber(contact.phone)},
                        {for: 'cell', content: formatPhoneNumber(contact.cell)},
                        {for: 'email', content: contact.email},
                    ],
                };
            });

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: contactsHeadData,
                    bodyData: exportContactsData,
                }
            });
        })

        setExportTotals([
            { name: 'Total de clientes', value: count },
            { name: 'Total de clientes ativos', value: activeCount },
            { name: 'Total de clientes inativos', value: inactiveCount },
        ]);

        setExportBodyData(exportDataList);
        setCountTotalPaginatedCustomers(count);
        setCountTotalCustomers(count);
        setCountTotalActiveCustomers(activeCount);
        setCountTotalInactiveCustomers(inactiveCount);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportCustomerResponse>("/report/customer", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundCustomers(rows);
        setCountTotalPaginatedCustomers(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundCustomers;

        aux.forEach((customer) => {
            const { data, contacts } = mountBodyDataRow(customer);

            if (showContacts) {
                if (contacts && contacts.length > 0) {
                    data.push({
                        for: "contacts",
                        value: '',
                        secondaryRow: true,
                        jsx: <ContactsTable contacts={ contacts } />
                    });
                }
            }

            list.push(data);
        });

        setBodyData(list);
    }, [foundCustomers]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);
   
    function mountBodyDataRow(reportRow: Customer) {
        let contacts = reportRow.contacts as Contact[];
        const typeValue = customerService.getTypeRegisterText(reportRow.typeRegister);

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "typeRegister", value: typeValue },
            { for: "name", value: reportRow.cnpj ? reportRow.fantasyName : reportRow.name},
            { for: "cpf", value: reportRow.cpf ? formatCpfCnpj("CPF", reportRow.cpf) : formatCpfCnpj("CNPJ", reportRow.cnpj) },
            { for: "phone", value: '-' },
            { for: "situation", value: reportRow.registrationStatus === "active" ? "Ativo" : reportRow.registrationStatus ? "Inativo" : '' },
            { for: "startOfActivity", value: reportRow.startOfActivity ? new Date(reportRow.startOfActivity).toLocaleDateString() : '' },
            { for: "finalOfActivity", value: reportRow.finalOfActivity ? new Date(reportRow.finalOfActivity).toLocaleDateString() : '' },
        ];

        return { data, contacts };
    }

    const clearSearch = () => {
        setSituation('all');
        setTypeRegister('');
        setName('');
        setCity('');
        setState('');
        setStartOfActivityMin('');
        setStartOfActivityMax('');
        setFinalOfActivityMin('');
        setFinalOfActivityMax('');
        setShowContacts(false);
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            name,
            city,
            state,
            situation,
            typeRegister,
            startOfActivityMin,
            startOfActivityMax,
            finalOfActivityMin,
            finalOfActivityMax
        };

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);

    }, [
        name,
        city,
        state,
        situation,
        typeRegister,
        startOfActivityMin,
        startOfActivityMax,
        finalOfActivityMin,
        finalOfActivityMax
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({filename: 'Clientes', extension: 'xlsx'}),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-3">
                    <TextField
                        select
                        size="small"
                        label="Tipo de Cadastro"
                        margin="normal"
                        variant="outlined"
                        value={typeRegister}
                    >
                        <MenuItem key="0" value="both" onClick={() => setTypeRegister('both')}>
                            Ambos
                        </MenuItem>

                        <MenuItem key="1" value="customer" onClick={() => setTypeRegister('customer')}>
                            Clientes
                        </MenuItem>

                        <MenuItem key="2" value="supplier" onClick={() => setTypeRegister('supplier')}>
                            Fornecedores
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-3">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        variant="outlined"
                        value={situation}
                    >
                        <MenuItem key="0" value="all" onClick={() => setSituation('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                            Ativo
                        </MenuItem>

                        <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                            Inativo
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-6">
                    <TextField
                        size="small"
                        label="Nome do cliente / fornecedor"
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        size="small"
                        label="Cidade"
                        margin="normal"
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <Autocomplete
                        options={allStates.states}
                        getOptionLabel={({ sigla }) => sigla}
                        value={allStates.states.find(({ sigla }) => sigla === state) ?? null}
                        onInputChange={(event, newInputValue) => setState(newInputValue)}
                        renderInput={(params) => <TextField {...params}
                            size="small"
                            label="Estado"
                            margin="normal"
                            variant="outlined"
                        />
                        }
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data Início Atividade"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={startOfActivityMin}
                        onChange={(e) => setStartOfActivityMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data Início Atividade"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={startOfActivityMax}
                        onChange={(e) => setStartOfActivityMax(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data Final Atividade"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={finalOfActivityMin}
                        onChange={(e) => setFinalOfActivityMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Data Final Atividade"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={finalOfActivityMax}
                        onChange={(e) => setFinalOfActivityMax(e.target.value)}
                    />
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-3">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showContacts}
                                onChange={(_, checked) => setShowContacts(checked)}
                            />
                        }
                        label="Mostrar contatos"
                        labelPlacement="end"
                        className="ml-1"
                    />
                </div>
                <div className="col-9 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>

                        </>}
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Clientes e Fornecedores"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({filename: 'Clientes', extension: 'pdf'})}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                   headData={headData}
                   bodyData={bodyData}
                   loadData={loadData}
                   sortable={true}
                   totalCount={countTotalPaginatedCustomers}
                   triggerLoad={triggerLoad}
                   setTriggerLoad={setTriggerLoad}
                   lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <ReportDetails 
                            bodyData={[
                                { text: 'Total de clientes', value: String(countTotalCustomers) },
                                { text: 'Total de clientes ativos', value: String(countTotalActiveCustomers) },
                                { text: 'Total de clientes inativos', value: String(countTotalInactiveCustomers) },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}