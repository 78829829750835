import { Product } from "../hooks/products";
import { Customer } from "./Customer";
import { yesOrNo } from "./yesOrNo";

export enum QuotationStatus {
    open = 'open',
    progress = 'progress',
    attended = 'attended',
    canceled = 'canceled',
    custom = 'custom',
}

export enum QuotationSupplierStatus {
    pending = 'pending',
    sent = 'sent',
    asnwered = 'asnwered',
    chosen = 'chosen',
    canceled = 'canceled',
}

export type Quotation = {
    id: number;
    companyId: number;
    name: string;
    responsibleName: string;
    responsibleId: number;
    comments: string;
    products: string;
    totalValue: number;
    chosenSupplierId?: number;
    answered: yesOrNo;
    situation: string;
    status: QuotationStatus;
    createdDate: string;
    updatedDate: string;
    customStatus: any;

    customerEntity: Customer;
};

export type QuotationProduct = {
    id: number;
    productId: number;
    amount: number;
    unitaryValue?: number;
    freightValue?: number;
    expensesValue?: number;
    icmsTax?: number;
    ipiTax?: number;
    total?: number;
    comments?: string;
    INSTANCE: Product;
}

export type QuotationSupplier = {
    id: number;
    quotationId: number;
    supplier: number;
    supplierName?: string;
    responsible?: string;
    paymentDetails?: string;
    paymentMethod?: string;
    products?: string;
    productsTotal?: number;
    freightTotal?: number;
    expensesTotal?: number;
    totalValue?: number;
    situation?: string;
    status?: QuotationSupplierStatus;
    createdDate?: string;
    updatedDate?: string;

    INSTANCE?: {
        name: string;
        email: string;
        cell: string;

        cpfCnpj?: string;
    };
}