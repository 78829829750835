import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { orderByIdDesc } from '../utils/orderTable';

import api from '../services/Api';
import { CategoryProduct, Product, StockProductHistoric } from '../hooks/products';
import { formatCurrency, formatToFloat } from '../utils/formatCurrency';
import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../components/ListWithModalChangeSituation';
import { Search } from '../components/Search';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { NumericFormat } from '../components/NumericFormat';
import '../style.css';
import useBackendLoad from '../hooks/backendReload';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../components/ApiResourceSelect';
import BrandService from '../services/BrandService';
import CategoryProductService from '../services/CategoryProductService';
import GridService from '../services/GridService';
import StockService from '../services/StockService';

type Filters = {
    searchQuery: string;
    situation: string;
    brand: number;
    grid: number;
    category: number;
    type: string;
    saleValueMin: number;
    saleValueMax: number;
    stockDateMin: string;
    stockDateMax: string;
}

type TotalStockByStockStoLocation = {
    entry: number;
    output: number;
    total: number;
};

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
    { reference: "code", value: "Código" },
    { reference: "stock", value: "Estoque Total", notSortable: true },
    { reference: "unitaryValue", value: "Valor unitário" },
];

export function ListProducts() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [countTotalProducts, setCountTotalProducts] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [all, setAll] = useState<Product[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [situation, setSituation] = useState("");
    const [brand, setBrand] = useState(0);
    const [category, setCategory] = useState(0);
    const [grid, setGrid] = useState(0);
    const [type, setType] = useState("both");
    const [saleValueMin, setSaleValueMin] = useState(0);
    const [saleValueMax, setSaleValueMax] = useState(0);
    const [stockDateMin, setStockDateMin] = useState("");
    const [stockDateMax, setStockDateMax] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [all, brands, categories, grids]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Product[], count: number}>("products", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const mapped = rows.map((product: Product) => {
            return {
                ...product,
                saleValue: formatToFloat(product.saleValue)
            };
        });

        setAll(mapped);
        setCountTotalProducts(count);
    }, []);

    useEffect(() => {
        getBodyData();
    }, [all]);

    function getTotalHistoric(stockHistoric?: StockProductHistoric[]) {
        if (stockHistoric) {
            stockHistoric.sort(function(a, b) { 
                return a.id - b.id;
            });
        }
        
        const totalByStockLocations = new Map<number, TotalStockByStockStoLocation>();

        let totalBalance = 0;
        if (stockHistoric) {
            for (const historicItem of stockHistoric) {
                let totals = totalByStockLocations.get(historicItem.stockId) || { total: 0, entry: 0, output: 0 };
    
                if (historicItem.type === 'Balanço') {
                    totals.entry = 0;
                    totals.output = 0;
                    totals.total = historicItem.totalLocation ? formatToFloat(historicItem.totalLocation) : 0;
    
                } else {
                    totals.entry += historicItem.entry ? formatToFloat(historicItem.entry) : 0;
                    totals.output += historicItem.output ? formatToFloat(historicItem.output) : 0;
                    totals.total += formatToFloat(historicItem.entry) - formatToFloat(historicItem.output);
                }

                totalByStockLocations.set(historicItem.stockId, totals);
            }
    
            for (const [stockId, totals] of totalByStockLocations.entries()) {
                totalBalance += totals.total;
            }
        }

        return totalBalance;
    }

    async function getBodyData() {
        const list: BodyDataBaseProps[][] = [];
        const aux = all;

        for (const product of aux) {
            const amount = formatCurrency(product.saleValue);
            const stockResult = getTotalHistoric(product?.stockHistoric);

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(product.id), id: true },
                { for: "name", value: product.name ?? '' },
                { for: "code", value: product.code ?? ''},
                { for: "stock", value: String(stockResult) ?? '' },
                { for: "unitaryValue", value: amount ?? '' },
                { for: "kit", value: product.isKit ?? '' }
            ];

            list.push(data);
        }

        setBodyData(list);
    }

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = all.filter((cat) => cat.id !== Number(id));
            await api.delete(`products/${id}`);

            setAll([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [all]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickStock = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}/estoque`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setType('both');
        setBrand(0);
        setGrid(0);
        setCategory(0);
        setSaleValueMin(0);
        setSaleValueMax(0);
        setStockDateMin("");
        setStockDateMax("");
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, situation, brand, grid, category, type,
            saleValueMin, saleValueMax, stockDateMin, stockDateMax,
        };

        reloadData();

        // let stockHistory: any;
        // if(stockDateMin || stockDateMax) {
        //     const response = await api.get(`stockProductHistoric`);
        //     stockHistory = response.data;
        // }
        // const advancedFilter = all.filter((product) => {
        //     let match = true;
        //     if(situation && product.situation !== situation)
        //         match = false;
        //     if(type && type !== 'both' && product.type !== type)
        //         match = false;
        //     if(brand && Number(product.brand) !== brand)
        //         match = false;
        //     if(grid) {
        //         if(variations.find(variation => variation.gridId === grid && variation.productId === product.id) === undefined) {
        //             match = false;
        //         }
        //     }
        //     if(category && Number(product.category) !== category)
        //         match = false;
        //     if(saleValueMin && product.saleValue < saleValueMin)
        //         match = false;
        //     if(saleValueMax && product.saleValue > saleValueMax)
        //         match = false;
        //     if(stockDateFilter(product, stockHistory, stockDateMin, stockDateMax) === false)
        //         match = false;
        //     return match;
        // });
        // const filtered = advancedFilter.filter(product => {
        //     return (
        //         String(product.id).toLowerCase().includes(searchQuery.toLowerCase()) ||
        //         product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //         (product.code ?? '').toLowerCase().includes(searchQuery.toLowerCase())
        //     );
        // });
        // setAll(filtered);
    }, [
        all, searchQuery, situation, brand, grid, category, type,
        saleValueMin, saleValueMax, stockDateMin, stockDateMax,
    ]);

    function stockDateFilter(product: Product, stockHistory: any[], dateMin: string, dateMax: string): boolean {
        if(!dateMin && !dateMax) {
            return true;
        }
        if(!stockHistory) {
            return true;
        }
        const historyFiltered = stockHistory.filter(history => history.productId === product.id);
        if(historyFiltered.length === 0) {
            return false;
        }
        const dates = historyFiltered.map(history => (new Date(history.date)).getTime());
        const entryDate = Math.min(...dates);
        if(dateMin) {
            const min = (new Date(dateMin)).getTime();
            if(!min || entryDate < min) {
                return false;
            }
        }
        if(dateMax) {
            const max = (new Date(dateMax)).getTime();
            if(!max || entryDate > max) {
                return false;
            }
        }

        return true;
    }

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Adicionar produto / serviço
                        </Button>
                        : <></>
                    }
                    <Button
                        type="button"
                        variant="secondary"
                        className="mr-2"
                        onClick={() => pushHistory(`${pathname}/importar-excel`)}
                    >
                        Importar Excel
                    </Button>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={situation}
                        >
                            <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                Nenhum
                            </MenuItem>

                            <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                                Ativo
                            </MenuItem>

                            <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Marca"
                            getOptionLabel={(option: any) => option.nameBrand}
                            value={brand}
                            onSelect={(option) => setBrand(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => BrandService.getBrandsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!brand) return null;
                                return loadedOptions.find((option) => option.id === Number(brand)) ?? BrandService.getBrandById(brand)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Grade"
                            getOptionLabel={(option: any) => option.name}
                            value={grid}
                            onSelect={(option) => setGrid(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => GridService.getGridsFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!grid) return null;
                                return loadedOptions.find((option) => option.id === Number(grid)) ?? GridService.getGridById(grid)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Categoria"
                            getOptionLabel={(option: CategoryProduct) => option.nameCategory}
                            value={category}
                            onSelect={(option) => setCategory(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => CategoryProductService.getCategoriesFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!category) return null;
                                return loadedOptions.find((option) => option.id === Number(category)) ?? CategoryProductService.getCategoryById(brand)
                            }}
                        />
                    </div>
                    <div className="col-lg-2">
                        <TextField
                            select
                            size="small"
                            label="Tipo de Cadastro"
                            margin="normal"
                            variant="outlined"
                            value={type}
                        >
                            <MenuItem key="0" value="both" onClick={() => setType('both')}>
                                Ambos
                            </MenuItem>

                            <MenuItem key="1" value="product" onClick={() => setType('product')}>
                                Produtos
                            </MenuItem>

                            <MenuItem key="2" value="service" onClick={() => setType('service')}>
                                Serviços
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor de Venda"
                            startAdornment="DE"
                            value={saleValueMin}
                            onChange={(evt) => setSaleValueMin(formatToFloat(evt.target.value))}
                        />
                    </div>
                    <div className="col-lg-2">
                        <NumericFormat
                            label="Valor de Venda"
                            startAdornment="ATÉ"
                            value={saleValueMax}
                            onChange={(evt) => setSaleValueMax(formatToFloat(evt.target.value))}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Entrada Estoque"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                                ),
                            }}
                            value={stockDateMin}
                            onChange={(e) => setStockDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Entrada Estoque"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                                ),
                            }}
                            value={stockDateMax}
                            onChange={(e) => setStockDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

			<div className="mt-3">
				<ListWithModalChangeSituation
                    products
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
					onStock={handleClickStock}
					onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalProducts}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
		</div>
    );
}