import React, { useCallback } from "react";
import { NewCustomerDefaultData, NewCustomerForm } from "./NewCustomerForm";
import { Customer } from "../../types/Customer";
import { Modal } from "react-bootstrap";
import ReactPortal from "../ReactPortal";
import { IconButton } from "@material-ui/core";

type NewCustomerModalProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCreateCustomer?: (createdCustomer: Customer) => void;
    defaultData?: NewCustomerDefaultData;
    allowedType?: 'both' | 'customer' | 'supplier';
};

export function NewCustomerModal({
    showModal,
    setShowModal,
    onCreateCustomer,
    defaultData,
    allowedType = 'both',
}: NewCustomerModalProps) {
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleCustomerCreated = useCallback((createdCustomer: Customer) => {
        if (onCreateCustomer) {
            onCreateCustomer(createdCustomer);
        }
    }, [onCreateCustomer]);

    return (
        <ReactPortal containerId="portal-new-customer-modal">
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                centered
                className="newProductWrapper"
                dialogClassName="wide-modal"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adicionar um Cliente / Fornecedor
                    </Modal.Title>

                    <IconButton
                        edge="end"
                        onClick={handleCloseModal}
                        title="Fechar"
                        className="py-0"
                    >
                        <i className="flaticon-close text-body"></i>
                    </IconButton>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <NewCustomerForm
                        defaultData={defaultData}
                        allowedType={allowedType}
                        onCloseModalLimit={handleCloseModal}
                        onCustomerCreated={handleCustomerCreated}
                        onCloseCustomerCreatedSuccessModal={handleCloseModal}
                    />
                </Modal.Body>
            </Modal>
        </ReactPortal>
    )
}