import React from "react";
import { Badge } from "react-bootstrap";

type ValidationBadgeProps = {
    variant: 'success' | 'warning' | 'danger',
    label: string;
    title?: string;
}

export function ValidationBadge({ variant, label, title }: ValidationBadgeProps) {
    const textColorClass = (
        variant === 'success'
            ? 'text-success'
            : variant === 'warning'
                ? 'text-primary'
                : 'text-danger'
    );

    const iconClass = (
        variant === 'success'
            ? 'flaticon2-check-mark'
            : variant === 'warning'
                ? 'flaticon2-warning'
                : 'flaticon2-cross'
    );

    const badgeStyle = variant === 'success' ? { color: "#fff", background: "green" } : { color: "#fff" };
    const badgeTextStyle = variant === 'success' ? { color: "green" } : undefined;

    return (
        <span className={textColorClass}>
            <Badge
                pill
                variant={variant}
                className="mr-3"
                style={badgeStyle}
                title={title}
            >
                <i className={iconClass} style={{ color: "#fff" }}></i>
            </Badge>
            <span style={badgeTextStyle}>{label}</span>
        </span>
    );
}