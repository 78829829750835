import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export type TableCellProps = {
    children?: React.ReactNode,
    width?: string,
    style?: any,
};

export default function TableCell({ children, width, style }: TableCellProps) {
    const styles = StyleSheet.create({
        cell: {
            width: width,
            paddingTop: 3,
            fontSize: 6,
            fontWeight: "extrabold"
        },
    });

    return (
        <Text style={style ?? styles.cell}>
            {children}
        </Text>
    );
};