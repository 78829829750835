import { useState } from "react";

export default function useBackendLoad() {
    const [triggerLoad, setTriggerLoad] = useState(false);

    function reloadData() {
        setTriggerLoad(true);
    }

    return {
        triggerLoad,
        setTriggerLoad,
        reloadData,
    };
}