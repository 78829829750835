import { yesOrNo } from "./yesOrNo";

export enum subscriptionPlanPaymentEnvironment {
    test = "test",
    production = 'production',
};

export type SuperConfig = {
    id: number,
    defaultMsgSubscriptionPlanExpiring: string,
    defaultMsgSubscriptionPlanPaymentPending: string,
    defaultMsgSubscriptionPlanPaymentRequired: string,
    daysWithoutPlanPaymentAllowed: number,
    subscriptionBilletExpireDays: number,
    subscriptionPlanPaymentEnvironment: subscriptionPlanPaymentEnvironment,

    requireUserCellValidation: yesOrNo;

    updatedDate: string,
}