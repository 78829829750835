import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { ChangeEvent, ChangeEventHandler, EventHandler, Fragment, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export type DataColumn = {
  id: number,
  name: string,
  type: string,
  size: number,
  rows: number,
  columns: number,
  mask: string,
  option: any,
  decimal: number,
  integerDecimal: number,
  extensions: any,
  otherExtension: string,
  required: boolean,
  savedId: number,
}

export type Table = {
  id: number,
  name: string,
  dataColumns: Array<DataColumn>,
  columns: number,
  rows: number
}

type Props = {
  addTable: (index: number) => void,
  saveEditTable: (groupIndex: number, tableindex: number) => void,
  removeTable: (indexGroup: number, idFieldOrTable: number) => void,
  deleteDataColumn: (val: number) => void,
  tableIndex: number,
  groupIndex: number,
  showModalTable: boolean,
  setShowModalTable: React.Dispatch<React.SetStateAction<boolean>>,
  toEditTable: boolean,
  idTable: number,
  nameTable: string,
  setNameTable: React.Dispatch<React.SetStateAction<string>>,
  dataColumnsTable: Array<DataColumn>,
  setDataColumnsTable: React.Dispatch<React.SetStateAction<DataColumn[]>>,
  modalTitle: string,
  maskField: string,
  setMaskField: React.Dispatch<React.SetStateAction<string>>,
  optionsField: any,
  setOptionsField: React.Dispatch<React.SetStateAction<any>>,
  extensionList: any,
  setErrorModal: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorModalText: React.Dispatch<React.SetStateAction<string>>,
}

export function ModalTable ({
  addTable,
  saveEditTable,
  removeTable,
  deleteDataColumn,
  tableIndex,
  groupIndex,
  showModalTable,
  setShowModalTable,
  toEditTable,
  idTable,
  nameTable,
  setNameTable,
  dataColumnsTable,
  setDataColumnsTable,
  modalTitle,
  extensionList,
  setErrorModal,
  setErrorModalText,
}: Props) {
  const [fieldCount, setFieldCount] = useState<number>(0);
  const [fieldIndex, setFieldIndex] = useState<number>(0);
  const [nameField, setNameField] = useState<string>("");
  const [typeField, setTypeField] = useState<string>("Texto");
  const [sizeField, setSizeField] = useState<number>(50);
  const [rowsField, setRowsField] = useState<number>(1);
  const [columnsField, setColumnsField] = useState<number>(3);
  const [requiredField, setRequiredField] = useState<string>('y');
  const [maskField, setMaskField] = useState<string>("");
  const [optionField, setOptionField] = useState<any>([""]);
  const [savedIdField, setSavedIdField] = useState<number>(0);
  const [modalType, setModalType] = useState<number>(0);
  const [decimalField, setDecimalField] = useState<number>(1);
  const [integerDecimalField, setIntegerDecimalField] = useState<number>(1);
  const [extensionField, setExtensionField] = useState<any>([]);
  const [otherExtensionField, setOtherExtensionField] = useState<string>("");

  const [isExtensionChecked, setIsExtensionChecked] = useState<boolean[]>([false]);

  useEffect(() => {
    let count = dataColumnsTable.length;
    setFieldCount(count);
  }, []);

  const handleCheckOtherExtension = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newCheckboxes = [...isExtensionChecked];
    newCheckboxes[index] = e.target.checked;
    setIsExtensionChecked(newCheckboxes);

    if (!newCheckboxes[index]) {
      setOtherExtensionField("");
    }
  }

  const changeModalBody = (type: number, index?: number, toValidate?: boolean) => {
    setModalType(type);

    if (type === 2) {
      let oldCheckboxes = [...isExtensionChecked];
      oldCheckboxes.push(false);
      setIsExtensionChecked(oldCheckboxes);
    }

    if (index != null && index != undefined) {
      setFieldIndex(index);
      let data = dataColumnsTable[index];
      console.log(data);
      let required = data.required ? 'y' : 'n';
      setNameField(data.name);
      setTypeField(data.type);
      setSizeField(data.size < 0 ? 1 : data.size);
      setRowsField(data.rows < 0 ? 1 : data.rows);
      setColumnsField(data.columns < 0 ? 1 : data.columns);
      setRequiredField(required);
      setMaskField(data.mask);
      setOptionField(data.option);
      if (data.decimal < 0) {
          setDecimalField(1);
          return;
      }

      if (data.decimal > 5) {
          setDecimalField(5);
          return;
      }

      setDecimalField(data.decimal);
      setIntegerDecimalField(data.integerDecimal < 0 ? 1 : data.integerDecimal);
      setExtensionField(data.extensions);
      setOtherExtensionField(data.otherExtension);
      setSavedIdField(data.savedId);
    } else {
      if (!nameField && toValidate) {
        setErrorModal(true);
        setErrorModalText("Preencha o nome do campo da coluna!");
        setModalType(1);

        return;
      }

      if (!typeField && toValidate) {
        setErrorModal(true);
        setErrorModalText("Preencha o tipo do campo da coluna!");
        setModalType(1);
        
        return;
      }
      let count = dataColumnsTable.length;
      setFieldIndex(count);
      clearData();
    }

    if (!type) {
      clearData();
    }
  }

  const clearData = () => {
    setNameField("");
    setTypeField("Texto");
    setSizeField(50);
    setRowsField(1);
    setColumnsField(3);
    setRequiredField('y');
    setMaskField("");
    setOptionField([]);
    setExtensionField([]);
    setOtherExtensionField("");
    setSavedIdField(0);
  }

  const deleteField = (fieldIndex: number) => {
    let data = dataColumnsTable;
    if (data[fieldIndex].savedId) {
      deleteDataColumn(data[fieldIndex].savedId);
    }
    data.splice(fieldIndex, 1);
    setDataColumnsTable(data);
    changeModalBody(0);
  }

  const handleChange = (e: any, index: number, optionIndex?: number) => {
    let newArray = [...dataColumnsTable];
    let property = e.target.name;
    let value = e.target.value;

    if (!newArray[index]) {
      newArray = dataColumnsTable;
      let data = {
        id: fieldCount,
        name: "",
        type: "Texto",
        size: 50,
        rows: 1,
        columns: 3,
        mask: "",
        option: [""],
        extensions: [],
        otherExtension: "",
        decimal: 1,
        integerDecimal: 1,
        required: true,
        savedId: 0,
      }

      let count = fieldCount + 1;
      setFieldCount(count);
      newArray.push(data);
      setDataColumnsTable(newArray);
    }

    switch (property) {
      case "name":
        newArray[index].name = value;
        setNameField(value);
        break;
      case "type":
        newArray[index].type = value;
        setTypeField(value);
        break;
      case "mask":
        newArray[index].mask = value;
        setMaskField(value);
        break;
      case "option":
        if (optionIndex != null || optionIndex != undefined) {
          newArray[index].option[optionIndex] = value;
        }
        break;
      case "decimal":
        newArray[index].decimal = Number(value);
        if (Number(value) < 0) {
            setDecimalField(1);
            break;
        }

        if (Number(value) > 5) {
            setDecimalField(5);
            break;
        }

        setDecimalField(Number(value));
        break;
      case "integerDecimal":
        newArray[index].integerDecimal = Number(value);
        setIntegerDecimalField(Number(value) < 0 ? 1 : Number(value));
        break;
      case "file":
        newArray[index].extensions = value;
        setExtensionField(value);
        break;
      case "otherExtension":
        newArray[index].otherExtension = value;
        setOtherExtensionField(value);
        break;
      case "size":
        newArray[index].size = Number(value);
        setSizeField(Number(value) < 0 ? 1 : Number(value));
        break;
      case "rows":
        newArray[index].rows = Number(value);
        setRowsField(Number(value) < 0 ? 1 : Number(value));
        break;
      case "columns":
        newArray[index].columns = Number(value);
        setColumnsField(Number(value) < 0 ? 1 : Number(value));
        break;
      case "required":
        let req = value == 'y' ? true : false;
        newArray[index].required = req;
        setRequiredField(value);
        break;
      case "checkboxOtherExtension":

    }

    setDataColumnsTable(newArray);
  }

  // Função para lidar com a adição de novas opções
  const handleAddOption = (index: any) => {
    let newArray = [...dataColumnsTable];
    let count = newArray[index].option.length;
    newArray[index].option[count] = "";
    setDataColumnsTable(newArray);
    setOptionField(newArray[index].option);
  };

  const deleteOption = (indexDataColumn: number, indexOption: number) => {
    let oldArray = [...dataColumnsTable];
    let oldOptions = oldArray[indexDataColumn].option;
    oldOptions.splice(indexOption, 1);
    setDataColumnsTable(oldArray);
    setOptionField(oldOptions);
  }
  
  return (
    <Modal
      size="lg"
      show={showModalTable}
      onHide={() => {
        setShowModalTable(false);
        changeModalBody(0);
      }}
      aria-labelledby="contained-modal-warning"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{display: !modalType ? "flex" : "none"}}
      >
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField
              type="string"
              label="Nome da tabela"
              size="small"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={nameTable}
              onChange={(e) => setNameTable(e.target.value)}
            />
          </Grid>
          {dataColumnsTable.map((dataColumn, index) => (
            <Fragment key={dataColumn.id}>
              <Grid item lg={5}>
                <TextField
                  type="string"
                  label="Nome da coluna"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  name="name"
                  value={dataColumn.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                />
              </Grid>
              <Grid item lg={5}>
                <TextField
                  select
                  label="Tipo da coluna"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  name="type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataColumn.type}
                  disabled={true}
                >
                  <MenuItem key="0" value="Texto">
                    Texto
                  </MenuItem>

                  <MenuItem key="1" value="Inteiro">
                    Número Inteiro
                  </MenuItem>

                  <MenuItem key="2" value="Decimal">
                    Número Decimal
                  </MenuItem>

                  <MenuItem key="3" value="OPCOES">
                    Opções
                  </MenuItem>

                  <MenuItem key="4" value="Mascara">
                    Máscara
                  </MenuItem>

                  <MenuItem key="5" value="Data">
                    Data
                  </MenuItem>

                  <MenuItem key="6" value="Anexo">
                    Anexo
                  </MenuItem>

                  <MenuItem key="7" value="Caixa_verificacao">
                    Caixa de Verificação
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item lg={1}>
                <Tooltip title="Editar">
                  <Button
                    type="button"
                    variant="warning"
                    size="sm"
                    className="mt-5"
                    onClick={() => changeModalBody(1, index)}
                  >
                    <i className="flaticon2-edit p-0"></i>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item lg={1}>
                <Tooltip title="Excluir">
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    className="mt-5"
                    onClick={() => deleteField(index)}
                  >
                    <i className="flaticon2-trash p-0"></i>
                  </Button>
                </Tooltip>
              </Grid>
            </Fragment>
          ))}
          <Grid item lg={3}>
            <Button variant="primary" onClick={() => changeModalBody(2)}>
              <i className="flaticon2-plus"></i>
              <span>Adicionar coluna</span>
            </Button>
          </Grid>
        </Grid>
      </Modal.Body>

      <Modal.Body
        style={{display: modalType ? "flex" : "none"}}
      >
        <Grid container spacing={3}>
          <Fragment>
            <Grid item lg={6}>
              <TextField
                type="string"
                label="Nome do Campo"
                size="small"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                name={"name"}
                value={nameField}
                onChange={(e) => handleChange(e, fieldIndex)}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                select
                label="Tipo"
                size="small"
                margin="normal"
                variant="outlined"
                name={"type"}
                value={typeField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e, fieldIndex)}
              >
                <MenuItem key="0" value="Texto">
                  Texto
                </MenuItem>

                <MenuItem key="1" value="Inteiro">
                  Número Inteiro
                </MenuItem>

                <MenuItem key="2" value="Decimal">
                  Número Decimal
                </MenuItem>

                <MenuItem key="3" value="OPCOES">
                  Opções
                </MenuItem>

                <MenuItem key="4" value="Mascara">
                  Máscara
                </MenuItem>

                <MenuItem key="5" value="Data">
                  Data
                </MenuItem>

                <MenuItem key="6" value="Anexo">
                  Anexo
                </MenuItem>

                <MenuItem key="7" value="Caixa_verificacao">
                  Caixa de Verificação
                </MenuItem>
              </TextField>
            </Grid>
            <Grid className={typeField === "Texto" ? "" : "d-none"} item lg={3}>
              <TextField
                type="number"
                label="Tamanho"
                size="small"
                margin="normal"
                variant="outlined"
                name={"size"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 }
                }}
                value={sizeField}
                onChange={(e) => handleChange(e, fieldIndex)}
              />
            </Grid>
            <Grid className={typeField === "Texto" ? "" : "d-none"} item lg={3}>
              <TextField
                type="number"
                label="Linhas"
                size="small"
                margin="normal"
                variant="outlined"
                name={"rows"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 }
                }}
                value={rowsField}
                onChange={(e) => handleChange(e, fieldIndex)}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                type="number"
                label="Colunas"
                size="small"
                margin="normal"
                variant="outlined"
                name={"columns"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1, max: 12 }
                }}
                value={columnsField}
                onChange={(e) => handleChange(e, fieldIndex)}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                select
                label="Obrigatório"
                size="small"
                margin="normal"
                variant="outlined"
                name={"required"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={requiredField}
                onChange={(e) => handleChange(e, fieldIndex)}
              >
                <MenuItem key="0" value="y">
                  Sim
                </MenuItem>

                <MenuItem key="1" value="n">
                  Não
                </MenuItem>
              </TextField>
            </Grid>
            {typeField === "Decimal" && (
              <>
                <Grid item lg={3}>
                  <TextField
                      type="number"
                      label="Casas antes da vírgula"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      name="integerDecimal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1 }
                      }}
                      value={integerDecimalField}
                      onChange={(e) => handleChange(e, fieldIndex)}
                    />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                      type="number"
                      label="Casas decimais"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      name={"decimal"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: 1, max: 5 }
                      }}
                      value={decimalField}
                      onChange={(e) => handleChange(e, fieldIndex)}
                    />
                </Grid>
              </>
            )}
            {typeField === "OPCOES" && (
              <>
                {optionField.map((option: string, index: number) => (
                  <Fragment key={index}>
                    <Grid item lg={11}>
                      <TextField
                        type="string"
                        label={`Opção ${index + 1}`}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        name={"option"}
                        value={option}
                        onChange={(e) => handleChange(e, fieldIndex, index)}
                      />
                    </Grid>
                    <Grid item lg={1}>
                      <Tooltip title="Remover">
                        <Button onClick={() => deleteOption(fieldIndex, index)} className="mt-5" size="sm" variant="danger">
                          <i className="flaticon2-trash p-0"></i>
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                ))}
                <Grid item lg={12}>
                  <Button onClick={() => handleAddOption(fieldIndex)}>+ Adicionar Opção</Button>
                </Grid>
              </>
            )}

              {typeField === "Mascara" && (
                <Grid item lg={12}>
                  <TextField
                    type="string"
                    label="Formato da Máscara"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    name={"mask"}
                    value={maskField}
                    onChange={(e) => handleChange(e, fieldIndex)}
                    placeholder="Exemplo: (11) 9XXXX-XXXX"
                  />
                  <Typography variant="body2" color="textSecondary">
                    Exemplos de uso para máscaras:
                    <br />
                    CPF: 000.000.000-00
                    <br />
                    CNPJ: 00.000.000/0000-00
                    <br />
                    Telefone: (00) 0000-0000
                    <br />
                    Celular: (00) 0 0000-0000
                  </Typography>
                </Grid>
              )}

              {typeField === "Anexo" && (
                <>
                  <Grid item lg={6}>
                    <TextField
                        size="small"
                        margin={"normal"}
                        variant={"outlined"}
                        select
                        label="Tipo(s) de arquivo"
                        style={{width: "100%"}}
                        name={"file"}
                        SelectProps={{
                          multiple: true,
                          value: extensionField,
                          onChange: (e) => handleChange(e, fieldIndex)
                        }}
                    >
                        {
                            extensionList.map((d: any, index: number) =>
                                <MenuItem key={index} value={d}>{d}</MenuItem>
                            )
                        }
                    </TextField>
                  </Grid>
                  <Grid item lg={3}>
                    <FormControlLabel control={<Checkbox checked={isExtensionChecked[fieldIndex]} onChange={(e) => handleCheckOtherExtension(e, fieldIndex)} color="primary" />} label="Outra extensão" />
                  </Grid>
                  <Grid className={isExtensionChecked[fieldIndex] ? "" : "d-none"} item lg={3}>
                    <TextField
                      type="string"
                      label="Extensão"
                      size="small"
                      margin="normal"
                      variant="outlined"
                      name="otherExtension"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={otherExtensionField}
                      onChange={(e) => handleChange(e, fieldIndex)}
                    />
                  </Grid>
                </>
              )}
          </Fragment>
        </Grid>
      </Modal.Body>

      <Modal.Footer 
        className={`flex flex-row align-items-center ${toEditTable ? 'justify-content-between' : 'justify-content-end'}`}
        style={{display: !modalType ? "flex" : "none"}}
      >
          {
            toEditTable
            ?
            <Button
              variant="danger"
              onClick={() => removeTable(groupIndex, idTable)}
            >
              Deletar
            </Button>
            : <></>
          }
          <div className='flex flex-row align-items-center'>
            <Button
              variant="secondary"
              onClick={() => setShowModalTable(!showModalTable)}
              className='mr-3'
            >
              Fechar
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                toEditTable
                  ? saveEditTable(groupIndex, idTable)
                  : addTable(groupIndex)
              }
            >
              {toEditTable ? "Salvar" : "Adicionar"}
            </Button>
          </div>
      </Modal.Footer>

      <Modal.Footer
        style={{display: modalType ? "flex" : "none"}}
      >
        <Button variant="primary" onClick={() => {
          changeModalBody(0, undefined, true);
        }}>
          <span>Adicionar</span>
        </Button>
        <Button variant="secondary" onClick={() => changeModalBody(0)}>
          <span>Voltar</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = {
  emptyButton: {
    border: "none",
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
  }
}