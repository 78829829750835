import React, {
    createContext,
    useCallback,
    useContext,
    useState
} from "react";

export type Customer = {
    id: number;
    name: string;
    typeRegister: "supplier" | "customer" | "both";
    email: string;
    cell: string;
}

interface CustomerResponse {
    id: number;
    name: string;
    corporateName: string;
    typeRegister: "supplier" | "customer" | "both";
    email: string;
    cell: string;
}

interface CustomerContextData {
    customers: Customer[];
    companies: Customer[];
    suppliers: Customer[];
    allCustomer: Customer[];
    suppliersNames: string[];
    allCustomerNames: string[];
    getCustomerById(id: number): Customer;
}

const CustomerContext = createContext<CustomerContextData>({} as CustomerContextData);

const CustomerProvider: React.FC = ({ children }) => {
    const [suppliersNames] = useState(['']);
    const [allCustomerNames] = useState(['']);
    const [allCustomer] = useState<Customer[]>([]);
    const [suppliers] = useState<CustomerResponse[]>([]);
    const [customers] = useState<CustomerResponse[]>([]);
    const [companies] = useState<CustomerResponse[]>([]);

    const getCustomerById = useCallback((id: number) => {
        const customer = allCustomer.find(customer => customer.id === id);

        if (!customer) {
            throw new Error("This customer does not exists!");
        }

        return customer;
    }, [allCustomer]);

    return (
        <CustomerContext.Provider value={{
            customers,
            allCustomer,
            allCustomerNames,
            suppliers,
            suppliersNames,
            companies,
            getCustomerById,
        }}>
            {children}
        </CustomerContext.Provider>
    );
}

function useCustomer() {
    const context = useContext(CustomerContext);

    if (!context) {
        throw new Error('The method useCustomer must be used eithin a CustomerProvider!');
    }

    return context;
}

export { CustomerProvider, useCustomer };