import { format } from "date-fns";
import { QuotationSupplier, QuotationSupplierStatus } from "../types/Quotation";
import { Situation } from "../types/Situation";
import api from "./Api";
import customerService from "./CustomerService";

type Params = {
    withSupplier?: boolean;
};

class QuotationSupplierService {
    setBypassAuth(value: boolean) {
        if(value) {
            api.defaults.headers['x-bypass-auth']  = `0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5`;
        } else {
            api.defaults.headers['x-bypass-auth'] = undefined;
        }
    }

    async getQuotationSupplierById(id: number | string, { withSupplier = false }: Params = {}) {
        try {
            const { data } = await api.get<QuotationSupplier>(`quotationSupplier/${id}`);

            let quotationSupplier = data;
            if (withSupplier) {
                const supplier = await customerService.getCustomerById(quotationSupplier.supplier);
                quotationSupplier = {
                    ...quotationSupplier,
                    INSTANCE: {
                        name: supplier.name,
                        email: supplier.email,
                        cell: supplier.cell,
                        cpfCnpj: supplier.typePeople === 'legal' ? supplier.cnpj : supplier.cpf,
                    },
                };
            }

            return quotationSupplier;
        } catch (error) {
            return undefined;
        }
    }

    async getQuotationSuppliersByQuotationId(quotationId: number | string, { withSupplier = false }: Params = {}) {
        try {
            const { data } = await api.get<QuotationSupplier[]>(`quotation/${quotationId}/quotationSupplier`);

            let quotationSuppliers = data;
            if (withSupplier) {
                quotationSuppliers = await Promise.all(data.map(async (quotationSupplier) => {
                    const supplier = await customerService.getCustomerById(quotationSupplier.supplier);
                    return {
                        ...quotationSupplier,
                        INSTANCE: {
                            name: supplier.name,
                            email: supplier.email,
                            cell: supplier.cell,
                            cpfCnpj: supplier.typePeople === 'legal' ? supplier.cnpj : supplier.cpf,
                        },
                    };
                }));
            }

            return quotationSuppliers;
        } catch (error) {
            return [];
        }
    }

    async createQuotationSupplier(data: any) {
        try {
            const response = await api.post<QuotationSupplier>('quotationSupplier/', data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateQuotationSupplier(id: number | string, data: any) {
        try {
            const response = await api.put<QuotationSupplier>(`quotationSupplier/${id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async deleteQuotationSupplier(id: number | string) {
        try {
            await api.delete(`quotationSupplier/${id}`);
        } catch { }
    }

    async saveMultipleQuotationSuppliers(dataArray: QuotationSupplier[]) {
        for (const supplierData of dataArray) {
            const supplierRaw: any = {
                quotationId: supplierData.quotationId,
                supplier: supplierData.supplier,
                supplierName: supplierData.INSTANCE?.name,
                status: QuotationSupplierStatus.pending,
            };

            if (supplierData.id !== 0) {
                await this.updateQuotationSupplier(supplierData.id, supplierRaw);
            } else {
                const supplierSituation: Situation[] = [];

                supplierSituation.push({
                    dateSituation: format(Date.now(), "yyyy-MM-dd"),
                    commentsSituation: "",
                    statusSituation: QuotationSupplierStatus.pending,
                });
                supplierRaw.situation = JSON.stringify(supplierSituation);

                await this.createQuotationSupplier(supplierRaw);
            }

        }
    }

    async updateQuotationSupplierSituation(id: number | string, data: {
        newStatus: QuotationSupplierStatus;
        comments?: string;
    }) {
        try {
            const quotationSupplier = await this.getQuotationSupplierById(id);
            const supplierSituation: Situation[] = quotationSupplier?.situation ? JSON.parse(quotationSupplier.situation) : [];

            supplierSituation.push({
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                commentsSituation: data.comments ?? '',
                statusSituation: data.newStatus,
            });

            let extraData: any = {};
            if(data.newStatus === QuotationSupplierStatus.sent || data.newStatus === QuotationSupplierStatus.canceled) {
                extraData = {
                    responsible: null,
                    paymentDetails: null,
                    products: null,
                    productsTotal: null,
                    freightTotal: null,
                    expensesTotal: null,
                    totalValue: null,
                };
            }

            const result = await this.updateQuotationSupplier(id, {
                ...extraData,
                situation: JSON.stringify(supplierSituation),
                status: data.newStatus,
            });

            return result;
        } catch (error) {
            throw error;
        }
    }

    convertStringToStatus(status: string): QuotationSupplierStatus {
        switch (status) {
            case QuotationSupplierStatus.pending:
                return QuotationSupplierStatus.pending;

            case QuotationSupplierStatus.sent:
                return QuotationSupplierStatus.sent;

            case QuotationSupplierStatus.asnwered:
                return QuotationSupplierStatus.asnwered;

            case QuotationSupplierStatus.chosen:
                return QuotationSupplierStatus.chosen;

            case QuotationSupplierStatus.canceled:
                return QuotationSupplierStatus.canceled;

            default:
                return QuotationSupplierStatus.pending;
        }
    }

    getDefaultEmailMessage(): string {
        return `Estamos lhe enviando uma solicitação de cotação de preços de alguns produtos, para analisarmos a possibilidade de realizarmos uma compra com você!`
    }

    getDefaultWhatsappMessage(customerName: string): string {
        return `Olá ${customerName}, estamos lhe enviando uma solicitação de cotação de preços de alguns produtos, para analisarmos a possibilidade de realizarmos uma compra com você!`
    }

    isEmailSent(quotationSupplier: QuotationSupplier): boolean {
        return (
            quotationSupplier.status !== QuotationSupplierStatus.pending && quotationSupplier.status !== QuotationSupplierStatus.canceled
        );
    }

    isCanceled(quotationSupplier: QuotationSupplier): boolean {
        return (
            quotationSupplier.status === QuotationSupplierStatus.canceled
        );
    }

    isAnswered(quotationSupplier: QuotationSupplier): boolean {
        return (
            quotationSupplier.status === QuotationSupplierStatus.asnwered || quotationSupplier.status === QuotationSupplierStatus.chosen
        );
    }

    isFinished(quotationSupplier: QuotationSupplier): boolean {
        return (
            quotationSupplier.status === QuotationSupplierStatus.chosen || quotationSupplier.status === QuotationSupplierStatus.canceled
        );
    }

    canBeAnswered(quotationSupplier: QuotationSupplier): boolean {
        return (
            !this.isAnswered(quotationSupplier) && !this.isFinished(quotationSupplier)
        );
    }

    getCanNotAnswerMessage(quotationSupplier: QuotationSupplier): string {
        if(this.canBeAnswered(quotationSupplier)) return '';

        if(quotationSupplier.status === QuotationSupplierStatus.asnwered) {
            return 'Sua proposta foi processada e enviada para o solicitante, aguarde o retorno via email ou whatsapp!';
        } else {
            return 'O processo de cotação foi encerrado e portanto não aceita mais propostas';
        }
    }
}

export default new QuotationSupplierService();