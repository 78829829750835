import api from "./Api";

type NfWhatsappData = {
    nf: any;
    type: NfType;
    customerId: string;
    customerName: string;
    refName: string;
    message: string;
}

type NfType = 'nfe' | 'nfce' | 'nfse';

class WhatsappNfService {
    async getAllWhatsappsByNfId(nfId: number | string, type: NfType) {
        try {
            const { data } = await api.get<any[]>(`whatsapp-nf/${type}/${nfId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendWhatsapp(number: string, {
        nf,
        type,
        customerId,
        customerName,
        refName,
        message,
    }: NfWhatsappData) {
        let danfeUrl = '';
        let xmlUrl = '';

        const apiResponseObj = nf.api_last_response ? JSON.parse(nf.api_last_response) : null;
        if (apiResponseObj.body && (apiResponseObj.body.url || apiResponseObj.body.caminho_danfe) && apiResponseObj.body.caminho_xml_nota_fiscal) {
            danfeUrl = apiResponseObj.body.url || apiResponseObj.body.caminho_danfe;

            const apiUrl = apiResponseObj.url;

            if(apiResponseObj.body.caminho_danfe) {
                danfeUrl = `${apiUrl}${apiResponseObj.body.caminho_danfe}`
            }

            xmlUrl = `${apiUrl}${apiResponseObj.body.caminho_xml_nota_fiscal}`;
        }

        const raw = {
            number,
            name: refName,
            type,
            id: nf.id,
            nameCustomer: customerName,
            customerId,
            message: message,
            danfeUrl,
            xmlUrl,
        };

        await api.post('whatsapp-nf/send', raw);
    };
}

export default new WhatsappNfService();