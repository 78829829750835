import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
});

export type TableRowProps = {
    children?: React.ReactNode,
    key?: string | number,
};

export default function TableRow({ children }: TableRowProps) {
    return (
        <View style={styles.row}>
            {children}
        </View>
    );
};