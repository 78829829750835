import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { formatCpfCnpj } from '../../../utils/formatCpfCnpj';
import { Search } from '../../../components/Search';
import { Collapse, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import useBackendLoad from '../../../hooks/backendReload';
import { useDispatch } from 'react-redux';
import { BsVariant } from '../../../types/BsVariant';
import * as auth from "../../../modules/Auth";
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { SubscriptionPlan } from '../../../types/SubscriptionPlan';
import SubscriptionPlanService from '../../../services/SubscriptionPlanService';
import { formatDate, formatStringDateToLocale } from '../../../utils/dateFormat';

type Filters = {
    searchQuery: string;
    situation: string;
    subscriptionPlan: number;
    dueDateMin: string;
    dueDateMax: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
    { reference: "cnpj", value: "CNPJ" },
    { reference: "plan", value: "Plano Contratado" },
    { reference: "situation", value: "Situação" },
    { reference: "dueDate", value: "Vencimento" },
];

export function ListAccountingCompanies() {
    const [companies, setCompanies] = useState<any[]>([]);
    const [dueDateMin, setDueDateMin] = useState('');
    const [dueDateMax, setDueDateMax] = useState('');
    const [subscriptionPlan, setSubscriptionPlan] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCustomers, setCountTotalCustomers] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [situation, setSituation] = useState("");

    const dispatch = useDispatch();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: any[], count: number}>("/accountantCompanies", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCompanies(rows);
        setCountTotalCustomers(count);
    }, []);
    
    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];

        companies.forEach((company: any) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(company.id), id: true },
                { for: "name", value: company.name ? company.name : company.corporateName  },
                { for: "cnpj", value: formatCpfCnpj("CNPJ", company.cnpj) },
                { for: "plan", value: company.subscriptionPlan?.name ?? '' },
                { for: "situation", value: company.plan && company.plan.active === "y" ? "Ativo" : "Inativo" },
                { for: "dueDate", value: company.plan?.expirationDate ? formatDate(company.plan?.expirationDate) : '-' },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [companies]);

    const handleChangeAccountingArea = useCallback((id: string) => {
        dispatch(auth.actions.setSelectedCompanyId(id));
        window.location.href = ('/contabil');
    }, []);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setSubscriptionPlan(0);
        setDueDateMin('');
        setDueDateMax('');
    }

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery,
            situation, 
            subscriptionPlan,
            dueDateMin,
            dueDateMax
        };
        reloadData();
    }, [
        companies, 
        searchQuery, 
        situation, 
        subscriptionPlan,
        dueDateMin,
        dueDateMax
    ]);

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            select
                            size="small"
                            label="Situação"
                            margin="normal"
                            variant="outlined"
                            value={situation}
                        >
                            <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                Nenhum
                            </MenuItem>

                            <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                                Ativo
                            </MenuItem>

                            <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Planos"
                            getOptionLabel={(option: SubscriptionPlan) => option.name}
                            value={subscriptionPlan}
                            onSelect={(option) => setSubscriptionPlan(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => SubscriptionPlanService.getSubscriptionPlanFiltered({ name: typedText })}
                            getSelectedOption={(loadedOptions) => {
                                if(!subscriptionPlan) return null;
                                return loadedOptions.find((option) => option.id === Number(subscriptionPlan)) ?? SubscriptionPlanService.getSubscriptionPlanById(subscriptionPlan)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Vencimento"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                    ),
                                }}
                                value={dueDateMin}
                                onChange={(e) => setDueDateMin(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Data de Vencimento"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                    ),
                                }}
                                value={dueDateMax}
                                onChange={(e) => setDueDateMax(e.target.value)}
                            />
                        </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

			<div className="col-lg-12 mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
                    customButtons={[
                        {
                            class: 'btn-light-info',
                            content: (<i className='p-0 fas fa-arrow-right'></i>),
                            variant: BsVariant.PRIMARY,
                            popup: "Ir para área contábil",
                            onClick: handleChangeAccountingArea
                        },
                    ]}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalCustomers}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    minWidth={100}
				/>
			</div>
		</div>
    );
}