import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';

export type ModalSuccessProps = {
    msgModal: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    redirect?: string;
    onClose?: () => void;
}

export default function ModalSuccess({
    msgModal,
    showModal,
    setShowModal,
    redirect,
    onClose,
} :ModalSuccessProps) {
    const history = useHistory();

    function handleCloseModal() {
        setShowModal(false);

        if (onClose) {
            onClose();
        }

        if (redirect?.length) {
            history.push(redirect);
        }
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                    Sucesso
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <b>{msgModal}</b>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}