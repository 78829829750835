import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { Button, Modal, OverlayTrigger, Spinner } from 'react-bootstrap';
import { NumericFormat } from '../../components/NumericFormat';
import api from '../../services/Api';
import { CashRegister, yesOrNo } from '../../types/CashRegister';
import { CashRegisterPickup } from '../../types/CashRegisterPickup';
import { CashRegisterSupply } from '../../types/CashRegisterSupply';
import { PdvSale } from '../../types/PdvSale';
import { PdvSalePayment } from '../../types/PdvSalePayment';
import { getDate } from '../../utils/dateTimeHelper';
import { formatCurrency, formatIntegerToMoney, formatMoneyToInteger, formatToFloat } from '../../utils/formatCurrency';
import userTableMock from "../../../app/modules/Auth/__mocks__/userTableMock";
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import useCurrentBalance from '../../hooks/useCurrentBalance';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';

export type ModalCashRegisterProps = {
    cashRegister: CashRegister,
    setCashRegister: (value: React.SetStateAction<CashRegister | undefined>) => void,
    showModalCashRegister: boolean
    setShowModalCashRegister: (value: React.SetStateAction<boolean>) => void,
    title: string,
    type: ModalCashRegisterType,
};

export enum ModalCashRegisterType {
    CLOSE = 'close',
    MOVEMENT = 'movement',
}

export default function ModalCashRegister({
    cashRegister,
    setCashRegister,
    showModalCashRegister,
    setShowModalCashRegister,
    title,
    type,
}: ModalCashRegisterProps) {
    const { user } = useSelector((state: any) => state.auth);

    const [isSubmit, setIsSubmit] = useState(false);

    const [pickups, setPickups] = useState<CashRegisterPickup[]>([]);
    const [supplies, setSupplies] = useState<CashRegisterSupply[]>([]);

    const [lastCashRegister, setLastCashRegister] = useState<CashRegister>();
    const [cashTotal, setCashTotal] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [totalPickups, setTotalPickups] = useState(0);
    const [totalSupplies, setTotalSupplies] = useState(0);
    const [pixEarned, setPixEarned] = useState(0);
    const [cardEarned, setCardEarned] = useState(0);
    const [moneyEarned, setMoneyEarned] = useState(0);
    const [movementValue, setMovementValue] = useState(0);
    const [notes, setNotes] = useState('');
    const [operation, setOperation] = useState('pickup');
    const [accountBank, setAccountBank] = useState("");
    const [availableMoney, setAvailableMoney] = useState(0);
    const [loadingCalc, setLoadingCalc] = useState(false);
    
    const [msgError, setMsgError] = useState('');

    const {getAccountBalance} = useCurrentBalance();

    async function calcAvailableMoney() {
        setLoadingCalc(true);

        var totalValueAccount = await getAccountBalance(cashRegister.bankAccount);
        setAvailableMoney(totalValueAccount);
        
        setLoadingCalc(false);
    }

    async function getData() {
        const resultPickups = await api.get(`/pdvCashRegisterPickups/pdvCashRegister/${cashRegister.id}`);
        const pickups: CashRegisterPickup[] = resultPickups.data;

        const resultSupplies = await api.get(`/pdvCashRegisterSupplies/pdvCashRegister/${cashRegister.id}`);
        const supplies: CashRegisterSupply[] = resultSupplies.data;

        setPickups(pickups);
        setSupplies(supplies);
    }

    async function getTotals() {
        const resultSales = await api.get(`/pdvSales/pdvCashRegister/${cashRegister.id}`);
        const sales: PdvSale[] = resultSales.data;
        let cashTotal = 0;
        let totalEarned = 0;
        let pixEarned = 0;
        let cardEarned = 0;
        let moneyEarned = 0;
        let totalChange = 0;

        for(const sale of sales) {
            totalChange += sale.changeValue;
        }

        for(const sale of sales) {
            totalEarned += sale.totalValue;

            const resultPayments = await api.get(`/pdvSalePayments/pdvSale/${sale.id}`);
            const payments: PdvSalePayment[] = resultPayments.data;
            for(const payment of payments) {
                if(payment.method === 'money') {
                    moneyEarned += payment.paidValue;
                } else if(payment.method === 'credit_card' || payment.method === 'debit_card') {
                    cardEarned += payment.paidValue;
                } else if(payment.method === 'pix') {
                    pixEarned += payment.paidValue;
                }
            }
        }

        moneyEarned = moneyEarned - totalChange;
        totalEarned = moneyEarned + cardEarned + pixEarned;

        let lastCashRegister: CashRegister = (await api.get(`pdvCashRegister/not_active/last`)).data;
        // const lastCashRegisterValue = lastCashRegister ? lastCashRegister.currentValue : 0;
        // cashTotal = (lastCashRegisterValue + cashRegister.startValue + moneyEarned) - totalPickups + totalSupplies;
        cashTotal = (cashRegister.startValue + moneyEarned) - totalPickups + totalSupplies;

        setLastCashRegister(lastCashRegister);
        setMoneyEarned(moneyEarned);
        setCardEarned(cardEarned);
        setPixEarned(pixEarned);
        setTotalEarned(totalEarned);
        setCashTotal(cashTotal);
        setAccountBank(String(cashRegister.bankAccount));
    }

    function getMovementTotals() {
        let totalPickups = 0;
        for(const pickup of pickups) {
            totalPickups += pickup.pickupValue;
        }

        let totalSupplies = 0;
        for(const supply of supplies) {
            totalSupplies += supply.supplyValue;
        }

        setTotalPickups(totalPickups);
        setTotalSupplies(totalSupplies);
    }

    async function handleSubmit() {
        setMsgError('');
        setIsSubmit(true);

        if(type === ModalCashRegisterType.CLOSE && movementValue !== cashTotal) {
            setMsgError('É necessário retirar todo o dinheiro em caixa para realizar o fechamento!');
            setIsSubmit(false);
            return;
        }
        if(operation === 'pickup' && movementValue > cashTotal) {
            setMsgError('O caixa não tem dinheiro suficiente para retirada!');
            setIsSubmit(false);
            return;
        }

        try {
            let currentValue = cashRegister.currentValue;

            if(operation === 'pickup') {
                const rawPickup = {
                    userId: user.id,
                    cashRegisterId: cashRegister.id,
                    pickupValue: movementValue,
                    notes: notes,
                    createdDate: getDate().fullDateStr,
                }

                const resultPickup = await api.post('pdvCashRegisterPickups', rawPickup);
                if(!resultPickup.data) {
                    throw 'Erro ao processar retirada';
                }
                const pickupsClone = pickups;
                pickupsClone.push(resultPickup.data);

                currentValue -= movementValue;
                setPickups(pickupsClone);

                // Atualizando valor em caixa na conta bancaria selecionada
                await api.patch(`/accountBank/cashierMoney/${cashRegister.bankAccount}`, { valueToRemove: movementValue });
                
            } else if(operation === 'supply') {
                const rawSupply = {
                    userId: user.id,
                    cashRegisterId: cashRegister.id,
                    supplyValue: movementValue,
                    notes: notes,
                    createdDate: getDate().fullDateStr,
                }

                const resultSupply = await api.post('pdvCashRegisterSupplies', rawSupply);
                if(!resultSupply.data) {
                    throw 'Erro ao processar suprimento';
                }
                const suppliesClone = supplies;
                suppliesClone.push(resultSupply.data);

                currentValue += movementValue;
                setSupplies(suppliesClone);

                // Atualizando valor em caixa na conta bancaria selecionada
                await api.patch(`/accountBank/cashierMoney/${cashRegister.bankAccount}`, { valueToAdd: movementValue });
            }


            // Atualizar cashRegister
            let rawCashRegister = {
                currentValue: currentValue,
                active: type === ModalCashRegisterType.CLOSE ? yesOrNo.NO : yesOrNo.YES,
                closedDate: type === ModalCashRegisterType.CLOSE ? getDate().fullDateStr : null,
            }
            const resultCashRegister = await api.post(`pdvCashRegister/${cashRegister.id}`, rawCashRegister);
            if(!resultCashRegister.data) {
                throw 'Erro ao processar operação';
            }
        

            setNotes('');
            setMovementValue(0);
            setOperation('pickup');
            setIsSubmit(false);
            setShowModalCashRegister(false);
            if(type === ModalCashRegisterType.CLOSE) {
                setCashRegister(undefined);
            } else {
                setCashRegister(resultCashRegister.data);
            }
            return;

        } catch (error) {
            console.log(error);
        }

        setIsSubmit(false);
        setShowModalCashRegister(false);
    }

    function handleMoneyInputChange(event: any, setter: Function) {
        setter(formatMoneyToInteger(event.target.value))
    }

    useEffect(() => {
            setMsgError('');
            getData();
    }, []);

    useEffect(() => {
            getMovementTotals();
            getTotals();
    }, [cashRegister, pickups, totalPickups, supplies, totalSupplies]);

    return (
        
        <>
            <Modal
                size="lg"
                show={showModalCashRegister}
                onHide={() => setShowModalCashRegister(false)}
                aria-labelledby="contained-modal-warning"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        {title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row pl-2">
                        <div className="col-md-4">
                            <h6 className="card-title mb-0">RESUMO</h6>
                            <div className="form-group mb-0">
                                {/* total_earned */}
                                <NumericFormat
                                    className="inputSmaller" 
                                    label="Faturamento"
                                    disabled
                                    value={formatIntegerToMoney(totalEarned)}
                                />
                            </div>
                            <div className="form-group mb-0">
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-10 pr-0">
                                        {/* pickup_value */}
                                        <NumericFormat
                                            className="inputSmaller" 
                                            label="Retirada supervisor"
                                            disabled
                                            value={formatIntegerToMoney(totalPickups)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="auto"
                                            overlay={
                                                (
                                                    <Popover id="pickup-popover">
                                                        <div className="p-5">
                                                            {pickups.length > 0 ? (
                                                                <ul className="list-group">
                                                                    {pickups.map((pickup, index) => {
                                                                        const foundUser = userTableMock.find((obj) => obj.id === pickup.userId);
                                                                        return (
                                                                        <li style={{color: 'red'}} key={index} className="list-group-item">
                                                                            {pickup.createdDate} - {formatIntegerToMoney(pickup.pickupValue, true)} - {foundUser !== undefined ? `${foundUser.firstname} ${foundUser.lastname}` : ''}
                                                                            <span className="d-block">
                                                                                Ocorrência: {pickup.notes.trim() ? pickup.notes.trim() : 'N/A'}
                                                                            </span>
                                                                        </li>
                                                                    )})}
                                                                </ul>
                                                            ) : (
                                                                <span>Sem Histórico</span>
                                                            )}
                                                        </div>
                                                    </Popover>
                                                )
                                            }
                                        >
                                            <i className="flaticon-info pickup-info-button mx-0"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-0">
                                <div className="row d-flex align-items-center">
                                    <div className="col-md-10 pr-0">
                                        {/* supply_value */}
                                        <NumericFormat
                                            className="inputSmaller" 
                                            label="Suprimentos supervisor"
                                            disabled
                                            value={formatIntegerToMoney(totalSupplies)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="auto"
                                            overlay={
                                                (
                                                    <Popover id="supply-popover">
                                                        <div className="p-5">
                                                            {supplies.length > 0 ? (
                                                                <ul className="list-group">
                                                                    {supplies.map((supply, index) => {
                                                                        const foundUser = userTableMock.find((obj) => obj.id === supply.userId);
                                                                        return (
                                                                        <li style={{color: 'green'}} key={index} className="list-group-item">
                                                                            {supply.createdDate} - {formatIntegerToMoney(supply.supplyValue, true)} - {foundUser !== undefined ? `${foundUser.firstname} ${foundUser.lastname}` : ''}
                                                                            <span className="d-block">
                                                                                Ocorrência: {supply.notes.trim() ? supply.notes.trim() : 'N/A'}
                                                                            </span>
                                                                        </li>
                                                                    )})}
                                                                </ul>
                                                            ) : (
                                                                <span>Sem Histórico</span>
                                                            )}
                                                        </div>
                                                    </Popover>
                                                )
                                            }
                                        >
                                            <i className="flaticon-info pickup-info-button mx-0"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-0">
                            <div className="row d-flex align-items-center">
                                    <div className="col-md-10 pr-0">
                                        {/* cash_total */}
                                        <NumericFormat
                                            className="inputSmaller" 
                                            label="Total em caixa"
                                            disabled
                                            value={formatIntegerToMoney(cashTotal)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="auto"
                                            overlay={
                                                (
                                                    <Popover id="supply-popover">
                                                        <div className="p-5">
                                                            <ul className="list-group">
                                                                {/* {lastCashRegister && (
                                                                    <li style={{color: 'green'}} className="list-group-item">
                                                                        + Valor caixa anterior: {formatIntegerToMoney(lastCashRegister.currentValue, true)}
                                                                    </li>
                                                                )} */}
                                                                <li style={{color: 'green'}} className="list-group-item">
                                                                    + Valor Inicial: {formatIntegerToMoney(cashRegister.startValue, true)}
                                                                </li>
                                                                <li style={{color: 'green'}} className="list-group-item">
                                                                    + Faturamento em dinheiro: {formatIntegerToMoney(moneyEarned, true)}
                                                                </li>
                                                                <li style={{color: 'green'}} className="list-group-item">
                                                                    + Suprimentos: {formatIntegerToMoney(totalSupplies, true)}
                                                                </li>
                                                                <li style={{color: 'red'}} className="list-group-item">
                                                                    - Retiradas: {formatIntegerToMoney(totalPickups, true)}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Popover>
                                                )
                                            }
                                        >
                                            <i className="flaticon-info pickup-info-button mx-0"></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h6 className="card-title mb-0">DEMONSTRATIVO</h6>
                            <div className="form-group mb-0">
                                {/* pix_earned */}
                                <NumericFormat
                                    className="inputSmaller" 
                                    label="PIX"
                                    disabled
                                    value={formatIntegerToMoney(pixEarned)}
                                />
                            </div>
                            <div className="form-group mb-0">
                                {/* card_earned */}
                                <NumericFormat
                                    className="inputSmaller" 
                                    label="Cartão de crédito/débito"
                                    disabled
                                    value={formatIntegerToMoney(cardEarned)}
                                />
                            </div>
                            <div className="form-group mb-0">
                                {/* cash_earned */}
                                <NumericFormat
                                    className="inputSmaller" 
                                    label="Dinheiro"
                                    disabled
                                    value={formatIntegerToMoney(moneyEarned)}
                                />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h6 className="card-title mb-0">FECHAMENTO</h6>
                            <div className="form-group mb-0">
                                {/* pickup_value */}
                                <NumericFormat
                                    className="inputSmaller" 
                                    label="Dinheiro"
                                    value={formatIntegerToMoney(movementValue)}
                                    onChange={(e) => {
                                        handleMoneyInputChange(e, setMovementValue);

                                        if (type === ModalCashRegisterType.MOVEMENT && operation == "supply" && formatToFloat(e.target.value) > availableMoney) {
                                            alert(`A conta selecionada tem apenas ${formatCurrency(availableMoney)} disponível!`);
                                            setMovementValue(0);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                           <InputAdornment position='end'>
                                            {
                                                type === ModalCashRegisterType.MOVEMENT && operation == "supply"
                                                ?
                                                    loadingCalc
                                                    ?
                                                        <Spinner animation="border" variant="dark" size="sm" />
                                                    : 
                                                        accountBank ? `Disponível: ${formatCurrency(availableMoney)}` : ""
                                                : <></>
                                            }
                                              
                                           </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    type === ModalCashRegisterType.CLOSE
                                    ?
                                        <ApiResourceSelect
                                            label="Conta bancária"
                                            disabled
                                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                            value={accountBank}
                                            onSelect={(option) => setAccountBank(String(option?.id ?? ''))}
                                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                            getSelectedOption={(loadedOptions) => {
                                                if(!accountBank) return null;
                                                return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                                            }}
                                        />
                                    : <></>
                                }
                            </div>
                            {type === ModalCashRegisterType.MOVEMENT ? (
                                <div className="form-group mb-0" id="operation-wrapper">
                                    {/* operation */}
                                    <TextField
                                        select
                                        label="Operação"
                                        margin="normal"
                                        variant="outlined"
                                        value={operation}
                                        onChange={(e) => {
                                            setOperation(e.target.value);

                                            if (type === ModalCashRegisterType.MOVEMENT && e.target.value == "supply") {
                                                calcAvailableMoney();
                                            }
                                        }}
                                    >
                                        <MenuItem key="0" value="pickup">
                                            Sangria
                                        </MenuItem>
                                        <MenuItem key="1" value="supply">
                                            Suprimento
                                        </MenuItem>
                                    </TextField>
                                </div>
                            ) : <></>}
                        </div>
                    </div>
                    <hr/>
                    <div className="row mt-2 pl-2">
                        <div className="form-group mb-0 col-md-8">
                            {/* notes */}
                            <TextField
                                label="Ocorrência"
                                multiline
                                rows="4"
                                margin="normal"
                                variant="outlined"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />

                            <div className="text-danger">
                                {msgError}
                            </div>
                        </div>
                    </div>

                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalCashRegister(!showModalCashRegister)}>Fechar</Button>
                    <Button
                        variant="primary"
                        disabled={isSubmit}
                        onClick={handleSubmit}>
                            {
                                isSubmit ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                        <span className='ml-2'>
                                            Aguarde...
                                        </span>

                                    </>
                                ) : (
                                    <span>{type === ModalCashRegisterType.CLOSE ? 'Fechar Caixa' : 'Finalizar'}</span>
                                )
                            }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}