import React, {useState, useEffect, useCallback} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useParams } from 'react-router-dom';

import {
    Button,
    Spinner,
    Modal,
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { Autocomplete } from '@material-ui/lab';
import { formatToFloat, formatCurrency, formatNumberToString } from '../../utils/formatCurrency';
import api from '../../services/Api';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { NumericFormat } from '../../components/NumericFormat';
import { IconButton, InputAdornment } from '@material-ui/core';
import { paymentOptions } from '../../utils/paymentOptions';

import { CompanyNfseActivity } from '../../types/CompanyNfseActivity';
import LogService from '../../services/LogService';
import NfseService from '../../services/NfseService';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import CustomerService from '../../services/CustomerService';
import { getActivityById } from '../../utils/getActivityById';
import { Installment } from '../../types/Installment';
import { useSelector } from 'react-redux';
import CustomerSelect from '../../components/CustomerSelect';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';


type urlParam = {
    id: string
}

export function EditNfse() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [isSubmit, setIsSubmit] = useState(false);
    // HOOKS
    const history          = useHistory();
    const { handleSubmit } = useForm();
    const { id } = useParams<urlParam>();

    const [activeTab, setActiveTab] = useState("details");

    // STATES
    const [customer, setCustomer] = useState<number>();  
    const [technical, setTechnical] = useState("");
    const [seriesRps, setSeriesRps] = useState("");
    const [numberRps, setNumberRps] = useState("");
    const [customerDontIdentified, setCustomerDontIdentified] = useState("");
    const [culturalIncentive, setCulturalIncentive] = useState("n");
    const [nature, setNature] = useState(1);
    const [taxRegime, setTaxRegime] = useState(1);
    const [specialTaxRegime, setSpecialTaxRegime] = useState<number|null>(null);
    const [placeDelivery, setPlaceDelivery] = useState("");
    const [construction, setConstruction] = useState("");
    const [numberJudicialProcess, setNumberJudicialProcess] = useState("");
    const [codeArt, setCodeArt] = useState("");
    const [codeConstruction, setCodeConstruction] = useState("");
    const [valueMaterial, setValueMaterial] = useState("0");
    const [cityDelivery, setCityDelivery] = useState("");
    const [internalObservation, setInternalObservation] = useState("");
    const [description, setDescription] = useState("");
    const [valueService, setValueService] = useState("");
    const [baseCalc, setBaseCalc] = useState("");
    const [aliquot, setAliquot] = useState("");
    const [valueTax, setValueTax] = useState("");
    const [holdIss, setHoldIss] = useState("");
    const [valueDeductions, setValueDeductions] = useState("");
    const [valueUnconditioned, setValueUnconditioned] = useState("");
    const [valueConditioned, setValueConditioned] = useState("");
    const [federalTaxes, setFederalTaxes] = useState("");
    const [municipalTaxes, setMunicipalTaxes] = useState("");
    const [cofins, setCofins] = useState("");
    const [pis, setPis] = useState("");
    const [csll, setCsll] = useState("");
    const [ir, setIr] = useState("");
    const [inss, setInss] = useState("");
    const [otherTaxes, setOtherTaxes] = useState("00,00");
    const [valueCofins, setValueCofins] = useState("");
    const [valuePis, setValuePis] = useState("");
    const [valueCsll, setValueCsll] = useState("");
    const [valueIr, setValueIr] = useState("");
    const [valueInss, setValueInss] = useState("");
    const [valueOtherTaxes, setValueOtherTaxes] = useState("00,00");
    const [holdCofins, setHoldCofins] = useState("y");
    const [holdPis, setHoldPis] = useState("y");
    const [holdCsll, setHoldCsll] = useState("y");
    const [holdIr, setHoldIr] = useState("y");
    const [holdInss, setHoldInss] = useState("y");
    const [holdOtherTaxes, setHoldOtherTaxes] = useState("y");
    const [valueLiquidNote, setValueLiquidNote] = useState("");
    const [amountInstallments, setAmountInstallments] = useState("");
    const [activity, setActivity] = useState<number|undefined>();
    const [activities, setActivities] = useState<CompanyNfseActivity[]>([]);

    const [installments, setInstallments] = useState<{installment: Installment[]}>({
        installment: []
    });

    // MODAL
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomer(createdCustomer.id);
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    useEffect(() => {
        getInvoice();
        getActivities();
    }, []);

    useEffect(() => {
        setInstallments({ installment: []});
    }, [valueLiquidNote]);

    useEffect(() => {
        setBaseCalc(valueService);
    }, [valueService]);
    
    useEffect(() => {
        var auxValueService     = valueService;
        var auxValueCofins      = holdCofins == "y" ? formatToFloat(valueCofins) : 0;
        var auxValuePis         = holdPis == "y" ? formatToFloat(valuePis) : 0;
        var auxValueCsll        = holdCsll == "y" ? formatToFloat(valueCsll) : 0;
        var auxValueIr          = holdIr == "y" ? formatToFloat(valueIr) : 0;
        var auxValueInss        = holdInss == "y" ? formatToFloat(valueInss) : 0;
        var auxValueOtherTaxes  = holdOtherTaxes == "y" ? formatToFloat(valueOtherTaxes) : 0;
        
        setBaseCalc(
            formatCurrency(formatToFloat(auxValueService) - (formatToFloat(valueDeductions) + formatToFloat(valueUnconditioned)))
        );

        setValueCofins(formatCurrency(formatToFloat(valueService) * (formatToFloat(cofins) / 100)));
        setValuePis(formatCurrency(formatToFloat(valueService) * (formatToFloat(pis) / 100)));
        setValueCsll(formatCurrency(formatToFloat(valueService) * (formatToFloat(csll) / 100)));
        setValueIr(formatCurrency(formatToFloat(valueService) * (formatToFloat(ir) / 100)));
        setValueInss(formatCurrency(formatToFloat(valueService) * (formatToFloat(inss) / 100)));
        setValueOtherTaxes(formatCurrency(formatToFloat(valueService) * (formatToFloat(otherTaxes) / 100)));

        var auxAliquot          = formatToFloat(baseCalc) * (formatToFloat(aliquot) / 100);
        var auxValueTax         = holdIss == "y" ? auxAliquot : 0;
        setValueTax(formatCurrency(auxAliquot));

        setValueLiquidNote(formatCurrency(formatToFloat(auxValueService) - (formatToFloat(valueDeductions) + formatToFloat(valueUnconditioned) + formatToFloat(valueConditioned) + auxValueTax + auxValueCofins + auxValuePis + auxValueCsll + auxValueIr + auxValueInss + auxValueOtherTaxes)));
    }, [baseCalc, 
        aliquot, 
        valueTax, 
        holdIss, 
        valueDeductions, 
        valueUnconditioned, 
        valueConditioned, 
        cofins,
        pis,
        csll,
        ir,
        inss,
        otherTaxes,
        holdCofins, 
        holdPis, 
        holdCsll, 
        holdIr, 
        holdInss]);

    async function getInvoice()
    {
        const {data} = await api.get(`/nfse/${id}`);

        setCustomer(Number(data.customer));
        setTechnical(data.technical);
        setSeriesRps(data.seriesRps);
        setNumberRps(data.numberRps);
        setCustomerDontIdentified(data.customerDontIdentified);
        setCulturalIncentive(data.culturalIncentive);
        setNature(data.nature);
        setTaxRegime(data.taxRegime);
        setSpecialTaxRegime(data.specialTaxRegime);
        setPlaceDelivery(data.placeDelivery);
        setConstruction(data.construction);
        setNumberJudicialProcess(data.numberJudicialProcess);
        setCodeArt(data.codeArt);
        setCodeConstruction(data.codeConstruction);
        setValueMaterial(formatCurrency(data.valueMaterial));
        setCityDelivery(data.cityDelivery);
        setInternalObservation(data.internalObservation);
        setDescription(data.description);
        setValueService(formatCurrency(data.valueService));
        setBaseCalc(formatCurrency(data.baseCalc));
        setAliquot(formatCurrency(data.aliquot));
        setValueTax(formatCurrency(data.valueTax));
        setHoldIss(data.holdIss);
        setValueDeductions(formatCurrency(data.valueDeductions));
        setValueUnconditioned(formatCurrency(data.valueUnconditioned));
        setValueConditioned(formatCurrency(data.valueConditioned));
        setFederalTaxes(formatCurrency(data.federalTaxes));
        setMunicipalTaxes(formatCurrency(data.municipalTaxes));
        setCofins(formatCurrency(data.cofins));
        setPis(formatCurrency(data.pis));
        setCsll(formatCurrency(data.csll));
        setIr(formatCurrency(data.ir));
        setInss(formatCurrency(data.inss));
        setOtherTaxes(formatCurrency(data.otherTaxes));
        setValueCofins(formatCurrency(data.valueCofins));
        setValuePis(formatCurrency(data.valuePis));
        setValueCsll(formatCurrency(data.valueCsll));
        setValueIr(formatCurrency(data.valueIr));
        setValueInss(formatCurrency(data.valueInss));
        setValueOtherTaxes(formatCurrency(data.valueOtherTaxes));
        setHoldCofins(data.holdCofins);
        setHoldPis(data.holdPis);
        setHoldCsll(data.holdCsll);
        setHoldIr(data.holdIr);
        setHoldInss(data.holdInss);
        setValueLiquidNote(formatCurrency(data.valueLiquidNote));
        setAmountInstallments(data.amountInstallments);
        setInstallments({ installment: JSON.parse(data.installments)});
        setActivity(Number(data.activity) || undefined);
    }

    async function getActivities() {
        const response = await api.get<CompanyNfseActivity[]>('companyNfseActivities');
        setActivities(response.data);
    }

    function handleChangeActivity(event: React.ChangeEvent<{}>, selected: CompanyNfseActivity | null) {
        setActivity(selected?.id ?? undefined);
        if(selected) {
            setMunicipalTaxes(formatCurrency(selected.municipalTax));
            setFederalTaxes(formatCurrency(selected.federalTax));
        }
    }

    function distributeValueInstallments(index: number)
    {
        var installmentsArray = installments.installment;
        
        var sumBeforeValue = 0;
        for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
            if (indexInstallment <= index) {
                sumBeforeValue += installmentsArray[indexInstallment].value;
            }
        }

        
        if (sumBeforeValue > formatToFloat(valueLiquidNote)) {
            setMsgError("A soma das parcelas ultrapassa o valor total da nota fiscal!");
            setShowModalAttention(true);

            var valueDistributed  = formatToFloat(valueLiquidNote) / (installmentsArray.length - (index - 1));
            for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
                if (indexInstallment > 0) {
                    installmentsArray[indexInstallment].value = valueDistributed;
                }
            }

        } else {
            var valueDistributed  = (formatToFloat(valueLiquidNote) - sumBeforeValue) / (installmentsArray.length - (index + 1));
            for (var indexInstallment = 0; indexInstallment < installmentsArray.length; indexInstallment++) {
                if (indexInstallment > index) {
                    installmentsArray[indexInstallment].value = valueDistributed;
                }
            }
        }
        
        setInstallments({ installment: installmentsArray });
    }

    function setValuesInstallments(value: any, index: number, type: string)
    {
        var installmentArray = installments.installment;

        if (type == "date") {
            installmentArray[index].date = value;

        } else if (type == "value") {
            installmentArray[index].value = formatToFloat(value);

        } else if (type == "payment") {
            installmentArray[index].payment = value;

        } else {
            installmentArray[index].comments = value;
        }

        setInstallments({ installment: installmentArray});
    }

    function generateInstallments() 
    {
        if (formatToFloat(valueLiquidNote)) {
            var installmentsArray = [];
            var today             = new Date();
            var day               = today.getDate() < 10 ? "0"+ today.getDate() : today.getDate();
            var month             = String(today.getMonth() + 1);
            var year              = today.getFullYear();

            var valueInstallment  = formatToFloat(valueLiquidNote) / formatToFloat(amountInstallments);
            
            for (var index = 0; index < formatToFloat(amountInstallments); index++) {
                month = today.getMonth() < 10 ? "0"+ (today.getMonth() + (index + 1)) : String(today.getMonth() + (index + 1));
                
                installmentsArray.push({
                    date: year +"-"+ month +"-"+ day,
                    value:  valueInstallment,
                    comments: "",
                    payment: "Cartão de Crédito"
                });
            }
            setInstallments({ installment: installmentsArray});
            
        } else {
            setMsgError("Informe o valor do serviço!");
            setShowModalAttention(true);
        }
    }
    
    function hasInstallments() 
    {
        var isSubmit = true;
        
        if (!installments.installment.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas da nota fiscal de serviço!");
            setShowModalAttention(true);
            isSubmit = false;
        }

        return isSubmit;
    }

    const onSubmit = async (data: any) => {
        setIsSubmit(true);
        
        if(!verifyInputs()) {
            setShowModalAttention(true);
            setIsSubmit(false);
            return;
        }

        if(!hasInstallments()) {
            setIsSubmit(false);
            return;
        }

        const lastInputs = await api.get(`/nfse/${id}`);

        var installmentsArray = installments.installment; 

        try {
            const raw = {
                customer: Number(customer) || null,
                technical: technical,
                seriesRps: seriesRps,
                numberRps: numberRps,
                customerDontIdentified: customerDontIdentified,
                nature: nature,
                taxRegime: taxRegime,
                culturalIncentive: culturalIncentive,
                specialTaxRegime: specialTaxRegime,
                placeDelivery: placeDelivery,
                construction: construction,
                numberJudicialProcess: numberJudicialProcess,
                codeArt: codeArt,
                codeConstruction: codeConstruction,
                valueMaterial: formatToFloat(valueMaterial),
                cityDelivery: cityDelivery,
                internalObservation: internalObservation,
                description: description,
                valueService: formatToFloat(valueService),
                baseCalc: formatToFloat(baseCalc),
                aliquot: formatToFloat(aliquot),
                valueTax: formatToFloat(valueTax),
                holdIss: holdIss,
                valueDeductions: formatToFloat(valueDeductions),
                valueUnconditioned: formatToFloat(valueUnconditioned),
                valueConditioned: formatToFloat(valueConditioned),
                federalTaxes: formatToFloat(federalTaxes),
                municipalTaxes: formatToFloat(municipalTaxes),
                activity: activity,
                cofins: formatToFloat(cofins),
                pis: formatToFloat(pis),
                csll: formatToFloat(csll),
                ir: formatToFloat(ir),
                inss: formatToFloat(inss),
                otherTaxes: formatToFloat(otherTaxes),
                valueCofins: formatToFloat(valueCofins),
                valuePis: formatToFloat(valuePis),
                valueCsll: formatToFloat(valueCsll),
                valueIr: formatToFloat(valueIr),
                valueInss: formatToFloat(valueInss),
                valueOtherTaxes: formatToFloat(valueOtherTaxes),
                holdCofins: holdCofins,
                holdPis: holdPis,
                holdCsll: holdCsll,
                holdIr: holdIr,
                holdInss: holdInss,
                valueLiquidNote: formatToFloat(valueLiquidNote),
                amountInstallments: amountInstallments,
                installments: JSON.stringify(installmentsArray),
                situation: "open",
            };

            const result = await api.post(`/nfse/${id}`, raw);

            LogService.logEdit({
                itemId: result.data.id,
                itemName: 'Nfse de Serviço',
                module: 'Notas Fiscais',
                oldData: lastInputs.data,
                newData: result.data,
                formattedFields: {
                    customer: (value) => CustomerService.getNameByIdAsync(value),
                    culturalIncentive: (value) => getYesOrNoText(value),
                    nature: (value) => NfseService.getNature(value),
                    taxRegime: (value) => NfseService.getTaxRegime(value),
                    specialTaxRegime: (value) => NfseService.getSpecialTaxRegime(value),
                    construction: (value) => getYesOrNoText(value),
                    activity: (value) => getActivityById(value),
                    valueService: (value) => formatNumberToString(value),
                    aliquot: (value) => formatNumberToString(value),
                    valueDeductions: (value) => formatNumberToString(value),
                    valueUnconditioned: (value) => formatNumberToString(value),
                    valueConditioned: (value) => formatNumberToString(value),
                    federalTaxes: (value) => formatNumberToString(value),
                    municipalTaxes: (value) => formatNumberToString(value),
                    cofins: (value) => formatNumberToString(value),
                    pis: (value) => formatNumberToString(value),
                    csll: (value) => formatNumberToString(value),
                    ir: (value) => formatNumberToString(value),
                    inss: (value) => formatNumberToString(value),
                    otherTaxes: (value) => formatNumberToString(value)
                },
                fieldsMap: {
                    customer: 'Cliente',
                    technical: 'Técnico',
                    culturalIncentive: 'Incentivador cultural',
                    nature: 'Natureza da operação',
                    numberJudicialProcess: 'Número do processo judicial',
                    taxRegime: 'Regime de tributação',
                    specialTaxRegime: 'Regime especial de tributação',
                    placeDelivery: 'Local de prestação',
                    cityDelivery: 'Código município da prestação',
                    construction: 'Construção civil',
                    codeArt: 'Código A.R.T',
                    codeConstruction: 'Código obra',
                    valueMaterial: 'Valor material',
                    internalObservation: 'Observações internas',
                    description: 'Descrição',
                    valueService: 'Valor do serviço',
                    aliquot: 'Aliquota',
                    valueDeductions: 'Valor deduções',
                    valueUnconditioned: 'Valor incondicionado',
                    valueConditioned: 'Valor condicionado',
                    federalTaxes: 'Tributos federais',
                    municipalTaxes: 'Tributos municipais',
                    activity: 'Atividade',
                    cofins: '% COFINS',
                    pis: '% PIS',
                    csll: '% CSLL',
                    ir: '% IR',
                    inss: '% INSS',
                    otherTaxes: '% Outras retenções',
                    amountInstallments: 'Quantidade de parcelas',
                },
            });     

            history.push("/notas-fiscais-servico");

        } catch (error) {
            console.log(error);
        }
            setIsSubmit(false);
    };

    function verifyInputs() {
        if(!customer) {
            setMsgError("Informe o cliente!");
            setShowModalAttention(true);
            return false;
        }
        
        if(!valueService) {
            setActiveTab('services');
            setMsgError("Informe o valor do serviço!");
            setShowModalAttention(true);
            return false;
        }

        if(!description.trim()) {
            setActiveTab('services');
            setMsgError('Informe a descrição dos serviços prestados!');
            return false;
        }

        if(!activity) {
            setActiveTab('services');
            setMsgError('Informe a atividade!');
            return false;
        }

        return true;
    }
    
    return (

        <div className="card card-custom gutter-b mt-2 newProductWrapper">
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header row m-0 pt-2 pb-0">
                    {/* INVOICES DETAIL*/}
                    <div className="col-6 m-0 p-0">
                        <h4 className="card-label m-0 mt-2">
                            Detalhes da Nota Fiscal de Serviço
                        </h4>
                    </div>

                    {
                        user.isAccountant == "n"
                        ?
                        <div className='col-lg-6 d-flex justify-content-end mb-3 pr-0'>
                            <Button
                                type='submit'
                                variant="primary"
                                disabled={isSubmit}
                            >

                                {
                                    isSubmit ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>

                                        </>
                                    :
                                        <span>Salvar</span>
                                }
                            </Button>

                            {/* <Button
                                size="lg"
                                variant="secondary"
                            >
                                Salvar e Emitir
                            </Button> */}
                        </div>
                        : <></>
                    }
                </div>
            

                <div className="card-body mt-0 pt-0">
                    <div className="row">
                        <div className="col-lg-5">
                            <CustomerSelect
                                label="Cliente *"
                                isEdit
                                customerId={customer}
                                setCustomerId={setCustomer}
                                disabled={customerDontIdentified == "y" || user.isAccountant == "y" ? true : false}
                                onClickAddCustomer={handleClickAddCustomer}
                            />
                        </div>

                        <div className="col-lg-3">
                            <TextField
                                size="small"
                                label="Técnico"
                                margin="normal"
                                variant="outlined"
                                value={technical}
                                onChange={(e) => setTechnical(e.target.value)}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                disabled
                                label="Série RPS"
                                margin="normal"
                                variant="outlined"
                                value={seriesRps}
                                onChange={(e) => setSeriesRps(e.target.value)}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                disabled
                                label="Nº RPS"
                                margin="normal"
                                variant="outlined"
                                value={numberRps}
                                onChange={(e) => setNumberRps(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row col-lg-4">
                        <FormControlLabel
                            value="end"
                            control={
                                <Checkbox
                                    disabled={user.isAccountant == "y" ? true : false}
                                    color="primary"
                                    checked={culturalIncentive === "y" ? true : false}
                                    onChange={() => setCulturalIncentive(culturalIncentive === "y" ? "n" : "y")}
                                />
                            }
                            label="Incentivador Cultural"
                            labelPlacement="end"
                        />
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs 
                                activeKey={activeTab} 
                                onSelect={(tab: string) => setActiveTab(tab)}
                                id='invoice-form-tabs'
                            >

                                <Tab
                                    eventKey='details'
                                    title='Detalhes'
                                >
                                    <div className="row">
                                        <div className="col-lg-3">

                                            <TextField
                                                select
                                                label="Natureza da operação"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                value={nature}
                                                onChange={(e) => {
                                                    setNature(Number(e.target.value));
                                                    setNumberJudicialProcess(e.target.value === '5' ? numberJudicialProcess : "");
                                                }}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="">
                                                     Selecione
                                                </MenuItem>

                                                <MenuItem key="1" value="1">
                                                    Tributação no município
                                                </MenuItem>

                                                <MenuItem key="2" value="2">
                                                    Tributação fora do município
                                                </MenuItem>

                                                <MenuItem key="3" value="3">
                                                    isenção
                                                </MenuItem>

                                                <MenuItem key="4" value="4">
                                                    Imune
                                                </MenuItem>

                                                <MenuItem key="5" value="5">
                                                    Processo Judicial
                                                </MenuItem>
                                            </TextField>

                                        </div>

                                        <div className="col-lg-3">

                                            <TextField
                                                select
                                                label="Regime de tributação"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                value={taxRegime}
                                                onChange={(e) => setTaxRegime(Number(e.target.value))}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="1">
                                                    Optante pelo simples nacional
                                                </MenuItem>

                                                <MenuItem key="1" value="2">
                                                    Contribuinte sujeito a tributação normal
                                                </MenuItem>
                                            </TextField>

                                        </div>

                                        <div className="col-lg-3">

                                            <TextField
                                                select
                                                label="Regime especial de tributação"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                defaultValue={'0'}
                                                value={specialTaxRegime ?? '0'}
                                                onChange={(e) => setSpecialTaxRegime(Number(e.target.value) || null)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="0">
                                                    Nenhum
                                                </MenuItem>
                                                <MenuItem key="1" value="1">
                                                    Micro empresa municipal
                                                </MenuItem>
                                                <MenuItem key="2" value="2">
                                                    Estimativa
                                                </MenuItem>
                                                <MenuItem key="3" value="3">
                                                    Sociedade de profissionais
                                                </MenuItem>
                                                <MenuItem key="4" value="4">
                                                    Cooperativa
                                                </MenuItem>
                                                <MenuItem key="5" value="5">
                                                    Micro empresario individual (MEI)
                                                </MenuItem>
                                                <MenuItem key="6" value="6">
                                                    Microempresa e empresario de pequeno porte
                                                </MenuItem>
                                            </TextField>

                                        </div>

                                        <div className="col-lg-3">

                                            <TextField
                                                select
                                                label="Local da prestação"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                value={placeDelivery}
                                                onChange={(e) => setPlaceDelivery(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="endereço do emitente">
                                                    Endereço do emitente
                                                </MenuItem>

                                                <MenuItem key="1" value="endereço do destinatario">
                                                    Endereço do destinatário
                                                </MenuItem>

                                                <MenuItem key="2" value="selecionar outro endereco">
                                                    Selecionar outro endereço
                                                </MenuItem>
                                            </TextField>

                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            nature === 5
                                            ?
                                            <div className="col-lg-3">
                                                <TextField
                                                    size="small"
                                                    label="Número do processo judicial"
                                                    inputProps={{ maxLength: 55 }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={numberJudicialProcess}
                                                    onChange={(e) => setNumberJudicialProcess(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>
                                            : <></>
                                        }

                                        {
                                            placeDelivery == "selecionar outro endereco"
                                            ?
                                                <div className="col-lg-4">
                                                    <TextField
                                                        size="small"
                                                        label="Código município da prestação"
                                                        inputProps={{ maxLength: 255 }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={cityDelivery}
                                                        onChange={(e) => setCityDelivery(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id='tooltip-top'>
                                                                                Consulte o código IBGE do município: <a target="_blank" href="https://www.ibge.gov.br/explica/codigos-dos-municipios.php"> Clique Aqui </a>
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            <i className="flaticon-info"></i>
                                                                        </IconButton>
                                                                    </OverlayTrigger>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            : <></>
                                    }
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 d-flex align-items-center">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={construction == "y" ? true : false} onChange={() => {
                                                    setConstruction(construction == "y" ? "n" : "y");
                                                    setCodeArt(construction == "y" ? "" : codeArt);
                                                    setCodeConstruction(construction == "y" ? codeConstruction : "");
                                                    setValueMaterial(construction == "y" ? valueMaterial : "");
                                                }} />}
                                                label="Construção civil"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        {
                                            construction == "y"
                                            ?
                                            <>
                                                <div className="col-lg-3">
                                                    <TextField
                                                        size="small"
                                                        label="Código A.R.T"
                                                        inputProps={{ maxLength: 15 }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={codeArt}
                                                        onChange={(e) => setCodeArt(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </div>

                                                <div className="col-lg-3">
                                                    <TextField
                                                        size="small"
                                                        label="Código obra"
                                                        inputProps={{ maxLength: 15 }}
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={codeConstruction}
                                                        onChange={(e) => setCodeConstruction(e.target.value)}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </div>

                                                <div className="col-lg-3">
                                                    <NumericFormat
                                                        className="inputSmaller"
                                                        label="Valor do material"
                                                        value={valueMaterial}
                                                        onChange={(e) => setValueMaterial(e.target.value)}
                                                        withPrefix={false}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    R$
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled={user.isAccountant == "y" ? true : false}
                                                    />
                                                </div>
                                            </>
                                            : <></>
                                        }
                                    </div>
                                    
                                    <div className="row col-lg-12">
                                        <TextField
                                            label="Observações internas"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            inputProps={{  maxLength: 5000 }}
                                            value={internalObservation}
                                            onChange={(e) => setInternalObservation(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='services'
                                    title='Serviços'
                                >
                                    <div className="row col-lg-12">
                                        <TextField
                                            label="Descrição *"
                                            multiline
                                            rows="10"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            inputProps={{  maxLength: 5000 }}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Valor do serviço"
                                                value={valueService}
                                                onChange={(e) => setValueService(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Base de cálculo"
                                                value={baseCalc}
                                                onChange={(e) => setBaseCalc(e.target.value)}
                                                withPrefix={false}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="% Alíquota"
                                                value={aliquot}
                                                onChange={(e) => setAliquot(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Valor imposto"
                                                value={valueTax}
                                                onChange={(e) => setValueTax(e.target.value)}
                                                withPrefix={false}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-3 d-flex align-items-center">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={holdIss == "y" ? true : false} onChange={() => setHoldIss(holdIss == "y" ? "n" : "y")} />}
                                                label="Reter ISS"
                                                labelPlacement="end"
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Valor deduções"
                                                value={valueDeductions}
                                                onChange={(e) => setValueDeductions(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Valor incondicionado"
                                                value={valueUnconditioned}
                                                onChange={(e) => setValueUnconditioned(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Valor condicionado"
                                                value={valueConditioned}
                                                onChange={(e) => setValueConditioned(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Tributos federais"
                                                value={federalTaxes}
                                                onChange={(e) => setFederalTaxes(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-3">
                                            <NumericFormat
                                                className="inputSmaller"
                                                label="Tributos municipais"
                                                value={municipalTaxes}
                                                onChange={(e) => setMunicipalTaxes(e.target.value)}
                                                withPrefix={false}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-8">
                                            <Autocomplete
                                                size="small"
                                                noOptionsText="Sem opções"
                                                options={activities}
                                                value={activities.find(obj => obj.id === activity) ?? null}
                                                getOptionLabel={({ serviceCode, tributeCode, description }) => `${serviceCode} - ${tributeCode} - ${description}`}
                                                onChange={(event, newInputValue) => handleChangeActivity(event, newInputValue)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        size="small"
                                                        { ...params }
                                                        label="Atividade *"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                }
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='total-invoice'
                                    title='Totais da Nota Fiscal'
                                >
                                    <div className="row">

                                    <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% COFINS" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={cofins}
                                                onChange={(e) => setCofins(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% PIS" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={pis}
                                                onChange={(e) => setPis(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% CSLL" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={csll}
                                                onChange={(e) => setCsll(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% IR" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={ir}
                                                onChange={(e) => setIr(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% INSS" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={inss}
                                                onChange={(e) => setInss(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="% Outras Retenções" 
                                                withPrefix={false}
                                                format="##,##"
                                                value={otherTaxes}
                                                onChange={(e) => setOtherTaxes(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Valor COFINS" 
                                                withPrefix={false}
                                                value={valueCofins}
                                                onChange={(e) => setValueCofins(e.target.value)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Valor PIS" 
                                                withPrefix={false}
                                                value={valuePis}
                                                onChange={(e) => setValuePis(e.target.value)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Valor CSLL" 
                                                withPrefix={false}
                                                value={valueCsll}
                                                onChange={(e) => setValueCsll(e.target.value)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Valor IR" 
                                                withPrefix={false}
                                                value={valueIr}
                                                onChange={(e) => setValueIr(e.target.value)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="Valor INSS" 
                                                withPrefix={false}
                                                value={valueInss}
                                                onChange={(e) => setValueInss(e.target.value)}
                                                disabled
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <NumericFormat 
                                                className="inputSmaller"
                                                label="R$ Outras Retenções" 
                                                withPrefix={false}
                                                value={valueOtherTaxes}
                                                onChange={(e) => setValueOtherTaxes(e.target.value)}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="row">

                                        <div className="col-lg-2">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox color="primary" checked={holdCofins == "y" ? true : false} onChange={() => setHoldCofins(holdCofins == "y" ? "n" : "y")} />}
                                                label="Reter COFINS"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox color="primary" checked={holdPis == "y" ? true : false} onChange={() => setHoldPis(holdPis == "y" ? "n" : "y")} />}
                                                label="Reter PIS"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox color="primary" checked={holdCsll == "y" ? true : false} onChange={() => setHoldCsll(holdCsll == "y" ? "n" : "y")} />}
                                                label="Reter CSLL"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox color="primary" checked={holdIr == "y" ? true : false} onChange={() => setHoldIr(holdIr == "y" ? "n" : "y")} />}
                                                label="Reter IR"
                                                labelPlacement="end"
                                            />
                                        </div>

                                        <div className="col-lg-2">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox color="primary" checked={holdInss == "y" ? true : false} onChange={() => setHoldInss(holdInss == "y" ? "n" : "y")} />}
                                                label="Reter INSS"
                                                labelPlacement="end"
                                            />
                                        </div>
                                    </div> */}

                                    <div className="row col-lg-4">
                                        <NumericFormat 
                                            className="inputSmaller"
                                            label="Valor líquido da nota" 
                                            withPrefix={false}
                                            value={valueLiquidNote}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        R$
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => setValueLiquidNote(e.target.value)}
                                            disabled
                                        />
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey='payment'
                                    title='Pagamento'
                                >
                                    <div className="row">

                                        {/* <div className="col-lg-3 d-flex align-items-center">

                                            <TextField
                                                select
                                                label="Condição de Pagamento"
                                                margin="normal"
                                                variant="outlined"
                                                name="conditionPayment"
                                                size="small"
                                            >
                                                <MenuItem key="0" value="nf-e normal">
                                                    Selecione
                                                </MenuItem>

                                                <MenuItem key="1" value="30/60/90">
                                                    30/60/90
                                                </MenuItem>
                                            </TextField>

                                        </div>

                                        <div className="p-3 d-flex align-items-center justify-content-center">
                                            <label>ou</label>
                                        </div> */}

                                        <div className="col-lg-4">

                                            <TextField
                                                type="number"
                                                label="Quantidade de Parcelas"
                                                margin="normal"
                                                variant="outlined"
                                                name="amountParcel"
                                                size="small"
                                                value={amountInstallments}
                                                onChange={(e) => setAmountInstallments(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                            </TextField>

                                        </div>

                                        {
                                            user.isAccountant == "n"
                                            ?
                                            <div className="col-md-3 d-flex align-items-center justify-content-center">

                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => generateInstallments()}
                                                    block
                                                >
                                                    Gerar parcelas
                                                </Button>

                                            </div>
                                            : <></>
                                        }

                                        <div className="col-lg-12 mt-10">
                                        {
                                            installments.installment.map((installment, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div className="col-lg-3 d-flex align-items-center p-1">
                                                            <div className="d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2 mr-2">
                                                                <label className="text-white m-0">
                                                                    {index + 1}.
                                                                </label>
                                                            </div>

                                                            <TextField
                                                                type="date"
                                                                label="Data"
                                                                margin="normal"
                                                                variant="outlined"
                                                                size="small"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                value={installment.date}
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "date")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col p-1">
                                                            {index + 1 !== installments.installment.length ? (
                                                                <NumericFormat
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    className="inputSmaller"
                                                                    withPrefix={false}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                R$
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        var installmentArray            = installments.installment;
                                                                        installmentArray[index].value   = formatToFloat(e.target.value);
                                                                        setInstallments({ installment: installmentArray });                                                                            
                                                                        distributeValueInstallments(index);
                                                                    }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            ) : (
                                                                <NumericFormat
                                                                    disabled
                                                                    key={index}
                                                                    label="Valor"
                                                                    value={installment.value}
                                                                    className="inputSmaller"
                                                                    withPrefix={false}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position='start'>
                                                                                R$
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onBlur={(e) => {setValuesInstallments(e.target.value, index, "value"); distributeValueInstallments(index)}}
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="col p-1">
                                                            <TextField
                                                                select
                                                                label="Forma de pgto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={installment.payment}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "payment")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key={-1} value="">
                                                                    Selecione
                                                                </MenuItem>
                                                                {
                                                                    paymentOptions.map((payment, i) => {
                                                                        return (
                                                                            <MenuItem key={i} value={payment.value}>
                                                                                {payment.value}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }
                                                            </TextField>

                                                        </div>

                                                        <div className="col p-1">
                                                            <TextField
                                                                label="Observações"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={installment.comments}
                                                                size="small"
                                                                onChange={(e) => setValuesInstallments(e.target.value, index, "comments")}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </div>
                                                    </div>           
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
}