import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {
    makeStyles,
    MenuItem,
    TextField,
    FormControlLabel,
    Link,
} from '@material-ui/core';
import {
    Button,
    Tab,
    Tabs,
    Spinner,
} from "react-bootstrap";

import { useSubheader } from "../../../_metronic/layout";

import api from '../../services/Api';
import { paymentOptions } from '../../utils/paymentOptions';
import ModalError from '../../components/ModalError';
import { NumericFormat } from '../../components/NumericFormat';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import LogService from '../../services/LogService';
import { SubCategory } from '../../types/Dre';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import { RecurringExpenses } from '../../types/RecurringExpenses';
import { frequency as frequencyEnum } from '../../types/RecurringExpenses';
import { extractDateStringFromTimestamp, getLastDayOfMonth } from '../../utils/dateTimeHelper';
import { formatFrequency } from '../../utils/frequencyToMonths';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import CenterCostService from '../../services/CenterCostService';
import { BankAccount } from '../../types/BankAccount';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import useAddAccountBank from '../../hooks/addAccountBank';

type Beneficiary = {
    id: number;
    name: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));

export function EditRecurringExpenses() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});
    const [activeTab, setActiveTab] = useState("detalhes-despesa");
    const [frequency, setFrequency] = useState("");
    const [name, setName] = useState("");
    const [payment, setPayment] = useState("");
    const [nextPaymentDate, setNextPaymentDate] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const [comments, setComments] = useState("");
    const [amount, setAmount] = useState(0);
    const [isPaid, setIsPaid] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [occurrence] = useState("unica");
    const [expireDay, setExpireDay] = useState("");
    const [daysBeforeExpire, setDaysBeforeExpire] = useState("");
    const [bankAccount, setBankAccount] = useState('');
    const [centerCost, setCenterCost] = useState("");
    const [beneficiaryType, setBeneficiaryType] = useState("supplier");
    const [beneficiaryName, setBeneficiaryName] = useState("");
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0)
    const [categoryName, setCategoryName] = useState('');
    const [nextGenerateDate, setNextGenerateDate] = useState("")

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    // Inputs Verify
    const [expensesError, setExpensesError] = useState(false);
    const [payMethodsError, setPayMethodsError] = useState(false);
    const [dueError, setDueError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [expireDayIsInvalid, setExpireDayIsInvalid] = useState(false);
    
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();

    subHeader.setTitle("Editar Conta a Pagar");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        setBankAccount(String(accountBank.id));
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setBeneficiaryId(String(createdCustomer.id));
    };
    
    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    useLayoutEffect(() => {
        async function loadData() {
            const { data } = await api.get<RecurringExpenses>(`recurringExpenses/${id}`);
            // const banks    = await api.get<BankAccount>(`/accountBank/${data.bankAccount}`);

            setName(data.name);
            setAmount(data.amount);
            setPayment(data.payment);
            setExpireDay(data.expireDay);
            setNextPaymentDate(data.nextPaymentDate ? extractDateStringFromTimestamp(data.nextPaymentDate) : '');
            setNextGenerateDate(data.nextGenerateDate ? extractDateStringFromTimestamp(data.nextGenerateDate) : '');
            setComments(data.comments ?? '');
            setBeneficiaryType(data.beneficiaryType);
            setBeneficiaryId(
                data.beneficiaryType === 'supplier'
                    ? String(data.supplier ?? '')
                    : String(data.seller ?? '')
            );
            setBeneficiaryName(
                data.beneficiaryType === 'supplier'
                    ? CustomerService.getCustomerName(data.customerEntity)
                    : SellerService.getName(data.sellerEntity)
            );
            setDocNumber(data.docNumber ?? '');
            setCenterCost(data.centerCost ? String(data.centerCost) : '');
            setBankAccount(data.bankAccount);
            setCategoryName(data.categoryName);
            setDreSubCategoryId(data.dreSubCategoryId);
            setDaysBeforeExpire(data.daysBeforeExpire);
            setFrequency(data.frequency);

            setBeforeSubmitData(data);
        }

        loadData();
    }, [id]);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return

        try {
            await saveBillToPay();

            setIsSubmit(false);

            history.push("/despesas-recorrentes");

        } catch (error: any) {
            setIsSubmit(false);
            console.log(error.message);
        }
    }

    async function saveBillToPay(additionalData?: any) {
        const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);

        const today = new Date().toISOString().split('T')[0];

        const generateDateObj = new Date(`${extractDateStringFromTimestamp(nextPaymentDate)} 00:00:00`);
        generateDateObj.setDate(generateDateObj.getDate() - Number(daysBeforeExpire));
        const nextDate = extractDateStringFromTimestamp(generateDateObj.toISOString());
            
        let data = {
            name: name,
            amount: formatToFloat(amount),
            remaining: formatToFloat(amount),
            status: isPaid ? "paid" : "pending",
            bankAccount: String(bankAccount).length > 0 ? parseInt(bankAccount) : null,
            nameBank: String(bankAccount).length > 0 ? bank.data.nameBank : null,
            centerCost: String(centerCost).length > 0 ? parseInt(centerCost) : null,
            docNumber: String(docNumber).length > 0 ? docNumber : null,
            comments: String(comments).length > 0 ? comments : null,
            nextPaymentDate: nextPaymentDate ? nextPaymentDate : null,
            nextGenerateDate: nextDate,
            beneficiaryType,
            payment: String(payment).length > 0 ? payment : null,
            supplier: beneficiaryType === 'supplier' ? (beneficiaryId || null) : null,
            seller: beneficiaryType === 'seller' ? (beneficiaryId || null) : null,
            occurrence: "unica",
            frequency: frequency || frequencyEnum.MONTHLY,
            daysBeforeExpire: daysBeforeExpire || 5,
            expireDay: expireDay,
            categoryName: categoryName,
            dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
            payedDate: isPaid ? today : null,
            totalPaid: isPaid ? formatToFloat(amount) : null,
        }

        if(additionalData) {
            data = {
                ...data,
                ...additionalData,
            };
        }
        
        const response = await api.put(`recurringExpenses/${id}`, data);
       
        LogService.logEdit({
            itemId: response.data.id,
            itemName: response.data.name,
            module: 'Despesas Recorrentes',
            oldData: {
                ...beforeSubmitData,
                beneficiaryName: beforeSubmitData.beneficiaryType === 'supplier'
                    ? CustomerService.getCustomerName(beforeSubmitData.customerEntity)
                    : SellerService.getName(beforeSubmitData.sellerEntity),
            },
            newData: {
                ...response.data,
                beneficiaryName,
            },
            formattedFields: {
            bankAccount: async (value) => (await BankAccountService.getBankAccountById(Number(value)))?.name ?? '',
            centerCost: async (value) => (await CenterCostService.getCenterCostById(Number(value)))?.name ?? '',
              nextPaymentDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
              frequency: (value) => formatFrequency(value),
              amount: (value) => formatNumberToString(value),
            },
            fieldsMap: {
              name: 'Nome da despesa',
              bankAccount: 'Conta bancária',
              payment: 'Forma de pagamento',
              categoryName: 'Plano de contas',
              supplier: 'Fornecedores',
              expireDay: 'Dia de Vencimento',
              daysBeforeExpire: 'Dias para gerar fatura',
              nextPaymentDate: 'Próximo pagamento',
              frequency: 'Periodicidade',
              amount: 'Valor',
              docNumber: 'Nº do documento',
              centerCost: 'Centro de custos',
              comments: 'Observações',
            },
        });          

    }

    function inputsVerify() {
        if(!name) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Nome da despesa é obrigatório!");
            setShowModalError(true);
            setExpensesError(true);
            return false;
        }

        if(!bankAccount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Selecione uma conta bancária!");
            setShowModalError(true);
            return false;
        }

        // if(!categoryName) {
        //     setIsSubmit(false);
        //     setMsgError("Campo Plano de Contas é obrigatório!");
        //     setShowModalError(true);
        //     return false;
        // }

        if(!payment) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            setPayMethodsError(true);
            return false;
        }

        if(!amount) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }
        if(amount <= 0) {
            setActiveTab("detalhes-despesa");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }

        if (!expireDay) {
            setActiveTab("recorrencia");
            setIsSubmit(false);
            setMsgError('Informe o dia do vencimento!');
            setShowModalError(true);
            setExpireDayIsInvalid(true);
            return false;
        }

        return true;
    }

    function validateNextPaymentDate(expire: string, paymentDate: string) {
        if (!paymentDate || !expire) return;

        const paymentDateObj = new Date(`${paymentDate} 00:00:00`);
        if (!(paymentDateObj instanceof Date)) {
            setNextPaymentDate('');
            return;
        }

        const expireDayNumber = Number(expire);
        const monthLastDay = getLastDayOfMonth(paymentDateObj.getFullYear(), paymentDateObj.getMonth() + 1);
        if (monthLastDay <= expireDayNumber) {
            paymentDateObj.setDate(monthLastDay);
        } else {
            paymentDateObj.setDate(expireDayNumber);
        }

        setNextPaymentDate(extractDateStringFromTimestamp(paymentDateObj.toISOString()));
    }

    function handleChangeNextPaymentDate(event: React.ChangeEvent<HTMLInputElement>) {
        setNextPaymentDate(event.target.value);

        validateNextPaymentDate(expireDay, event.target.value);
    }

    function handleChangeExpireDay(event: React.ChangeEvent<HTMLInputElement>) {
        const expireDayNumber = Number(event.target.value);
        let expireDayValue = event.target.value;

        if (expireDayNumber <= 0) {
            expireDayValue = '';
        } else if (expireDayNumber > 31) {
            expireDayValue = '31';
        }

        setExpireDay(expireDayValue);

        validateNextPaymentDate(expireDayValue, nextPaymentDate);
    }

    function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>) {
        setFrequency(event.target.value);
    }

    function handleChangeBeneficiaryType(value: string) {
        setBeneficiaryType(value);
        setBeneficiaryId('');
        setBeneficiaryName('');
    }

    return (

        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >
                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs">
                    <Tab eventKey="detalhes-despesa" title="Detalhes da Despesa" className="mb-15">

                        <div className="row">

                            <div className="col-lg-3">
                                <TextField
                                    label="Nome da despesa*"
                                    margin="normal"
                                    error={expensesError}
                                    className={classes.error}
                                    variant="outlined"
                                    size="small"
                                    value={name}
                                    onChange={(evt) => {
                                        setName(evt.target.value)
                                        setExpensesError(evt.target.value ? false : true)
                                    }}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                    
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Conta bancária *"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={bankAccount}
                                    hasError={showModalError}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                    onSelect={(option) => setBankAccount(String(option?.id ?? ''))}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!bankAccount) return null;
                                        return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                                    }}
                                    renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Forma de Pagamento*"
                                    className={`${classes.textField} ${classes.error}`}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={payment}
                                    onChange={(evt) => {
                                        setPayment(evt.target.value)
                                        setPayMethodsError(evt.target.value ? false : true)
                                    }}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                    error={payMethodsError}
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>

                                    {
                                        paymentOptions.map((payment, index) => (
                                            <MenuItem key={index + 1} value={payment.value}>
                                                {payment.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas *"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryId}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                    onSelect={(option) => {
                                        setDreSubCategoryId(option?.id ?? 0);
                                        setCategoryName(option?.name ?? '');
                                    }}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'expense')}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-2">
                                <TextField
                                    value={beneficiaryType}
                                    select
                                    label="Tipo Beneficiário"
                                    size="small"
                                    className="ml-0"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key="0" value="supplier">
                                        Fornecedor
                                    </MenuItem>

                                    <MenuItem key="1" value="seller">
                                        Vendedor
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-4 d-flex align-items-center">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Beneficiário"
                                    getOptionLabel={(option: Beneficiary) => `${option.id} - ${option.name}`}
                                    value={beneficiaryId}
                                    onSelect={(option) => { setBeneficiaryId(option ? String(option.id) : ''); setBeneficiaryName(option?.name ?? '') }}
                                    apiSearchHandler={beneficiaryType === 'supplier'
                                        ? ((typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true }))
                                        : ((typedText) => SellerService.getSellersFiltered({ name: typedText }))}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!beneficiaryId) return null;

                                        const loadedBeneficiary = loadedOptions.find((option) => option.id === Number(beneficiaryId));
                                        if(loadedBeneficiary) return loadedBeneficiary;

                                        return beneficiaryType === 'supplier' ? CustomerService.getCustomerById(beneficiaryId) : SellerService.getSellerById(beneficiaryId);
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                    renderAddButton={(
                                        beneficiaryType === 'supplier'
                                            ? (typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText)} />
                                            : undefined
                                    )}
                                />
                            </div>

                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor*"
                                    startAdornment="R$"
                                    error={valueError}
                                    className={`${classes.error}`}
                                    value={amount}
                                    onChange={(evt) => {
                                        setAmount(formatToFloat(evt.target.value));
                                        setValueError(evt.target.value ? false : true);
                                    }}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                />
                            </div>

                        </div>

                    </Tab>

                    <Tab eventKey="detalhes-conta" title="Detalhes da Conta" className="mb-15">

                        <div className="row">
                            <div className="col-lg-6">
                                <TextField
                                    label="Nº do documento"
                                    className="ml-0"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={docNumber}
                                    onChange={(evt) => setDocNumber(evt.target.value)}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Centro de Custos"
                                    getOptionLabel={(option: CenterCost) => option.name}
                                    value={centerCost}
                                    hasError={showModalError}
                                    disabled={isPaid || user.isAccountant == "y" ? true : false}
                                    onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!centerCost) return null;
                                        return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                    }}
                                />
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Ocorrência"
                                    className="ml-0"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={occurrence}
                                    onChange={(evt) => setOccurrence(evt.target.value)}
                                >
                                    <MenuItem key="0" value="unica">
                                        Única
                                    </MenuItem>

                                    <MenuItem key="1" value="parcelada">
                                        Parcelada
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-5">
                                <label htmlFor="formFile" className="mt-3">Anexo</label>
                                <input className="form-control" type="file" id="formFile" disabled={isPaid || user.isAccountant == "y" ? true : false}/>
                                <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label>
                            </div>
                        </div>

                        <TextField
                            label="Observações"
                            multiline
                            rows="2"
                            className="ml-0"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            value={comments}
                            onChange={(evt) => setComments(evt.target.value)}
                            disabled={isPaid || user.isAccountant == "y" ? true : false}
                        />

                    </Tab>
                    <Tab eventKey="recorrencia" title="Recorrência">
                        <div className="row">
                            <div className="col-lg-3">
                                <NumericFormat
                                    label="Dia de Vencimento*"
                                    className={`mx-2 ${classes.error}`}
                                    thousandSeparator={''}
                                    decimalScale={0}
                                    value={expireDay}
                                    onChange={handleChangeExpireDay}
                                    error={expireDayIsInvalid}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-3">
                                <NumericFormat
                                    label="Dias para gerar Fatura"
                                    className={`mx-2 ${''}`}
                                    thousandSeparator={''}
                                    decimalScale={0}
                                    value={daysBeforeExpire}
                                    onChange={(e) => setDaysBeforeExpire(e.target.value)}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    value={frequency}
                                    onChange={handleChangeFrequency}
                                    size="small"
                                    select
                                    label="Periodicidade"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    defaultValue={frequencyEnum.MONTHLY}
                                    disabled={user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem value={frequencyEnum.MONTHLY}>
                                        Mensal
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.BIMONTHLY}>
                                        Bimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.TRIMONTHLY}>
                                        Trimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.QUARTERLY}>
                                        Quadrimestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.SEMIANNUAL}>
                                        Semestral
                                    </MenuItem>
                                    <MenuItem value={frequencyEnum.ANNUAL}>
                                        Anual
                                    </MenuItem>
                                </TextField>
                            </div>

                            <div className="col-lg-3">
                            <TextField
                                size="small"
                                type='date'
                                margin="normal"
                                label="Próximo Pagamento"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                className={` ${classes.textField} ${classes.error} `}
                                value={nextPaymentDate}
                                onChange={handleChangeNextPaymentDate}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                            </div>
                        </div>
                    </Tab>

                </Tabs>

                {
                    user.isAccountant == "n"
                    ?
                    <div className='col-lg-6 d-flex flex-row'>
                        {
                            <Button
                            type='submit'
                            className='mr-3'
                            variant="primary"
                            disabled={isSubmit}
                        >

                            {isSubmit ? <>

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </> : <>

                                <span>
                                    Salvar
                                </span>

                            </>}


                        </Button>
                        }

                        <Link href='/despesas-recorrentes' className='btn btn-secondary'>
                            Cancelar
                        </Link>
                    </div>
                    : <></>
                }
            </form>
        </div>
    );
}
