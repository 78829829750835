import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { EditQuotation } from '../pages/Quotation/Edit-Quotation';
import { ListQuotation } from '../pages/Quotation/List-Quotation';
import { NewQuotation } from '../pages/Quotation/New-Quotation';

export function Quotation() {
  return (
      <Switch>
        <Route path='/cotacoes/adicionar' component={NewQuotation} />
        <Route path='/cotacoes/duplicar/:idToClone' component={NewQuotation} />
        <Route path='/cotacoes/:id' component={EditQuotation} />
        <Route path='/cotacoes' component={ListQuotation} />
      </Switch>
  )
}
