import { ServiceContractData } from "../types/ServiceContractData";
import { getDate } from "../utils/dateTimeHelper";
import api from "./Api";
import NotificationService from "./NotificationService";

type DispatchStatusChangeNotificationAndEmailParams = {
    serviceContractId: number;
    lastStatusHistory: any;
    newStatusHistory: any;
}

class ServiceContractService {
    async getServiceContractsFiltered(filters: any) {
        const contractResponse = await api.get<ServiceContractData[]>('/serviceContract', { params: { filters } });

        return contractResponse.data;
    }

    async dispatchStatusChangeNotificationAndEmail({
        serviceContractId,
        lastStatusHistory,
        newStatusHistory,
    }: DispatchStatusChangeNotificationAndEmailParams) {
        try {
            await api.post(`serviceContract/statusNotification`, {
                serviceContractId,
                lastStatusHistory,
                newStatusHistory,
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getServiceContractsCountByCustomerId(customerId: number | string) {
        const response = await api.get('/serviceContract/customer/count', { params: { customerId } });
        return response.data.count as number;
    }
}

export default new ServiceContractService();