import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { formatDate } from '../../../utils/dateFormat';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { Customer } from '../../../types/Customer';
import BankAccountService from '../../../services/BankAccountService';
import { BankAccount } from '../../../types/BankAccount';
import { BillsToReceive } from '../../../types/BillsToReceive';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "billetNumber", value: "Nº Boleto" },
    { reference: "dueDate", value: "Vencimento" },
    { reference: "customer", value: "Cliente" },
    { reference: "title", value: "Nome da Receita" },
    { reference: "bankAccount", value: "Conta Bancária" },
    { reference: "status", value: "Situação" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "totalPaid", value: "Valor Pago" },
];

type Filters = {
    customer: string,
    dueDateMin: string,
    dueDateMax: string,
    paymentDateMin: string,
    paymentDateMax: string,
    issuanceDateMin: string,
    issuanceDateMax: string,
    status: string,
    billetNumber: string,
    accountBank: string
}

export function ListReportBillet() {
    const [foundBilletPaginate, setFoundBilletPaginate] = useState<any[]>([]);
    const [foundBillet, setFoundBillet] = useState<any[]>([]);
    const [countTotalBillet, setCountTotalBillet] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [customer, setCustomer] = useState("");

    const [status, setStatus] = useState('all');
    const [dueDateMin, setDueDateMin] = useState('');
    const [dueDateMax, setDueDateMax] = useState('');
    const [paymentDateMin, setPaymentDateMin] = useState('');
    const [paymentDateMax, setPaymentDateMax] = useState('');
    const [billetNumber, setNumberBillet] = useState('');
    const [accountBank, setAccountBank] = useState('');
    const [issuanceDateMin, setIssuanceDateMin] = useState('');
    const [issuanceDateMax, setIssuanceDateMax] = useState('');

    const [totalReceivesLate, setTotalReceivesLate] = useState(0);
    const [totalReceivesToday, setTotalReceivesToday] = useState(0);
    const [totalReceivesForeseen, setTotalReceivesForeseen] = useState(0);
    const [totalReceive, setTotalReceive] = useState(0);
    const [totalReceived, setTotalReceived] = useState(0);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundBilletPaginate;

        aux.forEach((billet) => {
            const dueDate = billet.dueDate ? formatDate(billet.dueDate) : '';

            const bankAccount = billet.bankAccountEntity 
                            ? `${billet.bankAccountEntity.nameBank} - ${billet.bankAccountEntity.name}`
                            : ' - '; 

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(billet.id) },
                { for: "billetNumber", value: billet.billetNumber ? String(billet.billetNumber) : "-"},
                { for: "dueDate", value: dueDate },
                { for: "customer", value: CustomerService.getCustomerName(billet.customerEntity) },
                { for: "title", value: billet.name },
                { for: "bankAccount", value: bankAccount },
                { for: "status", value: getSituationText(billet.status) === "Pago" ? `Pago ${billet.payedDate ? `(${formatDate(billet.payedDate)})` : ''}` : getSituationText(billet.status) },
                { for: "totalValue", value: formatCurrency(billet.amount) },
                { for: "totalPaid", value: formatCurrency(billet.totalPaid) },
            ];

            list.push(data);
        });

        foundBillet.forEach((billet) => {
            const dueDate = billet.dueDate ? formatDate(billet.dueDate) : '';
                            
            const bankAccount = billet.bankAccountEntity 
                            ? `${billet.bankAccountEntity.nameBank} - ${billet.bankAccountEntity.name}`
                            : ' - '; 

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(billet.id) },
                { for: "billetNumber", value: billet.billetNumber ? String(billet.billetNumber) : "-"},
                { for: "dueDate", value: dueDate },
                { for: "customer", value: CustomerService.getCustomerName(billet.customerEntity) },
                { for: "title", value: billet.name },
                { for: "bankAccount", value: bankAccount },
                { for: "status", value: getSituationText(billet.status) === "Pago" ? `Pago ${billet.payedDate ? `(${formatDate(billet.payedDate)})` : ''}` : getSituationText(billet.status) },
                { for: "totalValue", value: formatCurrency(billet.amount) },
                { for: "totalPaid", value: formatCurrency(billet.totalPaid) },
            ];

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
            });
        });

        setExportTotals([
            { name: 'Quantidade de Contas', value: foundBillet.length },
            { name: 'Recebimentos Atrasados', value: formatCurrency(totalReceivesLate) },
            { name: 'Recebimentos para Hoje', value: formatCurrency(totalReceivesToday) },
            { name: 'Recebimentos Futuros', value: formatCurrency(totalReceivesForeseen) },
            { name: 'Valor total a Receber', value: formatCurrency(totalReceive) },
            { name: 'Valor total Recebido', value: formatCurrency(totalReceived) },
        ]);

        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundBilletPaginate, foundBillet]);

    const clearSearch = () => {
        setCustomer('');
        setStatus('all');
        setDueDateMin('');
        setDueDateMax('');
        setPaymentDateMin('');
        setPaymentDateMax('');
        setNumberBillet('');
        setAccountBank('');
        setIssuanceDateMin('');
        setIssuanceDateMax('');
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: BillsToReceive[], count:number}>("/report/billet", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;


        const billets = await api.get("/report/billet", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setFoundBilletPaginate(data.rows ? rows : []);

        setTotalReceivesLate(billets.data.totalLate);
        setTotalReceivesToday(billets.data.totalToday);
        setTotalReceivesForeseen(billets.data.totalFuture);
        setTotalReceive(billets.data.totalToReceive);
        setTotalReceived(billets.data.totalReceived);
        
        setFoundBillet(billets.data.rows);
        setCountTotalBillet(billets.data.rows.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customer,
            dueDateMin,
            dueDateMax,
            paymentDateMin,
            paymentDateMax,
            issuanceDateMin,
            issuanceDateMax,
            status,
            billetNumber,
            accountBank
        };
        reloadData();

    }, [
        customer,
        dueDateMin,
        dueDateMax,
        paymentDateMin,
        paymentDateMax,
        issuanceDateMin,
        issuanceDateMax,
        status,
        billetNumber,
        accountBank
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Títulos', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(String(option?.id) || '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de vencimento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMin}
                        onChange={(e) => setDueDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de vencimento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={dueDateMax}
                        onChange={(e) => setDueDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de pagamento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={paymentDateMin}
                        onChange={(e) => setPaymentDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de pagamento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={paymentDateMax}
                        onChange={(e) => setPaymentDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de emissão"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={issuanceDateMin}
                        onChange={(e) => setIssuanceDateMin(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data de emissão"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={issuanceDateMax}
                        onChange={(e) => setIssuanceDateMax(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        variant="outlined"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="pending" onClick={() => setStatus('pending')}>
                            Pendente
                        </MenuItem>
                        <MenuItem key="2" value="late" onClick={() => setStatus('late')}>
                            Vencido
                        </MenuItem>
                        <MenuItem key="3" value="paid" onClick={() => setStatus('paid')}>
                            Pago
                        </MenuItem>
                        <MenuItem key="4" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        type="number"
                        size="small"
                        label="Número do boleto"
                        margin="normal"
                        variant="outlined"
                        value={billetNumber}
                        onChange={e => setNumberBillet(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Conta bancária"
                        getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                        value={accountBank}
                        onSelect={(option) => setAccountBank(String(option?.id ?? ''))}
                        apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!accountBank) return null;
                            return loadedOptions.find((option) => option.id === Number(accountBank)) ?? BankAccountService.getBankAccountById(accountBank)
                        }}
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Títulos"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Títulos', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalBillet}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de Contas</TableCell>
                                    <TableCell colSpan={1}>{foundBillet.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Recebimentos Atrasados</TableCell>
                                    <TableCell colSpan={1}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(totalReceivesLate)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Recebimentos para Hoje</TableCell>
                                    <TableCell colSpan={1}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(totalReceivesToday)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Recebimentos Futuros</TableCell>
                                    <TableCell colSpan={1}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(totalReceivesForeseen)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total a Receber</TableCell>
                                    <TableCell colSpan={1}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(totalReceive)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total Recebido</TableCell>
                                    <TableCell colSpan={1}>{new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(totalReceived)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}