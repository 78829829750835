import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from "../../services/Api";
import { useFormik } from "formik";
// import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import ModalSuccess from "../../components/ModalSuccess";
import ModalError from "../../components/ModalError";
import ReactInputMask from "react-input-mask";
import { cnpjSearch } from "../../utils/cnpjSearch";
import { validateCpfCnpj } from "../../utils/validateCpfCnpj";

export function FreeTrialPage() {
  const [name, setName] = useState<string>("");
  const [cell, setCell] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [segment, setSegment] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>("");
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
  const [companySegmentInvalid, setCompanySegmentInvalid] = useState<boolean>(false);
  const [nameInvalid, setNameInvalid] = useState<boolean>(false);
  const [cellInvalid, setCellInvalid] = useState<boolean>(false);
  const [cnpjInvalid, setCnpjInvalid] = useState<boolean>(false);
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);

  function inputsVerify() {
    setLoading(true);
    setEmailInvalid(false);
    setNameInvalid(false);
    setCellInvalid(false);
    setPasswordInvalid(false);

    if (!email) {
      setMsgError("Preencha o e-mail corretamente");
      setShowModalError(true);
      setEmailInvalid(true);
      setLoading(false);

      return false;
    }

    if (!segment) {
      setMsgError("Escolha o segmento da empresa");
      setShowModalError(true);
      setCompanySegmentInvalid(true);
      setLoading(false);

      return false;
    }

    if (!name) {
      setMsgError("Preencha o nome de usuário corretamente");
      setShowModalError(true);
      setNameInvalid(true);
      setLoading(false);

      return false;
    }

    if (!cell) {
      setMsgError("Preencha o telefone corretamente");
      setShowModalError(true);
      setCellInvalid(true);
      setLoading(false);

      return false;
    }

    if (!cnpj) {
      setMsgError("Preencha o CNPJ corretamente");
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);

      return false;
    }

    if (!password) {
      setMsgError("Preencha a senha corretamente");
      setShowModalError(true);
      setPasswordInvalid(true);
      setLoading(false);

      return false;
    }

    return true;
  }

  async function searchCnpj(cnpj: string) {
    const searchResponse = await cnpjSearch(cnpj);

    if (searchResponse.status === "ERROR") {
      setMsgError(searchResponse.message);
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);
      
      return;
    }

    setName(searchResponse.nome);
  }

  async function handleSubmit(evt: any) {
    evt.preventDefault();

    setLoading(true);

    if (!inputsVerify()) return;

    if (!validateCpfCnpj(cnpj)) {
      setMsgError("CNPJ inválido");
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);

      return;
    }

    const formattedCnpj = cnpj.replace(/[^0-9]/g, "");

    try {
      const raw = {
        name,
        cell,
        email,
        password,
        cnpj: formattedCnpj,
        corporateName: name,
        segment,
      }
  
      const response = await api.post('/companies/free', raw);

      setLoading(false);
      setShowModalSuccess(true);
    } catch (error: any) {
      setLoading(false);
      setMsgError(error.response.data.message ?? "Erro ao criar conta de teste gratuito");
      setShowModalError(true);
    }
  }

  return (
    <section className="d-flex align-items-center justify-content-center py-5 bg-gray-200 min-vh-100">
      <ModalSuccess
        msgModal="Conta para teste gratuito criada com sucesso!"
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect="/auth/login"
        />

      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="shadow-sm p-6 overflow-hidden d-flex bg-white rounded animate__animated animate__fadeInDown"
                  style={{gap: '3rem'}}>
              <form onSubmit={handleSubmit} className="p-3" action="" style={{flex: '1 1 0'}}>
                <h4 className="mb-6 text-secondary">Comece seu teste gratuito!</h4>
                <div className="form-group">
                  <label
                  className={"d-block font-weight-bold " + (emailInvalid ? "text-danger" : "")}
                  htmlFor="email">E-mail*</label>
                  <input
                    onChange={e => {
                      setEmail(e.target.value)
                      setEmailInvalid(e.target.value ? false : true)
                    }}
                    value={email}
                    name="email"
                    id="email"
                    className={"form-control " + (emailInvalid ? "border-danger" : "")}
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <label
                  className={"d-block font-weight-bold " + (companySegmentInvalid ? "text-danger" : "")}
                  htmlFor="segment">Segmento da empresa*</label>
                  <select onChange={(e) => setSegment(e.target.value)}
                    value={segment}
                    disabled={isOtherChecked}
                    className={"form-control " + (companySegmentInvalid ? "border-danger" : "")}>
                    <option disabled value="">Selecione o segmento</option>
                    <option value="agricultura">Agricultura</option>
                    <option value="alimentacao">Alimentação</option>
                    <option value="comunicacao">Comunicação</option>
                    <option value="construcao-civil">Construção Civil</option>
                    <option value="educacao">Educação</option>
                    <option value="energia">Energia</option>
                    <option value="financeiro">Financeiro</option>
                    <option value="imobiliario">Imobiliário</option>
                    <option value="saude">Saúde</option>
                    <option value="seguranca">Segurança</option>
                    <option value="tecnologia">Tecnologia</option>
                    <option value="textil">Têxtil</option>
                    <option value="transporte">Transporte</option>
                    <option value="eletronicos">Eletrônicos</option>
                  </select>
                  <div className="mt-4 d-flex align-items-center">
                    <input onChange={() => setIsOtherChecked(!isOtherChecked)}
                      id="other"
                      checked={isOtherChecked}
                      type="checkbox"
                      style={{ width: "15px", height: "15px" }}
                      />
                    <label className="mb-0 ml-2" htmlFor="other">Outro segmento</label>
                  </div>
                  <div className={`form-group mt-4 ${isOtherChecked ? "" : "d-none"}`}>
                    <input onChange={(e) => setSegment(e.target.value)}
                      value={segment}
                      placeholder="Digite o segmento da empresa"
                      className={"form-control " + (companySegmentInvalid ? "border-danger" : "")}
                      type="text" />
                  </div>
                </div>
                <div className="form-group">
                  <label
                  className={"d-block font-weight-bold " + (nameInvalid ? "text-danger" : "")}
                  htmlFor="name">Nome*</label>
                  <input
                    onChange={e => {
                      setName(e.target.value)
                      setNameInvalid(e.target.value ? false : true)
                    }}
                    value={name}
                    name="name"
                    id="name"
                    className={"form-control " + (nameInvalid ? "border-danger" : "")}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label
                  className={"d-block font-weight-bold " + (cellInvalid ? "text-danger" : "")}
                  htmlFor="cell">Celular*</label>
                  <ReactInputMask mask="(99) 99999-9999" 
                    onChange={e => {
                      setCell(e.target.value)
                      setCellInvalid(e.target.value ? false : true)
                    }}
                    value={cell}
                    name="cell"
                    id="cell"
                    className={"form-control " + (cellInvalid ? "border-danger" : "")}
                    type="text"
                  />
                </div>
                <div className="form-group d-flex align-items-end">
                  <div className="flex-grow-1 mr-3">
                    <label
                    className={"d-block font-weight-bold " + (cnpjInvalid ? "text-danger" : "")}
                    htmlFor="cnpj">CNPJ*</label>
                    <ReactInputMask mask="99.999.999/9999-99"
                      onChange={e => {
                        setCnpj(e.target.value)
                        setCnpjInvalid(e.target.value ? false : true)
                      }}
                      value={cnpj}
                      name="cnpj"
                      id="cnpj"
                      className={"form-control " + (cnpjInvalid ? "border-danger" : "")}
                      type="text"
                    />
                  </div>
                  
                  <button type="button" className="btn btn-primary" onClick={() => searchCnpj(cnpj)}>
                    <i className="flaticon-search p-0"></i>
                  </button>
                </div>
                <div className="form-group">
                  <label
                    className={"d-block font-weight-bold " + (passwordInvalid ? "text-danger" : "")}
                    htmlFor="password">Senha*</label>
                  <input
                    onChange={e => {
                      setPassword(e.target.value)
                      setPasswordInvalid(e.target.value ? false : true)
                    }}
                    value={password}
                    name="password"
                    id="password"
                    className={"form-control " + (passwordInvalid ? "border-danger" : "")}
                    type="password"
                  />
                </div>

                <button type="submit" className="btn btn-success w-100 font-weight-bold" disabled={loading}>{
                  !loading ? (
                    <>
                      <span>Iniciar teste gratuito</span>
                    </>
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        size="sm"
                        aria-hidden="true"
                      />
                      <span className="ml-2">Aguarde...</span>
                    </>
                  )
                }</button>
              </form>
              <aside className="rounded-2 d-xl-flex d-lg-flex d-md-flex d-sm-flex flex-column align-items-center px-3 py-10 bg-secondary d-none"
              style={{gap: '3.5rem', flex: '1 1 0'}}>
                <img src={toAbsoluteUrl("/media/logos/header/logo-header-completo-gold.png")} alt="" width="210" height="130" />

                <div className="px-lg-10 px-md-10 px-sm-2 text-white">
                  <h2 className="mb-6">Utilize o Milliontech por 7 dias, gratuitamente!</h2>
                  <p style={{fontSize: '16px'}}><i className="flaticon2-check-mark text-primary mr-1"></i> Tenha controle sobre seus negócios </p>
                  <p style={{fontSize: '16px'}}><i className="flaticon2-check-mark text-primary mr-1"></i> Relatórios sobre estoque, vendas, financeiro, serviços e muito mais</p>
                  <p style={{fontSize: '16px'}}><i className="flaticon2-check-mark text-primary mr-1"></i> Acesso a um dashboard com gráficos precisos sobre seus resultados</p>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}