import React, { useEffect, useState } from 'react';

import { BankAccountProvider } from './bankAccount';
import { ParameterProvider } from './parameters';
import { CustomerProvider } from './customer';
import { ProductProvider } from './products';

export const PersonalHooks: React.FC = ({ children }) => {
    return (
        <CustomerProvider>
            <ProductProvider>
                <ParameterProvider>
                    <BankAccountProvider>
                        {children}
                    </BankAccountProvider>
                </ParameterProvider>
            </ProductProvider>
        </CustomerProvider>
    );
}
