import { CenterCost } from "../types/CenterCost";
import api from "./Api";

class CenterCostService {
    async getCenterCostById(id: string | number) {
        const centerCostResponse = await api.get<CenterCost>(`/centerCost/${id || '0'}`);

        return centerCostResponse.data;
    }

    async getCenterCostsFiltered(filters: any) {
        const centerCostResponse = await api.get<CenterCost[]>('/centerCost', { params: { filters } });

        return centerCostResponse.data;
    }

    getCenterCostName(centerCost?: CenterCost, defaultValue: string = '') {
        if(!centerCost) {
            return defaultValue ? defaultValue : '';
        }
        
        return centerCost.name;
    }
}

export default new CenterCostService();