import React, { useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    TextField,
    Typography,
    Table,
    TableRow,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    SvgIcon,
} from '@material-ui/core';
import { Button, } from 'react-bootstrap';

type solicitationHistory = { historyData?: any }

const SolicitationHistory: React.FC<solicitationHistory> = ({historyData}) => {   
    const count = historyData ? historyData.length : 0;
    
    return (
        <div className="shadow">
            <Table stickyHeader className="wrap">
                <TableHead>
                    <TableRow>
                        <TableCell>Processo</TableCell>
                        <TableCell>Data inicial</TableCell>
                        <TableCell>Data final</TableCell>
                        <TableCell>Usuário</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        historyData.map((h: any, index: number) => 
                            <TableRow
                                style={
                                    count == index + 1 ?
                                        styles.selected
                                        : {}
                                }
                                hover
                            >
                                <TableCell>{h.solicitationName}</TableCell>
                                <TableCell>{h.start_date}</TableCell>
                                <TableCell>{h.end_date}</TableCell>
                                <TableCell>{h.user_name}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </div>
    );
}

const styles = {
    table: {
        width: "100%",
        border: "1px solid #DCDCDC",
    },
    selected: {
        backgroundColor: "#B3E5FC",
    },
}

export default SolicitationHistory;