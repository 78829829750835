import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, makeStyles, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatNumberToString } from '../../../utils/formatCurrency';
import { getSituationText } from '../../../utils/getSituationText';
import { Autocomplete } from '@material-ui/lab';
import { formatDate } from '../../../utils/dateFormat';
import { ServicesTable } from './ServicesTable';
import { formatFrequency } from '../../../utils/frequencyToMonths';
import { ServiceContractData } from '../../../types/ServiceContractData';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { Link } from 'react-router-dom';

const headData: HeadDataBaseProps[] = [
    { reference: "number", value: "Nº Orçamento" },
    { reference: "billType", value: "Tipo de Pagamento" },
    { reference: "customer", value: "Cliente" },
    { reference: "startDate", value: "Data Inicial" },
    { reference: "finalDate", value: "Data Final" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "referencies", value: "Referência" },
    { reference: "nextPayment", value: "Próximo Pagamento" },
    { reference: "status", value: "Situação" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "name", value: "Nome" },
    { reference: "amount", value: "Qtde" },
    { reference: "unitaryValue", value: "Valor Unitário" },
    { reference: "total", value: "Valor Total" },
];

type Filters = {
    customer: number,
    date: string,
    dateLimit: string,
    status: string,
    typeBill: string,
    refs: string,
    nextPayment: string
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
        },
      },
}));

export function ListReportServiceContract() {
    const [foundServiceContractPaginate, setServiceContractPaginate] = useState<any[]>([]);
    const [foundServiceContract, setServiceContract] = useState<any[]>([]);
    const [countTotalServiceContract, setCountTotalServiceContract] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [customer, setCustomer] = useState<any>('');
    const [customerError, setCustomerError] = useState(false);

    const [refs, setRefs] = useState<any>('');
    const [status, setstatus] = useState('all');
    const [dateLimit, setDateLimit] = useState('');
    const [date, setDate] = useState('');
    const [uTotal, setUTotal] = useState(0);
    const [showServices, setShowServices] = useState(false);
    const [typeBill, setTypeBill] = useState('all');
    const [nextPayment, setNextPayment] = useState('');

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);
    const classes = useStyles();
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundServiceContractPaginate;
        let exportProductsData: ReportDataRow[] = [];
        let total: number = 0;

        aux.forEach((serviceContract) => {            
            const paymentNext =  serviceContract.nextPaymentDate || serviceContract.nextPaymentDate !== null ? formatDate(serviceContract.nextPaymentDate) : ' - '; 
            const obs = serviceContract.comments || serviceContract.comments !== null ? String(serviceContract.comments) : ' - ';
            const refs = serviceContract.referencies !== null ? serviceContract.referencies : ' - ';
            const startDate = serviceContract.startDate !== null ? formatDate(serviceContract.startDate) : ' - ';
            const finalDate = serviceContract.finalDate !== null ? formatDate(serviceContract.finalDate) : ' - ';
            const nexPayment = serviceContract.nextPaymentDate !== null ? formatDate(serviceContract.nextPaymentDate) : ' - ';
            const frequencyFormated = serviceContract.frequency || serviceContract.frequency !== null ? formatFrequency(serviceContract.frequency) : ' - ';
            const servicesFormated = JSON.parse(serviceContract.services);
            const customer = serviceContract.customerEntity.length 
                            ? (serviceContract.customerEntity[0].typePeople == "physical" ? serviceContract.customerEntity[0].name : serviceContract.customerEntity[0].corporateName)
                            : ' - ';
            const valueTotal = serviceContract.totalValue || serviceContract.totalValue !== null ? 'R$ ' + serviceContract.totalValue : ' - ';
            const serviceContractNum = serviceContract.number !== null || serviceContract.number !== '' || serviceContract.number !== 0 ? serviceContract.number : ' - ';

            var billType = "";
            if (serviceContract.billType == "account") {
                billType = "Conta";
            } else {
                billType = "Boleto";
            }
            
            const data: BodyDataBaseProps[] = [
                {
                    for: "number",
                    value: String(serviceContractNum),
                    jsx: <Link to={`/contrato-servico/${serviceContract.id}`}>{serviceContract.id}</Link>,
                },
                { for: "billType", value: billType },
                { for: "customer", value: customer },
                { for: "startDate", value: startDate },
                { for: "finalDate", value: finalDate },
                { for: "totalValue", value: valueTotal},
                { for: "referencies", value: String(refs) },
                { for: "nextPayment", value: nexPayment },
                { for: "status", value: getSituationText(serviceContract.status) },
            ];

            if (showServices !== false) {
                data.push(
                    {
                        for: "products/services",
                        value: '',
                        secondaryRow: true,
                        jsx: <ServicesTable services={servicesFormated} observations={obs} frequency={frequencyFormated} payment={paymentNext} />
                    },
                );
            };

            list.push(data);
        });

        foundServiceContract.forEach((serviceContract) => {
            const paymentNext =  serviceContract.nextPaymentDate || serviceContract.nextPaymentDate !== null ? formatDate(serviceContract.nextPaymentDate) : ' - '; 
            const obs = serviceContract.comments || serviceContract.comments !== null ? String(serviceContract.comments) : ' - ';
            const frequencyFormated = serviceContract.frequency || serviceContract.frequency !== null ? formatFrequency(serviceContract.frequency) : ' - ';
            const servicesFormated = JSON.parse(serviceContract.services);
            const refs = serviceContract.referencies !== null ? serviceContract.referencies : ' - ';
            const date = serviceContract.startDate !== null ? formatDate(serviceContract.startDate) : ' - ';
            const customer = serviceContract.customerEntity.length 
                            ? (serviceContract.customerEntity[0].typePeople == "physical" ? serviceContract.customerEntity[0].name : serviceContract.customerEntity[0].corporateName)
                            : ' - ';
            const valueTotal = serviceContract.totalValue || serviceContract.totalValue !== null ? 'R$ ' + serviceContract.totalValue : ' - ';
            const serviceContractNum = serviceContract.number !== null || serviceContract.number !== '' || serviceContract.number !== 0 ? serviceContract.number : ' - ';

            const data: BodyDataBaseProps[] = [
                { for: "number", value: String(serviceContractNum) },
                { for: "customer", value: customer },
                { for: "startDate", value: date },
                { for: "totalValue", value: valueTotal},
                { for: "referencies", value: String(refs) },
                { for: "status", value: getSituationText(serviceContract.status) },
            ];

            total += Number(serviceContract.totalValue);
            
            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            if (showServices !== false) {
                exportProductsData = servicesFormated.map((service: any) => {
                    return {
                        cells: [
                            {for: 'Serviço', content: service.INSTANCE.name ?? '-'},
                            {for: 'Observações', content: obs },
                            {for: 'Periodicidade', content: frequencyFormated},
                            {for: 'Próx. Pagamento', content: paymentNext},
                            {for: 'Valor Total', content: 'R$ ' +  formatNumberToString(service.totalValue)},
                        ],
                    };
                });
            };

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setUTotal(total);

        setExportTotals([
            { name: 'Quantidade de Serviços', value: foundServiceContract.length },
            { name: 'Valor total', value: 'R$ ' + formatNumberToString(total) },
        ]);
        
        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundServiceContractPaginate, foundServiceContract]);

    const clearSearch = () => {
        setRefs('')
        setstatus('all');
        setCustomer('');
        setDate('');
        setDateLimit('');
        setTypeBill('');
        setNextPayment('');
        setShowServices(false);
    };


    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: ServiceContractData[], count:number}>("/report/serviceContract", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows} = data;

        const nfse = await api.get("/report/serviceContract", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        setServiceContractPaginate(data.rows ? rows : []);

        setServiceContract(nfse.data);
        setCountTotalServiceContract(nfse.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customer,
            date,
            dateLimit,
            status: status == "all" ? "" : status,
            typeBill: typeBill == "all" ? "" : typeBill,
            refs,
            nextPayment
        };
        reloadData();

    }, [
        customer,
        date,
        dateLimit,
        status,
        typeBill,
        refs,
        nextPayment
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Contratos de Serviços', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">

                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                        onChangeTextField={(e) => setCustomerError(e.target.value ? false : true)}
                        textFieldClass={classes.error}
                        hasError={customerError}
                    />
                </div>

                <div className="col-lg-4 d-flex justify-content-between">
                    <TextField
                        type="date"
                        label="Data de Início"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />

                    <TextField
                        type="date"
                        label="Data Final"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateLimit}
                        onChange={(e) => setDateLimit(e.target.value)}
                        className="ms-20"
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        variant="outlined"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setstatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setstatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="progress" onClick={() => setstatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="3" value="attended" onClick={() => setstatus('attended')}>
                            Atendido
                        </MenuItem>

                        <MenuItem key="4" value="canceled" onClick={() => setstatus('canceled')}>
                            Cancelado
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo de Pagamento"
                        margin="normal"
                        variant="outlined"
                        value={typeBill}
                    >
                        <MenuItem key="0" value="all" onClick={() => setTypeBill('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="account" onClick={() => setTypeBill('account')}>
                            Conta
                        </MenuItem>

                        <MenuItem key="2" value="billet" onClick={() => setTypeBill('billet')}>
                            Boleto
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <TextField
                        size="small"
                        label="Referência"
                        margin="normal"
                        variant="outlined"
                        value={refs}
                        onChange={(e) => setRefs(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                <TextField
                        type="date"
                        label="Próximo Pagamento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={nextPayment}
                        onChange={(e) => setNextPayment(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={showServices}
                                onChange={(_, checked) => setShowServices(checked)}
                            />
                        }
                        label="Exibir os Serviços"
                        labelPlacement="end"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Contratos de Serviços"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Contratos de Serviços', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalServiceContract}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                    defaultSortReference="number"
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Quantidade de Serviços</TableCell>
                                    <TableCell colSpan={1}>{foundServiceContract.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={1}>Valor Total</TableCell>
                                    <TableCell colSpan={1}>{'R$ ' +  formatNumberToString(uTotal) }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}