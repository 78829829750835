import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Activity from './Activity';
import Dashboard from './Dashboard';

export function ListSolicitation() {
    const [activeTab, setActiveTab] = useState("activities");

    return (
        <div className="card card-body pt-4">
            <Tabs
                activeKey={activeTab}
                onSelect={(tab: string) => setActiveTab(tab)}
                className="mb-3"
                id='solicitation-tabs'
            >
                <Tab
                    eventKey='activities'
                    title="Atividades"
                    className='py-5'
                >
                    <Activity/>
                </Tab>
                <Tab
                    eventKey='dashboard'
                    title="Dashboards"
                    className='py-5'
                >
                    <Dashboard/>
                </Tab>
            </Tabs>
        </div>
    );
}