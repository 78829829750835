import api from 'axios'

//* URL produção
const url = process.env.REACT_APP_API_URL

const unauthenticatedRoutes = [
    '/teste-gratuito',
    '/criar-senha'
]

api.defaults.baseURL = url;

api.defaults.headers['Content-Type']  = 'application/json';

api.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401
        && window.location.pathname != '/auth/login'
        && !unauthenticatedRoutes.includes(window.location.pathname)
        && !window.location.pathname.includes('/criar-senha')) {
        window.location.href = "/auth/login";
    }

    return Promise.reject(error);
});

export default api;