import React, { useState, useEffect } from 'react';

import { useSubheader } from "../../../_metronic/layout";

import {
    makeStyles,
    TextField,
    Grid,
    Checkbox
} from '@material-ui/core';

import {
    Button,
    Spinner,
    Modal
} from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ModalError from '../../components/ModalError';
import useMenu from '../../hooks/menu';
import { Menu } from '../../types/Menu';
import api from '../../services/Api';
import { useHistory, useParams } from 'react-router';
import { Profile } from '../../types/Profile';
import PermissionMenuSelector from '../../components/PermissionMenuSelector';
import { useSelector } from 'react-redux';
import useCompany from '../../hooks/company';
import { ExtraPermissionsMapped, PermissionMenu } from '../../types/PermissionMenu';
import { useStyles } from '../../hooks/styles';
import PermissionMenuService from '../../services/PermissionMenuService';
import ModalSuccess from '../../components/ModalSuccess';

export function EditUserProfile() {    
    const { id } = useParams<{id: string}>();

    const history = useHistory();

    const classes = useStyles();
    const { user } = useSelector((state: any) => state.auth);
    const { companies } = useCompany({only: user.companyId});
    const [ company ] = companies;

    const subHeader = useSubheader();
    subHeader.setTitle("Editar Perfil de Usuário");

    const { menus } = useMenu();
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(menus as PermissionMenu[]);
    const [savedPermissions, setSavedPermissions] = useState<Array<number>>([]);
    const [savedExtraMenuPermissions, setSavedExtraMenuPermissions] = useState<ExtraPermissionsMapped[]>([]);

    const [name, setName] = useState("");
    const [nameInvalid, setNameInvalid] = useState(false);

    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if(!menus.length) return;
        if(!user) return;
        if(!company) return;

        const companyPermissions: Array<number> = JSON.parse(company.permissions);
        const filtered = PermissionMenuService.filterCompanyPermissionMenu(menus as PermissionMenu[], companyPermissions);
        let permissions = PermissionMenuService.mapPermissionsChecked(savedPermissions, filtered);
        permissions = PermissionMenuService.mapMenuExtraPermissionsChecked(savedExtraMenuPermissions, permissions);
        setPermissionMenus([...permissions]);
    }, [menus, user, company, savedPermissions, savedExtraMenuPermissions]);

    useEffect(() => {
        getProfile();
    }, []);

    async function getProfile() {
        const response = await api.get<Profile>(`userProfiles/${id}`);

        setName(response.data.name);
        setSavedPermissions(JSON.parse(response.data.permissions));
        setSavedExtraMenuPermissions(JSON.parse(response.data.extraMenuPermissions ?? '[]'));
    }

    function inputsVerify(): boolean {

        if (!name) {
            setIsSubmit(false)
            setMsgError("Preencha o nome corretamente")
            setShowModalError(true);
            setNameInvalid(true);

            return false

        }

        return true

    }

    function handleChangeName(value: string) {
        setName(value);
        setNameInvalid(!value ? true : false);
    }

    const onSubmit = async (evt: any) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!inputsVerify()) return;

        const aux = permissionMenus.map(menu => ({ ...menu }));

        const menusFiltered = PermissionMenuService.filterPermissionMenu(aux);
        const permissions = PermissionMenuService.mapPermissionMenu(menusFiltered);
        const extraPermissions = PermissionMenuService.mapMenuExtraPermissions(menusFiltered);

        const raw = {
            name,
            permissions: JSON.stringify(permissions),
            extraMenuPermissions: JSON.stringify(extraPermissions),
        };

        try {
            await api.put(`userProfiles/${id}`, raw);

            setMsgSuccess('Edição realizada com sucesso!');
            setShowModalSuccess(true);
        } catch (error) {
            setMsgError('Erro ao salvar perfil');
            setShowModalError(true);
        }

        setIsSubmit(false);
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/perfis-usuario"
            />
            <form
                className={'makeStyles-container-12'}
                onSubmit={(evt) => onSubmit(evt)}
            >

                <div className="row">

                    <div className="col-lg-6">
                        <TextField
                            size="small"
                            className={classes.error}
                            label="Nome do Perfil*"
                            margin="normal"
                            variant="outlined"
                            value={name}
                            onChange={(e) => handleChangeName(e.target.value)}
                            error={nameInvalid}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </div>

                    {
                        user.isAccountant == "n"
                        ?
                        <div className="col-lg-6 d-flex align-items-start justify-content-end">
                            <Button
                                type='submit'
                                className='mt-4'
                                variant="primary"
                                disabled={isSubmit}
                            >

                                {isSubmit ? <>

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className='ml-2'>
                                        Aguarde...
                                    </span>

                                </> : <>

                                    <span>
                                        Salvar
                                    </span>

                                </>}


                            </Button>
                        </div>
                        : <></>
                    }

                </div>

                <div className="row">
                    <div className="col-12">
                        <PermissionMenuSelector
                            permissionMenus={permissionMenus}
                            setPermissionMenus={setPermissionMenus}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}