import { LinkedFieldError } from "../types/LinkedFieldError";
import { getDate } from "../utils/dateTimeHelper";
import api from "./Api";
import NotificationService from "./NotificationService";

type DispatchStatusChangeNotificationAndEmailParams = {
    serviceOrderId: number;
    lastStatusHistory: any;
    newStatusHistory: any;
}

class ServiceOrderService {
    verifyLinkedFields(request: any) {
        const errors: LinkedFieldError[] = [];

        if(!request.customerId && request.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: request.customerName,
            });
        }

        const products = JSON.parse(request.products ?? '[]');
        for (const product of products) {
            if(product.name && !product.isLinked) {
                errors.push({
                    fieldName: 'Produto',
                    fieldValue: product.name,
                });
            }
        }

        const services = JSON.parse(request.services ?? '[]');
        for (const service of services) {
            if(service.name && !service.isLinked) {
                errors.push({
                    fieldName: 'Serviço',
                    fieldValue: service.name,
                });
            }
        }

        return errors;
    }

    verifyCustomerLink(request: any) {
        const errors: LinkedFieldError[] = [];

        if(!request.customerId && request.customerName) {
            errors.push({
                fieldName: 'Cliente',
                fieldValue: request.customerName,
            });
        }

        return errors;
    }

    async dispatchStatusChangeNotificationAndEmail({
        serviceOrderId,
        lastStatusHistory,
        newStatusHistory,
    }: DispatchStatusChangeNotificationAndEmailParams) {
        try {
            await api.post(`service-order/statusNotification`, {
                serviceOrderId,
                lastStatusHistory,
                newStatusHistory,
            });
        } catch (error) {
            console.log(error);
        }
    }
}

export default new ServiceOrderService();