class NfeService{

    getFinality(id: string | number ){
        const formatStr = id.toString();

        switch (formatStr) {
            case '1' :
                return 'NF-e normal';
            case '2' :
                return 'NF-e complementar';
            case '3' :
                return 'NF-e de ajuste';
            case '4' :
                return 'Devolução ou retorno';

            default:
                return '';
        };
    };

    getTypeDocument(value: string | number){
        const formatStr = value.toString();

        switch (formatStr) {
            case 'receipt' || '1' :
                return 'Nota Fiscal';
            case 'taxCoupon' || '2' :
                return 'Cupom Fiscal' ;
            case 'ruralProducer' || '3' :
                return 'Produtor Rural';
        
            default :
                return '';
        };
    };

    getType(value: string | number){
        const formatStr = value.toString();

        switch (formatStr) {
            case '0' || 0 :
                return 'Entrada';
            case '1' || 1 :
                return 'Saída';
            case '2' || 2 :
                return 'Exportação'
            case '3' || 3 :
                return 'Importação'

            default :
                return '';
        };
    };

    getTypeAttendance(value: string | number){
        const formatStr = value.toString();

        switch (formatStr) {
            case '0' || 0 :
                return 'Não se aplica';
            case '1' || 1 :
                return 'Operação presencial';
            case '2' || 2 :
                return 'Operação pela internet';
            case '3' || 3 :
                return 'Operação não presencial, Teleatendimento';
            case '4' || 4 :
                return 'NFC-e em operação com entrega em domicílio';
            case '9' || 9 :
                return 'Operação não presencial, Outros';
        
            default :
                return '';
        };
    };

    getModelDocument(value: string | number) {
        const formatStr = value.toString();

        switch (formatStr) {

            case '01' || 1 :            
                return 'Nota Fiscal';
            case '04' || 4 :           
                return 'Nota Fiscal de Produtor';
            case '2B' || 2 + 'B' :
                return 'Cupom Fiscal emitido por máquina registradora (não ECF)';
            case '2C' || 2 + 'C' :
                return 'Cupom Fiscal PDV';
            case '2D' || 2 + 'D' :          
                return 'Cupom Fiscal (emitido por ECF)';

        
            default :
                return '';
        };
    };

    getDestinationOperation(value: string | number){
        const formatStr = value.toString();

        switch (formatStr) {
            case '1' || 1 :
                return 'Operação interna';
            case '2' || 2 :
                return 'Operação interestadual';
            case '3' || 3 :
                return 'Operação com exterior';
            
            default :
                return '';
        };
    };

    getindicator(value: string | number) {
        const formatStr = value.toString();

        switch (formatStr) {
            case '0' || 0 :
                return 'Operação sem Intermediador (em site ou plataforma própria)';
            case '1' || 1 :
                return 'Operação em site ou plataforma de terceiros (intermediadores/marketplace)';
            
            default:
                return '';
        }
    }

    getDefaultEmailMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da sua compra.'
        );
    }

    getDefaultWhatsappMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da sua compra.'
        );
    }
};


export default new NfeService();