import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";

import { NumericFormat } from './NumericFormat';

import '../style.css';
interface SellValueCalculator {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    applySellValue: Function;
}

export const SellValueCalculator: React.FC<SellValueCalculator> = ({ isModalOpen, setIsModalOpen, applySellValue }) => {
    const [buyValue, setBuyValue] = useState("");
    const [sellValue, setSellValue] = useState(0);
    const [aditionalCost, setAdicionalCost] = useState("");
    const [ipiPercentage, setIpiPercentage] = useState("");
    const [icmsPercentage, setIcmsPercentage] = useState("");
    const [icmsstPercentage, setIcmsstPercentage] = useState("");
    const [profitPercentage, setProfitPercentage] = useState("");
    const [freightPercentage, setFreightPercentage] = useState("");

    const [ipiMoney, setIpiMoney] = useState("");
    const [icmsMoney, setIcmsMoney] = useState("");
    const [icmsstMoney, setIcmsstMoney] = useState("");
    const [profitMoney, setProfitMoney] = useState("");
    const [freightMoney, setFreightMoney] = useState("");

    const parcePercentageToNumber = (value: string) => {
        var replaced = value.replace(",", ".");

        return Number(replaced);
    };

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })
    
    useEffect(() => {
        setSellValue(parcePercentageToNumber(buyValue));
    }, [buyValue])

    useEffect(() => {
        const buyValueNumber = parcePercentageToNumber(buyValue);
        const aditionalCostNumber = parcePercentageToNumber(aditionalCost);

        const ipiPercentageNumber = parcePercentageToNumber(ipiPercentage);
        const icmsPercentageNumber = parcePercentageToNumber(icmsPercentage);
        const icmsstPercentageNumber = parcePercentageToNumber(icmsstPercentage);
        const profitPercentageNumber = parcePercentageToNumber(profitPercentage);
        const freightPercentageNumber = parcePercentageToNumber(freightPercentage);

        const aux = buyValueNumber - (buyValueNumber * icmsPercentageNumber / 100);
        const aux2 = aux + (aux * ipiPercentageNumber / 100);
        const aux3 = aux2 + (aux2 * icmsstPercentageNumber / 100);
        const aux4 = aux3 + (aux3 * profitPercentageNumber / 100);
        const aux5 = aux4 + (aux4 * freightPercentageNumber / 100);

        const total = aux5 + aditionalCostNumber;

        setSellValue(total);
        setIcmsMoney(icmsPercentage ? formatDecimal.format(buyValueNumber * (icmsPercentageNumber / 100)) : "");
        setIcmsstMoney(icmsstPercentage ? formatDecimal.format(buyValueNumber * (icmsstPercentageNumber / 100)) : "");
        setFreightMoney(freightPercentage ? formatDecimal.format(buyValueNumber * (freightPercentageNumber / 100)) : "");
        setIpiMoney(ipiPercentage ? formatDecimal.format(buyValueNumber * (ipiPercentageNumber / 100)) : "");
        setProfitMoney(profitPercentage ? formatDecimal.format(buyValueNumber * (profitPercentageNumber / 100)) : "");
    }, [
        ipiPercentage,
        icmsPercentage,
        icmsstPercentage,
        profitPercentage,
        freightPercentage,
    ]);

    useEffect(() => {
        const buyValueNumber = parcePercentageToNumber(buyValue);
        const aditionalCostNumber = parcePercentageToNumber(aditionalCost);

        const ipiMoneyNumber = parcePercentageToNumber(ipiMoney);
        const icmsMoneyNumber = parcePercentageToNumber(icmsMoney);
        const icmsstMoneyNumber = parcePercentageToNumber(icmsstMoney);
        const profitMoneyNumber = parcePercentageToNumber(profitMoney);
        const freightMoneyNumber = parcePercentageToNumber(freightMoney);


        const total = (buyValueNumber - icmsMoneyNumber) + icmsstMoneyNumber + freightMoneyNumber + ipiMoneyNumber + profitMoneyNumber + aditionalCostNumber;
        setSellValue(total);
        setIcmsPercentage(icmsMoneyNumber ? formatDecimal.format((icmsMoneyNumber / buyValueNumber) * 100) : "");
        setIcmsstPercentage(icmsstMoneyNumber ? formatDecimal.format((icmsstMoneyNumber / buyValueNumber) * 100) : "");
        setFreightPercentage(freightMoneyNumber ? formatDecimal.format((freightMoneyNumber / buyValueNumber) * 100) : "");
        setIpiPercentage(ipiMoneyNumber ? formatDecimal.format((ipiMoneyNumber / buyValueNumber) * 100) : "");
        setProfitPercentage(profitMoneyNumber ? formatDecimal.format((profitMoneyNumber / buyValueNumber) * 100) : "");
    }, [
        aditionalCost,
        ipiMoney,
        icmsMoney,
        icmsstMoney,
        profitMoney,
        freightMoney,
    ]);

    return (
        <Modal
            size="xl"
            centered
            show={isModalOpen}
            onHide={() => setIsModalOpen(false)}
            className="newProductWrapper"
        >
            <Modal.Header>
                <Modal.Title>Calcular valor de venda</Modal.Title>
            </Modal.Header>

            <Modal.Body className="pt-0">
                <Row>
                    <Col lg={3}>
                        <NumericFormat
                            label="Valor de compra"
                            className="m-2"
                            startAdornment="R$"
                            value={buyValue}
                            onChange={(evt) => setBuyValue(evt.target.value)}
                        />
                    </Col>

                    <Col lg={9} className="d-flex align-items-center justify-content-end">
                        <Button variant="primary" className="mr-3" onClick={() => {applySellValue(sellValue); setIsModalOpen(false);}}>Aplicar valor de venda</Button>
                        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                            Fechar
                        </Button>
                    </Col>
                </Row>

                <Card className="mt-3">
                    <Card.Body className="p-3">
                        <h5>Encargos de compra:</h5>

                        <Row>
                            <Col lg={2} className="d-flex align-items-center">
                                Crédito ICMS (-)
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="%"
                                    value={icmsPercentage}
                                    onChange={(evt) => setIcmsPercentage(evt.target.value)}
                                />
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="R$"
                                    value={icmsMoney}
                                    onChange={(evt) => setIcmsMoney(evt.target.value)}
                                />
                            </Col>

                            <Col lg={2} className="d-flex align-items-center">
                                ICMSST (+)
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="%"
                                    value={icmsstPercentage}
                                    onChange={(evt) => setIcmsstPercentage(evt.target.value)}
                                />
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="R$"
                                    value={icmsstMoney}
                                    onChange={(evt) => setIcmsstMoney(evt.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={2} className="d-flex align-items-center">
                                Frete (+)
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="%"
                                    value={freightPercentage}
                                    onChange={(evt) => setFreightPercentage(evt.target.value)}
                                />
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="R$"
                                    value={freightMoney}
                                    onChange={(evt) => setFreightMoney(evt.target.value)}
                                />
                            </Col>

                            <Col lg={4} className="d-flex align-items-center">
                                Custo Adicional (+)
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="R$"
                                    value={aditionalCost}
                                    onChange={(evt) => setAdicionalCost(evt.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={2} className="d-flex align-items-center">
                                IPI (+)
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="%"
                                    value={ipiPercentage}
                                    onChange={(evt) => setIpiPercentage(evt.target.value)}
                                />
                            </Col>

                            <Col lg={2}>
                                <NumericFormat
                                    label=""
                                    className="m-2"
                                    startAdornment="R$"
                                    value={ipiMoney}
                                    onChange={(evt) => setIpiMoney(evt.target.value)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row className="pl-4">
                    <Col lg={2} className="d-flex align-items-center">
                        Margem de Lucro (+)
                    </Col>

                    <Col lg={2}>
                        <NumericFormat
                            label=""
                            className="m-2"
                            startAdornment="%"
                            value={profitPercentage}
                            onChange={(evt) => setProfitPercentage(evt.target.value)}
                        />
                    </Col>

                    <Col lg={2}>
                        <NumericFormat
                            label=""
                            className="m-2"
                            startAdornment="R$"
                            value={profitMoney}
                            onChange={(evt) => setProfitMoney(evt.target.value)}
                        />
                    </Col>
                </Row>

                <Row className="pl-4">
                    <Col lg={2} className="d-flex align-items-center">
                        Valor de venda
                    </Col>

                    <Col lg={4}>
                        <NumericFormat
                            label=""
                            startAdornment="R$"
                            className="m-2"
                            value={sellValue}
                            disabled
                        />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};