class NfceService{
    getDefaultEmailMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da sua compra.'
        );
    }

    getDefaultWhatsappMessage() {
        return (
            'Olá, estamos enviando o comprovante fiscal da sua compra.'
        );
    }
};


export default new NfceService();