import { useCallback, useEffect, useState } from "react";
import NotificationService from "../services/NotificationService";

export default function useNotification() {
    const [countNotifications, setCountNotifications] = useState(0);
    const [notifications, setNotifications] = useState<any[]>([]);

    useEffect(() => {
        handleNotifications();
        const interval = setInterval(handleNotifications, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    async function handleNotifications() {
        const {rows, count} = await NotificationService.getNotifications();
        setNotifications(rows);
        setCountNotifications(count);
    }

    const handleDismissNotification = useCallback(async (notificationId: number) => {
        
        setNotifications((prevState) => {
            return prevState.filter((notification) => notification.id !== notificationId);
        });
        setCountNotifications((prevState) => prevState - 1);

        await NotificationService.dismissNotification(notificationId);

        handleNotifications();
    }, []);

    return {countNotifications, notifications, handleDismissNotification};
}