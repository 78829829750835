import React, { useState, useEffect } from 'react';
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    SvgIcon,
} from '@material-ui/core';
import { Button, } from 'react-bootstrap';

type customLabel = {
    id: string,
    tipo: string,
    atividade: string,
    responsaveis: any,
    destino: any,
    alinhamento: string,
    selected?: string,
}

const CustomLabel: React.FC<customLabel> = ({ id, tipo, atividade, responsaveis, destino, alinhamento, selected }) => {
    const icons: { [key: string]: string } = {
        "Início": "M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Z",
        "Atividade": "M19,3H5A5,5,0,0,0,0,8v8a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V8A5,5,0,0,0,19,3Zm3,13a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V8A3,3,0,0,1,5,5H19a3,3,0,0,1,3,3Z",
        "Fim": "m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,16c-2.209,0-4-1.791-4-4s1.791-4,4-4,4,1.791,4,4-1.791,4-4,4Z",
        "Exclusivo": "m22.69,8.831L15.165,1.306C14.32.46,13.196-.005,12.001-.005s-2.319.465-3.165,1.311L1.311,8.831C.466,9.676,0,10.8,0,11.995s.465,2.318,1.31,3.163l7.525,7.525c.845.846,1.969,1.312,3.165,1.312s2.319-.466,3.164-1.312l7.525-7.525c.845-.845,1.311-1.969,1.311-3.163s-.465-2.319-1.311-3.164Zm-.707,5.62l-7.525,7.525c-1.313,1.312-3.603,1.312-4.915,0l-7.525-7.525c-1.354-1.354-1.354-3.559,0-4.913l7.525-7.525c.656-.656,1.529-1.018,2.458-1.018s1.801.361,2.457,1.018l7.525,7.525c1.354,1.354,1.354,3.559,0,4.913Zm-8.983,2.549c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm-1.5-3.5v-7c0-.276.224-.5.5-.5s.5.224.5.5v7c0,.276-.224.5-.5.5s-.5-.224-.5-.5Z",
        "Paralelo": "m22.69,8.831L15.165,1.306C14.32.46,13.196-.005,12.001-.005s-2.319.465-3.165,1.311L1.311,8.831C.466,9.676,0,10.8,0,11.995s.465,2.318,1.31,3.163l7.525,7.525c.845.846,1.969,1.312,3.165,1.312s2.319-.466,3.164-1.312l7.525-7.525c.845-.845,1.311-1.969,1.311-3.163s-.465-2.319-1.311-3.164Zm-.707,5.62l-7.525,7.525c-1.313,1.312-3.603,1.312-4.915,0l-7.525-7.525c-1.354-1.354-1.354-3.559,0-4.913l7.525-7.525c.656-.656,1.529-1.018,2.458-1.018s1.801.361,2.457,1.018l7.525,7.525c1.354,1.354,1.354,3.559,0,4.913Zm-8.983,2.549c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm-1.5-3.5v-7c0-.276.224-.5.5-.5s.5.224.5.5v7c0,.276-.224.5-.5.5s-.5-.224-.5-.5Z",
        "Inclusivo": "m22.69,8.831L15.165,1.306C14.32.46,13.196-.005,12.001-.005s-2.319.465-3.165,1.311L1.311,8.831C.466,9.676,0,10.8,0,11.995s.465,2.318,1.31,3.163l7.525,7.525c.845.846,1.969,1.312,3.165,1.312s2.319-.466,3.164-1.312l7.525-7.525c.845-.845,1.311-1.969,1.311-3.163s-.465-2.319-1.311-3.164Zm-.707,5.62l-7.525,7.525c-1.313,1.312-3.603,1.312-4.915,0l-7.525-7.525c-1.354-1.354-1.354-3.559,0-4.913l7.525-7.525c.656-.656,1.529-1.018,2.458-1.018s1.801.361,2.457,1.018l7.525,7.525c1.354,1.354,1.354,3.559,0,4.913Zm-8.983,2.549c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm-1.5-3.5v-7c0-.276.224-.5.5-.5s.5.224.5.5v7c0,.276-.224.5-.5.5s-.5-.224-.5-.5Z",
        "Arrow": "M17.414,10.586,13.121,6.293,11.707,7.707,15,11H5v2H15l-3.293,3.293,1.414,1.414,4.293-4.293A2,2,0,0,0,17.414,10.586Z",
    };

    return (
        <TableRow
            style={
                selected ?
                    selected == id ?
                        styles.selectedColumn
                        : {}
                    : {}
            }
        >
            <TableCell className='d-flex align-items-center'>
                <SvgIcon className='mr-5' style={{marginLeft: `${alinhamento}px`}}>
                    <path d={icons[tipo]} />
                </SvgIcon>
                <span className={tipo == 'Início'? 'bg-success p-2 rounded text-white' : (tipo === 'Fim'? 'bg-danger p-2 rounded text-white' : (tipo === 'Atividade'? 'bg-info p-2 rounded text-white' : 'bg-warning p-2 rounded text-white'))}>{tipo}</span>
            </TableCell>
            <TableCell>{atividade}</TableCell>
            <TableCell>
                {
                    responsaveis?.map((responsavel: any) =>
                        <span key={responsavel.id}>{responsavel.firstname} </span>
                    )
                }
            </TableCell>
            <TableCell>
                {destino ? (
                    destino.map((item: any, index: number) => (
                        <div key={index} className="destino-item">
                            <SvgIcon className='mr-1'>
                                <path d={icons['Arrow']} />
                            </SvgIcon>
                            <span>{item}</span>
                        </div>
                    ))
                ) : ''}
            </TableCell>
        </TableRow>
    );
};

type solicitationProcess = {
    sortedData?: any,
    current?: string
}

const SolicitationProcess: React.FC<solicitationProcess> = ({ sortedData, current, }) => {    
    return (
        <div className="shadow">
            {
                sortedData ?
                    <Table stickyHeader className="wrap">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo de Atividade</TableCell>
                                <TableCell>Atividade</TableCell>
                                <TableCell>Responsável</TableCell>
                                <TableCell>Destino</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((label: any, index: number) => (
                                <CustomLabel
                                    key={index}
                                    id={label.id}
                                    tipo={label.tipo}
                                    atividade={label.atividade}
                                    responsaveis={label.responsaveis}
                                    destino={label.destino}
                                    alinhamento={label.alinhamento}
                                    selected={current}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    : <></>
            }
        </div>
    );
}

const styles = {
    table: {
        width: "100%",
        border: "1px solid #DCDCDC",
        marginTop: 20,
    },
    selectedColumn: {
        backgroundColor: "#B3E5FC",
    }
}

export default SolicitationProcess;