import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React from "react";

type DynamicClasses = {
    table?: string;
    col?: string;
}

type ReportDataBaseProps = {
    text: string;
    value: string;
}

type ReportDetailsProps = {
    bodyData: ReportDataBaseProps[];
    classes?: DynamicClasses;
}

export const ReportDetails = ({ bodyData, classes }: ReportDetailsProps) => {
    return (
        <div className={ classes?.col ?? '' }>
            <Table className={ classes?.table ?? "border ml-3 mt-0" }>
                <TableBody>
                    {
                        bodyData.map((data, index) => (
                            <TableRow key={ index }>
                                <TableCell colSpan={1}>{ data.text }</TableCell>
                                <TableCell colSpan={1}>{ data.value }</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    );
}