import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { usePdfStyles } from "../../../hooks/pdfStyles";
import { ReportTotals } from "../../../types/ReportData";

type PdfReportTotalsParams = {
    totals: ReportTotals[];
}

export function PdfReportTotals({ totals }: PdfReportTotalsParams) {
    const styles = usePdfStyles();

    return (
        <View style={styles.section}>
            <Text style={styles.titleSection}></Text>

            <View style={styles.rowPayment}>
                <View style={styles.left}>
                    {totals.map((totalData, index) => (
                        <View key={index} style={styles.rowPayment}>
                            <View style={styles.leftPayment}>
                                <Text style={styles.titleInfo}>{totalData.name.toUpperCase()}</Text>
                            </View>
                            <View style={styles.rightPayment}>
                                <Text style={styles.titleInfo}>{totalData.value}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </View>
    );
}