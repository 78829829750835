import { BackendLoadData } from "../types/BackendLoadData";
import api from "./Api";

type GetNotificationsParams = {
    pagination?: BackendLoadData;
    filters?: Filters | null,
    typeUser?: 'admin' | 'user' | 'admin_and_user' | 'accountant' | 'any';
}

type CreateNotificationParams = {
    title: string;
    message?: string;
    link: string;
    showToEveryone: boolean;
    module: string;
    entityType: string;
    entitiesIds: number[];
}

export type Filters = {
    searchQuery: string;
    situation: string;
    initialCreatedDate: string;
    finalCreatedDate: string;
    minValue: number;
    maxValue: number;
}

class NotificationService {
    async getNotifications({
        pagination,
        filters,
        typeUser
    }: GetNotificationsParams = {}) {
        try {
            await this.checkForNotifications();

            const result = await api.get<{ rows: any[], count: number }>("notifications", {
                params: {
                    skip: pagination ? pagination.rowsPerPage * pagination.currentPage : 0,
                    take: pagination?.rowsPerPage ?? 5,
                    filters: filters ? JSON.stringify(filters) : undefined,
                    sortReference: pagination?.sortReference ?? 'date',
                    sortDirection: pagination?.sortDirection ?? 'DESC',
                    typeUser: typeUser ?? 'admin_and_user',
                }
            });
            const { rows, count } = result.data;

            return { rows, count };
        } catch (error) {
            console.log(error);
            return { rows: [], count: 0 };
        }
    }

    async checkForNotifications() {
        try {
            await api.post('notifications/check');
        } catch (error) {
            console.log(error);
        }
    }

    async dismissNotification(notificationId: number) {
        try {
            await api.patch(`notifications/dismiss/${notificationId}`);
        } catch (error) {
            console.log(error);
        }
    }

    async createNotification(params: CreateNotificationParams) {
        try {
            await api.post(`notifications/`, params);
        } catch (error) {
            console.log(error);
        }
    }
}

export default new NotificationService();