import React, { useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    TextField,
    Typography,
    Table,
    TableRow,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    SvgIcon,
} from '@material-ui/core';
import { Button, } from 'react-bootstrap';
import BpmnViewer from '../../../components/BpmnViewer'

type solicitationProcess = { sortedData?: any }

const SolicitationDiagram: React.FC<solicitationProcess> = ({sortedData}) => {
    const [tableData, setTableData] = useState<any>(sortedData);
    const processActivities: any = ["Início", "Fim", "Atividade", "Exclusivo", "Paralelo", "Inclusivo"];
    
    return (
        <>
            <div style={{position: "relative"}}>
                <BpmnViewer
                    tableRows={tableData}
                    setTableRows={setTableData}
                    processActivities={processActivities}
                    displayOnly={true}
                />
            </div>
        </>
    );
}

export default SolicitationDiagram;