import React, { useEffect, useRef, useState } from "react";
import { createSameOriginUrl } from "../utils/createSameOriginUrl";

type DownloadableResourceProps = {
    url: string;
    filename: string;
    onExecuteDownload: () => void;
}

export default function DownloadableResource({ url, filename, onExecuteDownload }: DownloadableResourceProps) {
    const anchorRef = useRef<HTMLAnchorElement>(null);
    const [sameOriginUrl, setSameOriginUrl] = useState('');

    useEffect(() => {
        async function createAndSetSameOriginUrl() {
            if(!url) return;
            
            const sameOriginUrl = await createSameOriginUrl(url);

            setSameOriginUrl(sameOriginUrl);
        }

        createAndSetSameOriginUrl();

        
    }, [url]);

    useEffect(() => {
        if(!anchorRef.current || !sameOriginUrl) return;

        anchorRef.current.click();

        onExecuteDownload();
    }, [sameOriginUrl])

    return (
        <a
            href={sameOriginUrl}
            style={{display: 'none'}}
            ref={anchorRef}
            download={filename}
        ></a>
    );
}