import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { styled } from '@material-ui/styles';
import { Grid, GridProps } from '@material-ui/core';
import { Link } from 'react-router-dom';

import api from '../../services/Api';
import {
    Category,
    ExpenditureAppearInsideDefaultOptions,
} from '../../hooks/parameters';
import { arrangeAlphabetically } from '../../utils/arrangeAlphabetically';
import { useSelector } from 'react-redux';

const GridItem = styled(Grid)<GridProps>(() => ({
    height: '39.38px',
    border: '1px solid #BDBDBD',
    borderTop: 0,
    transition: 'background 0.2s',
    '&:hover': {
        background: "#F9F9F9"
    }
}));


interface ExpenditureListItemProps {
    type: "others" | "expenditure" | "employees" | "admin" | "financial" | "profit";
}


export const ExpenditureListItem: React.FC<ExpenditureListItemProps> = ({ type }) => {
    const { user } = useSelector((state: any) => state.auth);

    
    const [expenditureToShow, setExpenditureToshow] = useState(['']);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        async function loadData() {
            const response = await api.get("categories");

            setCategories(response.data);
        }

        loadData();

        switch(type) {
            case "others":
                const others = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.others);
                setExpenditureToshow(others);
                break;
            case "expenditure":
                const expenditure = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.expenditure);
                setExpenditureToshow(expenditure);
                break;
            case "employees":
                const employees = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.employeesExpenditure);
                setExpenditureToshow(employees);
                break;
            case "admin":
                const admin = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.adminExpenditure);
                setExpenditureToshow(admin);
                break;
            case "financial":
                const financial = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.financialExpenditure);
                setExpenditureToshow(financial);
                break;
            case "profit":
                const profit = arrangeAlphabetically(ExpenditureAppearInsideDefaultOptions.profitDistribuition);
                setExpenditureToshow(profit);
                break;
            default:
                break;
        }
    }, []);

    const handleDelete = async (id: number) => {
        await api.delete(`category/${id}`);

        setCategories((prev) => [...prev.filter((category) => category.id !== id)]);
    };

    const handleHoverDelete = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
        evt.currentTarget.style.transition = 'background 0.2';
        evt.currentTarget.style.background = '#E0E0E0';
    }, []);

    const handleLeaveDelete = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
        evt.currentTarget.style.transition = 'background 0.2';
        evt.currentTarget.style.background = 'transparent';
    }, []);

    return (
        <>
            {
                expenditureToShow.map(expenditure => {
                    const haveInside = categories.find(cat => {
                        if (cat.appearInside.toUpperCase() === expenditure.toUpperCase()) {
                            return cat;
                        }
                    });

                    if (haveInside) {
                        return (
                            <>
                                <GridItem key={expenditure} item>
                                    <a className="btn" style={{ width: '100%', textAlign: 'left' }}>
                                        <span className="p-6 mb-0 ml-12">
                                            {expenditure}
                                        </span>
                                    </a>
                                </GridItem>

                                <GridItem
                                    item
                                    key={haveInside.description}
                                >
                                    <Link to={`/parametros/categorias/${haveInside.id}`} className="btn" style={{ width: '80%', textAlign: 'left' }}>
                                        <span className="p-6 mb-0 ml-20">
                                            {haveInside.description}
                                        </span>
                                    </Link>

                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <button
                                            className="btn font-weight-bold px-3 py-1"
                                            onClick={() => handleDelete(haveInside.id)}
                                            onMouseOver={(evt) => handleHoverDelete(evt)}
                                            onMouseLeave={(evt) => handleLeaveDelete(evt)}
                                        >
                                            x
                                        </button>
                                        : <></>
                                    }
                                </GridItem>
                            </>
                        );
                    }

                    return (
                        <GridItem key={expenditure} item>
                            <a className="btn" style={{ width: '100%', textAlign: 'left' }}>
                                <span className="p-6 mb-0 ml-12">
                                    {expenditure}
                                </span>
                            </a>
                        </GridItem>
                    );
                })
            }
        </>
    );
}