import api from "./Api";

type ServiceContractWhatsappData = {
    serviceContractId: string|number;
    customerId: string;
    customerName: string;
    refName: string;
    message: string;
    link?: string;
    attachment?: string;
    companyId: number;
}

class WhatsappServiceContractService {
    async getAllWhatsappsByServiceContractId(serviceContractId: number | string) {
        try {
            const { data } = await api.get<any[]>(`whatsapp-service-contract/serviceContract/${serviceContractId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendWhatsapp(number: string, {
        serviceContractId,
        customerId,
        customerName,
        refName,
        message,
        link,
        attachment,
        companyId,
    }: ServiceContractWhatsappData) {
        let file = undefined;
        if (attachment) {
            file = {
                urlFile: link,
                fileName: attachment ?? '',
            };
        }

        const raw = {
            number: number,
            name: refName,
            id: serviceContractId,
            customerId: customerId,
            nameCustomer: customerName,
            message: message,
            link: link,
            companyId: companyId,
            file,
            type: 'serviceContract',
        };

        await api.post('whatsapp-service-contract/send', raw);
    };
}

export default new WhatsappServiceContractService();