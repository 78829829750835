import { BankAccount } from "../types/BankAccount";
import { bankNumberIsInter } from "../utils/bankNumberIsInter";
import api from "./Api";

class BankAccountService {
    async getBankAccountById(id: string | number) {
        const bankAccountResponse = await api.get<BankAccount>(`/accountBank/${id || '0'}`);

        return bankAccountResponse.data;
    }

    async getBankAccountsFiltered(filters: any) {
        const bankAccountResponse = await api.get<BankAccount[]>('/accountsBank', { params: { filters } });

        return bankAccountResponse.data;
    }

    async getDefaultBankAccounts() {
        const bankAccountResponse = await api.get<{ standardAccountForRecipes: BankAccount, standardExpenseAccount: BankAccount }>('/accountBank/default/all');

        return bankAccountResponse.data;
    }

    async validateBilletRequiredInformation(bankAccount: BankAccount) {
        const bankIsInter = bankNumberIsInter(bankAccount.numberBank);

        if (bankIsInter) {
            try {
                await api.get(`accountBank/interBilletVerification/${bankAccount.id}`);

                return '';
            } catch (error: any) {
                return error?.response?.data?.message ? error.response.data.message : 'Não foi possível estabelecer conexão com os serviços do Banco Inter, verifique as configurações da conta bancária';
            }
        } else {
            if (!bankAccount.shipmentSequence || !bankAccount.billetSequence) {
                return 'Informe a sequência da Remessa e boleto da conta bancária!';
            }
        }

        return '';
    }

    getFormSteps() {
        return ['Selecione um banco para cadastrar a conta bancária', 'Create an ad group', 'Create an ad'];
    }
}

export default new BankAccountService();