import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { Button, Modal } from 'react-bootstrap';
import { TextField, MenuItem } from '@material-ui/core';

type Category = {
    id: number,
    name: string,
    type: string,
    editable: boolean,
    active: boolean,
    subCategories: SubCategory[]
}

type SubCategory = {
    id: number,
    dreCategoryId: number,
    name: string,
    type: string,
    active: boolean,
    editable: boolean
}

export type ModalType = {
    modalSubEdit: boolean
    setModalSubEdit: React.Dispatch<React.SetStateAction<boolean>>

    idSubEdit: number
    setIdSubEdit: React.Dispatch<React.SetStateAction<number>>

    nameSubEdit: string
    setNameSubEdit: React.Dispatch<React.SetStateAction<string>>

    dreCategoryIdSubEdit: number
    setDreCategoryIdSubEdit: React.Dispatch<React.SetStateAction<number>>

    listCategoriesSubEdit: Category[]

    getCategoriesRevenue: () => void
    getCategoriesExpense: () => void
}

export default function ModalSubEdit(props: ModalType) {

    async function edit() {
        await api.put(`/dre/editSub/` + props.idSubEdit, {
            name: props.nameSubEdit,
            dreCategoryId: props.dreCategoryIdSubEdit
        })
        .then(response => {
            props.getCategoriesRevenue()
        })
        .catch(error => {
            console.log('Ocorreu algum erro')
        })
        .finally(() => {
            props.setModalSubEdit(false)
        })
    }

    return (
        <Modal
            show={props.modalSubEdit}
            onHide={() => props.setModalSubEdit(false)}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Editar Sub-Categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    size="small"
                    label="Nome da categoria*"
                    margin="normal"
                    variant="outlined"
                    value={props.nameSubEdit}
                    onChange={(e) => props.setNameSubEdit(e.target.value)}
                />
                <TextField
                        size="small"
                        select
                        label="Aparecer dentro da categoria*"
                        margin="normal"
                        variant="outlined"
                        value={props.dreCategoryIdSubEdit}
                        onChange={(e) => props.setDreCategoryIdSubEdit(Number(e.target.value))}
                    >
                        { props.listCategoriesSubEdit.map((category) => 
                            <MenuItem key={category.id} value={category.id}>{ category.name }</MenuItem>)
                        }
                    </TextField>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => props.setModalSubEdit(false)}>Cancelar</Button>
                <Button variant="primary" type="button" onClick={edit}>Salvar</Button>
            </Modal.Footer>
        </Modal>
    )
}