import { StockLocations } from "../types/StockLocations";
import api from "./Api";

class StockLocationService {
    async getStockLocationById(id: string | number) {
        const stockResponse = await api.get<StockLocations>(`/stockLocation/${id || '0'}`);

        return stockResponse.data;
    }

    async getStockLocationsFiltered(filters: any) {
        const stockResponse = await api.get<StockLocations[]>('/stockLocation', { params: { filters } });

        return stockResponse.data;
    }
}

export default new StockLocationService();