import { BankAccount } from "../types/BankAccount";
import { ProcessBpm } from "../types/ProcessBpm";
import { bankNumberIsInter } from "../utils/bankNumberIsInter";
import api from "./Api";

class ProcessBpmService {
    async getProcessById(id: string | number) {
        const bankAccountResponse = await api.get<ProcessBpm>(`/accountBank/${id || '0'}`);

        return bankAccountResponse.data;
    }
    
    async getProcessFiltered(filters: any) {
        const processResponse = await api.get<ProcessBpm[]>('/bpm/process/displayAll', { params: { filters } });

        return processResponse.data;
    }
}

export default new ProcessBpmService();