import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Product as ProductHook } from '../../../hooks/products';
import ProductSelect from '../../../components/ProductSelect';
import { Product as SelectableProduct } from '../../../services/ProductService';
import { formatToFloat } from '../../../utils/formatCurrency';
import { NewProductModal } from '../../../components/Product/NewProductModal';
import { Product } from '../../../types/Product';
import { Product as GeneralProduct } from '../../../types/Product';
import useNewProductForm from '../../../hooks/newProductForm';
import { SellerConsignmentProduct } from '../../../types/SellerConsignment';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { StockLocations } from '../../../types/StockLocations';
import StockLocationService from '../../../services/StockLocationService';

export type ListConsignmentProductsProps = {
    consignmentProducts: SellerConsignmentProduct[];
    setConsignmentProducts: React.Dispatch<React.SetStateAction<SellerConsignmentProduct[]>>;
    consignmentIsFinished?: boolean;
};

export function ListConsignmentProducts({
    consignmentProducts,
    setConsignmentProducts,
    consignmentIsFinished = false,
}: ListConsignmentProductsProps) {
    const { user } = useSelector((state: any) => state.auth);

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: SellerConsignmentProduct } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        selectProduct(selectableProduct, productBeingCreated.index)
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: SellerConsignmentProduct) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    function addProducts() {
        var productsArray = consignmentProducts;

        productsArray.push({
            id: 0,
            productId: 0,
            amount: 0,
            stockLocationId: 0,
            INSTANCE: {} as SelectableProduct,
        });

        setConsignmentProducts([...productsArray]);
    }

    function deleteProduct(index: number) {
        var productsArray = consignmentProducts;
        productsArray.splice(index, 1);
        setConsignmentProducts([...productsArray]);
    }

    const selectProduct = useCallback(async (value: any, index: number) => {
        var productArray = consignmentProducts;

        if (value) {
            var productsInKit = value.kit;
            var type = value.type;

            if (value.isKit == "y") {
                type = "kit";
            } else if (value.hasVariation == "y") {
                type = "variation";
            }

            productArray[index].productId = value.id;
            productArray[index].amount = 1;
            productArray[index].stockLocationId = 0;
            productArray[index].INSTANCE = {
                id: value.id,
                name: value.name,
                code: value.code,
                saleValue: value.saleValue,
                codeGtinEan: value.codeGtinEan,
                stockInitial: value.stockInitial,
                currentStock: formatToFloat(value.currentStock),
                hasVariation: value.hasVariation,
                isKit: value.isKit,
                kit: productsInKit,
                type: type,
                originalProductId: value.originalProductId ?? undefined,
            };

        } else {
            productArray[index].productId = 0;
            productArray[index].amount = 0;
            productArray[index].stockLocationId = 0;
            productArray[index].INSTANCE = {
                id: 0,
                name: "",
                code: "",
                saleValue: 0,
                codeGtinEan: "",
                stockInitial: "",
                currentStock: 0,
                hasVariation: "",
                isKit: "",
                kit: "",
                type: "product"

            };
        }

        setConsignmentProducts([...productArray]);
    }, [consignmentProducts]);

    function changeAmount(value: any, index: number) {
        var productArray = consignmentProducts;
        productArray[index].amount = Number(value);

        setConsignmentProducts([...productArray]);
    }
    async function changeStockLocationId(value: any, index: number) {
        var productArray = consignmentProducts;

        productArray[index].stockLocationId = Number(value);

        setConsignmentProducts([...productArray]);
    }

    return (
        <>
            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />
            <div className="row">
                <div className="col">
                    {
                        consignmentProducts.map((product, index: number) => {
                            return (
                                <div className="row p-1" key={index}>
                                    <div className="col-lg-6 d-flex align-items-center ">
                                        <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                                            <label className="text-white m-0">{index + 1}.</label>

                                            {
                                                product.INSTANCE.isKit == "y"
                                                    ?
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id='tooltip-top'>
                                                                <b>Produtos do Kit</b><br />
                                                                {product.INSTANCE.kit}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            size="sm"
                                                            variant="primary"
                                                            className="d-flex flex-row align-items-center justify-content-center ml-2 p-0"
                                                            style={{ padding: "5px", background: "transparent" }}
                                                        >
                                                            <i className="flaticon2-information m-0 p-0"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    :
                                                    <></>
                                            }
                                        </div>

                                        <ProductSelect
                                            label="Produto *"
                                            selectedProduct={product}
                                            onChangeProduct={(prod) => selectProduct(prod, index)}
                                            type="product"
                                            disabled={consignmentIsFinished || user.isAccountant === 'y'}
                                            onClickAddProduct={(typedText) => handleClickAddProduct(typedText, index, product)}
                                        />
                                    </div>

                                    <div className="col-lg-2 p-1">
                                        <TextField
                                            type="number"
                                            size="small"
                                            label="Qtde. *"
                                            margin="normal"
                                            variant="outlined"
                                            name="amount"

                                            value={product.amount}
                                            onChange={(evt) => changeAmount(evt.target.value, index)}
                                            disabled={consignmentIsFinished || user.isAccountant === 'y'}
                                        />
                                    </div>

                                    <div className="col-lg-2 p-1">
                                        <ApiResourceSelect
                                            style={{ width: '100%' }}
                                            label="Local de estoque *"
                                            getOptionLabel={(option: StockLocations) => option.name}
                                            value={product.stockLocationId}
                                            onSelect={(option) => changeStockLocationId(option?.id ?? 0, index)}
                                            apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!product.stockLocationId) return null;
                                                return loadedOptions.find((option) => option.id === product.stockLocationId) ?? StockLocationService.getStockLocationById(product.stockLocationId)
                                            }}
                                            disabled={consignmentIsFinished || user.isAccountant === 'y'}
                                        />
                                    </div>

                                    <>
                                        {
                                            (index > 0 && user.isAccountant == "n" && !consignmentIsFinished) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-link"
                                                    onClick={() => deleteProduct(index)}
                                                    title="Remover"
                                                >
                                                    <i className="flaticon-delete"></i>
                                                </button>
                                            )
                                        }
                                    </>
                                </div>
                            );
                        })
                    }
                </div>

                {
                    user.isAccountant == "n" && !consignmentIsFinished
                        ?
                        <div className="row col-lg-12 mt-3 ml-2">
                            <button
                                type="button"
                                className="btn btn-link-secondary p-0"
                                onClick={() => addProducts()}
                            >
                                <ins>+ adicionar outro produto</ins>
                            </button>
                        </div>
                        : <></>
                }
            </div>
        </>
    );
}