import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditNfse } from '../pages/Nfse/Edit-Nfse';
import { ListNfse } from '../pages/Nfse/List-Nfse';
import { NewNfse } from "../pages/Nfse/New-Nfse";

export function Nfse() {
    
    return (
        <Switch>
            {/* <Route path="/notas-fiscais-servico" component={List}/> */}
            <Route path="/notas-fiscais-servico/:servideOrderId/adicionar" component={NewNfse}/>
            <Route path="/notas-fiscais-servico/adicionar" component={NewNfse}/>
            <Route path="/notas-fiscais-servico/duplicar/:idToClone" component={NewNfse}/>
            <Route path="/notas-fiscais-servico/:id" component={EditNfse}/>
            <Route path="/notas-fiscais-servico" component={ListNfse}/>
        </Switch>
    );
}