import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
    Line
} from "@react-pdf/renderer";
import { usePdfStyles } from "../../../hooks/pdfStyles";
import Table from "../Table";
import TableRow from "../TableRow";
import TableCell from "../TableCell";
import { BodyDataBaseProps, HeadDataBaseProps } from "../../ListWithModalChangeSituation";
import { ReportDataRow } from "../../../types/ReportData";

type PdfReportBodyParams = {
    bodyData: ReportDataRow[];
    headData: HeadDataBaseProps[];
}

export function PdfReportBody({ bodyData, headData, }: PdfReportBodyParams) {
    const styles = usePdfStyles();

    const today = new Date().toLocaleDateString();

    function buildTable({ bodyData, headData, subRow = false }: {
        bodyData: ReportDataRow[];
        headData: HeadDataBaseProps[];
        subRow?: boolean;
    }) {
        const tableStyle = subRow ? {paddingTop: 4} : undefined;
        const rowStyle = subRow ? styles.flexRowSub : styles.flexRow;
        const cellStyle = subRow ? styles.flexCellSub : styles.flexCell;

        return (
            <>
                <Table style={tableStyle}>
                    <TableRow style={rowStyle}>
                        {headData.map((head) => (
                            <TableCell key={head.reference} style={cellStyle}>
                                {head.value.toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </Table>

                <Table style={{ paddingTop: 0, }}>
                    {
                        bodyData.map((row, indexRow: number) => {
                            return (
                                <React.Fragment key={indexRow}>
                                    <View style={rowStyle}>
                                        {row.cells.map((cell, indexCell) => {
                                            return (
                                                <Text key={indexCell} style={cellStyle}>
                                                    {cell.content}
                                                </Text>
                                            );
                                        })}
                                    </View>
                                    {!!row.subRow && row.subRow.bodyData.length > 0 && (
                                        buildTable({
                                            headData: row.subRow.headData,
                                            bodyData: row.subRow.bodyData,
                                            subRow: true
                                        })
                                    )}
                                </React.Fragment>
                            )
                        })
                    }
                </Table>
            </>
        )
    }

    return (
        <View style={styles.section}>
            {buildTable({bodyData, headData})}
        </View>
    );
}