import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditCompany } from '../pages/Company/Edit-Company';
import { ListCompany } from '../pages/Company/List-Company';
import { NewCompany } from '../pages/Company/New-Company';

export function Company() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Empresas");
    
    return (
        <Switch>
            <Route path="/empresas/adicionar" component={NewCompany}/>
            <Route path="/empresas/:id" component={EditCompany}/>
            <Route path="/empresas" component={ListCompany}/>
        </Switch>
    );
}