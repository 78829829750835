import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {ListExtract} from '../pages/List-Extract';
import {NewExtract} from '../pages/New-Extract';

export function Extract() {
    
    return (
        <Switch>
            <Route path="/extrato/adicionar" component={NewExtract}/>
            <Route path="/extrato" component={ListExtract}/>
        </Switch>
    );
}