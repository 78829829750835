import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { Button, Modal } from 'react-bootstrap';

export type ModalType = {
    modalDelete: boolean
    setModalDelete: React.Dispatch<React.SetStateAction<boolean>>

    idDelete: number
    setIdDelete: React.Dispatch<React.SetStateAction<number>>

    nameDelete: string
    setNameDelete: React.Dispatch<React.SetStateAction<string>>

    type: string

    getCategoriesRevenue: () => void
    getCategoriesExpense: () => void
}

export default function ModalDelete(props: ModalType) {

    async function deleteCategory() {
        await api.put(`/dre/delete/` + props.idDelete, {type: props.type == "revenue" ? "revenue_dre_sub_category" : "expense_dre_sub_category"})
        .then(response => {
            props.getCategoriesRevenue()
            props.getCategoriesExpense()
        })
        .catch(error => {
            console.log('Ocorreu algum erro')
        })
        .finally(() => {
            props.setModalDelete(false)
        })
    }

    return (
        <Modal
            show={props.modalDelete}
            onHide={() => props.setModalDelete(false)}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Excluir Categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Voce realmente deseja excluir a categoria <b>{props.nameDelete}</b> ?<br />
                <b>OBS:</b> Tambem sera excluido as sub-categorias existentes aqui
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => props.setModalDelete(false)}>Cancelar</Button>
                <Button variant="danger" type="button" onClick={deleteCategory}>Excluir</Button>
            </Modal.Footer>
        </Modal>
    )
}