import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";

import contactService from "../../../services/ContactService";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";

type ContactsTableParams = {
    contacts: any[];
};

export function ContactsTable({ contacts }: ContactsTableParams) {
    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Celular</TableCell>
                    <TableCell>Email</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {contacts.map((contact) => (
                    <TableRow key={contact.id}>
                        <TableCell>{contactService.getTypeContactText(contact.typeContact)}</TableCell>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{formatPhoneNumber(contact.phone)}</TableCell>
                        <TableCell>{formatPhoneNumber(contact.cell)}</TableCell>
                        <TableCell>{contact.email}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}