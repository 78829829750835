import api from "../services/Api";
import BillToReceiveService from "../services/BillToReceiveService";
import { BillsToReceive } from "../types/BillsToReceive";

export type BillEmailData = {
    bill: BillsToReceive;
    customerId: string;
    customerName: string;
    contactName: string;
    subject: string;
    refName: string;
    message: string;
}

async function sendBillEmailToCustomer(emails: string[], {
    bill,
    customerId,
    customerName,
    contactName,
    subject,
    refName,
    message,
}: BillEmailData) {
    var file = undefined;

    if(bill && bill.recordType === 'billet') {
        file = {
            urlFile: await BillToReceiveService.getBilletFileUrl(bill),
            fileName: `Conta-a-receber-${bill.id}.pdf`,
        }
    }

    const raw = {
        to: emails,
        subject: subject,
        name: refName,
        id: bill.id,
        nameContact: contactName,
        nameCustomer: customerName,
        message: message,
        file,
        paymentMethod: bill.payment,
        amount: bill.remaining,
        comments: bill.comments || undefined,
        companyId: bill.companyId,
        dueDate: bill.dueDate,
    };

    await api.post('email-bill/send', raw);
};

export { sendBillEmailToCustomer }