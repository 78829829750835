import React, { createContext, useContext, useState, Dispatch, SetStateAction, useCallback } from 'react';

import api from '../services/Api';


export type Category = {
    id: number;
    description: string;
    financialGroup: string;
    appearInside: string;
    visibilityDRE: "y" | "n";
    typeCategory: "revenue" | "expenditure";
}

export type CenterCost = {
    id: number;
    name: string;
    status: "active" | "inactive";
}


export const RevenueAppearInsideDefaultOptions = {
    others: [
        "Ajuste de Caixa",
        "Ajuste Saldo Inicial",
        "Aplicações Financeiras",
        "Descontos Recebidos",
        "Devolução de Adiamento",
        "Juros/Multas Recebidas",
        "Outras Receitas",
        "Saldo Inicial",
        "Serviços",
        "Transferência de Entrada",
        "Vendas",
    ],
    revenueWithNoFinancialGroup: [
        "Financiamentos e Investimentos",
    ],
    profitRetention: [
        "Retenção de Lucros",
    ],
};

export const ExpenditureAppearInsideDefaultOptions = {
    expenditure: [
        "Combustivel e Translados",
        "Energia Elétrica + Água",
        "Manutenção Equipamentos",
        "Comissão Vendedores",
        "Fornecedor",
        "Impostos - ICMS",
        "Aquisição de Equipamentos",
        "Impostos - DAS",
        "Insumos de Produção",
    ],
    employeesExpenditure: [
        "Confraternizações",
        "Cursos e Treinamentos",
        "Encargos Funcionários - 13o Salário",
        "Encargos Funcionários - Alimentação",
        "Encargos Funcionários - Assist. Médica e Odontol.",
        "Encargos Funcionários - Exames Pré e Demissionais",
        "Encargos Funcionários - FGTS",
        "Encargos Funcionarios - Horas Extras",
        "Encargos Funcionários - Vale Transporte",
        "Encargos Funcionários - Rescisões Trabalhistas",
        "Adiantamento - Funcionários",
        "Encargos Funcionários - Sindicato",
        "Encargos Funcionários - Salário",
        "Prolabore",
    ],
    adminExpenditure: [
        "Aluguel",
        "Assessorias e Associações",
        "Cartório",
        "Correios",
        "Impostos - Alvará",
        "Impostos - Coleta de Lixo",
        "Impostos - IPTU",
        "Licença ou Aluguel de Softwares",
        "Limpeza",
        "Marketing e Publicidade",
        "Material de Escritório",
        "Segurança",
        "Supermercado",
        "Telefonia e Internet",
        "Transportadora",
        "Viagens",
        "Impostos - Importação IPI",
        "Custos de Serviços",
        "Amortizações",
    ],
    financialExpenditure: [
        "Contabilidade",
        "Encargos Funcionários - INSS",
        "Impostos - PIS",
        "Impostos - Cofins",
        "Impostos - CSLL",
        "Impostos - IRPJ",
        "Impostos - ISS",
    ],
    profitDistribuition: [
        "Distribuição de Lucros",
    ],
    others: [
        "Ajuste Caixa",
        "Transferência de Saída",
        "Impostos - IOF",
        "Empréstimos",
        "Material Reforma",
        "Outras Despesas",
        "Compras Cartão de Crédito",
        "Financiamentos e Investimentos",
        "Depreciação",
        "Credito Troca",
    ],
};

export const RevenueFinancialGroupDefaultOptions = [
    'Outras Receitas',
    'Receita Operacional Bruta',
    'Receitas Financeiras',
    'Retenção de Lucros',
];

export const ExpenditureFinancialGroupDefaultOptions = [
    'Custos',
    'Deduções',
    'Despesas administrativas',
    'Despesas com pessoal',
    'Despesas financeiras',
    'Distribuição de lucros',
    'Outras despesas',
    'Provisão para imposto de renda e contribuição social sobre lucro',
];


interface ParameterContextData {
    categories: Category[];
    revenueNames: string[];
    centerCosts: CenterCost[];
    expenditureNames: string[];
    centerCostsNames: string[];
    setCategories: Dispatch<SetStateAction<Category[]>>
    handleDelete: (id: number) => Promise<void>;
}


const ParameterContext = createContext<ParameterContextData>({} as ParameterContextData);

const ParameterProvider: React.FC = ({ children }) => {
    const [revenueNames] = useState(['']);
    const [categories, setCategories] = useState<Category[]>([]);
    const [centerCostsNames] = useState(['']);
    const [expenditureNames] = useState(['']);
    const [centerCosts] = useState<CenterCost[]>([]);

    const handleDelete = useCallback(async (id: number) => {
        await api.delete(`category/${id}`);

        setCategories((state) => [...state.filter(category => category.id === id)]);
    }, [api, categories]);

    return (
        <ParameterContext.Provider value={{
            categories,
            handleDelete,
            setCategories,
            centerCosts,
            centerCostsNames,
            expenditureNames,
            revenueNames
        }}>
            { children }
        </ParameterContext.Provider>
    );
}

const useParameters = () => {
    const context = useContext(ParameterContext);

    if (!context) {
        throw new Error("The method useParameters must be used within a ParameterProvider!");
    }

    return context;
}

export { ParameterProvider, useParameters };
