import React, { useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    Card,
    Modal,
    Button,
    Spinner,
    Tabs,
    Tab,
} from "react-bootstrap";
import { Autocomplete } from '@material-ui/lab';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import api from '../../services/Api';
import logService from '../../services/LogService';
import UserService from '../../services/UserService';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { yesOrNo } from '../../types/yesOrNo';
import ModalSuccess from '../../components/ModalSuccess';
import { useStyles } from '../../hooks/styles';
import { Seller } from '../../types/Seller';

type Inputs = {
    typePeople: string,
    cpf: string,
    name: string,
    cnpj: string,
    corporateName: string,
    fantasyName: string,
    zipCode: string,
    address: string,
    number: string,
    district: string,
    complement: string,
    city: string,
    state: string,
    contactPeople: string,
    phone: string,
    extension: string,
    fax: string,
    cell: string,
    email: string,
    webSite: string,
    bank: string,
    agency: string,
    currentAccount: string,
    salary: string,
    sellerCommission: string,
    userId: string,
    situation: string,
    note: string
}

export function NewSeller() {
    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Adicionar um Vendedor");

    const [isSubmit, setIsSubmit] = useState(false);


    const [activeTab, setActiveTab] = useState("details");
    const [nameInvalid, setNameInvalid] = useState(false);
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
    const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [userIdInvalid, setUserIdInvalid] = useState(false);

    const [typePeople, setTypePeople] = useState("legal");
    const [cpf, setCpf] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [name, setName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [fantasyName, setFantasyName] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [contactPeople, setContactPeople] = useState("");
    const [phone, setPhone] = useState("");
    const [extension, setExtension] = useState("");
    const [fax, setFax] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [webSite, setWebSite] = useState("");
    const [bank, setBank] = useState("");
    const [agency, setAgency] = useState("");
    const [currentAccount, setCurrentAccount] = useState("");
    const [salary, setSalary] = useState("");
    const [sellerCommission, setSellerCommission] = useState("");
    const [userId, setUserId] = useState("");
    const [isConsignment, setIsConsignment] = useState<yesOrNo>(yesOrNo.NO);
    const [situation, setSituation] = useState("active");
    const [note, setNote] = useState("");

    const [allStates, setAllStates] = useState({ states: [] });

    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [nameCnpj, setNameCnpj] = useState("");
    const [fantasy, setFantasy] = useState("");

    const { register, handleSubmit, control } = useForm<Inputs>();
    const history = useHistory();

    useEffect(() => {
        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: statesBrazil.data });
        }

        getStates();
    }, []);

    function infoAddress(zipCodeCnpj?: string) {
        const checkCep = async () => {
            var zipCodeChanged = "";

            if (zipCodeCnpj) {
                zipCodeChanged = zipCodeCnpj.replace(/[^0-9]/g, "");

            } else {
                zipCodeChanged = zipCode.replace(/[^0-9]/g, "");
            }


            const cep = await axios.get(`https://viacep.com.br/ws/${zipCodeChanged}/json/`);
            setAddress(cep.data.logradouro);
            setDistrict(cep.data.bairro);
            setCity(cep.data.localidade);
            setState(cep.data.uf);
        }
        checkCep();
    }

    async function CheckCnpj(value: string) {
        const cnpjFormated = value.replace(/[^0-9]/g, "");

        var response = await axios.get(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${cnpjFormated}`);

        if (response.data.status === 'ERROR') {
            setMsgError(response.data.message);
            setShowModalExist(true);
            setCnpjInvalid(true);
            setCnpj('');
            return;
        }

        setCorporateName(response.data.nome);
        setFantasyName(response.data.fantasia);

        setZipCode(response.data.cep);
        setAddress(response.data.logradouro);
        setDistrict(response.data.bairro);
        setCity(response.data.municipio);
        setState(response.data.uf);
        setNumber(response.data.numero);
        setComplement(response.data.complemento);

        infoAddress(response.data.cep);
    }

    async function checkUserSellerExist() {
        // if (userId) {
        //     var response = await api.get<Seller[]>(`/sellers/userIdExist/${userId}`);
        //     if (response.data.length > 0) {
        //         return false;
        //     }
        // }

        return true;
    }

    async function checkEmailSellerExist() {
        // if (email) {
        //     var response = await api.get(`/sellers/emailExist/${email}`);
        //     if (response.data.length > 0) {
        //         return false;
        //     }
        // }

        return true;
    }

    async function checkCpfSellerExist() {
        // if (cpf) {
        //     var cpfFormated = cpf.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/sellers/cpfExist/${cpfFormated}`);
        //     if (response.data.length > 0) {
        //         setMsgError("Cpf informado já existe!");
        //         setShowModalExist(true);
        //         setCpf("");
        //     }
        // }
    }

    async function checkCnpjSellerExist() {
        // if (cnpj) {
        //     var cnpjormated = cnpj.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/sellers/cnpjExist/${cnpjormated}`);
        //     if (response.data.length > 0) {
        //         setMsgError("Oops, CNPJ já foi cadastrado!");
        //         setShowModalExist(true);
        //         setCnpj("");
        //     }
        // }
        // CheckCnpj(cnpj);
    }

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "phone" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if ("email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    async function inputsVerify(): Promise<boolean> {

        if (typePeople === "physical") {

            if (!cpf || sanitizeInput("cpf", cpf).length !== 11) {
                setMsgError("Preencha o cpf corretamente");
                setShowModalExist(true);
                setCpfInvalid(true);
                return false;

            } else if (!name) {
                setMsgError("Preencha o nome corretamente");
                setShowModalExist(true);
                setNameInvalid(true);
                return false;
            }

        } else if (typePeople === "legal") {

            if (!cnpj || sanitizeInput("cnpj", cnpj).length !== 14) {
                setMsgError("Preencha o CNPJ corretamente");
                setCnpjInvalid(true);
                return false;
            } else if (!corporateName) {
                setMsgError("Preencha a razão social da empresa");
                setCorporateNameInvalid(true);
                return false;
            } else if (!fantasyName) {
                setMsgError("Preencha o nome fantasia da empresa");
                setFantasyNameInvalid(true);
                return false;
            }
        }

        if (!phone || sanitizeInput("phone", phone).length !== 10) {
            setActiveTab("details");
            setMsgError("Preencha o telefone corretamente");
            setPhoneInvalid(true);
            return false;

        }

        if (!email || sanitizeInput("email", email) === "false") {
            setActiveTab("details");
            setMsgError("Preencha o email corretamente");
            setEmailInvalid(true);
            return false;
        }

        // if (!(await checkEmailSellerExist())) {
        //     setMsgError("Este email já está cadastrado!");
        //     setEmailInvalid(true);
        //     return false;
        // }

        if (isConsignment === yesOrNo.YES && !userId) {
            setActiveTab("additional-info");
            setMsgError("Para definir que o vendedor realiza venda consignada é preciso vincular a um usuário!");
            setUserIdInvalid(true);
            return false;
        }

        if (userId && !(await checkUserSellerExist())) {
            setActiveTab("additional-info");
            setMsgError("Não é possível vincular ao usuário selecionado pois ele já está vinculado à outro vendedor!");
            setUserIdInvalid(true);
            return false;
        }

        return true;

    }

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            let cpfFormated = cpf.replace(/[^0-9]/g, "");
            let cnpjFormated = cnpj.replace(/[^0-9]/g, "");

            setIsSubmit(true);

            const isValid = await inputsVerify();

            if (!isValid) {
                setShowModalExist(true);
                setIsSubmit(false);
                return;
            };

            const raw = {
                typePeople: typePeople,
                cpf: cpfFormated ? cpfFormated : null,
                name: name,
                cnpj: cnpjFormated ? cnpjFormated : null,
                corporateName: corporateName,
                fantasyName: fantasyName,
                zipCode: zipCode,
                address: address,
                number: data.number,
                district: district,
                complement: data.complement,
                city: city,
                state: state,
                contactPeople: data.contactPeople,
                phone: phone,
                extension: data.extension,
                fax: data.fax,
                cell: data.cell,
                email: email,
                webSite: data.webSite,
                bank: data.bank,
                agency: data.agency,
                currentAccount: data.currentAccount,
                salary: data.salary,
                sellerCommission: sellerCommission,
                userId: userId ? Number(userId) : null,
                isConsignment: isConsignment,
                situation: situation,
                note: data.note
            };

            const createResponse = await api.post("sellers", raw);

            logService.logRegister({
                itemId: createResponse.data.id,
                module: 'Vendedores',
                itemName: createResponse.data.typePeople === 'physical' ? createResponse.data.name : createResponse.data.corporateName,
            });

            setShowModalSuccess(true);
        } catch (error: any) {
            setShowModalExist(true);
            setMsgError('Ocorreu um erro ao processar a requisição');
        }

        setIsSubmit(false);
    }

    function formatToFloat(value: string) {
        if (value.includes(',') && !value.includes('.')) {
            return parseFloat(value.replace(',', '.'));

        } else {
            return parseFloat(value.replace('.', '').replace(',', '.'));
        }
    }

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })

    return (
        <div className="row card card-body pt-2 newProductWrapper">
            <ModalSuccess
                msgModal="Vendedor cadastrado com sucesso!"
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/vendedores"
            />

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">

                <div className="col-lg-12">
                    <Grid container spacing={3}>
                        <Grid item lg={2}>
                            <TextField
                                size="small"
                                select
                                label="Tipo de pessoa"
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                value={typePeople}
                                onChange={(e) => {
                                    setTypePeople(e.target.value);

                                    setCpf("");
                                    setName("");

                                    setCnpj("");
                                    setCorporateName("");
                                    setFantasyName("");
                                }}
                            >
                                <MenuItem key="0" value="legal">
                                    Pessoa Jurídica
                                </MenuItem>

                                <MenuItem key="1" value="physical">
                                    Pessoa Física
                                </MenuItem>

                            </TextField>
                        </Grid>

                        {typePeople === "physical"
                            ?
                            <>
                                <Grid item lg={3}>
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        format="###.###.###-##"
                                        label="CPF"
                                        required
                                        mask="_"
                                        value={cpf}
                                        onChange={(e) => {
                                            setCpf(e.target.value)
                                            setCpfInvalid(e.target.value ? false : true)
                                        }}
                                        error={cpfInvalid}
                                        onBlur={() => checkCpfSellerExist()}
                                    />
                                </Grid>

                                <Grid item lg={6}>
                                    <TextField
                                        size="small"
                                        label="Nome"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={nameInvalid}

                                    />
                                </Grid>

                                <Grid item lg={1} className="d-flex align-items-end justify-content-end">
                                    <Button
                                        type='submit'
                                        className='mb-2'
                                        variant="primary"
                                        disabled={isSubmit}
                                    >

                                        {isSubmit ? <>

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>

                                        </> : <>

                                            <span>
                                                Salvar
                                            </span>

                                        </>}


                                    </Button>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item lg={3} className="d-flex align-items-center">
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        label="CNPJ"
                                        required
                                        format="##.###.###/####-##"
                                        mask="_"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() => CheckCnpj(cnpj)}
                                                    >
                                                        <i className="flaticon-search"></i>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={cnpj}
                                        onChange={(e) => {
                                            setCnpj(e.target.value)
                                            setCnpjInvalid(e.target.value ? false : true)
                                        }}
                                        error={cnpjInvalid}
                                        onBlur={() => checkCnpjSellerExist()}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        value={corporateName}
                                        label="Razão social"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setCorporateName(e.target.value)
                                            setCorporateNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={corporateNameInvalid}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        value={fantasyName}
                                        label="Nome fantasia"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setFantasyName(e.target.value)
                                            setFantasyNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={fantasyNameInvalid}
                                    />
                                </Grid>

                                <Grid item lg={1} className="d-flex align-items-end justify-content-end">
                                    <Button
                                        type='submit'
                                        className='mb-2'
                                        variant="primary"
                                        disabled={isSubmit}
                                    >

                                        {isSubmit ? <>

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>

                                        </> : <>

                                            <span>
                                                Salvar
                                            </span>

                                        </>}


                                    </Button>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(tab: string) => setActiveTab(tab)}
                        id='newproduct-form-tabs'
                        className="mt-3"
                    >
                        <Tab
                            eventKey='details'
                            title="Detalhes"
                        >


                            <Grid container spacing={3} className="mt-1">
                                <Grid item lg={3} className="d-flex justify-content-between align-items-center">
                                    <NumericFormat
                                        margin="normal"
                                        className="mr-3 inputSmaller"
                                        customInput={TextField}
                                        variant="outlined"
                                        format="#####-###"
                                        label="CEP"
                                        mask="_"
                                        value={zipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />

                                    <Button variant="primary" onClick={() => infoAddress()}><i className="flaticon-search p-0"></i></Button>
                                </Grid>

                                <Grid item lg={4}>
                                    <TextField
                                        size="small"
                                        label="Endereço"
                                        value={address}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Grid>

                                <Grid item lg={2}>
                                    <TextField
                                        size="small"
                                        label="Número"
                                        margin="normal"
                                        variant="outlined"
                                        value={number}
                                        {...register("number")}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        size="small"
                                        label="Bairro"
                                        value={district}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setDistrict(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item lg={5}>
                                    <TextField
                                        size="small"
                                        label="Complemento"
                                        margin="normal"
                                        variant="outlined"
                                        {...register("complement")}
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <TextField
                                        size="small"
                                        label="Cidade"
                                        value={city}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Grid>

                                <Grid item lg={3} className="d-flex align-items-center">
                                    <Autocomplete
                                        size="small"
                                        inputValue={state}
                                        options={allStates.states}
                                        getOptionLabel={({ sigla }) => sigla}
                                        style={{ width: "100%", marginTop: "6px" }}
                                        onInputChange={(event, newInputValue) => {
                                            setState(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small" {...params}
                                            label="Estado"
                                            variant="outlined"
                                        />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} className="mt-1">
                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Pessoa de Contato"
                                        margin="normal"
                                        variant="outlined"
                                        {...register("contactPeople")}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        format="(##) ####-####"
                                        label="Telefone"
                                        required
                                        mask="_"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                            setPhoneInvalid(e.target.value ? false : true)
                                        }}
                                        error={phoneInvalid}
                                    // onBlur={() => checkPhoneCustomerExist()}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <TextField
                                        size="small"
                                        label="Ramal"
                                        margin="normal"
                                        variant="outlined"
                                        {...register("extension")}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <TextField
                                        size="small"
                                        label="Fax"
                                        margin="normal"
                                        variant="outlined"
                                        {...register("fax")}
                                    />
                                </div>

                                {/* <div className="col-lg-4">
                                <Controller
                                    name="cell"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(99) \99999-9999" value={value} onChange={onChange}>
                                            {
                                                () => (
                                                    <TextField
                                                        size="small"
                                                        label="Celular"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                )
                                            }
                                        </InputMask>
                                    )}
                                />
                            </div> */}

                                <div className="col-lg-4">
                                    <NumericFormat
                                        margin="normal"
                                        className={classes.error + ' inputSmaller'}
                                        customInput={TextField}
                                        variant="outlined"
                                        format="(##) #####-####"
                                        label="Celular"
                                        mask="_"
                                        {...register("cell")}
                                    // onBlur={() => checkPhoneCustomerExist()}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="E-mail"
                                        required
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailInvalid(e.target.value ? false : true)
                                        }}
                                        error={emailInvalid}
                                    //onBlur={() => checkEmailCustomerExist()}
                                    />
                                </div>

                                <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Website"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="www.suaempresa.com.br"
                                        {...register("webSite")}
                                    />
                                </div>
                            </Grid>
                        </Tab>

                        <Tab
                            eventKey='bank-info'
                            title='Dados Bancários'
                        >

                            <Card.Body className="p-0 mt-2">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Banco"
                                            margin="normal"
                                            variant="outlined"
                                            {...register("bank")}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Agência"
                                            margin="normal"
                                            variant="outlined"
                                            {...register("agency")}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            label="Conta corrente"
                                            margin="normal"
                                            variant="outlined"
                                            {...register("currentAccount")}
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <NumericFormat
                                            label="Salário"
                                            startAdornment="R$"
                                            {...register("salary")}
                                        />
                                    </div>
                                </div>
                            </Card.Body>

                        </Tab>

                        <Tab
                            eventKey='additional-info'
                            title='Dados Adicionais'
                        >

                            <Card.Body className="p-0 mt-2">
                                <div className="row align-items-center">
                                    <div className="col-lg-3">
                                        <NumericFormat
                                            label="Comissão do vendedor"
                                            startAdornment="%"
                                            value={sellerCommission}
                                            onChange={(e) => setSellerCommission(e.target.value)}
                                            onBlur={(e) => {
                                                var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                                                if (converted > 100) {
                                                    setSellerCommission("100");
                                                } else {
                                                    setSellerCommission(converted ? formatDecimal.format(converted) : "");
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4">
                                        <ApiResourceSelect
                                            label="Vincular ao usuário"
                                            getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                                            value={userId}
                                            hasError={userIdInvalid}
                                            onSelect={(option) => setUserId(String(option?.id ?? ''))}
                                            apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText, isAccountant: yesOrNo.NO })}
                                            getSelectedOption={(loadedOptions) => {
                                                if (!userId) return null;
                                                return loadedOptions.find((option) => option.id === Number(userId)) ?? UserService.getUserById(userId)
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-2">
                                        <FormControlLabel
                                            value="end"
                                            control={(
                                                <Checkbox
                                                    color="primary"
                                                    onChange={e => setIsConsignment(e.target.checked ? yesOrNo.YES : yesOrNo.NO)}
                                                    checked={isConsignment === yesOrNo.YES}
                                                />
                                            )}
                                            label="Venda consignada"
                                            labelPlacement="end"
                                        />
                                    </div>

                                    <div className="col-lg-3">
                                        <TextField
                                            size="small"
                                            select
                                            label="Situação"
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={situation}
                                            onChange={(e) => setSituation(e.target.value)}
                                        >
                                            <MenuItem key="0" value="active">
                                                Ativo
                                            </MenuItem>

                                            <MenuItem key="1" value="inactive">
                                                Inativo
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className="col-lg-12">
                                        <TextField
                                            size="small"
                                            label="Observações"
                                            multiline
                                            rows="4"
                                            margin="normal"
                                            variant="outlined"
                                            {...register("note")}
                                        />
                                    </div>
                                </div>
                            </Card.Body>

                        </Tab>

                    </Tabs>

                </div>

            </form>
        </div>
    );
}