import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { currencyFormat, formatCurrency, formatIntegerToMoney, formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import CustomerService from '../../../services/CustomerService';
import { Customer } from '../../../types/Customer';
import { BillsToReceive } from '../../../types/BillsToReceive';
import SellerService from '../../../services/SellerService';
import ProductService from '../../../services/ProductService';
import { Product } from '../../../types/Product';
import { ReportDetails } from '../../../components/ReportDetails';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "product", value: "Produto" },
    { reference: "code", value: "Código" },
    { reference: "quantitySold", value: "Qtde. Vendido" },
    { reference: "saleValue", value: "Valor Vendido" },
    { reference: "buyValue", value: "Valor Compra" },
    { reference: "ipi", value: "IPI" },
];

type Filters = {
    customer: string,
    initialDate: string,
    finalDate: string,
    status: string,
    seller: string,
    type: string,
    product: string,
    isCostRegister: string,
}

export function ListReportProfitMargin() {
    const [foundProfitMarginPaginate, setFoundProfitMarginPaginate] = useState<any[]>([]);
    const [foundProfitMargin, setFoundProfitMargin] = useState<any[]>([]);
    const [countTotalProfitMargin, setCountTotalProfitMargin] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    
    const [customer, setCustomer] = useState("");
    const [status, setStatus] = useState('all');
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [seller, setSeller] = useState('');
    const [type, setType] = useState('all');
    const [product, setProduct] = useState('');
    const [productId, setProductId] = useState('');

    const [totalSold, setTotalSold] = useState(0);
    const [totalBuy, setTotalBuy] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [percentageProfit, setPercentageProfit] = useState(0);
    const [isUnitDiscount, setIsUnitDiscount] = useState(false);
    const [isIpiDiscount, setIsIpiDiscount] = useState(false);
    const [isCostRegister, setIsCostRegister] = useState(false);


    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();


    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const exportDataList: ReportDataRow[] = [];
        const aux = foundProfitMarginPaginate;
        let auxTotalSold = 0;
        let auxTotalBuy = 0;

        aux.forEach((product) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(product.id) },
                { for: "product", value: product.name },
                { for: "code", value: product.code ? product.code : '-' },
                { for: "quantitySold", value: product.countSold ? String(product.countSold) : 0 },
                { for: "saleValue", value: product.totalSold ? formatCurrency(formatToFloat(product.totalSold)) : formatCurrency(0) },
                { for: "buyValue", value: product.totalBuy ? formatCurrency(formatToFloat(product.totalBuy)) : formatCurrency(0) },
                { for: "ipi", value: formatCurrency(formatToFloat(product.ipiRate)) },
            ];

            list.push(data);
        });

        foundProfitMargin.forEach((product) => {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(product.id) },
                { for: "product", value: product.name },
                { for: "code", value: product.code ? product.code : '-' },
                { for: "quantitySold", value: product.countSold ? String(product.countSold) : 0 },
                { for: "saleValue", value: product.totalSold ? formatCurrency(formatToFloat(product.totalSold)) : formatCurrency(0) },
                { for: "buyValue", value: product.totalBuy ? formatCurrency(formatToFloat(product.totalBuy)) : formatCurrency(0) },
                { for: "ipi", value: formatCurrency(formatToFloat(product.ipiRate)) },
            ];

            auxTotalSold += product.totalSold ? formatToFloat(product.totalSold) : 0;
            auxTotalBuy += product.totalBuy ? formatToFloat(product.totalBuy) : 0;

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells,
            });
        });

        setExportTotals([
            { name: 'Quantidade de Produtos Vendidos', value: foundProfitMargin.length },
            { name: 'Valor Vendido de Produtos', value: formatCurrency(auxTotalSold) },
            { name: 'Valor Compra de Produtos', value: formatCurrency(auxTotalBuy) },
            { name: 'Valor Lucro', value: formatCurrency(auxTotalSold - auxTotalBuy) },
            { name: 'Valor Porcentagem', value: new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL"}).format(auxTotalSold || auxTotalBuy ? ((auxTotalSold - auxTotalBuy) / auxTotalSold) * 100 : 0) +"%" },
        ]);

        setTotalSold(auxTotalSold);
        setTotalBuy(auxTotalBuy);
        setTotalProfit(auxTotalSold - auxTotalBuy);
        setPercentageProfit(auxTotalSold || auxTotalBuy ? ((auxTotalSold - auxTotalBuy) / auxTotalSold) * 100 : 0);
        
        setBodyData(list);
        setExportBodyData(exportDataList);

    }, [foundProfitMarginPaginate, foundProfitMargin]);

    const clearSearch = () => {
        setCustomer('');
        setStatus('all');
        setInitialDate('');
        setFinalDate('');
        setSeller('');
        setType('');
        setProduct('');
        setProductId('');
        setIsCostRegister(false);
    };

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: BillsToReceive[], count:number}>("/report/profitMargin", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;


        const profitMargin = await api.get("/report/profitMargin", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });
        
        setFoundProfitMarginPaginate(data.rows ? rows : []);
        
        setFoundProfitMargin(profitMargin.data);
        setCountTotalProfitMargin(profitMargin.data.length);
    }, []);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            customer,
            initialDate,
            finalDate,
            status: status == "all" ? "" : status,
            seller,
            type: type == "all" ? "" : type,
            product: productId,
            isCostRegister: isCostRegister == false ? "" : "true",
        };
        reloadData();

    }, [
        customer,
        initialDate,
        finalDate,
        status,
        seller,
        type,
        productId,
        isCostRegister,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Margem de Lucro', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Cliente"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={customer}
                        onSelect={(option) => setCustomer(String(option?.id) ?? '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, type: 'customer' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!customer) return null;
                            return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data inicial"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={initialDate}
                        onChange={(e) => setInitialDate(e.target.value)}
                    />
                </div>
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Data Final"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={finalDate}
                        onChange={(e) => setFinalDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        variant="outlined"
                        value={status}
                    >
                        <MenuItem key="0" value="all" onClick={() => setStatus('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="open" onClick={() => setStatus('open')}>
                            Em aberto
                        </MenuItem>

                        <MenuItem key="2" value="progress" onClick={() => setStatus('progress')}>
                            Em andamento
                        </MenuItem>

                        <MenuItem key="3" value="attended" onClick={() => setStatus('attended')}>
                            Finalizado
                        </MenuItem>

                        <MenuItem key="4" value="canceled" onClick={() => setStatus('canceled')}>
                            Cancelado
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Vendedor"
                        getOptionLabel={(option: any) => option.name}
                        value={seller}
                        onSelect={(option) => setSeller(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!seller) return null;
                            return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo"
                        margin="normal"
                        variant="outlined"
                        value={type}
                    >
                        <MenuItem key="0" value="all" onClick={() => setType('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="nfe" onClick={() => setType('nfe')}>
                            Nota Fiscal
                        </MenuItem>

                        <MenuItem key="2" value="request" onClick={() => setType('request')}>
                            Pedido
                        </MenuItem>

                        <MenuItem key="3" value="budget" onClick={() => setType('budget')}>
                            Orçamento 
                        </MenuItem>

                        <MenuItem key="4" value="pdv" onClick={() => setType('pdv')}>
                            PDV
                        </MenuItem>

                        <MenuItem key="4" value="serviceOrder" onClick={() => setType('serviceOrder')}>
                            Ordem de Serviço
                        </MenuItem>
                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={product}
                        onSelect={(option) => {setProduct(option?.name ?? ''); setProductId(String(option?.id) ?? '')}}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>

                <div className="col-lg-4">
                    <FormControlLabel
                        value="end"
                        control={
                            <Checkbox
                                color="primary"
                                checked={isCostRegister}
                                onChange={(_, checked) => setIsCostRegister(checked)}
                            />
                        }
                        label="Considerar custo do cadastro do produto (por padrão é considerado o custo da venda)"
                        labelPlacement="end"
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Margem de Lucro"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Margem de Lucro', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    loadData={loadData}
                    sortable={true}
                    totalCount={countTotalProfitMargin}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <ReportDetails 
                            bodyData={[
                                { text: 'Quantidade de Produtos Vendidos', value: String(foundProfitMargin.length) },
                                { text: 'Valor vendido de produtos', value: formatCurrency(totalSold) },
                                { text: 'Valor Compra de Produtos', value: formatCurrency(totalBuy) },
                                { text: 'Valor Lucro', value: formatCurrency(totalProfit) },
                                { text: 'Lucro Porcentagem', value: `${currencyFormat(percentageProfit, { prefixSymbol: false, style: 'decimal' })}%` },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}