import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams
} from '../../../components/ListWithModalChangeSituation';

import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { CategoryProduct, Product } from '../../../hooks/products';
import { getEnabledStatusText } from '../../../utils/getEnabledStatusText';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import useBackendLoad from '../../../hooks/backendReload';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import ProductService from '../../../services/ProductService';
import CategoryProductService from '../../../services/CategoryProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';

type ReportPriceResponse = {
    rows: Product[];
    count: number;
}

type Filters = {
    productInput: string,
    situation: string,
    provider: string,
    category: string
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "name", value: "Nome" },
    { reference: "code", value: "Código" },
    { reference: "unitaryValue", value: "Valor unitário" },
    { reference: "situation", value: "Situação" },
];

export function ListReportPrice() {
    const [foundProducts, setFoundProducts] = useState<Product[]>([]);
    const [countTotalProductsPaginated, setCountTotalProductsPaginated] = useState(0);
    const [countTotalProducts, setCountTotalProducts] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [situation, setSituation] = useState('all');
    const [productInput, setProductInput] = useState('');
    const [category, setCategory] = useState('');
    const [provider, setProvider] = useState('');
    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<ReportPriceResponse>("/report/price", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        const exportDataList: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            exportDataList.push({
                cells: exportCells
            });
        })

        setExportTotals([
            { name: 'Total de produtos', value: count },
        ])

        setExportBodyData(exportDataList);
        setCountTotalProducts(count);
    }, []);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<ReportPriceResponse>("/report/price", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundProducts(rows);
        setCountTotalProductsPaginated(count);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = foundProducts;

        aux.forEach((product) => {
            const data = mountBodyDataRow(product);

            list.push(data);
        });

        setBodyData(list);
    }, [foundProducts]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: Product) {
        const situationValue = getEnabledStatusText(reportRow.situation);
        const amount = formatCurrency(formatToFloat(reportRow.saleValue));

        const data: BodyDataBaseProps[] = [
            { for: "id", value: String(reportRow.id), id: true },
            { for: "name", value: reportRow.name },
            { for: "code", value: reportRow.code },
            { for: "unitaryValue", value: amount },
            { for: "situation", value: situationValue },
        ];

        return data;
    }

    const clearSearch = () => {
        setSituation('all');
        setProductInput('');
        setProvider('');
        setCategory('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            productInput,
            situation,
            provider,
            category
        };
        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        productInput,
        situation,
        provider,
        category,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Preços', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    }

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={productInput}
                        onSelect={(option) => setProductInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>
                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Situação"
                        margin="normal"
                        variant="outlined"
                        value={situation}
                    >
                        <MenuItem key="0" value="all" onClick={() => setSituation('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                            Ativo
                        </MenuItem>

                        <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                            Inativo
                        </MenuItem>
                    </TextField>
                </div>
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Categoria"
                        getOptionLabel={(option: CategoryProduct) => option.nameCategory}
                        value={category}
                        onSelect={(option) => setCategory(String(option?.id) ?? '')}
                        apiSearchHandler={(typedText) => CategoryProductService.getCategoriesFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!category) return null;
                            return loadedOptions.find((option) => option.id === Number(category)) ?? CategoryProductService.getCategoryById(category)
                        }}
                    />
                </div>
                <div className="col-lg-4">
                    <ApiResourceSelect
                        style={{ width: "100%" }}
                        label="Fornecedor"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={provider}
                        onSelect={(option) => setProvider(option ? String(option.id) : '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                        getSelectedOption={(loadedOptions) => {
                            if(!provider) return null;
                            return loadedOptions.find((option) => option.id === Number(provider)) ?? CustomerService.getCustomerById(provider)
                        }}
                    />
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Preços"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Preços', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                     headData={headData}
                     bodyData={bodyData}
                     loadData={loadData}
                     sortable={true}
                     totalCount={countTotalProductsPaginated}
                     triggerLoad={triggerLoad}
                     setTriggerLoad={setTriggerLoad}
                     lastCell={false}
                />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <Table className="border ml-3 mt-0">
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={1}>Total de produtos</TableCell>
                                    <TableCell colSpan={1}>{countTotalProducts}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >
    );
}