class ContactService {
    getTypeContactText(type: string) {
        if(!type) return '';

        switch (type) {
            case 'family':
                return 'Familiar';
            case 'personal':
                return 'Pessoal';
            case 'professional':
                return 'Profissional';
        
            default:
                return '';
        }
    }
}

export default new ContactService();