import { status, typeIndication, whoPay } from "../types/Indications";
import { yesOrNo } from "../types/yesOrNo";
import { bypassAuthHeader } from "../utils/bypassAuthHeader";
import api from "./Api";
import LogService from "./LogService";

type Params = {
    bypassAuth?: boolean;
}

type CreateAccountantUserParams = {
    existAccountant?: any;
    accountantCreatedFromIndication: boolean;
    accountantNewCompaniesIds: (number | string)[];
    firstname: string;
    lastname: string;
    cell: string;
    email: string;
    canAccessMainCompany: yesOrNo;
    photoFile: File | null;
    currentCompany: any;
    permissionsForCurrentCompany: number[];
    fastAccessForCurrentCompany: number[];
}

class UserService {
    async getAll() {
        const { data } = await api.get('users');
        return data;
    }

    async getAllUsersFiltered(filters: any) {
        const userResponse = await api.get<any[]>('/users', { params: { filters } });

        return userResponse.data;
    }

    async getNameById(id: number): Promise<string> {
        const foundUser = await this.getUserById(id);
        if (!foundUser) return '';

        return `${foundUser.firstname} ${foundUser.lastname}`;
    }

    getFullName(user?: any, defaultValue: string = '') {
        if (!user || typeof user !== 'object') {
            return defaultValue ? defaultValue : '';
        }

        return `${user.firstname} ${user.lastname}`
    }

    async getUserById(id: number | string, { bypassAuth }: Params = {}) {
        const { data } = await api.get(`users/${id || '0'}`, { headers: bypassAuth ? bypassAuthHeader : undefined });
        return data;
    }

    async getFirstUserByCompanyId(companyId: number) {
        const response = await api.get(`/users/company/first/${companyId}`);

        return response.data;
    }

    getFirstAvailableCompanyId(user: any) {
        if (!user) {
            return undefined;
        }
        if (user.canAccessMainCompany === 'y') {
            return user.companyId;
        }

        const userBranchesIds = user.branchesIds ? JSON.parse(user.branchesIds) : [];

        if (userBranchesIds.length === 0) {
            return user.companyId;
        }

        return userBranchesIds[0];
    }

    async verifyCellExists(cell: string, userId?: number | string) {
        const response = await api.get(`users/check/cell?cell=${cell}&id=${userId ?? ''}`);
        return response.data;
    }

    async verifyEmailExists(email: string, userId?: number | string) {
        const response = await api.get(`users/check/email?email=${email}&id=${userId ?? ''}`);
        return response.data;

    }

    async verifyLoginExists(login: string, userId?: number | string) {
        const response = await api.get(`users/check/login?login=${login}&id=${userId ?? ''}`);
        return response.data;
    }

    async createAccountantUser({
        existAccountant,
        accountantCreatedFromIndication,
        accountantNewCompaniesIds,
        firstname,
        lastname,
        cell,
        email,
        canAccessMainCompany,
        photoFile,
        currentCompany,
        permissionsForCurrentCompany,
        fastAccessForCurrentCompany,
    }: CreateAccountantUserParams) {
        if (existAccountant && existAccountant.isAccountant !== 'y') {
            throw new Error('Contador inválido');
        }

        const companies = existAccountant ? JSON.parse(existAccountant.branchesIds) : [];
        const accountantPermissions: any[] = existAccountant ? JSON.parse(existAccountant.permissions) : [];
        const accountantFastAccess: any[] = existAccountant ? JSON.parse(existAccountant.fastAccess) : [];

        let accountantCompanies = [
            ...companies,
            ...accountantNewCompaniesIds,
        ]

        for (const companyId of accountantCompanies) {
            if (!accountantPermissions.find((permission: any) => permission.companyId == companyId)) {
                accountantPermissions.push({
                    companyId: companyId,
                    permissions: JSON.stringify(permissionsForCurrentCompany),
                });
            }

            if (!accountantFastAccess.find((fastAccess: any) => fastAccess.companyId == companyId)) {
                accountantFastAccess.push({
                    companyId: companyId,
                    fastAccess: JSON.stringify(fastAccessForCurrentCompany),
                });
            }
        }

        let createdUser = null;

        if (existAccountant) { //quando o contador já existe
            const raw = {
                branchesIds: JSON.stringify(accountantCompanies),
                permissions: JSON.stringify(accountantPermissions),
                fastAccess: JSON.stringify(accountantFastAccess)
            };

            const response = await api.put(`users/${existAccountant.id}`, raw);

            createdUser = response.data;

            try {
                await this.uploadUserPhoto(Number(response.data.id), photoFile);
            } catch (error) {
                console.log('upload', error);
            }

            if (accountantCreatedFromIndication) {
                const rawIndication = {
                    companyId: currentCompany.id,
                    accountantId: response.data.id,
                    typeIndication: typeIndication.LINK,
                    status: status.ACCEPT,
                    whoPay: whoPay.COMPANY,
                    companyAlreadyExists: yesOrNo.YES,
                };
                await api.post(`/indications`, rawIndication);
            }

            // Email de boas vindas do contador
            await api.post("/users/welcome/accountant", {
                to: email,
                idUser: Number(response.data.id),
                companyId: currentCompany.id,
                companyName: currentCompany.name,
            });
        } else { // quando o contador não existe ainda no sistema
            const raw = {
                userProfileId: 0.1,
                isAdmin: yesOrNo.NO,
                isSuper: yesOrNo.NO,
                firstname: firstname ? firstname : null,
                lastname: lastname ? lastname : null,
                cell: cell ? cell : null,
                email: email ? email : null,
                login: email ? email : null,
                password: "",
                canAccessMainCompany,
                isAccountant: yesOrNo.YES,
                branchesIds: JSON.stringify(accountantCompanies),
                permissions: JSON.stringify(accountantPermissions),
                fastAccess: JSON.stringify(accountantFastAccess)
            };

            const response = await api.post(`users`, raw);

            createdUser = response.data;

            LogService.logRegister({
                itemId: response.data.id,
                module: 'Usuários',
                itemName: `${response.data.firstname} ${response.data.lastname ?? ''}`,
            });

            try {
                await this.uploadUserPhoto(Number(response.data.id), photoFile);

                // Email de convite para criação de senha
                await api.post("/users/invite/accountant", {
                    to: email,
                    idUser: Number(response.data.id),
                    companyId: currentCompany.id,
                    companyName: currentCompany.name,
                });
            } catch (error) {
                console.log('upload', error);
            }
        }

        return createdUser;
    }

    async uploadUserPhoto(userId: number, imageFile: File | null): Promise<string | boolean> {
        if (!imageFile) {
            return false;
        }

        var formFile = new FormData();
        formFile.append('image', imageFile, imageFile.name);

        const response = await api.post('/image', formFile);
        const raw = {
            photo: response.data
        }

        await api.put(`users/${userId}`, raw);
        return response.data;
    }

    async checkIfAuthUserHasPermissionToExecuteAction(menuId: number, action: string) {
        try {
            await api.head('/users/permission/action', { params: { menuId, action } });
            return true;
        } catch (error) {
            return false;
        }
    }
}

export default new UserService();