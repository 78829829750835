export const formatCpfCnpj = (type: "CPF" | "CNPJ", value: string) => {
    if(!value) return '';
    
    let formated = "";

    const cleaned = ('' + value).replace(/\D/g, '');

    if (type === "CPF") {
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

        if (match) {
            formated = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }
    }

    if (type === "CNPJ") {
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);

        if (match) {
            formated = `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
        }
    }

    return formated;
};

export const formatCpfCnpjDynamic = (value?: string) => {
    if(!value) return '';
    
    let formated = "";

    const cleaned = ('' + value).replace(/\D/g, '');

    if (cleaned.length === 11) {
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

        if (match) {
            formated = `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }
    }

    if (cleaned.length === 14) {
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);

        if (match) {
            formated = `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
        }
    }

    return formated;
};