import { format } from "date-fns";
import { BackendLoadData } from "../types/BackendLoadData";
import { Quotation, QuotationStatus } from "../types/Quotation";
import { Situation } from "../types/Situation";
import api from "./Api";

export type Filters = {
    searchQuery: string;
    situation: string;
    initialCreatedDate: string;
    finalCreatedDate: string;
    minValue: number;
    maxValue: number;
}

type DispatchStatusChangeNotificationAndEmailParams = {
    quotationId: number;
    lastStatusHistory: any;
    newStatusHistory: any;
}

class QuotationService {
    setBypassAuth(value: boolean) {
        if(value) {
            api.defaults.headers['x-bypass-auth']  = `0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5`;
        } else {
            api.defaults.headers['x-bypass-auth'] = undefined;
        }
    }
    
    async getAllQuotations({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: BackendLoadData, filters: Filters | null) {
        try {
            const { data } = await api.get<{rows: Quotation[], count: number}>('quotation', {
                params: {
                    skip: rowsPerPage * currentPage,
                    take: rowsPerPage,
                    filters: filters ? JSON.stringify(filters) : undefined,
                    sortReference,
                    sortDirection,
                }
            });

            const {rows, count} = data;

            return {rows, count};
        } catch (error) {
            return {rows: [], count: 0};
        }
    }

    async getQuotationById(id: number | string) {
        try {
            const { data } = await api.get<Quotation>(`quotation/${id}`);
            return data;
        } catch (error) {
            return undefined;
        }
    }

    async createQuotation(data: any) {
        try {
            const response = await api.post<Quotation>('quotation/', data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateQuotation(id: number | string, data: any) {
        try {
            const response = await api.put<Quotation>(`quotation/${id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async deleteQuotation(id: number | string) {
        try {
            await api.delete(`quotation/${id}`);
        } catch {}
    }

    async updateQuotationSituation(id: number | string, data: {
        newStatus: QuotationStatus;
        comments?: string;
    }) {
        try {
            const quotation = await this.getQuotationById(id);
            const situation: Situation[] = quotation?.situation ? JSON.parse(quotation.situation) : [];

            situation.push({
                dateSituation: format(Date.now(), "yyyy-MM-dd"),
                commentsSituation: data.comments ?? '',
                statusSituation: data.newStatus,
            });

            const result = await this.updateQuotation(id, {
                situation: JSON.stringify(situation),
                status: data.newStatus,
            });

            return result;
        } catch (error) {
            throw error;
        }
    }

    async dispatchStatusChangeNotificationAndEmail({
        quotationId,
        lastStatusHistory,
        newStatusHistory,
    }: DispatchStatusChangeNotificationAndEmailParams) {
        try {
            await api.post(`quotation/statusNotification`, {
                quotationId,
                lastStatusHistory,
                newStatusHistory,
            });
        } catch (error) {
            console.log(error);
        }
    }

    convertStringToStatus(status: string): QuotationStatus {
        switch (status) {
            case QuotationStatus.open:
                return QuotationStatus.open;

            case QuotationStatus.progress:
                return QuotationStatus.progress;

            case QuotationStatus.attended:
                return QuotationStatus.attended;

            case QuotationStatus.canceled:
                return QuotationStatus.canceled;
            case 'custom':
                return QuotationStatus.custom;
        
            default:
                return QuotationStatus.open;
        }
    }

    isFinished(quotation: Quotation) {
        return (
            quotation.status === QuotationStatus.attended || quotation.status === QuotationStatus.canceled
        )
    }
}

export default new QuotationService();