import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import api from "../services/Api";
import { Menu } from "../types/Menu";

export enum Menus {
    BRANCHES = 74,
};

export default function useMenu() {

    const superRoutes = useMemo(() => [
        '/empresas',
        '/planos',
        '/perfis-acesso',
        '/config/super',
        '/faturas/super',
    ], []);

    const { user } = useSelector((state: any) => state.auth);

    const [menus, setMenus] = useState<Menu[]>([]);
    const [menusPlain, setMenusPlain] = useState<any[]>([]);

    useEffect(() => {
        load();
    }, [user]);

    async function load() {
        const response = await api.get('menus');
        setMenusPlain(response.data || []);
        const menus = setMenuStructure(response.data, null) || [];
        setMenus(menus);
    }

    const setMenuStructure = (menus: any[], parentId: number|null) => {
        const structure = [] as Menu[];
        const menuLevel = menus.filter((menu) => menu.menuId === parentId);
        if(menuLevel.length === 0) {
            return undefined;
        } else {
            for (const menu of menuLevel) {
                structure.push({
                    id: menu.id,
                    name: menu.name,
                    route: menu.route || undefined,
                    submenus: setMenuStructure(menus, menu.id),
                });
            }
        }
        return structure;
    }

    return {
        menus,
        menusPlain,
        superRoutes,
    };

    // const menus = useMemo<Menu[]>(() => {
    //     api.get('menus').then((res) => {
    //         console.log({res});
    //     });
    //     return [
    //         {
    //             id: 1,
    //             name: 'Cadastros',
    //             submenus: [
    //                 {
    //                     id: 2,
    //                     name: 'Clientes / Fornecedores',
    //                     route: '/clientes',
    //                 },
    //                 {
    //                     id: 3,
    //                     name: 'Produtos / Serviços',
    //                     route: '/produtos',
    //                 },
    //                 {
    //                     id: 4,
    //                     name: 'Transportadoras',
    //                     route: '/transportadoras',
    //                 },
    //                 {
    //                     id: 5,
    //                     name: 'Vendedores',
    //                     route: '/vendedores',
    //                 },
    //                 {
    //                     id: 6,
    //                     name: 'Contas Bancárias',
    //                     route: '/contas-bancarias',
    //                 },
    //                 {
    //                     id: 7,
    //                     name: 'Lista de Preços',
    //                     route: '/lista-de-precos',
    //                 },
    //                 {
    //                     id: 8,
    //                     name: 'Categorias de Produto',
    //                     route: '/categorias-produto',
    //                 },
    //                 {
    //                     id: 9,
    //                     name: 'Marcas',
    //                     route: '/marcas',
    //                 },
    //                 {
    //                     id: 10,
    //                     name: 'Parâmetros',
    //                     submenus: [
    //                         {
    //                             id: 11,
    //                             name: 'Locais de Estoque',
    //                             route: '/parametros/locais-de-estoque'
    //                         },
    //                     ]
    //                 },
    //             ],
    //         },
    //         {
    //             id: 12,
    //             name: 'Vendas',
    //             submenus: [
    //                 {
    //                     id: 13,
    //                     name: 'Notas Fiscais',
    //                     route: '/notas-fiscais',
    //                 },
    //                 {
    //                     id: 14,
    //                     name: 'Pedidos',
    //                     route: '/pedidos',
    //                 },
    //                 {
    //                     id: 15,
    //                     name: 'Orçamentos',
    //                     route: '/orcamentos',
    //                 },
    //                 {
    //                     id: 16,
    //                     name: 'PDV',
    //                     route: '/pdv',
    //                 },
    //                 {
    //                     id: 17,
    //                     name: 'NFC-e Consumidor',
    //                     route: '/notas-fiscais-consumidor',
    //                 },
    //             ],
    //         },
    //         {
    //             id: 18,
    //             name: 'Compras',
    //             submenus: [
    //                 {
    //                     id: 19,
    //                     name: 'Ordem de Compra',
    //                     route: '/ordem-de-compra',
    //                 },
    //             ],
    //         },
    //         {
    //             id: 20,
    //             name: 'Serviços',
    //             submenus: [
    //                 {
    //                     id: 21,
    //                     name: 'Notas Fiscais',
    //                     route: '/notas-fiscais-servico',
    //                 },
    //                 {
    //                     id: 22,
    //                     name: 'Ordem de Serviço',
    //                     route: '/ordem-de-servico',
    //                 },
    //             ],
    //         },
    //         {
    //             id: 23,
    //             name: 'Financeiro',
    //             submenus: [
    //                 {
    //                     id: 24,
    //                     name: 'Contas a Pagar',
    //                     route: '/contas-a-pagar',
    //                 },
    //                 {
    //                     id: 25,
    //                     name: 'Contas a Receber',
    //                     route: '/contas-a-receber',
    //                 },
    //                 {
    //                     id: 26,
    //                     name: 'Fluxo de Caixa',
    //                     route: '/fluxo-de-caixa',
    //                 },
    //                 {
    //                     id: 27,
    //                     name: 'Conciliação Bancárias',
    //                     route: '/conciliacao-bancaria',
    //                 },
    //                 {
    //                     id: 28,
    //                     name: 'Parâmetros',
    //                     submenus: [
    //                         {
    //                             id: 29,
    //                             name: 'Centro de Custos',
    //                             route: 'parametros/centro-de-custos',
    //                         },
    //                     ],
    //                 },
    //             ],
    //         }
    //     ]
    // }, []);
}