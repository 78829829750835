import { CategoryProduct } from "../hooks/products";
import api from "./Api";

class CategoryProductService {
    async getCategoryById(id: string | number) {
        const categoryResponse = await api.get<CategoryProduct>(`/categoryproduct/${id || '0'}`);

        return categoryResponse.data;
    }

    async getCategoriesFiltered(filters: any) {
        const categoryResponse = await api.get<CategoryProduct[]>('/categoryproduct', { params: { filters } });

        return categoryResponse.data;
    }
}

export default new CategoryProductService();