import { Grid, MenuItem, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { NumericFormat } from "../NumericFormat";
import AntSwitch from "../AntSwitch";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStyles } from "../../hooks/styles";
import BankAccountService from "../../services/BankAccountService";
import { BankAccount } from "../../types/BankAccount";
import api from "../../services/Api";
import LogService from "../../services/LogService";
import { getEnabledStatusText } from "../../utils/getEnabledStatusText";
import { getYesOrNoText } from "../../utils/getYesOrNoText";
import { banks, banksBillet, numberBanksThatRequireWallet } from "../../utils/banks";
import ServiceContractService from "../../services/ServiceContractService";
import RecurringExpenseService from "../../services/RecurringExpenseService";
import { GenericObject } from "../../types/GenericObject";
import { getFileExtension } from "../../utils/getFileExtension";
import { api as apiUrl } from '../../services/ApiURL';
import { bankNumberIsInter } from "../../utils/bankNumberIsInter";

type EditAccountBankModalProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    accountBankIdToEdit: string | number;
    setAccountBankIdToEdit: React.Dispatch<React.SetStateAction<string | number>>;
    onEditAccountBank?: (accountBank: BankAccount) => void;
}

const steps = BankAccountService.getFormSteps();

export function EditAccountBankModal({
    showModal,
    setShowModal,
    accountBankIdToEdit,
    setAccountBankIdToEdit,
    onEditAccountBank,
}: EditAccountBankModalProps) {
    const { user } = useSelector((state: any) => state.auth);
    const classes = useStyles();

    // EDIT ACCOUNT
    const [isSubmit, setIsSubmit] = useState(false);
    const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});
    const [activeStepEdit, setActiveStepEdit] = useState(0);
    const [walletType, setWalletType] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [currentStepEdit, setCurrentStepEdit] = useState(1);

    //VALIDAÇÕES CAMPOS VERMELHOS
    const [nameAccountInvalid, setNameAccountInvalid] = useState(false);
    const [openingBalanceInvalid, setOpeningBalanceInvalid] = useState(false);
    const [initialDateInvalid, setInitialDateInvalid] = useState(false);
    const [agencyInvalid, setAgencyInvalid] = useState(false);
    const [agencyDigitInvalid, setAgencyDigitInvalid] = useState(false);
    const [accountInvalid, setAccountInvalid] = useState(false);
    const [accountDigitInvalid, setAccountDigitInvalid] = useState(false);
    const [shipmentSequenceInvalid, setShipmentSequenceInvalid] = useState(false);
    const [billetSequenceInvalid, setBilletSequenceInvalid] = useState(false);
    const [defaultLayoutInvalid, setDefaultLayoutInvalid] = useState(false);
    const [titleSpeciesInvalid, setTitleSpeciesInvalid] = useState(false);
    const [covenantCodeInvalid, setCovenantCodeInvalid] = useState(false);
    const [codeAssignorInvalid, setCodeAssignorInvalid] = useState(false);
    const [walletInvalid, setWalletInvalid] = useState(false);
    const [situationInvalid, setSituationInvalid] = useState(false);
    const [contractsWithAccountBankError, setContractsWithAccountBankError] = useState(false);
    const [expensesWithAccountBankError, setExpensesWithAccountBankError] = useState(false);
    const [interClientIdInvalid, setInterClientIdInvalid] = useState(false);
    const [interClientSecretInvalid, setInterClientSecretInvalid] = useState(false);

    const [idAccount, setIdAccount] = useState("");
    const [bank, setBank] = useState("");
    const [numberBank, setNumberBank] = useState("");
    const [nameAccount, setNameAccount] = useState("");
    const [openingBalance, setOpeningBalance] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [agency, setAgency] = useState("");
    const [agencyDigit, setAgencyDigit] = useState("");
    const [account, setAccount] = useState("");
    const [accountDigit, setAccountDigit] = useState("");
    const [situation, setSituation] = useState("n");
    const [standardAccountForRecipes, setStandardAccountForRecipes] = useState("n");
    const [standardExpenseAccount, setStandardExpenseAccount] = useState("n");
    const [willGenerateBills, setWillGenerateBills] = useState("n");
    const [accountAcceptEdit, setAccountAcceptEdit] = useState("N");
    const [wallet, setWallet] = useState("");
    const [codeAssignor, setCodeAssignor] = useState("");
    const [covenantCode, setCovenantCode] = useState("");
    const [walletVariation, setWalletVariation] = useState("");
    const [instructions, setInstructions] = useState("");
    const [demonstrative, setDemonstrative] = useState("");
    const [percentageOfFinePerMonth, setPercentageOfFinePerMonth] = useState("");
    const [interestPercentage, setInterestPercentage] = useState("");
    const [interestDays, setInterestDays] = useState("");
    const [shipmentSequence, setShipmentSequence] = useState("");
    const [billetSequence, setBilletSequence] = useState("");
    const [protestInstruction, setProtestInstruction] = useState("");
    const [defaultLayout, setDefaultLayout] = useState("Cnab400");
    const [titleSpecies, setTitleSpecies] = useState("DM");
    const [fineDays, setFineDays] = useState('');
    const [interDaysToCancelBillet, setInterDaysToCancelBillet] = useState(0);
    const [interClientId, setInterClientId] = useState('');
    const [interClientSecret, setInterClientSecret] = useState('');
    const [interCertificateFile, setInterCertificateFile] = useState<File | null>(null);
    const [interKeyFile, setInterKeyFile] = useState<File | null>(null);
    const [savedInterCertificatePath, setSavedInterCertificatePath] = useState('');
    const [savedInterKeyPath, setSavedInterKeyPath] = useState('');

    const interCertificateFileRef = useRef<HTMLInputElement>(null);
    const interKeyFileRef = useRef<HTMLInputElement>(null);

    const selectedBankCanGenerateBillets = !!banksBillet.find(bank => bank.number == numberBank);
    const selectedBankIsInter = bankNumberIsInter(numberBank);

    useEffect(() => {
        if (accountBankIdToEdit) {
            cleanBeforeAccount();
            cleanInvalids();
            getAccount(accountBankIdToEdit);
        }
    }, [accountBankIdToEdit]);

    async function editAccount() {
        if (idAccount) {
            setIsSubmit(true);

            if (standardAccountForRecipes == "y") {
                await disableOtherDefaultRevenue(Number(idAccount));
            }

            if (standardExpenseAccount == "y") {
                await disableOtherDefaultExpense(Number(idAccount));
            }

            const requestData: GenericObject = {
                name: nameAccount,
                nameBank: bank,
                numberBank: numberBank,
                openingBalance: openingBalance,
                initialDate: initialDate,
                agency: agency,
                agencyDigit: agencyDigit,
                account: account,
                accountDigit: accountDigit,
                situation: situation,
                standardAccountForRecipes: standardAccountForRecipes,
                standardExpenseAccount: standardExpenseAccount,
                willGenerateBills: willGenerateBills,
                accept: accountAcceptEdit,
                wallet: wallet,
                codeAssignor: codeAssignor,
                covenantCode: covenantCode,
                walletVariation: walletVariation,
                instructions: instructions,
                demonstrative: demonstrative,
                percentageOfFinePerMonth: percentageOfFinePerMonth,
                interestPercentage: interestPercentage,
                interestDays: interestDays,
                shipmentSequence: Number(shipmentSequence) ?? null,
                billetSequence: Number(billetSequence) ?? null,
                protestInstruction: protestInstruction,
                defaultLayout: defaultLayout || null,
                titleSpecies: titleSpecies || null,
            }

            if (selectedBankIsInter && willGenerateBills === 'y') {
                requestData.fineDays = Number(fineDays || 0);
                requestData.interDaysToCancelBillet = Number(interDaysToCancelBillet || 0);
                requestData.interClientId = interClientId;
                requestData.interClientSecret = interClientSecret;
            } else {
                requestData.fineDays = null;
                requestData.interDaysToCancelBillet = null;
                requestData.interClientId = null;
                requestData.interClientSecret = null;
            }

            try {

                if (beforeSubmitData.numberBank != numberBank) {
                    await api.post(`/billsToPay/update-relations/${idAccount}`, { nameBank: bank })
                    await api.post(`/billsToReceive/update-relations/${idAccount}`, { nameBank: bank })
                }

                const response = await api.post(`/accountBank/${idAccount}`, requestData);

                // Fazer upload dos arquivos certificado e chave do Inter
                if (selectedBankIsInter && willGenerateBills === 'y') {
                    let uploadSuccess = true;
                    try {
                        uploadSuccess = await uploadInterFiles(Number(idAccount));
                    } catch (error) {
                        uploadSuccess = false;
                    }
                }

                setIsSubmit(false);

                if (onEditAccountBank) {
                    onEditAccountBank(response.data);
                }

                setIsSubmit(false);
                handleCloseEditAccountBankModal();

                LogService.logEdit({
                    itemId: response.data.id,
                    itemName: response.data.name,
                    module: 'Contas Bancárias',
                    oldData: beforeSubmitData,
                    newData: response.data,
                    formattedFields: {
                        standardAccountForRecipes: (value) => getEnabledStatusText(value),
                        standardExpenseAccount: (value) => getEnabledStatusText(value),
                        willGenerateBills: (value) => getYesOrNoText(value),
                        initialDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        situation: (value) => getEnabledStatusText(value),
                        interCertificatePath: (value) => value ? 'Arquivo upload' : 'Sem arquivo',
                        interKeyPath: (value) => value ? 'Arquivo upload' : 'Sem arquivo',
                    },
                    fieldsMap: {
                        name: 'Nome da conta',
                        nameBank: 'Banco',
                        numberBank: 'Número do banco',
                        openingBalance: 'Saldo inicial',
                        initialDate: 'Data inicial',
                        agency: 'Agência',
                        agencyDigit: 'Dígito da agência',
                        account: 'Conta',
                        accountDigit: 'Dígito da conta',
                        situation: 'Situação',
                        standardAccountForRecipes: 'Conta padrão para receitas',
                        standardExpenseAccount: 'Conta padrão para despesas',
                        willGenerateBills: 'Esta conta irá gerar boletos bancários(com registro)?',
                        accept: 'Aceito',
                        wallet: 'Carteira',
                        codeAssignor: 'Atribuidor de código',
                        covenantCode: 'Código da aliança',
                        walletVariation: 'Variação de carteira',
                        instructions: 'Instruções que serão impressas no boleto',
                        demonstrative: 'Demonstrativo',
                        percentageOfFinePerMonth: 'Porcentagem de multa ao mês',
                        interestPercentage: 'Porcentagem de juros ao mês',
                        interestDays: 'Porcentagem de juros em dias',
                        shipmentSequence: 'Sequência da remessa',
                        billetSequence: 'Sequência do boleto',
                        protestInstruction: 'Unknow', // Campo nem existe
                        defaultLayout: 'Layout padrão (CNAB)',
                        titleSpecies: 'Espécie do título',
                        interCertificatePath: 'Certificado',
                        interKeyPath: 'Chave',
                    },
                });


            } catch (error) {
                setIsSubmit(false);
                console.log(error)
                alert('Ocorreu um erro ao processar a requisição');
            }
        }
    }

    async function uploadInterFiles(accountBankId: number): Promise<boolean> {
        let certificatePath = '';
        let keyPath = '';

        if (interCertificateFile) {
            var formFile = new FormData();
            formFile.append('file', interCertificateFile, interCertificateFile.name);
            const certificateResponse = await api.post('/file', formFile);
            certificatePath = certificateResponse.data;
        }

        if (interKeyFile) {
            var formFile = new FormData();
            formFile.append('file', interKeyFile, interKeyFile.name);
            const keyResponse = await api.post('/file', formFile);
            keyPath = keyResponse.data;
        }

        if (!certificatePath && !keyPath) {
            return false;
        }

        const raw = {
            interCertificatePath: certificatePath || undefined,
            interKeyPath: keyPath || undefined,
        };

        await api.post(`accountBank/${accountBankId}`, raw);

        if (certificatePath && savedInterCertificatePath) {
            removeOldFile(savedInterCertificatePath);
        }
        if (keyPath && savedInterKeyPath) {
            removeOldFile(savedInterKeyPath);
        }

        return false;
    }

    async function removeOldFile(filename: string) {
        await api.delete('/file', { data: { filename } });
    }

    async function inputsVerify() {
        cleanInvalids();
        setContractsWithAccountBankError(false);
        setExpensesWithAccountBankError(false);

        const bankRequiresWallet = numberBanksThatRequireWallet.includes(numberBank);

        if (!nameAccount) {
            setIsSubmit(false)
            setNameAccountInvalid(true);
            alert("Preencha o nome da conta corretamente")

            return false

        }


        if (!openingBalance) {
            setIsSubmit(false)
            setOpeningBalanceInvalid(true);
            alert("Preencha o saldo inicial corretamente")

            return false
        }


        if (!initialDate) {
            setIsSubmit(false)
            setInitialDateInvalid(true);
            alert("Preencha a data corretamente")

            return false

        }

        if (numberBank != "000") {
            if (!agency) {
                setIsSubmit(false)
                setAgencyInvalid(true);
                alert("Preencha a agência corretamente")

                return false

            }

            if (!account) {
                setIsSubmit(false)
                setAccountInvalid(true);
                alert("Preencha a conta corretamente")

                return false

            }

            if (bank != "Itaú") {
                // if (!agencyDigit) {
                //     setIsSubmit(false)
                //     setAgencyDigitInvalid(true);
                //     alert("Preencha o dígito da agência corretamente")

                //     return false

                // }

                if (!accountDigit) {
                    setIsSubmit(false)
                    setAccountDigitInvalid(true);
                    alert("Preencha o dígito da conta corretamente")

                    return false

                }
            }
        }


        if (currentStep == 1 && selectedBankCanGenerateBillets) {
            if (willGenerateBills === 'y') {
                switch (bank) {
                    case "Banco do Brasil": //Banco do Brasil
                        if (!covenantCode || (covenantCode.length != 4 && covenantCode.length != 6 && covenantCode.length != 7)) {
                            setIsSubmit(false)
                            setCovenantCodeInvalid(true);
                            alert("O Convênio (código do cedente) deve ter 4, 6 ou 7 digitos")

                            return false
                        }
                        break;
                    case "Bradesco": //Bradesco
                        if (!codeAssignor) {
                            setIsSubmit(false)
                            setCodeAssignorInvalid(true);
                            alert("Informe o Convênio (código do cedente)")

                            return false
                        }
                        break;
                    case "Caixa Econômica Federal": //Caixa Econômica Federal
                        if (!codeAssignor || !(codeAssignor.length == 6)) {
                            setIsSubmit(false)
                            setCodeAssignorInvalid(true);
                            alert("O Convênio (código do cedente) deve ter 6 digitos")

                            return false
                        }
                        break;
                }

                if (bankRequiresWallet && !wallet) {
                    setIsSubmit(false)
                    setWalletInvalid(true);
                    alert("Selecione o tipo de carteira")

                    return false
                }
            }
        }

        if (currentStepEdit == 1 && beforeSubmitData.situation === 'y' && situation === 'n') {
            const serviceContractsWithAccountBank = await ServiceContractService.getServiceContractsFiltered({ bankAccount: beforeSubmitData.id });
            const recurringExpensesWithAccountBank = await RecurringExpenseService.getRecurringExpensesFiltered({ bankAccount: beforeSubmitData.id });

            const serviceContractsWithAccountBankError = serviceContractsWithAccountBank.length > 0;
            setContractsWithAccountBankError(serviceContractsWithAccountBankError);

            const recurringExpensesWithAccountBankError = recurringExpensesWithAccountBank.length > 0;
            setExpensesWithAccountBankError(recurringExpensesWithAccountBankError);

            if (serviceContractsWithAccountBankError || recurringExpensesWithAccountBankError) {
                setIsSubmit(false)
                return false;
            }
        }

        if (currentStepEdit == 2 && selectedBankCanGenerateBillets && willGenerateBills === 'y') {
            if (!selectedBankIsInter) {
                // Validação de campos para bancos que geram boletos de forma manual

                if (!shipmentSequence) {
                    setIsSubmit(false)
                    setShipmentSequenceInvalid(true);
                    alert("Preencha a sequência da remessa")

                    return false

                }

                if (!billetSequence) {
                    setIsSubmit(false)
                    setBilletSequenceInvalid(true);
                    alert("Preencha a sequência do boleto")

                    return false

                }

                if (!defaultLayout) {
                    setIsSubmit(false)
                    setDefaultLayoutInvalid(true);
                    alert("Selecione um layout padrão do boleto")

                    return false

                }

                if (!titleSpecies) {
                    setIsSubmit(false)
                    setTitleSpeciesInvalid(true);
                    alert("Selecione a espécie do título")

                    return false

                }
            }

            if (selectedBankIsInter) {
                // Validação específica para o banco Inter
                const requiresCertificateUpload = !savedInterCertificatePath;
                const requiresKeyUpload = !savedInterKeyPath;

                if (!interClientId) {
                    setIsSubmit(false);
                    setInterClientIdInvalid(true);
                    alert("Preencha o ClientId corretamente");
                    return false;
                }

                if (!interClientSecret) {
                    setIsSubmit(false);
                    setInterClientSecretInvalid(true);
                    alert("Preencha o ClientSecret corretamente");
                    return false;
                }

                if (requiresCertificateUpload && !interCertificateFile) {
                    setIsSubmit(false);
                    alert("Faça upload do arquivo do certificado Inter");
                    return false;
                }

                // Validando se o arquivo é do tipo .crt
                if (interCertificateFile && interCertificateFile.type !== 'application/x-x509-ca-cert') {
                    setIsSubmit(false);
                    alert("O arquivo do certificado Inter fornecido não possui um formato válido");
                    return false;
                }

                if (requiresKeyUpload && !interKeyFile) {
                    setIsSubmit(false);
                    alert("Faça upload do arquivo da chave Inter");
                    return false;
                }

                if (interKeyFile && getFileExtension(interKeyFile.name) !== 'key') {
                    setIsSubmit(false);
                    alert("O arquivo da chave Inter fornecido não possui um formato válido");
                    return false;
                }
            }
        }

        handleNextEdit();
        setCurrentStepEdit(currentStepEdit + 1);

        return true

    }

    function cleanBeforeAccount() {
        setIdAccount("");
        setBank("");
        setNameAccount("");
        setOpeningBalance("");
        setInitialDate("");
        setAgency("");
        setAgencyDigit("");
        setAccount("");
        setAccountDigit("");
        setSituation("");
        setStandardAccountForRecipes("n");
        setStandardExpenseAccount("n");
        setWillGenerateBills("n");
        setWallet("");
        setCodeAssignor("");
        setCovenantCode("");
        setWalletVariation("");
        setInstructions("");
        setDemonstrative("");
        setPercentageOfFinePerMonth("");
        setInterestPercentage("");
        setInterestDays("");
        setShipmentSequence("");
        setBilletSequence("");
        setProtestInstruction("");
        setDefaultLayout("Cnab400");
        setTitleSpecies("DM");
        setActiveStepEdit(0);
        setCurrentStep(1);
        setCurrentStepEdit(1);
        setInterClientId('');
        setInterClientSecret('');
        setInterCertificateFile(null);
        setInterKeyFile(null);
        setFineDays('');
        setInterDaysToCancelBillet(0);

        if (interCertificateFileRef.current) {
            interCertificateFileRef.current.files = null;
            interCertificateFileRef.current.value = '';
        }
        if (interKeyFileRef.current) {
            interKeyFileRef.current.value = '';
            interKeyFileRef.current.files = null;
        }
    }

    function cleanInvalids() {
        setNameAccountInvalid(false);
        setOpeningBalanceInvalid(false);
        setInitialDateInvalid(false);
        setAgencyInvalid(false);
        setAgencyDigitInvalid(false);
        setAccountInvalid(false);
        setAccountDigitInvalid(false);
        setShipmentSequenceInvalid(false);
        setBilletSequenceInvalid(false);
        setDefaultLayoutInvalid(false);
        setTitleSpeciesInvalid(false);
        setCovenantCodeInvalid(false);
        setCodeAssignorInvalid(false);
        setContractsWithAccountBankError(false);
        setExpensesWithAccountBankError(false);
        setSituationInvalid(false);
        setInterClientIdInvalid(false);
        setInterClientSecretInvalid(false);
    }

    async function getAccount(id: string | number) {
        const account = await api.get<BankAccount>(`/accountBank/${id}`);

        setIdAccount(String(account.data.id ?? ''));
        setBank(account.data.nameBank ?? '');
        setNameAccount(account.data.name ?? '');
        setNumberBank(account.data.numberBank ?? '');
        setOpeningBalance(account.data.openingBalance ?? '');
        setInitialDate(account.data.initialDate ?? '');
        setAgency(account.data.agency ?? '');
        setAgencyDigit(account.data.agencyDigit ?? '');
        setAccount(account.data.account ?? '');
        setAccountDigit(account.data.accountDigit ?? '');
        setSituation(account.data.situation ?? '');
        setStandardAccountForRecipes(account.data.standardAccountForRecipes ?? '');
        setStandardExpenseAccount(account.data.standardExpenseAccount ?? '');
        setWillGenerateBills(account.data.willGenerateBills ?? '');
        setAccountAcceptEdit(account.data.accept ?? '');
        setWallet(account.data.wallet ?? '');
        setCodeAssignor(account.data.codeAssignor ?? '');
        setCovenantCode(account.data.covenantCode ?? '');
        setWalletVariation(account.data.walletVariation ?? '');
        setInstructions(account.data.instructions ?? '');
        setDemonstrative(account.data.demonstrative ?? '');
        setPercentageOfFinePerMonth(account.data.percentageOfFinePerMonth ?? '');
        setInterestPercentage(account.data.interestPercentage ?? '');
        setInterestDays(account.data.interestDays ?? '');
        setShipmentSequence(String(account.data.shipmentSequence ?? ''));
        setBilletSequence(String(account.data.billetSequence ?? ''));
        // setProtestInstruction(account.data.protestInstruction ?? '');
        setDefaultLayout(account.data.defaultLayout ?? '');
        setTitleSpecies(account.data.titleSpecies ?? '');
        setInterClientId(account.data.interClientId ?? '');
        setInterClientSecret(account.data.interClientSecret ?? '');
        setFineDays(String(account.data.fineDays ?? ''));
        setInterDaysToCancelBillet(account.data.interDaysToCancelBillet);

        setSavedInterCertificatePath(account.data.interCertificatePath);
        setSavedInterKeyPath(account.data.interKeyPath);

        setBeforeSubmitData(account.data);
    }

    async function disableOtherDefaultRevenue(id: string | number) {
        const otherAccounts = await api.get(`/accountsBank`);


        for (var account of otherAccounts.data) {
            if (account.id != id && account.standardAccountForRecipes == "y") {
                await api.post(`/accountBank/${account.id}`, { standardAccountForRecipes: "n" });
            }
        }
    }

    async function disableOtherDefaultExpense(id: string | number) {
        const otherAccounts = await api.get(`/accountsBank`);

        for (var account of otherAccounts.data) {
            if (account.id != id && account.standardExpenseAccount == "y") {
                await api.post(`/accountBank/${account.id}`, { standardExpenseAccount: "n" });
            }
        }
    }

    function handleNextEdit() {
        setActiveStepEdit(prevActiveStep => prevActiveStep + 1);
    }

    function handleCloseEditAccountBankModal() {
        setShowModal(false);
        setAccountBankIdToEdit(0);
        cleanBeforeAccount();
        cleanInvalids();
    }

    const handleChangeInterCertificateFile = (fileList: FileList | null) => {
        setInterCertificateFile(fileList && fileList.length > 0 ? fileList[0] : null);
    };
    const handleChangeInterKeyFile = (fileList: FileList | null) => {
        setInterKeyFile(fileList && fileList.length > 0 ? fileList[0] : null);
    };

    return (

        <Modal
            show={showModal}
            onHide={handleCloseEditAccountBankModal}
            aria-labelledby="contained-modal-warning"
            size="xl"
            centered
            className="newProductWrapper"
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Detalhes da conta bancária
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div>
                    <Stepper activeStep={activeStepEdit} orientation="vertical">
                        <Step key={2}>
                            <StepLabel>
                                <Grid container spacing={3}>
                                    <Grid item lg={6}>Informações básicas sobre a conta bancária</Grid>

                                    <Grid item lg={6} className="d-flex align-items-center justify-content-end">
                                        {(currentStepEdit > 0)
                                            ?
                                            <>
                                                {
                                                    user.isAccountant == "n"
                                                        ?
                                                        <>
                                                            {(activeStepEdit === 2)
                                                                ? <Button variant="primary" className="mr-3" onClick={() => {
                                                                    editAccount();
                                                                }}>
                                                                    {isSubmit ? <>

                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />

                                                                        <span className='ml-2'>
                                                                            Aguarde...
                                                                        </span>

                                                                    </> : <>

                                                                        <span>
                                                                            Salvar
                                                                        </span>

                                                                    </>}
                                                                </Button>
                                                                : <Button variant="primary" className="mr-3" onClick={() => {
                                                                    inputsVerify()
                                                                }}>Continuar</Button>
                                                            }
                                                        </>
                                                        : <Button variant="primary" className="mr-3" onClick={() => {
                                                            inputsVerify()
                                                        }}>Continuar</Button>
                                                }
                                            </>
                                            : ""
                                        }
                                        <Button variant="secondary" onClick={handleCloseEditAccountBankModal}>Fechar</Button>
                                    </Grid>
                                </Grid>

                            </StepLabel>
                            <StepContent>
                                <Card className="mt-5">
                                    <Card.Body className="row">
                                        <Grid container spacing={3} className="mb-3">
                                            <Grid item lg={3}
                                                className="d-flex flex-column justify-content-center">
                                                <TextField
                                                    select
                                                    size="small"
                                                    label="Banco"
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={bank}
                                                    onChange={(e) => setBank(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                    <MenuItem key="0" disabled>
                                                        Selecione
                                                    </MenuItem>

                                                    {
                                                        banks.map(function (object, index) {
                                                            return (
                                                                <MenuItem key={object.number} value={object.name} onClick={() => setNumberBank(object.number)}>
                                                                    {object.name}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }

                                                </TextField>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <TextField
                                                    label="Nome da conta"
                                                    size="small"
                                                    required
                                                    placeholder="Digite o nome da conta"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={nameAccount}
                                                    onChange={(e) => {
                                                        setNameAccount(e.target.value)
                                                        setNameAccountInvalid(e.target.value ? false : true)
                                                    }}
                                                    error={nameAccountInvalid}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={2}>
                                                <NumericFormat
                                                    label="Saldo Inicial"
                                                    required
                                                    startAdornment="R$"
                                                    value={openingBalance}
                                                    onChange={(e) => {
                                                        setOpeningBalance(e.target.value)
                                                        setOpeningBalanceInvalid(e.target.value ? false : true)
                                                    }}
                                                    error={openingBalanceInvalid}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>

                                            <Grid item lg={3} className="d-flex align-items-center">
                                                <TextField
                                                    label="Data Inicial"
                                                    type="date"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={initialDate}
                                                    onChange={(e) => {
                                                        setInitialDate(e.target.value)
                                                        setInitialDateInvalid(e.target.value ? false : true)
                                                    }}
                                                    error={initialDateInvalid}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item lg={3}>
                                                <strong>Agência {numberBank == "000" ? "" : "*"}:</strong>

                                                <Row>
                                                    <Col lg={8}>
                                                        <TextField
                                                            size="small"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={agency}
                                                            onChange={(e) => {
                                                                setAgency(e.target.value)
                                                                setAgencyInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={agencyInvalid}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Col>

                                                    <Col lg={4}>
                                                        <TextField
                                                            size="small"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={agencyDigit}
                                                            onChange={(e) => {
                                                                setAgencyDigit(e.target.value)
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta {numberBank == "000" ? "" : "*"}:</strong>
                                                <Row>
                                                    <Col lg={8}>
                                                        <TextField
                                                            size="small"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={account}
                                                            onChange={(e) => {
                                                                setAccount(e.target.value)
                                                                setAccountInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={accountInvalid}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Col>

                                                    <Col lg={4}>
                                                        <TextField
                                                            size="small"
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={accountDigit}
                                                            onChange={(e) => {
                                                                setAccountDigit(e.target.value)
                                                                setAccountDigitInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={accountDigitInvalid}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} className="pt-7">
                                            <Grid item lg={3} className={contractsWithAccountBankError || expensesWithAccountBankError ? 'border border-danger' : ''}>
                                                <strong>Situação:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={(situation === "y") ? true : false}
                                                                onChange={(e) => setSituation((e.target.checked) ? "y" : "n")}
                                                                value={situation}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta padrão para receitas:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={(standardAccountForRecipes === "y") ? true : false}
                                                                onChange={(e) => setStandardAccountForRecipes((e.target.checked) ? "y" : "n")}
                                                                value={standardAccountForRecipes}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta padrão para despesas:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={(standardExpenseAccount === "y") ? true : false}
                                                                onChange={(e) => setStandardExpenseAccount((e.target.checked) ? "y" : "n")}
                                                                value={standardExpenseAccount}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {contractsWithAccountBankError || expensesWithAccountBankError ? (
                                            <Grid container spacing={3} className="pt-7">
                                                <Grid item lg={3}>
                                                    <div className="text-danger">
                                                        Não é possível inativar a conta bancária pois existem contratos ou despesas recorrentes vinculados com esta conta.
                                                    </div>
                                                    {contractsWithAccountBankError && (
                                                        <Link to={`/contrato-servico/?bankAccount=${idAccount}`} className="btn btn-primary mr-3">
                                                            Ver contratos
                                                        </Link>
                                                    )}
                                                    {expensesWithAccountBankError && (
                                                        <Link to={`/despesas-recorrentes/?bankAccount=${idAccount}`} className="btn btn-primary">
                                                            Ver despesas recorrentes
                                                        </Link>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ) : <></>}

                                        {
                                            selectedBankCanGenerateBillets
                                                ?
                                                <>
                                                    <Grid container spacing={3} className="mt-7 border-top">
                                                        <Grid item lg={5}>
                                                            <strong>Esta conta irá gerar boletos bancários (com registro)?</strong>
                                                            <Typography component="div">
                                                                <Grid component="label" container
                                                                    alignItems="center" spacing={1}>
                                                                    <Grid item>Não</Grid>
                                                                    <Grid item>
                                                                        <AntSwitch
                                                                            checked={(willGenerateBills === "y") ? true : false}
                                                                            onChange={(e) => setWillGenerateBills((e.target.checked) ? "y" : "n")}
                                                                            value={willGenerateBills}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>Sim</Grid>
                                                                </Grid>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {(willGenerateBills === "y")
                                                        ?
                                                        <>
                                                            <Grid container spacing={3} className="mt-3">
                                                                <Grid item lg={4}>


                                                                    {(() => {

                                                                        switch (bank) {
                                                                            case 'Itaú':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        label="Carteira"
                                                                                        size="small"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        value={wallet}
                                                                                        onChange={(e) => {
                                                                                            setWallet(e.target.value)
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="109">109 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="2" value="110">110 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="3" value="111">111 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="4" value="112">112 - Escritural Eletrônica - simples / contratual</MenuItem>
                                                                                        <MenuItem key="5" value="115">115 - Carteira 115</MenuItem>
                                                                                        <MenuItem key="6" value="121">121 - Direta Eletrônica Emissão Parcial - Simples/Contra</MenuItem>
                                                                                        <MenuItem key="7" value="188">188 - Carteira 188</MenuItem>
                                                                                        <MenuItem key="8" value="180">180 - Direta Eletrônica Emissão Integral</MenuItem>
                                                                                    </TextField>
                                                                                );
                                                                            case 'Bradesco':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        label="Carteira"
                                                                                        size="small"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        value={wallet}
                                                                                        onChange={(e) => {
                                                                                            setWallet(e.target.value)
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="09">09 - Com Registro</MenuItem>
                                                                                        <MenuItem key="2" value="21">21 - Com Registro - Pagável somente no Bradesco</MenuItem>
                                                                                        <MenuItem key="3" value="26">26 - Com Registro – Emissão na Internet</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Banco do Brasil':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        label="Carteira"
                                                                                        size="small"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        value={wallet}
                                                                                        onChange={(e) => {
                                                                                            setWallet(e.target.value)
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="11">11 - Cobrança com registro Simples</MenuItem>
                                                                                        <MenuItem key="2" value="12">12 - Cobrança com registro Indexada</MenuItem>
                                                                                        <MenuItem key="3" value="15">15 - Cobrança com registro Prêmios de Seguro</MenuItem>
                                                                                        <MenuItem key="4" value="17">17 - Cobrança com registro Direta Especial</MenuItem>
                                                                                        <MenuItem key="5" value="18">18 - Cobrança com registro Simples</MenuItem>
                                                                                        <MenuItem key="6" value="31">31 - Cobrança com registro Caucionada</MenuItem>
                                                                                        <MenuItem key="7" value="51">51 - Cobrança com registro Descontada</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Santander':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        label="Carteira"
                                                                                        size="small"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        value={wallet}
                                                                                        onChange={(e) => {
                                                                                            setWallet(e.target.value)
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="101">101 - Cobrança Simples</MenuItem>
                                                                                        <MenuItem key="2" value="201">201 - Penhor Rápida</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Caixa Econômica Federal':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        label="Carteira"
                                                                                        size="small"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        value={wallet}
                                                                                        onChange={(e) => {
                                                                                            setWallet(e.target.value)
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="RG">RG - Com Registro</MenuItem>
                                                                                    </TextField>
                                                                                );
                                                                        }
                                                                    })()}
                                                                </Grid>

                                                                {(() => {

                                                                    switch (bank) {
                                                                        case 'Itaú':
                                                                            return (
                                                                                <>

                                                                                </>
                                                                            );
                                                                        case 'Caixa Econômica Federal':
                                                                        case 'Santander':
                                                                        case 'Bradesco':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            label="Convênio (código do cendente)"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            value={codeAssignor}
                                                                                            onChange={(e) => {
                                                                                                setCodeAssignor(e.target.value)
                                                                                                setCodeAssignorInvalid(e.target.value.length > 0 ? false : true)
                                                                                            }}
                                                                                            error={codeAssignorInvalid}
                                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            );

                                                                        case 'Banco do Brasil':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            label="Convênio (código do cedente)"
                                                                                            size="small"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            value={covenantCode}
                                                                                            onChange={(e) => {
                                                                                                setCovenantCode(e.target.value)
                                                                                                setCovenantCodeInvalid(e.target.value.length != 4 && e.target.value.length != 6 && e.target.value.length != 7 ? true : false)
                                                                                            }}
                                                                                            error={covenantCodeInvalid}
                                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                                        >
                                                                                        </TextField>
                                                                                    </Grid>

                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            label="Variação de carteira"
                                                                                            size="small"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            value={walletVariation}
                                                                                            onChange={(e) => setWalletVariation(e.target.value)}
                                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                                        >
                                                                                        </TextField>
                                                                                    </Grid>
                                                                                </>
                                                                            );
                                                                    }


                                                                })()}
                                                            </Grid>
                                                        </>
                                                        : ''
                                                    }
                                                </>
                                                : <></>
                                        }

                                    </Card.Body>
                                </Card>
                            </StepContent>
                        </Step>

                        {
                            selectedBankCanGenerateBillets && willGenerateBills === 'y'
                                ?
                                [
                                    <Step key={3}>
                                        <StepLabel>Informações avançadas sobre a conta bancária</StepLabel>
                                        <StepContent>

                                            {selectedBankIsInter ? (
                                                <Card className="mt-5">
                                                    {/* CONFIGURACOES DE BOLETOS DO BANCO INTER */}
                                                    <Card.Body className="row">
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={8} className="mb-3">
                                                                <TextField
                                                                    label="Instruções que serão impressas no boleto"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    rowsMax={5}
                                                                    inputProps={{ maxLength: 390 }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={instructions}
                                                                    onChange={(e) => setInstructions(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Multa"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    value={percentageOfFinePerMonth}
                                                                    onChange={(e) => setPercentageOfFinePerMonth(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Multa"
                                                                    margin="normal"
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    value={fineDays}
                                                                    onChange={(e) => setFineDays(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Juros"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    value={interestPercentage}
                                                                    onChange={(e) => setInterestPercentage(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Juros"
                                                                    margin="normal"
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    value={interestDays}
                                                                    onChange={(e) => setInterestDays(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <div className="mt-1">&nbsp;</div>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={4}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Cancelamento automático"
                                                                    helperText="Em dias (após vencimento)"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={String(interDaysToCancelBillet)}
                                                                    onChange={(e) => setInterDaysToCancelBillet(Number(e.target.value || '0'))}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <h5 className="mt-7">Configurações da aplicação do Banco Inter:</h5>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={6}>
                                                                <TextField
                                                                    size="small"
                                                                    label="ClientId *"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={interClientId}
                                                                    onChange={(e) => setInterClientId(e.target.value)}
                                                                    error={interClientIdInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={6}>
                                                                <TextField
                                                                    size="small"
                                                                    label="ClientSecret *"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={interClientSecret}
                                                                    onChange={(e) => setInterClientSecret(e.target.value)}
                                                                    error={interClientSecretInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            {user.isAccountant == "n" && (
                                                                <Grid item lg={6}>
                                                                    <label htmlFor="formFile" className="mt-5">
                                                                        Alterar arquivo de Certificado (arquivo .crt)
                                                                    </label>

                                                                    <input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="interCertificateFile"
                                                                        ref={interCertificateFileRef}
                                                                        onChange={(e) => handleChangeInterCertificateFile(e.target.files)}
                                                                        accept=".crt"
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {savedInterCertificatePath && (
                                                                <Grid item lg={6}>
                                                                    <label htmlFor="formFile" className="mt-5">
                                                                        Arquivo de Certificado salvo
                                                                    </label>

                                                                    <div className="d-block">
                                                                        <a href={`${apiUrl}/${savedInterCertificatePath}`} target="_blank" download>Certificado (Clique para baixar)</a>
                                                                    </div>
                                                                </Grid>
                                                            )}
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            {user.isAccountant == "n" && (
                                                                <Grid item lg={6}>
                                                                    <label htmlFor="formFile" className="mt-5">
                                                                        Alterar arquivo de Chave (arquivo .key)
                                                                    </label>

                                                                    <input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="interKeyFile"
                                                                        ref={interKeyFileRef}
                                                                        onChange={(e) => handleChangeInterKeyFile(e.target.files)}
                                                                        accept=".key"
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {savedInterKeyPath && (
                                                                <Grid item lg={6}>
                                                                    <label htmlFor="formFile" className="mt-5">
                                                                        Arquivo de Chave salvo
                                                                    </label>

                                                                    <div className="d-block">
                                                                        <a href={`${apiUrl}/${savedInterKeyPath}`} target="_blank" download>Chave (Clique para baixar)</a>
                                                                    </div>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Card.Body>
                                                </Card>
                                            ) : (
                                                <Card className="mt-5">
                                                    {/* CONFIGURACOES DE BOLETOS DOS DEMAIS BANCOS */}
                                                    <Card.Body className="row">
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={6} className="mb-3">
                                                                <TextField
                                                                    label="Instruções que serão impressas no boleto"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={instructions}
                                                                    onChange={(e) => setInstructions(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={6} className="mb-3">
                                                                <TextField
                                                                    label="Demonstrativo"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={demonstrative}
                                                                    onChange={(e) => setDemonstrative(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Multa"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    value={percentageOfFinePerMonth}
                                                                    onChange={(e) => setPercentageOfFinePerMonth(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Juros"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    value={interestPercentage}
                                                                    onChange={(e) => setInterestPercentage(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    label="Juros"
                                                                    margin="normal"
                                                                    inputProps={{ min: 1 }}
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    value={interestDays}
                                                                    onChange={(e) => setInterestDays(e.target.value)}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 1 }}
                                                                    label="Sequência da remessa"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={shipmentSequence}
                                                                    onChange={(e) => {
                                                                        setShipmentSequence(e.target.value)
                                                                        setShipmentSequenceInvalid(e.target.value ? false : true)
                                                                    }}
                                                                    error={shipmentSequenceInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    label="Sequência do boleto"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={billetSequence}
                                                                    onChange={(e) => {
                                                                        setBilletSequence(e.target.value)
                                                                        setBilletSequenceInvalid(e.target.value ? false : true)
                                                                    }}
                                                                    error={billetSequenceInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <h5 className="mt-7">Configurações de remessa:</h5>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    select
                                                                    label="Layout padrão (CNAB)"
                                                                    size="small"
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={defaultLayout}
                                                                    onChange={(e) => {
                                                                        setDefaultLayout(e.target.value)
                                                                        setDefaultLayoutInvalid(e.target.value ? false : true)
                                                                    }}
                                                                    error={defaultLayoutInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                >

                                                                    <MenuItem key="0" value="Cnab240">
                                                                        240
                                                                    </MenuItem>

                                                                    <MenuItem key="1" value="Cnab400">
                                                                        400
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    select
                                                                    label="Espécie do título"
                                                                    size="small"
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={titleSpecies}
                                                                    onChange={(e) => {
                                                                        setTitleSpecies(e.target.value)
                                                                        setDefaultLayoutInvalid(e.target.value ? false : true)
                                                                    }}
                                                                    error={titleSpeciesInvalid}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                >
                                                                    <MenuItem key="0" value="">
                                                                        Selecione
                                                                    </MenuItem>

                                                                    <MenuItem key="1" value="DM">
                                                                        DM - Duplicata mercantil
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </StepContent>
                                    </Step>,
                                    <Step key={4}>
                                        <StepLabel>Confirmação de dados</StepLabel>
                                        <StepContent>

                                            <Card className="mt-5">
                                                <Card.Body className="row">
                                                    <h5 className="mb-3">Informações básicas</h5>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Banco:</strong>
                                                            {bank}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Nome da conta:</strong>
                                                            {nameAccount}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Data Inicial:</strong>
                                                            {initialDate}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Agência:</strong>
                                                            {agency + (agencyDigit ? '-' + agencyDigit : '')}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Conta:</strong>
                                                            {account + (accountDigit ? '-' + accountDigit : '')}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Situação:</strong>
                                                            {situation === 'y' ? 'Ativo' : 'Inativo'}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Padrão receita:</strong>
                                                            {standardAccountForRecipes === 'y' ? 'Ativo' : 'Inativo'}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Padrão despesa:</strong>
                                                            {standardExpenseAccount === 'y' ? 'Ativo' : 'Inativo'}
                                                        </Grid>
                                                    </Grid>
                                                </Card.Body>
                                            </Card>
                                        </StepContent>
                                    </Step>
                                ]

                                :
                                <Step key={4}>
                                    <StepLabel>Confirmação de dados</StepLabel>
                                    <StepContent>

                                        <Card className="mt-5">
                                            <Card.Body className="row">
                                                <h5 className="mb-3">Informações básicas</h5>

                                                <Grid container spacing={3} className="mb-3">
                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Banco:</strong>
                                                        {bank}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Nome da conta:</strong>
                                                        {nameAccount}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Data Inicial:</strong>
                                                        {initialDate.split('-').reverse().join('/')}
                                                    </Grid>
                                                </Grid>

                                                {
                                                    numberBank != "000"
                                                        ?
                                                        <Grid container spacing={3} className="mb-3">
                                                            <Grid item lg={4}
                                                                className="mb-1 d-flex flex-column justify-content-center">
                                                                <strong>Agência:</strong>
                                                                {agency + (agencyDigit ? '-' + agencyDigit : '')}
                                                            </Grid>

                                                            <Grid item lg={4}
                                                                className="mb-1 d-flex flex-column justify-content-center">
                                                                <strong>Conta:</strong>
                                                                {account + (accountDigit ? '-' + accountDigit : '')}
                                                            </Grid>
                                                        </Grid>
                                                        : <></>
                                                }

                                                <Grid container spacing={3} className="mb-3">
                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Situação:</strong>
                                                        {situation === 'y' ? 'Ativo' : 'Inativo'}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Padrão receita:</strong>
                                                        {standardAccountForRecipes === 'y' ? 'Ativo' : 'Inativo'}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Padrão despesa:</strong>
                                                        {standardExpenseAccount === 'y' ? 'Ativo' : 'Inativo'}
                                                    </Grid>
                                                </Grid>
                                            </Card.Body>
                                        </Card>
                                    </StepContent>
                                </Step>
                        }
                    </Stepper>
                </div>
            </Modal.Body >
        </Modal >
    );
}