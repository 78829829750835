import React, { useState, useEffect } from 'react';
import api from '../../services/Api';
import { Button, Modal } from 'react-bootstrap';

export type ModalType = {
    modalSubDelete: boolean
    setModalSubDelete: React.Dispatch<React.SetStateAction<boolean>>

    idSubDelete: number
    setIdSubDelete: React.Dispatch<React.SetStateAction<number>>

    nameSubDelete: string
    setNameSubDelete: React.Dispatch<React.SetStateAction<string>>

    type: string
    
    getCategoriesRevenue: () => void
    getCategoriesExpense: () => void
}

export default function ModalSubDelete(props: ModalType) {

    async function deleteSub() {
        await api.put(`/dre/deleteSub/` + props.idSubDelete, {
            active: 'n',
            type: props.type == "revenue" ? "revenue_dre_sub_category" : "expense_dre_sub_category"
        })
        .then(response => {
            props.getCategoriesRevenue()
            props.getCategoriesExpense()
        })
        .catch(error => {
            console.log('Ocorreu algum erro')
        })
        .finally(() => {
            props.setModalSubDelete(false)
        })
    }

    return (
        <Modal
            show={props.modalSubDelete}
            onHide={() => props.setModalSubDelete(false)}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Excluir Sub-Categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Voce realmente deseja excluir a sub-categoria <b>{props.nameSubDelete}</b> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={() => props.setModalSubDelete(false)}>Cancelar</Button>
                <Button variant="danger" type="button" onClick={deleteSub}>Excluir</Button>
            </Modal.Footer>
        </Modal>
    )
}