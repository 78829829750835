import { SuperConfig } from "../types/SuperConfig";
import api from "./Api";

class SuperConfigService {
    async getConfig() {
        const superConfigResponse = await api.get<SuperConfig>('/superConfig');
        const superConfig = superConfigResponse.data;

        return superConfig;
    }
}

export default new SuperConfigService();