import { useEffect, useState } from "react";
import { useCompanyBranch } from "./companyBranch";

export function useNf() {
    const { selectedCompany } = useCompanyBranch({ withNfApiConfig: true });
    const [nfceEnabled, setNfceEnabled] = useState(true);
    const [nfeEnabled, setNfeEnabled] = useState(true);
    const [nfseEnabled, setNfseEnabled] = useState(true);
    const [nfeManifestEnabled, setNfeManifestEnabled] = useState(true);

    useEffect(() => {
        if(!selectedCompany) return;

        setNfceEnabled(!!selectedCompany.nfApiConfig?.habilita_nfce);
        setNfeEnabled(!!selectedCompany.nfApiConfig?.habilita_nfe);
        setNfseEnabled(!!selectedCompany.nfApiConfig?.habilita_nfse);
        setNfeManifestEnabled(!!selectedCompany.nfApiConfig?.habilita_manifestacao);
    }, [selectedCompany]);

    return {
        nfceEnabled,
        nfeEnabled,
        nfseEnabled,
        nfeManifestEnabled,
    };
}