/** OBS: ESSA MESMA CLASSE TBM EXISTE NA API, MANTER FUNÇÕES IGUAIS (NO WEB TEM MAIS FUNÇÕES) */

import { ExtraPermissions, ExtraPermissionsMapped, PermissionMenu } from "../types/PermissionMenu";

export enum menuIdEnum {
    BILLS_TO_PAY = 24,
    BILLS_TO_RECEIVE = 25,
};

export enum extraPermissionEnum {
    CAN_CHANGE_BILL_SITUATION_OF_PAST_MONTH = 'canChangeBillSituationOfPastMonth',
}

const billsToPayMenuId = menuIdEnum.BILLS_TO_PAY as number;
const billsToReceiveMenuId = menuIdEnum.BILLS_TO_RECEIVE as number;
const billExtraPermissions: ExtraPermissions[] = [{
    key: extraPermissionEnum.CAN_CHANGE_BILL_SITUATION_OF_PAST_MONTH as string,
    label: 'Administrador de fechamento de mês',
    checked: false,
}];

class PermissionMenuService {
    /** 
     * Retorna apenas os menus que contém nas permissões da empresa
     */
    filterCompanyPermissionMenu(menus: PermissionMenu[], companyPermissions: Array<number>): PermissionMenu[] {
        let filtered = menus.filter((menu) => companyPermissions.includes(menu.id));
        if(!filtered || filtered.length === 0) {
            return [];
        }

        filtered = filtered.map((menu) => {
            const extraPermissions = this.getMenuExtraPermissions(menu.id);
            if (extraPermissions) {
                return { ...menu, extraPermissions };
            }
            return menu;
        })

        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = this.filterCompanyPermissionMenu(filtered[i].submenus as PermissionMenu[], companyPermissions).filter(() => true);
            }
        }
        return filtered;
    }

    /** 
     * Retorna apenas os menus que estão marcados (checked)
     */
    filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
        const filtered = menus.filter((menu) => menu.checked);
        if(!filtered || filtered.length === 0) {
            return [];
        }
        for(let i = 0; i < filtered.length; i++) {
            if(filtered[i].submenus?.length) {
                filtered[i].submenus = this.filterPermissionMenu(filtered[i].submenus as PermissionMenu[]).filter(() => true);
            }
        }
        return filtered;
    }

    /**
     * Retorna um array com os ids dos menus
    */
    mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
        let mapped = menus.map((menu) => menu.id);
        for(let i = 0; i < menus.length; i++) {
            if(menus[i].submenus?.length) {
                mapped = mapped.concat(this.mapPermissionMenu(menus[i].submenus as PermissionMenu[]));
            }
        }
        return mapped;
    }

    /**
     * Marca como selecionado os menus de acordo com o array de permissoes passado (array com os ids dos menus)
    */
    mapPermissionsChecked(permissions: Array<number>, menus: PermissionMenu[]) {
        const aux = menus;
        for(let i = 0; i < aux.length; i++) {
            aux[i].checked = permissions.includes(aux[i].id);
            if(aux[i].submenus?.length) {
                aux[i].submenus = this.mapPermissionsChecked(permissions, aux[i].submenus as PermissionMenu[]);
            }
        }
        return aux;
    }

    /**
     * Recupera as permissões extras de um determinado menu
    */
    getMenuExtraPermissions(menuId: number) {
        if ([billsToPayMenuId, billsToReceiveMenuId].includes(menuId)) {
            return billExtraPermissions.filter(item => true);
        } else {
            return null;
        }
    }

    /**
     * Recupera os objetos de permissões extras em formato de string, com base nas permissões de menu (array de ids) passadas
    */
    getMenuExtraPermissionsFromMenuPermissions(menuPermissionsString: string) {
        const menuPermissionsArray: number[] = JSON.parse(menuPermissionsString || '[]');

        const extraPermissions: ExtraPermissionsMapped[] = [];

        for (const permission of menuPermissionsArray) {
            const foundExtraPermissions = this.getMenuExtraPermissions(permission)
            if (foundExtraPermissions && foundExtraPermissions.length) {
                for (const extra of foundExtraPermissions) {
                    extraPermissions.push({
                        menuId: permission,
                        key: extra.key,
                    });
                }
            }
        }

        return extraPermissions;
    }

    /**
     * Extrai as permissões extras do menu que foram selecionadas, convertando para um objeto
    */
    mapMenuExtraPermissions(menus: PermissionMenu[]): ExtraPermissionsMapped[] {
        let mapped: ExtraPermissionsMapped[] = [];

        for (const menu of menus) {
            if (menu.extraPermissions) {
                menu.extraPermissions.forEach((extra) => {
                    if (extra.checked) {
                        mapped.push({
                            menuId: menu.id,
                            key: extra.key,
                        });
                    }
                })
            }

            if(menu.submenus?.length) {
                mapped = mapped.concat(this.mapMenuExtraPermissions(menu.submenus as PermissionMenu[]));
            }
        }

        return mapped;
    }

    /**
     * Marca como selecionado as permissões extras de cada menu de acordo com o array de permissoes extras passado 
    */
    mapMenuExtraPermissionsChecked(savedExtraPermissions: ExtraPermissionsMapped[], menus: PermissionMenu[]) {
        const aux = menus.filter((item) => true);

        for (const menu of aux) {
            if (menu.extraPermissions) {
                menu.extraPermissions!.forEach((extra, extraIndex) => {
                    const foundExtraPermission = savedExtraPermissions.find((savedExtra) => savedExtra.menuId === menu.id && savedExtra.key === extra.key);
                    if (foundExtraPermission) {
                        menu.extraPermissions![extraIndex] = { ...menu.extraPermissions![extraIndex], checked: true };
                    }
                })
            }

            if(menu.submenus?.length) {
                menu.submenus = this.mapMenuExtraPermissionsChecked(savedExtraPermissions, menu.submenus as PermissionMenu[]);
            }
        }

        for(let i = 0; i < aux.length; i++) {
            
        }
        return aux;
    }
}

export default new PermissionMenuService();