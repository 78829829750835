import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import api from '../../../services/Api';
import { FormBpm } from '../../../types/FormBpm';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "name", value: "Nome" },
];

export function ListModelForms() {
    const { user } = useSelector((state: any) => state.auth);

    const { push: pushHistory, location: { pathname } } = useHistory();

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [modelForms, setModelForms] = useState<any[]>([]);
    const [countTotalForms, setCountTotalForms] = useState(0);
    
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        // const { data } = await api.get<{rows: FormBpm[], count: number}>("formBpm", {
        //     params: {
        //         skip: rowsPerPage * currentPage,
        //         take: rowsPerPage,
        //         filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
        //         sortReference,
        //         sortDirection,
        //     }
        // });

        // const {rows, count} = data;

        // setModelForms(rows);
        // setCountTotalForms(count);
    }, []);
    
    useLayoutEffect(() => {
        const fetch = async() => {
            const list: BodyDataBaseProps[][] = [];
            // const aux = modelForms;

            let resp = await api.get("/bpm/form/displayAll");
            console.log(resp);
            let data: any = resp.data;

            data.forEach((form: any) => {
                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(form.id), id: true },
                    { for: "name", value: String(form.name) },
                ];

                list.push(data);
            });

            setBodyData(list);
        }
        fetch().then();
    }, []);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery
        };

        reloadData();
    }, [modelForms, searchQuery]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar/0`);
    }, []);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/adicionar/${id}`);
    }, []);
    
    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ? 
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Novo Formulário
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <div className="mt-3">
				<ListWithModalChangeSituation
					headData={headData}
					bodyData={bodyData}
					onEdit={handleClickEdit}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalForms}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
				/>
			</div>
        </div>
    );
}