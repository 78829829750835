const validateCpf = (cpf: string): boolean => {
    if (cpf.length !== 11) {
        return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    let weight = 10;

    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * weight;
        weight--;
    }

    const remainder = sum % 11;
    const firstDigit = (remainder < 2) ? 0 : (11 - remainder);

    if (parseInt(cpf.charAt(9)) !== firstDigit) {
        return false;
    }

    sum = 0;
    weight = 11;

    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * weight;
        weight--;
    }

    const secondRemainder = sum % 11;
    const secondDigit = (secondRemainder < 2) ? 0 : (11 - secondRemainder);

    return parseInt(cpf.charAt(10)) === secondDigit;
};

const validateCnpj = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let dvLength = cnpj.length - 2
    let numbers = cnpj.substring(0,dvLength);
    let digits = cnpj.substring(dvLength);
    let sum = 0;
    let pos = dvLength - 7;

    for (let i = dvLength; i >= 1; i--) {
      sum += Number(numbers.charAt(dvLength - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;

    if (result !== Number(digits.charAt(0)))
        return false;
         
    dvLength = dvLength + 1;
    numbers = cnpj.substring(0,dvLength);
    sum = 0;
    pos = dvLength - 7;

    for (let i = dvLength; i >= 1; i--) {
      sum += Number(numbers.charAt(dvLength - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - sum % 11;

    if (result != Number(digits.charAt(1)))
          return false;
           
    return true;
};

export function validateCpfCnpj(input: string): boolean {
    const cleanedInput = input.replace(/\D/g, '');

    if (cleanedInput.length === 11) {
        if (!validateCpf(cleanedInput)) {
            return false;
        }
    } else if (cleanedInput.length === 14) {
        if (!validateCnpj(cleanedInput)) {
            return false;
        }
    } else {
        return false;
    }

    return true;
}