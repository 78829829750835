import { Address } from "./Address";
import { BillsToPay } from "./BillsToPay";
import { BillsToReceive } from "./BillsToReceive";
import { Nfce } from "./Nfce";
import { Nfe } from "./Nfe";
import { Nfse } from "./Nfse";

export enum limitCreditPeriodicity {
    MONTHLY = 'monthly',
    BIMONTHLY = 'bimonthly',
    TRIMONTHLY = 'trimonthly',
    QUARTERLY = 'quarterly',
    SEMIANNUAL = 'semiannual',
    ANNUAL = 'annual',
    OTHER = 'other', // Não está sendo usado
}

export type Customer = {
    id: number,
    name: string,
    birthDate: string,
    gender: string,
    cpf: string,
    rg: string,
    issuingAgency: string,
    issueDate: string,
    passport: string,
    isForeign: string,
    ruralProducerRegistration: string,
    isRuralProducer: string,
    profession: string,
    employer: string,
    isRetiree: string,
    maritalStatus: string,
    startOfActivity: string,
    finalOfActivity: string,
    activityClosed: string,
    cnpj: string,
    exempt: string,
    inscriptionInTheSuframa: string,
    corporateName: string,
    fantasyName: string,
    municipalRegistration: string,
    stateRegistration: string,
    cell: string,
    phone: string,
    extension: string,
    fax: string,
    email: string,
    website: string,
    note: string,
    registrationStatus: string,
    typePeople: string,
    typeRegister: string,
    finalCostumer: string,
    icmsTaxpayer: string,
    noteRemarks: string,
    taxRegime: string,
    priceList: string,
    paymentTerm: string,
    seller: string,
    deliveryMode: string,
    carrying: string,
    limitCredit: number,
    limitCreditPeriodicity: limitCreditPeriodicity,
    allowToExceedLimit: string,

    contacts: any[] | undefined,
    mainAddress: Address,
    addresses: Address[],
    toReceive: BillsToReceive[],
    toPay: BillsToPay[],
    nfe: Nfe[],
    nfce: Nfce[],
    nfse: Nfse[]
}