import api from "./Api";

type NfEmailData = {
    nf: any;
    type: NfType;
    customerId: string;
    customerName: string;
    contactName: string;
    subject: string;
    refName: string;
    message: string;
}

type NfType = 'nfe' | 'nfce' | 'nfse';

class EmailNfService {
    async getAllEmailsByNfId(nfId: number | string, type: NfType) {
        try {
            const { data } = await api.get<any[]>(`email-nf/${type}/${nfId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendEmail(emails: string[], {
        nf,
        type,
        customerId,
        customerName,
        contactName,
        subject,
        refName,
        message,
    }: NfEmailData) {
        let danfeUrl = '';
        let xmlUrl = '';

        const apiResponseObj = nf.api_last_response ? JSON.parse(nf.api_last_response) : null;
        if (apiResponseObj.body && (apiResponseObj.body.url || apiResponseObj.body.caminho_danfe) && apiResponseObj.body.caminho_xml_nota_fiscal) {
            danfeUrl = apiResponseObj.body.url || apiResponseObj.body.caminho_danfe;

            const apiUrl = apiResponseObj.url;

            if(apiResponseObj.body.caminho_danfe) {
                danfeUrl = `${apiUrl}${apiResponseObj.body.caminho_danfe}`
            }

            xmlUrl = `${apiUrl}${apiResponseObj.body.caminho_xml_nota_fiscal}`;
        }

        const raw = {
            to: emails,
            subject: subject,
            name: refName,
            type,
            id: nf.id,
            nameContact: contactName,
            nameCustomer: customerName,
            message: message,
            danfeUrl,
            xmlUrl,
            customerId,
        };

        await api.post('email-nf/send', raw);
    };
}

export default new EmailNfService();