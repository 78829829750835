import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditProfile } from '../pages/Profiles/Edit-Profile';
import { ListProfiles } from '../pages/Profiles/List-Profiles';
import { NewProfile } from '../pages/Profiles/New-Profile';

export function Profile() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Perfis de Acesso");
    
    return (
        <Switch>
            <Route path="/perfis-acesso/adicionar" component={NewProfile}/>
            <Route path="/perfis-acesso/:id" component={EditProfile}/>
            <Route path="/perfis-acesso" component={ListProfiles}/>
        </Switch>
    );
}