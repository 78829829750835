import { api as apiUrl } from "../services/ApiURL";

export async function createSameOriginUrl(url: string) {
    if(!url) return '';

    try {
        const resourceResponse = await fetch(url);

        const blob = await resourceResponse.blob();

        const sameOriginUrl = window.URL.createObjectURL(blob);

        return sameOriginUrl;
    } catch (error) {
        const serverUrl = `${apiUrl}/download/url?fileUrl=${JSON.stringify(url)}`;

        const resourceResponse = await fetch(serverUrl);

        const blob = await resourceResponse.blob();

        const sameOriginUrl = window.URL.createObjectURL(blob);

        return sameOriginUrl;
    }
}