import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, resetPassword } from "../_redux/authCrud";

const initialValues = {
  password: "",
  changepassword: "",
};

function ResetPassword(props) {
  const { token } = useParams();

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Digite mais de 3 caracteres")
      .max(50, "O máximo de caracteres é 50")
      .required(
        'Preencha a senha'
      ),
    changepassword: Yup.string()
      .required(
        'Confirme a senha'
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Senhas não coincidem"
        ),
      }),
  });

  useEffect(() => {
    //
  }, [token]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      resetPassword(token, values.password)
        .then((data) => {
          disableLoading();
          setSuccessMessage('Senha redefinida com sucesso!');
          setStatus('');
          setSubmitting(false);

          window.location.href = '/auth/login';
        })
        .catch(() => {
          setSubmitting(false);
          setSuccessMessage('');
          setStatus(
            'Erro ao processar requisição'
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      {!tokenIsValid && <Redirect to="/auth" />}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Redefinir senha
        </h3>
        <p className="text-muted font-weight-bold">
          Digite a sua nova senha
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {/* begin: Success */}
        {successMessage && (
          <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
            <div className="alert-text font-weight-bold">{successMessage}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Senha"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirmar Senha"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Enviar</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Voltar
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
