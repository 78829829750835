import { useCallback, useState } from "react";
import { PermissionMenu } from "../types/PermissionMenu";

export type submenu = {
    id: number,
    name: string,
    menuId: number,
    checked: boolean,
    subMenu: submenu[]
}

export type menuSystem = {
    id: number,
    menuId: number,
    name: string,
    checked: boolean,
    subMenu: submenu[]
}

export function useCustomerPermissionHandler() {
    const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>([]);
    const [menuSystem, setMenuSystem] = useState<menuSystem[]>([]);

    const handleChangePermission = useCallback((checked: boolean, indexes: Array<number>) => {
        const aux = permissionMenus;
        if(indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].checked = checked;

            aux[i1].submenus = modifyPermissionSubmenus(aux[i1]);

            if (aux[i1].extraPermissions) {
                aux[i1].extraPermissions = aux[i1].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        } else if(indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].submenus as PermissionMenu[];

            aux[i2].checked = checked || aux[i2].checked;
            submenu1[j2].checked = checked;

            submenu1[j2].submenus = modifyPermissionSubmenus(submenu1[j2]);
            aux[i2].submenus = submenu1;

            if (submenu1[j2].extraPermissions) {
                submenu1[j2].extraPermissions = submenu1[j2].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        } else if(indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].submenus as PermissionMenu[];
            const submenu2 = submenu1[j3].submenus as PermissionMenu[];

            aux[i3].checked = checked || aux[i3].checked;
            submenu1[j3].checked = checked || submenu1[j3].checked;
            submenu2[k3].checked = checked;

            submenu2[k3].submenus = modifyPermissionSubmenus(submenu2[k3]);
            (aux[i3].submenus || [])[j3].submenus = submenu2;

            if (submenu2[k3].extraPermissions) {
                submenu2[k3].extraPermissions = submenu2[k3].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        }

        if(checked === false) {
            handleChangePermissionFastAccess(checked, indexes);
        }

        setPermissionMenus([...aux]);
    }, [permissionMenus]);

    function modifyPermissionSubmenus(menu: PermissionMenu): PermissionMenu[] {
        const checked = menu.checked ?? false;
        const submenus = menu.submenus?.filter(() => true) as PermissionMenu[];
        if(!submenus || submenus.length === 0) {
            return [];
        }
        for (let i = 0; i < submenus.length; i++) {
            submenus[i].checked = checked;
            submenus[i].extraPermissions = submenus[i].extraPermissions?.map((extraPermission) => ({ ...extraPermission, checked }));
            submenus[i].submenus = modifyPermissionSubmenus(submenus[i]);
        }
        return submenus;
    }

    const handleChangePermissionFastAccess = useCallback((checked: boolean, indexes: Array<number>) => {
        const aux = menuSystem;
        if(indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].checked = checked;

            aux[i1].subMenu = modifyPermissionSubmenusFastAccess(aux[i1]);
        } else if(indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].subMenu as submenu[];

            aux[i2].checked = checked || aux[i2].checked;
            submenu1[j2].checked = checked;

            submenu1[j2].subMenu = modifyPermissionSubmenusFastAccess(submenu1[j2]);
            aux[i2].subMenu = submenu1;
        } else if(indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].subMenu as submenu[];
            const submenu2 = submenu1[j3].subMenu as submenu[];

            aux[i3].checked = checked || aux[i3].checked;
            submenu1[j3].checked = checked || submenu1[j3].checked;
            submenu2[k3].checked = checked;

            submenu2[k3].subMenu = modifyPermissionSubmenusFastAccess(submenu2[k3]);
            (aux[i3].subMenu || [])[j3].subMenu = submenu2;
        }

        // Atualizar acesso
        if(checked) {
            handleChangePermission(checked, indexes);
        }

        setMenuSystem([...aux]);
    }, [menuSystem, handleChangePermission]);

    function modifyPermissionSubmenusFastAccess(menu: submenu): submenu[] {
        const checked = menu.checked ?? false;
        const submenus = menu.subMenu?.filter(() => true) as submenu[];
        if(!submenus || submenus.length === 0) {
            return [];
        }
        for (let i = 0; i < submenus.length; i++) {
            submenus[i].checked = checked;
            submenus[i].subMenu = modifyPermissionSubmenusFastAccess(submenus[i]);
        }
        return submenus;
    }

    return {
        permissionMenus,
        setPermissionMenus,
        handleChangePermission,
        menuSystem,
        setMenuSystem,
        handleChangePermissionFastAccess,
    };
}