import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditUser } from '../pages/User/Edit-User';
import { ListUser } from '../pages/User/List-User';
import { NewUser } from '../pages/User/New-User';

export function User() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Usuários");
    
    return (
        <Switch>
            <Route path="/usuarios/adicionar" component={NewUser}/>
            <Route path="/usuarios/:id" component={EditUser}/>
            <Route path="/usuarios" component={ListUser}/>
        </Switch>
    );
}