import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
} from "@react-pdf/renderer";
import { HeadDataBaseProps } from "../../ListWithModalChangeSituation";
import { PdfReportHeader } from "../../Pdf/Report/PdfReportHeader";
import { PdfReportBody } from "../../Pdf/Report/PdfReportBody";
import { PdfReportTotals } from "../../Pdf/Report/PdfReportTotals";
import { ReportDataRow, ReportTotals } from "../../../types/ReportData";
import api from "../../../services/Api";

const axiosDefaults = {
  headers: {
    'x-bypass-auth': '0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5'
  },
};

type PdfReportDocumentParams = {
  title: string;
  companyId: number;
  bodyData: ReportDataRow[];
  headData: HeadDataBaseProps[];
  totals: ReportTotals[];
}

// Create Document Component
export default function PdfReportDocument({
  title,
  companyId,
  bodyData,
  headData,
  totals,
}: PdfReportDocumentParams) {
  // COMPANY DATA
  const [company, setCompany] = useState<any>({});
  // =========== COMPANY =====

  useEffect(() => {
    async function loadData() {
      getCompany(companyId);
    }

    loadData();
  }, [companyId]);

  async function getCompany(companyId: number) {
    const company = await api.get(`/companies/${companyId}`, axiosDefaults);

    if (company) {
      setCompany(company.data);
    }
  }

  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4">
        <PdfReportHeader company={company}>
          {title}
        </PdfReportHeader>

        <PdfReportBody
          headData={headData}
          bodyData={bodyData}
        />

        <PdfReportTotals
          totals={totals}
        />
      </Page>
    </Document>
  );
}

