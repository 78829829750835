import React from 'react'
import { Switch, Route } from 'react-router-dom';

import { ServiceOrderProvider } from '../pages/ServiceOrder/context';
import { NewServiceOrder } from '../pages/ServiceOrder/New-ServiceOrder';
import { ListServiceOrder } from '../pages/ServiceOrder/List-ServiceOrder';
import { EditServiceOrder } from '../pages/ServiceOrder/Edit-ServiceOrder';
import { ListProducts } from '../pages/List-Products';

export function ServiceOrder() {
  return (
    <ServiceOrderProvider>
      <Switch>
        <Route path='/ordem-de-servico/adicionar' component={NewServiceOrder} />
        <Route path='/ordem-de-servico/duplicar/:idToClone' component={NewServiceOrder} />
        <Route path='/ordem-de-servico/:id' component={EditServiceOrder} />
        <Route path='/ordem-de-servico' component={ListServiceOrder} />
        <Route path='/notas-fiscais' component={ListProducts} />
      </Switch>
    </ServiceOrderProvider>
  )
}
