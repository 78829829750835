import { Checkbox, FormControlLabel, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BankAccountService from "../services/BankAccountService";
import { BankAccount } from "../types/BankAccount";
import { CenterCost } from "../types/CenterCost";
import { SubCategory } from "../types/Dre";
import { ThrowBillInstallment } from "../types/ThrowBillInstallment";
import { formatCurrency } from "../utils/formatCurrency";
import { paymentOptions } from "../utils/paymentOptions";
import ApiResourceSelect from "./ApiResourceSelect";
import { CompanySubscriptionPlanPayment } from "../types/CompanySubscriptionPlanPayment";
import { SubscriptionPlan } from "../types/SubscriptionPlan";
import SubscriptionPlanService from "../services/SubscriptionPlanService";
import { getDate } from "../utils/dateTimeHelper";
import { getSituationText } from "../utils/getSituationText";
import CompanySubscriptionPlanPaymentService from "../services/CompanySubscriptionPlanPaymentService";
import api from "../services/Api";
import { initializeDate } from "../utils/dateFormat";

type ModalChangePlanProps = {
    isChangePlanModalOpen: boolean;
    setIsChangePlanModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    invoiceSelected: CompanySubscriptionPlanPayment | null,
    subscriptionPlans: SubscriptionPlan[],
    expirationDate: string,
    setExpirationDate: React.Dispatch<React.SetStateAction<string>>,
    subscriptionPlan: SubscriptionPlan | null,
    setSubscriptionPlan: React.Dispatch<React.SetStateAction<SubscriptionPlan | null>>,
    paymentMethodInvoice: string,
    setPaymentMethodInvoice: React.Dispatch<React.SetStateAction<string>>,
    paymentDateInvoice: string,
    setPaymentDateInvoice: React.Dispatch<React.SetStateAction<string>>,
    dueDateInvoice: string,
    setDueDateInvoice: React.Dispatch<React.SetStateAction<string>>,
    statusInvoice: string,
    setStatusInvoice: React.Dispatch<React.SetStateAction<string>>,
    reloadData: () => void
}


export function ModalChangePlan({
    isChangePlanModalOpen,
    setIsChangePlanModalOpen,
    invoiceSelected,
    subscriptionPlans,
    expirationDate,
    setExpirationDate,
    subscriptionPlan,
    setSubscriptionPlan,
    paymentMethodInvoice,
    setPaymentMethodInvoice,
    paymentDateInvoice,
    setPaymentDateInvoice,
    dueDateInvoice,
    setDueDateInvoice,
    statusInvoice,
    setStatusInvoice,
    reloadData
}: ModalChangePlanProps) {
    console.log('-------');
    console.log( invoiceSelected);
    console.log( expirationDate);
    console.log( subscriptionPlan);
    console.log( paymentMethodInvoice);
    console.log('-------');
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChangeSubscriptionPlan = useCallback((event: React.ChangeEvent<{}>, subscriptionPlanSelected: SubscriptionPlan | null) => {
        if(subscriptionPlanSelected) {
            const planExpirationDateObj = SubscriptionPlanService.addPlanDurationToTodayDate(subscriptionPlanSelected);
            const planExpirationDateString = planExpirationDateObj
            ? getDate({ initialDate: planExpirationDateObj, dateFormat: 'y-m-d' }).dateStr
            : '';

            setDueDateInvoice(planExpirationDateString);
        }

        setSubscriptionPlan(subscriptionPlanSelected);
    }, []);

    const onSubmit = useCallback(async () => {
        setIsSubmit(true);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        let currentDate = `${year}-${month}-${day}`;

        if (dueDateInvoice < currentDate) {
            alert("Data selecionada menor que data atual");
            setIsSubmit(false);
            return false;
        }

        try {
            const response = await api.put(`/companySubscriptionPlanPayment/${invoiceSelected?.id}`, {
                dueDate: `${dueDateInvoice} 23:59:59`,
            });

            reloadData();
            setIsChangePlanModalOpen(false);
        } catch(error) {
            console.log(error);
        }
        /*
        console.log("salvou!");
        if (!subscriptionPlan) {
            alert("Selecione um plano!");
            setIsSubmit(false);
            return false;
        }

        if (!statusInvoice) {
            alert("Selecione um status!");
            setIsSubmit(false);
            return false;
        }

        try {
            const response = await api.put(`/companySubscriptionPlanPayment/${invoiceSelected?.id}`, {
                paymentMethod: paymentMethodInvoice,
                paymentDate: initializeDate(paymentDateInvoice),
                dueDate: initializeDate(dueDateInvoice),
                status: statusInvoice
            });

            reloadData();
            setIsChangePlanModalOpen(false);
        } catch(error) {
            console.log(error);
        }

        */
        setIsSubmit(false);
    }, [subscriptionPlan, statusInvoice, invoiceSelected, paymentMethodInvoice, paymentDateInvoice, dueDateInvoice]);

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"

            size="xl"
            show={isChangePlanModalOpen}
            onHide={() => {
                setExpirationDate("");
                setSubscriptionPlan(null);
                setIsChangePlanModalOpen(false);
                setPaymentMethodInvoice("");
                setPaymentDateInvoice("");
                setDueDateInvoice("");
                setStatusInvoice("");
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Editar fatura
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="newProductWrapper pt-1">
                <div className="row">
                    <div className="col-lg mt-1 d-flex align-items-center justify-content-end">
                        <Button variant="primary" className="mr-2" onClick={() => onSubmit()} disabled={isSubmit}>Salvar</Button>
                        <Button variant="secondary" onClick={() => setIsChangePlanModalOpen(false)}>Cancelar</Button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h3>Registro da fatura</h3>
                    </div>
                </div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    /*
                                    <TableCell>Plano</TableCell>
                                    <TableCell>Forma de Pagamento</TableCell>
                                    <TableCell>Competência</TableCell>
                                    */
                                }
                                <TableCell>Data de Vencimento</TableCell>
                                { /*<TableCell>Status</TableCell>*/ }
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                {
                                /*
                                <>
                                <TableCell>
                                    <Autocomplete
                                        size="small"
                                        noOptionsText="Sem opções"
                                        options={subscriptionPlans}
                                        getOptionLabel={({name, value}) => `${name} - ${value ? formatCurrency(value) : 'Gratuito'}`}
                                        onChange={(event, newInputValue) => handleChangeSubscriptionPlan(event, newInputValue)}
                                        value={subscriptionPlan}
                                        renderInput={(params) =>
                                            <TextField
                                                size="small"
                                                { ...params }
                                                label="Plano"
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        }
                                    />
                                </TableCell>

                                <TableCell>
                                    <TextField
                                            select
                                            label="Forma de Pagamento"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={paymentMethodInvoice}
                                            onChange={e => setPaymentMethodInvoice(e.target.value)}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            <MenuItem key="1" value="boleto">
                                                Boleto
                                            </MenuItem>

                                            <MenuItem key="2" value="credit_card">
                                                Cartão de Crédito
                                            </MenuItem>

                                    </TextField>
                                </TableCell>

                                <TableCell>
                                    <TextField
                                        type="date"
                                        label="Competência"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={paymentDateInvoice}
                                        onChange={e => setPaymentDateInvoice(e.target.value)}
                                    />
                                </TableCell>
                                </>
                                */
                                }
                                <TableCell>
                                    <TextField
                                        type="date"
                                        label="Data de Vencimento"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={dueDateInvoice}
                                        onChange={e => setDueDateInvoice(e.target.value)}
                                    />
                                </TableCell>

                                {
                                /*
                                <TableCell>
                                    <TextField
                                            select
                                            label="Status"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={statusInvoice}
                                            onChange={e => setStatusInvoice(e.target.value)}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            <MenuItem key="1" value="pending">
                                                Pendente
                                            </MenuItem>

                                            <MenuItem key="2" value="failed">
                                                Falha
                                            </MenuItem>

                                            <MenuItem key="3" value="paid">
                                                Pago
                                            </MenuItem>
                                            
                                            <MenuItem key="4" value="canceled">
                                                Cancelado
                                            </MenuItem>
                                    </TextField>
                                </TableCell>
                                */
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Modal.Body>
        </Modal>
    );
}