import { Quotation, QuotationStatus, QuotationSupplier, QuotationSupplierStatus } from "../types/Quotation";
import api from "./Api";

type QuotationSupplierEmailData = {
    quotationSupplier: QuotationSupplier;
    customerId: string;
    customerName: string;
    contactName: string;
    subject: string;
    refName: string;
    message: string;
    link?: string;
    companyId: number;
}

class EmailQuotationSupplierService {
    async getAllEmailsByQuotationSupplierId(quotationSupplierId: number | string) {
        try {
            const { data } = await api.get<any[]>(`email-quotation-supplier/quotationSupplier/${quotationSupplierId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendEmail(emails: string[], {
        quotationSupplier,
        customerId,
        customerName,
        contactName,
        subject,
        refName,
        message,
        link,
        companyId,
    }: QuotationSupplierEmailData) {
        const raw = {
            to: emails,
            subject: subject,
            name: refName,
            // type: typeOrder,
            id: quotationSupplier.id,
            supplierId: quotationSupplier.supplier,
            nameContact: contactName,
            nameCustomer: customerName,
            message: message,
            link: link,
            companyId: companyId,
        };
    
        await api.post('email-quotation-supplier/send', raw);
    };
}

export default new EmailQuotationSupplierService();