import React, { useState, useEffect } from 'react';
import api from '../services/Api';

type changeValueSwitch = {
    value: boolean,
    url?: string,
    disabled?: boolean,
}

const ChangeValueSwitch: React.FC<changeValueSwitch> = ({
    value,
    url = null,
    disabled = false,
}) => {
    const [switchValue, setSwitchValue] = useState(value);
    const [containerStyle, setContainerStyle] = useState<any>({});
    const [switchStyle, setSwitchStyle] = useState<any>({});

    useEffect(() => {
        const fetch = async() => {
            let style = 
                disabled ?
                    switchValue ? {...styles.container, ...styles.disabledActive} : {...styles.container, ...styles.disabledNotActive}
                    :
                    switchValue ? {...styles.container, ...styles.active} : {...styles.container, ...styles.notActive};
            setContainerStyle(style);
            let sStyle = 
                disabled ?
                    {...styles.switch, ...styles.switchDisabled}
                    :
                    switchValue ? {...styles.switch, ...styles.switchActive} : {...styles.switch, ...styles.switchNotActive};
            setSwitchStyle(sStyle);
        }
        fetch().then();
    }, [switchValue]);

    const changeValue = async(url: any = null) => {
        try {
            if (url) {
                let resp = await api.post(url, {active: !switchValue});
            }
            setSwitchValue(!switchValue);
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <div
            style={containerStyle}
            onClick={
                disabled ? 
                    () => {}
                    :
                    () => changeValue(url)
            }
        >
            <div style={switchStyle}></div>
        </div>
    );
}

const styles = {
    container: {
        width: 37,
        height: 22,
        borderColor: '#000',
        borderRadius: 13,
        paddingTop: 1,
    },
    switch: {
        height: 20,
        width: 20,
        borderRadius: 50,
        vorderColor: '#777',
    },
    active: {
        paddingLeft: 16,
        backgroundColor: 'green',
    },
    notActive: {
        paddingLeft: 1,
        backgroundColor: 'red',
    },
    disabledActive: {
        paddingLeft: 15,
        backgroundColor: 'gray',
    },
    disabledNotActive: {
        paddingLeft: 1,
        backgroundColor: 'gray',
    },
    switchActive: { backgroundColor: '#E0F2F1', },
    switchNotActive: { backgroundColor: '#FFEBEE', },
    switchDisabled: { backgroundColor: '#DCDCDC', },
};

export default ChangeValueSwitch;
