import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditSubscriptionPlan } from '../pages/SubscriptionPlan/Edit-SubscriptionPlan';
import { ListSubscriptionPlans } from '../pages/SubscriptionPlan/List-SubscriptionPlans';
import { NewSubscriptionPlan } from '../pages/SubscriptionPlan/New-SubscriptionPlan';

export function SubscriptionPlan() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Planos");
    
    return (
        <Switch>
            <Route path="/planos/adicionar" component={NewSubscriptionPlan}/>
            <Route path="/planos/:id" component={EditSubscriptionPlan}/>
            <Route path="/planos" component={ListSubscriptionPlans}/>
        </Switch>
    );
}