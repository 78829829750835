import { TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import { Search } from '../../../components/Search';
import useBackendLoad from '../../../hooks/backendReload';
import api from '../../../services/Api';
import { Indications } from '../../../types/Indications';
import { ReportDataCell, ReportDataRow } from '../../../types/ReportData';
import { IndicationsAccept } from './IndicationsAccept';
import { IndicationsCanceled } from './IndicationsCanceled';
import { IndicationsPending } from './IndicationsPending';

type Filters = {
    searchQuery: string;
}

export function ListIndications () {
    const [showModalIndicationLink, setShowModalIndicationLink] = useState(false);
    const [indicationLink, setIndicationLink] = useState('');
    const [indicationLinkWasCopied, setIndicationLinkWasCopied] = useState(false);

    const [foundIndicationsPending, setFoundIndicationsPending] = useState<Indications[]>([]);
    const [countTotalIndicationsPending, setCountTotalIndicationsPending] = useState(0);
    
    const [foundIndicationsAccept, setFoundIndicationsAccept] = useState<Indications[]>([]);
    const [countTotalIndicationsAccept, setCountTotalIndicationsAccept] = useState(0);

    const [foundIndicationsCanceled, setFoundIndicationsCanceled] = useState<Indications[]>([]);
    const [countTotalIndicationsCanceled, setCountTotalIndicationsCanceled] = useState(0);


    const [searchQuery, setSearchQuery] = useState("");

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
    const { location: { pathname }, push: pushHistory } = useHistory();
    const { user } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if (!user) {
            return;
        }

        setIndicationLink(`${process.env.REACT_APP_PUBLIC_URL}/indicacao/link?code=${Buffer.from(String(user.id)).toString('base64')}`);
    }, [user]);

    const loadPending = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Indications[], count:number}>("/indications/filter/pending", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundIndicationsPending(data.rows ? rows : []);
        setCountTotalIndicationsPending(count);
    }, []);

    const loadAccept = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Indications[], count:number}>("/indications/filter/accept", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundIndicationsAccept(data.rows ? rows : []);
        setCountTotalIndicationsAccept(count);
    }, []);

    const loadCanceled = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Indications[], count:number}>("/indications/filter/canceled", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setFoundIndicationsCanceled(data.rows ? rows : []);
        setCountTotalIndicationsCanceled(count);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickCopyIndicationLink = useCallback(() => {
        navigator.clipboard.writeText(indicationLink);

        setIndicationLinkWasCopied(true);

        const timeout = setTimeout(() => {
            setIndicationLinkWasCopied(false);
        }, 1500);

        return () => clearTimeout(timeout);
    }, [indicationLink]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery
        };

        reloadData();
    }, [
        searchQuery
    ]);

    const handleClickIndication = useCallback((indicationId: number) => {
        pushHistory(`${pathname}/${indicationId}`);
    }, [pathname]);

    return (
        <div className="card card-body pt-4">
            <Modal
                show={showModalIndicationLink}
                onHide={() => setShowModalIndicationLink(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-copy icon-xl text-success mr-3"></i>
                        Indicação via Link
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>Compartilhe este link com a empresa que deseja indicar, a partir do link a empresa irá criar o seu cadastro e concederá a você acesso ao painel de contabilidade.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-start">
                            <TextField
                                style={{ width: '70%' }}
                                label="Link"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                value={indicationLink}
                                disabled
                            />
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip-top'>
                                        Copiar Link
                                    </Tooltip>
                                }
                            >
                                <Button className="ml-3 mt-2" variant="secondary" onClick={handleClickCopyIndicationLink}>
                                    <i className='p-0 flaticon2-copy'></i>
                                    {indicationLinkWasCopied && <span className="ml-2">Copiado!</span>}
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalIndicationLink(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    <Button
                        type="button"
                        variant="success"
                        className="mr-2"
                        onClick={() => handleClickAdd()}
                    >
                        Indicar Empresa
                    </Button>
                    <Button
                        type="button"
                        className="mr-2 btn-light-primary"
                        onClick={() => setShowModalIndicationLink(true)}
                    >
                        Indicar via Link
                    </Button>
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            
            <hr />
            <div className="row">
                <div className="col-lg-4">
                    <IndicationsPending 
                        data={foundIndicationsPending}
                        totalCount={countTotalIndicationsPending}
                        loadData={loadPending}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        onClickIndication={handleClickIndication}
                    />
                </div>

                <div className="col-lg-4">
                    <IndicationsAccept 
                        data={foundIndicationsAccept}
                        totalCount={countTotalIndicationsAccept}
                        loadData={loadAccept}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        onClickIndication={handleClickIndication}
                    />
                </div>

                <div className="col-lg-4">
                    <IndicationsCanceled 
                        data={foundIndicationsCanceled}
                        totalCount={countTotalIndicationsCanceled}
                        loadData={loadCanceled}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        onClickIndication={handleClickIndication}
                    />
                </div>
            </div>
        </div>
    )
}