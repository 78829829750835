import { isPast } from "date-fns";
import { BillsToReceive, billetType } from "../types/BillsToReceive";
import { Company } from "../types/Company";
import { bankNumberIsInter } from "../utils/bankNumberIsInter";
import { getLastDayOfMonth } from "../utils/dateTimeHelper";
import api from "./Api";
import { extraPermissionEnum, menuIdEnum } from "./PermissionMenuService";
import UserService from "./UserService";
import { formatToFloat } from "../utils/formatCurrency";
import { Customer } from "../types/Customer";
import CustomerService from "./CustomerService";
import { validateCpfCnpj } from "../utils/validateCpfCnpj";

type GenerateBilletOptions = {
    updateBilletSequence?: boolean;
}

type ValidateGenerateInterBilletFieldsParams = {
    dueDate?: string;
    amount: string | number;
    customerId: string | number;
};

class BillToReceiveService {
    async generateBillet(bill: BillsToReceive, options: GenerateBilletOptions = {}) {
        if (bill.recordType === 'billet' && bill.status !== 'paid') {
            const isInterBillet = bankNumberIsInter(bill.bankAccountEntity.numberBank);

            if (isInterBillet) {
                return this.generateInterBillet(bill, options);
            } else {
                return this.generateRegularBillet(bill, options);
            }
        }
    }

    async getBilletDocumentFilename(bill: BillsToReceive) {
        let billetDocumentName = bill.billetDocument;

        if (!billetDocumentName) {
            billetDocumentName = await this.generateBillet(bill) ?? '';
        }

        return billetDocumentName;
    }

    async getBilletFileUrl(bill: BillsToReceive) {
        const billetDocumentName = await this.getBilletDocumentFilename(bill);

        if (bill.billetType === billetType.INTER) {
            return `${process.env.REACT_APP_API_URL}/${billetDocumentName}`;
        } else {
            return `${process.env.REACT_APP_BILLET_API_URL}/${billetDocumentName}`;
        }
    }

    getBillPaymentDate(bill: BillsToReceive): string | null {
        if (!bill || bill.status !== 'paid') {
            return null;
        }

        if (!bill.payedDate && !bill.partialPayments) {
            return bill.dueDate || null;
        }

        let payedDate = bill.payedDate;
        if (!payedDate && bill.partialPayments) {
            const partials: any[] = JSON.parse(bill.partialPayments);
            payedDate = partials[partials.length - 1].payedDate;
        }

        return payedDate;
    }

    /** Verifica e atualiza o status de atrasado das contas */
    async resolveBillsDueDates(bills?: any[]) {
        let billsArray: any[] = bills ?? [];
        if (!bills) {
            const { data } = await api.get<BillsToReceive[]>("billsToReceive");
            billsArray = data;
        }

        billsArray.forEach((bill, index) => {
            const dueDateObj = new Date(`${bill.dueDate} 23:59:59`);
            const today = new Date();
            if (bill.status === 'pending') {
                const dueDateObj = new Date(`${bill.dueDate} 23:59:59`);
                if (dueDateObj.getTime() <= today.getTime()) {
                    billsArray[index].status = 'late';
                    // Marcar conta a receber como 'late' (Vencido)
                    api.put(`billsToReceive/${bill.id}`, {
                        status: 'late',
                    });
                }
            } else if (bill.status === 'late') {
                if (dueDateObj.getTime() > today.getTime()) {
                    billsArray[index].status = 'pending';
                    api.put(`billsToReceive/${bill.id}`, {
                        status: 'pending',
                    });
                }
            }
        });

        return billsArray;
    }

    async checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate: string, company: Company) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const year = today.getFullYear();
        const monthIndex = today.getMonth();
        const blockDayForChangeOfBillsSituationOfPastMonth = company.blockDayForChangeOfBillsSituationOfPastMonth || 1;
        const lastDayOfMonth = getLastDayOfMonth(year, monthIndex);
        const monthCloseDay = blockDayForChangeOfBillsSituationOfPastMonth > lastDayOfMonth ? lastDayOfMonth : blockDayForChangeOfBillsSituationOfPastMonth;

        const monthCloseDate = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/${monthCloseDay} 00:00:00`);

        // Realizar validação apenas quando tiver passado da data de fechamento do mês fiscal
        if (today.getTime() < monthCloseDate.getTime()) {
            return true;
        }

        const startOfMonth = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/01 00:00:00`);
        const paymentDateObject = new Date(`${paymentDate} 00:00:00`);

        // Validando se o usuário tem permissão para administrar fechamento de mês 
        if (paymentDateObject.getTime() < startOfMonth.getTime()) {
            const hasPermission = await UserService.checkIfAuthUserHasPermissionToExecuteAction(
                menuIdEnum.BILLS_TO_RECEIVE,
                extraPermissionEnum.CAN_CHANGE_BILL_SITUATION_OF_PAST_MONTH
            );

            return hasPermission;
        }

        return true;
    }

    async validateGenerateInterBilletFields({
        dueDate,
        amount,
        customerId,
    }: ValidateGenerateInterBilletFieldsParams) {
        if (dueDate && isPast(new Date(dueDate + ' 23:59'))) {
            return {
                error: true,
                field: 'dueDate',
                message: 'Para emitir boleto pelo Inter a data de vencimento não pode estar no passado!',
            };
        }

        if (formatToFloat(amount) < 2.5) {
            return {
                error: true,
                field: 'amount',
                message: 'Para emitir boleto pelo Inter o valor da receita deve ser igual ou superior a R$ 2,50',
            };
        }

        const customerEntity: Customer = await CustomerService.getCustomerById(customerId, { withMainAddress: true });

        const customerHasAddress = (
            customerEntity.mainAddress
            && customerEntity.mainAddress.publicPlace
            && customerEntity.mainAddress.zipcode
            && customerEntity.mainAddress.city
            && customerEntity.mainAddress.state
        );

        if (!customerHasAddress) {
            return {
                error: true,
                field: 'customerId',
                message: 'Para emitir boleto pelo Inter é necessário cadastrar o endereço do cliente!',
            };
        }

        if (!validateCpfCnpj(customerEntity.typePeople === 'legal' ? customerEntity.cnpj : customerEntity.cpf)) {
            return {
                error: true,
                field: 'customerId',
                message: 'Para emitir boleto pelo Inter o CPF / CNPJ do cliente deve ser válido!',
            };
        }

        return {
            error: false,
            field: '',
            message: '',
        };
    }

    private async generateRegularBillet(bill: BillsToReceive, options: GenerateBilletOptions = {}) {
        const willUpdateBilletSequence = options.updateBilletSequence !== false;

        const billetData = {
            account: bill.bankAccount,
            dueDate: bill.dueDate,
            value: bill.amount,
            customer: bill.customer,
            idBill: bill.id,
        }

        const response = await api.post("/billet/generate-billet", billetData);
        const billetFilename = String(response.data.data ?? '');

        // Salvando nome do arquivo do boleto
        await api.put(`/billsToReceive/${bill.id}`, { billetDocument: billetFilename });

        if (willUpdateBilletSequence) {
            // Atualizar sequencia do numero do boleto
            await api.patch(`/accountBank/billet/sequence/${Number(bill.bankAccount)}`);
        }

        return billetFilename;
    }

    private async generateInterBillet(bill: BillsToReceive, options: GenerateBilletOptions = {}) {

        const billetResponse = await api.post('/inter-billet/generate-billet', {
            billId: bill.id,
        });
        const billetFilename = billetResponse.data;
        return billetFilename;
    }
}

export default new BillToReceiveService();