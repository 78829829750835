import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import {
    makeStyles,
    MenuItem,
    TextField,
    Link
} from '@material-ui/core';
import { Button } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";
import '../../style.css';
import { getEnabledStatusText } from '../../utils/getEnabledStatusText';
import LogService from '../../services/LogService';
import { useSelector } from 'react-redux';


type CenterCost = {
    id: number;
    name: string;
    status: "active" | "inactive";
};


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

export function EditCenterCost() {
    const { user } = useSelector((state: any) => state.auth);

    
    const [name, setName] = useState('');
    const [status, setStatus] = useState<"active" | "inactive">("active");

    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{id: string}>();


    subHeader.setTitle("Adicionar um Centro de Custos");


    useLayoutEffect(() => {
        async function loadData() {
            const response = await api.get<CenterCost>(`centerCost/${id}`);

            setName(response.data.name);
            setStatus(response.data.status);
        }

        loadData();
    }, []);

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        try {

            const lastInputs = await api.get<CenterCost>(`centerCost/${id}`);

            const data = {
                name,
                status
            };

            const result = await api.put(`centerCost/${id}`, data);

            LogService.logEdit({
                itemId: result.data.id,
                itemName: result.data.name,
                module: 'Centro de Custos',
                oldData: lastInputs.data,
                newData: result.data,
                formattedFields: {
                  status: (value) => getEnabledStatusText(value),
                },
                fieldsMap: {
                  name: 'Nome',
                  status: 'Status',
                },
            });          

            history.push("/parametros/centro-de-custos");

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <form className={classes.container} onSubmit={(evt) => onSubmit(evt)}>
                <div className="row col-lg-12">
                    <div className="col-lg-9"> 
                        <TextField
                            size="small"
                            label="Descrição"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={name}
                            onChange={(evt) => setName(evt.target.value)}
                            disabled={user.isAccountant == "y" ? true : false}
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            select
                            label="Status"
                            size="small"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            value={status}
                            onChange={
                                ({ target: { value } }) => setStatus(value === "active" ? "active" : "inactive")
                            }
                            disabled={user.isAccountant == "y" ? true : false}
                        >
                            <MenuItem key="0" value="active">
                                Ativo
                            </MenuItem>                                

                            <MenuItem key="1" value="inactive">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div>          
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button type="submit" variant="primary" className="mr-3">Salvar</Button>
                        : <></>
                    }
                    <Link href="/parametros/centro-de-custos" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </div>
    );
}