import React, { useCallback } from "react";
import { Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Menu } from "../types/Menu";
import useMenu from "../hooks/menu";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PermissionMenu } from "../types/PermissionMenu";

export interface PermissionMenuSelectorProps {
    permissionMenus: PermissionMenu[];
    setPermissionMenus: React.Dispatch<React.SetStateAction<PermissionMenu[]>>;
    customChangePermissionHandler?: (checked: boolean, indexes: Array<number>) => void;
}

const useStyles = makeStyles(theme => ({
    fontLg: {
        fontSize: 22,
    },
}));

export default function PermissionMenuSelector({ permissionMenus, setPermissionMenus, customChangePermissionHandler }: PermissionMenuSelectorProps) {
    const { user } = useSelector((state: any) => state.auth);

    const classes = useStyles();

    const handleCollapsePermission = useCallback((collapsed: boolean, indexes: Array<number>) => {
        const aux = permissionMenus;
        if (indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].collapsed = collapsed;

            aux[i1].submenus = modifyPermissionSubmenusCollapse(aux[i1]);
        } else if (indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].submenus as PermissionMenu[];

            aux[i2].collapsed = collapsed || aux[i2].collapsed;
            submenu1[j2].collapsed = collapsed;

            submenu1[j2].submenus = modifyPermissionSubmenusCollapse(submenu1[j2]);
            aux[i2].submenus = submenu1;
        } else if (indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].submenus as PermissionMenu[];
            const submenu2 = submenu1[j3].submenus as PermissionMenu[];

            aux[i3].collapsed = collapsed || aux[i3].collapsed;
            submenu1[j3].collapsed = collapsed || submenu1[j3].collapsed;
            submenu2[k3].collapsed = collapsed;

            submenu2[k3].submenus = modifyPermissionSubmenusCollapse(submenu2[k3]);
            (aux[i3].submenus || [])[j3].submenus = submenu2;
        }

        setPermissionMenus([...aux]);
    }, [permissionMenus]);

    const handleChangePermissionDefault = useCallback((checked: boolean, indexes: Array<number>) => {
        const aux = permissionMenus;
        if (indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].checked = checked;

            aux[i1].submenus = modifyPermissionSubmenus(aux[i1]);

            if (aux[i1].extraPermissions) {
                aux[i1].extraPermissions = aux[i1].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        } else if (indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].submenus as PermissionMenu[];

            aux[i2].checked = checked || aux[i2].checked;
            submenu1[j2].checked = checked;

            submenu1[j2].submenus = modifyPermissionSubmenus(submenu1[j2]);
            aux[i2].submenus = submenu1;

            if (submenu1[j2].extraPermissions) {
                submenu1[j2].extraPermissions = submenu1[j2].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        } else if (indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].submenus as PermissionMenu[];
            const submenu2 = submenu1[j3].submenus as PermissionMenu[];

            aux[i3].checked = checked || aux[i3].checked;
            submenu1[j3].checked = checked || submenu1[j3].checked;
            submenu2[k3].checked = checked;

            submenu2[k3].submenus = modifyPermissionSubmenus(submenu2[k3]);
            (aux[i3].submenus || [])[j3].submenus = submenu2;

            if (submenu2[k3].extraPermissions) {
                submenu2[k3].extraPermissions = submenu2[k3].extraPermissions!.map((extraPermission) => ({ ...extraPermission, checked }))
            }
        }

        setPermissionMenus([...aux]);
    }, [permissionMenus]);

    const handleChangeExtraPermission = useCallback((checked: boolean, indexes: Array<number>, extraPermissionKey: string) => {
        const aux = permissionMenus;
        if (indexes.length === 1) {
            const [i1] = indexes;

            aux[i1].checked = checked || aux[i1].checked;
            if (aux[i1].extraPermissions) {
                aux[i1].extraPermissions = aux[i1].extraPermissions!.map((extraPermission) => {
                    if (extraPermission.key === extraPermissionKey) {
                        return { ...extraPermission, checked };
                    }
                    return extraPermission;
                });
            }
        } else if (indexes.length === 2) {
            const [i2, j2] = indexes;
            const submenu1 = aux[i2].submenus as PermissionMenu[];

            aux[i2].checked = checked || aux[i2].checked;
            submenu1[j2].checked = checked || submenu1[j2].checked;
            if (submenu1[j2].extraPermissions) {
                submenu1[j2].extraPermissions = submenu1[j2].extraPermissions!.map((extraPermission) => {
                    if (extraPermission.key === extraPermissionKey) {
                        return { ...extraPermission, checked };
                    }
                    return extraPermission;
                });
            }
        } else if (indexes.length === 3) {
            const [i3, j3, k3] = indexes;
            const submenu1 = aux[i3].submenus as PermissionMenu[];
            const submenu2 = submenu1[j3].submenus as PermissionMenu[];

            aux[i3].checked = checked || aux[i3].checked;
            submenu1[j3].checked = checked || submenu1[j3].checked;
            submenu2[k3].checked = checked || submenu2[k3].checked;
            if (submenu2[k3].extraPermissions) {
                submenu2[k3].extraPermissions = submenu2[k3].extraPermissions!.map((extraPermission) => {
                    if (extraPermission.key === extraPermissionKey) {
                        return { ...extraPermission, checked };
                    }
                    return extraPermission;
                });
            }
        }

        setPermissionMenus([...aux]);
    }, [permissionMenus]);

    const handleChangePermission = customChangePermissionHandler ?? handleChangePermissionDefault;

    function modifyPermissionSubmenus(menu: PermissionMenu): PermissionMenu[] {
        const checked = menu.checked ?? false;
        const submenus = menu.submenus?.filter(() => true) as PermissionMenu[];
        if (!submenus || submenus.length === 0) {
            return [];
        }
        for (let i = 0; i < submenus.length; i++) {
            submenus[i].checked = checked;
            submenus[i].extraPermissions = submenus[i].extraPermissions?.map((extraPermission) => ({ ...extraPermission, checked }));
            submenus[i].submenus = modifyPermissionSubmenus(submenus[i]);
        }
        return submenus;
    }

    function modifyPermissionSubmenusCollapse(menu: PermissionMenu): PermissionMenu[] {
        const collapsed = menu.collapsed ?? false;
        const submenus = menu.submenus?.filter(() => true) as PermissionMenu[];
        if (!submenus || submenus.length === 0) {
            return [];
        }
        for (let i = 0; i < submenus.length; i++) {
            submenus[i].collapsed = collapsed;
            submenus[i].submenus = modifyPermissionSubmenus(submenus[i]);
        }
        return submenus;
    }

    return <>
        {
            !!permissionMenus && (
                <>
                    <Tabs defaultActiveKey="1" id="permissionTabs">
                        {permissionMenus.map((menu, i) => (
                            <Tab key={i} eventKey={menu.id} title={menu.name}>
                                <div className="row" key={`${i}`}>
                                    <div className="col-md-7 col-lg-5">
                                        <TableContainer style={{ maxHeight: '75vh' }}>
                                            <Table stickyHeader className="wrap" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="none">
                                                            <IconButton
                                                                aria-label="expand row"
                                                                title="Expandir detalhes"
                                                                size="small"
                                                                onClick={(e) => handleCollapsePermission(!menu.collapsed, [i])}
                                                            >
                                                                <i className={menu.collapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell padding="none">
                                                            <Checkbox
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                                checked={menu.checked ?? false}
                                                                inputProps={{
                                                                    'aria-label': 'success checkbox',
                                                                }}
                                                                onChange={(e, checked) => handleChangePermission(checked, [i])}
                                                            />
                                                            <strong>{menu.name == "Dashboard" ? "Dashboard" : "Menu"} Ativo</strong>
                                                        </TableCell>
                                                        <TableCell>{menu.name == "Dashboard" ? "Dashboard" : "Menu"}</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {menu.submenus && menu.submenus.map((submenu1: PermissionMenu, j) => (
                                                        <React.Fragment key={`${i}-${j}`}>
                                                            <TableRow>
                                                                <TableCell padding="none">
                                                                    {(!!submenu1.submenus && submenu1.submenus.length > 0) || !!submenu1.extraPermissions ? (
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            title="Expandir detalhes"
                                                                            size="small"
                                                                            onClick={(e) => handleCollapsePermission(!submenu1.collapsed, [i, j])}
                                                                        >
                                                                            <i className={submenu1.collapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                                                        </IconButton>
                                                                    ) : <></>}
                                                                </TableCell>
                                                                <TableCell padding="none">
                                                                    <Checkbox
                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                        checked={submenu1.checked ?? false}
                                                                        inputProps={{
                                                                            'aria-label': 'success checkbox',
                                                                        }}
                                                                        onChange={(e, checked) => handleChangePermission(checked, [i, j])}
                                                                    />
                                                                </TableCell>
                                                                <TableCell><span>{submenu1.name}</span></TableCell>
                                                            </TableRow>

                                                            {(!!submenu1.submenus && submenu1.submenus.length > 0) || !!submenu1.extraPermissions ? (
                                                                <TableRow>
                                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                                                        <Collapse in={!!submenu1.collapsed} timeout="auto">

                                                                            {/* Submenu de nivel 2 */}
                                                                            {(!!submenu1.submenus && submenu1.submenus.length > 0) && (
                                                                                <Table size="small">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell padding="none"></TableCell>

                                                                                            <TableCell padding="none"></TableCell>

                                                                                            <TableCell padding="default">
                                                                                                <strong>Submenu</strong>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {submenu1.submenus.map((submenu2: PermissionMenu, k) => (
                                                                                            <TableRow key={`${i}-${j}-${k}`}>
                                                                                                <TableCell padding="none"></TableCell>

                                                                                                <TableCell padding="none">
                                                                                                    <Checkbox
                                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                                        checked={submenu2.checked ?? false}
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'success checkbox',
                                                                                                        }}
                                                                                                        onChange={(e, checked) => handleChangePermission(checked, [i, j, k])}
                                                                                                    />
                                                                                                </TableCell>

                                                                                                <TableCell padding="default">
                                                                                                    {submenu2.name}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            )}

                                                                            {/* Permissões adicionais */}
                                                                            {!!submenu1.extraPermissions && (
                                                                                <Table size="small">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell padding="none"></TableCell>

                                                                                            <TableCell padding="none"></TableCell>

                                                                                            <TableCell padding="default">
                                                                                                <strong>Permissão</strong>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {submenu1.extraPermissions.map((extraPermission, k) => (
                                                                                            <TableRow key={`${i}-${j}-${k}`}>
                                                                                                <TableCell padding="none"></TableCell>

                                                                                                <TableCell padding="none">
                                                                                                    <Checkbox
                                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                                        checked={extraPermission.checked ?? false}
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'success checkbox',
                                                                                                        }}
                                                                                                        onChange={(e, checked) => handleChangeExtraPermission(checked, [i, j], extraPermission.key)}
                                                                                                    />
                                                                                                </TableCell>

                                                                                                <TableCell padding="default">
                                                                                                    {extraPermission.label}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            )}
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : <></>}
                                                        </React.Fragment>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </>
            )
        }
    </>;
}