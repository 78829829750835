import React from "react";
import { Route, Switch } from "react-router-dom";

import { useSubheader } from "../../_metronic/layout";
import { NewPriceList } from "../pages/New-PriceList";
import { ListPriceList } from "../pages/List-PriceList";
import { EditPriceList } from "../pages/Edit-PriceList";
// import Button from '@material-ui/core/Button';

export function PriceList() {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Lista de Preços");

    return (
        <Switch>
          <Route path="/lista-de-precos/adicionar" component={NewPriceList}/>  
          <Route path="/lista-de-precos/:id" component={EditPriceList}/>
          <Route path="/lista-de-precos" component={ListPriceList} />               
        </Switch>
      );
}
