import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListModelForms } from '../pages/Bpm/ModelForm/List-ModelForm';
import { ListModelProcess } from '../pages/Bpm/ModelProcess/List-ModelProcess';
import { ListSolicitationProcess } from '../pages/Bpm/Solicitation/List-SolicitationProcess';
import { ProcessRequest } from '../pages/Bpm/Solicitation/ProcessRequest';
import { NewModelForm } from '../pages/Bpm/ModelForm/New-ModelForm';
import { NewModelProcess } from '../pages/Bpm/ModelProcess/New-ModelProcess';
import { SolicitationForm } from '../pages/Bpm/Solicitation/SolicitationForm';
import { SolicitationProcessForm } from '../pages/Bpm/SolicitationProcessForm/SolicitationProcessForm';
import { ListSolicitationProcessForm } from '../pages/Bpm/SolicitationProcessForm/List-SolicitationProcessForm';
import { ListSolicitation } from '../pages/Bpm/Solicitation/List-Solicitation';

export function Bpm() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("BPM");

    return (
        <Switch>
            <Route path="/bpm/modelagem-de-formulario/adicionar/:formId" component={NewModelForm}/>
            {/* <Route path="/bpm/modelagem-de-formulario/:id" component={ListModelForms}/> */}
            <Route path="/bpm/modelagem-de-formulario" component={ListModelForms}/>

            <Route path="/bpm/modelagem-de-processos/adicionar/:processId" component={NewModelProcess}/>
            <Route path="/bpm/modelagem-de-processos" component={ListModelProcess}/>

            <Route path="/bpm/solicitacoes-de-processos" component={ListSolicitationProcess}/>
            <Route path="/bpm/solicitacao-de-processos/:processId" component={ProcessRequest}/>

            <Route path="/bpm/solicitacao-formulario/:processId/solicitacao/:solicitationId" component={SolicitationForm}/>

            <Route path="/bpm/solicitacao-processo-formulario/:processId" component={SolicitationProcessForm}/>
            <Route path="/bpm/solicitacao-processo-formulario" component={ListSolicitationProcessForm}/>

            <Route path="/bpm/solicitacoes" component={ListSolicitation} />
        </Switch>
    );
}
