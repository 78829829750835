import { BillsToPay } from "../types/BillsToPay";
import { Company } from "../types/Company";
import { getLastDayOfMonth } from "../utils/dateTimeHelper";
import api from "./Api";
import { extraPermissionEnum, menuIdEnum } from "./PermissionMenuService";
import UserService from "./UserService";

class BillToPayService {
    getBillPaymentDate(bill: BillsToPay): string | null {
        if(!bill || bill.status !== 'paid') {
            return null;
        }

        if(!bill.payedDate && !bill.partialPayments) {
            return bill.dueDate || null;
        }

        let payedDate = bill.payedDate;
        if(!payedDate && bill.partialPayments) {
            const partials: any[] = JSON.parse(bill.partialPayments);
            payedDate = partials[partials.length - 1].payedDate;
        }

        return payedDate;
    }

    async resolveBillsDueDates(bills?: any[]) {
        let billsArray: any[] = bills ?? [];
        if(!bills) {
            const { data } = await api.get<BillsToPay[]>("billsToPay");
            billsArray = data;
        }

        billsArray.forEach((bill, index) => {
            const dueDateObj = new Date(`${bill.dueDate} 23:59:59`);
            const today = new Date();
            if(bill.status === 'pending') {
                const dueDateObj = new Date(`${bill.dueDate} 23:59:59`);
                if(dueDateObj.getTime() <= today.getTime()) {
                    billsArray[index].status = 'late';
                    // Marcar conta a receber como 'late' (Vencido)
                    api.put(`billsToPay/${bill.id}`, {
                        status: 'late',
                    });
                }
            } else if(bill.status === 'late') {
                if(dueDateObj.getTime() > today.getTime()) {
                    billsArray[index].status = 'pending';
                    api.put(`billsToPay/${bill.id}`, {
                        status: 'pending',
                    });
                }
            }
        });

        return billsArray;
    }

    async checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate: string, company: Company) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const year = today.getFullYear();
        const monthIndex = today.getMonth();
        const blockDayForChangeOfBillsSituationOfPastMonth = company.blockDayForChangeOfBillsSituationOfPastMonth || 1;
        const lastDayOfMonth = getLastDayOfMonth(year, monthIndex);
        const monthCloseDay = blockDayForChangeOfBillsSituationOfPastMonth > lastDayOfMonth ? lastDayOfMonth : blockDayForChangeOfBillsSituationOfPastMonth;
        
        const monthCloseDate = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/${monthCloseDay} 00:00:00`);

        // Realizar validação apenas quando tiver passado da data de fechamento do mês fiscal
        if (today.getTime() < monthCloseDate.getTime()) {
            return true;
        }

        const startOfMonth = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/01 00:00:00`);
        const paymentDateObject = new Date(`${paymentDate} 00:00:00`);

        // Validando se o usuário tem permissão para administrar fechamento de mês 
        if (paymentDateObject.getTime() < startOfMonth.getTime()) {
            const hasPermission = await UserService.checkIfAuthUserHasPermissionToExecuteAction(
                menuIdEnum.BILLS_TO_PAY,
                extraPermissionEnum.CAN_CHANGE_BILL_SITUATION_OF_PAST_MONTH
            );

            return hasPermission;
        }

        return true;
    }
}

export default new BillToPayService();