import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EditCompanyData } from '../pages/Config/CompanyData/Edit-CompanyData';

import { useSubheader } from '../../_metronic/layout';
import { SuperConfig } from '../pages/Config/SuperConfig';

export function Config() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Configurações");
    
    return (
        <Switch>
            <Route path="/config/editar-dados-empresa" component={EditCompanyData}/>
            <Route path="/config/super" component={SuperConfig}/>
        </Switch>
    );
}