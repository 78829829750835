type ObjectWithTypePeople = {
    typePeople: string,
    name: string,
    corporateName: string,
};

export function getNameByTypePeople(object?: ObjectWithTypePeople | null) {
    if(!object) {
        return '';
    }

    return object.typePeople.toLowerCase() === 'legal' ? object.corporateName : object.name;
}