import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, MenuItem, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import '../../../style.css';

import api from "../../../services/Api";
import { formatCurrency, formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import { extractDateStringFromTimestamp } from '../../../utils/dateTimeHelper';
import { subYears } from 'date-fns';
import ModalError from '../../../components/ModalError';
import { ExtractRow } from '../../../types/ExtractRow';
import { Link } from 'react-router-dom';
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';

type ExtractByMonth = {
    monthLabel: string;
    totalValue: number;
    extractRows: ExtractRow[];
}

type ExtractByCenterCost = {
    centerCostId: number;
    centerCostName: string;
    totalValue: number;
    percentageValue: number;
    extractRows: ExtractRow[];
    extractByMonth: ExtractByMonth[];

    collapsed?: boolean;
}

type DreCategoryRow = {
    dreSubCategoryId: number;
    dreSubCategoryName: string;
    totalValue: number;
    percentageValue: number;
    extractRows: ExtractRow[];
    extractByCenterCost: ExtractByCenterCost[];
    extractByMonth: ExtractByMonth[];

    collapsed?: boolean;
}

type DreCategoryResponse = {
    rows: DreCategoryRow[];
    totalValue: number;
    allMonths: ExtractByMonth[];
}

type Filters = {
    initialDate: string;
    finalDate: string;
    typeBill: string;
}

const today = new Date();

const oneYearAgo = subYears(today, 1);

export function ListReportDreCategory() {
    const [dreCategoryRows, setDreCategoryRows] = useState<DreCategoryRow[]>([]);
    const [totalValue, setTotalValue] = useState(0);
    const [allMonths, setAllMonths] = useState<ExtractByMonth[]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [msgError, setMsgError] = useState('');

    const [initialDate, setInitialDate] = useState(extractDateStringFromTimestamp(oneYearAgo.toISOString()));
    const [finalDate, setFinalDate] = useState(extractDateStringFromTimestamp(today.toISOString()));
    const [typeBill, setTypeBill] = useState("receive");
    const [showRevenueSubCategories, setShowRevenueSubCategories] = useState(false);

    const [allRowsCollapsed, setAllRowsCollapsed] = useState(false);

    const filtersRef = useRef<Filters | null>(null);

    const loadExportData = useCallback(async () => {
        // const { data } = await api.get<ReportBillsResponse>("/report/dreCategory", {
        //     params: {
        //         filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
        //         sortReference,
        //         sortDirection,
        //     }
        // });

        // const {
        //     rows,
        //     count,
        //     totalValue,
        //     totalDownloaded,
        //     totalFees,
        //     totalAddition,
        //     totalDiscount,
        //     totalTax,
        //     totalPaid,
        //     totalToPay,
        //     totalLate,
        //     totalToday,
        //     totalFuture
        // } = data;

        // const exportDataList: ReportDataRow[] = [];

        // rows.forEach((reportRow) => {
        //     const data = mountBodyDataRow(reportRow);

        //     const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

        //     exportDataList.push({
        //         cells: exportCells
        //     });
        // })

        // setExportTotals([
        //     { name: 'Quantidade de Contas', value: count },
        //     { name: 'Valor Original Total', value: formatCurrency(totalValue) },
        //     { name: 'Valor Total Baixado', value: formatCurrency(totalDownloaded) },
        //     { name: 'Valor Total Juros', value: formatCurrency(totalFees) },
        //     { name: 'Valor Total Acréscimos', value: formatCurrency(totalAddition) },
        //     { name: 'Valor Total Descontos', value: formatCurrency(totalDiscount) },
        //     { name: 'Valor Total Taxas', value: formatCurrency(totalTax) },
        //     { name: 'Valor Total Pago', value: formatCurrency(totalPaid) },
        //     { name: 'Valor Total a Pagar', value: formatCurrency(totalToPay) },
        //     { name: 'Pagamentos Atrasados', value: formatCurrency(totalLate) },
        //     { name: 'Pagamentos para Hoje', value: formatCurrency(totalToday) },
        //     { name: 'Pagamentos Futuros', value: formatCurrency(totalFuture) },
        // ])

        // setExportBodyData(exportDataList);
        // setCountTotalBillsToPay(count);
    }, []);

    const loadData = useCallback(async () => {
        setIsSearching(true);
        setAllRowsCollapsed(false);

        const { data } = await api.get<DreCategoryResponse>("/report/dreCategory", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
            }
        });

        const {
            rows,
            totalValue,
            allMonths
        } = data;

        setDreCategoryRows(rows);
        setTotalValue(totalValue);
        setIsSearching(false);
        setAllMonths(allMonths);
    }, []);

    useEffect(() => {
        resetDefaultDates();
        handleClickSearch();
    }, []);

    // useEffect(() => {
    //     loadExportData(lastSortDirection, lastSortReference);
    // }, [lastSortDirection, lastSortReference]);

    const resetDefaultDates = useCallback(() => {
        setInitialDate(extractDateStringFromTimestamp(oneYearAgo.toISOString()));
        setFinalDate(extractDateStringFromTimestamp(today.toISOString()));
    }, []);

    const validateFilters = useCallback(() => {
        if (!initialDate || !finalDate) {
            return 'Selecione a data inicial e a data final para realizar a busca!';
        }

        if (!typeBill) {
            return 'Selecione para exibir um tipo de plano de contas!';
        }

        return '';
    }, [initialDate, finalDate, showRevenueSubCategories, typeBill]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            initialDate,
            finalDate,
            typeBill,
        };

        const validationErrorMsg = validateFilters();
        if (validationErrorMsg) {
            setMsgError(validationErrorMsg);
            setShowModalError(true);
            setIsSearching(false);
            return;
        }

        loadData();
        loadExportData();
    }, [
        initialDate, finalDate,
        showRevenueSubCategories, typeBill,
        validateFilters,
        resetDefaultDates,
    ]);

    const handleCollapseDreCategoryRow = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, dreSubCategoryId: number) => {
        e.stopPropagation();

        setAllRowsCollapsed(false);

        const updatedRows = dreCategoryRows;

        for (const dreCategoryRow of updatedRows) {
            if (dreCategoryRow.dreSubCategoryId === dreSubCategoryId) {
                dreCategoryRow.collapsed = !dreCategoryRow.collapsed;
            }
        }

        setDreCategoryRows([...updatedRows]);
    }, [dreCategoryRows]);

    const handleCollapseAllRows = useCallback(() => {
        const updatedRows = dreCategoryRows;

        for (const dreCategoryRow of updatedRows) {
            dreCategoryRow.collapsed = !allRowsCollapsed;
        }

        setDreCategoryRows([...updatedRows]);

        setAllRowsCollapsed((prevState) => !prevState);
    }, [dreCategoryRows, allRowsCollapsed]);

    const handleClickExportExcel = useCallback(async () => {
        if (dreCategoryRows.length === 0) return;

        setIsGeneratingExcel(true);

        const workbook = new Workbook();

        const worksheet = workbook.addWorksheet();

        const monthColumns = allMonths.map((month) => ({
            header: month.monthLabel.toUpperCase(), key: month.monthLabel, width: 15
        }));

        worksheet.columns = [
            { header: 'PLANO DE CONTAS', key: 'dreSubCategoryName', width: 30 },
            { header: 'CENTRO DE CUSTO', key: 'centerCostName', width: 30 },
            { header: 'VALOR TOTAL (R$)', key: 'totalValue', width: 18 },
            { header: 'PORCENTAGEM (%)', key: 'percentageValue', width: 18 },
            ...monthColumns,
        ];
        worksheet.getRow(1).font = { bold: true };

        const totalMonthCells: any = {};
        allMonths.forEach((month) => {
            totalMonthCells[month.monthLabel] = formatCurrency(month.totalValue)
        });

        const row = worksheet.addRow({
            dreSubCategoryName: 'TOTAL DO PERÍODO',
            centerCostName: '',
            totalValue: formatCurrency(totalValue),
            percentageValue: `${formatNumberToString(100)}%`,
            ...totalMonthCells,
        });

        row.getCell(1).font = { bold: true };
        row.getCell(2).merge(row.getCell(1));

        for (const dreCategoryRow of dreCategoryRows) {
            for (const centerCostRow of dreCategoryRow.extractByCenterCost) {
                const centerCostMonthCells: any = {};
                allMonths.forEach((month) => {
                    const foundCenterCostMonth = centerCostRow.extractByMonth.find((centerCostMonth) => centerCostMonth.monthLabel === month.monthLabel);

                    let monthValue = '--';

                    if (foundCenterCostMonth) {
                        monthValue = formatCurrency(foundCenterCostMonth.totalValue);
                    }

                    centerCostMonthCells[month.monthLabel] = monthValue
                });

                worksheet.addRow({
                    dreSubCategoryName: dreCategoryRow.dreSubCategoryName,
                    centerCostName: centerCostRow.centerCostName,
                    totalValue: formatCurrency(centerCostRow.totalValue),
                    percentageValue: `${formatNumberToString(centerCostRow.percentageValue)}%`,
                    ...centerCostMonthCells,
                });
            }
        }

        // worksheet.addRow([
        //     'TOTAL',
        //     '',
        //     '',
        //     '',
        //     new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalReceive),
        //     new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(totalPay),
        //     new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(bills[bills.length - 1]?.balance),
        //     '',
        // ]);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), getFileNameWithDate({ filename: 'Fluxo_de_Caixa', extension: 'xlsx' }));

        setIsGeneratingExcel(false);
    }, [dreCategoryRows, allMonths, totalValue]);

    const numberOfColumns = 4 + allMonths.length;

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <ModalError
                showModalError={showModalError}
                setShowModalError={setShowModalError}
                msgError={msgError}
            />
            <div className="row d-flex align-items-center">
                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Período"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    DE
                                </InputAdornment>
                            ),
                        }}
                        value={initialDate}
                        onChange={(e) => setInitialDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-3">
                    <TextField
                        type="date"
                        label="Período"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ATÉ
                                </InputAdornment>
                            ),
                        }}
                        value={finalDate}
                        onChange={(e) => setFinalDate(e.target.value)}
                    />
                </div>

                <div className="col-lg-3 mt-3">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Exibir Plano de Contas</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" checked={typeBill === 'receive'} onChange={(e, checked) => setTypeBill('receive')} />}
                                label="Receitas"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" checked={typeBill === 'expense'} onChange={(e, checked) => setTypeBill('expense')} />}
                                label="Despesas"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </div>

                <div className="col-lg-3 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                            disabled={isGeneratingExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                            {isGeneratingExcel && (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-2"
                                    />
                                </>
                            )}
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3 border-top">
                <Paper style={{ width: '100%' }}>
                    <TableContainer style={{ maxHeight: '75vh' }}>
                        <Table stickyHeader style={{ position: 'sticky' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="none">
                                        <IconButton
                                            aria-label="expand row"
                                            title="Expandir tudo"
                                            size="small"
                                            onClick={() => handleCollapseAllRows()}
                                        >
                                            <i className={allRowsCollapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                        </IconButton>
                                    </TableCell>

                                    <TableCell padding="default">
                                        <strong>Plano de Contas</strong>
                                    </TableCell>


                                    <TableCell padding="default">
                                        <strong>Valor Total (R$)</strong>
                                    </TableCell>

                                    <TableCell padding="default">
                                        <strong>Porcentagem (%)</strong>
                                    </TableCell>

                                    {allMonths.map((month) => (
                                        <TableCell key={month.monthLabel} padding="default">
                                            <strong>{month.monthLabel}</strong>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    !dreCategoryRows.length && (
                                        <TableRow role="checkbox" tabIndex={-1}>
                                            <TableCell scope="row" padding="default" colSpan={numberOfColumns} className="text-center">
                                                Nenhuma informação encontrada...
                                            </TableCell>
                                        </TableRow>
                                    )
                                }

                                {
                                    dreCategoryRows.length > 0 && (
                                        <>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                style={{ background: "#efefef" }}
                                            >
                                                <TableCell padding="none"></TableCell>

                                                <TableCell scope="row" padding="default">
                                                    Total do Período
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    <b>{formatCurrency(totalValue)}</b>
                                                </TableCell>

                                                <TableCell scope="row" padding="default">
                                                    {formatNumberToString(100)}%
                                                </TableCell>

                                                {allMonths.map((month) => (
                                                    <TableCell key={month.monthLabel} padding="default">
                                                        {formatCurrency(month.totalValue)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>

                                            {
                                                dreCategoryRows.map((dreCategoryRow) => (
                                                    <React.Fragment key={dreCategoryRow.dreSubCategoryId}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell padding="none">
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    title="Expandir detalhes"
                                                                    size="small"
                                                                    onClick={(e) => handleCollapseDreCategoryRow(e, dreCategoryRow.dreSubCategoryId)}
                                                                >
                                                                    <i className={dreCategoryRow.collapsed ? 'flaticon2-arrow-down collapse-arrow-up' : 'flaticon2-arrow-down collapse-arrow-down'}></i>
                                                                </IconButton>
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default">
                                                                {dreCategoryRow.dreSubCategoryName}
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default">
                                                                <Link
                                                                    to={`/relatorios/extrato?initialDate=${initialDate}&finalDate=${finalDate}&subCategoryId=${dreCategoryRow.dreSubCategoryId}`}
                                                                >
                                                                    {formatCurrency(dreCategoryRow.totalValue)}
                                                                </Link>
                                                            </TableCell>

                                                            <TableCell scope="row" padding="default">
                                                                {formatNumberToString(dreCategoryRow.percentageValue)}%
                                                            </TableCell>

                                                            {allMonths.map((month) => {
                                                                const foundDreCategoryMonth = dreCategoryRow.extractByMonth.find(
                                                                    (extractMonth) => extractMonth.monthLabel === month.monthLabel
                                                                );

                                                                return (
                                                                    <TableCell key={month.monthLabel} padding="default">
                                                                        {foundDreCategoryMonth
                                                                            ? formatCurrency(foundDreCategoryMonth.totalValue)
                                                                            : <>--</>
                                                                        }
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={numberOfColumns}>
                                                                <Collapse in={!!dreCategoryRow.collapsed} timeout="auto">
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell padding="none"></TableCell>

                                                                                <TableCell padding="default">
                                                                                    <strong>Centro de Custo</strong>
                                                                                </TableCell>

                                                                                <TableCell padding="default">
                                                                                    <strong>Valor Total (R$)</strong>
                                                                                </TableCell>

                                                                                <TableCell padding="default">
                                                                                    <strong>Porcentagem (%)</strong>
                                                                                </TableCell>

                                                                                {allMonths.map((month) => (
                                                                                    <TableCell key={month.monthLabel} padding="default">
                                                                                        <strong>{month.monthLabel}</strong>
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {dreCategoryRow.extractByCenterCost.map((centerCostRow) => (
                                                                                <TableRow key={`${dreCategoryRow.dreSubCategoryId}-${centerCostRow.centerCostId}`}>

                                                                                    <TableCell padding="none"></TableCell>

                                                                                    <TableCell padding="default">
                                                                                        {centerCostRow.centerCostName}
                                                                                    </TableCell>

                                                                                    <TableCell padding="default">
                                                                                        <Link
                                                                                            to={`/relatorios/extrato?initialDate=${initialDate}&finalDate=${finalDate}&subCategoryId=${dreCategoryRow.dreSubCategoryId}&centerCost=${centerCostRow.centerCostId}`}
                                                                                        >
                                                                                            {formatCurrency(centerCostRow.totalValue)}
                                                                                        </Link>
                                                                                    </TableCell>

                                                                                    <TableCell padding="default">
                                                                                        {formatNumberToString(centerCostRow.percentageValue)}%
                                                                                    </TableCell>

                                                                                    {allMonths.map((month) => {
                                                                                        const foundCenterCostMonth = centerCostRow.extractByMonth.find(
                                                                                            (extractMonth) => extractMonth.monthLabel === month.monthLabel
                                                                                        );

                                                                                        return (
                                                                                            <TableCell key={month.monthLabel} padding="default">
                                                                                                {foundCenterCostMonth
                                                                                                    ? formatCurrency(foundCenterCostMonth.totalValue)
                                                                                                    : <>--</>
                                                                                                }
                                                                                            </TableCell>
                                                                                        )
                                                                                    })}
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {/* <TablePagination
                            page={page}
                            component="div"
                            count={listOptions.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            backIconButtonProps={{
                                'aria-label': 'Página Anterior',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Próxima Página',
                            }}
                            onChangePage={(_, next) => handleChangePage(next)}
                            onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                        /> */}
            </div>
        </div>
    )
}