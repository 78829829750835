import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export type ModalLimitProps = {
    messages: string[];
    showModalLimit: boolean;
    setShowModalLimit: React.Dispatch<React.SetStateAction<boolean>>;
    onHide?: () => void;
}

export default function ModalLimit({messages, showModalLimit, setShowModalLimit, onHide} :ModalLimitProps) {
    function handleCloseModal() {
        setShowModalLimit(false);
        
        if (onHide) {
            onHide();
        }
    }

    return (
        <Modal
            show={showModalLimit}
            onHide={handleCloseModal}
            aria-labelledby="contained-modal-warning"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                    Atenção
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {messages.map((msg, index) => (
                    <div className="row" key={index}>
                        <div className="col-12">
                            <b>{msg}</b>
                        </div>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Ok</Button>
            </Modal.Footer>
        </Modal>
    )
}