import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';

export function ListProducts(props: any) {
    
    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Cod.</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Tipo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.products.map((product: any) => (
                    <TableRow key={product.INSTANCE.id}>
                        <TableCell>{product.INSTANCE.code}</TableCell>
                        <TableCell>{product.INSTANCE.name}</TableCell>
                        <TableCell>{formatCurrency(product.INSTANCE.saleValue)}</TableCell>
                        <TableCell>{ product.INSTANCE.type == 'product' ? 'Produto' : 'Serviço' }</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}