import api from "./Api";

type InvoiceWhatsappData = {
    invoice: any;
    refName: string;
    companyId: string;
    companyName: string;
    message: string;
}

class WhatsappInvoiceService {
    async getAllWhatsappsByInvoice(invoiceId: string | number, type: string) {
        try {
            const { data } = await api.get<any[]>(`whatsapp-invoice/${type}/${invoiceId}`);
            return data;
        } catch (error) {
            return [];
        }
    }

    async sendWhatsapp(number: string, {
        invoice,
        refName,
        companyId,
        companyName,
        message,
    }: InvoiceWhatsappData) {
        const raw = {
            number,
            id: invoice.id,
            type: refName,
            companyName: companyName,
            companyId,
            message: message,
            billetUrl: invoice.billetUrl,
        };

        await api.post('whatsapp-invoice/send', raw);
    };
}

export default new WhatsappInvoiceService();