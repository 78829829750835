import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import {ListAccountsPlan} from '../pages/AccountsPlan/List-AccountsPlan';

export function AccountsPlan() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Plano de Contas");
    
    return (
        <Switch>
            <Route path="/plano-de-contas" component={ListAccountsPlan}/>
        </Switch>
    );
}