import React, { useCallback } from 'react';
import {
    Link,
    Radio,
    MenuItem,
    TextField,
    FormLabel,
    makeStyles,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
import { Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import api from '../../services/Api';
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';


type ConditionPayment = {
    name: string;
    payment: string;
    interval: number;
    interest: number;
    status: "active" | "inactive";
    firstPaymentInstallment: number;
    amountPaymentInstallment: number;
    interestType: "simple" | "compound";
};


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

export function NewConditionPayment() {
    const classes = useStyles();
    const history = useHistory();
    const subHeader = useSubheader();
    const { register, handleSubmit } = useForm();

    subHeader.setTitle("Adicionar uma Condição de Pagamento");

    const onSubmit = useCallback(async (formData: any) => {
        try {
            const aux = {
                name: String(formData.name).length > 0 ? formData.name : null,
                status: String(formData.status).length > 0 ? formData.status : null,
                payment: String(formData.payment).length > 0 ? formData.payment : null,
                interval: String(formData.interval).length > 0 ? formData.interval : null,
                interest: String(formData.interest).length > 0 ? formData.interest : null,
                interestType: String(formData.interestType).length > 0 ? formData.interestType : null,
                firstPaymentInstallment: String(formData.firstPaymentInstallment).length > 0 ? formData.firstPaymentInstallment : null,
                amountPaymentInstallment: String(formData.amountPaymentInstallment).length > 0 ? formData.amountPaymentInstallment : null,
            };

            const data: ConditionPayment = {
                name: aux.name,
                status: aux.status,
                payment: aux.payment,
                interestType: aux.interestType,
                interval: Number(aux.interval),
                interest: Number(aux.interest),
                firstPaymentInstallment: Number(aux.firstPaymentInstallment),
                amountPaymentInstallment: Number(aux.amountPaymentInstallment),
            };

            await api.post("conditionPayment", data);

            history.push("/parametros/condicoes-de-pagamento");
        } catch (error) {
            console.log(error);
        }
    }, []);
    
    return (
        <div className="row card card-body pt-4">
            <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
                <div className="row col-lg-12">
                    <div className="col-lg-6">
                        <TextField
                            { ...register("name") }
                            required
                            label="Nome"
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            placeholder="Apelido para a condição. EX: 30 / 60 / 90, 10 DIAS."
                        />
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            { ...register("payment") }
                            select
                            label="Forma de Pagamento"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem key="0" value="">
                                Selecione
                            </MenuItem>

                            {
                                paymentOptions.map((payment, index) => (
                                    <MenuItem key={index} value={payment.value}>
                                        {payment.value}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>

                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("status") }
                            select
                            label="Status"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                            name="status"
                        >
                            <MenuItem key="0" value="active">
                                Ativo
                            </MenuItem>  

                            <MenuItem key="1" value="inactive">
                                Inativo
                            </MenuItem>
                        </TextField>
                    </div> 
                </div> 

                <div className="row col-lg-12">
                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("interval") }
                            type="number"
                            label="Intervalo"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            placeholder="Entre Parcelas"
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("firstPaymentInstallment") }
                            type="number"
                            label="Primeira Parcela"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            placeholder="Dias para a primeira parcela"
                        />
                    </div>

                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("amountPaymentInstallment") }
                            type="number"
                            label="Qtde. de Parcelas"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </div> 

                    <div className="col-lg-3"> 
                        <TextField
                            { ...register("interest") }
                            type="number"
                            label="Juros"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            placeholder="%"
                        />
                    </div>
                </div>

                <div className="row col-lg-12">
                    <div className="col-lg-5 d-flex align-items-center"> 
                        <FormControl component="fieldset" className="ml-3 mt-3">
                            <FormLabel component="legend">Percentual de Juros</FormLabel>
                            <RadioGroup { ...register("interestType") } row>
                                <FormControlLabel
                                    value="simple"
                                    label="Simples"
                                    labelPlacement="end"
                                    control={
                                        <Radio color="primary" />
                                    }
                                />

                                <FormControlLabel
                                    value="compound"
                                    label="Composto"
                                    labelPlacement="end"
                                    control={
                                        <Radio color="primary" />
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>

                <div className="col-lg-2 mt-15 d-flex flex-row">
                    <Button type="submit" variant="primary" className="mr-3">Salvar</Button>
                    <Link href="/parametros/condicoes-de-pagamento" className="btn btn-secondary">Cancelar</Link>
                </div>
            </form>
        </div>
    );
}