import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditBranch } from '../pages/Branch/Edit-Branch';
import { ListBranch } from '../pages/Branch/List-Branch';
import { NewBranch } from '../pages/Branch/New-Branch';

export function Branches() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Empresas");
    
    return (
        <Switch>
            <Route path="/empresas-filiais/adicionar" component={NewBranch}/>
            <Route path="/empresas-filiais/:id" component={EditBranch}/>
            <Route path="/empresas-filiais" component={ListBranch}/>
        </Switch>
    );
}