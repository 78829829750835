import React from "react";
import { useLayoutProps } from "../hooks/useLayoutProps";
import { Aside } from "./aside/Aside";

export function LayoutContentBody({ children }) {
    const layoutProps = useLayoutProps();

    return (
        <>
            {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className={layoutProps.contentContainerClasses}>
                        {layoutProps.asideDisplay && (
                            <div className="d-lg-flex flex-row-fluid">
                                <Aside />
                                <div className="content-wrapper flex-row-fluid">
                                    {children}
                                </div>
                            </div>
                        )}
                        {!layoutProps.asideDisplay && <>{children}</>}
                    </div>
                    {/*end::Container*/}
                </div>
            )}
        </>
    )
}