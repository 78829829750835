import { useState } from "react";
import { LinkedFieldError } from "../types/LinkedFieldError";

export function useLinkedFieldsError() {
    const [showModalLinkedFieldsError, setShowModalLinkedFieldsError] = useState(false);
    const [linkedFieldsErrors, setLinkedFieldsErrors] = useState<LinkedFieldError[]>([]);

    return {
        showModalLinkedFieldsError,
        setShowModalLinkedFieldsError,
        linkedFieldsErrors,
        setLinkedFieldsErrors,
    };
}