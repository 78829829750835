import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Button, Spinner, Tabs, Tab, Modal } from "react-bootstrap";
import { MenuItem, TextField, Link, InputAdornment } from '@material-ui/core';


import api from "../../services/Api";
import { PaymentDetails, Product, usePurchaseOrder } from './context';
import { ListProducts } from './ListProducts';
import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import { ConditionOrInstallment } from './ConditionOrInstallments';
import { OrdersSituation } from '../../components/ListWithModalChangeSituation';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import LogService from '../../services/LogService';
import CustomerSelect from '../../components/CustomerSelect';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import SellerService from '../../services/SellerService';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import useSellerUser from '../../hooks/sellerUser';
import { uploadMultipleFiles } from '../../utils/FilesHelper';
import UploadFiles from '../../components/UploadFiles';

export function NewPurchaseOrder() {
    const [isSubmit, setIsSubmit] = useState(false);

    const [activeTab, setActiveTab] = useState("products");

    const [note, setNote] = useState("");
    const [weight, setWeight] = useState("");
    const [carrier, setCarrier] = useState("");
    const [modalityFreight, setModalityFreight] = useState(0);
    const [deadline, setDeadline] = useState("");
    const [insideNotes, setInsideNotes] = useState("");
    const [supplierId, setSupplierId] = useState<number>();
    const [supplierName, setSupplierName] = useState("");
    const [seller, setSeller] = useState("");
    const [total, setTotal] = useState(0);
    const [numberPO, setNumberPO] = useState(0);
    const [orderDate, setOrderDate] = useState("");
    const [freightPrice, setFreightPrice] = useState(0);
    const [discountMoney, setDiscountMoney] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [conditionName, setConditionName] = useState("");
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [reference, setReference] = useState("");

    //Input Verify
    const [supplierError, setSupplierError] = useState(false);
    const [productsError, setProductsError] = useState(false);
    const [installmentsError, setInstallmentsError] = useState(false);

    const { idToClone } = useParams<{ idToClone: string }>();
    const history = useHistory();
    const subHeader = useSubheader();
    const { handleSubmit } = useForm();
    const { classes, products, payments, purchaseOrders, dispatchProducts, dispatchPayment } = usePurchaseOrder();
    const { userSellerInfo } = useSellerUser();

    const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
    const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

    subHeader.setTitle("Adicionar uma Ordem de Compra");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setSupplierId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        if (userSellerInfo) {
            setSeller(String(userSellerInfo.id));
        }
    }, [userSellerInfo]);

    useEffect(() => {
        dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
        dispatchProducts({ type: "ADD" });
        dispatchPayment({
            type: "GENERATE",
            payload: {
                amount: 0,
                value: 0,
            }
        });
        setOrderDate(format(Date.now(), "yyyy-MM-dd"));
    }, []);

    useEffect(() => {
        const helper = purchaseOrders;
        const sorted = helper.sort(
            (el, el_) => {
                if (el.id > el_.id) return 1;
                if (el.id < el_.id) return -1;
                return 0;
            }
        );

        let newNumberPO = 0;

        if (sorted.length > 0)
            newNumberPO = sorted[helper.length - 1].id + 2;
        else
            newNumberPO = 1;

        setNumberPO(newNumberPO);
    }, [purchaseOrders]);

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        const discountNumber = discountMoney ? discountMoney : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freightPrice ? freightPrice : 0;

        const partial = totalProduct + freigthNumber;

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setFreightPrice(0);
            setTotal(0);
            setDiscountMoney(0);

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {

            total = total - (total * (discountPercentageFloat / 100));
        }

        setTotal(total);
    }, [products, discountMoney, discountPercentage, freightPrice]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    useEffect(() => {
        if (!idToClone) return;

        async function getOrderToCloneData() {
            const { data: orderToClone } = await api.get(`purchase-order/${idToClone}`);

            if (!orderToClone) return;

            const productsData: Product[] = JSON.parse(orderToClone.products);
            const installmentsData: PaymentDetails[] = JSON.parse(orderToClone.installments);

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: productsData,
                }
            });

            // dispatchPayment({
            //     type: "INITIAL",
            //     payload: {
            //         payments: installmentsData,
            //     }
            // });

            setNote(orderToClone.note);
            setTotal(parseFloat(orderToClone.totalPrice));
            setWeight(formatCurrency(orderToClone.totalWeight));
            setSeller(String(orderToClone.seller));
            setCarrier(orderToClone.carrier);
            setSupplierId(orderToClone.supplierId ?? undefined);
            setSupplierName(orderToClone.supplierName ?? '');
            // setDeadline(orderToClone.deadline);
            setInsideNotes(orderToClone.insideNote);
            setFreightPrice(parseFloat(orderToClone.freightPrice));
            setDiscountMoney(orderToClone.discountMoney);
            setConditionName(orderToClone.conditionName);
            setModalityFreight(Number(orderToClone.modalityFreight));
            setDiscountPercentage(orderToClone.discountPercentage);
            setReference(orderToClone.reference);
        }

        getOrderToCloneData();
    }, [idToClone]);

    function inputsValid() {
        setSupplierError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        var isSubmit = true;

        if (!supplierId && !supplierName) {
            setMsgError("Selecione um fornecedor!");
            setShowModalAttention(true);
            setSupplierError(true);
            isSubmit = false;
            return isSubmit;
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setActiveTab("products");
            setMsgError("Adicione pelo menos 1(um) Produto para a Ordem de Compra!");
            setShowModalAttention(true);
            isSubmit = false;
            return isSubmit;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (!payments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas da ordem de compra!");
            setShowModalAttention(true);
            setInstallmentsError(true);
            isSubmit = false;
            return isSubmit;
        }

        const sumInstallments = payments.reduce((sum, payment) => sum + payment.value, 0);

        if (sumInstallments !== formatToFloat(total)) {
            setActiveTab("payment");
            setMsgError("A soma das parcelas deve concidir com o valor total da ordem de compra!");
            setShowModalAttention(true);
            setInstallmentsError(true);
            isSubmit = false;
            return isSubmit;
        }

        return isSubmit;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const isValid = (data: any) => {
        setProductsError(data)
    };

    const onSubmit = async (data: any) => {
        if (inputsValid()) {
            try {
                let filesPath: any  = [];
                if (filesSelected) {
                    filesPath = await uploadMultipleFiles(filesSelected, setShowModalAttention, setMsgError, setIsSubmit);                
                    if (filesPath === false) {
                        return;
                    }
                }
                
                const situation: OrdersSituation[] = [];

                situation.push({
                    dateSituation: format(Date.now(), "yyyy-MM-dd"),
                    commentsSituation: "",
                    statusSituation: "open",
                });

                const raw = {
                    orderDate,
                    numberOrder: String(numberPO),
                    insideNote: String(insideNotes).length > 0 ? insideNotes : null,
                    supplierId: supplierId,
                    supplierName: !supplierId ? supplierName : null,
                    carrier: String(carrier).length > 0 ? carrier : null,
                    seller: seller.length > 0 ? Number(seller) : null,
                    note: String(note).length > 0 ? note : null,
                    totalWeight: String(weight).length > 0 ? formatToFloat(weight) : null,
                    deadline: String(deadline).length > 0 ? deadline : null,
                    modalityFreight: String(modalityFreight).length > 0 ? Number(modalityFreight) : null,
                    discountPercentage: String(discountPercentage).length > 0 ? formatToFloat(discountPercentage) : null,
                    freightPrice: freightPrice ? freightPrice : null,
                    discountMoney: discountMoney ? discountMoney : null,
                    conditionName: String(conditionName).length > 0 ? conditionName : null,
                    status: "open",
                    totalPrice: total,
                    products: JSON.stringify(products),
                    situation: JSON.stringify(situation),
                    installments: JSON.stringify(payments),
                    movedToStock: "n",
                    reference: reference,
                    attachments: JSON.stringify(filesPath)
                }

                const result = await api.post("purchase-order", raw);

                history.push("/ordem-de-compra");

                LogService.logRegister({
                    itemId: result.data.id,
                    module: 'Ordem de compra',
                    itemName: 'Compra'
                });

            } catch (error) {
                console.log(error);
            }
        }
    }

    return (

        <div className="card card-custom gutter-b p-6 newProductWrapper">
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType="supplier"
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5">
                            <CustomerSelect
                                label="Fornecedor*"
                                hasError={supplierError}
                                isEdit={false}
                                customerId={supplierId}
                                setCustomerId={setSupplierId}
                                customerName={supplierName}
                                setCustomerName={setSupplierName}
                                allowIncomplete={true}
                                typeCustomer="supplier"
                                onClickAddCustomer={handleClickAddCustomer}
                            />
                        </div>
                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Vendedor"
                                getOptionLabel={(option: any) => option.name}
                                value={seller}
                                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if (!seller) return null;
                                    return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                }}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                disabled
                                size="small"
                                label="Nº do pedido"
                                margin="normal"
                                variant="outlined"

                                value={numberPO}
                            />
                        </div>

                        <div className="col-lg-1 d-flex align-items-end">
                            <Button
                                type='submit'
                                className='mb-2'
                                variant="primary"
                                disabled={isSubmit}
                            >

                                {
                                    isSubmit ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />

                                            <span className='ml-2'>
                                                Aguarde...
                                            </span>

                                        </>
                                        :
                                        <span>Salvar</span>
                                }
                            </Button>
                        </div>
                    </div>
                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' className="mb-3 mt-3">
                    <Tab eventKey='products' title='Produtos'>
                        <div className="card-body p-0">
                            <div className="row">
                                {
                                    products.map(
                                        (prod, index) => (
                                            <ListProducts
                                                key={index}
                                                product={prod}
                                                index={index}
                                                onClickAddProduct={handleClickAddProduct}
                                            />
                                        )
                                    )
                                }

                                <div className="row col-lg-12 mt-3 ml-2">
                                    <button
                                        type="button"
                                        className="btn btn-link-secondary p-0"
                                        onClick={() => dispatchProducts({ type: "ADD" })}
                                    >
                                        <ins>+ adicionar outro produto</ins>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='totalRequest' title='Totais do Pedido'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Valor dos produtos"
                                        disabled
                                        className="inputSmaller"
                                        value={products.reduce((acc, { total }) => acc + total, 0)}
                                        startAdornment="R$"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <TextField
                                        size="small"
                                        label="Valor do IPI"
                                        margin="normal"
                                        variant="outlined"
                                        disabled
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <NumericFormat
                                        label="Valor do Frete"
                                        className="inputSmaller"
                                        value={freightPrice}
                                        onChange={e => setFreightPrice(formatToFloat(e.target.value))}
                                        startAdornment="R$"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Desconto (R$)"
                                        className="inputSmaller"
                                        value={discountMoney}
                                        onChange={e => setDiscountMoney(formatToFloat(e.target.value))}
                                        startAdornment="R$"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <NumericFormat
                                        className="inputSmaller"
                                        margin="normal"
                                        customInput={TextField}
                                        variant="outlined"
                                        label="Desconto"
                                        startAdornment="%"
                                        value={discountPercentage}
                                        onChange={(e) => setDiscountPercentage(e.target.value)}
                                    />
                                </div>


                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Peso total"
                                        className="inputSmaller"
                                        value={weight}
                                        onChange={(e) => setWeight(e.target.value)}
                                        startAdornment="KG"
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Valor total do pedido"
                                        disabled
                                        className="inputSmaller"
                                        value={total}
                                        startAdornment="R$"
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='payment' title='Pagamento'>
                        <div className="card-body p-0">
                            <ConditionOrInstallment total={total} conditionName={conditionName} setConditionName={setConditionName} />
                        </div>
                    </Tab>

                    <Tab eventKey='transport' title='Transporte'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-4">
                                    <TextField
                                        select
                                        size="small"
                                        label="Modalidade de Frete"
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        value={modalityFreight}
                                        onChange={(evt) => setModalityFreight(Number(evt.target.value))}
                                    >
                                        <MenuItem key="0" value="0">
                                            Emitente
                                        </MenuItem>

                                        <MenuItem key="1" value="1">
                                            Destinatário
                                        </MenuItem>

                                        <MenuItem key="2" value="2">
                                            Terceiro
                                        </MenuItem>

                                        <MenuItem key="3" value="3">
                                            Próprio por conta do Remetente
                                        </MenuItem>

                                        <MenuItem key="4" value="4">
                                            Próprio por conta Destinatário
                                        </MenuItem>

                                        <MenuItem key="5" value="9">
                                            Sem Frete
                                        </MenuItem>
                                    </TextField>
                                </div>

                                <div className="col-lg-8">
                                    <ApiResourceSelect
                                        label="Transportadora"
                                        freeSolo
                                        getOptionLabel={(option: Carrier) => option.name}
                                        value={carrier}
                                        onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                        onInputChange={(typedText) => setCarrier(typedText || '')}
                                        apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='details-request' title='Detalhes do Pedido'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <TextField
                                        size="small"
                                        type="date"
                                        label="Data da ordem"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        value={orderDate}
                                    />
                                </div>

                                <div className="col-lg-2 d-flex align-items-center">
                                    <TextField
                                        size="small"
                                        type="date"
                                        label="Prazo de Entrega"
                                        margin="normal"
                                        variant="outlined"
                                        value={deadline}
                                        onChange={(e) => setDeadline(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        label="Referência"
                                        margin="normal"
                                        variant="outlined"
                                        value={reference}
                                        onChange={(evt) => setReference(evt.target.value)}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        label="Observações"
                                        multiline
                                        rows="10"
                                        placeholder="Esta informação será impressa nas observações da nota."
                                        margin="normal"
                                        variant="outlined"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                </div>


                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        label="Observações Internas"
                                        multiline
                                        rows="10"
                                        placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                        margin="normal"
                                        variant="outlined"
                                        value={insideNotes}
                                        onChange={(e) => setInsideNotes(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='attachment' title='Anexo'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 ml-2">
                                    <UploadFiles
                                        label="Contrato/Anexos"
                                        filesSelected={filesSelected}
                                        setFilesSelected={setFilesSelected}
                                        filesNamesSaved={filesNamesSaved}
                                        setFilesNamesSaved={setFilesNamesSaved}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </form>

        </div>
    );
}