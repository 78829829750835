import { Grid, MenuItem, TextField, Select, FormControl, InputLabel, SvgIcon, Popover, Typography, Checkbox, FormControlLabel} from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Spinner, Tab, Tabs, Table, Modal } from 'react-bootstrap';
import { appendErrors, useForm } from 'react-hook-form';
import DestinationField from '../../../components/DestinationField';
// import BpmnViewer from '../../../components/BpmnViewer'
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/Api';
import { useStyles } from '../../../hooks/styles';
import ModalSuccess from '../../../components/ModalSuccess';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import BpmFormService from '../../../services/BpmFormService';
import BpmnViewer from '../../../components/BpmnViewer';

type param = {
    processId: string,
}

interface TableRowData {
  id: string,
  tipo: string,
  atividade?: string,
  descricaoAtividade?: string,
  responsaveis?: Responsible[],
  destino?: string[],
  tipo_id?: number,
  alinhamento?: number,
  prazo?: number,
  permissions?: { [key: string]: string };
  container?: object,
  subcontainers?: string[],
  width?: number,
  height?: number,
  x?: number,
  y?: number,
  parent?: object,
  children?: object,
  aprovacaoSupervisor?: boolean,
}

interface FormsList {
    companyId: number,
    id: number,
    name: string,
}

interface Employee{
    id: string;
    firstname: string;
    lastname?: string;
}

interface Supervisor{
    id: string;
    firstname: string;
    lastname?: string;
}

interface Responsible{
    id: string;
    firstname: string;
    lastname?: string;
}

interface CustomLabelProps extends TableRowData {
    onEdit: () => void;
    onDelete: () => void;
}

// Componente CustomLabel com botões de edição e exclusão
const CustomLabel: React.FC<CustomLabelProps> = ({ tipo, atividade, responsaveis, aprovacaoSupervisor, destino, alinhamento, onEdit, onDelete }) => {
    const icons: { [key: string]: any } = {
        "Início": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M1015.477 98.928C654.779 97.73 308.674 330.794 171.775 664.19c-144.118 330.153-69.237 740.284 182.862 997.772c242.463 260.992 641.949 356.786 976.52 234.672c346.739-118.074 602.723-458.594 616.638-825.092c23.34-360.13-188.535-719.421-512.953-876.293C1307.807 131.856 1165.949 98.797 1024 99a832.3 832.3 0 0 0-8.523-.072zm25.351 99.972c353.073 1.939 685.605 257.36 776.918 599.03c97.03 326.907-34.214 705.405-315.906 899.025C1209.227 1912.03 775.3 1896.47 498.977 1660.8c-278.414-221.98-378.072-632.576-235.227-958.182C387.557 401.605 698.338 195.18 1024 199c5.614-.098 11.224-.13 16.828-.1z"/></g></svg></svg>,
        "Atividade": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><rect width="17.563" height="14.478" x="1.23" y="1035.052" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.034" rx="2.759" transform="translate(55.328 -99820.702) scale(96.7529)"/></g></svg></svg>,
        "Fim": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M1015.043 99.002C599.21 95.906 209.098 411.146 121.606 817.247c-84.386 356.719 66.704 754.624 369.312 962.585c293.721 210.37 712.33 226.857 1017.865 31.474c307.224-188.136 488.14-563.828 430.814-921.32c-52.494-370.583-348.523-692.886-716.13-769.06c-68.345-15.248-138.415-22.388-208.424-21.924zm22.08 289.882c305.56-.968 586.24 251.915 617.384 556.116c39.754 290.762-147.703 594.914-429.538 682.33c-275.076 93.609-606.772-25.852-750.075-281.009c-158.564-264.725-91.1-641.965 160.165-825.757c113.947-87.92 258.202-134.99 402.064-131.68z"/></g></svg></svg>,
        "Exclusivo": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M1024.022 99.36c-19.324-.017-38.646 7.15-52.98 21.55L120.937 971.023c-28.67 28.668-28.537 77.295.132 105.963l849.971 849.965c28.67 28.678 77.294 28.804 105.963 0l850.106-850.1c28.669-28.667 28.536-77.296-.135-105.964L1077.002 120.91c-14.334-14.334-33.657-21.534-52.98-21.55zm-.065 126.045l798.66 798.666l-798.66 798.657l-798.66-798.657l798.66-798.666zM725.686 669.792c-.014 0-9.612 1.838-9.62 1.838c-.01 0-8.144 5.513-8.15 5.513l-30.732 30.739c-.01 0-5.61 8.225-5.614 8.322c0 .01-1.737 9.48-1.736 9.48c0 .01 1.868 9.385 1.871 9.385c0 .01 5.338 8.322 5.344 8.322l280.707 280.7l-280.572 280.574v-.088c0 .01-5.61 8.32-5.614 8.32c0 .01-1.736 9.483-1.736 9.483c0 .02 1.868 9.385 1.871 9.385c0 0 5.339 8.223 5.344 8.32l30.734 30.728c.01.01 8.411 5.516 8.418 5.516c.01 0 9.346 1.838 9.354 1.838c.01 0 9.479-1.74 9.486-1.74c.01 0 8.28-5.614 8.285-5.614l280.576-280.582l280.637 280.641c.01.01 8.412 5.516 8.418 5.516c.01 0 9.346 1.838 9.354 1.838c.01 0 9.48-1.743 9.488-1.743c.01 0 8.276-5.61 8.281-5.61l30.735-30.73c.01-.01 5.475-8.126 5.48-8.126c0-.01 1.871-9.58 1.871-9.676c0-.01-1.869-9.385-1.873-9.385c0 0-5.472-8.418-5.478-8.418l-280.606-280.611l280.608-280.604c.01 0 5.473-8.127 5.478-8.127c0-.01 1.871-9.578 1.871-9.578c0-.02-1.868-9.385-1.87-9.385c0-.01-5.606-8.322-5.612-8.322l-30.735-30.738c-.01 0-8.143-5.514-8.15-5.514c-.01 0-9.345-1.84-9.353-1.84c-.01 0-9.613 1.84-9.62 1.84c-.01 0-8.145 5.514-8.15 5.514l-280.613 280.613l-280.739-280.748v-.088c-.01 0-8.278-5.32-8.285-5.32c-.01 0-9.34-1.837-9.351-1.838h-.002z"/></g></svg></svg>,
        "Paralelo": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M1024.022 99.36c-19.324-.017-38.646 7.15-52.98 21.55L120.937 971.023c-28.67 28.668-28.537 77.295.132 105.963l849.971 849.965c28.67 28.678 77.294 28.804 105.963 0l850.106-850.1c28.669-28.667 28.536-77.296-.135-105.964L1077.002 120.91c-14.334-14.334-33.657-21.534-52.98-21.55zm-.065 126.045l798.66 798.666l-798.66 798.657l-798.66-798.657l798.66-798.666zm-21.803 329.82c0 .01-9.657 1.838-9.662 1.838c-.01 0-7.908 5.323-7.914 5.323c-.01.01-5.497 8.127-5.502 8.127c0 .01-1.86 9.675-1.863 9.675V977.04H580.188l-.067-.078c0 .01-9.618 2.129-9.623 2.129c-.01.01-7.907 5.322-7.912 5.322l.008-.098c-.01.01-5.497 8.127-5.502 8.127c-.01 0-1.861 9.676-1.865 9.676v43.47s1.848 9.783 1.914 9.85c0 .01 5.478 7.934 5.478 7.934c.01.01 7.957 5.322 7.96 5.322c.01.01 9.656 2.127 9.66 2.127h396.978v396.785l-.063-.058c0 .01 1.916 9.85 1.915 9.85c0 .01 5.476 7.933 5.476 7.933c.01.01 7.96 5.32 7.961 5.32c0 0 9.59 2.032 9.662 2.13l43.461-.01c.011 0 9.846-2.032 9.852-2.032c0 0 7.908-5.32 7.914-5.32c.01-.01 5.47-7.936 5.476-7.936c0 0 1.887-9.82 1.89-9.82V1070.86h396.88c.011.01 9.847-2.031 9.851-2.031c.01 0 7.91-5.322 7.914-5.322c.01-.01 5.471-7.934 5.477-7.934c0 0 1.886-9.82 1.89-9.82v-43.461c0-.01-1.877-9.58-1.874-9.676c-.01-.01-5.45-8.127-5.518-8.127c-.01-.01-7.958-5.32-7.96-5.32c0 .01-9.82-2.13-9.825-2.033h-396.838V580.294c0-.01-1.878-9.577-1.875-9.674c-.01-.01-5.45-8.129-5.45-8.129c-.01-.01-7.956-5.32-7.958-5.32c-.01 0-9.848-1.936-9.852-1.936l-43.469-.01z"/></g></svg></svg>,
        "Inclusivo": <svg width="50" height="50" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 2048 2048" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="currentColor" d="M1024.022 99.36c-19.324-.017-38.646 7.15-52.98 21.55L120.937 971.023c-28.67 28.668-28.537 77.295.132 105.963l849.971 849.965c28.67 28.678 77.294 28.804 105.963 0l850.106-850.1c28.669-28.667 28.536-77.296-.135-105.964L1077.002 120.91c-14.334-14.334-33.657-21.534-52.98-21.55zm-.065 126.045l798.66 798.666l-798.66 798.657l-798.66-798.657l798.66-798.666zm.043 368.6c-237.232 0-430 192.78-430 430.008c0 237.228 192.768 430 430 430s430-192.772 430-430c0-237.229-192.768-430.008-430-430.008zm0 47.69c211.408 0 382.323 170.912 382.323 382.318c0 211.405-170.915 382.33-382.323 382.33c-211.407 0-382.322-170.925-382.322-382.33c0-211.406 170.915-382.319 382.322-382.319z"/></g></svg></svg>,
        "Arrow": <svg width="40" height="40" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><rect width="512" height="512" x="0" y="0" rx="30" fill="transparent" stroke="transparent" strokeWidth="0" strokeOpacity="100%" paintOrder="stroke"></rect><svg width="256px" height="256px" viewBox="0 0 32 32" fill="currentColor" x="128" y="128" role="img" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m22 6l8 10l-8 10m8-10H2"/></g></svg></svg>,
    };

    return (
        <tr>
            <td className='d-flex align-items-center'>
                <span style={{marginLeft: `${alinhamento}px`}}>
                    {icons[tipo]}
                </span>
                {/* <span style={{marginLeft: `${alinhamento}px`}}>icon</span> */}
                <span className={tipo == 'Início'? 'bg-primary p-2 rounded text-black' : (tipo === 'Fim'? 'bg-danger p-2 rounded text-white' : (tipo === 'Atividade'? 'bg-info p-2 rounded text-white' : 'bg-warning p-2 rounded text-white'))}>{tipo}</span>
            </td>
            <td>{atividade}</td>
            <td>
                {responsaveis?.map((responsavel) => (
                    <span key={responsavel.id}>{responsavel.firstname} {responsavel?.lastname}</span>
                ))}
            </td>
            <td>{aprovacaoSupervisor ? "Sim" : "Não"}</td>
            <td>
                {destino ? (
                    destino.map((item, index) => (
                        <div key={index} className="destino-item">
                            {icons['Arrow']}
                            <span>{item}</span>
                        </div>
                    ))
                ) : ''}
            </td>
            <td>
                <Button variant="primary" onClick={onEdit} style={{ marginRight: '10px' }}>
                    Editar
                </Button>
                <Button variant="danger" onClick={onDelete}>
                    Excluir
                </Button>
            </td>
        </tr>
    );
};

const sortTableData = (data: TableRowData[]) => {
    const newData: TableRowData[] = [];
    const remainingData: TableRowData[] = [...data]; // Clona o array original
    let cont = 0;
  
    // Processar objetos sem destino e que não são destino de outros objetos
    remainingData.forEach(currentItem => {
      const isDestination = remainingData.some(item => item.destino && item.destino.includes(currentItem.atividade || ''));
      if (!currentItem.destino?.length && !isDestination) {
        newData.push({ ...currentItem, alinhamento: 0 });
        cont += 1;
      }
    });
  
    // Remover os itens já processados
    remainingData.forEach(currentItem => {
      if (newData.some(item => item.id === currentItem.id)) {
        const index = remainingData.indexOf(currentItem);
        if (index !== -1) {
          remainingData.splice(index, 1);
        }
      }
    });
  
    // Processar os demais itens com destinos e dependências
    remainingData.forEach(currentItem => {
      const exists = newData.some(item => item.id === currentItem.id);
      if (!exists) {
        let alinhamento = cont * 10; //refatorar
        if (currentItem.destino && currentItem.destino.length > 0) {
          alinhamento = cont * 10;
        }
        newData.push({ ...currentItem, alinhamento: alinhamento });
      }
  
      if (currentItem.destino && currentItem.destino.length > 0) {
        const order = currentItem.destino.filter(destino => {
          const dependentIndex = newData.findIndex(item => item.atividade === destino);
  
          if (dependentIndex !== -1) {
            // Mover o item dependente para logo após o pai
            const dependentItem = newData.splice(dependentIndex, 1)[0];
            dependentItem.alinhamento = cont * 10;
            const parentIndex = newData.findIndex(item => item.atividade === currentItem.atividade);
            newData.splice(parentIndex + 1, 0, dependentItem);
            return true;
          }
          return false;
        });
      }
      cont += 1;
    });
  
    // Substituir os dados originais com os novos dados ordenados
    data.splice(0, newData.length, ...newData);
    return newData;
};

export function NewModelProcess() {
    const { register, watch, handleSubmit } = useForm();
    const { push: pushHistory, location: { pathname } } = useHistory();
    const { processId } = useParams<param>();
    const [activeTab, setActiveTab] = useState("details");
    const [activityTab, setActivityTab] = useState("general");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [gatwayModal, setGatwayModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorModalText, setErrorModalText] = useState<string>("");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [successModalText, setSuccessModalText] = useState<string>("");
    const [redirect, setRedirect] = useState<string>("");
    const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);
    const [tableData, setTableData] = useState<TableRowData[]>([]);
    const [tipo, setTipo] = useState<string>('Exclusivo');
    const [permissions, setPermissions] = useState<{ [key: string]: string }>({});
    const [atividade, setAtividade] = useState<string>('');
    const [descricaoAtividade, setDescricaoAtividade] = useState<string>('');
    const [prazo, setPrazo] = useState<number>(0);
    const [supervisores, setSupervisores] = useState<Supervisor[]>([]);
    const [responsaveis, setResponsaveis] = useState<Responsible[]>([]);
    const [destino, setDestino] = useState<string[]>([]);
    const [sortedData, setSortedData] = useState<TableRowData[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<string>("");
    // const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [formsList, setFormList] = useState<FormsList[]>([]);
    const [formPermissions, setFormPermissisons] = useState<any>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [hasAprovacaoSupervisor, setAprovacaoHasSupervisor] = useState(false);

    const [anchorElDestino, setAnchorElDestino] = useState<HTMLButtonElement | null>(null);
    const [anchorElPermissoes, setAnchorElPermissoes] = useState<HTMLButtonElement | null>(null);
    const [anchorElGateway, setAnchorElGateway] = useState<HTMLButtonElement | null>(null);

    const [processName, setProcessName] = useState<string>("");
    const [form, setForm] = useState<string>("0");
    const [oldForm, setOldForm] = useState<any>([]);
    const [description, setDescription] = useState<string>("");
    const processActivities: any = ["Início", "Fim", "Atividade", "Exclusivo", "Paralelo", "Inclusivo"];

    const [processNameInvalid, setProcessNameInvalid] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);

    const classes = useStyles();

    const employeesOptions = employees.map(employee => {
        return employee.lastname ? employee.firstname + " " + employee.lastname : employee.firstname;
    })

    const openHelper = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.currentTarget.id === "helper-destino-button") {
            setAnchorElDestino(event.currentTarget);
            return;
        }

        if (event.currentTarget.id === "helper-gateway-button") {
            setAnchorElGateway(event.currentTarget);
            return;
        }

        setAnchorElPermissoes(event.currentTarget);
    }

    const openDestino = Boolean(anchorElDestino);
    const openPermissoes = Boolean(anchorElPermissoes);
    const openGateway = Boolean(anchorElGateway);

    useEffect(() => {
        const fetch = async() => {
            let resp = await api.get("/bpm/form/displayAll");
            setFormList(resp.data);
            // console.log(resp.data)
            
            let users = await api.get("/users");
            setEmployees(users.data);
            // console.log(employees)
            
            if (Number(processId)) {
                let data = await api.get(`/bpm/process/display/${processId}`);
                let d = data.data;
                // console.log(d);
                setProcessName(d.name);
                let responsible = d.responsible ? JSON.parse(d.responsible) : [];
                setSupervisores(responsible);
                setForm(String(d.FormBpmId));
                setDescription(d.description);
                let tData = d.data ? JSON.parse(d.data) : [];
                let tForm = d.oldForm ? JSON.parse(d.oldForm) : [];
                setFormPermissisons(tForm.groups);
                setOldForm(tForm);
                setTableData(tData);
                let supervisors = d.supervisors ? JSON.parse(d.supervisors) : [];
                setSupervisores(supervisors);
            }
        }
        fetch().then();
    }, []);

    useEffect(() => {
        const fetchFormPermissions = async () => {
            try {
                const resp = await api.get(`/bpm/form/display/${form}`);
                setFormPermissisons(resp.data.groups);
                setOldForm(resp.data);
            } catch (error) {
                console.error('Error fetching form permissions:', error);
            }
        };

        if (form && !Number(processId)) {
            fetchFormPermissions();
        }
    }, [form]);
    
    // Vai reordenar os dados sempre que tableData for alterado
    useEffect(() => {
        const sorted = sortTableData(tableData);
        setSortedData(sorted);
        // setIsButtonDisabled(isAtividadeInArray(atividade) || !atividade);
        const sorted2 = sortTableData(tableData);
        setSortedData(sorted2);
    }, [tableData]);

    const updateForm = () => {
        const updateForm = async () => {
            try {
                const resp = await api.get(`/bpm/form/display/${form}`);
                setFormPermissisons(resp.data.groups);
                setOldForm(resp.data);
            } catch (error) {
                console.error('Error fetching form permissions:', error);
            }
        };

        if(form){
            updateForm();
            setSuccessModal(true);
            setSuccessModalText("Formulário atualizado com sucesso! É necessário atualizar as permissões do formulário nas atividades!");
        }
    }

    //Abre o modal para adicionar ou editar
    const handleOpenModal = () => {
        setEditingRowIndex(null);
        setShowModal(true);
        console.log(form)
    };

    const handleOpenGatwayModal = () => {
        setEditingRowIndex(null);
        setGatwayModal(true);
    };

    // Fecha o modal
    const handleCloseModal = () => {
        setShowModal(false);
        setActivityTab('general');
        setTipo('');
        setAtividade('');
        setDescricaoAtividade('');
        setResponsaveis([]);
        setDestino([]);
        setPrazo(0);
        setPermissions({});
        setAprovacaoHasSupervisor(false);
    };

    const handleCloseGatwayModal = () =>{
        setGatwayModal(false)
        setTipo('');
        setAtividade('');
        setResponsaveis([]);
        setDestino([]);
        setPrazo(0);
    }

    // Abre o modal para editar a linha clicada
    const handleEdit = (index: number) => {
        const label = sortedData[index];
        setSelectedActivity(label.atividade || "");
        setTipo(label.tipo);
        setAtividade(label.atividade || '');
        setResponsaveis(label.responsaveis || []);
        setDestino(label.destino || []);
        setPrazo(label.prazo || 0);
    
        // Definir permissões no estado
        const newPermissions: { [key: string]: string } = {};
        if (label.permissions) {
            for (const groupId in label.permissions) {
                if (label.permissions.hasOwnProperty(groupId)) {
                    newPermissions[groupId] = label.permissions[groupId];
                }
            }
        }
        setPermissions(newPermissions);
    
        setEditingRowIndex(index);
        setShowModal(true);
    };
    

    const handleGatwayEdit = (index: number) => {
        const label = sortedData[index];
        setTipo(label.tipo);
        setAtividade(label.atividade || '');
        setDestino(label.destino || []);
        setEditingRowIndex(index);
        setGatwayModal(true);
        setSelectedActivity(label.atividade || "");
    };

    const labelSizes = (type: string) => {
        let width = 0;
        let height = 0;

        switch (type) {
            case "Início":
            case "Fim":
                width = 36;
                height = 36;
                break;
            case "Exclusivo":
            case "Paralelo":
            case "Inclusivo":
                width = 50;
                height = 50;
                break;
            case "Atividade":
                width = 100;
                height = 80;
                break;
            default:
                width = 50;
                height = 50;
        }
        
        return {width, height};
    }

    // Adiciona ou edita o rótulo
    const handleAddOrEditCustomLabel = (tipo_id: number) => {
        const newLabel: TableRowData = {
            id: uuidv4(),
            tipo,
            atividade,
            descricaoAtividade,
            responsaveis,
            destino,
            prazo,
            permissions,
            tipo_id: tipo_id,
            width: labelSizes(tipo).width,
            height: labelSizes(tipo).height,
            aprovacaoSupervisor: hasAprovacaoSupervisor,
        };

        if (editingRowIndex !== null) {
            // Atualiza a linha existente
            const updatedData = [...tableData];
            let existingLabel = updatedData[editingRowIndex];
            let existingData = {
                width: existingLabel.width,
                height: existingLabel.height,
                x: existingLabel.x,
                y: existingLabel.y,
            }
            let updatedNewLabel = {...newLabel, ...existingData};
            updatedData[editingRowIndex] = updatedNewLabel;
            const oldAtividade = tableData[editingRowIndex].atividade;
            setTableData(updatedData);

            // Remover o valor antigo do atributo destino de todos os elementos
            if(oldAtividade != newLabel.atividade){
                const updatedDataWithUpdatedDestinos = updatedData.map(item => {
                    if (item.destino) {
                    return {
                        ...item,
                        destino: item.destino.filter(dest => dest !== oldAtividade),
                    };
                    }
                    return item;
                });
                setTableData(updatedDataWithUpdatedDestinos);
            }
        } else {
            // Adiciona nova linha
            setTableData([newLabel, ...tableData]);
        }
        console.log(tableData)

        handleCloseModal();
        handleCloseGatwayModal();
    };

    // Função para excluir uma atividade
    const handleDelete = (index: number) => {
        const atividadeToDelete = sortedData[index].atividade;
        const updatedData = sortedData.filter((_, i) => i !== index);
    
        // Remover a atividade dos destinos existentes (caso exista)
        const updatedDataWithUpdatedDestinos = updatedData.map(item => {
            if (item.destino) {
                return {
                    ...item,
                    destino: item.destino.filter(destino => destino !== atividadeToDelete)
                };
            }
            return item;
        });
    
        setTableData(updatedDataWithUpdatedDestinos);
    };

    // Funções para mudanças nos campos
    const handleTipoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTipo(event.target.value as string);
    };

    const handlePermissionsChange = (event: React.ChangeEvent<{ value: unknown }>, groupId: string) => {
        setPermissions({
            ...permissions,
            [groupId]: event.target.value as string,
        });
    };

    //Campos Supervisores
    const handleSupervisorChange = (value: string, index: number) => {
        const employee = employees.find(emp => {
            return emp.lastname ? (emp.firstname + " " + emp.lastname) === value : emp.firstname === value;
        });
        if (employee) {
            const newSupervisors = [...supervisores];
            newSupervisors[index] = { ...newSupervisors[index], firstname: employee.firstname, lastname: employee.lastname ?? undefined, id: employee.id };
            setSupervisores(newSupervisors);
        }
    };


    const handleAddSupervisorsField = () => {
        const newSupervisor: Supervisor = { id: (supervisores.length + 1).toString(), firstname: '' };
        setSupervisores([...supervisores, newSupervisor]);
    };

    const handleDeleteSupervisorsField = (index: number) => {
        const newSupervisors = [...supervisores];
        newSupervisors.splice(index, 1);
        setSupervisores(newSupervisors);
    };

    //Campos Responsável
    const handleResponseChange = (value: string, index: number) => {
        const employee = employees.find(emp => {
            return emp.lastname ? (emp.firstname + " " + emp.lastname) === value : emp.firstname === value;
        });
        if (employee) {
            const newResponsible = [...responsaveis];
            newResponsible[index] = { ...newResponsible[index], firstname: employee.firstname, lastname: employee.lastname ?? undefined, id: employee.id };
            setResponsaveis(newResponsible);
        }
    };

    const handleAddResponseField = () => {
        // setResponsaveis([...responsaveis, '']);
        const newResponsible: Responsible = { id: (responsaveis.length + 1).toString(), firstname: '' };
        setResponsaveis([...responsaveis, newResponsible]);
    };

    const handleDeleteResponseField = (index: number) => {
        const newResponse = [...responsaveis];
        newResponse.splice(index, 1);
        setResponsaveis(newResponse);
    };

    //Campos Destino
    const handleDestinoChange = (value: string, index: number) => {
        const newDestino = [...destino];
        newDestino[index] = value;
        setDestino(newDestino);
    };

    const handleAddDestinoField = () => {
        setDestino([...destino, '']);
    };

    const handleDeleteDestinoField = (index: number) => {
        const newDestino = [...destino];
        newDestino.splice(index, 1);
        setDestino(newDestino);
    };

    const isTipoInArray = (tipo: string): boolean => {
        return tableData.some(item => item.tipo === tipo);
    };

    // const isAtividadeInArray = (atividade: string): boolean => {
    //     // setIsButtonDisabled(tableData.some(item => item.atividade === atividade));
    //     return tableData.some(item => item.atividade === atividade);
    // };
  
  function inputsVerify() {
        if (!processName) {
            setErrorModal(true);
            setErrorModalText("Preencha o nome do processo!");
            setProcessNameInvalid(true);
            setIsSubmit(false);
            return false;
        }

        if (form === "0") {
            setErrorModal(true);
            setErrorModalText("Selecione um formulário!");
            setFormInvalid(true);
            setIsSubmit(false);
            return false;
        }

        if (!isTipoInArray('Início') || !isTipoInArray('Fim')) {
            setErrorModal(true);
            setErrorModalText("Não é possível criar um processo sem início e fim!");
            setIsSubmit(false);
            return false;
        }

        return true;
  }
  
  const displaySelectableItems = () => {
        return (
            <>
                {
                    destino.map((item, index) => {
                        let count = tableData.length;
                        let data = [];
                        for (let i = 0; i < count; i++) {
                            if (processActivities.includes(tableData[i].tipo)) {
                                data.push(tableData[i]);
                            }
                        }
                        return (
                            <DestinationField
                                key={index}
                                value={item}
                                options={data.map(label => label.atividade).filter(Boolean) as string[]}
                                onChange={handleDestinoChange}
                                onDelete={handleDeleteDestinoField}
                                index={index}
                                selectedActivity={selectedActivity}
                            />
                        );
                    })
                }
                <Button disabled={tableData.length === 0} className="mr-3" variant="primary" onClick={handleAddDestinoField}>
                    + Adicionar Destino
                </Button>
            </>
        );
    }

    async function onSubmit() {
        setIsSubmit(true);

        if (!inputsVerify()) return;

        let processActivitiesArr = [];
        let tableDataCount = tableData.length;
        let procArr = processActivities;
        procArr.push("Participante");
        for (let i = 0; i < tableDataCount; i++) {
            if (processActivities.includes(tableData[i].tipo)) {
                processActivitiesArr.push(tableData[i]);
            }
        }
      
        try {
            let obj = {
                processId: processId,
                processName: processName,
                form: form,
                description: description,
                tableData: processActivitiesArr,
                responsible: supervisores,
                supervisores,
                oldForm: oldForm,
            }
            let data = JSON.stringify(obj);
    
            let resp = await api.post("/bpm/process/save", {data});

            setIsSubmit(false);
            setSuccessModalText(`Processo ${processId !== "0" ? "atualizado" : "criado"} com sucesso!`);
            setSuccessModal(true);
            setRedirect("/bpm/modelagem-de-processos");
        } catch (error) {
            console.log(error);
        }
        setIsSubmit(false);
    }

    const handleChangeForm = useCallback((form: FormsList | null | undefined) => {
        setForm(String(form?.id ?? ''));
    }, []);

    return (
        <>
            <ModalSuccess
                msgModal={successModalText}
                showModal={successModal}
                setShowModal={(setShowModal)}
                onClose={() => setSuccessModal(false)}
                redirect={redirect}
            />

            <Modal show={errorModal} onHide={() => setErrorModal(false)}>
                <Modal.Header>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{errorModalText}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setErrorModal(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <form className="card card-body pt-4">
                <Grid container spacing={3}>
                    <Grid item lg={6}>
                        <TextField
                            type="string"
                            label="Nome do Processo"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={processName}
                            onChange={(e) => {
                                setProcessName(e.target.value);
                                setProcessNameInvalid(e.target.value ? false : true);
                            }}
                            className={classes.error}
                            error={processNameInvalid}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <div className="h-100 d-flex justify-content-end align-items-end">
                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmit}
                                className="mb-2"
                                onClick={() => onSubmit()}
                            >
                                {isSubmit ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className="ml-2">
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span>
                                        Salvar
                                    </span>
                                </>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs" className="mb-5">
                    <Tab eventKey="details" title="Detalhes">
                        <Grid container spacing={3}>
                            <Grid item lg={12}>
                                {/* <ApiResourceSelect
                                    required
                                    label="Formulário"
                                    getOptionLabel={(option: FormsList) => option.name}
                                    value={form}
                                    onSelect={handleChangeForm}
                                    apiSearchHandler={(typedText) => BpmFormService.getFormsFiltered({ name: typedText })}
                                    onChangeTextField={(e) => setFormInvalid(e.target.value ? false : true)}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!form) return null;
                                        return loadedOptions.find((option) => option.id === Number(form))
                                    }}
                                    hasError={formInvalid}
                                    textFieldClass={classes.error}
                                /> */}

                                <TextField
                                    select
                                    label="Formulário"
                                    size="small"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={form}
                                    onChange={(e) => {
                                        setForm(e.target.value);
                                        setFormInvalid(e.target.value ? false : true);
                                    }}
                                    className={classes.error}
                                    error={formInvalid}
                                >
                                    {
                                        formsList.map((f: any) =>
                                            <MenuItem key={f.id} value={f.id}>
                                                {f.name}
                                            </MenuItem>
                                        )
                                    }
                                </TextField>
                                <Button className={processId !== "0" ? "" : "d-none"} variant="primary" onClick={updateForm}>
                                    Atualizar formulário
                                </Button>
                            </Grid>


                            <Grid item lg={12}>
                                <TextField
                                    label="Descrição"
                                    multiline
                                    rows="10"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 5000 }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Tab>
                    <Tab eventKey="activities" title="Atividades">
                        <Button variant="primary" onClick={handleOpenGatwayModal} style={{ marginRight: '10px' }}>
                            Novo Gateway
                        </Button>
                        <Button variant="primary" onClick={handleOpenModal}>
                            Nova Atividade
                        </Button>

                        <Modal show={gatwayModal} onHide={handleCloseGatwayModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>{editingRowIndex !== null ? "Editar Gateway" : "Adicionar Novo Item"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Tabs activeKey={activityTab} onSelect={(tab: string) => setActivityTab(tab)} id="newproduct-form-tabs" className="mb-5">
                                    <Tab eventKey="general" title="Geral">
                                        <TextField
                                            type="string"
                                            label="Descrição"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={atividade}
                                            onChange={(e) => setAtividade(e.target.value)}
                                            // error={isAtividadeInArray(atividade)}
                                            // helperText={isAtividadeInArray(atividade) ? "Atividade já existe." : ""}
                                        />
                                        {/* <FormControl variant="outlined" size="small" margin="normal">
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                value={tipo}
                                                onChange={handleTipoChange}
                                                label="Tipo"
                                            >
                                                <MenuItem value="Exclusivo">Exclusivo</MenuItem>
                                                <MenuItem value="Paralelo">Paralelo</MenuItem>
                                                <MenuItem value="Inclusivo">Inclusivo</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </Tab>
                                    <Tab eventKey="destiny" title="Destino">
                                        { displaySelectableItems() }
                                        <Button id="helper-gateway-button" size="sm" variant="info" onClick={openHelper}>
                                            <i className="fas fa-info-circle p-0"></i>
                                        </Button>
                                        <Popover
                                            id="helper-gateway"
                                            open={openGateway}
                                            anchorEl={anchorElGateway}
                                            onClose={() => setAnchorElGateway(null)}
                                            anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                            }}>
                                                <Typography className="p-2">
                                                    É necessário ter ao menos uma atividade para definir o destino do gateway
                                                </Typography>
                                        </Popover>
                                    </Tab>
                                </Tabs>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseGatwayModal}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={() => handleAddOrEditCustomLabel(2)} disabled={!atividade}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showModal} onHide={handleCloseModal} dialogClassName='modal-lg'>
                            <Modal.Header closeButton>
                                <Modal.Title>{editingRowIndex !== null ? "Editar Atividade" : "Adicionar Novo Item"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Tabs activeKey={activityTab} onSelect={(tab: string) => setActivityTab(tab)} id="newproduct-form-tabs" className="mb-5">
                                    <Tab eventKey="general" title="Geral">
                                        <FormControl variant="outlined" size="small" margin="normal">
                                            <InputLabel>Tipo de atividade</InputLabel>
                                            <Select
                                                value={tipo}
                                                onChange={handleTipoChange}
                                                label="Tipo de atividade"
                                            >
                                                <MenuItem value="Início" disabled={isTipoInArray('Início')}>Início</MenuItem>
                                                <MenuItem value="Atividade">Atividade</MenuItem>
                                                <MenuItem value="Fim" disabled={isTipoInArray('Fim')}>Fim</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            type="string"
                                            label="Nome da atividade"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={atividade}
                                            onChange={(e) => setAtividade(e.target.value)}
                                            // error={isAtividadeInArray(atividade)}
                                            // helperText={isAtividadeInArray(atividade) ? "Atividade já existe." : ""}
                                        />
                                        <TextField
                                            multiline
                                            rows={3}
                                            type="string"
                                            label="Descrição da atividade"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={descricaoAtividade}
                                            onChange={(e) => setDescricaoAtividade(e.target.value)}
                                        />
                                        <FormControlLabel
                                                control={<Checkbox checked={hasAprovacaoSupervisor}
                                                onChange={(e) => setAprovacaoHasSupervisor(e.target.checked)}
                                                color="primary"/>}
                                            label="Precisa de aprovação do supervisor?" />
                                    </Tab>
                                    <Tab eventKey="term" title="Prazo">
                                        <TextField
                                            type="number"
                                            label="Dias Corridos"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            value={prazo}
                                            InputProps={{
                                                inputProps: { min: 0 }
                                            }}
                                            onChange={(e) => setPrazo(Number(e.target.value) < 0 ? 0 : Number(e.target.value))}
                                        />
                                    </Tab>
                                    <Tab eventKey="responsible" title="Responsável">
                                        <DestinationField
                                            value={responsaveis.length ? `${responsaveis[0].firstname} ${responsaveis[0]?.lastname}` : '' }
                                            options={employeesOptions.filter(Boolean) as string[]}
                                            onChange={handleResponseChange}
                                            onDelete={handleDeleteResponseField}
                                            index={0}
                                            title="Responsável"
                                        />
                                        {/* {responsaveis.map((item, index) => (
                                            <DestinationField
                                                key={item.id}
                                                value={item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname }
                                                options={employeesOptions.filter(Boolean) as string[]}
                                                onChange={handleResponseChange}
                                                onDelete={handleDeleteResponseField}
                                                index={index}
                                                title="Responsável"
                                            />
                                        ))} */}
                                        {/* <Button variant="primary" onClick={handleAddResponseField}>
                                            + Adicionar Responsável
                                        </Button> */}
                                    </Tab>
                                    <Tab eventKey="destiny" title="Destino">
                                        { displaySelectableItems() }
                                        <Button id="helper-destino-button" size="sm" variant="info" onClick={openHelper}>
                                            <i className="fas fa-info-circle p-0"></i>
                                        </Button>
                                        <Popover
                                            id="helper-destino"
                                            open={openDestino}
                                            anchorEl={anchorElDestino}
                                            onClose={() => setAnchorElDestino(null)}
                                            anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                            }}>
                                                <Typography className="p-2">
                                                    É necessário ter ao menos uma atividade para definir o destino da atividade
                                                </Typography>
                                        </Popover>
                                    </Tab>
                                    <Tab eventKey="permissions" title="Permissões">
                                        <Button id="helper-permissoes-button" size="sm" variant="info" onClick={openHelper}>
                                            <i className="fas fa-info-circle p-0"></i>
                                        </Button>
                                        <Popover
                                            id="helper-permissoes"
                                            open={openPermissoes}
                                            anchorEl={anchorElPermissoes}
                                            onClose={() => setAnchorElPermissoes(null)}
                                            anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                            }}>
                                                <Typography className="p-2">
                                                    É necessário selecionar um formulário para exibir as permissões
                                                </Typography>
                                        </Popover>
                                    {
                                        formPermissions.map((group: any) => (
                                            <FormControl key={group.id} variant="outlined" size="small" margin="normal">
                                                <InputLabel>{group.name}</InputLabel>
                                                <Select
                                                    value={permissions[group.id] || ''}
                                                    onChange={(event) => handlePermissionsChange(event, group.id)}
                                                    label={group.name}
                                                >
                                                    <MenuItem value="Não Exibir">Não Exibir</MenuItem>
                                                    <MenuItem value="Visualizar">Visualizar</MenuItem>
                                                    <MenuItem value="Editar">Editar</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ))
                                    }
                                    </Tab>
                                </Tabs>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={() => handleAddOrEditCustomLabel(1)} disabled={!tipo}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <td>Tipo de Atividade</td>
                                    <td>Atividade</td>
                                    <td>Responsável</td>
                                    <td>Aprovação supervisor?</td>
                                    <td>Destino</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((label: any, index: number) => {
                                    if (processActivities.includes(label.tipo ? label.tipo : "")) {
                                        return (
                                            <CustomLabel
                                                key={index}
                                                id={label.id}
                                                tipo={label.tipo}
                                                atividade={label.atividade}
                                                responsaveis={label.responsaveis}
                                                aprovacaoSupervisor={label.aprovacaoSupervisor}
                                                destino={label.destino}
                                                alinhamento={label.alinhamento}
                                                onEdit={label.tipo_id === 1 ? () => handleEdit(index): () => handleGatwayEdit(index)}
                                                onDelete={() => handleDelete(index)}
                                            />
                                        );
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Tab>

                    <Tab eventKey="diagram" title="Diagrama">
                        <BpmnViewer
                            tableRows={sortedData}
                            setTableRows={setTableData}
                            processActivities={processActivities}
                        />
                    </Tab>

                    <Tab eventKey="supervisors" title="Supervisores">
                        {supervisores.map((item, index) => (
                            <DestinationField
                                key={item.id}
                                value={item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname}
                                options={employeesOptions.filter(Boolean) as string[]}
                                onChange={handleSupervisorChange}
                                onDelete={handleDeleteSupervisorsField}
                                index={index}
                                title="Supervisor"
                            />
                        ))}
                        <Button variant="primary" onClick={handleAddSupervisorsField}>
                            + Adicionar Supervisor
                        </Button>
                    </Tab>
                </Tabs>
            </form>
        </>
    );
}
