import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../services/Api";
import CompanyService from "../services/CompanyService";

type useCompanyParams = {
    only?: number,
    appendSubscriptionPlan?: boolean,
    mainCompanyId?: number,
};

export default function useCompany({ only, appendSubscriptionPlan, mainCompanyId }: useCompanyParams = {}) {
    const { user } = useSelector((state: any) => state.auth);
    
    const [companies, setCompanies] = useState<any[]>([]);
    const [mainAndBranches, setMainAndBranches] = useState<any[]>([]);
    const [companiesFromProfile, setCompaniesFromProfile] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            let companies;
            let mainAndBranches;
            if(only) {
                const foundCompany = await CompanyService.getCompanyById(only, { withSubscriptionPlan: appendSubscriptionPlan })
                companies = [foundCompany];
            } else if(mainCompanyId) {
                companies = await CompanyService.getCompanyBranches(mainCompanyId, { withSubscriptionPlan: appendSubscriptionPlan });
                mainAndBranches = await CompanyService.getMainCompanyAndCompanyBranches(mainCompanyId, { withSubscriptionPlan: appendSubscriptionPlan });
            } else {
                companies = await CompanyService.getCompanies({ withSubscriptionPlan: appendSubscriptionPlan });
            }
            
            setCompanies(companies);
            if(user) {
                setCompaniesFromProfile(companies.filter((company: any) => company.profileId == user.id));
            }

            if (mainAndBranches) {
                mainAndBranches = [
                    {
                        id: 0,
                        name: 'Todas'
                    },
                    ...mainAndBranches
                ]

                setMainAndBranches(mainAndBranches);
            }
        })()
    }, [only, appendSubscriptionPlan, mainCompanyId, user]);

    const getStatusValue = useCallback((company) => {
        return CompanyService.getStatusValue(company);
    }, []);


    const getStatusText = useCallback((company) => {
        return CompanyService.getStatusText(company);
    }, []);

    const companyCanHaveBranches = (company: any) => {
        return CompanyService.companyCanHaveBranches(company);
    }

    return {
        companies,
        mainAndBranches,
        companiesFromProfile,
        setCompanies,
        getStatusText,
        getStatusValue,
        companyCanHaveBranches,
    };
}