import { durationType, SubscriptionPlan } from "../types/SubscriptionPlan";
import { bypassAuthHeader } from "../utils/bypassAuthHeader";
import api from "./Api";

type Params = {
    bypassAuth?: boolean;
}

class SubscriptionPlanService {
    async getSubscriptionPlanById(id: string | number, { bypassAuth }: Params = {}) {
        const subscriptionPlanResponse = await api.get<SubscriptionPlan>(`/subscriptionPlans/${id || '0'}`, {
            headers: bypassAuth ? bypassAuthHeader : undefined,
        });

        return subscriptionPlanResponse.data;
    }

    addPlanDurationToTodayDate(subscriptionPlan: SubscriptionPlan) {
        const todayDateObj = new Date();

        if(subscriptionPlan.duration === null || subscriptionPlan.durationType === durationType.NO_DURATION) {
            return null;
        }

        switch (subscriptionPlan.durationType) {
            case durationType.DAYS:
                todayDateObj.setDate(todayDateObj.getDate() + subscriptionPlan.duration);
                break;

            case durationType.MONTHS:
                todayDateObj.setMonth(todayDateObj.getMonth() + subscriptionPlan.duration);
                break;

            case durationType.TWO_MONTHS:
                todayDateObj.setMonth(todayDateObj.getMonth() + (subscriptionPlan.duration * 2));
                break;

            case durationType.THREE_MONTHS:
                todayDateObj.setMonth(todayDateObj.getMonth() + (subscriptionPlan.duration * 3));
                break;

            case durationType.FOUR_MONTHS:
                todayDateObj.setMonth(todayDateObj.getMonth() + (subscriptionPlan.duration * 4));
                break;

            case durationType.SEMESTERS:
                todayDateObj.setMonth(todayDateObj.getMonth() + (subscriptionPlan.duration * 6));
                break;

            case durationType.YEARS:
                todayDateObj.setMonth(todayDateObj.getMonth() + (subscriptionPlan.duration * 12));
                break;
        }

        return todayDateObj;
    }

    async getSubscriptionPlanFiltered(filters: any) {
        const subscriptionPlanResponse = await api.get<SubscriptionPlan[]>('/subscriptionPlans', { params: { filters } });

        return subscriptionPlanResponse.data;
    }
}

export default new SubscriptionPlanService();