import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSubheader } from '../../_metronic/layout';
import { EditSubscriptionPlan } from '../pages/SubscriptionPlan/Edit-SubscriptionPlan';
import { ListSubscriptionPlans } from '../pages/SubscriptionPlan/List-SubscriptionPlans';
import { NewSubscriptionPlan } from '../pages/SubscriptionPlan/New-SubscriptionPlan';
import { PromptPaymentSubscriptionPlan } from '../pages/SubscriptionPlan/Payment/PromptPayment-SubscriptionPlan';

export function SubscriptionPlanCompany() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Plano");
    
    return (
        <Switch>
            <Route path="/plano/pagamento" component={PromptPaymentSubscriptionPlan}/>
        </Switch>
    );
}