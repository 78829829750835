import { Collapse, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export type Search = {
    query: string;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
    setCollapseAdvancedSearch?: React.Dispatch<React.SetStateAction<boolean>>;
    onClickSearch: () => void;
}

export function Search({
    query,
    setQuery,
    setCollapseAdvancedSearch,
    onClickSearch
} :Search) {
    function handleChangeSearchField(value: string) {
        setQuery(value);
    }

    // useEffect(() => {
    //     onClickSearch();
    // }, [query])

    return (
        <div className="d-flex align-items-center">
            <TextField
                size="small"
                label="Busca"
                margin="normal"
                variant="outlined"
                value={query}
                onChange={(e) => handleChangeSearchField(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                aria-label="Search"
                                onClick={onClickSearch}
                            >
                                <i className="flaticon-search"></i>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {setCollapseAdvancedSearch && (
                <Button
                    title="Busca avançada"
                    className="ml-3"
                    style={{marginTop: "8px"}}
                    onClick={() => setCollapseAdvancedSearch((prevState) => !prevState)}
                >
                    <FilterList />
                </Button>
            )}
        </div>
    )
}