import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  Line
} from "@react-pdf/renderer";
import { useHistory, useParams } from "react-router";
import api from "../../../services/Api";
import photo from "./test.jpg";
import { formatCurrency, formatIntegerToMoney } from "../../../utils/formatCurrency";
import { formatCpfCnpj } from "../../../utils/formatCpfCnpj";
import Table from "../../../components/Pdf/Table";
import TableRow from "../../../components/Pdf/TableRow";
import TableCell from "../../../components/Pdf/TableCell";
import { usePdfStyles } from "../../../hooks/pdfStyles";
import { QuotationProduct, QuotationSupplier } from "../../../types/Quotation";
import quotationSupplierService from "../../../services/QuotationSupplierService";
import quotationService from "../../../services/QuotationService";

const axiosDefaults = {
  headers: {
    'x-bypass-auth': '0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5'
  },
};

type PdfQuotationSupplierParams = {
  quotationSupplierId: number,
}

// Create Document Component
export default function PdfQuotationSupplier({ quotationSupplierId }: PdfQuotationSupplierParams) {
  const styles = usePdfStyles();

  const [products, setProducts] = useState<QuotationProduct[]>([]);

  // COMPANY DATA
  const [nameCompany, setNameCompany] = useState("");
  const [typeCompany, setTypeCompany] = useState("");
  const [cnpjCompany, setCnpjCompany] = useState("");
  const [cpfCompany, setCpfCompany] = useState("");
  const [addressCompany, setAddressCompany] = useState("");
  const [numberCompany, setNumberCompany] = useState("");
  const [neighborhoodCompany, setNeighborhoodCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [ufCompany, setUfCompany] = useState("");
  const [zipCodeCompany, setZipCodeCompany] = useState("");
  const [phoneCompany, setPhoneCompany] = useState("");
  const [emailCompany, setEmailCompany] = useState("");
  const [siteCompany, setSiteCompany] = useState("");
  const [photoCompany, setPhotoCompany] = useState("");
  // =========== COMPANY =====



  // SUPPLIER DATA 
  const [name, setName] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [rg, setRg] = useState("");
  const [tell, setTell] = useState("");
  const [email, setEmail] = useState("");
  const [cell, setCell] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [typePeople, setTypePeople] = useState("");
  // ========= SUPPLIER ===  


  // CONTACT DATA
  const [nameContact, setNameContact] = useState("");
  const [tellContact, setTellContact] = useState("");
  const [webSite, setWebSite] = useState("");
  const [cellContact, setCellContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  // ========= CONTACT ===

  // QUOTATION DATA
  const [quotationId, setQuotationId] = useState(0);
  // ========= QUOTATION ===

  // QUOTATION SUPPLIER DATA
  //
  // ========= QUOTATION SUPPLIER ===


  // DETAILS
  const [amountTotal, setAmountTotal] = useState(0);
  const [productsTotal, setProductsTotal] = useState(0);
  const [freightTotal, setFreightTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [responsible, setResponsible] = useState("");
  //  ======== DETAILS ===

  function formatDate(date: string) {
    var dateSplit = date.split("-");
    return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
  }

  async function getCompany(companyId: number) {
    const company = await api.get(`/companies/${companyId}`, axiosDefaults);

    if (company) {
      setNameCompany(company.data.name);
      setTypeCompany(company.data.typePeople);
      setCnpjCompany(company.data.cnpj);
      setCpfCompany(company.data.cpf);
      setAddressCompany(company.data.address);
      setNumberCompany(company.data.number);
      setNeighborhoodCompany(company.data.district);
      setCityCompany(company.data.city);
      setUfCompany(company.data.state);
      setZipCodeCompany(company.data.zipCode);
      setPhoneCompany(company.data.phone);
      setEmailCompany(company.data.email);
      setSiteCompany(company.data.website);
      setPhotoCompany(company.data.logo);
    }
  }

  async function getCustomer(customerId: number) {
    const customer = await api.get(`/customer/${customerId}`, axiosDefaults);

    setTypePeople(customer.data.typePeople);
    setName(customer.data.name);
    setCorporateName(customer.data.corporateName);
    setFantasyName(customer.data.fantasyName);
    setStateRegistration(customer.data.stateRegistration);
    setCpf(customer.data.cpf);
    setCnpj(customer.data.cnpj);
    setRg(customer.data.rg);
    setTell(customer.data.phone);
    setEmail(customer.data.email);
    setCell(customer.data.cell);

    if (customer.data.typePeople == "physical") {
      getAddress(customer.data.id).then((address) => {
        var hasDefaultAddress = false;

        for (var index = 0; index < address.data.length; index++) {
          if (address.data[index].defaultAddress == "y") {
            setAddress(address.data[index].publicPlace);
            setNumber(address.data[index].number);
            setNeighborhood(address.data[index].district);
            setComplement(address.data[index].complement);
            setZipCode(address.data[index].zipcode);
            setCity(address.data[index].city);
            setUf(address.data[index].state);

            hasDefaultAddress = true;
          }
        }

        if (!hasDefaultAddress && address.data.length) {
          setAddress(address.data[0].publicPlace);
          setNumber(address.data[0].number);
          setNeighborhood(address.data[0].district);
          setComplement(address.data[0].complement);
          setZipCode(address.data[0].zipcode);
          setCity(address.data[0].city);
          setUf(address.data[0].state);
        }
      });

      getContact(customer.data.id).then((contact) => {
        var hasDefaultContact = false;

        for (var index = 0; index < contact.data.length; index++) {
          if (contact.data[index].isLegalResponsible == "y") {
            setNameContact(contact.data[index].name);
            setTellContact(contact.data[index].phone);
            setWebSite(contact.data[index].website)
            setCellContact(contact.data[index].cell);
            setEmailContact(contact.data[index].email);

            hasDefaultContact = true;
          }
        }

        if (!hasDefaultContact && contact.data.length) {
          setNameContact(contact.data[0].name);
          setTellContact(contact.data[0].phone);
          setWebSite(contact.data[0].website)
          setCellContact(contact.data[0].cell);
          setEmailContact(contact.data[0].email);
        }
      });
    }
  }

  async function getAddress(customerId: number) {
    return await api.get(`/address/user/${customerId}`, axiosDefaults);
  }

  async function getContact(customerId: number) {
    return await api.get(`/contact/user/${customerId}`, axiosDefaults);
  }

  // useEffect(() => {
  //   api.defaults.headers['x-bypass-auth']  = `0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5`;
  // }, []);

  useEffect(() => {
    async function loadData() {
      quotationSupplierService.setBypassAuth(true);
      quotationService.setBypassAuth(true);

      const quotationSupplier = await quotationSupplierService.getQuotationSupplierById(quotationSupplierId);
      if (!quotationSupplier) return;
      const allProducts: QuotationProduct[] = quotationSupplier.products ? JSON.parse(quotationSupplier.products) : [];

      setProducts(allProducts);
      setAmountTotal(allProducts.reduce((acc, product) => acc + Number(product.amount), 0));
      setProductsTotal(quotationSupplier.productsTotal ?? 0);
      setFreightTotal(quotationSupplier.freightTotal ?? 0);
      setExpensesTotal(quotationSupplier.expensesTotal ?? 0);
      setTotalValue(quotationSupplier.totalValue ?? 0);
      setPaymentMethod(quotationSupplier.paymentMethod ?? '');
      setPaymentDetails(quotationSupplier.paymentDetails ?? '');

      const quotation = await quotationService.getQuotationById(quotationSupplier.quotationId);
      if (!quotation) return;
      setQuotationId(quotation.id);

      getCustomer(quotationSupplier.supplier);
      getCompany(quotation.companyId);

      quotationSupplierService.setBypassAuth(false);
      quotationService.setBypassAuth(false);
    }

    loadData();
  }, [quotationSupplierId]);

  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4">
        <View style={styles.header}>
          <View style={styles.photo}>
            <Image source={{ uri: `${process.env.REACT_APP_API_URL}/${photoCompany}`, method: "GET", headers: {}, body: "" }} style={styles.customerPhoto} />
          </View>
          <View style={styles.infoHeader}>
            <View style={styles.titleHeader}>
              <Text>Cotação {quotationId}</Text>
            </View>
            <Text style={styles.titleInfo}>{nameCompany} - {typeCompany == "legal" ? formatCpfCnpj('CNPJ', cnpjCompany) : formatCpfCnpj('CPF', cpfCompany)}</Text>
            <Text style={styles.titleInfo}>{addressCompany}, {numberCompany} {neighborhoodCompany} - {cityCompany} - {ufCompany} CEP: {zipCodeCompany}</Text>
            <Text style={styles.titleInfo}>Fone: {phoneCompany} E-mail: {emailCompany} Site: {siteCompany}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.titleSection}>DADOS DO FORNECEDOR</Text>

          <View style={styles.row}>
            <View style={styles.left}>
              {
                typePeople == "physical"
                  ?
                  <>
                    <Text style={styles.titleInfo}>NOME: {name}</Text>
                    <Text style={styles.titleInfo}>RG: {rg}</Text>
                  </>
                  :
                  <>
                    <Text style={styles.titleInfo}>RAZÃO SOCIAL: {corporateName}</Text>
                    <Text style={styles.titleInfo}>CNPJ: {formatCpfCnpj('CNPJ', cnpj)}</Text>
                  </>
              }
              <Text style={styles.titleInfo}>TELEFONE: {tell}</Text>
              <Text style={styles.titleInfo}>CELULAR: {cell}</Text>
              <Text style={styles.titleInfo}>NÚMERO: {number}</Text>
              <Text style={styles.titleInfo}>COMPLEMENTO: {complement}</Text>
              <Text style={styles.titleInfo}>CIDADE: {city}</Text>
            </View>

            <View style={styles.right}>
              {
                typePeople == "physical"
                  ?
                  <>
                    <Text style={styles.titleInfo}>CPF: {formatCpfCnpj('CPF', cpf)}</Text>
                    <Text style={styles.titleInfo}>CONTATO: {nameContact}</Text>
                  </>
                  :
                  <>
                    <Text style={styles.titleInfo}>NOME FANTASIA: {fantasyName}</Text>
                    <Text style={styles.titleInfo}>INSC. ESTADUAL: {stateRegistration}</Text>
                  </>
              }
              <Text style={styles.titleInfo}>EMAIL: {email}</Text>
              <Text style={styles.titleInfo}>ENDEREÇO: {address}</Text>
              <Text style={styles.titleInfo}>BAIRRO: {neighborhood}</Text>
              <Text style={styles.titleInfo}>CEP: {zipCode}</Text>
              <Text style={styles.titleInfo}>UF: {uf}</Text>
            </View>
          </View>
        </View>

        {
          typePeople == "physical"
            ?
            <>
              <View style={styles.section}>
                <Text style={styles.titleSection}>DADOS DE CONTATO</Text>

                <View style={styles.row}>
                  <View style={styles.left}>
                    <Text style={styles.titleInfo}>NOME: {nameContact}</Text>
                    <Text style={styles.titleInfo}>WEBSITE: {webSite}</Text>
                    <Text style={styles.titleInfo}>EMAIL: {emailContact}</Text>
                  </View>

                  <View style={styles.right}>
                    <Text style={styles.titleInfo}>TELEFONE: {tellContact}</Text>
                    <Text style={styles.titleInfo}>CELULAR: {cellContact}</Text>
                  </View>
                </View>
              </View>
            </>
            : <></>
        }

        <View style={styles.section}>
          <Text style={styles.titleSection}>DADOS DA COTAÇÃO</Text>

          <Table>
            <TableRow>
              <TableCell width={styles.cell1.width}></TableCell>
              <TableCell width={styles.cell12.width}>DESCRIÇÃO</TableCell>
              <TableCell width={styles.cell3.width}>QTDE.</TableCell>
              <TableCell width={styles.cell7.width}>VALOR UNIT.</TableCell>
              <TableCell width={styles.cell7.width}>VL FRETE</TableCell>
              <TableCell width={styles.cell8.width}>VALOR TOTAL</TableCell>
              <TableCell width={styles.cell11.width}>OBS</TableCell>
            </TableRow>
          </Table>

          <Table>
            {
              products.map((product, index: number) => {
                return (
                  <View key={product.id} style={styles.rowProduct}>
                    <Text style={styles.cell1}>{index + 1}.</Text>
                    <Text style={styles.cell12}>{product.INSTANCE.name}</Text>
                    <Text style={styles.cell3}>{product.amount}</Text>
                    <Text style={styles.cell7}>{formatCurrency(product.unitaryValue ?? 0)}</Text>
                    <Text style={styles.cell7}>{formatCurrency(product.freightValue ?? 0)}</Text>
                    <Text style={styles.cell8}>{formatCurrency(product.total ?? 0)}</Text>
                    <Text style={styles.cell11}>{product.comments ?? ''}</Text>
                  </View>
                )
              })
            }
          </Table>
        </View>

        <View style={styles.section}>
          <Text style={styles.titleSection}></Text>

          <View style={styles.rowPayment}>
            <View style={styles.left}>
              <View style={styles.rowPayment}>
                <View style={styles.leftPayment}>
                  <Text style={styles.titleInfo}>VALOR TOTAL DOS PRODUTOS</Text>
                  <Text style={styles.titleInfo}>VALOR TOTAL DO FRETE</Text>
                  <Text style={styles.titleInfo}>FORMA DE PAGAMENTO</Text>
                </View>
                <View style={styles.rightPayment}>
                  <Text style={styles.titleInfo}>{formatCurrency(productsTotal)}</Text>
                  <Text style={styles.titleInfo}>{formatCurrency(freightTotal)}</Text>
                  <Text style={styles.titleInfo}>{paymentMethod}</Text>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.rowPayment}>
                <View style={styles.leftPayment}>
                  <Text style={styles.titleInfo}>NÚMERO TOTAL DE ITENS</Text>
                  <Text style={styles.titleInfo}>VALOR TOTAL DO PEDIDO</Text>
                  <Text style={styles.titleInfo}>DETALHES PAGAMENTO</Text>
                </View>
                <View style={styles.rightPayment}>
                  <Text style={styles.titleInfo}>{amountTotal} produto(s)</Text>
                  <Text style={styles.titleInfo}>{formatCurrency(totalValue)}</Text>
                  <Text style={styles.titleInfo}>{paymentDetails}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

