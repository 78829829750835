import { useState } from "react";
import { EmailToCustomer } from "../components/ModalSendEmail";
import { WhatsappToCustomer } from "../components/ModalSendWhatsapp";
import { EmailsHistory } from "../types/EmailsHistory";
import { WhatsappHistory } from "../types/WhatsappHistory";

export default function useSendEmailAndWhatsapp() {
    //Envio de Email/Whatspp
    const [showModalEmail, setShowModalEmail] = useState(false);
    const [infoEmail, setInfoEmail] = useState<EmailToCustomer>({
        refId: '',
        refName: '',
        toName: '',
        customerName: '',
        customerId: '',
        email: '',
        contactName: '',
        message: '',
        emailSubject: '',
    });
    const [emailsHistory, setEmailsHistory] = useState<EmailsHistory[]>([]);
    const [showModalWhatsapp, setShowModalWhatsapp] = useState(false);
    const [infoWhatsapp, setInfoWhatsapp] = useState<WhatsappToCustomer>({
        refId: '',
        refName: '',
        customerName: '',
        customerId: '',
        number: '',
        message: '',
    });
    const [whatsappHistory, setWhatsappHistory] = useState<WhatsappHistory[]>([]);

    return {
        showModalEmail, setShowModalEmail,
        infoEmail, setInfoEmail,
        emailsHistory, setEmailsHistory,
        showModalWhatsapp, setShowModalWhatsapp,
        infoWhatsapp, setInfoWhatsapp,
        whatsappHistory, setWhatsappHistory,
    };
}