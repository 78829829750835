import React from 'react';
import { useSubheader } from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListCarriers } from '../pages/List-Carriers';
import { NewCarriers } from '../pages/New-Carriers';
import { EditCarriers } from '../pages/Edit-Carriers';

export function Carriers() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("Transportadoras");

    return  (
        <Switch>
            <Route path="/transportadoras/adicionar" component={NewCarriers}/>
            <Route path="/transportadoras/:id" component={EditCarriers}/>
            <Route path="/transportadoras" component={ListCarriers}/>
        </Switch>
    );
}