import React, { useCallback, useEffect, useState } from 'react';
import { IndicationsStatus } from '../../../types/Indications';
import { formatCpfCnpj } from '../../../utils/formatCpfCnpj';

export function IndicationsCanceled({
    data,
    totalCount,
    loadData,
    triggerLoad,
    setTriggerLoad,
    onClickIndication,
}: IndicationsStatus) {
    const [pageChanged, setPageChanged] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const isBackEndPagination = true;
    const [sortDirection, setSortDirection] = useState<'ASC'|'DESC'>('DESC');
    const [sortReference, setSortReference] = useState('id');
    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleLoadData = useCallback(async () => {
        if((pageChanged && loadData) || (loadData && triggerLoad && setTriggerLoad)) {
            setIsLoadingData(true);
            await loadData({rowsPerPage, currentPage: pages, sortDirection, sortReference});
            setIsLoadingData(false);

            if(pageChanged) {
                setPageChanged(false);
            }

            if(triggerLoad && setTriggerLoad) {
                setPages(0);
                setTriggerLoad(false);
            }
        }
    }, [rowsPerPage, pages, triggerLoad, pageChanged, sortDirection, sortReference]);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPage, pages, triggerLoad, pageChanged]);

    return (
        <>
            <h5
                style={{ borderBottom: '3px solid red', paddingBottom: '5px' }}>
                Cancelado {' '}
                <label style={{ color: "#ccc", fontSize: "12px" }}>({totalCount})</label>
            </h5>

            {
                data.length 
                ?
                    data.map((indication) => {

                        return (
                            <div
                                key={indication.id}
                                className="p-5 mb-5 hover"
                                style={{ boxShadow: '0px 6px 5px -3px rgba(0,0,0,0.1)' }}
                                onClick={() => onClickIndication(indication.id)}
                            >
                                <b>{indication.companyEntity.name}</b>
                                <p>{indication.companyEntity.typePeople == "legal" ? formatCpfCnpj("CNPJ", indication.companyEntity.cnpj) : formatCpfCnpj("CPF", indication.companyEntity.cpf)}</p>
                            </div>
                        );
                    })
                : <p style={{ color: "#ccc" }}>Nenhuma empresa cancelou até o momento...</p> 
            }
        </>
    )
}