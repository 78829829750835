import { useState } from "react";

export default function usePaymentForm() {
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [name, setName] = useState('');

    const [cardNumberError, setCardNumberError] = useState(true);
    const [nameError, setNameError] = useState(true);
    const [expiryError, setExpiryError] = useState(true);
    const [cvcError, setCvcError] = useState(true);

    return {
        cardNumber, setCardNumber,
        expiry, setExpiry,
        cvc, setCvc,
        name, setName,
        cardNumberError, setCardNumberError,
        nameError, setNameError,
        expiryError, setExpiryError,
        cvcError, setCvcError,
    };
}