import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const compressFiles = (files: any, fileName: string, zipFileName: string, format?: string) => {
    const zip = new JSZip();

    files.forEach((file: any, index: number) => {
        zip.file(`${fileName}_${index + 1}${format ? `.${format}` : ''}`, file);
    });

    zip.generateAsync({ type:"blob" }).then(function(content) {
        saveAs(content, `${zipFileName}.zip`);
    });
}

export default {
    compressFiles
}
