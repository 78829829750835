import React, {
    Dispatch,
    useContext,
    useReducer,
    createContext,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { Product as ProductHook } from '../../hooks/products';
import calculateInstallmentsTotals from '../../utils/calculateInstallmentsTotals';

export type Product = {
    INSTANCE: ProductHook;
    name: string;
    isLinked: boolean;
    amount: number;
    ipi: string;
    icms: string;
    unitaryValue: number;
    cfop: string;
    ncm: string;
    total: number;
    hasError?: boolean;
};

export type InstallmentDetails = {
    date: string;
    value: number;
    comments: string;
    payment: string;
    revenue?: string;
    centerCost?: string;
    isPaid?: boolean;
    paymentDate?: string;
    bankAccount?: string;
};

type ProductsActionTypes = "CHANGE" | "ADD" | "DELETE" | "INITIAL";
type ProductsAction = {
    type: ProductsActionTypes;
    payload?: {
        index: number;
        product?: Product;
        products?: Product[];
    };
}

type InstallmentActionTypes = "CHANGE" | "GENERATE" | "CHANGE_VALUE" | "CHANGE_DATE" | "INITIAL";
type InstallmentAction = {
    type: InstallmentActionTypes;
    payload: {
        index?: number;
        value?: number;
        date?: string;
        amount?: number;
        installment?: InstallmentDetails;
        installments?: InstallmentDetails[];
    };

};

const ActionObjects = {
    CHANGE: "CHANGE",
    CHANGE_VALUE: "CHANGE_VALUE",
    CHANGE_DATE: "CHANGE_DATE",
    DELETE: "DELETE",
    ADD: "ADD",
    GENERATE: "GENERATE",
    INITIAL: "INITIAL",
};

const productsReducer = (state: Product[], { type, payload }: ProductsAction): Product[] => {
    const aux = state;

    if (type === ActionObjects.INITIAL) {
        if (!payload || !payload.products) {
            throw new Error(`Type \'${ActionObjects.INITIAL}\' must contain payload params`);
        }

        return [...payload.products];
    }

    if (type === ActionObjects.CHANGE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        const { product, index } = payload;
        
        if (!product) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain product within payload`);
        }

        aux[index] = product;

        return [...aux];
    }
   
    if (type === ActionObjects.DELETE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        if (!payload.index) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        const filteredState = state.filter((_, i) => i !== payload.index);

        return [...filteredState];
    }

    if (type === ActionObjects.ADD) {
        const newState: Product = {
            INSTANCE: {} as ProductHook,
            isLinked: false,
            name: '',
            amount: 0,
            cfop: "0",
            icms: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            unitaryValue: 0,
        }

        return [...aux, newState];
    }
 
    return [...aux];
}

const InstallmentReducer = (state: InstallmentDetails[], { type, payload }: InstallmentAction): InstallmentDetails[] => {
    const aux = state;

    if (type === ActionObjects.INITIAL) {
        if (!payload || !payload.installments) {
            throw new Error(`Type \'${ActionObjects.INITIAL}\' must contain payload params`);
        }

        return [...payload.installments];
    }

    if (type === ActionObjects.CHANGE) {
        const { index, installment } = payload;

        if (!installment || index === undefined) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        aux[index] = installment;

        return [...aux];
    }

    if (type === ActionObjects.CHANGE_VALUE) {
        const { installment, value, index, amount } = payload;

        if (!installment || value === undefined || index === undefined || !amount) {
            throw new Error();
        }

        let newArray: InstallmentDetails[] = [];
        const total = state.reduce((acc, { value }) => acc + value, 0);

        const beforeIndex = state.reduce((acc, { value }, i) => {
            if (i < index) {
                return acc + value;
            }

            return acc;
        }, 0);

        const partial = beforeIndex + value;

        if (partial > total) {
            alert("A soma das parcelas não pode ser maior que o valor total");
            return [];
        }

        const remainingInstallment = amount - index - 1;
        const newValueInstallment = (total - partial) / remainingInstallment;

        for (let i = 0; i < amount; i += 1) {
            if (i < index) {
                newArray.push({ ...aux[i] });
            }

            if (i === index) {
                newArray.push(installment);
            }

            if (i > index) {
                const newInstallmentDetails: InstallmentDetails = {
                    ...aux[i],
                    value: newValueInstallment
                };

                newArray.push(newInstallmentDetails);
            }
        }

        return [...newArray];
    }

    if(type === ActionObjects.CHANGE_DATE) {
        const { installment, date, index } = payload;

        if (!installment || date === undefined || index === undefined) {
            throw new Error();
        }

        let newArray = state.map((installmentObj, i) => i === index ? {...installmentObj, date} : installmentObj);

        return [...newArray];
    }

    if (type === ActionObjects.GENERATE) {
        const { amount, value } = payload;

        if (amount === undefined || value === undefined) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        let initial: InstallmentDetails[] = [];
        const installmentValue = Number(value) / amount;

        const initialDate = new Date();

        for (let i = 0; i < amount; i += 1) {
            initialDate.setMonth(initialDate.getMonth() + 1);

            const day = String(initialDate.getDate()).padStart(2, '0');
            const month = String(initialDate.getMonth() + 1).padStart(2, '0');
            const year = initialDate.getFullYear();

            const date = year + '-' + month + '-' + day;

            const Installment: InstallmentDetails = {
                date,
                comments: "",
                value: Number(installmentValue.toFixed(2)),
                payment: "Cartão de Crédito",
            };

            initial.push(Installment);
        }
        initial = calculateInstallmentsTotals(initial, value);

        return initial;
    }

    return [...aux];
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));


interface RequestContextData {
    products: Product[];
    installments: InstallmentDetails[];
    dispatchProducts: Dispatch<ProductsAction>;
    dispatchInstallments: Dispatch<InstallmentAction>;
    classes: ClassNameMap<"container" | "dense" | "menu" | "textField" | "formControl">;
}
const RequestContext = createContext({} as RequestContextData);


const RequestProvider: React.FC = ({ children }) => {
    const [products, dispatchProducts] = useReducer(productsReducer, [
        {
            isLinked: false,
            name: '',
            icms: "0",
            cfop: "0",
            ipi: "0",
            ncm: "0",
            total: 0,
            amount: 0,
            unitaryValue: 0,
            INSTANCE: {} as ProductHook,
        }
    ]);
    const [installments, dispatchInstallments] = useReducer(InstallmentReducer, []);

    const classes = useStyles();

    return (
        <RequestContext.Provider value={{
            classes,
            products,
            installments,
            dispatchProducts,
            dispatchInstallments,
        }}>
            {children}
        </RequestContext.Provider>
    );
};

const useRequest = () => {
    const context = useContext(RequestContext);

    if (!context) {
        throw new Error();
    }

    return context;
}

export { RequestProvider, useRequest };
