import React from "react";
import { Button } from "react-bootstrap";

type ApiResourceSelectAddButtonProps = {
    onClick: () => void;
    label?: string;
}

export function ApiResourceSelectAddButton({
    onClick,
    label,
}: ApiResourceSelectAddButtonProps) {
    return (
        <Button
            size="sm"
            variant="light"
            onClick={onClick}
        >
            <i className="flaticon2-add"></i>
            {label ?? 'Adicionar Novo Registro'}
        </Button>
    );
}