import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import api from '../../../services/Api';
import { Badge, Button, Spinner } from 'react-bootstrap';
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    AreaChart,
    Area,
} from "recharts";

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [data1, setData1] = useState<any>([]);
    const [data2, setData2] = useState<any>([]);
    const [data3, setData3] = useState<any>([]);
    const [data4, setData4] = useState<any>([]);

    useEffect(() => {
        const fetch = async() => {
            try {
                await setSolicitations();
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, []);

    const setSolicitations = async() => {
        setIsLoading(true);
        let start = startDate ? startDate : "all";
        let end = endDate ? endDate : "all";
        let user = username ? username : "all";
        let actRes = await api.get(`/bpm/solicitations/dashboard/start/${start}/end/${end}`);
        setData1(actRes.data.data1);
        setData2(actRes.data.data2);
        setData3(actRes.data.data3);
        setData4(actRes.data.data4);
        setIsLoading(false);
    }

    const applyFilter = async() => {
        if (startDate || endDate || username) {
            await setSolicitations();
        }
    }

    const clearFilter = async() => {
        setStartDate("");
        setEndDate("");
        setUsername("");
        let actRes = await api.get(`/bpm/solicitations/dashboard/start/all/end/all`);
        setData1(actRes.data.data1);
    }

    return (
        <>
            {/* <div style={{...styles.header, border:"none", marginBottom: 20, display: "relative"}}>
                <div style={{width: "100%", display: "inline-flex", position: "relative", zIndex: 49,}}>
                    <div style={{width: "40%"}}>
                        <TextField
                            label={"Data inicial"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{width: "49%", marginRight: "1%"}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label={"Prazo"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{width: "49%", marginRight: "1&"}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
                <div style={styles.filterButtons}>
                    {
                        startDate || endDate ?
                            <Button
                                onClick={() => clearFilter()}
                                variant={"danger"}
                                style={{marginRight: 5}}
                            >
                                <i className="flaticon2-cross p-0"/>
                            </Button>
                            : <></>
                    }
                    <Button
                        onClick={() => applyFilter()}
                    >
                        <i className="flaticon-search p-0"/>
                    </Button>
                </div>
            </div> */}
            <div className="d-flex flex-column col-12 text-left" style={styles.content}>
                <h4>Status geral das solicitações</h4>
                <LineChart
                    width={1090}
                    height={420}
                    data={data1}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Valor" stroke="#8884d8" />
                    <Line type="monotone" dataKey="Total" stroke="#82ca9d" />
                </LineChart>
            </div>
            <div className="d-flex flex-column col-6 text-left" style={{...styles.content, float: "left" as "left", width: "49%"}}>
                <h4>Solicitações mensais completas</h4>
                <BarChart
                    width={500}
                    height={300}
                    data={data2}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="Valor" fill="#555" background={{ fill: '#eee' }} />
                </BarChart>
            </div>
            <div className="d-flex flex-column col-6 text-left" style={{...styles.content, width: "49%"}}>
                <h4>Solicitações mensais não completas</h4>
                <BarChart
                    width={500}
                    height={300}
                    data={data3}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="Valor" fill="#555" background={{ fill: '#eee' }} />
                </BarChart>
            </div>
            <div className="d-flex flex-column col-12 text-left" style={styles.content}>
                <h4>Solicitações do mês no prazo e em atrazo</h4>
                <AreaChart
                    width={1090}
                    height={420}
                    data={data4}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="Valor" stroke="#8884d8" fill="#8884d8" />
                    <Area type="monotone" dataKey="Total" stroke="#8884d8" fill="#FFF" />
                </AreaChart>
            </div>
        </>
    );
}

const styles = {
    content: {
        // overflow: "scroll",
        width: "100%",
        textAlign: "center" as "center",
    },
    header: {
        width: "100%",
        border: `1px solid #DCDCDC`,
        borderRadius: 6,
        marginTop: -20,
        marginBottom: 40,
    },
    filterButtons: {
        float: "right" as "right",
        marginTop: -45,
        position: "relative" as "relative",
        zIndex: 50,
    },
}

export default Dashboard;