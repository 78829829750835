import React, { useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    TextField,
    Typography,
    Table,
    TableRow,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Badge, Button, Form, Modal, Tab, Tabs} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/Api';
import SolicitationProcess from './SolicitationProcess';
import SolicitationDiagram from './SolicitationDiagram';
import SolicitationHistory from './SolicitationHistory';
import { useSelector } from "react-redux";
import { formatDate } from '../../../utils/dateFormat';
import ModalSuccess from '../../../components/ModalSuccess';

type params = {
    processId: string,
    solicitationId: string
}

export function SolicitationForm() {
    const { user } = useSelector((state: any) => state.auth);
    const { push: pushHistory, location: { pathname } } = useHistory();
    const { processId, solicitationId } = useParams<params>();
    const [selectedPage, setSelectedPage] = useState(0);
    const selected = {...styles.headerButton, ...styles.selected};
    const [form, setForm] = useState<any>([]);
    const [name, setName] = useState<string>("");
    const [value, setValue] = useState<any>([]);
    const [hideTabs, setHideTabs] = useState<number[]>([]);
    const [currentStage, setCurrentStage] = useState<string>("");
    const [currentStageType, setCurrentStageType] = useState<number>(1);
    const [solicitationProcess, setSolicitationProcess] = useState<any>([]);
    const [solicitationHistory, setSolicitationHistory] = useState<any>([]);
    const [selectedNextStage, setSelectedNextStage] = useState<string>("0");
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [supervisors, setSupervisors] = useState<any>([]);
    const [requester, setRequester] = useState<number>(0);
    const [requesterName, setRequesterName] = useState("");
    const [responsible, setResponsible] = useState<any>([]);
    const [logUserGroupPermition, setlogUserGroupPermition] = useState<any>([]);
    const [error, setError] = useState<any>(undefined);
    const permitionsGroup = ["Editar", "Visualizar"];
    const [b, setB] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState("form");
    const [endDateActivity, setEndDateActivity] = useState("");
    const [solicitationCreatedAt, setSolicitationCreatedAt] = useState("");
    const [statusSolicitation, setStatusSolicitation] = useState("");
    const [supervisorsName, setSupervisorsName] = useState("");
    const [processName, setProcessName] = useState("");

    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    useEffect(() => {
        const fetch = async() => {
            try {
                if (Number(solicitationId)) {
                    let resp = await api.get(`/bpm/displaySolicitation/${solicitationId}`);
                    let data = resp.data;
                    let sol = data.solicitation;
                    if (sol) {
                        setName(sol.form_name);
                        setValue(JSON.parse(sol.solicitationData));
                        let formArr = JSON.parse(sol.formData)
                        setForm(formArr);
                        let procArr = JSON.parse(sol.processData);
                        setSolicitationProcess(procArr);
                        setSolicitationHistory(data.history);
                        setCurrentStage(sol.processBpmStage);
                        setCurrentStageType(data.processType);
                        setRequester(sol.RequesterId);
                        setRequesterName(sol.requester);
                        setEndDateActivity(sol.endDate);
                        setSolicitationCreatedAt(formatDate(sol.startSolicitation));
                        setStatusSolicitation(sol.statusSolicitation);
                        setProcessName(data.processName);

                        const supervisorsNameConverted = sol.process_supervisors ? JSON.parse(sol.process_supervisors).map((supervisorName: any, index: number) => index == 0 ? `${supervisorName.firstname} ${supervisorName.lastname}` : `, ${supervisorName.firstname} ${supervisorName.lastname}`) : "";
                        setSupervisorsName(supervisorsNameConverted);

                        let superv = sol.process_supervisors ? JSON.parse(sol.process_supervisors) : [];
                        let supCount = superv.length;
                        let supArr = [];
                        if (supCount) {
                            for (let i = 0; i < supCount; i++) {
                                supArr.push(superv[i].id);
                            }
                        }
                        setSupervisors(supArr);

                        let procCount = procArr.length;
                        let respDataArr = [];
                        let permitions: any = {};
                        for (let i = 0; i < procCount; i++) {
                            if (procArr[i].id == sol.processBpmStage) {
                                respDataArr = procArr[i].responsaveis;
                                permitions = procArr[i].permissions ? procArr[i].permissions : {};
                            }
                        }
                        let respArr = [];
                        let rspCount = respDataArr.length;
                        for (let i = 0; i < rspCount; i++) {
                            respArr.push(respDataArr[i].id);
                        }
                        setResponsible(respArr);

                        let logPermition = [];
                        let permitionsArr: any = [];
                        Object.keys(permitions).map((index: any) => {
                            permitionsArr.push(permitions[index]);
                        });
                        if (respArr.includes(user.id)) {
                            let formCount = formArr.length;
                            for (let i = 0; i < formCount; i++) {
                                if (permitionsArr[i]) {
                                    logPermition.push(permitionsArr[i]);
                                } else {
                                    logPermition.push("Não Exibir");
                                }
                            }
                        }
                        setlogUserGroupPermition(logPermition);
                    }
                } else {
                    if (Number(processId)) {
                        let resp = await api.get(`/bpm/form/displayProcessForm/${processId}`);
                        let data = resp.data;
                        setName(data.form[0].name);
                        setSolicitationProcess(JSON.parse(data.process_data));
                        let groupArr = [];
                        let groupData = data.groups;
                        let gCount = groupData.length;
                        let val = value;

                        let usPermition = [];
                        for (let i = 0; i < gCount; i++) {
                            let group = groupData[i];
                            groupArr.push({
                                name: group.name,
                                index: i,
                                savedId: group.id,
                                inputs: [],
                                tables: [],
                            });
                            val.push({
                                inputs: [],
                                tables: [],
                            });

                            usPermition.push("Editar");
                        }
                        setlogUserGroupPermition(usPermition);
                        setResponsible([user.id]);
                        setSupervisors([]);
        
                        let fieldData = data.fields;
                        let fCount = fieldData.length;
                        let tableData = data.tables;
                        let tCount = tableData.length;
                        let tableFieldData = data.tableFields;
                        let tfCount = tableFieldData.length;
                        for (let i = 0; i < gCount; i++) {
                            let groupArrI =  groupArr[i];
                            let group = groupData[i];
        
                            let input: any = [];
                            let fCounter = 0;
                            let vInput = [];
                            for (let j = 0; j < fCount; j++) {
                                let field = fieldData[j];
                                if (field.groupFormId == group.id) {
                                    let option = field.options.split("*|||*");
                                    let required = field.isRequired == 'y' ? true : false;
                                    let extension = field.extensions;
                                    input.push({
                                        id: fCounter,
                                        name: field.textLabel,
                                        type: field.type,
                                        size: field.length,
                                        rows: 3,
                                        columns: field.lengthColumn,
                                        mask: field.mask,
                                        option,
                                        extension,
                                        required,
                                        savedId: field.id,
                                    })
                                    fCounter++;
                                    vInput.push(field.type == "Caixa_verificacao" ? false : null);
                                }
                            }
                            groupArrI.inputs = input;
                            val[i].inputs = vInput;
        
                            let tables: any = [];
                            let stCounter = 0;
                            let tInput = [];
                            for (let k = 0; k < tCount; k++) {
                                let table = tableData[k];
                                if (table.groupFormId == group.id) {
                                    tables.push({
                                        id: stCounter,
                                        name: table.name,
                                        dataColumns: [],
                                        columns: 0,
                                        rows: table.countRows,
                                        savedId: table.id,
                                    });
        
                                    let dataColumns: any = [];
                                    let dColumnsCounter = 0;
                                    let tfInput: any = [[]];
                                    for (let l = 0; l < tfCount; l++) {
                                        let tField = tableFieldData[l];
                                        if (tField.TableFieldBpmId == table.id) {
                                            let option = tField.options.split("*|||*");
                                            let required = tField.isRequired == 'y' ? true : false;
                                            let extension = tField.extensions;
                                            dataColumns.push({
                                                id: dColumnsCounter,
                                                name: tField.textLabel,
                                                type: tField.type,
                                                size: tField.length,
                                                rows: 3,
                                                columns: tField.lengthColumn,
                                                mask: tField.mask,
                                                option,
                                                extension,
                                                required,
                                                savedId: tField.id,
                                            });
                                            dColumnsCounter++;
                                            tfInput[0].push(tField.type == "Caixa_verificacao" ? false : null);
                                        }
                                    }
                                    tInput.push(tfInput);
                                    tables[stCounter].dataColumns = dataColumns;
                                    stCounter++;
                                }
                            }
                            val[i].tables = tInput;
                            groupArrI.tables = tables;
                        }
                        setValue(val);
                        setForm(groupArr);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, []);

    const setData = (groupIndex: number, inputIndex: number, val: any) => {
        let valArr: any = value;
        valArr[groupIndex].inputs[inputIndex] = val;
        console.log(valArr);
        setValue(valArr);
        setUpdate();
    }

    const setFile = async(
        groupIndex: number,
        inputIndex: number,
        event: any,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            let file = event.target;
            if (!file.files || file.files.length == 0) {
                return;
            }
            let val = file.files[0];
            let fd = new FormData();
            fd.append("file", val, val.name);
            let upload = await api.post("/bpm/solicitation/file", fd);
            let fileUpload = upload.data;
            let valArr = value;
            if (type == "input") {
                valArr[groupIndex].inputs[inputIndex] = fileUpload;
            } else {
                if ((tableIndex != null || tableIndex != undefined) && (columnIndex != null || columnIndex != undefined)) {
                    valArr[groupIndex].tables[tableIndex][columnIndex][inputIndex] = fileUpload;
                }
            }

            let obj = {
                solicitationId,
                value: JSON.stringify(valArr),
            }
            let resp = await api.post("/bpm/solicitation/uploadSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);

            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const clearFile = async(
        groupIndex: number,
        inputIndex: number,
        filename: string,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            await api.delete('/file', {data: { filename }})
            let obj = {
                solicitationId,
                type,
                groupIndex,
                tableIndex,
                columnIndex,
                inputIndex,
            }
            let resp = await api.post("/bpm/solicitation/deleteSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);
            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const displayInputs = (groupIndex: number, inputs: any = []) => {
        return(
            inputs.map((i: any, inputIndex: number) =>{
                let type = form[groupIndex].inputs[inputIndex].type;
                let label = form[groupIndex].inputs[inputIndex].name;
                let rows = form[groupIndex].inputs[inputIndex].rows;
                let fieldSize = form[groupIndex].inputs[inputIndex].columns;
                return(
                    <Grid item lg={fieldSize} key={i.id}>
                        { fieldType(type, groupIndex, inputIndex, label, rows) }
                    </Grid>
                );
            })
        );
    }

    const fieldType = (type: string, gIndex: number, iIndex: number, label?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Visualizar" ? true : false
                : 
                true;
        let fieldValue = value[gIndex].inputs[iIndex];
        let required = form[gIndex].inputs[iIndex].required;
        let err = error ? error[gIndex].inputs[iIndex] : "";

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField                        
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].inputs[iIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                label={label}
                                value={selValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{label} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].inputs[iIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={label}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].inputs[iIndex].extension;
                return(
                    <div>
                        <span>{label}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small style={{marginLeft: 5}}>{fieldValue}</small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, iIndex, fieldValue)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        // required={required}
                                        onChange={(e) => setFile(gIndex, iIndex, e)}
                                        style={
                                            err ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].inputs[iIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => setData(gIndex, iIndex, !v)}
                                    disabled={condition}
                                    // required={required}
                                    id={`checkbox-${gIndex}${iIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${iIndex}`}>{label}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const displayTables = (groupIndex: number, tables: any = [], condition: boolean) => {
        return(
            tables.map((t: any, tableIndex: number) =>
                <div key={tableIndex} style={{marginTop: 35, width: "100%"}}>
                    <div className="d-flex flex-row align-items-center justify-content-between col-12">
                        <b style={{color: condition ? "#ccc" : "#555"}}>{t.name}</b>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Button
                                onClick={() => addColumn(groupIndex, tableIndex)}
                                disabled={condition}
                                className="d-flex flex-row align-items-center"
                                size="sm"
                                variant={condition ? "light" : "primary"}
                            >
                                <i className="flaticon2-plus p-0 mr-3" style={{fontSize: "12px"}} />
                                Adicionar
                            </Button>
                        </Tooltip>
                    </div>
                    <Grid item lg={12}>
                        { displayTableField(groupIndex, tableIndex) }
                    </Grid>
                </div>
            )
        );
    }

    const displayTableField = (groupIndex: number, tableIndex: number) => {
        let dataColumns = form[groupIndex].tables[tableIndex].dataColumns;
        let valueTable = value[groupIndex].tables[tableIndex];
        let count = valueTable.length;
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[groupIndex]) ?
                logUserGroupPermition[groupIndex] == "Visualizar" ? true : false
                : 
                true;
        return(
            valueTable.map((c: any, columnIndex: number) =>
                <div className="col-12" key={columnIndex}>
                    { /*<TableCell>{columnIndex + 1}</TableCell>*/ }
                    <div className='row'>
                        {
                            c.map((f: any, fieldIndex: number) => {
                                let type = dataColumns[fieldIndex].type;
                                let placeholder = dataColumns[fieldIndex].name;
                                let rows = dataColumns[fieldIndex].rows;
                                return(
                                    <div className="col" key={fieldIndex}>
                                        { tableFieldType(type, groupIndex, tableIndex, columnIndex, fieldIndex, placeholder, rows) }
                                    </div>
                                );
                            })
                        }
                            {
                                columnIndex ?
                                    <div className="col-1 pt-5">
                                        <Button
                                            variant={"danger"}
                                            onClick={() => {deleteColumn(groupIndex, tableIndex, columnIndex)}}
                                            disabled={condition}
                                            size="sm"
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                : <></>
                            }
                    </div>
                </div>
            )
        );
    }

    const tableFieldType = (type: string, gIndex: number, tIndex: number, cIndex: number, fIndex: number, placeholder?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Visualizar" ? true : false
                : 
                true;
        let fieldValue = value[gIndex].tables[tIndex][cIndex][fIndex];
        let required = form[gIndex].tables[tIndex].dataColumns[fIndex].required;
        let err = error ? error[gIndex].tables[tIndex][cIndex][fIndex] : "";

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].tables[tIndex].dataColumns[fIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                placeholder={placeholder}
                                value={selValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{placeholder} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].tables[tIndex].dataColumns[fIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={placeholder}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                placeholder={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                // required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].tables[tIndex].dataColumns[fIndex].extension;
                return(
                    <div>
                        <span>{placeholder}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small style={{marginLeft: 5}}>{fieldValue}</small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, fIndex, fieldValue, "table", tIndex, cIndex)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        // required={required}
                                        onChange={(e) => setFile(gIndex, fIndex, e, "table", tIndex, cIndex)}
                                        style={
                                            err ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].tables[tIndex][cIndex][fIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => changeTableField(gIndex, tIndex, cIndex, fIndex, !v)}
                                    disabled={condition}
                                    // required={required}
                                    id={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}>{placeholder}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const displayError = (message?: string) => {
        return (
            message ?
                <small
                    style={{
                        fontWeight: "bold" as "bold",
                        color: "red"
                    }}
                >*{message}</small>
                : <></>
        );
    }

    const changeTableField = (gIndex: number, tIndex: number, cIndex: number, fIndex: number, impValue: any) => {
        let val: any = value;
        if (val[gIndex]) {
            if (val[gIndex].tables[tIndex]) {
                if (val[gIndex].tables[tIndex][cIndex]) {
                    val[gIndex].tables[tIndex][cIndex][fIndex] = impValue;
                    setValue(val);
                }
            }
        }
        setUpdate();
    }

    const addColumn = (groupIndex: number, tableIndex: number) => {
        setError(undefined);
        let numFields = form[groupIndex].tables[tableIndex].dataColumns.length;
        let data = [];
        for (let i = 0; i < numFields; i++) {
            data.push(null);
        }
        let val = value;
        val[groupIndex].tables[tableIndex].push(data);
        console.log(val);
        setValue(val);
        setUpdate();
        console.log(value[groupIndex].tables[tableIndex]);
    }

    const deleteColumn = async(groupIndex: number, tableIndex: number, columnIndex: number) => {
        try {
            setError(undefined);
            let val = value;
            let columnFields = val[groupIndex].tables[tableIndex][columnIndex];
            let columnFieldslength = columnFields.length;
            let updateColumn = false;
            for (let i = 0; i < columnFieldslength; i++) {
                let fieldType = form[groupIndex].tables[tableIndex].dataColumns[i].type;
                if (fieldType == "Anexo" && columnFields[i]) {
                    updateColumn = true;
                    let filename = columnFields[i];
                    await api.delete('/file', {data: { filename }})
                }
            }

            val[groupIndex].tables[tableIndex].splice(columnIndex, 1);
            let obj = {
                solicitationId,
                value: JSON.stringify(val),
                groupIndex,
                tableIndex,
            }
            let tableRes = await api.post("/bpm/solicitation/updateTable", obj);
            let tableData = JSON.parse(tableRes.data.solicitationData);
            setValue(tableData);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const setUpdate = () => {
        setB(!b);
    }

    const hideGroupTab = (groupIndex: number) => {
        let arr = hideTabs;
        if (arr.includes(groupIndex)) {
            let index = arr.indexOf(groupIndex);
            arr.splice(index, 1);
        } else {
            arr.push(groupIndex);
        }
        setHideTabs(arr);
        setUpdate();
        console.log(arr);
    }

    const displayGatewayNextStage = () => {
        let options: any = [];
        let procLength = solicitationProcess.length;
        for (let i = 0; i < procLength; i++) {
            if (solicitationProcess[i].id == currentStage) {
                options = solicitationProcess[i].destino;
            }
        }

        let optionsData = []
        let optCount = options.length;
        for (let i = 0; i < optCount; i++) {
            for (let j = 0; j < procLength; j++) {
                if (options[i] == solicitationProcess[j].atividade) {
                    optionsData.push({
                        value: solicitationProcess[j].id,
                        name: solicitationProcess[j].atividade,
                    })
                }
            }
        }

        return (
            <Modal
                show={displayModal}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Próxima atividade
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{width: "100%"}}>
                        <TextField
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            select
                            value={selectedNextStage}
                            onChange={(e) => setSelectedNextStage(e.target.value)}
                        >
                            <MenuItem value={"0"} selected disabled>Selecione...</MenuItem>
                            {
                                optionsData.map((d: any, index: number) =>
                                    <MenuItem key={index} value={d.value}>{d.name}</MenuItem>
                                )
                            }
                        </TextField>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setDisplayModal(false)}
                    >
                        Fechar
                    </Button>
                    <Button
                        style={{...styles.saveButton, marginTop: 2}}
                        onClick={() => onSubmit()}
                        disabled={
                            currentStageType == 1 ?
                                responsible.includes(user.id) ? false : true
                                :
                                selectedNextStage == "0" ? true : false
                        }
                    >Salvar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const getNextStageProcess = (solicitationProcessData: any, selectedNextStageData: string, currentStageData: string) => {
        for (const activity of solicitationProcessData) {
            if (selectedNextStageData == '0') {
                const next = solicitationProcessData.find((process: any) => process.atividade == activity.destino);

                if (next && activity.id == currentStageData) {
                    return next;
                }

            } else {
                if (activity.id == selectedNextStageData) {
                    return activity;
                }
            }
        }
        return null;
    }

    const onSubmit = async() => {
        try {
            let submit = true;
            let error: any = [];
            let gCount = form.length;
            for (let i = 0; i < gCount; i++) {
                error.push({
                    inputs: [],
                    tables: [],
                });
            }

            for (let i = 0; i < gCount; i++) {
                let fCount = form[i].inputs.length;
                let formInputs = form[i].inputs;
                let formValues = value[i].inputs;
                let condition = 
                    permitionsGroup.includes(logUserGroupPermition[i]) ?
                        logUserGroupPermition[i] == "Visualizar" ? true : false
                        : 
                        true;
                for (let j = 0; j < fCount; j++) {
                    let fieldName = formInputs[j].name;
                    if (!condition && formInputs[j].required && !formValues[j]) {
                        // PROVISORIO
                        // error[i].inputs.push(`O campo ${fieldName} é obrigatório`);
                        // submit= false;
                    } else {
                        error[i].inputs.push(null);
                    }
                }

                let formTables = form[i].tables;
                let tCount = formTables.length;
                let formTablesValues = value[i].tables;
                for (let k = 0; k < tCount; k++) {
                    error[i].tables.push([])
                    let tableValueColumns = formTablesValues[k];
                    let tableValueColumnsCount = tableValueColumns.length;
                    for (let l = 0; l < tableValueColumnsCount; l++) {
                        error[i].tables[k].push([]);
                        let columnTableValues = tableValueColumns[l];
                        let columnTableValuesCount = columnTableValues.length;
                        for (let m = 0; m < columnTableValuesCount; m++) {
                            if (!condition && formTables[k].dataColumns[m].required && !columnTableValues[m]) {
                                // PROVISORIO
                                // let fieldName = formTables[k].dataColumns[m].name;
                                // error[i].tables[k][l].push(`O campo ${fieldName} é obrigatório`);
                                // submit= false;
                            } else {
                                error[i].tables[k][l].push(null);
                            }
                        }
                    }
                }
            }
            setError(error);

            const correntSolicitationProcess = solicitationProcess.find((activity: any) => activity.id == currentStage);
            console.log(correntSolicitationProcess);
            const nextStageProcess = getNextStageProcess(solicitationProcess, selectedNextStage, currentStage);
            if (correntSolicitationProcess.tipo == "Exclusivo") {
                setMsgSuccess(`Atividade salva com sucesso! Próxima atividade é "${nextStageProcess?.atividade}", O responsável receberá uma notificação.`);
            } else if (nextStageProcess && nextStageProcess.tipo == "Exclusivo") {
                setMsgSuccess(`Atividade salva com sucesso! Próxima atividade é o gateway "${nextStageProcess?.atividade}", aguarde o supervisor para selecionar o destino dela.`);
                
            } else if (
                correntSolicitationProcess.tipo == "Fim" &&
                correntSolicitationProcess.destino.length == 0
            ) {
                setMsgSuccess(`Solicitação concluída com sucesso! Todas atividades foram concluídas.`);

            } else {
                setMsgSuccess(`Atividade salva com sucesso! Próxima atividade é "${nextStageProcess?.atividade}", O responsável receberá uma notificação.`);
            }
            
            if (currentStageType == 2) {
                if (selectedNextStage == "0") {
                    setDisplayModal(true);
                } else {
                    if (submit) {
                        let obj = {
                            solicitationId,
                            processId,
                            form,
                            value,
                            selectedNextStage,
                        }
                        let res = await api.post("/bpm/solicitation/save", obj);
                        // pushHistory(`/bpm/solicitacao-de-processos/${processId}`);
                        setDisplayModal(false);
                        setShowModalSuccess(true);
                    }
                }
            } else {
                if (submit) {
                    let obj = {
                        solicitationId,
                        processId,
                        form,
                        value,
                    }
                    let res = await api.post("/bpm/solicitation/save", obj);
                    // pushHistory(`/bpm/solicitacao-de-processos/${processId}`);
                    setDisplayModal(false);
                    setShowModalSuccess(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const displayFormData = (g: any, groupIndex: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[groupIndex]) ?
                logUserGroupPermition[groupIndex] == "Visualizar" ? true : false
                : 
                true;
        
        return (
            <div key={g.id}>
                <h5 className="mb-0 mt-15" style={{color: condition ? "#ccc" : "#555",}}>{g.name}</h5>
                <div style={
                    hideTabs.includes(groupIndex) ?
                        {...styles.groupDivStyle, display: "none"}
                        :
                        {...styles.groupDivStyle}
                }>
                    <Grid container spacing={3}>
                        { displayInputs(groupIndex, g.inputs) }
                    </Grid>
                    <Grid container spacing={3}>
                        { displayTables(groupIndex, g.tables, condition) }
                    </Grid>
                </div>
            </div>
        );
    }

    const displayForm = () => {
        return(
            form.length ?
                <>
                    {
                        form.map((g: any, groupIndex: number) => {
                            if (!Number(solicitationId)) {
                                return displayFormData(g, groupIndex);
                            } else {
                                if (
                                    (supervisors.includes(user.id) || permitionsGroup.includes(logUserGroupPermition[groupIndex])) 
                                    && logUserGroupPermition[groupIndex] != "Não Exibir"
                                ) {
                                    return displayFormData(g, groupIndex);
                                } else {
                                    return (<></>);
                                }
                            }
                        })
                    }
                </>
                : <></>
        );
    }

    return (
        <>
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect={`/bpm/solicitacao-de-processos/${processId}`}
            />
        
            <div className="card card-body pt-4">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(tab: string) => setActiveTab(tab)}
                    className="mb-3"
                    id='solicitation-tabs'
                >
                    <Tab
                        eventKey='form'
                        title="Formulário"
                        className='py-5'
                    >
                        <div className="d-flex flex-column">
                            <div style={{width: "100%"}}>
                                <h2 style={{float: "left"}}>{name}</h2>
                                <Tooltip title={(currentStageType == 1 ?
                                                responsible.includes(user.id) ? false : true
                                                :
                                                false) == true ? "Você não possuí permissão para salvar, aguarde o responsável!" : ""}>
                                    <Button
                                        style={styles.saveButton}
                                        onClick={() => onSubmit()}
                                        disabled={
                                            currentStageType == 1 ?
                                                responsible.includes(user.id) ? false : true
                                                :
                                                false
                                        }
                                    >Salvar</Button>
                                </Tooltip>
                            </div>
                            <div className="col-12" style={styles.infos}>
                                <div className="row">
                                    <div className="col-6">
                                        <h4 className="d-flex flex-row align-items-center">
                                            Solicitação  
                                            <Tooltip title="Número de Identificação">
                                                <Badge
                                                    variant="light"
                                                    style={{backgroundColor: "#fff", border: "1px solid #f2f2f2", cursor: "default", margin: "0 12px"}}
                                                >
                                                    #{solicitationId}
                                                </Badge>
                                            </Tooltip>
                                            {
                                                statusSolicitation == "active"
                                                ?
                                                <Tooltip title="Data Limite Entrega">
                                                    <label className="d-flex flex-row align-items-center m-0">
                                                        <i className="flaticon2-time p-0 mr-2" style={{color: "#000"}}></i>
                                                        {formatDate(endDateActivity)}
                                                    </label>
                                                </Tooltip>
                                                :
                                                <></>
                                            }
                                        </h4>
                                        <b>{processName}</b>
                                        <p className="m-0">{solicitationProcess.length ? solicitationProcess.find((activity: any) => activity.id == currentStage)?.atividade : ""}</p>
                                        <p style={{fontSize: "0.85rem"}}>{solicitationProcess.length ? solicitationProcess.find((activity: any) => activity.id == currentStage)?.descricaoAtividade : ""}</p>
                                    </div>
                                    <div className="col-6 text-right">
                                        {
                                            statusSolicitation == "active"
                                            ?
                                            <Tooltip title="Data Início Atividade">
                                                <label style={{fontSize: "16px", marginBottom: "0.5rem"}}>
                                                    {
                                                        solicitationHistory.length
                                                        ?
                                                            formatDate(solicitationHistory[solicitationHistory.length - 1].endDate)
                                                        :
                                                        solicitationCreatedAt
                                                    }
                                                </label>
                                            </Tooltip>
                                            :
                                            <></>
                                        }
                                        <br />
                                        <b>Solicitante</b>
                                        <p className="mb-0">{requesterName}</p>
                                        <b>Supervisores</b>
                                        <p className="mb-0">{supervisorsName}</p>
                                        <b>Responsável</b>
                                        <p className="mb-0">
                                            {
                                                solicitationProcess.find((activity: any) => activity.id == currentStage)?.responsaveis.length 
                                                ?
                                                <>
                                                    {
                                                        solicitationProcess.find((activity: any) => activity.id == currentStage).responsaveis.map((responsavel: any, index: number) => {
                                                            return index == 0 ? `${responsavel.firstname} ${responsavel.lastname}` : `, ${responsavel.firstname} ${responsavel.lastname}`;
                                                        })
                                                    }
                                                </>
                                                : 
                                                <p className="d-flex flex-row align-items-start justify-content-end text-warning m-0">
                                                    <i className='flaticon2-warning mr-2 p-0 text-warning'></i>
                                                    Responsabilidade dos Supervisores
                                                </p>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            { displayForm() }
                            { displayGatewayNextStage() }
                        </div>
                    </Tab>
                    <Tab
                        eventKey='historic'
                        title="Histórico"
                        className='py-5'
                    >
                        {
                            Number(solicitationId) ?
                                <SolicitationHistory
                                    historyData={solicitationHistory}
                                />
                            : <></>
                        }
                    </Tab>
                    <Tab
                        eventKey='process'
                        title="Processos"
                        className='py-5'
                    >
                        <SolicitationProcess
                            sortedData={solicitationProcess}
                            current={currentStage}
                        />
                    </Tab>
                    <Tab
                        eventKey='diagram'
                        title="Diagrama"
                        className='py-5'
                    >
                        <SolicitationDiagram
                            sortedData={solicitationProcess}
                        />
                    </Tab>
                </Tabs>            
            </div>
        </>
    );
}

const styles = {
    infos: {
        backgroundColor: "#fdfdfd", 
        borderLeft: "3px solid #2CFFEA",
        marginTop: "5px",
        padding: "15px",
    },
    buttonGroupStyle: {
        border: "none",
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        float: "left" as "left",
        marginRight: 6,
    },
    buttonGroupIcon: {
        color: "#555",
        fontSize: 15,
        marginTop: 5,
    },
    groupDivStyle: {
        width: "100%",
    },
    table: {
        border: "1px solid #DCDCDC",
    },
    tableButton: {
        width: 45,
        height: 45,
    },
    header: {
        width: "100%",
        height: 40,
        border: `1px solid #DCDCDC`,
        borderRadius: 6,
        marginBottom: 30,
    },
    headerButton: {
        border: "none",
        backgroundColor: "transparent",
        marginLeft: 15,
        marginTop: 8,
        color: "#555",
        padding: 0,
        borderBottom: "none",
        borderRadius: 0,
    },
    selected: {
        fontWeight: 600,
        color: "#222",
        borderBottom: "2px solid #222",
    },
    saveButton: {
        marginRight: 5,
        marginTop: -5,
        float: "right" as "right",
    },
    displayFileField: {
        width: "100%",
        height: 54,
        border: "1px solid #BBB",
        borderRadius: 4,
        lineHeight: "50px",
    },
    fileDeleteButton: {
        float:"right" as "right",
        marginRight: 5,
        marginTop:3,
    }
}