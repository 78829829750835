import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { BodyDataBaseProps, HeadDataBaseProps } from "../components/ListWithModalChangeSituation";
import { ReportDataRow, ReportTotals } from '../types/ReportData';

type DownloadSheetParams = BuildSheetParams & {
    filename: string;
    headData: HeadDataBaseProps[];
    bodyData: ReportDataRow[];
    totalsData: ReportTotals[];
}

type BuildSheetParams = {
    headData: HeadDataBaseProps[];
    bodyData: ReportDataRow[];
    totalsData: ReportTotals[];
}

type MountTableParams = {
    worksheet: Worksheet;
    headData: HeadDataBaseProps[];
    bodyData: ReportDataRow[];
    subRow?: boolean;
}

type MountTotalsParams = {
    worksheet: Worksheet;
    totalsData: ReportTotals[];
}

class ReportExcelService {
    async downloadSheet({
        filename,
        headData,
        bodyData,
        totalsData,
    }: DownloadSheetParams) {
        const workbook = this.buildSheet({ headData, bodyData, totalsData });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), filename);
    }

    buildSheet({
        headData,
        bodyData,
        totalsData,
    }: BuildSheetParams) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();

        this.mountTable({
            worksheet,
            headData,
            bodyData,
        });

        this.mountTotals({
            worksheet,
            totalsData,
        })

        return workbook;
    }

    private mountTable({
        worksheet,
        headData,
        bodyData,
        subRow = false,
    }: MountTableParams) {
        // Mount table heading
        if (subRow) {
            worksheet.addRow(headData.map(header => header.value)).font = { bold: true };
        } else {
            worksheet.columns = headData.map((header) => ({
                header: header.value, key: header.reference, width: 20,
            }));
            worksheet.getRow(1).font = { bold: true };
        }

        // Mount table body
        bodyData.forEach((row) => {
            worksheet.addRow(row.cells.map(cell => cell.content !== null ? cell.content.toString() : ''));

            if (row.subRow && row.subRow.bodyData.length) {
                this.mountTable({
                    worksheet,
                    headData: row.subRow.headData,
                    bodyData: row.subRow.bodyData,
                    subRow: true
                });
            }
        });
    }

    private mountTotals({ worksheet, totalsData }: MountTotalsParams) {
        worksheet.addRow([]);

        totalsData.forEach(data => {
            const row = worksheet.addRow([data.name, [], data.value]);
            row.getCell(1).font = { bold: true };
            row.getCell(2).merge(row.getCell(1));
        });
    }
}

export default new ReportExcelService();