import { Collapse, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { EmailsHistory, emailStatus } from '../types/EmailsHistory';
import { ValidationBadge } from './ValidationBadge';

export type ModalSendEmailProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onClickSend: () => any;
    infoEmail: EmailToCustomer;
    setInfoEmail: React.Dispatch<React.SetStateAction<EmailToCustomer>>;
    emailsHistory: EmailsHistory[];
}

export type EmailToCustomer = {
    refId: string,
    refName: string,
    toName: string,
    customerName: string,
    customerId: string,
    email: string,
    contactName: string,
    message: string,
    emailSubject: string,
};

export function ModalSendEmail({
    showModal,
    setShowModal,
    onClickSend,
    infoEmail,
    setInfoEmail,
    emailsHistory,
}: ModalSendEmailProps) {
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [pagesEmail, setPagesEmail] = useState(0);
    const [rowsPerPageEmail, setRowsPerPageEmail] = useState(5);

    const handleChangePageEmail = useCallback((next: number) => {
        setPagesEmail(next);
    }, []);

    const handleRowsPerPageEmail = useCallback((value: number) => {
        setPagesEmail(0);
        setRowsPerPageEmail(value);
    }, []);

    const clearEmailInfo = () => {
        setInfoEmail((prevState) => ({
            ...prevState,
            refId: '',
            refName: '',
            toName: '',
            customerName: '',
            customerId: '',
            email: '',
            contactName: '',
            message: '',
            emailSubject: '',
        }));
    }

    const handleChangeInfoEmailField = (event: any, refId: string, field: string) => {
        const newValue = event.target.value;
        setInfoEmail((prevState) => {
            if (prevState.refId !== refId) return prevState;

            return {
                ...prevState,
                [field]: newValue,
            };
        });
    };

    const handleClickSendEmail = async () => {
        setIsSendingEmail(true);

        await onClickSend();

        setIsSendingEmail(false);
    };

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"
            size="lg"
            show={showModal}
            onHide={() => {
                setShowModal(false);
                clearEmailInfo();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Enviar por E-Mail
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-lg-3">
                        <TextField
                            label='Para / Contato'
                            margin='normal'
                            variant='outlined'
                            size="small"
                            value={infoEmail.contactName}
                            onChange={(e) => handleChangeInfoEmailField(e, infoEmail.refId, 'contactName')}
                        />
                    </div>

                    <div className="col-lg-3">
                        <TextField
                            label='Assunto'
                            margin='normal'
                            variant='outlined'
                            size="small"
                            value={infoEmail.emailSubject}
                            onChange={(e) => handleChangeInfoEmailField(e, infoEmail.refId, 'emailSubject')}
                        />
                    </div>

                    <div className="col-lg-6">
                        <TextField
                            label='E-mail do destinatário'
                            margin='normal'
                            variant='outlined'
                            size="small"
                            value={infoEmail.email}
                            onChange={(e) => handleChangeInfoEmailField(e, infoEmail.refId, 'email')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <TextField
                            size="small"
                            multiline
                            rows="2"
                            margin="normal"
                            variant="outlined"
                            label="Mensagem"
                            value={infoEmail.message}
                            onChange={(e) => handleChangeInfoEmailField(e, infoEmail.refId, 'message')}
                        />
                    </div>
                </div>

                <div className="mt-3">
                    <h3 className="border-bottom mt-3 pb-3">E-mails enviados</h3>

                    <TableContainer style={{ maxHeight: '75vh' }}>
                        <Table stickyHeader className="wrap">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Mensagem</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    emailsHistory.length
                                        ?
                                        emailsHistory.slice(pagesEmail * rowsPerPageEmail, pagesEmail * rowsPerPageEmail + rowsPerPageEmail).map((value, index) => {
                                            var created_at = new Date(value.created_at);
                                            var date = created_at.getDate() < 10 ? "0" + created_at.getDate() : created_at.getDate();
                                            var month = (created_at.getMonth() + 1) < 10 ? "0" + (created_at.getMonth() + 1) : (created_at.getMonth() + 1);

                                            var hour = (created_at.getHours() + 2) < 10 ? "0" + (created_at.getHours() + 2) : (created_at.getHours() + 2);


                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{value.email}</TableCell>
                                                    <TableCell>{date + "/" + month + "/" + created_at.getFullYear() + "  " + hour + ":" + (created_at.getMinutes() < 10 ? "0" + created_at.getMinutes() : created_at.getMinutes())}</TableCell>
                                                    <TableCell>
                                                        {value.message}
                                                    </TableCell>
                                                    <TableCell>
                                                        {value.status === emailStatus.FAILED && (
                                                            <ValidationBadge variant="danger" label="Falha" title={value.failReason || 'Falha ao enviar'} />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell className="text-center" style={{ color: "#ccc" }} colSpan={3}>Nenhum e-mail enviado até o momento...</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        labelRowsPerPage="Linhas por página"
                        page={pagesEmail}
                        component="div"
                        count={emailsHistory.length}
                        rowsPerPage={rowsPerPageEmail}
                        rowsPerPageOptions={[5, 10, 25]}
                        backIconButtonProps={{
                            'aria-label': 'Página Anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Próxima Página',
                        }}
                        onChangePage={(_, next) => handleChangePageEmail(next)}
                        onChangeRowsPerPage={(evt) => handleRowsPerPageEmail(Number(evt.target.value))}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => handleClickSendEmail()}>
                    {
                        isSendingEmail ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />

                                <span className='ml-2'>
                                    Aguarde...
                                </span>

                            </>
                            :
                            <span>Enviar</span>
                    }
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowModal(false);
                    clearEmailInfo();
                }}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}