import React, { useState } from "react";
import { IconButton, TextField } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "../../../components/NumericFormat";
export type ConfigFinancialProps = {
    companyId: number;
    blockDayForChangeOfBillsSituationOfPastMonth: string;
    setBlockDayForChangeOfBillsSituationOfPastMonth: React.Dispatch<React.SetStateAction<string>>;
};

export function ConfigFinancial({
    companyId,
    blockDayForChangeOfBillsSituationOfPastMonth,
    setBlockDayForChangeOfBillsSituationOfPastMonth,
}: ConfigFinancialProps) {
    const [forceUpdate, setForceUpdate] = useState(false);

    function handleChangeBlockDayForChangeOfBillsSituationOfPastMonth(event: React.ChangeEvent<HTMLInputElement>) {
        let dayNumber = Number(event.target.value);
        let dayValue = event.target.value;

        if (dayNumber <= 0) {
            dayValue = '';
        } else if (dayNumber > 31) {
            dayValue = '31';
        }

        setBlockDayForChangeOfBillsSituationOfPastMonth(dayValue);
        setForceUpdate((prevState) => !prevState);
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <NumericFormat
                                label="Dia de encerramento do mês fiscal anterior"
                                className="mx-2"
                                thousandSeparator={''}
                                decimalScale={0}
                                value={blockDayForChangeOfBillsSituationOfPastMonth}
                                onChange={handleChangeBlockDayForChangeOfBillsSituationOfPastMonth}
                                endAdornment={(
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip-top'>
                                                Indica quando o mês fiscal anterior será fechado.
                                                {' '} Quando o mês fiscal é fechado, os usuários sem permissão de "Administrador de fechamento de mês"
                                                {' '} não podem liquidar ou estornar registros nos módulos financeiros. (permissão configurada em Cadastros -&gt; Usuários -&gt; Acesso)
                                                {' '} Se este campo não estiver preenchido, o fechamento do mês anterior ocorrerá no primeiro dia do mês vigente.
                                            </Tooltip>
                                        }
                                    >
                                        <IconButton
                                            edge="end"
                                        >
                                            <i className="flaticon-info"></i>
                                        </IconButton>
                                    </OverlayTrigger>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}