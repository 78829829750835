import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { useHistory, useParams } from "react-router";
import PdfOrder from "./PdfOrder";

type urlParam = {
    type: string,
    id: string
}

export default function PdfOrderView() {
   const { type, id } = useParams<urlParam>();
    
  return (
    <>
        <PDFViewer
            children={<PdfOrder orderId={id} typeOrder={type} />}
            style={{ height: "100vh" }}
        />
    </>
  );
}

