import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../../components/ListWithModalChangeSituation';

import '../../../style.css';
import api from "../../../services/Api";
import { MenuItem, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Zoom } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportExcelService from '../../../services/ReportExcelService';
import { ReportDataCell, ReportDataRow, ReportTotals } from '../../../types/ReportData';
import { getFileNameWithDate } from '../../../utils/getFIleNameWithDate';
import PdfReportDocument from '../../../components/Pdf/Report/PdfReportDocument';
import { formatCurrency, formatNumberToString } from '../../../utils/formatCurrency';
import { ProductsTable } from './ProductsTable';
import { formatDate } from '../../../utils/dateFormat';
import { sumValues } from '../../../utils/sumValues';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import { Product } from '../../../types/Product';
import ProductService from '../../../services/ProductService';
import { Customer } from '../../../types/Customer';
import CustomerService from '../../../services/CustomerService';
import { CategoryProduct } from '../../../hooks/products';
import CategoryProductService from '../../../services/CategoryProductService';
import { StockLocations } from '../../../types/StockLocations';
import StockLocationService from '../../../services/StockLocationService';
import useBackendLoad from '../../../hooks/backendReload';
import { ReportDetails } from '../../../components/ReportDetails';

type Filters = {
    dateMin: string;
    dateMax: string;
    locationId: number;
    situation: string;
    productInput: string;
    categoryId: number;
    providerInputName: string;
    showTypeProducts: string;
    observations: string;
    typeRoadProducts: string;
}

type ProductStockMovementRow = {
    product: Product;
    entries: number;
    outputs: number;
    balance: number;
    supplierName: string;
    movements: StockMovement[];
}

type StockMovement = {
    date: string;
    observation: string;
    entry: number | null;
    output: number | null;
    unitValue: number;
    totalValue: number;
}

type MovementStockResponse = {
    rows: ProductStockMovementRow[],
    count: number;
    totalEntries: number;
    totalOutputs: number;
    totalValueFromEntries: number;
    totalValueFromOutputs: number;
}

const headData: HeadDataBaseProps[] = [
    { reference: "product", value: "Produto" },
    { reference: "code", value: "Código" },
    { reference: "entry", value: "Entradas" },
    { reference: "output", value: "Saídas" },
    { reference: "balance", value: "Saldo" },
    { reference: "provider", value: "Fornecedor" },
];

const productsHeadData: HeadDataBaseProps[] = [
    { reference: "data", value: "Data" },
    { reference: "observations", value: "Observações" },
    { reference: "entry", value: "Entrada" },
    { reference: "output", value: "Saída" },
    { reference: "valueProduct", value: "Valor Unitário" },
    { reference: "totalValue", value: "Valor Total" },
];

export function ListReportMovementStock() {
    const [reportRows, setReportRows] = useState<ProductStockMovementRow[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [isSearching, setIsSearching] = useState(false);
    const [showProducts, setShowProducts] = useState(true);

    const [lastSortReference, setLastSortReference] = useState('id');
    const [lastSortDirection, setLastSortDirection] = useState<'ASC' | 'DESC'>('DESC');
    const [productInput, setProductInput] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [providerInput, setProviderInput] = useState('');
    const [typeRoadProducts, setTypeRoadProducts] = useState('all');
    const [observations, setObservations] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [showTypeProducts, setShowTypeProducts] = useState('all');
    const [dateMin, setDateMin] = useState('');
    const [dateMax, setDateMax] = useState('');
    const [situation, setSituation] = useState('all');

    const [totalCountReportRows, setTotalCountReportRows] = useState(0);
    const [totalEntries, setTotalEntries] = useState<number>();
    const [totalOutputs, setTotalOutputs] = useState<number>();
    const [totalValueFromEntries, setTotalValueFromEntries] = useState<number>(0);
    const [totalValueFromOutputs, setTotalValueFromOutputs] = useState<number>(0);

    const filtersRef = useRef<Filters | null>(null);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    //EXPORT
    const [exportBodyData, setExportBodyData] = useState<ReportDataRow[]>([]);
    const [exportTotals, setExportTotals] = useState<ReportTotals[]>([]);

    //DATA
    const { user } = useSelector((state: any) => state.auth);

    const loadExportData = useCallback(async (
        sortDirection,
        sortReference,
    ) => {
        const { data } = await api.get<MovementStockResponse>("/report/movementStock", {
            params: {
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalEntries,
            totalOutputs,
            totalValueFromEntries,
            totalValueFromOutputs,
        } = data;

        const exportDataList: ReportDataRow[] = [];
        let exportProductsData: ReportDataRow[] = [];

        rows.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow, showProducts);

            const exportCells: ReportDataCell[] = data.map((cell) => ({ id: cell.id, for: cell.for, content: cell.value }));

            if(showProducts) {
                exportProductsData = reportRow.movements.map((movement) => {
                    return {
                        cells: [
                            {for: 'Data', content: formatDate(movement.date)},
                            {for: 'Observações', content: movement.observation},
                            {for: 'Entrada', content: String(movement.entry ?? '-')},
                            {for: 'Saída', content: String(movement.output ?? '-')},
                            {for: 'Valor', content: formatCurrency(movement.unitValue)},
                            {for: 'Valor Total', content: formatCurrency(movement.totalValue)}
                        ],
                    };
                });
            }

            exportDataList.push({
                cells: exportCells,
                subRow: {
                    headData: productsHeadData,
                    bodyData: exportProductsData,
                }
            });
        });

        setExportTotals([
            { name: 'Entradas', value: totalEntries },
            { name: 'Saídas', value: totalOutputs },
            { name: 'Valor Entradas', value: formatCurrency(totalValueFromEntries) },
            { name: 'Valor Saídas', value: formatCurrency(totalValueFromOutputs) },
        ]);
        setExportBodyData(exportDataList);
    }, [showProducts]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setIsSearching(true);

        const { data } = await api.get<MovementStockResponse>("/report/movementStock", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {
            rows,
            count,
            totalEntries,
            totalOutputs,
            totalValueFromEntries,
            totalValueFromOutputs,
        } = data;

        setReportRows(rows);

        setTotalCountReportRows(count);
        setTotalEntries(totalEntries);
        setTotalOutputs(totalOutputs);
        setTotalValueFromEntries(totalValueFromEntries);
        setTotalValueFromOutputs(totalValueFromOutputs);

        setIsSearching(false);
        setLastSortReference(sortReference);
        setLastSortDirection(sortDirection);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = reportRows;

        aux.forEach((reportRow) => {
            const data = mountBodyDataRow(reportRow, showProducts);

            list.push(data);
        });

        setBodyData(list);
    }, [reportRows, showProducts]);

    useEffect(() => {
        loadExportData(lastSortDirection, lastSortReference);
    }, [lastSortDirection, lastSortReference]);

    function mountBodyDataRow(reportRow: ProductStockMovementRow, withProducts: boolean) {
        const data: BodyDataBaseProps[] = [
            { for: "product", value: reportRow.product?.name ?? '' },
            { for: "code", value: reportRow.product?.code ?? '' },
            { for: "entry", value: String(reportRow.entries) },
            { for: "output", value: String(reportRow.outputs) },
            { for: "balance", value: String(reportRow.balance) },
            { for: "provider", value: reportRow.supplierName }
        ];

        if (withProducts) {
            data.push({
                for: "products/movements",
                value: '',
                secondaryRow: true,
                jsx: <ProductsTable products={reportRow.movements} />
            });
        };

        return data;
    }

    const clearSearch = () => {
        setLocationId(0);
        setSituation('all');
        setProductInput('');
        setCategoryId(0);
        setShowTypeProducts('all');
        setProviderInput('');
        setDateMin('');
        setDateMax('');
        setObservations('');
        setTypeRoadProducts('all');
    };

    const handleClickSearch = useCallback(() => {
        const providerInputName = providerInput || 'all';

        filtersRef.current = {
            dateMin,
            dateMax,
            locationId,
            situation,
            productInput: productInput || 'all',
            categoryId,
            providerInputName,
            showTypeProducts,
            observations,
            typeRoadProducts,
        }

        reloadData();
        loadExportData(lastSortDirection, lastSortReference);
    }, [
        dateMin, dateMax, locationId, situation,
        productInput, categoryId, providerInput,
        showTypeProducts, observations, typeRoadProducts,
        lastSortDirection, lastSortReference,
    ]);

    async function handleClickExportExcel() {
        ReportExcelService.downloadSheet({
            filename: getFileNameWithDate({ filename: 'Estoque-Movimentações', extension: 'xlsx' }),
            headData,
            bodyData: exportBodyData,
            totalsData: exportTotals,
        });
    };

    return (
        <div className="card card-body pt-4 newProductWrapper" >
            <div className="row d-flex align-items-center">

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Produto"
                        getOptionLabel={(option: Product) => option.name}
                        value={productInput}
                        onSelect={(option) => setProductInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => ProductService.getAllProductsFiltered({ name: typedText, type: 'product' })}
                    />
                </div>

                <div className="col-lg-4 d-flex justify-content-between">

                    <TextField
                        type="Date"
                        label="Período - Min"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMin}
                        onChange={(e: any) => setDateMin(e.target.value)}
                    />

                    <TextField
                        type="Date"
                        label="Período - Máx"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        className="ms-20"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={dateMax}
                        onChange={(e: any) => setDateMax(e.target.value)}
                    />

                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Status"
                        margin="normal"
                        variant="outlined"
                        value={situation}
                    >
                        <MenuItem key="0" value="all" onClick={() => setSituation('all')}>
                            Todos
                        </MenuItem>

                        <MenuItem key="1" value="active" onClick={() => setSituation('active')}>
                            Ativo
                        </MenuItem>

                        <MenuItem key="2" value="inactive" onClick={() => setSituation('inactive')}>
                            Inativo
                        </MenuItem>

                    </TextField>
                </div>

                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Localização"
                        getOptionLabel={(option: StockLocations) => option.name}
                        value={locationId}
                        onSelect={(option) => setLocationId(Number(option?.id ?? 0))}
                        apiSearchHandler={(typedText) => StockLocationService.getStockLocationsFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!locationId) return null;
                            return loadedOptions.find((option) => option.id === Number(locationId)) ?? StockLocationService.getStockLocationById(locationId)
                        }}
                    />
                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Exibir produtos"
                        margin="normal"
                        variant="outlined"
                        value={showTypeProducts}         
                    >
                    
                        <MenuItem key="0" value="all" onClick={() => setShowTypeProducts('all')}>
                            Todos
                        </MenuItem>
                    
                        <MenuItem key="1" value="inStock" onClick={() => setShowTypeProducts('inStock')}>
                            Somente em estoque
                        </MenuItem>

                        <MenuItem key="2" value="stockMin" onClick={() => setShowTypeProducts('stockMin')}>
                            Abaixo do estoque mínimo
                        </MenuItem>

                        <MenuItem key="3" value="stockMax" onClick={() => setShowTypeProducts('stockMax')}>
                            Acima do estoque máximo
                        </MenuItem>

                        <MenuItem key="4" value="noStock" onClick={() => setShowTypeProducts('noStock')}>
                            Sem estoque
                        </MenuItem>

                    </TextField>

                </div>

                <div className="col-lg-4">
                    <TextField
                        select
                        size="small"
                        label="Tipo"
                        margin="normal"
                        variant="outlined"
                        value={typeRoadProducts}         
                    >
                    
                        <MenuItem key="0" value="all" onClick={() => setTypeRoadProducts('all')}>
                            Todos
                        </MenuItem>
                    
                        <MenuItem key="1" value="entry" onClick={() => setTypeRoadProducts('entry')}>
                            Entrada
                        </MenuItem>

                        <MenuItem key="2" value="output" onClick={() => setTypeRoadProducts('output')}>
                            Saída
                        </MenuItem>

                        <MenuItem key="3" value="transfer" onClick={() => setTypeRoadProducts('transfer')}>
                            Transferência
                        </MenuItem>

                    </TextField>
                    
                </div>

                <div className="col-lg-6">
                    <ApiResourceSelect
                        label="Fornecedor"
                        getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                        value={providerInput}
                        onSelect={(option) => setProviderInput(option?.name ?? '')}
                        apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                    />
                </div>

                <div className="col-lg-6">
                    <TextField
                        size="small"
                        label="Observações"
                        margin="normal"
                        variant="outlined"
                        value={observations}
                        onChange={(e) => setObservations(e.target.value)}
                    />
                </div>

                
                <div className="col-lg-4">
                    <ApiResourceSelect
                        label="Categorias"
                        getOptionLabel={(option: CategoryProduct) => option.nameCategory}
                        value={categoryId}
                        onSelect={(option) => setCategoryId(Number(option?.id ?? 0))}
                        apiSearchHandler={(typedText) => CategoryProductService.getCategoriesFiltered({ name: typedText })}
                        getSelectedOption={(loadedOptions) => {
                            if(!categoryId) return null;
                            return loadedOptions.find((option) => option.id === Number(categoryId)) ?? CategoryProductService.getCategoryById(categoryId)
                        }}
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <Button
                        type="button"
                        variant="primary"
                        disabled={isSearching}
                        className="mx-2"
                        onClick={handleClickSearch}
                    >
                        {isSearching ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="situation"
                                aria-hidden="true"
                            />
                            <span className='ml-2'>
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Pesquisar
                            </span>
                        </>}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={clearSearch}
                    >
                        Limpar
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex align-items-center">
                    <PDFDownloadLink
                        document={(
                            <PdfReportDocument
                                title={"Relatório de Movimentações do Estoque"}
                                companyId={user.companyId}
                                bodyData={exportBodyData}
                                headData={headData}
                                totals={exportTotals}
                            />
                        )}
                        fileName={getFileNameWithDate({ filename: 'Estoque-Movimentações', extension: 'pdf' })}
                    >
                        <Tooltip TransitionComponent={Zoom} title="Exportar PDF">
                            <Button
                                className="btn-secondary ml-3"
                                type="button"
                            >
                                <i className="flaticon2-printer p-0"></i>
                            </Button>
                        </Tooltip>
                    </PDFDownloadLink>
                    <Tooltip TransitionComponent={Zoom} title="Exportar Excel">
                        <Button
                            className="btn-info ml-3"
                            type="button"
                            onClick={handleClickExportExcel}
                        >
                            <i className="flaticon2-sheet p-0"></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className="col-lg-12 mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        loadData={loadData}
                        sortable={true}
                        totalCount={totalCountReportRows}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                        lastCell={false}
                        rowsPerPageProp={[5, 15, 25]}
                    />
            </div>

            <div className="col-lg-12 mt-2">
                <div className="card w-50">
                    <div className="card-body">
                        <ReportDetails
                            bodyData={[
                                { text: 'Entradas', value: String(totalEntries) },
                                { text: 'Saídas', value: String(totalOutputs) },
                                { text: 'Valor Entradas', value: formatCurrency(totalValueFromEntries) },
                                { text: 'Valor Saídas', value: formatCurrency(totalValueFromOutputs) },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div >
    );
}