import { makeStyles, MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import React, { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CompanyFormDetails } from "../../components/CompanyForm/CompanyFormDetails";
import useCompanyFormDetails from "../../components/CompanyForm/CompanyFormDetails/useCompanyFormDetails";
import ModalError from "../../components/ModalError";
import ModalSuccess from "../../components/ModalSuccess";
import useMenu from "../../hooks/menu";
import api from "../../services/Api";
import StatusService from "../../services/StatusService";
import UserService from "../../services/UserService";
import {  status, typeIndication, whoPay } from "../../types/Indications";
import { Profile } from "../../types/Profile";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { bypassAuthHeader } from "../../utils/bypassAuthHeader";
import { sanitizeInput, SanitizeInputType } from "../../utils/sanitizeInput";
import useQueryParams from "../../hooks/queryParams";
import { cnpjSearch } from "../../utils/cnpjSearch";
import { ExecuteCreateCompanyPlanFunction, SubscriptionPlanCheckout } from "../../components/SubscriptionPlanCheckout";
import { ValidationBadge } from "../../components/ValidationBadge";
import PermissionMenuService from "../../services/PermissionMenuService";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fontLg: {
        fontSize: 22,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function CreateCompanyFromIndicationLink() {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const { menus } = useMenu();
    const classes = useStyles();
    const { getQueryParam } = useQueryParams();

    const [activeTab, setActiveTab] = useState("details");

    const [isLoadingIndicationAccountant, setIsLoadingIndicationAccountant] = useState(true);
    const [indicationAccountant, setIndicationAccountant] = useState<any>();

    const [subscriptionPlanCheckoutSubmitted, setSubscriptionPlanCheckoutSubmitted] = useState(false);
    const [chosenSubscriptionPlan, setChosenSubscriptionPlan] = useState<SubscriptionPlan>();
    const [permissions, setPermissions] = useState<number[]>([]);
    const [executeCreateCompanyPlan, setExecuteCreateCompanyPlan] = useState<ExecuteCreateCompanyPlanFunction>();

    const [createdCompanyUserId, setCreatedCompanyUserId] = useState(0);

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const [typePeople, setTypePeople] = useState("legal");
    const [name, setName] = useState("");
    const [corporateName, setCorporateName] = useState("");

    const [nameInvalid, setNameInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);

    const {
        cpf, setCpf,
        cnpj, setCnpj,
        municipalRegistration, setMunicipalRegistration,
        stateRegistration, setStateRegistration,
        taxRegime, setTaxRegime,
        cnae, setCnae,
        rntrc, setRntrc,
        responsible, setResponsible,
        cell, setCell,
        phone, setPhone,
        email, setEmail,
        website, setWebsite,
        zipCode, setZipCode,
        address, setAddress,
        district, setDistrict,
        city, setCity,
        state, setState,
        number, setNumber,
        complement, setComplement,
        cpfInvalid, setCpfInvalid,
        cnpjInvalid, setCnpjInvalid,
        responsibleInvalid, setResponsibleInvalid,
        cellInvalid, setCellInvalid,
        emailInvalid, setEmailInvalid,
        zipCodeInvalid, setZipCodeInvalid,
        addressInvalid, setAddressInvalid,
        numberInvalid, setNumberInvalid,
        districtInvalid, setDistrictInvalid,
        cityInvalid, setCityInvalid,
        stateInvalid, setStateInvalid,
        allStates, setAllStates,

        infoAddress,
        getErrorsCompanyDetailsInputs,
    } = useCompanyFormDetails({ typePeople, bypassAuth: true });

    useEffect(() => {
        if (!isLoadingIndicationAccountant && !indicationAccountant) {
            window.location.href = "/";
        }
    }, [indicationAccountant, isLoadingIndicationAccountant]);

    useEffect(() => {
        async function loadIndicationAccountant() {
            setIsLoadingIndicationAccountant(true);

            if (getQueryParam('code') === null) {
                return;
            }

            const accountantIdEncoded = getQueryParam('code') ?? '';

            const accountantId = Buffer.from(accountantIdEncoded, 'base64').toString();

            const accountant = await UserService.getUserById(accountantId, { bypassAuth: true });

            setIndicationAccountant(accountant);

            setIsLoadingIndicationAccountant(false);
        }

        loadIndicationAccountant();
    }, [getQueryParam]);

    function cleanInputs() {
        setName("");
        setCpf("");
        setCorporateName("");
        setCnpj("");
        setMunicipalRegistration("");
        setStateRegistration("");
        setTaxRegime("");
        setCnae("");
        setRntrc("");
    }

    async function CheckCnpj(value: string, type?: string) {
        if (value) {
            const cnpjResponse = await cnpjSearch(value);

            if (cnpjResponse.status === 'ERROR') {
                setMsgError(cnpjResponse.message);
                setShowModalError(true);
                setCnpjInvalid(true);
                setCnpj('');
                return;
            }

            setCorporateName(cnpjResponse.nome || '');
            setName(cnpjResponse.fantasia || '');
            setEmail(cnpjResponse.email || '');
            setPhone(cnpjResponse.telefone || '');

            setZipCode(cnpjResponse.cep || '');
            setAddress(cnpjResponse.logradouro || '');
            setDistrict(cnpjResponse.bairro || '');
            setCity(cnpjResponse.municipio || '');
            setState(cnpjResponse.uf || '');
            setNumber(cnpjResponse.numero || '');
            setComplement(cnpjResponse.complemento || '');
        }
    }

    async function inputsVerify(): Promise<boolean> {
        const detailsError = await getErrorsCompanyDetailsInputs();

        if (detailsError) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError(detailsError);
            setShowModalError(true);
            return false;
        }

        if (!subscriptionPlanCheckoutSubmitted) {
            setActiveTab("subscriptionPlan");
            setIsSubmit(false);
            setMsgError("Assine um plano para completar o seu cadastro!");
            setShowModalError(true);
            return false;
        }

        return true;

    }

    const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        setIsSubmit(true);

        if (!(await inputsVerify())) return;

        try {
            if (!indicationAccountant?.id) {
                throw new Error("Convite inválido");
            }

            const raw = {
                profileId: chosenSubscriptionPlan?.profileId ?? 0,
                permissions: JSON.stringify(permissions),
                isSuper: 'n',
                typePeople: typePeople,
                name: name ? name : null,
                corporateName: corporateName ? corporateName : null,
                cpf: cpf ? sanitizeInput(SanitizeInputType.NUMERIC, cpf) : null,
                cnpj: cnpj ? sanitizeInput(SanitizeInputType.NUMERIC, cnpj) : null,
                stateRegistration: stateRegistration ? stateRegistration : null,
                municipalRegistration: municipalRegistration ? municipalRegistration : null,
                taxRegime: taxRegime ? taxRegime : null,
                cnae: cnae ? cnae : null,
                rntrc: rntrc ? rntrc : null,
                responsible: responsible ? responsible : null,
                cell: cell ? cell : null,
                phone: phone ? phone : null,
                email: email ? email : null,
                website: website ? website : null,
                zipCode: zipCode ? zipCode : null,
                address: address ? address : null,
                number: number ? number : null,
                district: district ? district : null,
                complement: complement ? complement : null,
                city: city ? city : null,
                state: state ? state : null,
            };

            const response = await api.post(`companies`, raw, { headers: bypassAuthHeader });

            const responsibleSplit = responsible.trim().split(' ');
            const firstname = responsibleSplit.shift();
            const lastname = responsibleSplit.join(' ');

            // Criar Usuario
            const rawUser = {
                companyId: response.data.id,
                permissions: JSON.stringify(permissions),
                extraMenuPermissions: JSON.stringify(PermissionMenuService.getMenuExtraPermissionsFromMenuPermissions(JSON.stringify(permissions))),
                firstname: firstname,
                lastname: lastname,
                email,
                cell,
                login: email,
                password: "",
                userProfileId: 0,
                isAdmin: 'y',
                isSuper: 'n',
                theme: 1,
                menuTheme: 1,
                menuType: 'top',
                accessRoute: '',
            };
            const userResponse = await api.post(`users`, rawUser, { headers: bypassAuthHeader });
            setCreatedCompanyUserId(userResponse.data.id);

            // Vincular plano
            if (executeCreateCompanyPlan) {
                await executeCreateCompanyPlan(response.data.id);
            }

            // Criando status padrões
            await StatusService.createDefaultStatus(response.data.id, { bypassAuth: true });

            // Inserir na tabela de indicações
            const rawIndication = {
                companyId: response.data.id,
                accountantId: indicationAccountant?.id ?? 0,
                typeIndication: typeIndication.LINK,
                status: status.ACCEPT,
                whoPay: whoPay.COMPANY,
            };
            await api.post(`/indications`, rawIndication, { headers: bypassAuthHeader });

            setShowModalSuccess(true);
            setMessageSuccess('Cadastro completado com sucesso! Você será redirecionado para criar a sua senha de acesso!')
        } catch (error) {
            console.log('error', error);
        }

        setIsSubmit(false);
    }

    const handleSubmitPlanCheckout = async (executeCreateCompanyPlan: ExecuteCreateCompanyPlanFunction, subscriptionPlan: SubscriptionPlan, expirationDate: string, permissions: number[]) => {
        setChosenSubscriptionPlan(subscriptionPlan);
        setPermissions(permissions);
        setExecuteCreateCompanyPlan(() => executeCreateCompanyPlan);
        setSubscriptionPlanCheckoutSubmitted(true);
    }

    return (
        <div className="d-flex flex-column flex-root">
            <ModalSuccess
                msgModal={messageSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect={`/criar-senha/${createdCompanyUserId}`}
            />
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />
            <div
                className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                id="kt_login"
            >
                <div
                    className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-5 p-lg-5"
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-14-login.jpg")})`,
                    }}
                >
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                            <img
                                alt="Logo"
                                className="max-h-70px"
                                src={toAbsoluteUrl("/media/logos/header/logo-header-gold.png")}
                            />
                        </Link>

                        <div className="flex-column-fluid d-flex flex-column justify-content-center">
                            <h3 className="font-size-h1 mb-5 text-white">
                                Bem-vindo!
                            </h3>
                            <p className="font-weight-lighter text-white">
                                Milliontech, o sistema que faltava para você ter mais LUCRO e LIBERDADE
                            </p>
                        </div>

                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                            <div className="font-weight-bold	text-white">
                                {new Date().getFullYear()} &copy; Milliontech
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                    <div className="mt-30 mt-lg-0 newProductWrapper">
                        <form onSubmit={(evt) => onSubmit(evt)}>
                            <div className="row">
                                <div className="col-12">
                                    <h5>Você recebeu um convite de indicação do seu contador! Preencha as informações da sua empresa para começar a usar a Milliontech!</h5>
                                    <h5>
                                        Já possui acesso à Milliontech?
                                        <Link to={`/?custom_redirect_route=/usuarios/adicionar?accountantId=${indicationAccountant?.id ?? 0}`}> Clique aqui </Link>
                                        para adicionar o seu contador.
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col-lg-3">
                                    <TextField
                                        id="outlined-select-currency"
                                        // required
                                        select
                                        size="small"
                                        label="Tipo de pessoa*"
                                        className={classes.textField}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        value={typePeople}
                                        onChange={e => { setTypePeople(e.target.value); cleanInputs() }}
                                    >
                                        <MenuItem key="0" value="physical" onClick={() => setTypePeople("physical")}>
                                            Pessoa Física
                                        </MenuItem>

                                        <MenuItem key="1" value="legal" onClick={() => setTypePeople("legal")}>
                                            Pessoa Jurídica
                                        </MenuItem>

                                    </TextField>
                                </div>

                                {typePeople === 'physical'
                                    ?
                                    <>
                                        <div className="col-6">
                                            <TextField
                                                label="Nome*"
                                                // required
                                                size="small"
                                                className={classes.error}
                                                margin="normal"
                                                variant="outlined"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                    setNameInvalid(e.target.value ? false : true)
                                                }}
                                                error={nameInvalid}

                                            />
                                        </div>

                                        <div className="col-lg-3 d-flex align-items-start justify-content-end">
                                            <Button
                                                type='submit'
                                                className='mt-4'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Criar Conta
                                                    </span>

                                                </>}


                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-3">
                                            <TextField
                                                value={corporateName}
                                                label="Razão social*"
                                                // required
                                                size="small"
                                                className={classes.error}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setCorporateName(e.target.value)
                                                    setCorporateNameInvalid(e.target.value ? false : true)
                                                }}
                                                error={corporateNameInvalid}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <TextField
                                                value={name}
                                                label="Nome fantasia*"
                                                // required
                                                size="small"
                                                className={classes.error}
                                                margin="normal"
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                    setNameInvalid(e.target.value ? false : true)
                                                }}
                                                error={nameInvalid}
                                            />
                                        </div>

                                        <div className="col-lg-2 d-flex align-items-start justify-content-end">
                                            <Button
                                                type='submit'
                                                className='mt-4'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Salvar
                                                    </span>

                                                </>}


                                            </Button>
                                        </div>
                                    </>
                                }

                            </div>

                            <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="editcompany-form-tabs">
                                <Tab eventKey="details" title="Dados da Empresa">
                                    <CompanyFormDetails
                                        typePeople={typePeople}
                                        cpf={cpf}
                                        setCpf={setCpf}
                                        cnpj={cnpj}
                                        setCnpj={setCnpj}
                                        municipalRegistration={municipalRegistration}
                                        setMunicipalRegistration={setMunicipalRegistration}
                                        stateRegistration={stateRegistration}
                                        setStateRegistration={setStateRegistration}
                                        taxRegime={taxRegime}
                                        setTaxRegime={setTaxRegime}
                                        cnae={cnae}
                                        setCnae={setCnae}
                                        rntrc={rntrc}
                                        setRntrc={setRntrc}
                                        responsible={responsible}
                                        setResponsible={setResponsible}
                                        cell={cell}
                                        setCell={setCell}
                                        phone={phone}
                                        setPhone={setPhone}
                                        email={email}
                                        setEmail={setEmail}
                                        website={website}
                                        setWebsite={setWebsite}
                                        zipCode={zipCode}
                                        setZipCode={setZipCode}
                                        address={address}
                                        setAddress={setAddress}
                                        district={district}
                                        setDistrict={setDistrict}
                                        city={city}
                                        setCity={setCity}
                                        state={state}
                                        setState={setState}
                                        number={number}
                                        setNumber={setNumber}
                                        complement={complement}
                                        setComplement={setComplement}
                                        cpfInvalid={cpfInvalid}
                                        setCpfInvalid={setCpfInvalid}
                                        cnpjInvalid={cnpjInvalid}
                                        setCnpjInvalid={setCnpjInvalid}
                                        responsibleInvalid={responsibleInvalid}
                                        setResponsibleInvalid={setResponsibleInvalid}
                                        cellInvalid={cellInvalid}
                                        setCellInvalid={setCellInvalid}
                                        emailInvalid={emailInvalid}
                                        setEmailInvalid={setEmailInvalid}
                                        zipCodeInvalid={zipCodeInvalid}
                                        setZipCodeInvalid={setZipCodeInvalid}
                                        addressInvalid={addressInvalid}
                                        setAddressInvalid={setAddressInvalid}
                                        numberInvalid={numberInvalid}
                                        setNumberInvalid={setNumberInvalid}
                                        districtInvalid={districtInvalid}
                                        setDistrictInvalid={setDistrictInvalid}
                                        cityInvalid={cityInvalid}
                                        setCityInvalid={setCityInvalid}
                                        stateInvalid={stateInvalid}
                                        setStateInvalid={setStateInvalid}
                                        allStates={allStates}
                                        setAllStates={setAllStates}
                                        CheckCnpj={CheckCnpj}
                                        infoAddress={infoAddress}
                                    />
                                </Tab>

                                <Tab eventKey="subscriptionPlan" title="Plano">
                                    <div className="row mt-3">
                                        {!subscriptionPlanCheckoutSubmitted && (
                                            <div className="col-12">
                                                <SubscriptionPlanCheckout
                                                    onSubmit={handleSubmitPlanCheckout}
                                                    companyId={0}
                                                    title={'Selecione uma das opções de plano abaixo'}
                                                    selectFirstSubscriptionPlan={false}
                                                />
                                            </div>
                                        )}

                                        {subscriptionPlanCheckoutSubmitted && (
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <span>
                                                    <ValidationBadge label="" variant="success" />
                                                    Plano assinado com sucesso!
                                                </span>
                                                <Button
                                                    variant="primary"
                                                    className="btn-light-primary ml-3"
                                                    onClick={() => {
                                                        setChosenSubscriptionPlan(undefined);
                                                        setPermissions([]);
                                                        setExecuteCreateCompanyPlan(undefined);
                                                        setSubscriptionPlanCheckoutSubmitted(false);
                                                    }}
                                                    disabled={isSubmit}
                                                >
                                                    <i className="flaticon2-edit p-0"></i>
                                                    Alterar
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}