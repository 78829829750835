import { format } from "date-fns";
import { Entry } from "../types/Entry";
import { convertProductUnitToEnum } from "../utils/convertProductUnitToEnum";
import { formatCurrency, formatToFloat } from "../utils/formatCurrency";
import { formatNcm, sanitizeNcm } from "../utils/formatNcm";
import { formatZipcode } from "../utils/formatZipcode";
import { freightModalityKeyById } from "../utils/freightModality";
import api from "./Api";
import StockService from "./StockService";
import { Product } from "../hooks/products";

type ImportEntryFromXmlParams = {
    xmlFile?: File;
    uploadedXmlFile?: {
        content: string;
        path: string;
    }
    extractedXmlInformation?: ImportXmlInformation;
    stockLocationId: number | null;
    launchInStock: boolean;
    selectedCompany: any;
}

type CreateEntryParams = {
    total: number,
    entryDate: string;
    issuanceDate?: string;
    freight?: number;
    discount?: number;
    products: string;
    installments: string;
    supplierId?: number;
    sellerId?: number;
    bcIcms?: number;
    valueIcms?: number;
    bcIcmsSt?: number;
    valueIcmsSt?: number;
    ipiPrice?: number;
    valueExpenses?: number;
    valueSafe?: number;
    valuePis?: number;
    valueCofins?: number;
    valueIrrf?: number;
    valueCsll?: number;
    valueSocial?: number;
    model?: string | null;
    nfeSerial?: string | number | null;
    nfeNumber?: string | number | null;
    nfeKey?: string | null;
    discountPercentage?: number;
    totalWeight?: number;
    typeFreigth?: string;
    carrier?: string;
    application?: string;
    centerCost?: string;
    comments?: string | null;
    internalComments?: string;
    nfXml?: string;
    purchaseOrderId?: number;
    commentsSituation?: string;
};

type DispatchStatusChangeNotificationAndEmailParams = {
    entryId: number;
    lastStatusHistory: any;
    newStatusHistory: any;
};

type ImportXmlInformation = {
    generalInfo: NfInformation;
    issuer: NfIssuer;
    issuerExists: boolean;
    carrier?: NfCarrier;
    carrierExists: boolean;
    products: NfProduct[];
};

export type NfInformation = {
    nature: string;
    model: string | null;
    nfSeries: number | null;
    nfNumber: number | null;
    nfKey: string | null;
    issuanceDate: Date;
    version: string;
    accessKey: string;
    protocol: string;
    entryDate: Date;
    status: {
        code: number;
        description: string;
    };
    bcIcms: string;
    valueIcms: string;
    bcIcmsSt: string;
    valueIcmsSt: string;
    valueIpi: string;
    valueProducts: string;
    discount: string;
    freight: string;
    liquidWeight: string;
    grossWeight: string;
    totalWeight: string;
    totalValue: string;
    valuePis: number;
    valueCofins: number;
    valueIrrf: number;
    valueCsll: number;
    valueSocial: number;
    valueSafe: number;
    valueExpenses: number;
    //Transporte
    modalityFreight: number;
    comments: string | null;
};

export type NfIssuer = {
    id?: number;
    corporateName: string,
    fantasyName: string,
    cpfCnpj: string,
    stateRegistration: string,
    address: string,
    number: string,
    city: string,
    uf: string,
    phone: string,
    district: string,
    zipcode: string,
    complement: string,
    taxRegime: string,
};

export type NfProduct = {
    id?: number;
    code: string;
    name: string;
    unit: string;
    ncm: string;
    saleValue: string;
    cfop: string;
    quantity: number;
    totalValue: string;
    codeGtinEan: string | null;
    productExists: boolean;
    replaceProductId: number | null;
};

export type NfCarrier = {
    name: string;
    cpfCnpj: string,
    stateRegistration: string,
    address: string,
    city: string,
    uf: string,
};

class EntryService {
    async importEntryFromXml({
        xmlFile,
        uploadedXmlFile,
        extractedXmlInformation,
        stockLocationId,
        launchInStock,
        selectedCompany,
    }: ImportEntryFromXmlParams) {
        if (!xmlFile && !uploadedXmlFile) {
            throw new Error('At least one of the params [xmlFile, uploadedXmlFile] is required');
        }

        const xmlFileContentString = xmlFile ? await xmlFile.text() : (uploadedXmlFile?.content);
        const extractedInformation = extractedXmlInformation ?? (await this.readEntryInformationFromXml(xmlFileContentString ?? '', selectedCompany))

        const {
            generalInfo: nfInformation,
            issuer,
            issuerExists,
            carrier,
            carrierExists,
            products,
        } = extractedInformation;

        let issuerId = issuer.id;

        // Criar o fornecedor
        if (!issuerExists) {
            let typePeople = undefined;
            if (issuer.cpfCnpj) {
                typePeople = issuer.cpfCnpj.length === 11 ? 'physical' : 'legal';
            }

            const issuerHasStateRegistration = issuer.stateRegistration || issuer.stateRegistration === 'ISENTO';

            const supplierRaw = {
                typePeople,
                name: typePeople === 'physical' && issuer.corporateName ? issuer.corporateName : undefined,
                corporateName: typePeople === 'legal' && issuer.corporateName ? issuer.corporateName : undefined,
                fantasyName: issuer.fantasyName || undefined,
                cpf: issuer.cpfCnpj && typePeople === 'physical' ? issuer.cpfCnpj : undefined,
                cnpj: issuer.cpfCnpj && typePeople === 'legal' ? issuer.cpfCnpj : undefined,
                stateRegistration: issuer.stateRegistration || undefined,
                exempt: !issuerHasStateRegistration ? 'y' : 'n',
                icmsTaxpayer: issuerHasStateRegistration ? 'y' : 'n',
                phone: issuer.phone || undefined,
                registrationStatus: 'active',
                typeRegister: 'supplier',
                cell: '',
                email: '',
                finalCostumer: 'n',
                taxRegime: issuer.taxRegime || undefined,
            };

            const { data: createdSupplier } = await api.post("/customer", supplierRaw);

            issuerId = createdSupplier.id;

            const supplierAddressRaw = {
                customerId: createdSupplier.id,
                zipcode: issuer.zipcode,
                publicPlace: issuer.address,
                number: issuer.number !== 'SN' ? Number(issuer.number) : null,
                district: issuer.district,
                city: issuer.city,
                state: issuer.uf,
                country: 'Brasil',
                defaultAddress: 'y',
                charge: 'n',
                delivery: 'n',
                withdrawal: 'n',
                complement: 'n',
                reference: issuer.complement,
                typePeople: createdSupplier.typePeople,
                name: createdSupplier.name,
                cpf: createdSupplier.cpf,
                cnpj: createdSupplier.cnpj,
                phone: createdSupplier.phone,
                corporateName: createdSupplier.corporateName,
                stateRegistration: createdSupplier.stateRegistration,
            };

            await api.post("/address", supplierAddressRaw);
        }

        const nfProducts = products;

        // Criar Produtos
        for (const product of nfProducts) {
            if (!product.productExists) {
                const productRaw = {
                    name: product.name,
                    code: product.code,
                    hasVariation: 'n',
                    unit: product.unit,
                    ncm: product.ncm,
                    stockLocation: stockLocationId,
                    stockMin: 0,
                    stockMax: 100,
                    stockInitial: 0,
                    currentStock: 0,
                    saleValue: '0,00',
                    costValue: product.saleValue.replace('R$ ', '').replace('.', ''),
                    situation: 'active',
                    type: 'product',
                    codeGtinEan: product.codeGtinEan,
                    taxableUnit: product.unit,
                    taxableCount: 1,
                    taxableValue: 0,
                    taxBenefitCode: '',
                    cestCode: '',
                    federal: '',
                    state: '',
                    isKit: 'n',
                    kit: '[]',
                    grossWeight: '',
                    liquidColumn: '',
                    productSize: '',
                    productOrigin: '',
                    ordersNumber: '',
                    typeClassification: '',
                    note: '',
                    icmsRate: '',
                    icmsStRate: '',
                    cstRate: '',
                    mvaRate: '',
                    mvaAdjustedRate: '',
                    deferralRate: '',
                    ipiRate: '',
                    pisRate: '',
                    cofinsRate: '',
                };

                const { data: createdProduct } = await api.post("/products", productRaw);

                product.id = createdProduct.id;

                await StockService.createStockHistoric({
                    productId: Number(createdProduct.id),
                    stockLocationId: Number(createdProduct.stockLocation),
                    productValue: formatToFloat(createdProduct.saleValue),
                    type: 'Balanço',
                    observation: 'Produto criado e inserido no histórico',
                    total: formatToFloat(createdProduct.stockInitial),
                    totalLocation: formatToFloat(createdProduct.stockInitial),
                    hasVariation: 'n',
                });
            }
        }

        // Criar transportadora
        if (carrier && !carrierExists) {
            let typePeople = undefined;
            if (carrier.cpfCnpj) {
                typePeople = carrier.cpfCnpj.length === 11 ? 'physical' : 'legal';
            }

            const carrierHasStateRegistration = carrier.stateRegistration || carrier.stateRegistration === 'ISENTO';

            const carrierRaw = {
                typePeople,
                name: typePeople === 'physical' && carrier.name ? carrier.name : '',
                corporateName: typePeople === 'legal' && carrier.name ? carrier.name : '',
                fantasyName: carrier.name || undefined,
                cpf: carrier.cpfCnpj && typePeople === 'physical' ? carrier.cpfCnpj : undefined,
                cnpj: carrier.cpfCnpj && typePeople === 'legal' ? carrier.cpfCnpj : undefined,
                stateRegistration: carrierHasStateRegistration ? carrier.stateRegistration : '',
                city: carrier.city,
                state: carrier.uf,
                address: carrier.address,
            };

            await api.post("/carriers", carrierRaw);
        }

        const todayDateString = format(Date.now(), "yyyy-MM-dd");

        // Criar Entrada de Mercadoria
        const productsMapped = await Promise.all(nfProducts.map(async (nfProduct) => {
            const { data: productInstance } = await api.get<Product>(`products/${nfProduct.id}`);

            return {
                amount: nfProduct.quantity,
                total: formatToFloat(nfProduct.totalValue),
                unitaryValue: formatToFloat(nfProduct.saleValue),
                cfop: nfProduct.cfop,
                icms: 0,
                ipi: 0,
                ncm: sanitizeNcm(nfProduct.ncm),
                INSTANCE: productInstance
            };
        }));

        const createdEntry = await this.createEntry({
            model: nfInformation.model,
            nfeSerial: nfInformation.nfSeries,
            nfeNumber: nfInformation.nfNumber,
            nfeKey: nfInformation.nfKey,
            total: formatToFloat(nfInformation.totalValue),
            entryDate: todayDateString,
            issuanceDate: format(nfInformation.issuanceDate, "yyyy-MM-dd"),
            freight: formatToFloat(nfInformation.freight),
            discount: formatToFloat(nfInformation.discount),
            products: JSON.stringify(productsMapped),
            installments: JSON.stringify([]),
            supplierId: issuerId,
            bcIcms: formatToFloat(nfInformation.bcIcms),
            valueIcms: formatToFloat(nfInformation.valueIcms),
            bcIcmsSt: formatToFloat(nfInformation.bcIcmsSt),
            valueIcmsSt: formatToFloat(nfInformation.valueIcmsSt),
            ipiPrice: formatToFloat(nfInformation.valueIpi),
            valueExpenses: nfInformation.valueExpenses,
            valueSafe: nfInformation.valueSafe,
            valuePis: nfInformation.valuePis,
            valueCofins: nfInformation.valueCofins,
            valueIrrf: nfInformation.valueIrrf,
            valueCsll: nfInformation.valueCsll,
            valueSocial: nfInformation.valueSocial,
            typeFreigth: freightModalityKeyById(nfInformation.modalityFreight),
            carrier: carrier && carrier.name ? carrier.name : undefined,
            comments: nfInformation.comments,
            nfXml: uploadedXmlFile ? uploadedXmlFile.path : undefined
        });

        //Lançar no estoque
        if (launchInStock) {
            StockService.launchInStock({
                products: JSON.parse(createdEntry.products),
                typeOrder: 'entry',
                isOs: false,
                orderSelected: String(createdEntry.id),
                stockLocationMove: String(stockLocationId),
            });
        }

        // Upload xml
        let uploadSuccess = true;

        if (xmlFile) {
            try {
                uploadSuccess = await this.uploadEntryXml(Number(createdEntry.id), xmlFile);
            } catch (error) {
                uploadSuccess = false;
            }
        }
    }

    async readEntryInformationFromXml(xmlContent: string, selectedCompany: any): Promise<ImportXmlInformation> {
        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(xmlContent, "text/xml");

        const receiverCpf = xmlDOM.querySelector('dest CPF')?.textContent;
        const receiverCnpj = xmlDOM.querySelector('dest CNPJ')?.textContent;

        const companyIsLegal = selectedCompany?.typePeople === 'legal';
        if (
            (companyIsLegal && selectedCompany.cnpj !== receiverCnpj)
            || (!companyIsLegal && selectedCompany.cpf !== receiverCpf)
        ) {
            throw 'CPF/CNPJ do destinatário não pertence à empresa/filial atual';
        }

        // Informações
        const nature = xmlDOM.getElementsByTagName('natOp')[0]?.textContent ?? '';
        const model = xmlDOM.getElementsByTagName('mod')[0]?.textContent ?? '';
        const nfSeries = xmlDOM.getElementsByTagName('serie')[0]?.textContent ?? '';
        const nfNumber = xmlDOM.getElementsByTagName('nNF')[0]?.textContent ?? '';
        const nfKey = xmlDOM.getElementsByTagName('chNFe')[0]?.textContent ?? '';
        const issuanceDate = xmlDOM.getElementsByTagName('dhEmi')[0]?.textContent ?? '';
        const version = xmlDOM.getElementsByTagName('verProc')[0]?.textContent ?? '';
        const comments = xmlDOM.querySelector('infAdic infCpl')?.textContent ?? '';

        // Info de autorização
        const accessKey = xmlDOM.getElementsByTagName('chNFe')[0]?.textContent ?? '';
        const protocol = xmlDOM.getElementsByTagName('nProt')[0]?.textContent ?? '';
        const entryDate = xmlDOM.getElementsByTagName('dhRecbto')[0]?.textContent ?? '';
        const statusCode = Number(xmlDOM.getElementsByTagName('cStat')[0]?.textContent ?? '0');
        const statusDescription = xmlDOM.getElementsByTagName('xMotivo')[0]?.textContent ?? '';

        // Dados do emitente
        const issuerCorporateName = xmlDOM.querySelector('emit xNome')?.textContent ?? '';
        const issuerFantasyName = xmlDOM.querySelector('emit xFant')?.textContent ?? '';
        const issuerCpf = xmlDOM.querySelector('emit CPF')?.textContent ?? '';
        const issuerCnpj = xmlDOM.querySelector('emit CNPJ')?.textContent ?? '';
        const issuerStateRegistration = xmlDOM.querySelector('emit IE')?.textContent ?? '';
        const issuerAddress = xmlDOM.querySelector('emit enderEmit xLgr')?.textContent ?? '';
        const issuerNumber = xmlDOM.querySelector('emit enderEmit nro')?.textContent ?? '';
        const issuerCity = xmlDOM.querySelector('emit enderEmit xMun')?.textContent ?? '';
        const issuerUf = xmlDOM.querySelector('emit enderEmit UF')?.textContent ?? '';
        const issuerComplement = xmlDOM.querySelector('emit enderEmit xCpl')?.textContent ?? '';
        const issuerPhone = xmlDOM.querySelector('emit enderEmit fone')?.textContent ?? '';
        const issuerDistrict = xmlDOM.querySelector('emit enderEmit xBairro')?.textContent ?? '';
        const issuerZipcode = formatZipcode(xmlDOM.querySelector('emit enderEmit CEP')?.textContent ?? '');
        const issuerCRT = xmlDOM.querySelector('emit CRT')?.textContent ?? ''
        const issuerTaxRegime = issuerCRT && issuerCRT === '1' ? 'simple national' : (issuerCRT && issuerCRT === '3' ? 'normal regime' : '');

        let issuerId: number | undefined = undefined;
        let issuerExists = false;

        if (issuerCpf) {
            const { data: cpfExists } = await api.get(`customer/cpfExist/${issuerCpf}`);
            issuerExists = cpfExists.length > 0;
            issuerId = cpfExists[0]?.id;
        } else if (issuerCnpj) {
            const { data: cnpjExists } = await api.get(`customer/cnpjExist/${issuerCnpj}`);
            issuerExists = cnpjExists.length > 0;
            issuerId = cnpjExists[0]?.id;
        }

        // Produtos
        const nodeArray: Element[] = [];
        const products: NfProduct[] = [];
        xmlDOM.querySelectorAll('det').forEach((node) => {
            nodeArray.push(node);
        });

        for (const productNode of nodeArray) {
            const productCode = productNode.querySelector('prod cProd')?.textContent ?? '';
            const productGtin = productNode.querySelector('prod cEAN')?.textContent ?? ''

            const { data: productExists } = await api.get(`products/code/${productCode}`);

            products.push({
                id: productExists?.id,
                code: productCode,
                name: productNode.querySelector('prod xProd')?.textContent ?? '',
                unit: convertProductUnitToEnum(productNode.querySelector('prod uCom')?.textContent ?? ''),
                ncm: formatNcm(productNode.querySelector('prod NCM')?.textContent ?? ''),
                saleValue: formatCurrency(formatToFloat(productNode.querySelector('prod vUnCom')?.textContent ?? '')),
                cfop: productNode.querySelector('prod CFOP')?.textContent ?? '',
                quantity: Number(productNode.querySelector('prod qCom')?.textContent ?? ''),
                totalValue: formatCurrency(formatToFloat(productNode.querySelector('prod vProd')?.textContent ?? '')),
                codeGtinEan: productGtin && productGtin !== 'SEM GTIN' ? productGtin : null,
                productExists: !!productExists,
                replaceProductId: null,
            });
        }

        // Totais
        const bcIcms = formatCurrency(formatToFloat(xmlDOM.querySelector('total vBC')?.textContent));
        const valueIcms = formatCurrency(formatToFloat(xmlDOM.querySelector('total vICMS')?.textContent));
        const bcIcmsSt = formatCurrency(formatToFloat(xmlDOM.querySelector('total vBCST')?.textContent));
        const valueIcmsSt = formatCurrency(formatToFloat(xmlDOM.querySelector('total vST')?.textContent));
        const valueIpi = formatCurrency(formatToFloat(xmlDOM.querySelector('total vIPI')?.textContent));
        const valueProducts = formatCurrency(formatToFloat(xmlDOM.querySelector('total vProd')?.textContent));
        const discount = formatCurrency(formatToFloat(xmlDOM.querySelector('total vDesc')?.textContent));
        const freight = formatCurrency(formatToFloat(xmlDOM.querySelector('total vFrete')?.textContent));
        const liquidWeight = formatToFloat(xmlDOM.querySelector('transp vol pesoL')?.textContent);
        const grossWeight = formatToFloat(xmlDOM.querySelector('transp vol pesoB')?.textContent);
        const totalWeight = formatCurrency(liquidWeight + grossWeight);
        const totalValue = formatCurrency(formatToFloat(xmlDOM.querySelector('total vNF')?.textContent));

        const valuePis = formatToFloat(xmlDOM.querySelector('total vRetPIS')?.textContent);
        const valueCofins = formatToFloat(xmlDOM.querySelector('total vRetCOFINS')?.textContent);
        const valueIrrf = formatToFloat(xmlDOM.querySelector('total vBCIRRF')?.textContent);
        const valueCsll = formatToFloat(xmlDOM.querySelector('total vRetCSLL')?.textContent);
        const valueSocial = formatToFloat(xmlDOM.querySelector('total vRetPrev')?.textContent);
        const valueSafe = formatToFloat(xmlDOM.querySelector('total vSeg')?.textContent);
        const valueExpenses = formatToFloat(xmlDOM.querySelector('total vOutro')?.textContent);

        // Transporte
        const modalityFreight = Number(xmlDOM.querySelector('transp modFrete')?.textContent ?? '9');

        const carrierName = xmlDOM.querySelector('transp transporta xNome')?.textContent ?? '';
        const carrierCpf = xmlDOM.querySelector('transp transporta CPF')?.textContent ?? '';
        const carrierCnpj = xmlDOM.querySelector('transp transporta CNPJ')?.textContent ?? '';
        const carrierStateRegistration = xmlDOM.querySelector('transp transporta IE')?.textContent ?? '';
        const carrierAddress = xmlDOM.querySelector('transp transporta xEnder')?.textContent ?? '';
        const carrierCity = xmlDOM.querySelector('transp transporta xMun')?.textContent ?? '';
        const carrierUf = xmlDOM.querySelector('transp transporta UF')?.textContent ?? '';

        let carrierExists = false;

        if (carrierCpf) {
            const { data: cpfExists } = await api.get(`carriers/cpfExist/${carrierCpf}`);
            carrierExists = cpfExists.length > 0;
        } else if (carrierCnpj) {
            const { data: cnpjExists } = await api.get(`carriers/cnpjExist/${carrierCnpj}`);
            carrierExists = cnpjExists.length > 0;
        }

        return {
            generalInfo: {
                //Geral
                nature,
                model: model || null,
                nfSeries: nfSeries ? Number(nfSeries) : null,
                nfNumber: nfNumber ? Number(nfNumber) : null,
                nfKey: nfKey || null,
                issuanceDate: new Date(issuanceDate),
                version,
                accessKey,
                protocol,
                entryDate: new Date(entryDate),
                status: {
                    code: statusCode,
                    description: statusDescription,
                },
                //Totais
                bcIcms,
                valueIcms,
                bcIcmsSt,
                valueIcmsSt,
                valueIpi,
                valueProducts,
                discount,
                freight,
                liquidWeight: formatCurrency(liquidWeight),
                grossWeight: formatCurrency(grossWeight),
                totalWeight,
                totalValue,
                valuePis,
                valueCofins,
                valueIrrf,
                valueCsll,
                valueSocial,
                valueSafe,
                valueExpenses,
                //Transporte
                modalityFreight,
                comments: comments || null,
            },
            issuer: {
                id: issuerId,
                corporateName: issuerCorporateName,
                fantasyName: issuerFantasyName,
                cpfCnpj: issuerCpf || issuerCnpj,
                stateRegistration: issuerStateRegistration,
                address: issuerAddress,
                number: issuerNumber,
                city: issuerCity,
                uf: issuerUf,
                phone: issuerPhone,
                district: issuerDistrict,
                complement: issuerComplement,
                zipcode: issuerZipcode,
                taxRegime: issuerTaxRegime,
            },
            issuerExists,
            carrier: carrierName ? {
                name: carrierName,
                cpfCnpj: carrierCpf || carrierCnpj,
                stateRegistration: carrierStateRegistration,
                address: carrierAddress,
                city: carrierCity,
                uf: carrierUf,
            } : undefined,
            carrierExists,
            products,
        };
    }

    async createEntry(data: CreateEntryParams) {
        const situation: any[] = [];

        situation.push({
            dateSituation: format(Date.now(), "yyyy-MM-dd"),
            commentsSituation: data.commentsSituation ?? "Criação da entrada",
            statusSituation: "open",
        });

        const raw = {
            total: data.total,
            status: "open",
            entryDate: data.entryDate,
            issuanceDate: data.issuanceDate || null,
            freight: data.freight || 0,
            discount: data.discount || 0,
            products: data.products,
            situation: JSON.stringify(situation),
            installments: data.installments || '[]',
            supplierId: data.supplierId || null,
            sellerId: data.sellerId || null,
            bcIcms: data.bcIcms || 0,
            valueIcms: data.valueIcms || 0,
            bcIcmsSt: data.bcIcmsSt || 0,
            valueIcmsSt: data.valueIcmsSt || 0,
            ipiPrice: data.ipiPrice || 0,
            valueExpenses: data.valueExpenses || 0,
            valueSafe: data.valueSafe || 0,
            valuePis: data.valuePis || 0,
            valueCofins: data.valueCofins || 0,
            valueIrrf: data.valueIrrf || 0,
            valueCsll: data.valueCsll || 0,
            valueSocial: data.valueSocial || 0,
            movedToStock: 'n',
            model: data.model,
            nfeSerial: data.nfeSerial,
            nfeNumber: data.nfeNumber,
            nfeKey: data.nfeKey,
            discountPercentage: data.discountPercentage,
            totalWeight: data.totalWeight,
            typeFreigth: data.typeFreigth || '',
            carrier: data.carrier || '',
            application: data.application || '',
            centerCost: data.centerCost,
            comments: data.comments,
            internalComments: data.internalComments,
            nfXml: data.nfXml,
            purchaseOrderId: data.purchaseOrderId,
        }

        const { data: createdEntry } = await api.post<Entry>("entry", raw);

        return createdEntry;
    }

    async dispatchStatusChangeNotificationAndEmail({
        entryId,
        lastStatusHistory,
        newStatusHistory,
    }: DispatchStatusChangeNotificationAndEmailParams) {
        try {
            await api.post(`entry/statusNotification`, {
                entryId,
                lastStatusHistory,
                newStatusHistory,
            });
        } catch (error) {
            console.log(error);
        }
    }

    getApplicationFormatted(application: string) {
        switch (application) {
            case 'merchandise for resale':
                return 'Mercadoria para Revenda';

            case 'raw material':
                return 'Matéria-Prima';

            case 'Material for use and consumption':
                return 'Material de Uso e Consumo';

            case 'permanent assets':
                return 'Ativo Imobilizado';

            case 'others':
                return 'Outras';

            default:
                return '';
        }
    }

    private async uploadEntryXml(entryId: number, xmlFile: File): Promise<boolean> {
        const file = xmlFile;
        if (!file) {
            return false;
        }

        var formFile = new FormData();
        formFile.append('file', file, file.name);

        const response = await api.post('/file', formFile);
        const raw = {
            nfXml: response.data
        };

        await api.put(`entry/${entryId}`, raw);

        return true;
    }
}

export default new EntryService();