import React, { ButtonHTMLAttributes } from 'react'

interface UnderlineLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  	text: string;
}

export function UnderlineLink({ text, ...rest }: UnderlineLinkProps) {
  	return (
		<div className="col-md-12" style={{ marginLeft: '6px' }}>
			<button {...rest} type="button" className="btn btn-link-secondary p-0"><ins>{text}</ins></button>
		</div>
  	)
}