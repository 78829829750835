import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NewProduct } from '../pages/Product/New-Product';
import { ListProducts } from '../pages/List-Products';
import { StockProduct } from '../pages/Product/Stock-Products';
import { EditProducts } from '../pages/Product/EditProducts/Edit-Products';
import { ImportExcelProduct } from '../pages/Product/ImportExcel-Product';

export function Products() {

   return (
      <Switch>
         <Route path='/produtos/importar-excel' component={ImportExcelProduct} />
         <Route path='/produtos/adicionar' component={NewProduct} />
         <Route path='/produtos/:id/estoque' component={StockProduct} />
         <Route path='/produtos/:id' component={EditProducts} />
         <Route path='/produtos' component={ListProducts} />
      </Switch>
   );
}
