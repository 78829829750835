export function getSituationText(situation: string = '') {
    switch (situation) {
        case 'open':
            return 'Em aberto';
        case 'pending':
            return 'Pendente';
        case 'progress':
            return 'Em andamento';
        case 'sent':
            return 'Enviado';
        case 'asnwered':
            return 'Respondido';
        case 'chosen':
            return 'Escolhido';
        case 'paid':
            return 'Pago'
        case 'late':
            return 'Vencido'
        case 'expiring':
            return 'Vencendo'
        case 'attended':
            return 'Finalizado';
        case 'canceled':
            return 'Cancelado';
        case 'failed':
            return 'Falho';
        case 'scheduled':
            return 'Agendado';
    
        default:
            return situation;
    }
}

export function getSituationFromText(situation: string = '') {
    switch (situation) {
        case 'Em aberto':
            return 'open';
        case 'Pendente':
            return 'pending';
        case 'Em andamento':
            return 'progress';
        case 'Em trâmite':
            return 'pending';
        case 'Enviado':
            return 'sent';
        case 'Respondido':
            return 'asnwered';
        case 'Escolhido':
            return 'chosen';
        case 'Pago':
            return 'paid'
        case 'Vencido':
            return 'late'
        case 'Atrasado':
            return 'late'
        case 'Vencendo':
            return 'expiring'
        case 'Atendido':
            return 'attended';
        case 'Finalizado':
            return 'attended';
        case 'Vendido':
            return 'sold';
        case 'Cancelado':
            return 'canceled';
        case 'Falho':
            return 'failed';
        case 'Agendado':
            return 'scheduled';
        case 'Devolvido':
            return 'returned';

        default:
            return 'open';
    }
}