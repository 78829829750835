import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ListBankReconciliation } from '../pages/BankReconciliation/List-BankReconciliation';
import { NewBankReconciliation } from '../pages/BankReconciliation/New-BankReconciliation';

export function BankReconciliation() {
    
    return (
        <Switch>
            <Route path="/conciliacao-bancaria/conciliados" component={ListBankReconciliation}/>
            <Route path="/conciliacao-bancaria" component={NewBankReconciliation}/>
        </Switch>
    );
}