import React, {
    Dispatch,
    useReducer,
    createContext,
    useContext,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Product as ProductHook } from '../../hooks/products';

export type Product = {
    amount: number;
    totalValue: number;
    unitaryValue: number;
    notes: string;
    INSTANCE: ProductHook;
    hasError?: boolean;
};

type ProductsActionTypes = "CHANGE" | "ADD" | "DELETE" | "INITIAL";
type ProductsAction = {
    type: ProductsActionTypes;
    payload?: {
        index: number;
        product?: Product;
        products?: Product[];
    };
}

const ActionObjects = {
    CHANGE: "CHANGE",
    CHANGE_VALUE: "CHANGE_VALUE",
    CHANGE_DATE: "CHANGE_DATE",
    DELETE: "DELETE",
    ADD: "ADD",
    GENERATE: "GENERATE",
    INITIAL: "INITIAL",
};

const productsReducer = (state: Product[], { type, payload }: ProductsAction): Product[] => {
    const aux = state;

    if (type === ActionObjects.INITIAL) {
        if (!payload || !payload.products) {
            throw new Error(`Type \'${ActionObjects.INITIAL}\' must contain payload params`);
        }

        return [...payload.products];
    }

    if (type === ActionObjects.CHANGE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain payload params`);
        }

        const { product, index } = payload;
        
        if (!product) {
            throw new Error(`Type \'${ActionObjects.CHANGE}\' must contain product within payload`);
        }

        aux[index] = product;

        return [...aux];
    }
   
    if (type === ActionObjects.DELETE) {
        if (!payload) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        if (!payload.index) {
            throw new Error(`Type \'${ActionObjects.DELETE}\' must contain payload params`);
        }

        const filteredState = state.filter((_, i) => i !== payload.index);

        return [...filteredState];
    }

    if (type === ActionObjects.ADD) {
        const newState: Product = {
            INSTANCE: {} as ProductHook,
            amount: 0,
            totalValue: 0,
            unitaryValue: 0,
            notes: '',
        }

        return [...aux, newState];
    }
 
    return [...aux];
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

interface ServiceContractContextData {
    services: Product[];
    dispatchServices: Dispatch<ProductsAction>;
    classes: ClassNameMap<"container" | "dense" | "menu" | "textField" | "formControl">;
}

const ServiceContractContext = createContext({} as ServiceContractContextData);

const ServiceContractProvider: React.FC = ({ children }) => {

    const [services, dispatchServices] = useReducer(productsReducer, [
        {
            amount: 0,
            totalValue: 0,
            unitaryValue: 0,
            notes: '',
            INSTANCE: {} as ProductHook,
        }
    ]);

    const classes = useStyles();

    return (
        <ServiceContractContext.Provider
            value={{
                classes,
                services,
                dispatchServices,
            }}
        >
            { children }
        </ServiceContractContext.Provider>
    );
};

const useProductsInstallments = () => {
    const context = useContext(ServiceContractContext);

    if (!context) {
        throw new Error();
    }

    return context;
};

export { ServiceContractProvider, useProductsInstallments };
