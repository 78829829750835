import React, {useEffect, useState, useCallback} from 'react';
import { useParams } from 'react-router-dom';

import axios from "../../services/Api";

import {
    InputAdornment,
    Paper,
    Table,
    TableRow,
    MenuItem,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    TablePagination,
    FormControlLabel,
    makeStyles,
    TableContainer,
} from '@material-ui/core';

import { Badge, Button, Modal } from 'react-bootstrap';
import { NumericFormat } from '../../components/NumericFormat';

import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
} from '../../components/ListWithModalChangeSituation';

import '../../style.css';
import { useSelector } from 'react-redux';
import ProductService from '../../services/ProductService';
import { formatNumberToString, formatToFloat } from '../../utils/formatCurrency';

type Product = {
    id: number;
    stockLocation: number;
    stockMin: number;
    stockMax: number;
    currentStock: number;
 };

 type currentCountStockLocations = {
    entry?: string;
    output?: string;
    balance?: string;
 }

type StockLocations = {
    id: number;
    name: string;
    description: string;
    entry: number,
    output: number,
    totalInStock: number,
    totalInConsignment: number,
    balanceTotal: number,
    minExceed: boolean;
    maxExceed: boolean;
};

type UrlParam = {
    id: string
}

type Variation = {
    id: number;
    name: string;
    stockMin: number;
    stockMax: number;
    stockInitial: number;
    currentStock: number;
    stockLocation: number;
}

type Historic = {
    id: number;
    date: string;
    entry: number;
    output: number;
    observation: string;
    value: number;
    type: string;
    totalLocation: string;
    total: string;
    hasVariation: string;
    variationId: number;
    productId: number;
    stockId: number;
    stockName: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "date", value: "Data" },
    { reference: "entry", value: "Entrada" },
    { reference: "output", value: "Saída" },
    { reference: "observation", value: "Observações" },
    { reference: "unitValue", value: "Valor unitário" },
    { reference: "local", value: "Local" },
    { reference: "type", value: "Tipo" },
    { reference: "totalLocal", value: "Local Total" },
    { reference: "total", value: "Total" },
];

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    btlr: {
        borderTopLeftRadius: 0
    },
    absoluteNav: {
        position: "absolute",
        top: "-50px",
        left: 0,
        background: "#fff",
        borderRadius: "0.42rem 0.42rem 0px 0px",
        height: "50px",
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

export function StockProduct() {
    const { user } = useSelector((state: any) => state.auth);
    
    const classes = useStyles();
    
    const [nameProduct, setNameProduct] = useState("");
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [stockLocations, setStockLocations] = useState<StockLocations[]>();
    const [currentProduct, setCurrentProduct] = useState<Product>();
    const [typeVariation, setTypeVariation] = useState("");
    const [variations, setVariations] = useState<Variation[]>();
    const [variationSelected, setVariationSelected] = useState<Variation>();
    const [historic, setHistoric] = useState<Historic[]>([]);
    const [totalInStock, setTotalInStock] = useState("0,00");
    const [totalInConsignment, setTotalInConsignment] = useState("0,00");
    const [totalLocations, setTotalLocations] = useState("0,00");
    const [minTotalExceed, setMinTotalExceed] = useState(false);
    const [maxTotalExceed, setMaxTotalExceed] = useState(false);

    const [typeMove, setTypeMove] = useState("");
    const [dateMove, setDateMove] = useState("");
    const [countMove, setCountMove] = useState("");
    const [valueMove, setValueMove] = useState("");
    const [observationMove, setObservationMove] = useState("");
    const [stockLocationMove, setStockLocationMove] = useState<StockLocations>();
    const [outputTransfer, setOutputTransfer] = useState<StockLocations>();
    const [entryTransfer, setEntryTransfer] = useState<StockLocations>();
    
    const [modalProductVariation, setModalProductVariation] = useState(false);
    const [moveStock, setMoveStock] = useState(false);

    const { id } = useParams<UrlParam>();
    
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate]     = useState("");

    const [pages, setPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        currency: 'BRL',
        minimumFractionDigits: 2
    })

    async function getVariations() {
        const {data} = await axios.get(`/variations/product/${id}`);
        setNameProduct(data.name);
        setVariations(data);
    }
    
    async function getStockLocations() {
        try {
            const { data: stockLocations } = await axios.get<StockLocations[]>("stockLocation", {
                params: {
                    calculateTotals: true,
                    productId: id,
                },
            });
            const product = await ProductService.getProductById(id);

            setCurrentProduct(product);

            if (product.hasVariation == "y") {
                getVariations();
                setModalProductVariation(true);
            } else {
                setNameProduct(product.name);
                getHistoric();
            }

            setStockLocations(stockLocations);
        } catch(error) {
            console.log(error);
        }
    }

    async function getHistoric() {
        const {data} = await axios.get(`/stockProductHistoric/product/${id}`);
        setHistoric(data);
        recalcStocks(false);
    }

    async function getHistoricVariation(variationId: number, variation?: any) {
        const {data} = await axios.get(`/stockProductHistoric/product/${id}/variation/${variationId}`);
        setHistoric(data);
        recalcStocks(false);
    }

    async function recalcStocks(variation?: any) {
        const { data: stockLocations } = await axios.get<StockLocations[]>("stockLocation", {
            params: {
                calculateTotals: true,
                productId: id,
                variationId: variation ? variation.id : null,
            },
        });
        const product = await axios.get(`/products/${id}`);

        setStockLocations(stockLocations);

        const { totalInStock, totalInConsignment, totalBalance } = calculateTotalStock(stockLocations);

        setTotalInStock(formatDecimal.format(totalInStock));
        setTotalInConsignment(formatDecimal.format(totalInConsignment));
        setTotalLocations(formatDecimal.format(totalBalance));

        if (variation) {
            setMinTotalExceed(totalBalance < (variation.stockMin) ? true : false);
            setMaxTotalExceed(totalBalance > variation.stockMax ? true : false);
        } else {
            setMinTotalExceed(totalBalance < product.data.stockMin ? true : false);
            setMaxTotalExceed(totalBalance > product.data.stockMax ? true : false);
        }
    }

    async function selectVariation(data: any) {
        getHistoricVariation(data.id);
    }

    function formatDate(date: string) {
        let dateExploded = date.split('-');
        return dateExploded[2] +"/"+ dateExploded[1] +"/"+ dateExploded[0];
    }

    function verifyInputsEmpty(): boolean {
        let isSubmit = true; 

        if (!typeMove) {
            isSubmit = false;
            alert("Selecione o tipo de movimentação!");            

        }else if (!countMove) {
            isSubmit = false;
            alert("Informe a quantidade da movimentação!");   
        }
        
        if (typeMove == "transfer") {

            if (!outputTransfer) {
                isSubmit = false;
                alert("Selecione o local de saída para movimentação!");   

            } else if (!entryTransfer) {
                isSubmit = false;
                alert("Selecione o local de entrada para movimentação!");   
            }
            
        } else {
            if (!stockLocationMove) {
                isSubmit = false;
                alert("Selecione o local para movimentação!");   
            }
        }

        return isSubmit;
    }

    function verifyCountMove(): boolean {
        let isSubmit = true; 
        
        if (typeMove == 'output' && stockLocationMove) {
            stockLocations?.map((value, index) => {
                if (value.id == stockLocationMove.id && formatToFloat(countMove) > formatToFloat(value.totalInStock)) {
                    isSubmit = false;
                    alert('Quantidade a ser tirada do estoque '+ value.name +' é maior que o saldo!');
                }
            });
            
        } else if (typeMove == 'transfer' && outputTransfer && entryTransfer) {
            stockLocations?.map((value, index) => {
                if (value.id == outputTransfer.id && formatToFloat(countMove) > formatToFloat(value.totalInStock)) {
                    isSubmit = false;
                    alert('Quantidade a ser tirada do estoque '+ value.name +' é maior que o saldo!');
                }
            });
        }

        return isSubmit;
    }

    function getTotalStock() {
        const { totalInStock, totalInConsignment, totalBalance } = calculateTotalStock(stockLocations);

        setTotalInStock(formatDecimal.format(totalInStock));
        setTotalInConsignment(formatDecimal.format(totalInConsignment));
        setTotalLocations(formatDecimal.format(totalBalance));

        return totalBalance;
    }

    function getTotalLocalStock (stockId: number) {
        let total = 0;

        stockLocations?.map((value, index) => {
            if (value.id == stockId) {
                total += formatToFloat(value.balanceTotal);
            }
        });

        return total;
    }

    function calculateTotalStock(stockLocations?: StockLocations[]) {
        let totalInStock = 0;
        let totalInConsignment = 0;
        let totalBalance = 0;

        if (stockLocations) {
            for (const location of stockLocations) {
                totalInStock += location.totalInStock;
                totalInConsignment += location.totalInConsignment;
                totalBalance += location.balanceTotal;
            }
        }

        return {
            totalInStock, totalInConsignment, totalBalance
        };
    }

    async function saveMoveStock() {
        if (verifyInputsEmpty()) {
            const dateStockMove = new Date();
            const month = dateStockMove.getMonth() + 1 < 10 ? "0"+ (dateStockMove.getMonth() + 1) : (dateStockMove.getMonth() + 1);
            const day   = dateStockMove.getDate() < 10 ? "0"+ dateStockMove.getDate() : dateStockMove.getDate();

            if (typeMove == 'entry') {
                let totalLocalStock = getTotalLocalStock(stockLocationMove!.id);
                
                const rawHistoric = {
                    productId: Number(id),
                    date: dateStockMove.getFullYear() +"-"+ month +"-"+ day,
                    entry: formatToFloat(countMove),
                    observation: observationMove ?? null,
                    stockId: stockLocationMove!.id,
                    stockName: stockLocationMove!.name,
                    value: valueMove ? formatToFloat(valueMove) : null,
                    type: "Entrada manual",
                    totalLocation: formatToFloat(countMove) + totalLocalStock,
                    total: getTotalStock() + formatToFloat(countMove),
                    hasVariation: variationSelected ? "y" : "n",
                    variationId: variationSelected?.id ?? null
                }

                const responseHistoric = await axios.post("/stockProductHistoric", rawHistoric);

            } else if (typeMove == 'output') {
                let totalLocalStock = getTotalLocalStock(stockLocationMove!.id);
                
                const rawHistoric = {
                    productId: Number(id),
                    date: dateStockMove.getFullYear() +"-"+ month +"-"+ day,
                    output: formatToFloat(countMove),
                    observation: observationMove ?? null,
                    stockId: stockLocationMove!.id,
                    stockName: stockLocationMove!.name,
                    value: valueMove ? formatToFloat(valueMove) : null,
                    type: "Saída manual",
                    totalLocation: totalLocalStock - formatToFloat(countMove),
                    total: getTotalStock() - formatToFloat(countMove),
                    hasVariation: variationSelected ? "y" : "n",
                    variationId: variationSelected?.id ?? null
                }

                const responseHistoric = await axios.post("/stockProductHistoric", rawHistoric);

            } else if (typeMove == 'transfer') {
                let totalLocalOutputStock = getTotalLocalStock(outputTransfer!.id);
                let totalLocalEntryStock  = getTotalLocalStock(entryTransfer!.id);
                                
                const rawOutputHistoric = {
                    productId: Number(id),
                    date: dateStockMove.getFullYear() +"-"+ month +"-"+ day,
                    output: formatToFloat(countMove),
                    observation: observationMove ?? null,
                    stockId: outputTransfer!.id,
                    stockName: outputTransfer!.name,
                    type: "Transferência saída",
                    totalLocation: totalLocalOutputStock - formatToFloat(countMove),
                    total: getTotalStock(),
                    hasVariation: variationSelected ? "y" : "n",
                    variationId: variationSelected?.id ?? null
                }

                const rawEntryHistoric = {
                    productId: Number(id),
                    date: dateStockMove.getFullYear() +"-"+ month +"-"+ day,
                    entry: formatToFloat(countMove),
                    observation: observationMove ?? null,
                    stockId: entryTransfer!.id,
                    stockName: entryTransfer!.name,
                    type: "Transferência entrada",
                    totalLocation: formatToFloat(countMove) + totalLocalEntryStock,
                    total: getTotalStock(),
                    hasVariation: variationSelected ? "y" : "n",
                    variationId: variationSelected?.id ?? null
                }
                
                const responseOutputHistoric = await axios.post("/stockProductHistoric", rawOutputHistoric);
                const responseEntryHistoric = await axios.post("/stockProductHistoric", rawEntryHistoric);

            } else if (typeMove == 'balance') {
                let totalLocalStock = getTotalLocalStock(stockLocationMove!.id);
                
                const rawHistoric = {
                    productId: Number(id),
                    date: dateStockMove.getFullYear() +"-"+ month +"-"+ day,
                    observation: observationMove ?? null,
                    value: valueMove ? formatToFloat(valueMove) : null,
                    stockId: stockLocationMove!.id,
                    stockName: stockLocationMove!.name,
                    type: "Balanço",
                    totalLocation: formatToFloat(countMove),
                    total: getTotalStock() - totalLocalStock + formatToFloat(countMove),
                    hasVariation: variationSelected ? "y" : "n",
                    variationId: variationSelected?.id ?? null
                }

                const responseHistoric = await axios.post("/stockProductHistoric", rawHistoric);
            }

            if (variationSelected) {
                getHistoricVariation(variationSelected?.id, variationSelected);
            } else {
                getHistoric();
            }

            setTypeMove("");
            setDateMove("");
            setCountMove("");
            setValueMove("");
            setObservationMove("");
            setStockLocationMove(undefined);
            setMoveStock(false);
        }
    }

    function getIcon(type: string) {
        switch (type) {
            case "Saída manual": 
                return "outputManual.png";
            case "Entrada manual":
                return "entryManual.png";
            case "Entrada de mercadoria":
                return "purchase.png";
            case "Pedido de compra":
                return "purchase.png";
            case "Pedido de compra (Dev)":
                return "purchaseDev.png";
            case "Pedido de venda":
                return "request.png";
            case "Pedido de venda (Dev)":
                return "requestDev.png";
            case "Transferência saída":
                return "outputTransfer.png";
            case "Transferência entrada":
                return "entryTransfer.png";
            case "Balanço":
                return "balance.png";
            case "Venda PDV":
                return "request.png";
        }
    }

    async function searchHistoricStock() {
        if (initialDate && finalDate) {
            const raw = {
                initialDate: initialDate,
                finalDate: finalDate
            }
    
            if (variationSelected) {
                axios.post(`/stockProductHistoric/product/${id}/variation/${variationSelected}`, raw).then((response: any) => {
                    setHistoric(response.data);
                    axios.get(`/variations/${variationSelected}`).then((variation: any) => {
                        recalcStocks(variation.data);
                    });
                });
    
            } else {
                axios.post(`/stockProductHistoric/product/${id}`, raw).then((response: any) => {
                    setHistoric(response.data);
                    recalcStocks();
                });
            }

        } else {
            alert("Preencha a data inicial e final para busca!");
        }
    }
    
    useEffect(() => {
        getStockLocations();
    }, []);

    const handleChangePage = useCallback((next: number) => {
        setPages(next);
    }, []);

    // Mudnando linhas por página
    const handleRowsPerPage = useCallback((value: number) => {
        setRowsPerPage(value);
    }, []);

    return (
        <div className="card card-body pt-4">
            <Modal
                show={modalProductVariation}
                onHide={() => setModalProductVariation(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Produto Variante
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        size="small"
                        id='outlined-select-currency'
                        select
                        label='Selecione a variação'
                        required
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin='normal'
                        variant='outlined'
                        value={typeVariation}
                        onChange={(e: any) => {
                            setTypeVariation(e.target.value);
                        }}
                    >
                        <MenuItem value='' disabled>
                            Selecione
                        </MenuItem>

                        {
                            variations?.map((value, index) => {
                                return (
                                    <MenuItem key={"variation"+index} value={value.id} onClick={() => {selectVariation(value); setVariationSelected(value);}}>
                                        {value.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => window.location.href = "/produtos"}>Cancelar</Button>
                    <Button variant="primary" onClick={() => setModalProductVariation(!modalProductVariation)}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={moveStock}
                onHide={() => setMoveStock(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Movimentar Estoque
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="newProductWrapper">
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField
                                size="small"
                                id='outlined-select-currency'
                                select
                                label='Tipo de movimentação'
                                required
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin='normal'
                                variant='outlined'
                                value={typeMove}
                                onChange={(e: any) => {
                                    setTypeMove(e.target.value);
                                }}
                            >
                                <MenuItem value='' disabled>
                                    Selecione
                                </MenuItem>

                                <MenuItem value="entry">
                                    Entrada
                                </MenuItem>

                                <MenuItem value="output">
                                    Saída
                                </MenuItem>

                                <MenuItem value="transfer">
                                    Transferência
                                </MenuItem>

                                <MenuItem value="balance">
                                    Balanço
                                </MenuItem>
                            </TextField>
                        </div>

                        {
                            typeMove == "transfer"
                            ?
                            <>
                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        id='outlined-select-currency'
                                        select
                                        label='Saída'
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin='normal'
                                        variant='outlined'
                                        value={outputTransfer?.id ?? ''}
                                        onChange={(e) => setOutputTransfer(stockLocations?.find(location => location.id === Number(e.target.value)))}
                                    >
                                        <MenuItem value='' disabled>
                                            Selecione
                                        </MenuItem>
                                        {
                                            stockLocations 
                                            ?
                                                stockLocations.map((value, index) => {
                                                    return (
                                                        <MenuItem key={index} value={value.id}>
                                                            {value.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            : <></>
                                        }
                                    </TextField>
                                </div>
                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        id='outlined-select-currency'
                                        select
                                        label='Entrada'
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin='normal'
                                        variant='outlined'
                                        value={entryTransfer?.id ?? ''}
                                        onChange={(e) => setEntryTransfer(stockLocations?.find(location => location.id === Number(e.target.value)))}
                                    >
                                        <MenuItem value='' disabled>
                                            Selecione
                                        </MenuItem>
                                        {
                                            stockLocations 
                                            ?
                                                stockLocations.map((value, index) => {
                                                    return (
                                                        <MenuItem key={index} value={value.id}>
                                                            {value.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            : <></>
                                        }
                                    </TextField>
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        label='Observações'
                                        margin='normal'
                                        variant='outlined'
                                        size="small"
                                        value={observationMove}
                                        onChange={(e: any) => setObservationMove(e.target.value)}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <NumericFormat
                                        required
                                        label="Quantidade"
                                        value={countMove}
                                        onChange={(e: any) => setCountMove(e.target.value)}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className="col-lg-6">
                                    <NumericFormat
                                        required
                                        label="Quantidade"
                                        variant="outlined"
                                        value={countMove}
                                        onChange={(e: any) => setCountMove(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <NumericFormat
                                        label="Valor unitário"
                                        value={valueMove}
                                        onChange={(e: any) => setValueMove(e.target.value)}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        label='Observações'
                                        margin='normal'
                                        variant='outlined'
                                        size="small"
                                        value={observationMove}
                                        onChange={(e: any) => setObservationMove(e.target.value)}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        required
                                        size="small"
                                        id='outlined-select-currency'
                                        select
                                        label='Local'
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin='normal'
                                        variant='outlined'
                                        value={stockLocationMove?.id ?? ''}
                                        onChange={(e) => setStockLocationMove(stockLocations?.find(location => location.id === Number(e.target.value)) ?? undefined)}
                                    >
                                        <MenuItem value='' disabled>
                                            Selecione
                                        </MenuItem>
                                        {
                                            stockLocations 
                                            ?
                                                stockLocations.map((value, index) => {
                                                    return (
                                                        <MenuItem key={index} value={value.id}>
                                                            {value.name}
                                                        </MenuItem>
                                                    )
                                                })
                                            : <></>
                                        }
                                    </TextField>
                                </div>
                            </>    
                        }
                    </div>

                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setMoveStock(!moveStock)}>Cancelar</Button>
                    <Button variant="primary" onClick={() => saveMoveStock()}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
            
            <div className="row border-bottom justify-content-center">
                <label style={{ fontWeight: "bolder", fontSize: 26 }}>{nameProduct}</label>
            </div>
            
            <div className="row d-flex justify-content-end mb-3">
                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="De"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={initialDate}
                        onChange={(e: any) => setInitialDate(e.target.value)}
                        InputProps={{ inputProps: {max: finalDate} }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div className="col-lg-2">
                    <TextField
                        type="date"
                        label="Até"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={finalDate}
                        onChange={(e: any) => setFinalDate(e.target.value)}
                        InputProps={{ inputProps: {min: initialDate} }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div className="mr-3 d-flex align-items-center">
                    <Button variant="success" className="mt-2" onClick={() => searchHistoricStock()}>Buscar</Button>
                </div>
            </div>
            
            <div className='row d-flex justify-content-center mb-3'>
                {
                    stockLocations
                    ?
                    stockLocations.map((value, index) => {
                        return (
                            <div key={"stock"+index} className="col-lg-6 mb-5">
                                <h3 className="text-center"><label style={{ fontWeight: 100 }}>Resumo estoque em:</label> <label style={{ fontWeight: 'bolder' }}>{value.name}</label> {value.minExceed ? <img src="/media/icons/minExceed.png" /> : ""} {value.maxExceed ? <img src="/media/icons/maxExceed.png" /> : ""}</h3>
                                <div key={"valueStock"+index} className="d-flex align-items-center justify-content-center"> 
                                    <div className="border d-flex flex-column align-items-center justify-content-center px-1 pt-5 pb-5" style={{ width: '100px'  }}>
                                        <label className="m-0">Entrada</label>
                                        <b>{value.entry}</b>
                                    </div>

                                    <div className="border d-flex flex-column align-items-center justify-content-center px-1 pt-5 pb-5" style={{ width: '100px'  }}>
                                        <label className="m-0">Saída</label>
                                        <b>{value.output}</b>
                                    </div>

                                    <div className="border d-flex flex-column align-items-center justify-content-center px-1 pt-5 pb-5" style={{ width: '100px'  }}>
                                        <label className="m-0">Estoque</label>
                                        <b>{value.totalInStock}</b>
                                    </div>
                                    <div className="border d-flex flex-column align-items-center justify-content-center px-1 pt-5 pb-5" style={{ width: '100px'  }}>
                                        <label className="m-0">Consignação</label>
                                        <b>{value.totalInConsignment}</b>
                                    </div>
                                    <div className="border d-flex flex-column align-items-center justify-content-center px-1 pt-5 pb-5" style={{ width: '100px'  }}>
                                        <label className="m-0">Total</label>
                                        <b>{value.balanceTotal}</b>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : 
                    <div className="col-lg-12 text-center mt-3">
                        <h3 style={{ color: "#ccc" }}>Nenhum estoque criado até o momento...</h3>
                    </div>  
                }
            </div>

            <div className="row col-lg-12 justify-content-around mb-3 pt-3 border-top">
                <h5>
                    <label style={{ fontWeight: 100 }}>
                        Saldo em Estoque: 
                    </label>
                    {' '}
                    <label style={{ fontWeight: 'bolder' }}>
                        {totalInStock}
                    </label>
                </h5>

                <h5>
                    <label style={{ fontWeight: 100 }}>
                        Saldo em Consignação: 
                    </label>
                    {' '}
                    <label style={{ fontWeight: 'bolder' }}>
                        {totalInConsignment}
                    </label>
                </h5>

                <h5>
                    <label style={{ fontWeight: 100 }}>
                        Saldo Total: 
                    </label>
                    {' '}
                    <label style={{ fontWeight: 'bolder' }}>
                        {totalLocations} {minTotalExceed ? <img src="/media/icons/minExceed.png" /> : ""} {maxTotalExceed ? <img src="/media/icons/maxExceed.png" /> : ""}
                    </label>
                </h5>
            </div>

            <div className=" col-lg-12 overflow-auto">
                <div className='d-flex justify-content-end flex-column align-items-end'>
                    <div>
                        {
                            user.isAccountant == "n"
                            ?
                            <Button variant="success" className="mb-3" onClick={() => setMoveStock(true)}>Movimentar Estoque</Button>
                            : <></>
                        }
                    </div>
                    <div className='overflow-auto w-100'>
                        <TableContainer style={{ maxHeight: '75vh' }}>
                            <Table stickyHeader className="wrap">
                                <TableHead>
                                    <TableRow>
                                        {
                                            headData.map((head, index) => (
                                                <TableCell
                                                    key={index}
                                                    padding="default"
                                                >
                                                    <strong>
                                                        {head.value}
                                                    </strong>
                                                </TableCell>
                                            ))
                                        }

                                        <TableCell padding="default"></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        bodyData.length ?
                                        bodyData
                                            .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <TableRow key={index} hover>
                                                    {
                                                        row.map((cell, i) => {
                                                            return (
                                                                <TableCell key={i}>
                                                                    {cell.value}
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            ))
                                        :
                                        <>
                                            {
                                                historic?.length
                                                ?
                                                historic
                                                    .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                                                    .map((value, index) => {
                                                    return(
                                                        <TableRow key={"row"+index}>
                                                            <TableCell>
                                                                {formatDate(value.date)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDecimal.format(value.entry) == '0,00' ? '-' : formatDecimal.format(value.entry)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDecimal.format(value.output) == '0,00' ? '-' : formatDecimal.format(value.output)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {value.observation ? value.observation : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDecimal.format(value.value) == '0,00' ? '-' : formatDecimal.format(value.value)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    value.stockName
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <img src={"/media/icons/"+ getIcon(value.type)} />
                                                                &nbsp;
                                                                &nbsp;
                                                                {value.type}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDecimal.format(parseFloat(value.totalLocation))}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDecimal.format(parseFloat(value.total))}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                <TableRow>
                                                    <TableCell className="text-center" colSpan={9}>
                                                        Nenhuma movimentação no estoque até o momento...
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </>
                                        
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div>
                        <TablePagination
                            labelRowsPerPage="Linhas por página"
                            page={pages}
                            component="div"
                            count={historic.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[15, 50, 100]}
                            backIconButtonProps={{
                                'aria-label': 'Página Anterior',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Próxima Página',
                            }}
                            onChangePage={(_, next) => handleChangePage(next)}
                            onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}