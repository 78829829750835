import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';

import { useEntry, Product } from './context';
import { NumericFormat } from '../../components/NumericFormat';
import { Product as SelectableProduct } from '../../services/ProductService';
import { formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import { useSelector } from 'react-redux';
import ProductSelect from '../../components/ProductSelect';
import api from '../../services/Api';

const useStyles = makeStyles(theme => ({
    error: {
        "& .Mui-error": {
          color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));
interface ListProductsProps {
    index: number;
    product: Product;
    status?: string;
    isContent?: any;
    onClickAddProduct?: (typedText: string, index: number, product: Product) => void;
}

export const ListProducts: React.FC<ListProductsProps> = ({
    index,
    product,
    status,
    isContent,
    onClickAddProduct,
}) => {
    const { user } = useSelector((state: any) => state.auth);


    const classes = useStyles();

    const { dispatchProducts } = useEntry();
    const [contentError, setContentError] = useState(false);
    const [nfeConfig, setNfeConfig] = useState<any>();

    const getCfop = useCallback(async () => {
        if(!nfeConfig) return undefined;

        return nfeConfig.cfopInUf;
    }, [nfeConfig]);

    async function getNfeConfig() {
        const response = await api.get('nfeConfig');
        const config = response.data[0];
        setNfeConfig(config);
    }

    useEffect(() => {
        getNfeConfig();
    }, []);

    const handleChangeProduct = async (value: SelectableProduct) => {
        const defaultCfop = (await getCfop()) ?? '0';

        const productObj = typeof value === 'string' ? null : value;
        const productName = typeof value === 'string' ? value : '';
        
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    amount: 1,
                    INSTANCE: productObj ?? {} as SelectableProduct,
                    total: productObj?.saleValue ?? 0,
                    unitaryValue: productObj?.saleValue ?? 0,
                    cfop: defaultCfop ?? "0",
                    icms: productObj?.icmsRate?.length ? productObj.icmsRate : "0",
                    ipi: productObj?.ipiRate?.length ? productObj.ipiRate : "0",
                    ncm: productObj?.ncm ?? "0",
                },
            }
        })
    }

    const handleChangeValues = (amount: number, unitaryValue: any) => {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    ...product,
                    amount: amount,
                    unitaryValue: formatToFloat(unitaryValue),
                    total: amount * formatToFloat(unitaryValue),
                }
            }
        })
    } 

    return (
        <div className="row col-lg-12 p-0 pl-7 pr-7 newProductWrapper">
            <div className="col-lg-4 d-flex align-items-center p-1 ">
                <div className="mr-2 d-flex justify-content-center align-items-center bg-secondary p-3 mt-2">
                    <label className="text-white m-0">{index + 1}.</label>
                </div>

                <ProductSelect
                    label="Produto"
                    selectedProduct={product}
                    onChangeProduct={(prod) => prod && handleChangeProduct(prod)}
                    disabled={status == "attended" || user.isAccountant == "y"  ? true : false}
                    hasError={product.hasError}
                    type="product"
                    onClickAddProduct={onClickAddProduct ? (typedText) => onClickAddProduct(typedText, index, product) : undefined}
                />
            </div>

            <div className="col p-1">
                <TextField
                    size="small"
                    label="Qtde."
                    margin="normal"
                    variant="outlined"
                    name="amount"
                    type="number"
                    value={product.amount}
                    onChange={
                        (evt) => handleChangeValues(Number(evt.target.value), product.unitaryValue)
                    }
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    label="IPI %"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.ipi}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ipi: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;
                        
                        console.log(converted);
                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: converted ? String(converted) : "0"
                                    }
                                }
                            }) 
                        }
                    }}
                />
            </div>

            <div className="col p-1">
            <NumericFormat
                    label="ICMS %"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.icms}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    icms: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: converted ? String(converted) : "0"
                                    }
                                }
                            }) 
                        }
                    }}
                />
            </div>

            <div className="col-lg-2 p-1">
                <NumericFormat
                    label="Valor Unit."
                    value={product.unitaryValue}
                    onChange={
                        (evt) => handleChangeValues(Number(product.amount), evt.target.value)
                    }
                    startAdornment="R$"
                    disabled={user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    format="####"
                    label="CFOP"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.cfop}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    cfop: evt.target.value
                                }
                            }
                        })
                    }
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    label="NCM"
                    format="########"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.ncm}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ncm: evt.target.value
                                }
                            }
                        })
                    }
                />
            </div>

            <div className="col-lg-2 p-1">
                <NumericFormat
                    label="Valor Total"
                    disabled
                    value={product.total}
                    startAdornment="R$"
                />
            </div>

            {
                index > 0 && (
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => dispatchProducts({
                            type: "DELETE",
                            payload: {
                                index
                            }
                        })}
                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    >
                        <i className="flaticon-delete"></i>
                    </button>
                )
            }
        </div>
    );
}