import { useEffect, useState } from "react";
import api from "../services/Api";

export default function useAllUf() {
    const [allStates, setAllStates] = useState({ states: [] as any[] });

    useEffect(() => {

        const getStates = async () => {
            const statesBrazil = await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: [...statesBrazil.data, { id: 0, sigla: 'EX', nome: 'Exterior' } as any] });
        }
        getStates();
    }, []);

    return {allStates};
}