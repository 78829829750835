import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { formatCurrency, formatNumberToString, formatToFloat } from '../../../utils/formatCurrency';
import { ReportComissionRowInstallments } from './List-ReportCommission';
import { formatDate } from '../../../utils/dateFormat';

type ListInstallmentsProps = {
    installments: ReportComissionRowInstallments[];
}

export function ListInstallments({ installments }: ListInstallmentsProps) {
    
    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>N° Parcela</TableCell>
                    <TableCell>Data de vencimento</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Valor da Comissão</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {installments.map((installment, index) => (
                    <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{formatDate(installment.date)}</TableCell>
                        <TableCell>{formatCurrency(installment.value)}</TableCell>
                        <TableCell>{formatNumberToString(installment.sellerCommissionValue)}%</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}