import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Collapse, InputAdornment, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
    HeadDataBaseProps,
    BodyDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../../components/ListWithModalChangeSituation';
import ModalError from '../../components/ModalError';
import api from '../../services/Api';
import { getDate } from '../../utils/dateTimeHelper';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import nfApiHelper from '../../utils/nfApiHelper';
import { BsVariant } from '../../types/BsVariant';
import { Search } from '../../components/Search';
import { NumericFormat } from '../../components/NumericFormat';
import '../../style.css';
import { useNf } from '../../hooks/nf';
import useBackendLoad from '../../hooks/backendReload';
import { ModalValidateNf } from '../../components/ModalValidateNf';
import { ModalSendEmail } from '../../components/ModalSendEmail';
import { ModalSendWhatsapp } from '../../components/ModalSendWhatsapp';
import EmailNfService from '../../services/EmailNfService';
import { Nfse } from '../../types/Nfse';
import NfseService from '../../services/NfseService';
import WhatsappNfService from '../../services/WhatsappNfService';
import useSendEmailAndWhatsapp from '../../hooks/sendEmailAndWhatsapp';
import ModalSuccess from '../../components/ModalSuccess';
import CustomerService from '../../services/CustomerService';
import zipHelpers from '../../utils/zipHelpers';
import ModalLimit from '../../components/ModalLimit';
import { NfApiSituation } from '../../types/NfApiSituation';

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Ref" },
    { reference: "rps", value: "RPS" },
    { reference: "date", value: "Data" },
    { reference: "customer", value: "Cliente" },
    { reference: "totalValue", value: "Valor Total" },
    { reference: "situation", value: "Situação", situation: true, notSortable: true },
];

type Filters = {
    searchQuery: string;
    situation: string;
    minValue: number;
    maxValue: number;
    initialDate: string;
    finalDate: string;
    technical: string;
}

export function ListNfse() {
    const { user } = useSelector((state: any) => state.auth);

    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [nfse, setNfse] = useState<Nfse[]>([]);
    const [countTotalNf, setCountTotalNf] = useState(0);
    const [selectAllNfse, setSelectAllNfse] = useState(false);
    const [selectedNfseId, setSelectedNfseId] = useState<string[]>([]);

    const { location: { pathname }, push: pushHistory } = useHistory();
    const { nfseEnabled } = useNf();

    const [nfseToIssue, setNfseToIssue] = useState<Nfse|undefined>();
    const [showModalIssue, setShowModalIssue] = useState(false);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const [nfseDetails, setNfseDetails] = useState<any>();
    const [cancelNote, setCancelNote] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelError, setCancelError] = useState("");

     // Campos da Busca
     const [searchQuery, setSearchQuery] = useState("");
     const [advancedSearch, setAdvancedSearch] = useState(false);
     const [situation, setSituation] = useState("");
     const [initialDate, setInitialDate] = useState("");
     const [finalDate, setFinalDate] = useState("");
     const [minValue, setMinValue] = useState("");
     const [maxValue, setMaxValue] = useState("");
     const [technical, setTechnical] = useState("");
     const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
     
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [msgInfo, setMsgInfo] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");

    //Envio de Email/Whatspp
    const {
        showModalEmail, setShowModalEmail,
        infoEmail, setInfoEmail,
        emailsHistory, setEmailsHistory,
        showModalWhatsapp, setShowModalWhatsapp,
        infoWhatsapp, setInfoWhatsapp,
        whatsappHistory, setWhatsappHistory,
    } = useSendEmailAndWhatsapp();

    const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
    const [showModalLimit, setShowModalLimit] = useState(false);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [nfse]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setSelectedNfseId([]);
        setSelectAllNfse(false);

        const response = await api.get<{rows: any[], count: number}>('nfse', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = response.data;

        const nfses = await Promise.all(rows.map(async (nfse) => {
            if(!nfApiHelper.isProccessing(nfse.api_situation)) {
                return nfse;
            }
            const response = await api.post(`nfse/check/${nfse.id}`);
            return response.data;
        }));

        setNfse(nfses);
        setCountTotalNf(count);
    }, []);

    useEffect(() => {
        if(!nfseEnabled) {
            setShowModalInfo(true);
            setMsgInfo('Empresa não pode emitir NFS-e portanto essa funcionalidade foi desativada!');
        }
    }, [nfseEnabled]);
    
    useEffect(() => {
        async function getBodyData() {
            const list: BodyDataBaseProps[][] = [];
            const aux:any = nfse;

            for (const invoice of aux) {
                invoice.customerName = CustomerService.getCustomerName(invoice.customerEntity);

                const status = invoice.situation === "open" ? "Em aberto"
                : invoice.situation === "progress" ? "Em andamento"
                : invoice.situation === "attended" ? "Atendido"
                : invoice.situation === "canceled" ? "Cancelado"
                : invoice.situation === "aproved" ? "Aprovado"
                : "";
            
                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(invoice.id), id: true },
                    { for: "rps", value: String(invoice.seriesRps) },
                    { for: "date", value: getDate({initialDate: new Date(invoice.createdDate)}).dateStr },
                    { for: "customer", value: String(invoice.customerName ?? '-') },
                    { for: "totalValue", value: formatCurrency(invoice.valueLiquidNote) },
                    { for: "situation", value: nfApiHelper.getStatus(invoice.api_situation) },
                    { for: "editButton", value: nfApiHelper.canEdit(invoice.api_situation) ? 'y' : 'n', hidden: true },
                ];
    
                list.push(data);
            }
    
            
    
            setBodyData(list);
        }
        getBodyData();
    }, [nfse]);

    const handleClickSearch = useCallback(() => {
        filtersRef.current = {
            searchQuery, situation,
            initialDate, finalDate, minValue: formatToFloat(minValue), maxValue: formatToFloat(maxValue), technical
        };

        reloadData();
    }, [nfse, searchQuery, situation, initialDate, finalDate, minValue, maxValue, technical]);

    const clearSearch = () => {
        setSearchQuery('');
        setSituation('');
        setInitialDate('');
        setFinalDate('');
        setMinValue('');
        setMaxValue('');
        setTechnical('');
    }

    const issueButtonCondition = useCallback((id: string) => {
        const row = nfse.find(obj => String(obj.id) === id);

        if(!row) return false;

        return nfApiHelper.canIssue(row ? row.api_situation : null) && nfseEnabled;
    }, [nfse, nfseEnabled]);
    const detailsButtonCondition = useCallback((id: string) => {
        const row = nfse.find(obj => String(obj.id) === id);

        if(!row) return false;


        return nfApiHelper.hasDetails(row ? row.api_situation : null);
    }, [nfse]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = nfse.filter((cat) => cat.id !== Number(id));
            await api.delete(`nfse/${id}`);

            setNfse([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [nfse]);

    async function handleConfirmIssue() {
        setShowModalIssue(false);
        if(nfseToIssue) {
            try {
                const response = await api.post<Nfse>(`nfse/issue/${nfseToIssue.id}`);
                
                const aux = nfse;
                const index = aux.findIndex(obj => obj.id === response.data.id);
                aux[index] = response.data;

                setNfse([...aux]);

                setShowModalInfo(true);
                setMsgInfo('Nota fiscal enviada para processo de emissão com sucesso!');
            } catch (error: any) {
                setShowModalInfo(true);
                setMsgInfo(error.response?.data?.message ?? 'Erro ao emitir');
            }
        }
    }

    async function handleConfirmCancel() {
        setCancelError('');
        if(!cancelReason) {
            setCancelError('Preencha a justificativa do cancelamento');
            return;
        }
        if(cancelReason.length < 15 || cancelReason.length > 255) {
            setCancelError('Campo justificativa deve ter entre 15 e 255 caracteres');
            return;
        }

        try {
            const response = await api.post(`nfse/cancel/${nfseDetails.id ?? 0}`, {
                reason: cancelReason,
            });

            const aux = nfse;
            const index = aux.findIndex(obj => obj.id === response.data.id);
            aux[index] = response.data;

            setNfseDetails({
                ...response.data,
                api_last_response: JSON.parse(response.data.api_last_response),
            });

            setNfse([...aux]);
            setCancelNote(false);
            setCancelReason('');
        } catch (error: any) {
            setCancelError(error.response?.data?.message ?? 'Erro ao cancelar a nota');
        }
    }

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickClone = useCallback((id: string) => {
        pushHistory(`${pathname}/duplicar/${id}`);
    }, [pathname]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickIssue = useCallback(async (id: string) => {
        const canIssue = await checkLimits();

        if (!canIssue) return;

        setShowModalIssue(true);
        setNfseToIssue(nfse.find((nfseObj) => nfseObj.id === Number(id)));
    }, [nfse]);

    const getNfse = useCallback(async (id: string) => {
        const response = await api.get(`nfse/${id}`);
        setNfseDetails({
            ...response.data,
            api_last_response: JSON.parse(response.data.api_last_response),
        });
    }, []);

    const handleClickDetails = useCallback(async (id: string) => {
        setShowModalDetails(true);
        setCancelNote(false);
        setCancelError('');
        await getNfse(id);
    }, [getNfse]);

    const sendEmailAndWhatsappButtonCondition = useCallback((id: string) => {
        const row = nfse.find(obj => String(obj.id) === id);

        if(!row) return false;

        return nfApiHelper.isAuthorized(row.api_situation);
    }, [nfse]);

    async function getEmails(nfseId: number) {
        setEmailsHistory([]);
        const emails = await EmailNfService.getAllEmailsByNfId(nfseId, 'nfse');
        setEmailsHistory(emails);
    }

    async function getWhatsapps(nfseId: number) {
        setWhatsappHistory([]);
        const history = await WhatsappNfService.getAllWhatsappsByNfId(nfseId, 'nfse');
        setWhatsappHistory(history);
    }

    function handleOpenSendEmailModal(id: string) {
        const row = nfse.find(obj => String(obj.id) === id);

        if(!row) return false;

        setShowModalEmail(true);
        getEmails(row.id);
        setInfoEmail({
            refId: String(row.id),
            refName: 'nfse',
            toName: '',
            customerName: row.customerName ?? '',
            customerId: String(row.customer),
            email: row.customerEntity.email,
            contactName: row.customerName ?? '',
            message: NfseService.getDefaultEmailMessage(),
            emailSubject: 'Comprovante fiscal',
        });
    }

    const handleClickSendEmail = useCallback(async () => {
        try {
            const emails = infoEmail.email.split(';').map(email => email.trim());

            const row = nfse.find(obj => obj.id === Number(infoEmail.refId));

            if(!row) return false;

            await EmailNfService.sendEmail(emails, {
                nf: row,
                type: 'nfse',
                customerId: infoEmail.customerId,
                customerName: infoEmail.customerName,
                contactName: infoEmail.contactName,
                message: infoEmail.message,
                refName: infoEmail.refName,
                subject: infoEmail.emailSubject,
            });

            setShowModalSuccess(true);
        } catch (error: any) {
            setMsgInfo(error.response?.data?.message ?? 'Falha ao enviar o e-mail!');
            setShowModalInfo(true);
        }

        setShowModalEmail(false);
    }, [infoEmail, nfse]);

    function handleOpenSendWhatsappModal(id: string) {
        const row = nfse.find(obj => String(obj.id) === id);

        if(!row) return false;

        setShowModalWhatsapp(true);
        getWhatsapps(row.id);
        setInfoWhatsapp({
            refId: String(row.id),
            refName: 'nfse',
            customerName: row.customerName ?? '',
            customerId: String(row.customer),
            number: row.customerEntity.cell ?? '',
            message: NfseService.getDefaultWhatsappMessage(),
        });
    }

    const handleClickSendWhatsapp = useCallback(async () => {
        try {
            const row = nfse.find(obj => obj.id === Number(infoWhatsapp.refId));
        
            if(!row) return;

            await WhatsappNfService.sendWhatsapp(infoWhatsapp.number, {
                nf: row,
                type: 'nfse',
                customerId: infoWhatsapp.customerId,
                customerName: infoWhatsapp.customerName,
                message: infoWhatsapp.message,
                refName: infoWhatsapp.refName,
            });

            setShowModalSuccess(true);
        } catch (error) {
            setMsgInfo('Falha ao enviar a mensagem!');
            setShowModalInfo(true);
        }

        setShowModalWhatsapp(false);
    }, [infoWhatsapp, nfse]);

    const handleChangeSelectAllNfse = useCallback((checked: boolean) => {
        setSelectAllNfse(checked);
        if(checked) {
            const nfseWithCheckbox = nfse.filter(nfseObj => showConditionSelectNfse(String(nfseObj.id)));
            setSelectedNfseId([...nfseWithCheckbox.map(nfseObj => String(nfseObj.id))])
        } else {
            setSelectedNfseId([]);
        }
    }, [nfse]);

    const checkedConditionSelectAllNfse = useCallback(() => {
        return selectAllNfse;
    }, [selectAllNfse]);

    const handleChangeSelectedNfse = useCallback((checked: boolean, id: string) => {
        setSelectedNfseId((prevState) => {
            if(prevState.includes(id)) {
                return prevState.filter(nfseId => nfseId !== id);
            } else {
                return [...prevState, id];
            }
        });
    }, []);

    const checkedConditionSelectedNfse = useCallback((id: string) => {
        return selectedNfseId.includes(id);
    }, [selectedNfseId]);

    const showConditionSelectNfse = useCallback((id: string) => {
        const foundNfse = nfse.find((nfseObj) => nfseObj.id === Number(id));

        if(!foundNfse) {
            return false;
        }

        if(!nfApiHelper.isAuthorized(foundNfse.api_situation) && !nfApiHelper.isCanceled(foundNfse.api_situation)) {
            return false;
        }

        return true;
    }, [nfse]);

    const handleDownloadXMLSelectedNfse = useCallback(async () => {
        const { data } = await api.get('/nfseXml', {
             params: {
                nfseIds: JSON.stringify(selectedNfseId)
             } 
        });

        if (data.length === 0) {
            setShowModalInfo(true);
            setMsgInfo('Selecione ao menos uma nota fiscal!');

            return;
        }

        zipHelpers.compressFiles(data, 'xml_nfse', 'xml', 'xml');
    }, [selectedNfseId]);

    const handleDownloadDANFESelectedNfse = useCallback(async () => {
        const { data } = await api.get('/nfseDanfe', {
             params: {
                nfseIds: JSON.stringify(selectedNfseId)
             } 
        });

        if (data.length === 0) {
            setShowModalInfo(true);
            setMsgInfo('Selecione ao menos uma nota fiscal!');

            return;
        }

        zipHelpers.compressFiles(data, 'danfe_nfse', 'danfe', 'html');
    }, [selectedNfseId]);

    async function checkLimits() {
        const response = await api.get('companySubscriptionPlans/limits/byField?field=nfse');
        const limits = response.data;
        if(limits.max !== -1 && limits.current >= limits.max) {
            setShowModalLimit(true);
            setModalLimitMsg([`O limite para emissões de notas fiscais de serviço por período foi atingido! (limite: ${limits.max})`]);
            return false;
        }

        return true;
    }

    return (
        <>
            <ModalError
                msgError={msgInfo}
                showModalError={showModalInfo}
                setShowModalError={setShowModalInfo}
            />
            <ModalSuccess
                setShowModal={setShowModalSuccess}
                showModal={showModalSuccess}
                msgModal={messageSuccess}
            />
            <ModalLimit
               showModalLimit={showModalLimit}
               setShowModalLimit={setShowModalLimit}
               messages={modalLimitMsg}
            />
            <ModalSendEmail
                showModal={showModalEmail}
                setShowModal={setShowModalEmail}
                onClickSend={handleClickSendEmail}
                emailsHistory={emailsHistory}
                infoEmail={infoEmail}
                setInfoEmail={setInfoEmail}
            />
            <ModalSendWhatsapp
                showModal={showModalWhatsapp}
                setShowModal={setShowModalWhatsapp}
                onClickSend={handleClickSendWhatsapp}
                whatsappHistory={whatsappHistory}
                infoWhatsapp={infoWhatsapp}
                setInfoWhatsapp={setInfoWhatsapp}
            />

            {/* Modal Emitir */}
            <ModalValidateNf
                nf={nfseToIssue}
                nfType="nfse"
                showModal={showModalIssue}
                setShowModal={setShowModalIssue}
                onConfirmIssue={handleConfirmIssue}
                onCloseModal={() => setNfseToIssue(undefined)}
            />

            {/* Modal detalhes da nota emitida */}
            <Modal
                show={showModalDetails}
                onHide={() => setShowModalDetails(false)}
                aria-labelledby="contained-modal-warning"
                centered 
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Nota Fiscal Nº {nfseDetails?.id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {nfseDetails && (
                        <>
                            {nfApiHelper.isProccessing(nfseDetails.api_situation) && (
                                <div className="alert alert-info"><i className="la la-check"></i> NFS-e em processamento para autorização</div>
                            )}
                            {nfApiHelper.hasError(nfseDetails.api_situation) && (
                                <>
                                    <div className="alert alert-danger"><i className="la la-times"></i> Erro de autorização </div>
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell padding="default">
                                                    <strong>Código</strong>
                                                </TableCell>
                                                <TableCell padding="default">
                                                    <strong>Mensagem</strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!!nfseDetails.api_last_response && !!nfseDetails.api_last_response['body']['erros'] && nfseDetails.api_last_response['body']['erros'].map((error:any, index:number) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {error['codigo']}
                                                    </TableCell>
                                                    <TableCell>
                                                        {error['mensagem']}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                            {nfApiHelper.isAuthorized(nfseDetails.api_situation) && (
                                <>
                                    <div className="alert alert-success"><i className="la la-check"></i> NFS-e autorizada e emitida com sucesso </div>
                                    <p>Código Verificador: { nfseDetails.api_last_response['body']['codigo_verificacao'] }</p>
                                    <p>Número da Nota: { nfseDetails.api_last_response['body']['numero'] }</p>

                                    {cancelNote && (
                                        <div className="row col-lg-8">
                                            <TextField
                                                label="Justificativa do cancelamento *"
                                                multiline
                                                rows="4"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                inputProps={{  maxLength: 5000 }}
                                                value={cancelReason}
                                                onChange={(e) => setCancelReason(e.target.value)}
                                                error={!!cancelError}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                            <small className="text-danger">{cancelError}</small>
                                        </div>
                                    )}
                                </>
                            )}
                            {nfApiHelper.isCanceled(nfseDetails.api_situation) && (
                                <>
                                    {nfseDetails.api_situation === NfApiSituation.CANCELED && (
                                        <>
                                            <div className="alert alert-danger"><i className="la la-times"></i> NFS-e cancelada </div>
                                            <p>Código Verificador: { nfseDetails.api_last_response['body']['codigo_verificacao'] }</p>
                                            <p>Número da Nota: { nfseDetails.api_last_response['body']['numero'] }</p>
                                        </>
                                    )}
                                    {nfseDetails.api_situation === NfApiSituation.PROCESSING_TIMEOUT && (
                                        <>
                                            <div className="alert alert-danger"><i className="la la-times"></i> NFS-e cancelada </div>
                                            <p>Motivo: Não foi possível estabelecer comunicação com a prefeitura durante o processamento da nota. Contate o suporte para mais informações.</p>
                                            <p>Número da Nota: { nfseDetails.api_last_response['body']['numero_rps'] }</p>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {nfseDetails && (
                        <>
                            {!cancelNote && nfApiHelper.isAuthorized(nfseDetails.api_situation) && (
                                <>
                                    {!!nfseDetails.api_last_response['body']['url'] && (
                                        <a href={nfseDetails.api_last_response['body']['url']} target="_blank" className="btn btn-success">Imprimir DANFE</a>
                                    )}
                                    {!!nfseDetails.api_last_response['body']['caminho_xml_nota_fiscal'] && (
                                        <a href={`${nfseDetails.api_last_response['url']}${nfseDetails.api_last_response['body']['caminho_xml_nota_fiscal']}`} target="_blank" className="btn btn-success">Baixar XML</a>
                                    )}
                                    {
                                        user.isAccountant == "n"
                                        ?
                                        <Button variant="danger" onClick={() => setCancelNote(true)}>Cancelar Nota</Button>
                                        : <></>
                                    }
                                </>
                            )}

                            {(cancelNote && user.isAccountant == "n") && (
                                <>
                                    <Button variant="danger" onClick={handleConfirmCancel}>Cancelar Nota</Button>
                                    <Button variant="secondary" onClick={() => setCancelNote(false)}>Voltar</Button>
                                </>
                            )}
                        </>
                    )}
                    <Button variant="secondary" onClick={() => setShowModalDetails(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <div className="card card-body pt-4 newProductWrapper">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <>
                                <Button
                                    type="button"
                                    variant="success"
                                    className="mr-2 mb-2"
                                    onClick={() => handleClickAdd()}
                                >
                                    Adicionar Nota Fiscal de Serviço
                                </Button>
                                 <Button 
                                    className="mr-2 mb-2 btn btn-primary"
                                    onClick={ handleDownloadXMLSelectedNfse }
                                 >
                                    Baixar XML
                                </Button>
                                <Button 
                                    className="mb-2 btn btn-success"
                                    onClick={ handleDownloadDANFESelectedNfse }
                                >
                                    Baixar DANFE
                                </Button>
                            </>
                            : <></>
                        }
                    </div>
                    <div className="col-lg-3 mt-3">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={setAdvancedSearch}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                <Collapse in={advancedSearch}>
                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                select
                                size="small"
                                label="Situação"
                                margin="normal"
                                variant="outlined"
                                value={situation}
                            >
                                <MenuItem key="0" value="" onClick={() => setSituation('')}>
                                    Todos
                                </MenuItem>
                                <MenuItem key="1" value="processando_autorizacao" onClick={() => setSituation('processando_autorizacao')}>
                                    Em andamento
                                </MenuItem>
                                <MenuItem key="2" value="autorizado" onClick={() => setSituation('autorizado')}>
                                    Autorizado
                                </MenuItem>
                                <MenuItem key="3" value="erro_autorizacao" onClick={() => setSituation('erro_autorizacao')}>
                                    Erro de autorização
                                </MenuItem>
                                <MenuItem key="4" value="cancelado" onClick={() => setSituation('cancelado')}>
                                    Cancelado
                                </MenuItem>
                                <MenuItem key="5" value="erro_api" onClick={() => setSituation('erro_api')}>
                                    Denegado
                                </MenuItem>
                                <MenuItem key="6" value="aguardando" onClick={() => setSituation('aguardando')}>
                                    Aguardando
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Data inicial"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={initialDate}
                                onChange={(e) => setInitialDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <TextField
                                size="small"
                                type="date"
                                label="Data final"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={finalDate}
                                onChange={(e) => setFinalDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                className="inputSmaller"
                                margin="normal"
                                customInput={TextField}
                                variant="outlined"
                                withPrefix={false}
                                label="Valor mínimo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            R$
                                        </InputAdornment>
                                    ),
                                }}
                                value={minValue} 
                                onChange={(evt) => setMinValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-lg-2">
                            <NumericFormat
                                className="inputSmaller"
                                margin="normal"
                                customInput={TextField}
                                variant="outlined"
                                withPrefix={false}
                                label="Valor máximo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            R$
                                        </InputAdornment>
                                    ),
                                }}
                                value={maxValue} 
                                onChange={(evt) => setMaxValue(evt.target.value)}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                size="small"
                                label="Técnico"
                                margin="normal"
                                variant="outlined"
                                value={technical}
                                onChange={(e) => setTechnical(e.target.value)}
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <Button
                                onClick={handleClickSearch}
                                className="mr-3"
                            >
                                Pesquisar
                            </Button>

                            <Button
                                onClick={clearSearch}
                            >
                                Limpar
                            </Button>
                        </div>
                    </div>
            </Collapse>

                <div className="mt-3">                
                    <ListWithModalChangeSituation
                        invoice
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onClone={handleClickClone}
                        onDelete={handleClickDelete}
                        customButtons={
                            user.isAccountant == "n"
                            ?
                            [
                                {
                                    class: 'btn-light-info',
                                    content: (<i className='p-0 flaticon-upload'></i>),
                                    variant: BsVariant.INFO,
                                    popup: "Emitir nota fiscal",
                                    onClick: handleClickIssue,
                                    showCondition: issueButtonCondition,
                                },
                                {
                                    class: 'btn-green p-2 mr-3',
                                    content: (<img src="/media/icons/whatsapp.png" alt="Whatsapp" />),
                                    variant: BsVariant.SUCCESS,
                                    popup: "Envio de whatsapp",
                                    onClick: handleOpenSendWhatsappModal,
                                    showCondition: sendEmailAndWhatsappButtonCondition,
                                },
                                {
                                    class: 'btn-blue p-2 mr-3',
                                    content: (<i className="flaticon2-email p-0" style={{ color: "#fff" }}></i>),
                                    variant: BsVariant.SUCCESS,
                                    popup: "Envio de email",
                                    onClick: handleOpenSendEmailModal,
                                    showCondition: sendEmailAndWhatsappButtonCondition,
                                },
                                {
                                    class: 'btn-light-info',
                                    content: (<i className='p-0 flaticon-info'></i>),
                                    variant: BsVariant.INFO,
                                    popup: "Visualizar nota fiscal",
                                    onClick: handleClickDetails,
                                    showCondition: detailsButtonCondition,
                                },
                            ]
                            : [
                                {
                                    class: 'btn-light-info',
                                    content: (<i className='p-0 flaticon-info'></i>),
                                    variant: BsVariant.INFO,
                                    popup: "Visualizar nota fiscal",
                                    onClick: handleClickDetails,
                                    showCondition: detailsButtonCondition,
                                }
                            ]
                        }
                        selectCheckbox={{
                            onChange: handleChangeSelectedNfse,
                            checkedCondition: checkedConditionSelectedNfse,
                            showCondition: showConditionSelectNfse,
                            onChangeSelectAll: handleChangeSelectAllNfse,
                            checkedConditionSelectAll: checkedConditionSelectAllNfse,
                        }}
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalNf}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
        </>
    );
}