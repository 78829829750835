import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactPortal from "../ReactPortal";
import { IconButton, TextField } from "@material-ui/core";
import { StockLocations } from "../../types/StockLocations";
import api from "../../services/Api";
import LogService from "../../services/LogService";
import ModalSuccess from "../ModalSuccess";

type NewStockLocationModalProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCreateStockLocation: (createdStockLocation: StockLocations) => void;
    defaultData: String;
};

export function NewStockLocationModal({
    showModal,
    setShowModal,
    onCreateStockLocation,
    defaultData,
}: NewStockLocationModalProps) {
    const [name, setName] = useState(defaultData);
    const [description, setDescription] = useState<String>("");

    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    async function handleStockLocationCreated() {
        try {
            var raw = {
                name: name.length ? name : defaultData,
                description: description
            }

            if (raw.name && raw.description) {
                const createResponse = await api.post('/stockLocation', raw);

                LogService.logRegister({
                    itemId: createResponse.data.id,
                    module: 'Locais de Estoque',
                    itemName: createResponse.data.name,
                });

                setName('');
                setDescription('');
                handleCloseModal();

                onCreateStockLocation(createResponse.data);
                setMsgSuccess("Local de estoque criado com sucesso!");
                setShowModalSuccess(true);
            } else {
                alert('Preencha os campos antes de salvar!');
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ReactPortal containerId="portal-new-customer-modal">
            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
            />
            
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adicionar um Local de Estoque
                    </Modal.Title>

                    <IconButton
                        edge="end"
                        onClick={handleCloseModal}
                        title="Fechar"
                        className="py-0"
                    >
                        <i className="flaticon-close text-body"></i>
                    </IconButton>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Nome'
                                margin='normal'
                                variant='outlined'
                                value={name.length ? name : defaultData}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-12">
                            <TextField
                                size="small"
                                label='Descrição'
                                margin='normal'
                                variant='outlined'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => handleStockLocationCreated()}>Salvar</Button>
                    <Button variant="secondary" onClick={() => handleCloseModal()}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </ReactPortal>
    )
}