import React, { useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { orderByIdDesc } from '../utils/orderTable';

import api from '../services/Api';
import {
    BodyDataBaseProps,
    HeadDataBaseProps,
    ListWithModalChangeSituation,
    LoadDataParams,
} from '../components/ListWithModalChangeSituation';
import { sortByNumber } from '../utils/sortCallback';
import { formatCpfCnpj } from '../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { Search } from '../components/Search';
import useBackendLoad from '../hooks/backendReload';
import { useSelector } from 'react-redux';
import { Carrier } from '../types/Carrier';

type Filters = {
    searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: 'id', value: 'Nº' },
    { reference: 'name', value: 'Nome' },
    { reference: 'cpf', value: 'CNPJ/CPF' },
    { reference: 'phone', value: 'Telefone' },
];

export function ListCarriers() {
    const { user } = useSelector((state: any) => state.auth);
    
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalCarriers, setCountTotalCarriers] = useState(0);

    const [carriersFiltered, setCarriersFiltered] = useState<Carrier[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const { location: { pathname }, push: pushHistory } = useHistory();

    // useEffect(() => {
    //     handleClickSearch();
    // }, [carriers]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{rows: Carrier[], count: number}>("/carriers", {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setCarriers(rows);
        setCarriersFiltered(rows);
        setCountTotalCarriers(count);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = carriersFiltered;

        aux.forEach((carrier) => {
            let name = carrier.name;
            let cpf = formatCpfCnpj("CPF", carrier.cpf);
            const isLegal = carrier.typePeople === "legal";

            if (isLegal) {
                name = carrier.corporateName;
                cpf = formatCpfCnpj("CNPJ", carrier.cnpj);
            }

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(carrier.id), id: true },
                { for: "name", value: name },
                { for: "cpf", value: cpf },
                { for: "phone", value: formatPhoneNumber(carrier.phone) },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [carriersFiltered]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, [pathname]);

    const handleClickDelete = useCallback(async (id: string) => {
        try {
            const filtered = carriers.filter((carrier) => carrier.id !== Number(id));

            await api.delete(`carriers/${id}`);

            setCarriers([...filtered]);
        } catch (error) {
            console.log(error);
        }
    }, [carriers]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, [pathname]);

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery,
        };

        reloadData();
    }, [carriers, searchQuery]);

    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <div className="col-lg-9 mt-3">
                    {
                        user.isAccountant == "n"
                        ?
                        <Button
                            type="button"
                            variant="success"
                            className="mr-2"
                            onClick={() => handleClickAdd()}
                        >
                            Adicionar
                        </Button>
                        : <></>
                    }
                </div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>

            <div className="mt-3">
                <ListWithModalChangeSituation
                    bodyData={bodyData}
                    headData={headData}
                    onEdit={handleClickEdit}
                    onDelete={handleClickDelete}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalCarriers}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                />
            </div>
        </div>
    );
}