export const formatPhoneNumber = (value: string) => {
    const cleaned = ('' + value).replace(/\D/g, '');

    const matchMobile = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
    const matchPhone = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (matchMobile) {
        return `(${matchMobile[1]}) ${matchMobile[2]} ${matchMobile[3]}-${matchMobile[4]}`;
    }

    if (matchPhone) {
        return `(${matchPhone[1]}) ${matchPhone[2]}-${matchPhone[3]}`;
    }

    return "";
}