import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSubheader } from "../../../_metronic/layout";
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import '../../style.css';
import api from "../../services/Api";
import axios from 'axios';
import logService from '../../services/LogService';
import { api as ApiUrl } from '../../services/ApiURL';

import {
    makeStyles,
    MenuItem,
    TextField,
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    Grid,
} from '@material-ui/core';


import {
    Button,
    Image,
    Accordion,
    Card,
    Modal,
    Spinner,
    Tabs,
    Tab,
} from "react-bootstrap";
import { getTypePeopleText } from '../../utils/getTypePeopleText';
import { formatCpfCnpj } from '../../utils/formatCpfCnpj';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import customerService from '../../services/CustomerService';
import { getYesOrNoText } from '../../utils/getYesOrNoText';
import { getTaxRegimeText } from '../../utils/getTaxRegimeText';
import { getGenderText } from '../../utils/getGenderText';
import { useSelector } from 'react-redux';
import { dateIsBetweenRange, extractDateStringFromTimestamp } from '../../utils/dateTimeHelper';
import { Customer, limitCreditPeriodicity as limitCreditPeriodicityEnum } from "../../types/Customer";
import { NumericFormat } from '../../components/NumericFormat';
import CustomerService from '../../services/CustomerService';
import { formatCurrency, formatToFloat } from '../../utils/formatCurrency';
import ServiceContractService from '../../services/ServiceContractService';
import { deleteMultipleFiles, uploadImage, uploadMultipleFiles } from '../../utils/FilesHelper';
import UploadFile from '../../components/UploadFile';
import UploadFiles from '../../components/UploadFiles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        "& .Mui-error": {
            color: "#f64e60 !important"
        },
        "& .MuiFormHelperText-root": {
            color: "#f64e60 !important"
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f64e60 !important"
        }
    }
}));

type Inputs = {
    name: string,
    typeRegister: string,
    typePeople: string,
    categoryClassification: string,
    registrationStatus: string,
    birthDate: string,
    gender: string,
    cpf: string,
    inscriptionInTheSuframa: string,
    corporateName: string,
    fantasyName: string,
    cnpj: string,
    municipalRegistration: string,
    stateRegistration: string,
    exempt: string,
    cell: string,
    tell: string,
    ramal: string,
    fax: string,
    email: string,
    website: string,
    note: string,
    rg: string,
    issuingOrgan: string,
    issuingDate: string,
    passport: string,
    foreign: string,
    ruralProducer: string,
    isRuralProducer: string,
    profession: string,
    employer: string,
    isRetiree: string,
    maritalStatus: string,
    initialActivity: string,
    finalActivity: string,
    activityClosed: string,
    finalCostumer: string,
    taxpayerIcms: string,
    noteRemarks: string,
    priceList: string,
    paymentTerm: string,
    nameSeller: string,
    deliveryMode: string,
    carrying: string,
    allowToExceedLimit: string,
    taxRegime: string
}

type Categories = {
    id: number,
    nameCategory: string,
    status: string
}

type urlParam = {
    id: string
}

export function EditCustomer() {
    const { user } = useSelector((state: any) => state.auth);


    const classes = useStyles();

    const subHeader = useSubheader();
    subHeader.setTitle("Detalhes do Cliente / Fornecedor");

    const { register, handleSubmit, control, setValue, watch, formState: { errors } } = useForm<Inputs>();

    const { id } = useParams<urlParam>();

    const [activeTab, setActiveTab] = useState("details");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [isSubmit, setIsSubmit] = useState(false);

    const [currentLimitCreditThisPeriod, setCurrentLimitCreditThisPeriod] = useState<number | null>(null);

    const [issueDateInvalid, setIssueDateInvalid] = useState(false);
    const [nameInvalid, setNameInvalid] = useState(false);
    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
    const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [cellInvalid, setCellInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [limitCreditInvalid, setLimitCreditInvalid] = useState(false);
    // const [limitCreditPeriodicityInvalid, setLimitCreditPeriodicityInvalid] = useState(false);

    const [fileSelected, setFileSelected] = useState<File | null>(null);
    const [fileNameSaved, setFileNameSaved] = useState<string | null>(null);
    const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
    const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);


    const [msgError, setMsgError] = useState("");
    const [showModalExist, setShowModalExist] = useState(false);
    const [name, setName] = useState("");
    const [typeRegister, setTypeRegister] = useState("");
    const [typePeople, setTypePeople] = useState("");
    const [categoryClassification, setCategoryClassification] = useState("");
    const [registrationStatus, setRegistrationStatus] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [cpf, setCpf] = useState("");
    const [inscriptionInTheSuframa, setInscriptionInTheSuframa] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [fantasyName, setFantasyName] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [municipalRegistration, setMunicipalRegistration] = useState("");
    const [stateRegistration, setStateRegistration] = useState("");
    const [exempt, setExempt] = useState("");
    const [cell, setCell] = useState("");
    const [tell, setTell] = useState("");
    const [ramal, setRamal] = useState("");
    const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [note, setNote] = useState("");
    const [rg, setRg] = useState("");
    const [issuingOrgan, setIssuingOrgan] = useState("");
    const [issuingDate, setIssueDate] = useState("");
    const [passport, setPassport] = useState("");
    const [foreign, setForeign] = useState("");
    const [ruralProducer, setRuralProducer] = useState("");
    const [isRuralProducer, setIsRuralProducer] = useState("");
    const [profession, setProfession] = useState("");
    const [employer, setEmployer] = useState("");
    const [isRetiree, setIsRetiree] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [initialActivity, setInitialActivity] = useState("");
    const [finalActivity, setFinalActivity] = useState("");
    const [activityClosed, setActivityClosed] = useState("");
    const [finalCostumer, setFinalCostumer] = useState("");
    const [taxpayerIcms, setTaxpayerIcms] = useState("");
    const [noteRemarks, setNoteRemarks] = useState("");
    const [priceList, setPriceList] = useState("");
    const [paymentTerm, setPaymentTerm] = useState("");
    const [nameSeller, setNameSeller] = useState("");
    const [deliveryMode, setDeliveryMode] = useState("");
    const [carrying, setCarrying] = useState("");
    const [taxRegime, setTaxRegime] = useState("");
    const [limitCredit, setLimitCredit] = useState("");
    // const [limitCreditPeriodicity, setLimitCreditPeriodicity] = useState<string>("");
    const [allowToExceedLimit, setAllowToExceedLimit] = useState("");

    const [typePeopleAddress, setTypePeopleAddress] = useState("physical");
    const [showModal, setShowModal] = useState(false);
    const [addCategory, setAddCategory] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(true);
    const [nameCnpj, setNameCnpj] = useState("");
    const [fantasy, setFantasy] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [allCarriers, setAllCarriers] = useState({ carriers: [] });
    const [idCategory, setIdCategory] = useState(0);
    const [categoriesSelected, setCategoriesSelected] = useState<number[]>([]);
    const [categoryNameCreated, setCategoryNameCreated] = useState("");
    const [categoryStatusCreated, setCategoryStatusCreated] = useState("y");
    const [categoriesName, setCategoriesName] = useState<string[]>([]);
    const [categories, setCategories] = useState<Categories[]>([
        {
            id: 0,
            nameCategory: "",
            status: ""
        }
    ]);

    const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

    const [address, setAddress] = useState({
        addresses: [
            {
                default: true,
                id: "",
                zipcode: "",
                charge: false,
                delivery: false,
                withdrawal: false,
                address: "",
                number: "",
                district: "",
                city: "",
                state: "",
                country: "",
                complement: "",
                reference: "",
                typePeople: "physical",
                name: "",
                cpf: "",
                cnpj: "",
                phone: "",
                corporateName: "",
                stateRegistration: "",
                copyInformation: ""
            }
        ]
    });

    function addAddress() {
        const allAddress = address.addresses;

        allAddress.push({
            default: (!allAddress.length ? true : false),
            id: "",
            zipcode: "",
            charge: false,
            delivery: false,
            withdrawal: false,
            address: "",
            number: "",
            district: "",
            city: "",
            state: "",
            country: "",
            complement: "",
            reference: "",
            typePeople: "physical",
            name: "",
            cpf: "",
            cnpj: "",
            phone: "",
            corporateName: "",
            stateRegistration: "",
            copyInformation: ""
        });

        setAddress({ addresses: allAddress });
    }

    const [contact, setContact] = useState({
        contacts: [
            {
                id: "",
                typeContact: "",
                relationship: "",
                isLegalResponsible: "n",
                name: "",
                birthDate: "",
                gender: "",
                cell: "",
                phone: "",
                extension: "",
                fax: "",
                email: "",
                website: "",
                note: ""
            }
        ]
    });

    function addContact() {
        var allContacts = contact.contacts;

        allContacts.push({
            id: "",
            typeContact: "",
            relationship: "",
            isLegalResponsible: "n",
            name: "",
            birthDate: "",
            gender: "",
            cell: "",
            phone: "",
            extension: "",
            fax: "",
            email: "",
            website: "",
            note: ""
        });

        setContact({ contacts: allContacts });
    }

    function copyInfoOfRegister(checkbox: any, index: number) {
        var addressChanged = address.addresses;

        if (checkbox) {
            addressChanged[index].typePeople = typePeople;

            if (typePeople === "physical") {
                addressChanged[index].name = name;
                addressChanged[index].cpf = cpf;

            } else {
                addressChanged[index].corporateName = corporateName;
                addressChanged[index].cnpj = cnpj;
                addressChanged[index].stateRegistration = stateRegistration;
            }
            addressChanged[index].phone = tell;

            setAddress({ addresses: addressChanged });
        }
    }

    function handleChangePage(event: any, newPage: number) {
        setPage(newPage);
    }

    function changeTypeContact(i: number, value: string) {
        var allContacts = contact.contacts;

        allContacts[i].typeContact = value;

        setContact({ contacts: allContacts });
    }

    function changeDefaultAddress(index: number) {
        var allAddress = address.addresses;

        allAddress.map((value, i) => {
            allAddress[i].default = false;
        });

        allAddress[index].default = !allAddress[index].default;

        setAddress({ addresses: allAddress });
    }

    async function infoAddress(index: number) {
        const checkCep = async () => {
            var allAddress = address.addresses;

            if (!allAddress[index].zipcode) return;

            const zipcode = allAddress[index].zipcode.replace(/[^0-9]/g, "");

            const cep = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

            allAddress[index].address = cep.data.logradouro;
            allAddress[index].district = cep.data.bairro;
            allAddress[index].city = cep.data.localidade;
            allAddress[index].state = cep.data.uf;
            if (cep.data.logradouro) {
                allAddress[index].country = "Brasil";
            }

            setAddress({ addresses: allAddress });
        }
        checkCep();
    }

    const [allStates, setAllStates] = useState({ states: [] as any[] });

    useEffect(() => {
        getCustomer();

        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: [...statesBrazil.data, { id: 0, sigla: 'EX', nome: 'Exterior' }] });
        }
        getStates();
        getCarrier();
        getAddressUser();
        getContactUser();
    }, []);

    async function getCustomer() {
        const customer = await CustomerService.getCustomerById(id);

        setName(customer.name);
        setTypeRegister(customer.typeRegister);
        setTypePeople(customer.typePeople);
        setCategoryClassification(customer.categoryClassification);
        setRegistrationStatus(customer.registrationStatus);
        setBirthDate(customer.birthDate);
        setGender(customer.gender);
        setCpf(customer.cpf);
        setInscriptionInTheSuframa(customer.inscriptionInTheSuframa);
        setCorporateName(customer.corporateName);
        setFantasyName(customer.fantasyName);
        setCnpj(customer.cnpj);
        setExempt(customer.exempt);
        setMunicipalRegistration(customer.municipalRegistration);
        setStateRegistration(customer.stateRegistration);
        setCell(customer.cell);
        setTell(customer.phone);
        setRamal(customer.extension);
        setFax(customer.fax);
        setEmail(customer.email);
        setWebsite(customer.website);
        setNote(customer.note);
        setRg(customer.rg);
        setIssuingOrgan(customer.issuingAgency);
        setIssueDate(customer.issueDate);
        setPassport(customer.passport);
        setForeign(customer.isForeign);
        setRuralProducer(customer.ruralProducerRegistration);
        setIsRuralProducer(customer.isRuralProducer);
        setProfession(customer.profession);
        setEmployer(customer.employer);
        setIsRetiree(customer.isRetiree);
        setMaritalStatus(customer.maritalStatus);
        setInitialActivity(customer.startOfActivity);
        setFinalActivity(customer.finalOfActivity);
        setActivityClosed(customer.activityClosed);
        setFinalCostumer(customer.finalCostumer);
        setTaxpayerIcms(customer.icmsTaxpayer);
        setNoteRemarks(customer.noteRemarks);
        setPriceList(customer.priceList);
        setPaymentTerm(customer.paymentTerm);
        setNameSeller(customer.nameSeller);
        setDeliveryMode(customer.deliveryMode);
        setCarrying(customer.carrying);
        setLimitCredit(customer.limitCredit !== null ? formatCurrency(customer.limitCredit) : '');
        // setLimitCreditPeriodicity(customer.limitCreditPeriodicity ?? '');
        setAllowToExceedLimit(customer.allowToExceedLimit);
        setTaxRegime(customer.taxRegime);
        setFileNameSaved(customer.photo);
        setFilesNamesSaved(customer.attachments ? JSON.parse(customer.attachments) : []);

        if (customer.limitCredit !== null && customer.limitCreditPeriodicity) {
            const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(customer.id);
            setCurrentLimitCreditThisPeriod(limitCreditInfo.currentCreditThisPeriod);
        } else {
            setCurrentLimitCreditThisPeriod(null);
        }

        setBeforeSubmitData((prevState) => (
            {
                ...prevState,
                ...customer,
            }
        ));
    }

    async function getCarrier() {
        var response = await api.get("/carriers");
        setAllCarriers({ carriers: response.data });
    }

    async function getAddressUser() {
        var addressUser = await api.get(`/address/user/${id}`);
        const allAddress = address.addresses;

        for (let index = 0; index < addressUser.data.length; index++) {

            if (allAddress[0].id === "" && index === 0) {
                allAddress[0].default = ((addressUser.data[index].defaultAddress === "y") ? true : false);
                allAddress[0].id = addressUser.data[index].id;
                allAddress[0].zipcode = addressUser.data[index].zipcode;
                allAddress[0].charge = (addressUser.data[index].charge === "y" ? true : false);
                allAddress[0].delivery = (addressUser.data[index].delivery === "y" ? true : false);
                allAddress[0].withdrawal = (addressUser.data[index].withdrawal === "y" ? true : false);
                allAddress[0].address = addressUser.data[index].publicPlace;
                allAddress[0].number = addressUser.data[index].number;
                allAddress[0].district = addressUser.data[index].district;
                allAddress[0].city = addressUser.data[index].city;
                allAddress[0].state = addressUser.data[index].state;
                allAddress[0].country = addressUser.data[index].country;
                allAddress[0].complement = addressUser.data[index].complement;
                allAddress[0].reference = addressUser.data[index].reference;
                allAddress[0].typePeople = addressUser.data[index].typePeople;
                allAddress[0].name = addressUser.data[index].name;
                allAddress[0].cpf = addressUser.data[index].cpf;
                allAddress[0].cnpj = addressUser.data[index].cnpj;
                allAddress[0].phone = addressUser.data[index].phone;
                allAddress[0].corporateName = addressUser.data[index].corporateName;
                allAddress[0].stateRegistration = addressUser.data[index].stateRegistration;
                allAddress[0].copyInformation = "";


            } else {
                allAddress.push({
                    default: ((addressUser.data[index].defaultAddress === "y") ? true : false),
                    id: addressUser.data[index].id,
                    zipcode: addressUser.data[index].zipcode,
                    charge: (addressUser.data[index].charge === "y" ? true : false),
                    delivery: (addressUser.data[index].delivery === "y" ? true : false),
                    withdrawal: (addressUser.data[index].withdrawal === "y" ? true : false),
                    address: addressUser.data[index].publicPlace,
                    number: addressUser.data[index].number,
                    district: addressUser.data[index].district,
                    city: addressUser.data[index].city,
                    state: addressUser.data[index].state,
                    country: addressUser.data[index].country,
                    complement: addressUser.data[index].complement,
                    reference: addressUser.data[index].reference,
                    typePeople: addressUser.data[index].typePeople,
                    name: addressUser.data[index].name,
                    cpf: addressUser.data[index].cpf,
                    cnpj: addressUser.data[index].cnpj,
                    phone: addressUser.data[index].phone,
                    corporateName: addressUser.data[index].corporateName,
                    stateRegistration: addressUser.data[index].stateRegistration,
                    copyInformation: ""
                });
            }

        }
        setAddress({ addresses: allAddress });
    }

    async function getContactUser() {
        var contactUser = await api.get(`/contact/user/${id}`);
        const allContact = contact.contacts;

        for (let index = 0; index < contactUser.data.length; index++) {

            if (allContact[0].id === "" && index === 0) {
                allContact[0].id = contactUser.data[index].id;
                allContact[0].typeContact = contactUser.data[index].typeContact;
                allContact[0].relationship = contactUser.data[index].relationship;
                allContact[0].isLegalResponsible = contactUser.data[index].isLegalResponsible;
                allContact[0].name = contactUser.data[index].name;
                allContact[0].birthDate = contactUser.data[index].birthDate;
                allContact[0].gender = contactUser.data[index].gender;
                allContact[0].cell = contactUser.data[index].cell;
                allContact[0].phone = contactUser.data[index].phone;
                allContact[0].extension = contactUser.data[index].extension;
                allContact[0].fax = contactUser.data[index].fax;
                allContact[0].email = contactUser.data[index].email;
                allContact[0].website = contactUser.data[index].website;
                allContact[0].note = contactUser.data[index].note;

            } else {
                allContact.push({
                    id: contactUser.data[index].id,
                    typeContact: contactUser.data[index].typeContact,
                    relationship: contactUser.data[index].relationship,
                    isLegalResponsible: contactUser.data[index].isLegalResponsible,
                    name: contactUser.data[index].name,
                    birthDate: contactUser.data[index].birthDate,
                    gender: contactUser.data[index].gender,
                    cell: contactUser.data[index].cell,
                    phone: contactUser.data[index].phone,
                    extension: contactUser.data[index].extension,
                    fax: contactUser.data[index].fax,
                    email: contactUser.data[index].email,
                    website: contactUser.data[index].website,
                    note: contactUser.data[index].note
                })
            }
        }
        setContact({ contacts: allContact });
    }

    function removeAddress(index: number, idAddress: string) {
        var addressChanged = address.addresses;
        addressChanged.splice(index, 1);
        setAddress({ addresses: addressChanged });
        api.delete(`/address/${idAddress}`);
    }

    function removeContact(index: number, idContact: string) {
        var contactChanged = contact.contacts;
        contactChanged.splice(index, 1);
        setContact({ contacts: contactChanged });
        api.delete(`/contact/${idContact}`);
    }

    function cleanInputs() {
        setName("");
        setBirthDate("");
        setGender("");
        setCpf("");

        setCorporateName("");
        setFantasyName("");
        setCnpj("");
        setMunicipalRegistration("");
        setStateRegistration("");
        setExempt("");
    }

    async function CheckCnpj(value: string) {
        if (value) {
            const cnpjFormated = value.replace(/[^0-9]/g, "");

            var response = await axios.get(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${cnpjFormated}`);

            if (response.data.status === 'ERROR') {
                setMsgError(response.data.message);
                setShowModalExist(true);
                setCnpjInvalid(true);
                setCnpj('');
                return;
            }

            setCorporateName(response.data.nome);
            setFantasyName(response.data.fantasia);
            setEmail(response.data.email);
            setTell(response.data.telefone);

            var allAddress = address.addresses;

            allAddress[0].zipcode = response.data.cep;
            allAddress[0].number = response.data.numero;
            setAddress({ addresses: allAddress });
            infoAddress(0);
        }
    }

    async function checkEmailCustomerExist() {

        // if (email) {
        //     var response = await api.get(`/customer/emailExist/${email}`);

        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("E-mail informado já existe!");
        //         setShowModalExist(true);
        //         setEmail("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkCpfCustomerExist() {
        
        // if (cpf) {
        //     var cpfFormated = cpf.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/customer/cpfExist/${cpfFormated}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("Cpf informado já existe!");
        //         setShowModalExist(true);
        //         setCpf("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkRgCustomerExist() {
        
        // if (rg) {
        //     var rgFormated = rg.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/customer/rgExist/${rgFormated}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("additional-info");
        //         setMsgError("Rg informado já existe!");
        //         setShowModalExist(true);
        //         setRg("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkCnpjCustomerExist() {
        
        // if (cnpj) {
        //     var cnpjFormated = cnpj.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/customer/cnpjExist/${cnpjFormated}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("Cnpj informado já existe!");
        //         setShowModalExist(true);
        //         setCnpj("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkIMCustomerExist() {
        
        // if (municipalRegistration) {
        //     var imFormat = municipalRegistration;
        //     var response = await api.get(`/customer/imExist/${imFormat}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("Inscrição municipal informada já existe!");
        //         setShowModalExist(true);
        //         setMunicipalRegistration("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkIECustomerExist() {
        
        // if (stateRegistration) {
        //     var ieFormat = stateRegistration
        //     var response = await api.get(`/customer/ieExist/${ieFormat}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("Inscrição estadual informada já existe!");
        //         setShowModalExist(true);
        //         setStateRegistration("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkCellCustomerExist() {
        // if (cell) {
        //     var cellFormated = cell.replace(/[^0-9]/g, "");
        //     var response = await api.get(`/customer/cellExist/${cellFormated}`);
        //     if (response.data.length > 0 && response.data[0].id != id) {
        //         setActiveTab("details");
        //         setMsgError("Celular informado já existe!");
        //         setShowModalExist(true);
        //         setCell("");
        //         return false;
        //     }
        // }
        return true;
    }

    async function checkEmailContactExist(index: number) {
        return;
        var allContact = contact.contacts;
        if (allContact[index].email) {
            var response = await api.get(`/contact/emailExist/${allContact[index].email}`);

            if (response.data.length > 0 && response.data[0].id != allContact[index].id) {
                setActiveTab("contacts");
                setMsgError("E-mail informado já existe!");
                setShowModalExist(true);
                allContact[index].email = "";

                setContact({ contacts: allContact });

            } else {

                for (let i = 0; i < allContact.length; i++) {

                    if (allContact[i].email === allContact[index].email && i != index) {
                        setActiveTab("contacts");
                        setMsgError("E-mail informado já existe!");
                        setShowModalExist(true);
                        allContact[index].email = "";
                    }

                }
                setContact({ contacts: allContact });

            }
        }

    }

    function sanitizeInput(type: string, value: string): string {

        if (
            type === "cpf" ||
            type === "cell" ||
            type === "cnpj"
        ) {

            var str = value
            str = str.replaceAll(/[^0-9]+/g, "")

            return str

        } else if ("email") {

            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(String(value).toLowerCase()).toString()

        }

        return ""

    }

    async function inputsVerify(): Promise<boolean> {
        setIssueDateInvalid(false);
        setNameInvalid(false);
        setCpfInvalid(false);
        setCorporateNameInvalid(false);
        setFantasyNameInvalid(false);
        setCnpjInvalid(false);
        setCellInvalid(false);
        setEmailInvalid(false);
        setLimitCreditInvalid(false);
        // setLimitCreditPeriodicityInvalid(false);

        if (issuingDate) {
            const dateIsValid = dateIsBetweenRange(issuingDate, '', extractDateStringFromTimestamp());
            if (!dateIsValid) {
                setActiveTab("additional-info");
                setIsSubmit(false)
                setMsgError("A data de emissão não pode ser maior que a data atual");
                setShowModalExist(true);
                setIssueDateInvalid(true);
                return false;
            }
        }

        if (typePeople === "physical") {

            if (!name) {
                setActiveTab("details");
                setIsSubmit(false)
                setMsgError("Preencha o nome corretamente")
                setShowModalExist(true);
                setNameInvalid(true);


                return false

            } else if (!cpf || sanitizeInput("cpf", cpf).length !== 11) {
                setActiveTab("details");
                setIsSubmit(false)
                setMsgError("Preencha o cpf corretamente")
                setShowModalExist(true);
                setCpfInvalid(true);


                return false

            }

        } else if (typePeople === "legal") {

            if (!corporateName) {

                setIsSubmit(false)
                setMsgError("Preencha a razão social da empresa")
                setShowModalExist(true);
                setCorporateNameInvalid(true);


                return false

            } else if (!fantasyName) {

                setIsSubmit(false)
                setMsgError("Preencha o nome fantasia da empresa")
                setShowModalExist(true);
                setFantasyNameInvalid(true);


                return false

            } else if (!cnpj || sanitizeInput("cnpj", cnpj).length !== 14) {
                setActiveTab("details");
                setIsSubmit(false)
                setMsgError("Preencha o CNPJ corretamente")
                setShowModalExist(true);
                setCnpjInvalid(true);


                return false

            }
        }

        if (!cell || sanitizeInput("cell", cell).length !== 11) {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o celular corretamente")
            setShowModalExist(true);
            setCellInvalid(true);


            return false

        }

        if (!email || sanitizeInput("email", email) === "false") {
            setActiveTab("details");
            setIsSubmit(false)
            setMsgError("Preencha o email corretamente")
            setShowModalExist(true);
            setEmailInvalid(true);


            return false

        }

        if (limitCredit) {
            // if ((limitCredit && !limitCreditPeriodicity) || (!limitCredit && limitCreditPeriodicity)) {
            //     setActiveTab("details");
            //     setIsSubmit(false);
            //     setMsgError("Limite de crédito e periodicidade devem estar ambos preenchidos, ou ambos vazios");
            //     setShowModalExist(true);
            //     setLimitCreditInvalid(true);
            //     setLimitCreditPeriodicityInvalid(true);
            //     return false;
            // }

            const customerContracts = await ServiceContractService.getServiceContractsCountByCustomerId(id);
            if (customerContracts > 0) {
                setActiveTab("details");
                setIsSubmit(false);
                setMsgError("Não é possível definir um limite de crédito pois este registro está vinculado à um contrato (menu Serviços -> Contratos)");
                setShowModalExist(true);
                setLimitCreditInvalid(true);
                // setLimitCreditPeriodicityInvalid(true);
                return false;
            }
        }

        return true;

    }

    async function onSubmit(data: Inputs) {
        setIsSubmit(true);

        const inputsValid = await inputsVerify();

        if (!inputsValid) return;

        if (
            !(await checkEmailCustomerExist())
            || !(await checkCellCustomerExist())
            || !(await checkCpfCustomerExist())
            || !(await checkCnpjCustomerExist())
            || !(await checkRgCustomerExist())
            || !(await checkIECustomerExist())
            || !(await checkIMCustomerExist())
        ) {
            setIsSubmit(false);
            return;
        }

        // if (typePeople ==="legal") {
        //     var responseCnpj = await axios.get(`https://clientedc.com.br/api/consultaCNPJ.php?cnpj=${sanitizeInput("cnpj", cnpj)}`);

        //     if (responseCnpj.data.status == "ERROR") {
        //         setIsSubmit(false);
        //         setMsgError("CNPJ Inválido");
        //         setShowModalExist(true);
        //         setCnpjInvalid(true);
        //         return false;

        //     }
        // }

        let photoPath: string | boolean | null  = "";
        if (fileSelected) {
            photoPath = await uploadImage(fileSelected, setShowModalExist, setMsgError, setIsSubmit);
            if (photoPath == false) {
                return;
            }
        } else {
            photoPath = fileNameSaved;
        }

        const customer = await CustomerService.getCustomerById(id);
        const filesRemained = await deleteMultipleFiles(customer.attachments ? JSON.parse(customer.attachments) : [], filesNamesSaved, setShowModalExist, setMsgError, setIsSubmit);
        let filesPath: any  = [];
        if (filesSelected) {
            filesPath = await uploadMultipleFiles(filesSelected, setShowModalExist, setMsgError, setIsSubmit);            
            if (filesPath === false) {
                return;
            }
        } else {
            filesPath = [];
        }


        var customerRaw = JSON.stringify({
            "name": (!name ? null : name),
            "birthDate": (!birthDate ? null : birthDate),
            "gender": (!gender ? null : gender),
            "cpf": (!cpf ? null : cpf.replace(/[^0-9]/g, "")),
            "rg": (!rg ? null : rg.replace(/[^0-9]/g, "")),
            "issuingAgency": issuingOrgan,
            "issueDate": issuingDate,
            "passport": passport,
            "isForeign": foreign,
            "ruralProducerRegistration": ruralProducer,
            "isRuralProducer": isRuralProducer,
            "profession": profession,
            "employer": employer,
            "isRetiree": isRetiree,
            "maritalStatus": maritalStatus,
            "startOfActivity": initialActivity,
            "finalOfActivity": finalActivity,
            "activityClosed": activityClosed,
            "cnpj": (!cnpj ? null : cnpj.replace(/[^0-9]/g, "")),
            "exempt": (exempt ? exempt : "n"),
            "inscriptionInTheSuframa": inscriptionInTheSuframa,
            "corporateName": (!corporateName ? null : corporateName),
            "fantasyName": (!fantasyName ? null : fantasyName),
            "municipalRegistration": municipalRegistration,
            "stateRegistration": stateRegistration,
            "cell": (!cell ? null : cell.replace(/[^0-9]/g, "")),
            "phone": (!tell ? null : tell.replace(/[^0-9]/g, "")),
            "extension": ramal,
            "fax": fax,
            "email": (!email ? null : email),
            "website": website,
            "note": note,
            "registrationStatus": registrationStatus,
            "typePeople": typePeople,
            "typeRegister": typeRegister,
            "finalCostumer": finalCostumer,
            "icmsTaxpayer": taxpayerIcms ?? 'n',
            "noteRemarks": noteRemarks,
            "priceList": priceList,
            "paymentTerm": paymentTerm,
            "seller": nameSeller,
            "deliveryMode": deliveryMode,
            "carrying": carrying,
            "limitCredit": limitCredit ? formatToFloat(limitCredit) : null,
            // "limitCreditPeriodicity": limitCreditPeriodicity || null,
            "allowToExceedLimit": allowToExceedLimit,
            "taxRegime": taxRegime || null,
            "photo": photoPath,
            "attachments": JSON.stringify([...filesRemained, ...filesPath])
        });

        try {
            const responseCustomer = await api.post(`/customer/${id}`, customerRaw);

            address.addresses.map(async (value, index) => {
                var addressRaw = JSON.stringify({
                    "customerId": id,
                    "zipcode": value.zipcode,
                    "publicPlace": value.address,
                    "number": parseInt(value.number),
                    "district": value.district,
                    "city": value.city,
                    "state": value.state,
                    "country": value.country,
                    "defaultAddress": (value.default ? "y" : "n"),
                    "charge": (value.charge ? "y" : "n"),
                    "delivery": (value.delivery ? "y" : "n"),
                    "withdrawal": (value.withdrawal ? "y" : "n"),
                    "complement": value.complement,
                    "reference": value.reference,
                    "typePeople": value.typePeople,
                    "name": value.name,
                    "cpf": (value.cpf || '').replace(/[^0-9]/g, ""),
                    "cnpj": (value.cnpj || '').replace(/[^0-9]/g, ""),
                    "phone": (value.phone || '').replace(/[^0-9]/g, ""),
                    "corporateName": value.corporateName,
                    "stateRegistration": value.stateRegistration,
                });

                if (value.id) {
                    await api.post(`/address/${value.id}`, addressRaw);

                } else {
                    await api.post(`/address`, addressRaw);
                }

            });

            contact.contacts.map(async (value, index) => {
                var contactRaw = JSON.stringify({
                    "customerId": id,
                    "typeContact": value.typeContact || 'family',
                    "relationship": value.relationship,
                    "isLegalResponsible": value.isLegalResponsible,
                    "name": value.name,
                    "birthDate": (!value.birthDate ? null : value.birthDate),
                    "gender": (!value.gender ? null : value.gender),
                    "cell": value.cell.replace(/[^0-9]/g, ""),
                    "phone": value.phone.replace(/[^0-9]/g, ""),
                    "extension": value.extension,
                    "fax": value.fax,
                    "email": (!value.email ? null : value.email),
                    "website": value.website,
                    "note": value.note
                });

                if (value.id) {
                    await api.post(`/contact/${value.id}`, contactRaw);

                } else {
                    await api.post(`/contact/`, contactRaw);
                }
            });

            logService.logEdit({
                itemId: responseCustomer.data.id,
                itemName: responseCustomer.data.typePeople === 'physical' ? responseCustomer.data.name : responseCustomer.data.corporateName,
                module: 'Clientes / Fornecedores',
                oldData: beforeSubmitData,
                newData: responseCustomer.data,
                formattedFields: {
                    typePeople: (value) => getTypePeopleText(value),
                    gender: (value) => getGenderText(value),
                    cpf: (value) => formatCpfCnpj('CPF', value),
                    cnpj: (value) => formatCpfCnpj('CNPJ', value),
                    phone: (value) => formatPhoneNumber(value),
                    cell: (value) => formatPhoneNumber(value),
                    typeRegister: (value) => customerService.getTypeRegisterText(value),
                    exempt: (value) => getYesOrNoText(value),
                    issueDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    isForeign: (value) => getYesOrNoText(value),
                    isRuralProducer: (value) => getYesOrNoText(value),
                    isRetiree: (value) => getYesOrNoText(value),
                    activityClosed: (value) => getYesOrNoText(value),
                    maritalStatus: (value) => customerService.getMaritalStatusText(value),
                    startOfActivity: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    finalOfActivity: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                    finalCostumer: (value) => getYesOrNoText(value),
                    icmsTaxpayer: (value) => getYesOrNoText(value),
                    taxRegime: (value) => getTaxRegimeText(value),
                    limitCredit: (value) => formatCurrency(value),
                    // limitCreditPeriodicity: (value) => customerService.getLimitCreditPeriodicityText(value),
                },
                fieldsMap: {
                    typeRegister: 'Tipo de cadastro',
                    typePeople: 'Tipo de pessoa',
                    name: 'Nome',
                    cpf: 'CPF',
                    rg: 'RG',
                    cnpj: 'CNPJ',
                    corporateName: 'Razão social',
                    fantasyName: 'Nome fantasia',
                    municipalRegistration: 'Inscrição Municipal',
                    stateRegistration: 'Inscrição Estadual',
                    exempt: 'Isento',
                    birthDate: 'Data de nascimento',
                    gender: 'Gênero',
                    email: 'E-mail',
                    cell: 'Celular',
                    phone: 'Telefone',
                    extension: 'Ramal',
                    fax: 'Fax',
                    website: 'Site ou Perfil',
                    note: 'Observações',
                    issuingAgency: 'Órgão expedidor',
                    issueDate: 'Data de emissão',
                    passport: 'Passaporte',
                    isForeign: 'Estrangeiro',
                    ruralProducerRegistration: 'Inscrição produtor rural',
                    isRuralProducer: 'Produtor rural',
                    profession: 'Profissão',
                    employer: 'Empregador',
                    isRetiree: 'Aposentado',
                    maritalStatus: 'Estado Civíl',
                    inscriptionInTheSuframa: 'Inscrição na Suframa',
                    startOfActivity: 'Início da atividade',
                    finalOfActivity: 'Final da ativadade',
                    activityClosed: 'Atividade encerrada',
                    finalCostumer: 'Consumidor final',
                    icmsTaxpayer: 'Contribuinte ICMS',
                    taxRegime: 'Regime tributário',
                    noteRemarks: 'Observações da nota',
                    limitCredit: 'Limite de Crédito',
                    // limitCreditPeriodicity: 'Periodicidade para o limite',
                },
            });

            setShowModalSuccess(true);
        } catch (error) {
            console.log('error', error);
            setMsgError("Ocorreu um erro ao processar a requisição")
            setShowModalExist(true);
        }

        setIsSubmit(false);
    }

    return (
        <div className="row card card-body p-4 newProductWrapper">
            <Modal
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    window.location.href = "/clientes";
                }}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                        Sucesso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Dados editados com sucesso !</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModalSuccess(!showModalSuccess);
                        window.location.href = "/clientes";
                    }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalExist}
                onHide={() => setShowModalExist(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalExist(!showModalExist)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.container} noValidate autoComplete="off">

                <div className="col-lg-12">
                    <Grid container spacing={3} className="mb-3">
                        <Grid item lg={3}>
                            <TextField
                                select
                                size="small"
                                label="Tipo de cadastro"
                                className={classes.textField}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                defaultValue="customer"
                                value={typeRegister}
                                onChange={e => setTypeRegister(e.target.value)}
                                disabled={user.isAccountant == "y" ? true : false}
                            >
                                <MenuItem key="0" value="customer">
                                    Cliente
                                </MenuItem>

                                <MenuItem key="1" value="supplier">
                                    Fornecedor
                                </MenuItem>

                                <MenuItem key="2" value="both">
                                    Ambos
                                </MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item lg={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                label="Tipo de pessoa"
                                className={classes.textField}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                variant="outlined"
                                value={typePeople}
                                onChange={e => { setTypePeople(e.target.value); cleanInputs() }}
                                disabled={user.isAccountant == "y" ? true : false}
                            >
                                <MenuItem key="0" value="physical" onClick={() => setTypePeople("physical")}>
                                    Pessoa Física
                                </MenuItem>

                                <MenuItem key="1" value="legal" onClick={() => setTypePeople("legal")}>
                                    Pessoa Jurídica
                                </MenuItem>

                            </TextField>
                        </Grid>

                        {(typePeople === "physical")
                            ?
                            <>
                                <Grid item lg>
                                    <TextField
                                        label="Nome"
                                        required
                                        size="small"
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                            setNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={nameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>
                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Grid item lg={1} className="d-flex align-items-end justify-content-end">
                                            <Button
                                                type='submit'
                                                className='mb-2'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Salvar
                                                    </span>

                                                </>}


                                            </Button>
                                        </Grid>
                                        : <></>
                                }
                            </>
                            :
                            <>
                                <Grid item lg>
                                    <TextField
                                        value={corporateName}
                                        label="Razão social"
                                        required
                                        size="small"
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setCorporateName(e.target.value)
                                            setCorporateNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={corporateNameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg>
                                    <TextField
                                        value={fantasyName}
                                        label="Nome fantasia"
                                        required
                                        size="small"
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setFantasyName(e.target.value)
                                            setFantasyNameInvalid(e.target.value ? false : true)
                                        }}
                                        error={fantasyNameInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Grid item lg={1} className="d-flex align-items-end">
                                            <Button
                                                type='submit'
                                                className='mb-2'
                                                variant="primary"
                                                disabled={isSubmit}
                                            >

                                                {isSubmit ? <>

                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </> : <>

                                                    <span>
                                                        Salvar
                                                    </span>

                                                </>}


                                            </Button>
                                        </Grid>
                                        : <></>
                                }
                            </>
                        }
                    </Grid>

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(tab: string) => setActiveTab(tab)}
                        id='newproduct-form-tabs'
                        className="mb-3"
                    >
                        <Tab
                            eventKey='details'
                            title="Detalhes"
                        >
                            {(typePeople === "physical")
                                ?
                                <Grid container spacing={3}>
                                    <Grid item lg={3}>
                                        <TextField
                                            type="date"
                                            label="Data de nascimento"
                                            className={classes.textField}
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={birthDate}
                                            onChange={e => setBirthDate(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={3}>
                                        <TextField
                                            select
                                            label="Gênero"
                                            size="small"
                                            className={classes.textField}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={gender}
                                            onChange={e => setGender(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        >
                                            <MenuItem key="0" value="">
                                                Selecione
                                            </MenuItem>

                                            <MenuItem key="1" value="male">
                                                Masculino
                                            </MenuItem>

                                            <MenuItem key="2" value="female">
                                                Feminino
                                            </MenuItem>

                                        </TextField>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <NumberFormat
                                            margin="normal"
                                            className="inputSmaller"
                                            customInput={TextField}
                                            variant="outlined"
                                            format="###.###.###-##"
                                            label="CPF"
                                            required
                                            mask="_"
                                            value={cpf}
                                            onChange={(e) => {
                                                setCpf(e.target.value)
                                                setCpfInvalid(e.target.value ? false : true)
                                            }}
                                            error={cpfInvalid}
                                            onBlur={() => checkCpfCustomerExist()}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2}>
                                        <NumberFormat
                                            margin="normal"
                                            className="inputSmaller"
                                            customInput={TextField}
                                            variant="outlined"
                                            format="(##) #####-####"
                                            label="Celular"
                                            required
                                            mask="_"
                                            value={cell}
                                            onChange={(e) => {
                                                setCell(e.target.value)
                                                setCellInvalid(e.target.value ? false : true)
                                            }}
                                            error={cellInvalid}
                                            onBlur={() => checkCellCustomerExist()}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>

                                    <Grid item lg={2}>
                                        <NumberFormat
                                            margin="normal"
                                            className="inputSmaller"
                                            customInput={TextField}
                                            variant="outlined"
                                            format="(##) ####-####"
                                            label="Telefone"
                                            mask="_"
                                            value={tell}
                                            onChange={e => setTell(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                <>

                                    <Grid container spacing={3}>
                                        <Grid item lg={2} className="pr-1">
                                            <NumberFormat
                                                margin="normal"
                                                className="inputSmaller pl-3"
                                                customInput={TextField}
                                                variant="outlined"
                                                label="CNPJ"
                                                required
                                                format="##.###.###/####-##"
                                                mask="_"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Toggle password visibility"
                                                                onClick={() => CheckCnpj(cnpj)}
                                                            >
                                                                <i className="flaticon-search"></i>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={cnpj}
                                                onChange={(e) => {
                                                    setCnpj(e.target.value)
                                                    setCnpjInvalid(e.target.value ? false : true)
                                                }}
                                                error={cnpjInvalid}
                                                onBlur={() => checkCnpjCustomerExist()}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={1} className="d-flex align-items-center">
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={e => setExempt((e.target.checked) ? "y" : "n")} checked={(exempt === "y") ? true : false} />}
                                                label="Isento"
                                                labelPlacement="end"
                                                className="ml-1"
                                            />
                                        </Grid>

                                        <Grid item lg={2}>
                                            <TextField
                                                size="small"
                                                label="Inscrição municipal"
                                                className={classes.textField}
                                                margin="normal"
                                                variant="outlined"
                                                value={municipalRegistration}
                                                onChange={e => setMunicipalRegistration(e.target.value)}
                                                onBlur={() => checkIMCustomerExist()}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={2}>
                                            <TextField
                                                size="small"
                                                label="Inscrição estadual"
                                                className={classes.textField}
                                                margin="normal"
                                                variant="outlined"
                                                value={stateRegistration}
                                                onChange={e => setStateRegistration(e.target.value)}
                                                onBlur={() => checkIECustomerExist()}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={2}>
                                            <NumberFormat
                                                margin="normal"
                                                className="inputSmaller"
                                                customInput={TextField}
                                                variant="outlined"
                                                format="(##) #####-####"
                                                label="Celular"
                                                required
                                                mask="_"
                                                value={cell}
                                                onChange={(e) => {
                                                    setCell(e.target.value)
                                                    setCellInvalid(e.target.value ? false : true)
                                                }}
                                                error={cellInvalid}
                                                onBlur={() => checkCellCustomerExist()}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                        <Grid item lg={3}>
                                            <NumberFormat
                                                margin="normal"
                                                className="inputSmaller"
                                                customInput={TextField}
                                                variant="outlined"
                                                format="(##) ####-####"
                                                label="Telefone"
                                                mask="_"
                                                value={tell}
                                                onChange={e => setTell(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            />
                                        </Grid>

                                    </Grid>
                                </>
                            }

                            <Grid container spacing={3}>
                                <Grid item lg={2}>
                                    <TextField
                                        size="small"
                                        label="Ramal"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        value={ramal}
                                        onChange={e => setRamal(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <TextField
                                        size="small"
                                        label="Fax"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        value={fax}
                                        onChange={e => setFax(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        label="E-mail"
                                        required
                                        size="small"
                                        className={classes.error}
                                        margin="normal"
                                        variant="outlined"
                                        // helperText="Este e-mail irá receber a nota fiscal. Para mais de um e-mail, separe por ponto e vírgula (;)"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            setEmailInvalid(e.target.value ? false : true)
                                        }}
                                        error={emailInvalid}
                                        onBlur={() => checkEmailCustomerExist()}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={3}>
                                    <TextField
                                        label="Site ou Perfil (URL)"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={website}
                                        onChange={e => setWebsite(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>
                            </Grid>


                            <Grid container spacing={3} className="mt-2">
                                <Grid item lg={6}>
                                    <TextField
                                        label="Observações"
                                        multiline
                                        rows="1"
                                        size="small"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                <Grid item lg={4}>
                                    <NumericFormat
                                        label="Limite de Crédito"
                                        startAdornment="R$"
                                        value={limitCredit}
                                        onChange={(e) => setLimitCredit(e.target.value)}
                                        error={limitCreditInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="mt-2">
                                <Grid item lg={4}>
                                    <UploadFile
                                        label="Foto do Cliente/Fornecedor"
                                        fileSelected={fileSelected}
                                        setFileSelected={setFileSelected}
                                        fileNameSaved={fileNameSaved}
                                        accept="image/jpeg, image/png"
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </Grid>

                                {/* <Grid item lg={4} md={4}>
                                    <TextField
                                        label="Periodicidade para o limite"
                                        className={classes.textField}
                                        size="small"
                                        select
                                        margin="normal"
                                        variant="outlined"
                                        value={limitCreditPeriodicity}
                                        defaultValue=""
                                        error={limitCreditPeriodicityInvalid}
                                        disabled={user.isAccountant == "y" ? true : false}
                                        onChange={(e) => setLimitCreditPeriodicity(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            Nenhum
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.MONTHLY}>
                                            Mensal
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.BIMONTHLY}>
                                            Bimestral
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.TRIMONTHLY}>
                                            Trimestral
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.QUARTERLY}>
                                            Quadrimestral
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.SEMIANNUAL}>
                                            Semestral
                                        </MenuItem>
                                        <MenuItem value={limitCreditPeriodicityEnum.ANNUAL}>
                                            Anual
                                        </MenuItem>
                                    </TextField>
                                </Grid> */}

                                {!!currentLimitCreditThisPeriod && (
                                    <Grid item lg={4} md={4} className="mt-2">
                                        <strong>Débito atual: </strong>
                                        <p className={`mt-2 ${currentLimitCreditThisPeriod > formatToFloat(limitCredit) ? 'text-danger' : ''}`}>
                                            {formatCurrency(currentLimitCreditThisPeriod)}
                                        </p>
                                    </Grid>
                                )}
                            </Grid>
                        </Tab>

                        <Tab
                            eventKey='addresses'
                            title="Endereços"
                        >
                            <>
                                {address.addresses.map((addressData, index) => {
                                    return (
                                        <Card key={index} className="p-0 mb-3">
                                            <Card.Body className="row d-flex align-items-center p-6">
                                                <Grid container spacing={3} className="mb-1">
                                                    {
                                                        user.isAccountant == "n"
                                                            ?
                                                            <Grid item lg={12} className="p-0 text-right">
                                                                <button type="button" className="btn btn-link" onClick={() => removeAddress(index, addressData.id)}><i className="flaticon2-delete"></i></button>
                                                            </Grid>
                                                            : <></>
                                                    }


                                                    <Grid item lg={4} className=" d-flex justify-content-between align-items-center">
                                                        <NumberFormat
                                                            margin="normal"
                                                            className="inputSmaller mr-3"
                                                            customInput={TextField}
                                                            variant="outlined"
                                                            format="#####-###"
                                                            label="CEP"
                                                            mask="_"
                                                            value={addressData.zipcode}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].zipcode = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />

                                                        {
                                                            user.isAccountant == "n"
                                                                ?
                                                                <Button variant="primary" className="btn-sm" onClick={() => infoAddress(index)}><i className="flaticon2-search-1 p-0"></i></Button>
                                                                : <></>
                                                        }
                                                    </Grid>

                                                    <Grid item lg={3} className="d-flex align-items-center">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={addressData.default} onChange={() => { changeDefaultAddress(index); setTypePeopleAddress("physical") }} />}
                                                            label="Endereço padrão"
                                                            labelPlacement="end"
                                                            name="defaultAddress"
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].default = allAddress[index].default ? false : true;
                                                                allAddress[index].charge = false;
                                                                allAddress[index].delivery = false;
                                                                allAddress[index].withdrawal = false;
                                                                allAddress[index].name = "";
                                                                allAddress[index].cpf = "";
                                                                allAddress[index].phone = "";
                                                                allAddress[index].corporateName = "";
                                                                allAddress[index].cnpj = "";
                                                                allAddress[index].stateRegistration = "";


                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                        />
                                                    </Grid>

                                                    {(addressData.default)
                                                        ? ''
                                                        :
                                                        <Grid item lg={5} className="d-flex flex-row align-items-center justify-content-between">
                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={addressData.charge} />}
                                                                label="Cobrança"
                                                                labelPlacement="end"
                                                                name="charge"
                                                                onChange={(e) => {
                                                                    var allAddress = address.addresses;
                                                                    allAddress[index].charge = allAddress[index].charge ? false : true;
                                                                    setAddress({ addresses: allAddress });
                                                                }}
                                                            />

                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={addressData.delivery} />}
                                                                label="Entrega"
                                                                labelPlacement="end"
                                                                name="delivery"
                                                                onChange={(e) => {
                                                                    var allAddress = address.addresses;
                                                                    allAddress[index].delivery = allAddress[index].delivery ? false : true;
                                                                    setAddress({ addresses: allAddress });
                                                                }}
                                                            />

                                                            <FormControlLabel
                                                                value="end"
                                                                control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={addressData.withdrawal} />}
                                                                label="Retirada"
                                                                labelPlacement="end"
                                                                name="withdrawal"
                                                                onChange={(e) => {
                                                                    var allAddress = address.addresses;
                                                                    allAddress[index].withdrawal = allAddress[index].withdrawal ? false : true;
                                                                    setAddress({ addresses: allAddress });
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>

                                                <Grid container spacing={3} className="mb-1">
                                                    <Grid item lg={6}>
                                                        <TextField
                                                            size="small"
                                                            label="Endereço"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="address"
                                                            value={addressData.address}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].address = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label="Número"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="number"
                                                            value={addressData.number}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].number = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4}>
                                                        <TextField
                                                            size="small"
                                                            label="Bairro"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="bairro"
                                                            value={addressData.district}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].district = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3} className="mb-1">
                                                    <Grid item lg={4}>
                                                        <TextField
                                                            size="small"
                                                            label="Cidade"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="city"
                                                            value={addressData.city}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].city = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2} className="d-flex align-items-center pr-3">
                                                        <Autocomplete
                                                            size="small"
                                                            inputValue={addressData.state}
                                                            options={allStates.states}
                                                            defaultValue={{ sigla: addressData.state }}
                                                            getOptionLabel={({ sigla }) => sigla}
                                                            style={{ width: "100%", marginTop: "6px" }}
                                                            onInputChange={(event, newInputValue) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].state = newInputValue;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            renderInput={(params) => <TextField {...params}
                                                                label="Estado"
                                                                variant="outlined"
                                                            />
                                                            }
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <TextField
                                                            size="small"
                                                            label="País"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="country"
                                                            value={addressData.country}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].country = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4}>
                                                        <TextField
                                                            size="small"
                                                            label="Complemento"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="complement"
                                                            value={addressData.complement}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].complement = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item lg={6}>
                                                        <TextField
                                                            size="small"
                                                            label="Referência"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="reference"
                                                            value={addressData.reference}
                                                            onChange={(e) => {
                                                                var allAddress = address.addresses;
                                                                allAddress[index].reference = e.target.value;
                                                                setAddress({ addresses: allAddress });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    {(addressData.default)
                                                        ? ''
                                                        :
                                                        <>
                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    select
                                                                    size="small"
                                                                    label="Tipo de pessoa"
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name="typePeopleAddress"
                                                                    defaultValue="physical"
                                                                    value={addressData.typePeople}
                                                                    onChange={(e) => {
                                                                        var allAddress = address.addresses;
                                                                        allAddress[index].typePeople = e.target.value;
                                                                        setAddress({ addresses: allAddress });
                                                                    }}
                                                                    disabled={user.isAccountant == "y" ? true : false}
                                                                >
                                                                    <MenuItem key="0" value="physical" onClick={() => setTypePeopleAddress("physical")}>
                                                                        Pessoa física
                                                                    </MenuItem>

                                                                    <MenuItem key="1" value="legal" onClick={() => setTypePeopleAddress("legal")}>
                                                                        Pessoa Jurídica
                                                                    </MenuItem>

                                                                </TextField>
                                                            </Grid>

                                                            {(addressData.typePeople == "physical")
                                                                ?
                                                                <>
                                                                    <Grid item lg={4}>
                                                                        <TextField
                                                                            size="small"
                                                                            label="Nome"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="namePeopleAddress"
                                                                            value={addressData.name}
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].name = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item lg={3} style={{ marginTop: "-10px" }}>
                                                                        <NumberFormat
                                                                            margin="normal"
                                                                            className="inputSmaller"
                                                                            customInput={TextField}
                                                                            variant="outlined"
                                                                            format="###.###.###-##"
                                                                            label="CPF"
                                                                            mask="_"
                                                                            value={addressData.cpf}
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].cpf = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item lg={3} style={{ marginTop: "-10px" }}>
                                                                        <NumberFormat
                                                                            margin="normal"
                                                                            className="inputSmaller"
                                                                            customInput={TextField}
                                                                            variant="outlined"
                                                                            format="(##) ####-####"
                                                                            label="Telefone"
                                                                            value={addressData.phone}
                                                                            mask="_"
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].phone = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>


                                                                </>
                                                                :
                                                                <>
                                                                    <Grid item lg={4}>
                                                                        <TextField
                                                                            size="small"
                                                                            label="Razão Social"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="corporateNamePeopleAddress"
                                                                            value={addressData.corporateName}
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].corporateName = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item lg={3} style={{ marginTop: "-10px" }}>
                                                                        <NumberFormat
                                                                            margin="normal"
                                                                            className="inputSmaller"
                                                                            customInput={TextField}
                                                                            variant="outlined"
                                                                            label="CNPJ"
                                                                            format="##.###.###/####-##"
                                                                            mask="_"
                                                                            value={addressData.cnpj}
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].cnpj = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item lg={3} style={{ marginTop: "-10px" }}>
                                                                        <NumberFormat
                                                                            margin="normal"
                                                                            className="inputSmaller"
                                                                            customInput={TextField}
                                                                            variant="outlined"
                                                                            format="(##) ####-####"
                                                                            label="Telefone"
                                                                            value={addressData.phone}
                                                                            mask="_"
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].phone = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item lg={3} style={{ marginTop: "-10px" }}>
                                                                        <TextField
                                                                            size="small"
                                                                            label="Inscrição estadual"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            value={addressData.stateRegistration}
                                                                            onChange={(e) => {
                                                                                var allAddress = address.addresses;
                                                                                allAddress[index].stateRegistration = e.target.value;
                                                                                setAddress({ addresses: allAddress });
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            }

                                                            <Grid item lg={3} className="d-flex align-items-center" style={{ marginTop: "-10px" }}>
                                                                <FormControlLabel
                                                                    value="end"
                                                                    control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={(e) => copyInfoOfRegister(e.target.checked, index)} />}
                                                                    className="ml-1"
                                                                    label="Copiar informações do cadastro"
                                                                    labelPlacement="end"
                                                                    name="copyInfoOfRegister"
                                                                />
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}


                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Card className="mt-5">
                                            <Card.Body className="row p-6">
                                                <Button variant="secondary" onClick={addAddress}>Adicionar endereço</Button>
                                            </Card.Body>
                                        </Card>
                                        : <></>
                                }
                            </>
                        </Tab>
                        <Tab
                            eventKey='additional-info'
                            title="Mais informações"
                        >
                            <Card.Body className="p-0">
                                {(typePeople == "physical")
                                    ?
                                    <>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextField
                                                    size="small"
                                                    label="RG"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={rg}
                                                    onChange={(e) => setRg(e.target.value)}
                                                    onBlur={() => checkRgCustomerExist()}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    size="small"
                                                    label="Órgão expedidor"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={issuingOrgan}
                                                    onChange={e => setIssuingOrgan(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    type="date"
                                                    size="small"
                                                    label="Data de emissão"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={issuingDate}
                                                    onChange={e => setIssueDate(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                    error={issueDateInvalid}
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Passaporte"
                                                    size="small"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={passport}
                                                    onChange={e => setPassport(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>

                                            <div className="col-lg-2 d-flex align-items-center">
                                                <FormControlLabel
                                                    value="end"
                                                    className={classes.textField}
                                                    control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={e => setForeign((e.target.checked) ? "y" : "n")} checked={(foreign === "y") ? true : false} />}
                                                    label="Estrangeiro"
                                                    labelPlacement="end"
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Inscrição produtor rural"
                                                    size="small"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={ruralProducer}
                                                    onChange={e => setRuralProducer(e.target.value)}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={isRuralProducer === "y" && user.isAccountant == "n" ? false : true}
                                                />
                                            </div>

                                            <div className="col-lg-2 d-flex align-items-center">
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={e => { setIsRuralProducer((e.target.checked) ? "y" : "n"); setRuralProducer("") }} checked={(isRuralProducer === "y") ? true : false} />}
                                                    className={classes.textField}
                                                    label="Produtor rural"
                                                    labelPlacement="end"
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Profissão"
                                                    size="small"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={profession}
                                                    onChange={e => setProfession(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Empregador"
                                                    size="small"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={employer}
                                                    onChange={e => setEmployer(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>

                                            <div className="col-lg-4 d-flex align-items-center">
                                                <FormControlLabel
                                                    value="end"
                                                    className={classes.textField}
                                                    control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={e => setIsRetiree((e.target.checked) ? "y" : "n")} checked={(isRetiree === "y") ? true : false} />}
                                                    label="Aposentado"
                                                    labelPlacement="end"
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <TextField
                                                    select
                                                    size="small"
                                                    label="Estado Civíl"
                                                    className={classes.textField}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={maritalStatus}
                                                    onChange={e => setMaritalStatus(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                >
                                                    <MenuItem key="0" value="">
                                                        Selecione
                                                    </MenuItem>

                                                    <MenuItem key="1" value="unmarried">
                                                        Solteiro
                                                    </MenuItem>

                                                    <MenuItem key="2" value="married">
                                                        Casado
                                                    </MenuItem>

                                                    <MenuItem key="3" value="widower">
                                                        Viúvo
                                                    </MenuItem>

                                                    <MenuItem key="4" value="separate">
                                                        Separado
                                                    </MenuItem>

                                                    <MenuItem key="5" value="divorced">
                                                        Divorciado
                                                    </MenuItem>

                                                    <MenuItem key="6" value="other">
                                                        Outro
                                                    </MenuItem>

                                                </TextField>
                                            </div>
                                        </div>


                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextField
                                                    label="Inscrição na suframa"
                                                    size="small"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText="Inscrição na zona franca de Manaus"
                                                    value={inscriptionInTheSuframa}
                                                    onChange={e => setInscriptionInTheSuframa(e.target.value)}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="row">
                                    <div className="col-lg-4">
                                        <TextField
                                            type="date"
                                            size="small"
                                            label="Início da atividade"
                                            className={classes.textField}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{ inputProps: { max: finalActivity } }}
                                            value={initialActivity}
                                            onChange={e => setInitialActivity(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-4">
                                        <TextField
                                            type="date"
                                            size="small"
                                            label="Final da atividade"
                                            className={classes.textField}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{ inputProps: { min: initialActivity } }}
                                            value={finalActivity}
                                            onChange={e => setFinalActivity(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-4 d-flex align-items-center">
                                        <FormControlLabel
                                            value="end"
                                            className={classes.textField}
                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" onChange={e => setActivityClosed((e.target.checked) ? "y" : "n")} checked={(activityClosed === "y") ? true : false} />}
                                            label="Atividade encerrada"
                                            labelPlacement="end"
                                        />
                                    </div>
                                </div>

                            </Card.Body>
                        </Tab>
                        <Tab
                            eventKey='contacts'
                            title="Contatos Adicionais"
                        >
                            <>
                                {contact.contacts.map((contactData, i) => {
                                    return (
                                        <Card key={i} className="p-0">
                                            <Card.Body className="pl-2 pr-8 pt-3 pb-3">
                                                <Grid container spacing={3}>
                                                    {
                                                        user.isAccountant == "n"
                                                            ?
                                                            <Grid item lg={12} className="p-0 text-right">
                                                                <button type="button" className="btn btn-link pr-0" onClick={() => removeContact(i, contactData.id)}><i className="flaticon2-delete"></i></button>
                                                            </Grid>
                                                            : <></>
                                                    }

                                                    <Grid item lg={3}>
                                                        <TextField
                                                            select
                                                            size="small"
                                                            label="Tipo de contato"
                                                            className={classes.textField}
                                                            SelectProps={{
                                                                MenuProps: {
                                                                    className: classes.menu,
                                                                },
                                                            }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="typeContact"
                                                            value={contactData.typeContact}
                                                            onChange={e => changeTypeContact(i, e.target.value)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >
                                                            <MenuItem key="0" value="selecione">
                                                                Selecione
                                                            </MenuItem>

                                                            <MenuItem key="1" value="family">
                                                                Familiar
                                                            </MenuItem>

                                                            <MenuItem key="2" value="personal">
                                                                Pessoal
                                                            </MenuItem>

                                                            <MenuItem key="3" value="professional">
                                                                Profissional
                                                            </MenuItem>

                                                        </TextField>
                                                    </Grid>

                                                    <Grid item lg={3}>
                                                        {contactData.typeContact === "family"
                                                            ?
                                                            <TextField
                                                                select
                                                                size="small"
                                                                label="Relação"
                                                                className={classes.textField}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                                name="relationship"
                                                                value={contactData.relationship}
                                                                onChange={(e) => {
                                                                    var allContacts = contact.contacts;
                                                                    allContacts[i].relationship = e.target.value;
                                                                    setContact({ contacts: allContacts });
                                                                }}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key="0" value="selecione">
                                                                    Selecione
                                                                </MenuItem>
                                                                <MenuItem key="0" value="spouse">
                                                                    Cônjuge
                                                                </MenuItem>
                                                                <MenuItem key="1" value="father">
                                                                    Pai
                                                                </MenuItem>
                                                                <MenuItem key="2" value="mom">
                                                                    Mãe
                                                                </MenuItem>
                                                                <MenuItem key="3" value="son">
                                                                    Filho (a)
                                                                </MenuItem>
                                                                <MenuItem key="4" value="brother">
                                                                    irmão (a)
                                                                </MenuItem>
                                                                <MenuItem key="5" value="kinship">
                                                                    Parente
                                                                </MenuItem>
                                                            </TextField>
                                                            : ''
                                                        }

                                                        {contactData.typeContact === "personal"
                                                            ?
                                                            <TextField
                                                                select
                                                                size="small"
                                                                label="Relação"
                                                                className={classes.textField}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                                name="relationship"
                                                                value={contactData.relationship}
                                                                onChange={(e) => {
                                                                    var allContacts = contact.contacts;
                                                                    allContacts[i].relationship = e.target.value;
                                                                    setContact({ contacts: allContacts });
                                                                }}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key="0" value="selecione">
                                                                    Selecione
                                                                </MenuItem>
                                                                <MenuItem key="1" value="friend">
                                                                    Amigo
                                                                </MenuItem>
                                                                <MenuItem key="2" value="known">
                                                                    Conhecido
                                                                </MenuItem>
                                                                <MenuItem key="3" value="colleague">
                                                                    Colega
                                                                </MenuItem>
                                                                <MenuItem key="4" value="others">
                                                                    Outros
                                                                </MenuItem>
                                                            </TextField>
                                                            : ''
                                                        }

                                                        {contactData.typeContact === "professional"
                                                            ?
                                                            <TextField
                                                                select
                                                                size="small"
                                                                label="Relação"
                                                                className={classes.textField}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                                name="relationship"
                                                                value={contactData.relationship}
                                                                onChange={(e) => {
                                                                    var allContacts = contact.contacts;
                                                                    allContacts[i].relationship = e.target.value;
                                                                    setContact({ contacts: allContacts });
                                                                }}
                                                                disabled={user.isAccountant == "y" ? true : false}
                                                            >
                                                                <MenuItem key="0" value="selecione">
                                                                    Selecione
                                                                </MenuItem>
                                                                <MenuItem key="1" value="collaborator">
                                                                    Colaborador
                                                                </MenuItem>
                                                                <MenuItem key="2" value="principal">
                                                                    Diretor
                                                                </MenuItem>
                                                                <MenuItem key="3" value="commercial">
                                                                    Comercial
                                                                </MenuItem>
                                                                <MenuItem key="4" value="purchases">
                                                                    Compras
                                                                </MenuItem>
                                                                <MenuItem key="5" value="financial">
                                                                    Financeiro
                                                                </MenuItem>
                                                                <MenuItem key="6" value="factory">
                                                                    Fábrica
                                                                </MenuItem>
                                                                <MenuItem key="7" value="others">
                                                                    Outros
                                                                </MenuItem>
                                                            </TextField>
                                                            : ''
                                                        }

                                                        {contactData.typeContact === ""
                                                            ?
                                                            <TextField
                                                                select
                                                                size="small"
                                                                label="Relação"
                                                                className={classes.textField}
                                                                SelectProps={{
                                                                    MenuProps: {
                                                                        className: classes.menu,
                                                                    },
                                                                }}
                                                                margin="normal"
                                                                variant="outlined"
                                                                name="relationship"
                                                                disabled
                                                            >
                                                                <MenuItem key="0" value="selecione">
                                                                    Selecione
                                                                </MenuItem>
                                                            </TextField>
                                                            : ''
                                                        }
                                                    </Grid>

                                                    <Grid item lg={3} className="d-flex align-items-center">
                                                        <FormControlLabel
                                                            value="end"
                                                            control={<Checkbox disabled={user.isAccountant == "y" ? true : false} color="primary" checked={(contactData.isLegalResponsible === "y") ? true : false} />}
                                                            label="Responsável legal"
                                                            labelPlacement="end"
                                                            name="legalResponsible"
                                                            className={classes.textField}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].isLegalResponsible = (allContacts[i].isLegalResponsible === "y" ? "n" : "y");
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item lg={6}>
                                                        <TextField
                                                            label="Nome do contato"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="nameContact"
                                                            value={contactData.name}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].name = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={3}>
                                                        <TextField
                                                            type="date"
                                                            label="Data de nascimento"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            value={contactData.birthDate}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].birthDate = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />

                                                    </Grid>

                                                    <Grid item lg={3}>
                                                        <TextField
                                                            select
                                                            label="Gênero"
                                                            size="small"
                                                            className={classes.textField}
                                                            SelectProps={{
                                                                MenuProps: {
                                                                    className: classes.menu,
                                                                },
                                                            }}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="gender"
                                                            value={contactData.gender}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].gender = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        >
                                                            <MenuItem key="0" value="">
                                                                Selecione
                                                            </MenuItem>

                                                            <MenuItem key="1" value="male">
                                                                Masculino
                                                            </MenuItem>

                                                            <MenuItem key="2" value="female">
                                                                Feminino
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item lg={3} className="pr-1">
                                                        <NumberFormat
                                                            margin="normal"
                                                            className="inputSmaller pl-3"
                                                            customInput={TextField}
                                                            variant="outlined"
                                                            format="(##) #####-####"
                                                            label="Celular"
                                                            mask="_"
                                                            value={contactData.cell}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].cell = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={3}>
                                                        <NumberFormat
                                                            margin="normal"
                                                            className="inputSmaller pr-0"
                                                            customInput={TextField}
                                                            variant="outlined"
                                                            format="(##) ####-####"
                                                            label="Telefone"
                                                            mask="_"
                                                            value={contactData.phone}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].phone = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <TextField
                                                            label="Ramal"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="ramalContact"
                                                            value={contactData.extension}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].extension = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4}>
                                                        <TextField
                                                            label="Fax"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="faxContact"
                                                            value={contactData.fax}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].fax = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item lg={6}>
                                                        <TextField
                                                            label="E-mail"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="emailContact"
                                                            value={contactData.email}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].email = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            onBlur={() => checkEmailContactExist(i)}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6}>
                                                        <TextField
                                                            label="Site ou Perfil (URL)"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="urlContact"
                                                            value={contactData.website}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].website = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={3}>
                                                    <Grid item lg={6}>
                                                        <TextField
                                                            label="Observações"
                                                            multiline
                                                            rows="1"
                                                            size="small"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="noteContact"
                                                            value={contactData.note}
                                                            onChange={(e) => {
                                                                var allContacts = contact.contacts;
                                                                allContacts[i].note = e.target.value;
                                                                setContact({ contacts: allContacts });
                                                            }}
                                                            disabled={user.isAccountant == "y" ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}


                                {
                                    user.isAccountant == "n"
                                        ?
                                        <Card className="mt-5">
                                            <Card.Body className="row p-6">
                                                <Button variant="secondary" onClick={addContact}>Adicionar contato</Button>
                                            </Card.Body>
                                        </Card>
                                        : <></>
                                }
                            </>
                        </Tab>
                        <Tab
                            eventKey='tax'
                            title="Fiscais e Tributárias"
                        >
                            <Card.Body className="p-6">
                                <Grid container spacing={3}>
                                    <Grid item lg={2}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Consumidor final</FormLabel>
                                            <RadioGroup aria-label="finalCostumer" onChange={e => setFinalCostumer(e.target.value)} value={finalCostumer} row>
                                                <FormControlLabel
                                                    value="y"
                                                    control={<Radio disabled={user.isAccountant == "y" ? true : false} color="primary" />}
                                                    label="Sim"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="n"
                                                    control={<Radio disabled={user.isAccountant == "y" ? true : false} color="primary" />}
                                                    label="Não"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={2}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Contribuinte ICMS</FormLabel>
                                            <RadioGroup aria-label="taxpayerIcms" onChange={e => { setTaxpayerIcms(e.target.value); setTaxRegime("") }} value={taxpayerIcms} row>
                                                <FormControlLabel
                                                    value="y"
                                                    control={<Radio disabled={user.isAccountant == "y" ? true : false} color="primary" />}
                                                    label="Sim"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="n"
                                                    control={<Radio disabled={user.isAccountant == "y" ? true : false} color="primary" />}
                                                    label="Não"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {(taxpayerIcms == "y")
                                        ?
                                        <Grid item lg={2}>
                                            <TextField
                                                select
                                                label="Regime tributário"
                                                size="small"
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu,
                                                    },
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                value={taxRegime}
                                                onChange={e => setTaxRegime(e.target.value)}
                                                disabled={user.isAccountant == "y" ? true : false}
                                            >
                                                <MenuItem key="0" value="">
                                                    Selecione
                                                </MenuItem>

                                                <MenuItem key="1" value="simple national">
                                                    Simples Nacional
                                                </MenuItem>

                                                <MenuItem key="2" value="normal regime">
                                                    Regime Normal
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        : ''
                                    }
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item lg={6}>
                                        <TextField
                                            label="Observações da nota"
                                            multiline
                                            rows="3"
                                            className="ml-0"
                                            margin="normal"
                                            helperText="Configure a mensagem do campo de observações da nota fiscal."
                                            variant="outlined"
                                            value={noteRemarks}
                                            onChange={e => setNoteRemarks(e.target.value)}
                                            disabled={user.isAccountant == "y" ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Tab>
                        <Tab
                            eventKey='attachments'
                            title="Anexos"
                        >
                            <Card.Body className="col-6 p-3">
                                <UploadFiles
                                    label="Anexos"
                                    filesSelected={filesSelected}
                                    setFilesSelected={setFilesSelected}
                                    filesNamesSaved={filesNamesSaved}
                                    setFilesNamesSaved={setFilesNamesSaved}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                            </Card.Body>
                        </Tab>

                    </Tabs>

                    <Accordion>

                        {/* <Accordion.Toggle as={Card.Header} eventKey="3" className="h4 pl-0" style={{ cursor: "pointer" }}>
                            <img src="/media/svg/icons/Navigation/Angle-double-down.svg" className="mr-3"/>
                            Configurações
                        </Accordion.Toggle> */}

                        {/* <Accordion.Collapse eventKey="3">
                            <Card.Body>
                            <div className="row">
                                    <div className="col-lg-4">
                                        <TextField
                                            select
                                            label="Lista de preço"
                                            className={classes.textField}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={priceList}
                                            onChange={e => setpriceList(e.target.value)}
                                        >
                                            <MenuItem key="0" value="selecione">
                                                Nenhuma lista encontrada
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className="col-lg-4">
                                        <TextField
                                            select
                                            label="Condição de pagamento"
                                            className={classes.textField}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={paymentTerm}
                                            onChange={e => setpaymentTerm(e.target.value)}
                                        >
                                            <MenuItem key="0" value="selecione">
                                                Selecione
                                            </MenuItem>

                                            <MenuItem key="1" value="30/60/90">
                                                30/60/90
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <TextField
                                            select
                                            label="Nome do vendedor"
                                            className={classes.textField}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={nameSeller}
                                            onChange={e => setnameSeller(e.target.value)}
                                        >
                                            <MenuItem key="0" value="selecione">
                                                Selecione
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <TextField
                                            select
                                            label="Modalidade de frete"
                                            className={classes.textField}
                                            SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            value={deliveryMode}
                                            onChange={e => setdeliveryMode(e.target.value)}
                                        >
                                            <MenuItem key="0" value="sem frete">
                                                Sem Frete
                                            </MenuItem>

                                            <MenuItem key="1" value="remetente">
                                                Remetente
                                            </MenuItem>

                                            <MenuItem key="2" value="destinatario">
                                                Destinatário
                                            </MenuItem>

                                            <MenuItem key="2" value="terceiro">
                                                terceiro
                                            </MenuItem>
                                        </TextField>
                                    </div>

                                    <div className="col-lg-4 d-flex align-items-center">
                                        <Controller
                                            name="carrying"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value }}) => (
                                                <Autocomplete
                                                    options={allCarriers.carriers}
                                                    getOptionLabel={({typePeople, fantasyName, name}) => typePeople === "physical" ? name : fantasyName}
                                                    style={{ width: "100%", marginTop: "6px" }}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label="Transportadora"
                                                                                        variant="outlined"
                                                                                        className={classes.textField}
                                                    />
                                                    }
                                                />
                                            )}
                                        />


                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 d-flex align-items-center">
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox color="primary" />}
                                            label="Permitir ultrapassar limite de crédito"
                                            labelPlacement="end"
                                            value={allowToExceedLimit}
                                            onChange={e => setallowToExceedLimit(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse> */}

                        {/* <Accordion.Toggle as={Card.Header} eventKey="6" className="h4 pl-0" style={{ cursor: "pointer" }}>
                            <img src="/media/svg/icons/Navigation/Angle-double-down.svg" className="mr-3"/>
                            Campos Personalizados
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="6">
                            <Card.Body className="text-center">
                                Nenhum campo personalizado foi criado
                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Accordion>

                </div>
            </form>
        </div>
    )
}