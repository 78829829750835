import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  Line
} from "@react-pdf/renderer";
import { useHistory, useParams } from "react-router";
import api from "../../services/Api";
import photo from "./test.jpg";
import { formatCurrency, formatIntegerToMoney } from "../../utils/formatCurrency";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import Table from "../Pdf/Table";
import TableRow from "../Pdf/TableRow";
import TableCell from "../Pdf/TableCell";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

const axiosDefaults = {
  headers: {
    'x-bypass-auth': '0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5'
  },
};

// Create styles
const styles = StyleSheet.create({
  header: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 8,
    fontSize: 11,
    alignItems: "flex-start",
    flexDirection: "row",
  },
  section: {
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8,
    fontSize: 11,
  },
  titleSection: {
    paddingBottom: 6,
    borderBottom: 0.7,
    fontSize: 8,
  },
  titleInfo: {
    fontSize: 6,
    fontWeight: 800,
    paddingBottom: 5,
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 8,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowPayment: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  rowProduct: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: 1,
    borderColor: "#ccc",
    borderStyle: "dashed",
    paddingTop: 3,
    paddingBottom: 2,
  },
  rowInstallment: {
    flexDirection: "row",
    alignItems: "center",
    borderBottom: 1,
    borderColor: "#ccc",
    borderStyle: "dashed",
    paddingTop: 3,
    paddingBottom: 2,
  },
  column: {
    flexDirection: "column",
    alignItems: "center",
  },
  customerPhoto: {
    width: '85%',
  },
  photo: {
    width: '15%',
    paddingTop: 8,
    paddingBottom: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  titleHeader: {
    width: "100%",
    marginBottom: 8,
    paddingBottom: 5,
    borderBottom: 0.7,
    borderColor: "#ccc"
  },
  subtitleText: {
    width: "100%",
    marginTop: 6,
    fontSize: 8,
  },
  infoHeader: {
    width: '85%',
    paddingTop: 8,
    paddingBottom: 8,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  left: {
    width: '50%',
    paddingTop: 8,
    flexDirection: "column",
  },
  right: {
    width: '50%',
    paddingTop: 8,
    flexDirection: "column",
  },
  leftPayment: {
    width: '80%',
    paddingTop: 8,
    flexDirection: "column",
  },
  rightPayment: {
    width: '20%',
    paddingTop: 8,
    flexDirection: "column",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  totals: {
    paddingTop: 16,
  },
  footer: {
    paddingTop: 24,
  },
  observationText: {
    width: '100%',
    fontSize: 6,
    marginTop: 6,
    marginLeft: 6,
    lineHeight: 1.5,
  },


  cell1: {
    width: '3%',
    fontSize: 6,
  },
  cell2: {
    width: '47%',
    fontSize: 6,
  },
  cell3: {
    width: '5%',
    fontSize: 6,
  },
  cell4: {
    width: '5%',
    fontSize: 6,
  },
  cell5: {
    width: '10%',
    fontSize: 6,
  },
  cell6: {
    width: '10%',
    fontSize: 6,
  },
  cell7: {
    width: '10%',
    fontSize: 6,
  },
  cell8: {
    width: '10%',
    fontSize: 6,
  },
  cell9: {
    width: '25%',
    fontSize: 6,
  },
  cell10: {
    width: '20%',
    fontSize: 6,
  },
});

const itemsTable = {
  cell1: {
    width: '3%',
  },
  cell2: {
    width: '47%',
  },
  cell3: {
    width: '5%',
  },
  cell4: {
    width: '5%',
  },
  cell5: {
    width: '10%',
  },
  cell6: {
    width: '10%',
  },
  cell7: {
    width: '10%',
  },
  cell8: {
    width: '10%',
  },
};

const paymentsTable = {
  cell1: {
    width: '20%',
  },
  cell2: {
    width: '20%',
  },
  cell3: {
    width: '20%',
  },
  cell4: {
    width: '25%',
  },
  cell5: {
    width: '25%',
  },
}

type Product = {
  id: number,
  name: string,
  unit: string,
  amount: number,
  size?: string,
  ncm?: string,
  unitValue: number,
  total: number,
  type: string,
}

type installments = {
  name: string,
  date: string,
  comments?: string,
  value: number,
  payment: string
}

type PdfOrderParams = {
  orderId: string,
  typeOrder: string,
}

// Create Document Component
export default function PdfOrder({ orderId, typeOrder }: PdfOrderParams) {
  const [products, setProducts] = useState<Product[]>([]);
  const [installments, setInstallments] = useState<installments[]>([]);
  const [type, setType] = useState("");
  const [reference, setReference] = useState('');
  const [comments, setComments] = useState('');
  const [equipment, setEquipment] = useState('');
  const [issues, setIssues] = useState('');
  const [receivement, setReceivement] = useState('');
  const [report, setReport] = useState('');

  // COMPANY DATA
  const [nameCompany, setNameCompany] = useState("");
  const [typeCompany, setTypeCompany] = useState("");
  const [cnpjCompany, setCnpjCompany] = useState("");
  const [cpfCompany, setCpfCompany] = useState("");
  const [addressCompany, setAddressCompany] = useState("");
  const [numberCompany, setNumberCompany] = useState("");
  const [neighborhoodCompany, setNeighborhoodCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [ufCompany, setUfCompany] = useState("");
  const [zipCodeCompany, setZipCodeCompany] = useState("");
  const [phoneCompany, setPhoneCompany] = useState("");
  const [emailCompany, setEmailCompany] = useState("");
  const [siteCompany, setSiteCompany] = useState("");
  const [photoCompany, setPhotoCompany] = useState("");
  // =========== COMPANY =====



  // CUSTOMER DATA 
  const [name, setName] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [rg, setRg] = useState("");
  const [tell, setTell] = useState("");
  const [email, setEmail] = useState("");
  const [cell, setCell] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [typePeople, setTypePeople] = useState("");
  // ========= CUSTOMER ===  


  // CONTACT DATA
  const [nameContact, setNameContact] = useState("");
  const [tellContact, setTellContact] = useState("");
  const [webSite, setWebSite] = useState("");
  const [cellContact, setCellContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  // ========= CONTACT ===


  // PAYMENT DATA
  const [amountTotal, setAmountTotal] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  //  ======== PAYMENT ===

  function formatDate(date: string) {
    var dateSplit = date.split("-");
    return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
  }

  async function getCompany(companyId: number) {
    return await api.get(`/companies/${companyId}`, axiosDefaults);
  }

  async function getOrder() {
    var order;
    switch (typeOrder) {
      case "request":
        setType("PEDIDO");
        order = await api.get(`/requests/${orderId}`, axiosDefaults);
        break;
      case "budget":
        setType("ORÇAMENTO");
        order = await api.get(`/budgets/${orderId}`, axiosDefaults);
        break;
      case "purchase":
        setType("ORDEM DE COMPRA");
        order = await api.get(`/purchase-order/${orderId}`, axiosDefaults);
        break;
      case "service":
        setType("ORDEM DE SERVIÇO");
        order = await api.get(`/service-order/${orderId}`, axiosDefaults);
        break;
    }

    return order;
  }

  async function getCustomer(customerId: number, customerName: string) {
    if (!customerId) {
      return { name: customerName };
    }
    const customer = await api.get(`/customer/${customerId}`, axiosDefaults);

    return customer.data;
  }

  async function getAddress(customerId: number) {
    return await api.get(`/address/user/${customerId}`, axiosDefaults);
  }

  async function getContact(customerId: number) {
    return await api.get(`/contact/user/${customerId}`, axiosDefaults);
  }

  // useEffect(() => {
  //   api.defaults.headers['x-bypass-auth']  = `0A0qnipTqSQyI1FsKkra2Q83qpeQiUZ1dAF82DeLhNbLkTItXbnNmCD5lsQXFbG5`;
  // }, []);

  useEffect(() => {
    getOrder().then((order: any) => {
      if (order) {
        getCompany(order.data.companyId).then((company: any) => {
          if (company) {
            setNameCompany(company.data.name);
            setTypeCompany(company.data.typePeople);
            setCnpjCompany(company.data.cnpj);
            setCpfCompany(company.data.cpf);
            setAddressCompany(company.data.address);
            setNumberCompany(company.data.number);
            setNeighborhoodCompany(company.data.district);
            setCityCompany(company.data.city);
            setUfCompany(company.data.state);
            setZipCodeCompany(company.data.zipCode);
            setPhoneCompany(company.data.phone);
            setEmailCompany(company.data.email);
            setSiteCompany(company.data.website);
            setPhotoCompany(company.data.logo);
          }
        });

        var objProducts = JSON.parse(order.data.products);
        var objServices = order.data.services ? JSON.parse(order.data.services) : [];
        var objInstallments = JSON.parse(order.data.installments);

        let productsArray: Product[] = [];
        let amountProducts = 0;

        if (objProducts.length) {
          const { mappedProducts, totalAmount } = mapProducts(objProducts, 'product');
          productsArray = [...productsArray, ...mappedProducts];
          amountProducts += totalAmount;
        }

        if (objServices.length) {
          const { mappedProducts, totalAmount } = mapProducts(objServices, 'service');
          productsArray = [...productsArray, ...mappedProducts];
          amountProducts += totalAmount;
        }

        setProducts(productsArray);
        setAmountTotal(amountProducts);
        setCountTotal(productsArray.length);
        setDiscount(order.data.discount ?? 0);
        setTotalOrder(order.data.totalValue ?? order.data.totalPrice);
        setReference(order.data.referencies ? order.data.referencies : order.data.reference ? order.data.reference : '');
        setComments(order.data.comments || (order.data.note || ''));
        setEquipment(order.data.equipment || '');
        setIssues(order.data.issues || '');
        setReceivement(order.data.receivement || '');
        setReport(order.data.report || '');

        if (objInstallments && objInstallments.length) {
          var installmentsArray = [];

          for (var indexInstallment = 0; indexInstallment < objInstallments.length; indexInstallment++) {
            installmentsArray.push({
              name: `Parcela ${indexInstallment + 1}`,
              date: objInstallments[indexInstallment].date,
              value: objInstallments[indexInstallment].value,
              payment: objInstallments[indexInstallment].payment ?? "-",
              comments: objInstallments[indexInstallment].comments ?? "N/A",
            });
          }
          setInstallments(installmentsArray);
        }

        getCustomer(getCustomerId(order.data), getCustomerName(order.data)).then((customer) => {
          if (!customer?.id) {
            setName(customer?.name ?? '');
            setCorporateName(customer?.name ?? '');
            return;
          }

          setTypePeople(customer.typePeople);
          setName(customer.name);
          setCorporateName(customer.corporateName);
          setFantasyName(customer.fantasyName);
          setStateRegistration(customer.stateRegistration);
          setCpf(customer.cpf);
          setCnpj(customer.cnpj);
          setRg(customer.rg);
          setTell(customer.phone);
          setEmail(customer.email);
          setCell(formatPhoneNumber(customer.cell ?? ''));

          getAddress(customer.id).then((address) => {
            var hasDefaultAddress = false;

            for (var index = 0; index < address.data.length; index++) {
              if (address.data[index].defaultAddress == "y") {
                setAddress(address.data[index].publicPlace);
                setNumber(address.data[index].number);
                setNeighborhood(address.data[index].district);
                setComplement(address.data[index].complement);
                setZipCode(address.data[index].zipcode);
                setCity(address.data[index].city);
                setUf(address.data[index].state);

                hasDefaultAddress = true;
              }
            }

            if (!hasDefaultAddress && address.data.length) {
              setAddress(address.data[0].publicPlace);
              setNumber(address.data[0].number);
              setNeighborhood(address.data[0].district);
              setComplement(address.data[0].complement);
              setZipCode(address.data[0].zipcode);
              setCity(address.data[0].city);
              setUf(address.data[0].state);
            }
          });

          getContact(customer.id).then((contact) => {
            var hasDefaultContact = false;

            for (var index = 0; index < contact.data.length; index++) {
              if (contact.data[index].isLegalResponsible == "y") {
                setNameContact(contact.data[index].name);
                setTellContact(contact.data[index].phone);
                setWebSite(contact.data[index].website)
                setCellContact(contact.data[index].cell);
                setEmailContact(contact.data[index].email);

                hasDefaultContact = true;
              }
            }

            if (!hasDefaultContact && contact.data.length) {
              setNameContact(contact.data[0].name);
              setTellContact(contact.data[0].phone);
              setWebSite(contact.data[0].website)
              setCellContact(contact.data[0].cell);
              setEmailContact(contact.data[0].email);
            }
          });
        });
      }
    });
  }, [orderId]);

  function mapProducts(products: any[], type: 'product' | 'service'): any {
    const mappedProducts = [];
    let totalAmount = 0;
    for (const product of products) {
      if (validateProduct(product)) {
        const amount = Number(product.amount ?? product.quantity);
        const unitValue = product.unitaryValue ?? product.INSTANCE.saleValue;

        mappedProducts.push({
          id: product.INSTANCE.id ?? Math.random(),
          name: product.INSTANCE.name ?? product.name,
          amount: amount,
          unit: product.INSTANCE.unit ?? 'Pç',
          unitValue: unitValue,
          total: unitValue * amount,
          ncm: product.ncm ? product.ncm : (product.INSTANCE.ncm ?? ''),
          size: product.INSTANCE.productSize ?? '',
          type: type,
        });

        if (amount) {
          totalAmount += amount;
        }
      }
    }

    return {
      mappedProducts, totalAmount
    };
  }

  function validateProduct(product: any) {
    if (!product.INSTANCE.id && !product.name) {
      return false;
    }

    return true;
  }

  function getCustomerId(order: any) {
    switch (typeOrder) {
      case "request":
        return order.customerId;
      case "budget":
        return order.customerId;
      case "purchase":
        return order.supplierId;
      case "service":
        return order.customerId;
      default:
        return order.customer;
    }
  }

  function getCustomerName(order: any) {
    return order.customerName ?? (order.supplierName ?? '');
  }

  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4">
        <View style={styles.header}>
          <View style={styles.photo}>
            <Image source={{ uri: `${process.env.REACT_APP_API_URL}/${photoCompany}`, method: "GET", headers: {}, body: "" }} style={styles.customerPhoto} />
          </View>
          <View style={styles.infoHeader}>
            <View style={styles.titleHeader}>
              <Text>{type} {orderId}</Text>
              {!!reference ? (
                <Text style={styles.subtitleText}>REFERÊNCIA: {reference}</Text>
              ) : <></>}

            </View>
            <Text style={styles.titleInfo}>{nameCompany} - {typeCompany == "legal" ? formatCpfCnpj('CNPJ', cnpjCompany) : formatCpfCnpj('CPF', cpfCompany)}</Text>
            <Text style={styles.titleInfo}>{addressCompany}, {numberCompany} {neighborhoodCompany} - {cityCompany} - {ufCompany} CEP: {zipCodeCompany}</Text>
            <Text style={styles.titleInfo}>Fone: {phoneCompany} E-mail: {emailCompany} Site: {siteCompany}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.titleSection}>DADOS DO CLIENTE</Text>

          <View style={styles.row}>
            <View style={styles.left}>
              {
                typePeople == "physical"
                  ?
                  <>
                    <Text style={styles.titleInfo}>NOME: {name}</Text>
                    <Text style={styles.titleInfo}>RG: {rg}</Text>
                  </>
                  :
                  <>
                    <Text style={styles.titleInfo}>RAZÃO SOCIAL: {corporateName}</Text>
                    <Text style={styles.titleInfo}>CNPJ: {formatCpfCnpj('CNPJ', cnpj)}</Text>
                  </>
              }
              <Text style={styles.titleInfo}>TELEFONE: {tell}</Text>
              <Text style={styles.titleInfo}>CELULAR: {cell}</Text>
              <Text style={styles.titleInfo}>NÚMERO: {number}</Text>
              <Text style={styles.titleInfo}>COMPLEMENTO: {complement}</Text>
              <Text style={styles.titleInfo}>CIDADE: {city}</Text>
            </View>

            <View style={styles.right}>
              {
                typePeople == "physical"
                  ?
                  <>
                    <Text style={styles.titleInfo}>CPF: {formatCpfCnpj('CPF', cpf)}</Text>
                    <Text style={styles.titleInfo}>CONTATO: {nameContact}</Text>
                  </>
                  :
                  <>
                    <Text style={styles.titleInfo}>NOME FANTASIA: {fantasyName}</Text>
                    <Text style={styles.titleInfo}>INSC. ESTADUAL: {stateRegistration}</Text>
                  </>
              }
              <Text style={styles.titleInfo}>EMAIL: {email}</Text>
              <Text style={styles.titleInfo}>ENDEREÇO: {address}</Text>
              <Text style={styles.titleInfo}>BAIRRO: {neighborhood}</Text>
              <Text style={styles.titleInfo}>CEP: {zipCode}</Text>
              <Text style={styles.titleInfo}>UF: {uf}</Text>
            </View>
          </View>
        </View>

        {
          typePeople == "physical"
            ?
            <>
              <View style={styles.section}>
                <Text style={styles.titleSection}>DADOS DE CONTATO</Text>

                <View style={styles.row}>
                  <View style={styles.left}>
                    <Text style={styles.titleInfo}>NOME: {nameContact}</Text>
                    <Text style={styles.titleInfo}>WEBSITE: {webSite}</Text>
                    <Text style={styles.titleInfo}>EMAIL: {emailContact}</Text>
                  </View>

                  <View style={styles.right}>
                    <Text style={styles.titleInfo}>TELEFONE: {tellContact}</Text>
                    <Text style={styles.titleInfo}>CELULAR: {cellContact}</Text>
                  </View>
                </View>
              </View>
            </>
            : <></>
        }

        <View style={styles.section}>
          <Text style={styles.titleSection}>DADOS {type == "PEDIDO" || type == "ORÇAMENTO" ? "DO" : "DA"} {type}</Text>

          <Table>
            <TableRow>
              <TableCell width={itemsTable.cell1.width}></TableCell>
              <TableCell width={itemsTable.cell2.width}>DESCRIÇÃO</TableCell>
              <TableCell width={itemsTable.cell3.width}>QTDE.</TableCell>
              <TableCell width={itemsTable.cell4.width}>UN.</TableCell>
              <TableCell width={itemsTable.cell5.width}>TAMANHO</TableCell>
              <TableCell width={itemsTable.cell6.width}>NCM</TableCell>
              <TableCell width={itemsTable.cell7.width}>VALOR UNIT.</TableCell>
              <TableCell width={itemsTable.cell8.width}>VALOR TOTAL</TableCell>
            </TableRow>
          </Table>

          <Table>
            {
              products.map((product: any, index: number) => {
                return (
                  <View key={index} style={styles.rowProduct}>
                    <Text style={styles.cell1}>{index + 1}.</Text>
                    <Text style={styles.cell2}>{product.name}</Text>
                    <Text style={styles.cell3}>{product.amount}</Text>
                    <Text style={styles.cell4}>{product.unit}</Text>
                    <Text style={styles.cell5}>{product.size}</Text>
                    <Text style={styles.cell6}>{product.ncm}</Text>
                    <Text style={styles.cell7}>{formatCurrency(product.unitValue)}</Text>
                    <Text style={styles.cell8}>{formatCurrency(product.total)}</Text>
                  </View>
                )
              })
            }
          </Table>
        </View>

        <View style={styles.section}>
          <Text style={styles.titleSection}>DADOS DE PAGAMENTO</Text>

          <View style={styles.rowPayment}>
            <View style={styles.left}>
              <View style={styles.rowPayment}>
                <View style={styles.leftPayment}>
                  <Text style={styles.titleInfo}>TOTAL DE QUANTIDADES</Text>
                  {/* <Text style={styles.titleInfo}>VALOR TOTAL DOS PRODUTOS</Text> */}
                  <Text style={styles.titleInfo}>DESCONTO CONCEDIDO</Text>
                </View>
                <View style={styles.rightPayment}>
                  <Text style={styles.titleInfo}>{countTotal}</Text>
                  {/* <Text style={styles.titleInfo}>R$ 2.750,00</Text> */}
                  <Text style={styles.titleInfo}>{formatCurrency(discount)}</Text>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.rowPayment}>
                <View style={styles.leftPayment}>
                  <Text style={styles.titleInfo}>NÚMERO TOTAL DE ITENS</Text>
                  <Text style={styles.titleInfo}>VALOR TOTAL DO PEDIDO</Text>
                </View>
                <View style={styles.rightPayment}>
                  <Text style={styles.titleInfo}>{amountTotal} produto(s)</Text>
                  <Text style={styles.titleInfo}>{formatCurrency(totalOrder)}</Text>
                </View>
              </View>
            </View>
          </View>


          <Table>
            <TableRow>
              <TableCell width={paymentsTable.cell1.width}>PARCELA</TableCell>
              <TableCell width={paymentsTable.cell2.width}>DATA</TableCell>
              <TableCell width={paymentsTable.cell3.width}>VALOR</TableCell>
              <TableCell width={paymentsTable.cell4.width}>PAGAMENTO</TableCell>
              <TableCell width={paymentsTable.cell5.width}>OBS</TableCell>
            </TableRow>
          </Table>

          <Table>
            {
              installments.map((installment: any, index: number) => {
                return (
                  <View key={index} style={styles.rowInstallment}>
                    <Text style={styles.cell10}>{installment.name}</Text>
                    <Text style={styles.cell10}>{formatDate(installment.date)}</Text>
                    <Text style={styles.cell10}>{formatCurrency(installment.value)}</Text>
                    <Text style={styles.cell9}>{installment.payment}</Text>
                    <Text style={styles.cell9}>{installment.comments ?? ''}</Text>
                  </View>
                )
              })
            }
          </Table>
        </View>

        {!!comments ? (
          <View style={styles.section}>
            <Text style={styles.titleSection}>OBSERVAÇÕES</Text>

            <Text style={styles.observationText}>{comments}</Text>
          </View>
        ) : <></>}

        {!!equipment ? (
          <View style={styles.section}>
            <Text style={styles.titleSection}>EQUIPAMENTO</Text>

            <Text style={styles.observationText}>{equipment}</Text>
          </View>
        ) : <></>}

        {!!issues ? (
          <View style={styles.section}>
            <Text style={styles.titleSection}>PROBLEMAS</Text>

            <Text style={styles.observationText}>{issues}</Text>
          </View>
        ) : <></>}

        {!!receivement ? (
          <View style={styles.section}>
            <Text style={styles.titleSection}>OBSERVAÇÕES DO RECEBIMENTO</Text>

            <Text style={styles.observationText}>{receivement}</Text>
          </View>
        ) : <></>}

        {!!report ? (
          <View style={styles.section}>
            <Text style={styles.titleSection}>LAUDO TÉCNICO / DIAGNÓSTICO</Text>

            <Text style={styles.observationText}>{report}</Text>
          </View>
        ) : <></>}
      </Page>
    </Document>
  );
}

