import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { formatNumberToString } from "../../../utils/formatCurrency";

export function ServicesTable({ services, observations, frequency, payment}: any) {

    return (
        <Table className="border ml-3 mt-0">
            <TableHead>
                <TableRow>
                    <TableCell>Serviço</TableCell>
                    <TableCell>Observações</TableCell>
                    <TableCell>Periodicidade</TableCell>
                    <TableCell>Próx. Pagamento</TableCell>
                    <TableCell>Valor Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {services.map((service: any) => (
                    <TableRow key={service.id}>
                        <TableCell>{service.INSTANCE.name ?? '-'}</TableCell>
                        <TableCell>{observations}</TableCell>
                        <TableCell>{frequency}</TableCell>
                        <TableCell>{payment}</TableCell>
                        <TableCell>{'R$ ' +  formatNumberToString(service.totalValue)}</TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
    );
}