import { LoadDataParams } from "../components/ListWithModalChangeSituation";
import { User } from "./User";
import { yesOrNo } from "./yesOrNo";

export type IndicationsStatus = {
    data: Indications[],
    totalCount: number;
    loadData?: (params: LoadDataParams) => any;
    triggerLoad?: boolean;
    setTriggerLoad?: React.Dispatch<React.SetStateAction<boolean>>;
    onClickIndication: (indicationId: number) => void;
}

export enum status {
    PENDING = "pending",
    ACCEPT = "accept",
    CANCELED = "canceled",
}

export enum typeIndication {
    MANUAL = "manual",
    LINK = "link",
}

export enum whoPay {
    ACCOUNTANT = "accountant",
    COMPANY = "company"
}

export type Indications = {
    accountantEntity: User;
    indicatedUserEntity: User;
    companyEntity: any;

    id: number;
    companyId: number;
    accountantId: number;
    typeIndication: typeIndication;
    whoPay: whoPay;
    companyAlreadyExists: yesOrNo;
    status: status;
    createdDate: Date;
    acceptDate: Date;
    canceledDate: Date;
}