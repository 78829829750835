import React, { useEffect, useState, useLayoutEffect, useCallback, useRef } from 'react';

import '../../style.css';

import { Collapse, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import useBackendLoad from '../../hooks/backendReload';
import { getDate } from '../../utils/dateTimeHelper';
import api from '../../services/Api';
import { BankReconciliation } from '../../types/BankReconciliation';
import { formatDate } from '../../utils/dateFormat';
import { formatCurrency } from '../../utils/formatCurrency';
import { BsVariant } from '../../types/BsVariant';
import { Search } from '../../components/Search';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { BankAccount } from '../../types/BankAccount';
import BankAccountService from '../../services/BankAccountService';
import { Button } from 'react-bootstrap';

const headData: HeadDataBaseProps[] = [
    { reference: "createdDate", value: 'Data Conciliado' },
    { reference: "billType", value: 'Tipo Conta', notSortable: true },
    { reference: "billName", value: 'Título' },
    { reference: "billIssueDate", value: 'Data da Conta' },
    { reference: "billPayment", value: 'Forma de Pagamento' },
    { reference: "billAmount", value: 'Valor' },
];

type Filters = {
    searchQuery: string;
    accountBankId: number;
    billType: string;
    createdDateMin: string;
    createdDateMax: string;
}

export function ListBankReconciliation() {
    const [bankReconciliations, setBankReconciliations] = useState<BankReconciliation[]>([]);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [countTotalReconciliations, setCountTotalReconciliations] = useState(0);
    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    //Search
    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [accountBankId, setAccountBankId] = useState(0);
    const [billType, setBillType] = useState("");
    const [createdDateMin, setCreatedDateMin] = useState("");
    const [createdDateMax, setCreatedDateMax] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: any[], count: number }>('/bankReconciliation', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setBankReconciliations(rows);
        setCountTotalReconciliations(count);
    }, []);

    useLayoutEffect(() => {
        const list: BodyDataBaseProps[][] = [];
        const aux = bankReconciliations;

        aux.forEach((bankReconciliation) => {
            const createdDate = getDate({ initialDate: new Date(bankReconciliation.createdDate) }).dateStr;
            const billIssueDate = bankReconciliation.billToPay ? formatDate(bankReconciliation.billToPay.issueDate) : formatDate(bankReconciliation.billToReceive.issuanceDate);
            const billAmount = bankReconciliation.billToPay ? formatCurrency(bankReconciliation.billToPay.amount) : formatCurrency(bankReconciliation.billToReceive.amount);

            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(bankReconciliation.id), hidden: true, id: true },
                { for: "createdDate", value: createdDate },
                { for: "billType", value: String(bankReconciliation.billType === 'pay' ? 'Despesa' : 'Receita') },
                { for: "billName", value: bankReconciliation.billToPay?.name ?? bankReconciliation.billToReceive.name },
                { for: "billIssueDate", value: billIssueDate },
                { for: "billPayment", value: bankReconciliation.billToPay?.payment ?? bankReconciliation.billToReceive.payment },
                { for: "billAmount", value: billAmount },
            ];

            list.push(data);
        });

        setBodyData(list);
    }, [bankReconciliations]);

    const clearSearch = () => {
        setSearchQuery('');
        setAccountBankId(0);
        setBillType('');
        setCreatedDateMin('');
        setCreatedDateMax('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = {
            searchQuery, accountBankId,
            billType, createdDateMin, createdDateMax,
        };

        reloadData();
    }, [
        bankReconciliations, searchQuery, accountBankId,
        billType, createdDateMin, createdDateMax,
    ]);

    const handleClickViewBill = useCallback((id: string) => {
        const foundRow = bankReconciliations.find(row => row.id === Number(id));

        if (!foundRow) return;

        pushHistory(`/${foundRow.billToPay ? 'contas-a-pagar' : 'contas-a-receber'}/${foundRow.billToPay?.id || foundRow.billToReceive.id}`);
    }, [pathname, bankReconciliations]);

    return (
        <div className="card card-body pt-4 newProductWrapper">
            <div className="col-lg-12 mt-3">
                <h3>Títulos Conciliados</h3>
            </div>

            <div className="row d-flex align-items-center">
                <div className="col-lg-9"></div>
                <div className="col-lg-3 mt-3">
                    <Search
                        query={searchQuery}
                        setQuery={setSearchQuery}
                        setCollapseAdvancedSearch={setAdvancedSearch}
                        onClickSearch={handleClickSearch}
                    />
                </div>
            </div>
            <Collapse in={advancedSearch}>
                <div className="row d-flex align-items-center">
                    <div className="col-lg-2">
                        <TextField
                            select
                            size="small"
                            label="Tipo"
                            margin="normal"
                            variant="outlined"
                            value={billType}
                            onChange={(e) => setBillType(e.target.value)}
                        >
                            <MenuItem key="0" value="">
                                Todos
                            </MenuItem>
                            <MenuItem key="1" value="receive">
                                Receitas
                            </MenuItem>
                            <MenuItem key="2" value="pay">
                                Despesas
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className="col-lg-3">
                        <ApiResourceSelect
                            label="Conta bancária"
                            getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                            value={accountBankId}
                            onSelect={(option) => setAccountBankId(option?.id ?? 0)}
                            apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                            getSelectedOption={(loadedOptions) => {
                                if (!accountBankId) return null;
                                return loadedOptions.find((option) => option.id === accountBankId) ?? BankAccountService.getBankAccountById(accountBankId)
                            }}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data da Conciliação"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        DE
                                    </InputAdornment>
                                ),
                            }}
                            value={createdDateMin}
                            onChange={(e) => setCreatedDateMin(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-3">
                        <TextField
                            type="date"
                            label="Data da Conciliação"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ATÉ
                                    </InputAdornment>
                                ),
                            }}
                            value={createdDateMax}
                            onChange={(e) => setCreatedDateMax(e.target.value)}
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <Button
                            onClick={handleClickSearch}
                            className="mr-3"
                        >
                            Pesquisar
                        </Button>

                        <Button
                            onClick={clearSearch}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Collapse>

            <div className="col-lg-12 mt-3">
                <ListWithModalChangeSituation
                    headData={headData}
                    bodyData={bodyData}
                    sortable={true}
                    loadData={loadData}
                    totalCount={countTotalReconciliations}
                    triggerLoad={triggerLoad}
                    setTriggerLoad={setTriggerLoad}
                    defaultSortReference={'createdDate'}
                    customButtons={[
                        {
                            class: 'btn-light-info',
                            content: (<i className='p-0 flaticon-eye'></i>),
                            variant: BsVariant.INFO,
                            popup: "Ver Conta",
                            onClick: (id) => handleClickViewBill(id),
                        },
                    ]}
                />
            </div>
        </div>
    );
}