import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import api from "../../../services/Api";
import { bypassAuthHeader } from "../../../utils/bypassAuthHeader";
import { sanitizeInput, SanitizeInputType } from "../../../utils/sanitizeInput";

export type InfoAddressData = {
    zipcode?: string,
    type?: string,
};

type useCompanyFormDetailsParams = {
    typePeople: string;
    bypassAuth?: boolean;
};

export default function useCompanyFormDetails({ typePeople, bypassAuth }: useCompanyFormDetailsParams) {
    const [cpf, setCpf] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [municipalRegistration, setMunicipalRegistration] = useState("");
    const [stateRegistration, setStateRegistration] = useState("");
    const [taxRegime, setTaxRegime] = useState("");
    const [cnae, setCnae] = useState("");
    const [rntrc, setRntrc] = useState("");
    const [responsible, setResponsible] = useState("");
    const [cell, setCell] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");

    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");

    const [cpfInvalid, setCpfInvalid] = useState(false);
    const [cnpjInvalid, setCnpjInvalid] = useState(false);
    const [responsibleInvalid, setResponsibleInvalid] = useState(false);
    const [cellInvalid, setCellInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [zipCodeInvalid, setZipCodeInvalid] = useState(false);
    const [addressInvalid, setAddressInvalid] = useState(false);
    const [numberInvalid, setNumberInvalid] = useState(false);
    const [districtInvalid, setDistrictInvalid] = useState(false);
    const [cityInvalid, setCityInvalid] = useState(false);
    const [stateInvalid, setStateInvalid] = useState(false);
    const [isSearchingCnpj, setIsSearchingCnpj] = useState(false);

    const [allStates, setAllStates] = useState({ states: [] });

    useEffect(() => {
        const getStates = async () => {
            const statesBrazil = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            setAllStates({ states: statesBrazil.data });
        }

        getStates();
    }, []);

    const infoAddress = useCallback(async ({zipcode, type}: InfoAddressData = {}) => {
        const zipcodeChanged = (zipcode || (zipCode || '')).replace(/[^0-9]/g, "");

        const cep = await axios.get(`https://viacep.com.br/ws/${zipcodeChanged}/json/`);

        setAddress(cep.data.logradouro);
        setDistrict(cep.data.bairro);
        setCity(cep.data.localidade);
        setState(cep.data.uf);
    }, [zipCode]);

    const getErrorsCompanyDetailsInputs = useCallback(async (): Promise<string> => {
        if(typePeople === 'physical') {
            if (!cpf || sanitizeInput(SanitizeInputType.NUMERIC, cpf).length !== 11) {
                setCpfInvalid(true);
                return 'Preencha o CPF corretamente'
            }
        } else {
            if (!cnpj || sanitizeInput(SanitizeInputType.NUMERIC, cnpj).length !== 14) {
                setCnpjInvalid(true);
                return 'Preencha o CNPJ corretamente'
            }
        }
        
        if (!responsible) {
            setResponsibleInvalid(true);
            return 'Preencha o nome do responsável corretamente';
        }
        if (!cell) {
            setCellInvalid(true);
            return 'Preencha o celular corretamente';
        }
        if(!(await verifyCellExists())) {
            return 'Celular já existe';
        }
        if (!email) {
            setEmailInvalid(true);
            return 'Preencha o e-mail corretamente';
        }
        if(!(await verifyEmailExists())) {
            return 'E-mail já existe';
        }
        if(!(await verifyCnpjExists())) {
            return 'CNPJ já existe';
        }
        if(!(await verifyCpfExists())) {
            return 'CPF já existe';
        }

        if (!zipCode) {
            setZipCodeInvalid(true);
            return 'Preencha o CEP corretamente';
        }
        if (!address) {
            setAddressInvalid(true);
            return 'Preencha o endereço corretamente';
        }
        if (!number) {
            setNumberInvalid(true);
            return 'Preencha o número do endereço corretamente';
        }
        if (!district) {
            setDistrictInvalid(true);
            return 'Preencha o bairro corretamente';
        }
        if (!city) {
            setCityInvalid(true);
            return 'Preencha a cidade corretamente';
        }
        if (!state) {
            setStateInvalid(true);
            return 'Preencha o estado corretamente';
        }

        return '';

    }, [
        responsible, cell, email,
        typePeople, cpf, cnpj,
        zipCode, address, number,
        district, city, state,
    ]);

    async function verifyCellExists() {
        try {
            const response = await api.head(`companies/check/cell?cell=${cell}`, { headers: bypassAuth ? bypassAuthHeader : undefined });
            return true;
        } catch (error) {
            return false;
        }
    }

    async function verifyEmailExists() {
        try {
            const response = await api.head(`companies/check/email?email=${email}`, { headers: bypassAuth ? bypassAuthHeader : undefined });
            return true;
        } catch (error) {
            return false;
        }
        
    }

    const verifyCnpjExists = useCallback(async () => {
        try {
            const response = await api.head(`companies/check/cnpj?cnpj=${cnpj.replace(/[^0-9]/g, "")}`, { headers: bypassAuth ? bypassAuthHeader : undefined });
            return true;
        } catch (error) {
            return false;
        }
        
    }, [bypassAuth, cnpj]);

    const verifyCpfExists = useCallback(async () => {
        try {
            const response = await api.head(`companies/check/cpf?cpf=${cpf.replace(/[^0-9]/g, "")}`, { headers: bypassAuth ? bypassAuthHeader : undefined });
            return true;
        } catch (error) {
            return false;
        }
        
    }, [bypassAuth, cpf]);

    return {
        // States
        cpf, setCpf,
        cnpj, setCnpj,
        municipalRegistration, setMunicipalRegistration,
        stateRegistration, setStateRegistration,
        taxRegime, setTaxRegime,
        cnae, setCnae,
        rntrc, setRntrc,
        responsible, setResponsible,
        cell, setCell,
        phone, setPhone,
        email, setEmail,
        website, setWebsite,
        zipCode, setZipCode,
        address, setAddress,
        district, setDistrict,
        city, setCity,
        state, setState,
        number, setNumber,
        complement, setComplement,
        cpfInvalid, setCpfInvalid,
        cnpjInvalid, setCnpjInvalid,
        responsibleInvalid, setResponsibleInvalid,
        cellInvalid, setCellInvalid,
        emailInvalid, setEmailInvalid,
        zipCodeInvalid, setZipCodeInvalid,
        addressInvalid, setAddressInvalid,
        numberInvalid, setNumberInvalid,
        districtInvalid, setDistrictInvalid,
        cityInvalid, setCityInvalid,
        stateInvalid, setStateInvalid,
        isSearchingCnpj, setIsSearchingCnpj,
        allStates, setAllStates,

        // Functions
        infoAddress,
        getErrorsCompanyDetailsInputs,
        verifyCnpjExists,
        verifyCpfExists,
    };
}