import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListNotifications } from '../pages/Notifications/List-Notifications';

export function NotifyDueDate() {
    
    return (
        <Switch>
            <Route path="/notificacoes" component={ListNotifications}/>
        </Switch>
    );
}