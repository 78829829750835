export enum yesOrNo {
    YES = "y", 
    NO = "n"
}

export type SubscriptionPlan = {
    id: number,
    profileId: number,
    name: string,
    description: string,
    img: string,
    value: number,
    max_customers: number,
    max_products: number,
    max_suppliers: number,
    max_nfe: number,
    max_nfce: number,
    max_cte: number,
    max_mdfe: number,
    max_nfse: number,
    max_billets: number,
    max_events: number,
    max_users: number,
    max_storage: number,
    max_logged_users: number,
    max_branches: number,
    paymentFrequency: frequency;
    paymentFrequencyDaysAmount: number;
    durationType: durationType;
    duration: number | null;
    visible: yesOrNo,
    paymentApiId: number,
    createdDate: Date,
    updatedDate: Date,
};

export enum frequency {
    NONE = 'none',
    DAYS = 'days',
    MONTHLY = 'monthly',
    BIMONTHLY = 'bimonthly',
    TRIMONTHLY = 'trimonthly',
    QUARTERLY = 'quarterly',
    SEMIANNUAL = 'semiannual',
    ANNUAL = 'annual',
    OTHER = 'other', // Não está sendo usado
};

export enum durationType {
    NO_DURATION = 'no_duration',
    DAYS = 'days',
    MONTHS = 'months',
    TWO_MONTHS = 'two_months',
    THREE_MONTHS = 'three_months',
    FOUR_MONTHS = 'four_months',
    SEMESTERS = 'semesters',
    YEARS = 'years',
};