import React, { useMemo } from "react";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { arrayChunk } from "../../utils/arrayChunk";
import { formatCurrency } from "../../utils/formatCurrency";
import { api as apiUrl } from "../../services/ApiURL";
import { Checkbox, Radio } from "@material-ui/core";

type PlanListProps = {
    subscriptionPlans: SubscriptionPlan[];
    selectedSubscriptionPlan?: SubscriptionPlan;
    handleChangeSubscriptionPlan: (subscriptionPlan: SubscriptionPlan | null) => void;
}

export function PlanList({
    subscriptionPlans,
    selectedSubscriptionPlan,
    handleChangeSubscriptionPlan,
}: PlanListProps) {
    const chunkedSubscriptionPlans = useMemo(() => {
        return arrayChunk(subscriptionPlans, 3);
    }, [subscriptionPlans]);

    return (
        <>
            {chunkedSubscriptionPlans.map((row, index) => (
                <div className="card-deck mt-3" key={index}>
                    {row.map((plan) => (
                        <div key={plan.id} className="card card-custom border border-primary" style={{ maxWidth: '30.6%' }}>
                            <div className="card-header d-flex align-items-center justify-content-start">
                                <Radio
                                    color="primary"
                                    checked={selectedSubscriptionPlan?.id === plan.id}
                                    onChange={(e, checked) => handleChangeSubscriptionPlan(checked ? plan : null)}
                                    id={`plan-${plan.id}`}
                                />
                                <label className="mt-4" htmlFor={`plan-${plan.id}`}>
                                    <h3>{plan.name}</h3>
                                </label>
                                {/* <h3 className="mt-2">{plan.name}</h3> */}
                            </div>

                            <div className="card-body d-flex flex-column justify-content-between align-items-center">
                                {plan.img && <img className="img-fluid" src={`${apiUrl}/${plan.img}`} alt="img" />}
                                
                                <strong className="text-primary" style={{ fontSize: 20 }}>
                                    {plan.value > 0 ? formatCurrency(plan.value) : 'Gratuito'}
                                </strong>
                            </div>

                            <div className="card-footer">
                                <p>{plan.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}