import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RequestProvider } from '../pages/Request/context';
import { NewRequests } from '../pages/Request/New-Requests';
import { EditRequests } from '../pages/Request/Edit-Requests';
import { ListRequests } from '../pages/Request/List-Requests';

export function Requests() {
    
    return (
        <RequestProvider>
            <Switch>
                <Route path="/pedidos/adicionar" component={NewRequests}/>
                <Route path="/pedidos/duplicar/:idToClone" component={NewRequests}/>
                <Route path="/pedidos/:id" component={EditRequests}/>
                <Route path="/pedidos" component={ListRequests}/>
            </Switch>
        </RequestProvider>
    );
}
