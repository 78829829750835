import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Spinner, Tabs, Tab, Modal } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';
import { MenuItem, TextField, Link, InputAdornment } from '@material-ui/core';


import api from "../../services/Api";
import { PaymentDetails, Product, usePurchaseOrder } from './context';
import { ListProducts } from './ListProducts';
import { useSubheader } from "../../../_metronic/layout";
import { NumericFormat } from '../../components/NumericFormat';
import { ConditionOrInstallment } from './ConditionOrInstallments';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';

import '../../style.css';
import LogService from '../../services/LogService';
import { freightModalityById } from '../../utils/freightModality';
import SellerService from '../../services/SellerService';
import CustomerService from '../../services/CustomerService';
import CustomerSelect from '../../components/CustomerSelect';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import { Carrier } from '../../types/Carrier';
import CarrierService from '../../services/CarrierService';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { NewProductModal } from '../../components/Product/NewProductModal';
import useNewProductForm from '../../hooks/newProductForm';
import { Product as GeneralProduct } from '../../types/Product';
import { Product as SelectableProduct } from '../../services/ProductService';
import UploadFiles from '../../components/UploadFiles';
import { deleteMultipleFiles, uploadMultipleFiles } from '../../utils/FilesHelper';

export function EditPurchaseOrder() {
    const { user } = useSelector((state: any) => state.auth);


    const [activeTab, setActiveTab] = useState("products");

    const [note, setNote] = useState("");
    const [total, setTotal] = useState(0);
    const [weight, setWeight] = useState("");
    const [seller, setSeller] = useState("");
    const [carrier, setCarrier] = useState("");
    const [supplierId, setSupplierId] = useState<number>();
    const [supplierName, setSupplierName] = useState("");
    const [deadline, setDeadline] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [numberOrder, setNumberOrder] = useState("");
    const [insideNotes, setInsideNotes] = useState("");
    const [freightPrice, setFreightPrice] = useState(0);
    const [discountMoney, setDiscountMoney] = useState(0);
    const [conditionName, setConditionName] = useState("");
    const [modalityFreight, setModalityFreight] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState("0");
    const [status, setStatus] = useState("");
    const [showModalAttention, setShowModalAttention] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [reference, setReference] = useState('');

    const history = useHistory();
    const subHeader = useSubheader();
    const { id } = useParams<{ id: string }>();
    const { classes, products, payments, dispatchProducts, dispatchPayment } = usePurchaseOrder();

    //Input Verify
    const [supplierError, setSupplierError] = useState(false);
    const [productsError, setProductsError] = useState(false);
    const [installmentsError, setInstallmentsError] = useState(false);

    const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
    const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

    subHeader.setTitle("Adicionar uma Ordem de Compra");

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setSupplierId(createdCustomer.id);
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText, typeRegister: 'supplier' });
        setShowModalNewCustomer(true);
    };

    // Modal New Product
    const [productBeingCreated, setProductBeingCreated] = useState<{ index: number, product: Product } | null>(null);
    const {
        showModalNewProduct, setShowModalNewProduct,
        newProductDefaultData, setNewProductDefaultData,
    } = useNewProductForm();

    const handleCreateProduct = useCallback((createdProduct: GeneralProduct) => {
        if (!productBeingCreated) return;

        const selectableProduct: SelectableProduct = {
            ...createdProduct as any,
            saleValue: formatToFloat(createdProduct.saleValue),
        };

        const amount = productBeingCreated.product.amount || 1;

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: productBeingCreated.index,
                product: {
                    ...productBeingCreated.product,
                    isLinked: true,
                    name: createdProduct.name,
                    amount,
                    unitaryValue: productBeingCreated.product.unitaryValue || selectableProduct.saleValue,
                    total: productBeingCreated.product.total || (selectableProduct.saleValue * amount),
                    INSTANCE: selectableProduct,
                },
            }
        });
    }, [productBeingCreated]);

    const handleClickAddProduct = (typedText: string, index: number, product: Product) => {
        setNewProductDefaultData({ nameProduct: typedText });
        setProductBeingCreated({ index, product })
        setShowModalNewProduct(true);
    };

    useEffect(() => {
        let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

        const discountNumber = discountMoney ? discountMoney : 0;
        const discountPercentageFloat = formatToFloat(discountPercentage);
        const freigthNumber = freightPrice ? freightPrice : 0;

        const partial = totalProduct + freigthNumber;

        if (discountNumber > partial) {
            alert("Desconto não pode ser maior que o valor total");

            setFreightPrice(0);
            setTotal(0);
            setDiscountMoney(0);

            return;
        }

        let total = partial - discountNumber;

        if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
            total = total - (total * (discountPercentageFloat / 100));
        }

        setTotal(parseFloat(String(total)));
    }, [products, discountMoney, discountPercentage, freightPrice]);

    useEffect(() => {
        let converted = formatToFloat(discountPercentage);
        if (converted > 100) {
            setDiscountPercentage("100,00");
        } else {
            setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
        }
    }, [discountPercentage]);

    useEffect(() => {
        async function loadData() {
            const { data } = await api.get(`purchase-order/${id}`);
            const productsData: Product[] = JSON.parse(data.products);
            const installmentsData: PaymentDetails[] = JSON.parse(data.installments);

            dispatchProducts({
                type: "INITIAL",
                payload: {
                    index: 0,
                    products: productsData,
                }
            });

            dispatchPayment({
                type: "INITIAL",
                payload: {
                    payments: installmentsData,
                }
            });

            setNote(data.note);
            setTotal(parseFloat(data.totalPrice));
            setWeight(formatCurrency(data.totalWeight));
            setSeller(String(data.seller ?? ''));
            setCarrier(data.carrier);
            setSupplierId(data.supplierId ?? undefined);
            setSupplierName(data.supplierName ?? '');
            setDeadline(data.deadline);
            setOrderDate(data.orderDate);
            setNumberOrder(data.numberOrder);
            setInsideNotes(data.insideNote);
            setFreightPrice(parseFloat(data.freightPrice));
            setDiscountMoney(data.discountMoney);
            setConditionName(data.conditionName);
            setModalityFreight(Number(data.modalityFreight));
            setDiscountPercentage(data.discountPercentage);
            setStatus(data.status);
            setReference(data.reference);
            setFilesNamesSaved(data.attachments ? JSON.parse(data.attachments) : []);
        }

        loadData();
    }, []);

    function inputsValid() {
        setSupplierError(false);
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            setProductError(i, product, false);
        }

        var isSubmit = true;

        if (!supplierId && !supplierName) {
            setMsgError("Selecione um fornecedor!");
            setShowModalAttention(true);
            setSupplierError(true);
            isSubmit = false;
            return isSubmit;
        }

        const hasProducts = products.find((product) => product.name || product.INSTANCE.id);

        if (!hasProducts) {
            setActiveTab("products");
            setMsgError("Adicione pelo menos 1(um) Produto para a Ordem de Compra!");
            setShowModalAttention(true);
            isSubmit = false;
            return isSubmit;
        }

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product.total && !product.INSTANCE?.id && !product.name) {
                setProductError(i, product, true);
                setMsgError('Informe um produto!');
                setActiveTab('products');
                setShowModalAttention(true);
                return false;
            }
        }

        if (!payments.length) {
            setActiveTab("payment");
            setMsgError("Informe as parcelas do pedido!");
            setShowModalAttention(true);
            isSubmit = false;
            return isSubmit;
        }

        const sumInstallments = payments.reduce((sum, payment) => sum + payment.value, 0);

        if (sumInstallments !== formatToFloat(total)) {
            setActiveTab("payment");
            setMsgError("A soma das parcelas deve concidir com o valor total da ordem de compra!");
            setShowModalAttention(true);
            setInstallmentsError(true);
            isSubmit = false;
            return isSubmit;
        }

        return isSubmit;
    }

    function setProductError(index: number, product: Product, error?: boolean) {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index: index,
                product: {
                    ...product,
                    hasError: error,
                },
            }
        });
    }

    const isValid = (data: any) => {
        setProductsError(data)
    };

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        if (inputsValid()) {
            const lastInputs = await api.get(`purchase-order/${id}`);
            const filesRemained = await deleteMultipleFiles(lastInputs.data.attachments ? JSON.parse(lastInputs.data.attachments) : [], filesNamesSaved, setShowModalAttention, setMsgError, setIsSubmit);
            let filesPath: any  = [];
            if (filesSelected) {
                filesPath = await uploadMultipleFiles(filesSelected, setShowModalAttention, setMsgError, setIsSubmit);                
                if (filesPath === false) {
                    return;
                }
            } else {
                filesPath = [];
            }
            

            try {
                const raw = {
                    totalPrice: total,
                    products: JSON.stringify(products),
                    installments: JSON.stringify(payments),
                    note: String(note).length > 0 ? note : null,
                    seller: String(seller).length > 0 ? Number(seller) : null,
                    totalWeight: weight ? formatToFloat(weight) : null,
                    carrier: String(carrier).length > 0 ? carrier : null,
                    deadline: String(deadline).length > 0 ? deadline : null,
                    supplierId: supplierId ?? null,
                    supplierName: !supplierId ? supplierName : null,
                    orderDate: String(orderDate).length > 0 ? orderDate : null,
                    insideNote: String(insideNotes).length > 0 ? insideNotes : null,
                    numberOrder: String(numberOrder).length > 0 ? numberOrder : null,
                    freightPrice: freightPrice ? freightPrice : null,
                    discountMoney: discountMoney > 0 ? discountMoney : null,
                    discountPercentage: discountPercentage ? formatToFloat(discountPercentage) : null,
                    conditionName: String(conditionName).length > 0 ? conditionName : null,
                    modalityFreight: modalityFreight ?? null,
                    reference: reference ?? null,
                    attachments: JSON.stringify([...filesRemained, ...filesPath])
                }

                const response = await api.put(`purchase-order/${id}`, raw);

                const previousCustomerEntity = await CustomerService.getCustomerById(lastInputs.data.supplierId);
                const newCustomerEntity = await CustomerService.getCustomerById(response.data.supplierId);

                LogService.logEdit({
                    itemId: response.data.id,
                    itemName: 'Compra',
                    module: 'Ordem de compra',
                    oldData: {
                        ...lastInputs.data,
                        supplier: CustomerService.getCustomerName(previousCustomerEntity, lastInputs.data.customerName)
                    },
                    newData: {
                        ...response.data,
                        supplier: CustomerService.getCustomerName(newCustomerEntity, response.data.customerName)
                    },
                    formattedFields: {
                        deadLine: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        orderDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                        modalityFreight: (value) => freightModalityById(value ?? ''),
                        seller: (value) => SellerService.getNameByIdAsync(value),
                        freightPrice: (value) => formatNumberToString(value),
                        discountMoney: (value) => formatNumberToString(value),
                        discountPercentage: (value) => formatNumberToString(value),
                    },
                    fieldsMap: {
                        supplier: 'Fornecedor',
                        seller: 'Vendedor',
                        note: 'Observações',
                        insideNote: 'Observações internas',
                        deadLine: 'Prazo de entrega',
                        orderDate: 'Data da ordem',
                        numberNumber: 'Nº do pedido',
                        modalityFreight: 'Modalidade de frete',
                        carrier: 'Transportadora',
                        freightPrice: 'Valor do frete',
                        discountMoney: 'Desconto em dinheiro',
                        discountPercentage: 'Desconto por porcentagem',
                        movedToStock: 'Movido para Estoque',
                        reference: 'Referência'
                    },
                });

                history.push("/ordem-de-compra");

            } catch (error) {
                console.log(error);
            }
        }

    }

    return (

        <div className="card card-custom gutter-b p-6 newProductWrapper">
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType="supplier"
            />

            <NewProductModal
                showModal={showModalNewProduct}
                setShowModal={setShowModalNewProduct}
                onCreateProduct={handleCreateProduct}
                defaultData={newProductDefaultData}
                allowChangeType={false}
            />

            <Modal
                show={showModalAttention}
                onHide={() => setShowModalAttention(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAttention(!showModalAttention)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={(evt) => onSubmit(evt)}>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-5">
                            <CustomerSelect
                                label="Fornecedor *"
                                hasError={supplierError}
                                isEdit
                                customerId={supplierId}
                                setCustomerId={setSupplierId}
                                customerName={supplierName}
                                setCustomerName={setSupplierName}
                                entityId={id}
                                entityType='purchase'
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                allowIncomplete={true}
                                typeCustomer="supplier"
                                onClickAddCustomer={handleClickAddCustomer}
                            />
                        </div>

                        <div className="col-lg-4">
                            <ApiResourceSelect
                                label="Vendedor"
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                getOptionLabel={(option: any) => option.name}
                                value={seller}
                                onSelect={(option) => setSeller(option ? String(option.id) : '')}
                                apiSearchHandler={(typedText) => SellerService.getSellersFiltered({ name: typedText })}
                                getSelectedOption={(loadedOptions) => {
                                    if (!seller) return null;
                                    return loadedOptions.find((option) => option.id === Number(seller)) ?? SellerService.getSellerById(seller)
                                }}
                            />
                        </div>

                        <div className="col-lg-2">
                            <TextField
                                label="Nº pedido"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                value={numberOrder}
                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        {
                            status == "attended" || user.isAccountant == "y"
                                ?
                                <></>
                                :
                                <div className="col-lg-1 d-flex align-items-end">
                                    <Button
                                        type='submit'
                                        className='mb-2'
                                        variant="primary"
                                        disabled={isSubmit}
                                    >

                                        {
                                            isSubmit ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />

                                                    <span className='ml-2'>
                                                        Aguarde...
                                                    </span>

                                                </>
                                                :
                                                <span>Salvar</span>
                                        }
                                    </Button>
                                </div>
                        }

                    </div>
                </div>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id='form-tabs' className="mb-3 mt-3">
                    <Tab eventKey='products' title='Produtos'>
                        <div className="card-body p-0">
                            <div className="row">
                                {
                                    products.map(
                                        (prod, index) => (
                                            <ListProducts
                                                key={index}
                                                product={prod}
                                                index={index}
                                                status={status}
                                                isEdit
                                                entityId={id}
                                                onClickAddProduct={handleClickAddProduct}
                                            />
                                        )
                                    )
                                }

                                {
                                    user.isAccountant == "n"
                                        ?
                                        <div className="row col-lg-12 mt-3 ml-2">
                                            <button
                                                type="button"
                                                className="btn btn-link-secondary p-0"
                                                onClick={() => dispatchProducts({ type: "ADD" })}
                                                disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                            >
                                                <ins>+ adicionar outro produto</ins>
                                            </button>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='totalRequest' title='Totais do Pedido'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Valor dos produtos"
                                        disabled
                                        className="inputSmaller"
                                        value={products.reduce((acc, { total }) => acc + total, 0)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <TextField
                                        size="small"
                                        label="Valor do IPI"
                                        margin="normal"
                                        variant="outlined"
                                        disabled
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <NumericFormat
                                        label="Valor do Frete"
                                        className="inputSmaller"
                                        value={formatCurrency(freightPrice)}
                                        onChange={e => setFreightPrice(formatToFloat(e.target.value))}
                                        startAdornment="R$"
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Desconto (R$)"
                                        className="inputSmaller"
                                        value={formatCurrency(discountMoney)}
                                        onChange={e => setDiscountMoney(formatToFloat(e.target.value))}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                        startAdornment="R$"
                                    />
                                </div>


                                <div className="col-lg-3">
                                    <NumericFormat
                                        className="inputSmaller"
                                        margin="normal"
                                        customInput={TextField}
                                        variant="outlined"
                                        label="Desconto"
                                        startAdornment="%"
                                        value={discountPercentage}
                                        onChange={(e) => setDiscountPercentage(e.target.value)}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>


                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Peso total"
                                        className="inputSmaller"
                                        value={weight}
                                        onChange={(e) => setWeight(e.target.value)}
                                        startAdornment="KG"
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-3">
                                    <NumericFormat
                                        label="Valor total do pedido"
                                        disabled
                                        className="inputSmaller"
                                        value={formatCurrency(total ?? 0)}
                                        startAdornment="R$"
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='payment' title='Pagamento'>
                        <div className="card-body p-0">
                            <ConditionOrInstallment total={total} conditionName={conditionName} setConditionName={setConditionName} status={status} />
                        </div>
                    </Tab>

                    <Tab eventKey='transport' title='Transporte'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-4">
                                    <TextField
                                        select
                                        size="small"
                                        label="Modalidade de Frete"
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                        value={modalityFreight}
                                        onChange={(evt) => setModalityFreight(Number(evt.target.value))}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                    >
                                        <MenuItem key="0" value="0">
                                            Emitente
                                        </MenuItem>

                                        <MenuItem key="1" value="1">
                                            Destinatário
                                        </MenuItem>

                                        <MenuItem key="2" value="2">
                                            Terceiro
                                        </MenuItem>

                                        <MenuItem key="3" value="3">
                                            Próprio por conta do Remetente
                                        </MenuItem>

                                        <MenuItem key="4" value="4">
                                            Próprio por conta Destinatário
                                        </MenuItem>

                                        <MenuItem key="5" value="9">
                                            Sem Frete
                                        </MenuItem>
                                    </TextField>
                                </div>

                                <div className="col-lg-8">
                                    <ApiResourceSelect
                                        label="Transportadora"
                                        freeSolo
                                        getOptionLabel={(option: Carrier) => option.name}
                                        value={carrier}
                                        onSelect={(option) => setCarrier(option ? String(option.name) : '')}
                                        onInputChange={(typedText) => setCarrier(typedText || '')}
                                        apiSearchHandler={(typedText) => CarrierService.getCarriersFiltered({ name: typedText })}
                                        disabled={user.isAccountant == "y" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='details-request' title='Detalhes do Pedido'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <TextField
                                        type="date"
                                        size="small"
                                        label="Data da ordem"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={orderDate}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-2 d-flex align-items-center">
                                    <TextField
                                        type="date"
                                        size="small"
                                        label="Prazo de Entrega"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={deadline}
                                        onChange={(e) => setDeadline(e.target.value)}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        size="small"
                                        label="Referência"
                                        margin="normal"
                                        variant="outlined"
                                        value={reference}
                                        onChange={(evt) => setReference(evt.target.value)}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-6">
                                    <TextField
                                        label="Observações"
                                        size="small"
                                        multiline
                                        rows="10"
                                        placeholder="Esta informação será impressa nas observações da nota."
                                        margin="normal"
                                        variant="outlined"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                    />
                                </div>


                                <div className="col-lg-6">
                                    <TextField
                                        label="Observações Internas"
                                        size="small"
                                        multiline
                                        rows="10"
                                        placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                                        margin="normal"
                                        variant="outlined"
                                        value={insideNotes}
                                        onChange={(e) => setInsideNotes(e.target.value)}
                                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey='attachment' title='Anexo'>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 ml-2">
                                    <UploadFiles
                                        label="Contrato/Anexos"
                                        filesSelected={filesSelected}
                                        setFilesSelected={setFilesSelected}
                                        filesNamesSaved={filesNamesSaved}
                                        setFilesNamesSaved={setFilesNamesSaved}
                                        disabled={status === 'attended' || user.isAccountant == "y" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </form>

        </div>
    );
}