import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ListStockLocations } from '../pages/Parameters/ListStockLocations';

export function StockLocations() {    
    return (
        <Switch>
            {/* <Route path="/vendedores/adicionar" component={NewSeller}/> */}
            {/* <Route path="/vendedores/:id" component={EditSeller}/> */}
            <Route path="/parametros/locais-de-estoque" component={ListStockLocations}/>
        </Switch>
    );
}