import {
    MenuItem,
    TextField,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
} from '@material-ui/core';
import React, { useRef, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import AntSwitch from '../AntSwitch';
import { useStyles } from '../../hooks/styles';
import BankAccountService from '../../services/BankAccountService';
import { useForm } from 'react-hook-form';
import api from '../../services/Api';
import LogService from '../../services/LogService';
import { banks, banksBillet, numberBanksThatRequireWallet, numberBanksWithAutomaticBillet } from '../../utils/banks';
import { NumericFormat } from '../NumericFormat';
import { BankAccount } from '../../types/BankAccount';
import { getFileExtension } from '../../utils/getFileExtension';
import { GenericObject } from '../../types/GenericObject';
import { bankNumberIsInter } from '../../utils/bankNumberIsInter';

type AddAccountBankModalProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCreateAccountBank?: (accountBank: BankAccount) => void;
}

const steps = BankAccountService.getFormSteps();

export function AddAccountBankModal({
    showModal,
    setShowModal,
    onCreateAccountBank,
}: AddAccountBankModalProps) {
    const classes = useStyles();
    const { register, watch, formState: { errors } } = useForm();

    const [activeStep, setActiveStep] = useState(0);
    const [currentStep, setCurrentStep] = useState(1);
    const [allowContinue, setAllowContinue] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [bankSelected, setBankSelected] = useState('');
    const [bankSelectedVal, setBankSelectedVal] = useState('');

    const [state, setState] = useState({
        checkedA: true,
        checkedB: false,
        checkedC: false,
        checkedD: false,
    });

    // Input states
    const [numberBank, setNumberBank] = useState('');
    const [initialDate, setInitialDate] = useState('');
    const [accountAccept, setAccountAccept] = useState("N");
    const [fineDays, setFineDays] = useState('');
    const [interDaysToCancelBillet, setInterDaysToCancelBillet] = useState(0);
    const [interClientId, setInterClientId] = useState('');
    const [interClientSecret, setInterClientSecret] = useState('');
    const [interCertificateFile, setInterCertificateFile] = useState<File | null>(null);
    const [interKeyFile, setInterKeyFile] = useState<File | null>(null);

    const interCertificateFileRef = useRef<HTMLInputElement>(null);
    const interKeyFileRef = useRef<HTMLInputElement>(null);

    // Input error states
    const [nameAccountInvalid, setNameAccountInvalid] = useState(false);
    const [openingBalanceInvalid, setOpeningBalanceInvalid] = useState(false);
    const [initialDateInvalid, setInitialDateInvalid] = useState(false);
    const [agencyInvalid, setAgencyInvalid] = useState(false);
    const [agencyDigitInvalid, setAgencyDigitInvalid] = useState(false);
    const [accountInvalid, setAccountInvalid] = useState(false);
    const [accountDigitInvalid, setAccountDigitInvalid] = useState(false);
    const [shipmentSequenceInvalid, setShipmentSequenceInvalid] = useState(false);
    const [billetSequenceInvalid, setBilletSequenceInvalid] = useState(false);
    const [defaultLayoutInvalid, setDefaultLayoutInvalid] = useState(false);
    const [titleSpeciesInvalid, setTitleSpeciesInvalid] = useState(false);
    const [covenantCodeInvalid, setCovenantCodeInvalid] = useState(false);
    const [codeAssignorInvalid, setCodeAssignorInvalid] = useState(false);
    const [walletInvalid, setWalletInvalid] = useState(false);
    const [interClientIdInvalid, setInterClientIdInvalid] = useState(false);
    const [interClientSecretInvalid, setInterClientSecretInvalid] = useState(false);

    const selectedBankCanGenerateBillets = !!banksBillet.find(bank => bank.number == numberBank);
    const selectedBankIsInter = bankNumberIsInter(numberBank);

    function handleCloseModal() {
        setShowModal(false);
        resetForm();
    }

    function resetForm() {
        setActiveStep(0);
        setCurrentStep(1);
        setIsSubmit(false);
        setAllowContinue(false);
        cleanInvalids();
        setInterClientId('');
        setInterClientSecret('');
        setInterCertificateFile(null);
        setInterKeyFile(null);
        setState({
            checkedA: true,
            checkedB: false,
            checkedC: false,
            checkedD: false,
        });
        setFineDays('');
        setInterDaysToCancelBillet(0);

        if (interCertificateFileRef.current) {
            interCertificateFileRef.current.files = null;
            interCertificateFileRef.current.value = '';
        }
        if (interKeyFileRef.current) {
            interKeyFileRef.current.value = '';
            interKeyFileRef.current.files = null;
        }
    }

    function cleanInvalids() {
        setNameAccountInvalid(false);
        setOpeningBalanceInvalid(false);
        setInitialDateInvalid(false);
        setAgencyInvalid(false);
        setAgencyDigitInvalid(false);
        setAccountInvalid(false);
        setAccountDigitInvalid(false);
        setShipmentSequenceInvalid(false);
        setBilletSequenceInvalid(false);
        setDefaultLayoutInvalid(false);
        setTitleSpeciesInvalid(false);
        setCovenantCodeInvalid(false);
        setCodeAssignorInvalid(false);
        setWalletInvalid(false);
        setInterClientIdInvalid(false);
        setInterClientSecretInvalid(false);
    }

    async function disableOtherDefaultRevenue(id: number) {
        const otherAccounts = await api.get(`/accountsBank`);


        for (var account of otherAccounts.data) {
            if (account.id != id && account.standardAccountForRecipes == "y") {
                await api.post(`/accountBank/${account.id}`, { standardAccountForRecipes: "n" });
            }
        }
    }

    async function disableOtherDefaultExpense(id: number) {
        const otherAccounts = await api.get(`/accountsBank`);

        for (var account of otherAccounts.data) {
            if (account.id != id && account.standardExpenseAccount == "y") {
                await api.post(`/accountBank/${account.id}`, { standardExpenseAccount: "n" });
            }
        }
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: event.target.checked });
    };

    const handleChangeInterCertificateFile = (fileList: FileList | null) => {
        setInterCertificateFile(fileList && fileList.length > 0 ? fileList[0] : null);
    };
    const handleChangeInterKeyFile = (fileList: FileList | null) => {
        setInterKeyFile(fileList && fileList.length > 0 ? fileList[0] : null);
    };


    function inputsVerifyCreate() {
        cleanInvalids();

        const bankRequiresWallet = numberBanksThatRequireWallet.includes(numberBank);

        if (!(watch('name'))) {
            setIsSubmit(false)
            setNameAccountInvalid(true);
            alert("Preencha o nome da conta corretamente")

            return false

        }

        if (!(watch('openingBalance'))) {
            setIsSubmit(false)
            setOpeningBalanceInvalid(true);
            alert("Preencha o saldo inicial corretamente")

            return false
        }


        if (!initialDate) {
            setIsSubmit(false)
            setInitialDateInvalid(true);
            alert("Preencha a data corretamente")

            return false

        }

        if (numberBank != "000") {
            if (!(watch('agency'))) {
                setIsSubmit(false)
                setAgencyInvalid(true);
                alert("Preencha a agência corretamente")

                return false

            }


            if (!(watch('account'))) {
                setIsSubmit(false)
                setAccountInvalid(true);
                alert("Preencha a conta corretamente")

                return false

            }

            if (numberBank != "341") {
                // if (!(watch('agencyDigit'))) {
                //     setIsSubmit(false)
                //     setAgencyDigitInvalid(true);
                //     alert("Preencha o dígito da agência corretamente")

                //     return false

                // }

                if (!(watch('accountDigit'))) {
                    setIsSubmit(false)
                    setAccountDigitInvalid(true);
                    alert("Preencha o dígito da conta corretamente")

                    return false

                }

            }
        }


        if (currentStep == 2 && selectedBankCanGenerateBillets) {
            if (state.checkedD) {
                switch (numberBank) {
                    case "001": //Banco do Brasil
                        var covenant = watch('covenantCode');
                        if (!covenant || (covenant.length != 4 && covenant.length != 6 && covenant.length != 7)) {
                            setIsSubmit(false);
                            setCovenantCodeInvalid(true);
                            alert("O Convênio (código do cedente) deve ter 4, 6 ou 7 digitos");
                            return false;
                        }
                        break;
                    case "237": //Bradesco
                        var assignor = watch('codeAssignor')
                        if (assignor.length == 0) {
                            setIsSubmit(false);
                            setCodeAssignorInvalid(true);
                            alert("Informe o Convênio (código do cedente)");
                            return false;
                        }
                        break;
                    case "104": //Caixa Econômica Federal
                        var assignor = watch('codeAssignor')
                        if (!assignor || !(assignor.length == 6)) {
                            setIsSubmit(false);
                            setCodeAssignorInvalid(true);
                            alert("O Convênio (código do cedente) deve ter 6 digitos");
                            return false;
                        }
                        break;
                }

                if (bankRequiresWallet && !(watch('wallet'))) {
                    setIsSubmit(false);
                    setWalletInvalid(true);
                    alert("Selecione o tipo de carteira");
                    return false;
                }
            }
        }

        if (currentStep == 3 && selectedBankCanGenerateBillets && state.checkedD) {
            if (!selectedBankIsInter) {
                // Validação de campos para bancos que geram boletos de forma manual

                if (!(watch('shipmentSequence'))) {
                    setIsSubmit(false);
                    setShipmentSequenceInvalid(true);
                    alert("Preencha a sequência da remessa");
                    return false;

                }

                if (!(watch('billetSequence'))) {
                    setIsSubmit(false);
                    setBilletSequenceInvalid(true);
                    alert("Preencha a sequência do boleto");
                    return false;

                }

                if (!watch('defaultLayout')) {
                    setIsSubmit(false);
                    setDefaultLayoutInvalid(true);
                    alert("Selecione um layout padrão do boleto");
                    return false;

                }

                if (!watch('titleSpecies')) {
                    setIsSubmit(false);
                    setTitleSpeciesInvalid(true);
                    alert("Selecione a espécie do título");
                    return false;

                }
            }

            if (selectedBankIsInter) {
                // Validação específica para o banco Inter

                if (!interClientId) {
                    setIsSubmit(false);
                    setInterClientIdInvalid(true);
                    alert("Preencha o ClientId corretamente");
                    return false;
                }

                if (!interClientSecret) {
                    setIsSubmit(false);
                    setInterClientSecretInvalid(true);
                    alert("Preencha o ClientSecret corretamente");
                    return false;
                }

                if (!interCertificateFile) {
                    setIsSubmit(false);
                    alert("Faça upload do arquivo do certificado Inter");
                    return false;
                }

                if (interCertificateFile.type !== 'application/x-x509-ca-cert') {
                    setIsSubmit(false);
                    alert("O arquivo do certificado Inter fornecido não possui um formato válido");
                    return false;
                }

                if (!interKeyFile) {
                    setIsSubmit(false);
                    alert("Faça upload do arquivo da chave Inter");
                    return false;
                }

                if (getFileExtension(interKeyFile.name) !== 'key') {
                    setIsSubmit(false);
                    alert("O arquivo da chave Inter fornecido não possui um formato válido");
                    return false;
                }
            }
        }

        handleNext();
        setCurrentStep(currentStep + 1)

        return true

    }

    async function handleDone() {
        setIsSubmit(true);

        //* Adicionar conta
        const raw: GenericObject = {
            ...watch(),
            walletType: "with record",
            numberBank: numberBank,
            accept: accountAccept,
            initialDate: initialDate,
            color: Math.round((Math.random() * 0xFFFFFF)).toString(16).padStart(6, '0'),
            interCertificatePath: null,
            interKeyPath: null,
        };

        if (selectedBankIsInter && state.checkedD) {
            raw.fineDays = Number(fineDays || 0);
            raw.interDaysToCancelBillet = Number(interDaysToCancelBillet || 0);
            raw.interClientId = interClientId;
            raw.interClientSecret = interClientSecret;
        } else {
            raw.fineDays = null;
            raw.interDaysToCancelBillet = null;
            raw.interClientId = null;
            raw.interClientSecret = null;
        }

        try {
            const result = await api.post<BankAccount>('/accountBank', raw)

            const bankAccount = result.data;

            if (bankAccount.standardAccountForRecipes == "y") {
                await disableOtherDefaultRevenue(Number(bankAccount.id));
            }

            if (bankAccount.standardExpenseAccount == "y") {
                await disableOtherDefaultExpense(Number(bankAccount.id));
            }

            // Fazer upload dos arquivos certificado e chave do Inter
            if (selectedBankIsInter && state.checkedD) {
                let uploadSuccess = true;
                try {
                    uploadSuccess = await uploadInterFiles(Number(bankAccount.id));
                } catch (error) {
                    uploadSuccess = false;
                }
            }

            LogService.logRegister({
                itemId: bankAccount.id,
                module: 'Contas Bancárias',
                itemName: bankAccount.name
            });

            if (onCreateAccountBank) {
                onCreateAccountBank(bankAccount);
            }

            handleCloseModal();
        } catch (error) {
            console.log('error', error);
            setIsSubmit(false);
            alert('Ocorreu um erro ao processar a requisição');
        }
    }

    async function uploadInterFiles(accountBankId: number): Promise<boolean> {
        if (interCertificateFile && interKeyFile) {
            
            var formFile = new FormData();
            formFile.append('file', interCertificateFile, interCertificateFile.name);
            const certificateResponse = await api.post('/file', formFile);

            var formFile = new FormData();
            formFile.append('file', interKeyFile, interKeyFile.name);
            const keyResponse = await api.post('/file', formFile);

            const raw = {
                interCertificatePath: certificateResponse.data,
                interKeyPath: keyResponse.data,
            };
    
            await api.post(`accountBank/${accountBankId}`, raw);
            return true;
        }

        return false;
    }

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="xl"
            aria-labelledby="contained-modal-price"
            centered
            className="newProductWrapper"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastro de conta bancária
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key={0}>

                            <StepLabel>
                                <Grid container spacing={3}>
                                    <Grid item lg={6}>Selecione um banco para cadastrar a conta bancária</Grid>

                                    <Grid item lg={6} className="d-flex align-items-center justify-content-end">
                                        {(activeStep > 0)
                                            ?
                                            <>
                                                {(activeStep === steps.length)
                                                    ? <Button variant="primary" className="mr-3" onClick={() => {
                                                        handleDone();
                                                    }}>
                                                        {isSubmit ? <>

                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />

                                                            <span className='ml-2'>
                                                                Aguarde...
                                                            </span>

                                                        </> : <>

                                                            <span>
                                                                Salvar
                                                            </span>

                                                        </>}
                                                    </Button>
                                                    : <Button variant="primary" className="mr-3" onClick={() => {
                                                        inputsVerifyCreate()
                                                    }}>Continuar</Button>
                                                }
                                            </>
                                            : ""
                                        }
                                        <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
                                    </Grid>
                                </Grid>

                            </StepLabel>
                            <StepContent>
                                <Card className="mt-5">
                                    <Card.Body className="row">
                                        <Grid container>
                                            {
                                                banks.map(function (object, index) {
                                                    return (
                                                        <Grid key={object.number} item lg={4} className="mb-3">
                                                            <Button
                                                                variant="outline-light"
                                                                size="lg"
                                                                onClick={() => {
                                                                    handleNext();
                                                                    setBankSelected(object.name);
                                                                    setBankSelectedVal(object.number);
                                                                    setNumberBank(object.number);
                                                                    setCurrentStep(currentStep + 1);
                                                                }}
                                                            >
                                                                <strong>{object.number}</strong> - {object.name}
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Card.Body>
                                </Card>
                            </StepContent>
                        </Step>

                        <Step key={2}>
                            <StepLabel>Informações básicas sobre a conta bancária</StepLabel>
                            <StepContent>
                                <Card className="mt-5">
                                    <Card.Body className="row">
                                        <Grid container spacing={3} className="mb-3">
                                            <Grid item lg={3}
                                                className="d-flex flex-column justify-content-center">
                                                <strong>Banco:</strong>
                                                {bankSelected}
                                            </Grid>
                                            <Grid item lg={4}>
                                                <TextField
                                                    size="small"
                                                    label="Nome da conta"
                                                    required
                                                    className={classes.error}
                                                    placeholder="Digite o nome do banco"
                                                    margin="normal"
                                                    variant="outlined"
                                                    {...register(
                                                        "name",
                                                        {
                                                            required: true
                                                        })}
                                                    onChange={(e) => {
                                                        setNameAccountInvalid(e.target.value ? false : true)
                                                    }}
                                                    error={nameAccountInvalid}
                                                />
                                            </Grid>

                                            <Grid item lg={2}>
                                                <NumericFormat
                                                    label="Saldo Inicial"
                                                    required
                                                    startAdornment="R$"
                                                    {
                                                    ...register(
                                                        "openingBalance",
                                                        { required: true }
                                                    )
                                                    }
                                                    onChange={(e) => {
                                                        setOpeningBalanceInvalid(e.target.value ? false : true)
                                                    }}
                                                    error={openingBalanceInvalid}
                                                />
                                            </Grid>

                                            <Grid item lg={3} className="d-flex align-items-center">
                                                <TextField
                                                    size="small"
                                                    id="date"
                                                    label="Data Inicial"
                                                    required
                                                    type="date"
                                                    variant="outlined"
                                                    className={classes.error}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {
                                                        setInitialDateInvalid(e.target.value ? false : true);
                                                        setInitialDate(e.target.value);
                                                    }}
                                                    error={initialDateInvalid}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item lg={3}>
                                                <strong>Agência {numberBank == "000" ? "" : "*"}:</strong>

                                                <Row>
                                                    <Col lg={8}>
                                                        <TextField
                                                            size="small"
                                                            className={classes.error}
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            {...register(
                                                                "agency",
                                                                {
                                                                    required: numberBank == "000" ? false : true
                                                                })}
                                                            onChange={(e) => {
                                                                setAgencyInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={agencyInvalid}
                                                        />
                                                    </Col>

                                                    <Col lg={4}>
                                                        <TextField
                                                            size="small"
                                                            className={classes.error}
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            {...register("agencyDigit",)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta {numberBank == "000" ? "" : "*"}:</strong>
                                                <Row>
                                                    <Col lg={8}>
                                                        <TextField
                                                            size="small"
                                                            className={classes.error}
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            {...register(
                                                                "account",
                                                                {
                                                                    required: numberBank == "000" ? false : true
                                                                })}
                                                            onChange={(e) => {
                                                                setAccountInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={accountInvalid}
                                                        />
                                                    </Col>

                                                    <Col lg={4}>
                                                        <TextField
                                                            size="small"
                                                            className={classes.error}
                                                            required
                                                            margin="normal"
                                                            variant="outlined"
                                                            {...register(
                                                                "accountDigit",
                                                                {
                                                                    required: numberBank == "000" ? false : true
                                                                })}
                                                            onChange={(e) => {
                                                                setAccountDigitInvalid(e.target.value ? false : true)
                                                            }}
                                                            error={accountDigitInvalid}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} className="pt-7">
                                            <Grid item lg={3}>
                                                <strong>Situação:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={state.checkedA}
                                                                onChange={handleChange('checkedA')}
                                                                value="y"
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta padrão para receitas:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={state.checkedB}
                                                                onChange={handleChange('checkedB')}
                                                                value="y"
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={3}>
                                                <strong>Conta padrão para despesas:</strong>
                                                <Typography component="div">
                                                    <Grid component="label" container
                                                        alignItems="center" spacing={1}>
                                                        <Grid item>Inativo</Grid>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={state.checkedC}
                                                                onChange={handleChange('checkedC')}
                                                                value="y"
                                                            />
                                                        </Grid>
                                                        <Grid item>Ativo</Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {
                                            selectedBankCanGenerateBillets
                                                ?
                                                <>

                                                    <Grid container spacing={3} className="mt-7 border-top">
                                                        <Grid item lg={5}>
                                                            <strong>Esta conta irá gerar boletos bancários (com registro)?</strong>
                                                            <Typography component="div">
                                                                <Grid component="label" container
                                                                    alignItems="center" spacing={1}>
                                                                    <Grid item>Não</Grid>
                                                                    <Grid item>
                                                                        <AntSwitch
                                                                            checked={state.checkedD}
                                                                            onChange={handleChange('checkedD')}
                                                                            value="y"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>Sim</Grid>
                                                                </Grid>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {(state.checkedD)
                                                        ?
                                                        <>
                                                            <Grid container spacing={3} className="mt-3">
                                                                <Grid item lg={4}>


                                                                    {(() => {

                                                                        switch (bankSelected) {
                                                                            case 'Itaú':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        size="small"
                                                                                        label="Carteira"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        {...register(
                                                                                            "wallet",
                                                                                            {
                                                                                                required: false
                                                                                            })}
                                                                                        onChange={(e) => {
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="109">109 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="2" value="110">110 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="3" value="111">111 - Direta Eletrônica Sem Emissão - Simples</MenuItem>
                                                                                        <MenuItem key="4" value="112">112 - Escritural Eletrônica - simples / contratual</MenuItem>
                                                                                        <MenuItem key="5" value="115">115 - Carteira 115</MenuItem>
                                                                                        <MenuItem key="6" value="121">121 - Direta Eletrônica Emissão Parcial - Simples/Contra</MenuItem>
                                                                                        <MenuItem key="7" value="188">188 - Carteira 188</MenuItem>
                                                                                        <MenuItem key="8" value="180">180 - Direta Eletrônica Emissão Integral</MenuItem>
                                                                                    </TextField>
                                                                                );
                                                                            case 'Bradesco':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        size="small"
                                                                                        label="Carteira"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        {...register(
                                                                                            "wallet",
                                                                                            {
                                                                                                required: false
                                                                                            })}
                                                                                        onChange={(e) => {
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="09">09 - Com Registro</MenuItem>
                                                                                        <MenuItem key="2" value="21">21 - Com Registro - Pagável somente no Bradesco</MenuItem>
                                                                                        <MenuItem key="3" value="26">26 - Com Registro – Emissão na Internet</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Banco do Brasil':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        size="small"
                                                                                        label="Carteira"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        {...register(
                                                                                            "wallet",
                                                                                            {
                                                                                                required: false
                                                                                            })}
                                                                                        onChange={(e) => {
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="11">11 - Cobrança com registro Simples</MenuItem>
                                                                                        <MenuItem key="2" value="12">12 - Cobrança com registro Indexada</MenuItem>
                                                                                        <MenuItem key="3" value="15">15 - Cobrança com registro Prêmios de Seguro</MenuItem>
                                                                                        <MenuItem key="4" value="17">17 - Cobrança com registro Direta Especial</MenuItem>
                                                                                        <MenuItem key="5" value="18">18 - Cobrança com registro Simples</MenuItem>
                                                                                        <MenuItem key="6" value="31">31 - Cobrança com registro Caucionada</MenuItem>
                                                                                        <MenuItem key="7" value="51">51 - Cobrança com registro Descontada</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Santander':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        size="small"
                                                                                        label="Carteira"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        {...register(
                                                                                            "wallet",
                                                                                            {
                                                                                                required: false
                                                                                            })}
                                                                                        onChange={(e) => {
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="101">101 - Cobrança Simples</MenuItem>
                                                                                        <MenuItem key="2" value="201">201 - Penhor Rápida</MenuItem>
                                                                                    </TextField>
                                                                                );

                                                                            case 'Caixa Econômica Federal':
                                                                                return (
                                                                                    <TextField
                                                                                        select
                                                                                        size="small"
                                                                                        label="Carteira"
                                                                                        SelectProps={{
                                                                                            MenuProps: {
                                                                                                className: classes.menu,
                                                                                            },
                                                                                        }}
                                                                                        margin="normal"
                                                                                        variant="outlined"
                                                                                        {...register(
                                                                                            "wallet",
                                                                                            {
                                                                                                required: false
                                                                                            })}
                                                                                        onChange={(e) => {
                                                                                            setWalletInvalid(e.target.value ? false : true)
                                                                                        }}
                                                                                        error={walletInvalid}
                                                                                    >
                                                                                        <MenuItem key="0" value="" disabled>Selecione</MenuItem>
                                                                                        <MenuItem key="1" value="RG">RG - Com Registro</MenuItem>
                                                                                    </TextField>
                                                                                );
                                                                        }

                                                                    })()}
                                                                </Grid>

                                                                {(() => {

                                                                    switch (bankSelected) {
                                                                        case 'Itaú':
                                                                            return (
                                                                                <>

                                                                                </>
                                                                            );
                                                                        case 'Bradesco':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            label="Convênio (código do cedente)"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            {...register(
                                                                                                "codeAssignor",
                                                                                                {
                                                                                                    required: false
                                                                                                })}
                                                                                            onChange={(e) => {
                                                                                                setCodeAssignorInvalid(e.target.value.length > 0 ? false : true)
                                                                                            }}
                                                                                            error={codeAssignorInvalid}

                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            );

                                                                        case 'Banco do Brasil':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            label="Convênio (código do cedente)"
                                                                                            size="small"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            {...register(
                                                                                                "covenantCode",
                                                                                                {
                                                                                                    required: false
                                                                                                })}
                                                                                            onChange={(e) => {
                                                                                                setCovenantCodeInvalid(e.target.value.length != 4 && e.target.value.length != 6 && e.target.value.length != 7 ? true : false)
                                                                                            }}
                                                                                            error={covenantCodeInvalid}
                                                                                        >
                                                                                        </TextField>
                                                                                    </Grid>

                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            label="Variação de carteira"
                                                                                            size="small"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            {...register(
                                                                                                "walletVariation",
                                                                                                {
                                                                                                    required: false
                                                                                                })}
                                                                                        >
                                                                                        </TextField>
                                                                                    </Grid>
                                                                                </>
                                                                            );

                                                                        case 'Santander':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            label="Convênio (código do cedente)"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            {...register(
                                                                                                "codeAssignor",
                                                                                                {
                                                                                                    required: false
                                                                                                })}
                                                                                            onChange={(e) => {
                                                                                                setCodeAssignorInvalid(e.target.value.length > 0 ? false : true)
                                                                                            }}
                                                                                            error={codeAssignorInvalid}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            );

                                                                        case 'Caixa Econômica Federal':
                                                                            return (
                                                                                <>
                                                                                    <Grid item lg={4}>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            label="Convênio (código do cedente)"
                                                                                            margin="normal"
                                                                                            variant="outlined"
                                                                                            {...register(
                                                                                                "codeAssignor",
                                                                                                {
                                                                                                    required: false
                                                                                                })}
                                                                                            onChange={(e) => {
                                                                                                setCodeAssignorInvalid(e.target.value.length > 0 ? false : true)
                                                                                            }}
                                                                                            error={codeAssignorInvalid}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            );
                                                                    }


                                                                })()}
                                                            </Grid>
                                                        </>
                                                        : ''
                                                    }
                                                </>

                                                : <></>
                                        }


                                    </Card.Body>
                                </Card>
                            </StepContent>
                        </Step>

                        {
                            selectedBankCanGenerateBillets && state.checkedD
                                ?
                                [
                                    <Step key={3}>
                                        <StepLabel>Informações avançadas sobre a conta bancária</StepLabel>
                                        <StepContent>

                                            {selectedBankIsInter ? (
                                                <Card className="mt-5">
                                                    {/* CONFIGURACOES DE BOLETOS DO BANCO INTER */}
                                                    <Card.Body className="row">
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={8} className="mb-3">
                                                                <TextField
                                                                    label="Instruções que serão impressas no boleto"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    rowsMax={5}
                                                                    inputProps={{ maxLength: 390 }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "instructions",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Multa"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    {
                                                                    ...register(
                                                                        "percentageOfFinePerMonth",
                                                                        {
                                                                            required: false
                                                                        }
                                                                    )
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Multa"
                                                                    margin="normal"
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    value={fineDays}
                                                                    onChange={(e) => setFineDays(e.target.value)}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Juros"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    {
                                                                    ...register(
                                                                        "interestPercentage",
                                                                        {
                                                                            required: false
                                                                        }
                                                                    )
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Juros"
                                                                    margin="normal"
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "interestDays",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <div className="mt-1">&nbsp;</div>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={4}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    label="Cancelamento automático"
                                                                    helperText="Em dias (após vencimento)"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={String(interDaysToCancelBillet)}
                                                                    onChange={(e) => setInterDaysToCancelBillet(Number(e.target.value || '0'))}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <h5 className="mt-7">Configurações da aplicação do Banco Inter:</h5>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={6}>
                                                                <TextField
                                                                    size="small"
                                                                    label="ClientId *"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={interClientId}
                                                                    onChange={(e) => setInterClientId(e.target.value)}
                                                                    error={interClientIdInvalid}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={6}>
                                                                <TextField
                                                                    size="small"
                                                                    label="ClientSecret *"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    value={interClientSecret}
                                                                    onChange={(e) => setInterClientSecret(e.target.value)}
                                                                    error={interClientSecretInvalid}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            <Grid item lg={6}>
                                                                <label htmlFor="formFile" className="mt-5">
                                                                    Certificado (arquivo .crt) *
                                                                </label>

                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="interCertificateFile"
                                                                    ref={interCertificateFileRef}
                                                                    onChange={(e) => handleChangeInterCertificateFile(e.target.files)}
                                                                    accept=".crt"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            <Grid item lg={6}>
                                                                <label htmlFor="formFile" className="mt-5">
                                                                    Chave (arquivo .key) *
                                                                </label>

                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="interKeyFile"
                                                                    ref={interKeyFileRef}
                                                                    onChange={(e) => handleChangeInterKeyFile(e.target.files)}
                                                                    accept=".key"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Card.Body>
                                                </Card>
                                            ) : (
                                                <Card className="mt-5">
                                                    {/* CONFIGURACOES DE BOLETOS DOS DEMAIS BANCOS */}
                                                    <Card.Body className="row">
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={6} className="mb-3">
                                                                <TextField
                                                                    label="Instruções que serão impressas no boleto"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "instructions",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={6} className="mb-3">
                                                                <TextField
                                                                    label="Demonstrativo"
                                                                    size="small"
                                                                    multiline
                                                                    rows="5"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "demonstrative",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Multa"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    {
                                                                    ...register(
                                                                        "percentageOfFinePerMonth",
                                                                        {
                                                                            required: false
                                                                        }
                                                                    )
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <NumericFormat
                                                                    label="Juros"
                                                                    helperText="Ao mês"
                                                                    startAdornment="%"
                                                                    {
                                                                    ...register(
                                                                        "interestPercentage",
                                                                        {
                                                                            required: false
                                                                        }
                                                                    )
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item lg={2}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 1 }}
                                                                    label="Juros"
                                                                    margin="normal"
                                                                    helperText="Em dias"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "interestDays",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 1 }}
                                                                    label="Sequência da remessa"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "shipmentSequence",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                    onChange={(e) => {
                                                                        setShipmentSequenceInvalid(e.target.value ? false : true)
                                                                    }}
                                                                    error={shipmentSequenceInvalid}
                                                                />
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    label="Sequência do boleto"
                                                                    inputProps={{ min: 1 }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "billetSequence",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <h5 className="mt-7">Configurações de remessa:</h5>

                                                        <Grid container spacing={3}>
                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    select
                                                                    size="small"
                                                                    label="Layout padrão (CNAB)"
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "defaultLayout",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                >
                                                                    <MenuItem key="1" value="Cnab240">
                                                                        240
                                                                    </MenuItem>

                                                                    <MenuItem key="2" value="Cnab400">
                                                                        400
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>

                                                            <Grid item lg={3}>
                                                                <TextField
                                                                    select
                                                                    size="small"
                                                                    label="Espécie do título:"
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            className: classes.menu,
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    {...register(
                                                                        "titleSpecies",
                                                                        {
                                                                            required: false
                                                                        })}
                                                                >
                                                                    <MenuItem key="0" value="">
                                                                        Selecione
                                                                    </MenuItem>

                                                                    <MenuItem key="1" value="DM">
                                                                        DM - Duplicata mercantil
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </Card.Body>
                                                </Card>
                                            )}

                                        </StepContent>
                                    </Step>,
                                    <Step key={4}>
                                        <StepLabel>Confirmação de dados</StepLabel>
                                        <StepContent>

                                            <Card className="mt-5">
                                                <Card.Body className="row">
                                                    <h5 className='mb-3'>Informações básicas</h5>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Banco:</strong>
                                                            {bankSelected}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Nome da conta:</strong>
                                                            {watch("name")}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Data Inicial:</strong>
                                                            {initialDate}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Agência:</strong>
                                                            {watch("agency") + (watch("agencyDigit") ? '-' + watch("agencyDigit") : '')}
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Conta:</strong>
                                                            {watch("account") + (watch("accountDigit") ? '-' + watch("accountDigit") : '')}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} className="mb-3">
                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Situação:</strong>
                                                            {state.checkedA ? 'Ativo' : 'Inativo'}
                                                            <input type="hidden"
                                                                value={state.checkedA ? 'y' : 'n'} {...register(
                                                                    "situation",
                                                                    {
                                                                        required: false
                                                                    })} />
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Padrão receita:</strong>
                                                            {state.checkedB ? 'Ativo' : 'Inativo'}
                                                            <input type="hidden"
                                                                value={state.checkedB ? 'y' : 'n'} {...register(
                                                                    "standardAccountForRecipes",
                                                                    {
                                                                        required: false
                                                                    })} />

                                                            <input type="hidden"
                                                                value={state.checkedD ? 'y' : 'n'} {...register(
                                                                    "willGenerateBills",
                                                                    {
                                                                        required: false
                                                                    })} />

                                                            <input type="hidden"
                                                                value={bankSelected} {...register(
                                                                    "nameBank",
                                                                    {
                                                                        required: true
                                                                    })} />
                                                        </Grid>

                                                        <Grid item lg={4}
                                                            className="mb-1 d-flex flex-column justify-content-center">
                                                            <strong>Padrão despesa:</strong>
                                                            {state.checkedC ? 'Ativo' : 'Inativo'}
                                                            <input type="hidden"
                                                                value={state.checkedC ? 'y' : 'n'} {...register(
                                                                    "standardExpenseAccount",
                                                                    {
                                                                        required: false
                                                                    })} />
                                                        </Grid>
                                                    </Grid>
                                                </Card.Body>
                                            </Card>
                                        </StepContent>
                                    </Step>
                                ]
                                :
                                <Step key={4}>
                                    <StepLabel>Confirmação de dados</StepLabel>
                                    <StepContent>

                                        <Card className="mt-5">
                                            <Card.Body className="row">
                                                <h5 className='mb-3'>Informações básicas</h5>

                                                <Grid container spacing={3} className="mb-3">
                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Banco:</strong>
                                                        {bankSelected}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Nome da conta:</strong>
                                                        {watch("name")}
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Data Inicial:</strong>
                                                        {initialDate.split('-').reverse().join('/')}
                                                    </Grid>
                                                </Grid>

                                                {
                                                    numberBank != "000"
                                                        ?
                                                        <Grid container spacing={3} className="mb-3">
                                                            <Grid item lg={4}
                                                                className="mb-1 d-flex flex-column justify-content-center">
                                                                <strong>Agência:</strong>
                                                                {watch("agency") + (watch("agencyDigit") ? '-' + watch("agencyDigit") : '')}
                                                            </Grid>

                                                            <Grid item lg={4}
                                                                className="mb-1 d-flex flex-column justify-content-center">
                                                                <strong>Conta:</strong>
                                                                {watch("account") + (watch("accountDigit") ? '-' + watch("accountDigit") : '')}
                                                            </Grid>
                                                        </Grid>
                                                        : <></>
                                                }


                                                <Grid container spacing={3} className="mb-3">
                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Situação:</strong>
                                                        {state.checkedA ? 'Ativo' : 'Inativo'}
                                                        <input type="hidden"
                                                            value={state.checkedA ? 'y' : 'n'} {...register(
                                                                "situation",
                                                                {
                                                                    required: false
                                                                })} />
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Padrão receita:</strong>
                                                        {state.checkedB ? 'Ativo' : 'Inativo'}
                                                        <input type="hidden"
                                                            value={state.checkedB ? 'y' : 'n'} {...register(
                                                                "standardAccountForRecipes",
                                                                {
                                                                    required: false
                                                                })} />

                                                        <input type="hidden"
                                                            value={state.checkedD ? 'y' : 'n'} {...register(
                                                                "willGenerateBills",
                                                                {
                                                                    required: false
                                                                })} />

                                                        <input type="hidden"
                                                            value={bankSelected} {...register(
                                                                "nameBank",
                                                                {
                                                                    required: true
                                                                })} />
                                                    </Grid>

                                                    <Grid item lg={4}
                                                        className="mb-1 d-flex flex-column justify-content-center">
                                                        <strong>Padrão despesa:</strong>
                                                        {state.checkedC ? 'Ativo' : 'Inativo'}
                                                        <input type="hidden"
                                                            value={state.checkedC ? 'y' : 'n'} {...register(
                                                                "standardExpenseAccount",
                                                                {
                                                                    required: false
                                                                })} />
                                                    </Grid>
                                                </Grid>
                                            </Card.Body>
                                        </Card>
                                    </StepContent>
                                </Step>
                        }
                    </Stepper>
                </div>
            </Modal.Body>
        </Modal>
    );
}